import React, { FC } from 'react';
// helpers
import { benefits } from 'tools/consts';
// styles
import './style.scss';

const Benefits: FC = () => {
  return (
    <div className="stripe-det__benefits-list benefits">
      {benefits.map((item: any, i: number) => (
        <div key={i} className="benefits__item">
          {item}
        </div>
      ))}
    </div>
  );
};

export default Benefits;
