import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import { Tabs } from 'antd';
import { useSelector, shallowEqual } from 'react-redux';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
// components
import ClientCard from '../../../organisms/info/client/card';
import ConsultationInfo from '../../../organisms/forms/consultationInfo';
import HistoryContainer from '../../historyContainer';
import AssasmentList from '../../../molecules/lists/assasments/therapist';
import NpsScoreContainer from '../../npsScoreContainer';
import ApproveClient from '../../../molecules/approve';
// types
import { stateType } from '../../../../types/types';
// styles
import './style.scss';

interface ConsultationContainerType {
  children: React.ReactNode;
}

const AdminClientInfoContaiener: React.FC = () => {
  const [active, setActive] = useState('consultation');

  const { TabPane } = Tabs;
  const loader: any = useSelector((state: stateType) => state.clients.loader);
  const item: any = useSelector((state: stateType) => state.clients.item, shallowEqual);

  const history = useHistory();
  const tab = queryString.parse(history.location.search).tab;

  const onChange = (key: any) => {
    setActive(key);
  };

  useEffect(() => {
    if (tab && typeof tab === 'string') {
      setActive(tab);
    }
  }, []);

  return (
    <Spin spinning={loader}>
      <div className="admin-info-client-container">
        {!loader && !_.isEmpty(item) && (
          <>
            <div className="admin-info-client-container__column">
              <ClientCard />
            </div>
            <div className="admin-info-client-container__column">
              <Tabs defaultActiveKey={active} activeKey={active} onChange={onChange} className="info-tabs">
                <TabPane tab="Consultation Form" key="consultation" className="info-tabs__item">
                  <ConsultationContainer>
                    <ConsultationInfo />
                  </ConsultationContainer>
                </TabPane>
                <TabPane tab="Assessments" key="assessments" className="info-tabs__item info-tabs__item_assessments">
                  <AssasmentList />
                </TabPane>
                <TabPane tab="History" key="history" className="info-tabs__item">
                  <HistoryContainer />
                </TabPane>
                <TabPane tab="NPS Score" key="nps" className="info-tabs__item">
                  <NpsScoreContainer paramsId={item.id} />
                </TabPane>
              </Tabs>
              <ApproveClient />
            </div>
          </>
        )}
      </div>
    </Spin>
  );
};

export default AdminClientInfoContaiener;

export const ConsultationContainer: React.FC<ConsultationContainerType> = ({ children }: ConsultationContainerType) => {
  const item: any = useSelector((state: stateType) => state.clients.item, shallowEqual);

  return (
    <div className="consultation-info-container">
      <div className="consultation-info-container__avatar">
        {item?.avatar?.url ? (
          <img src="#" alt="avatar" className="consultation-info-container__img" />
        ) : (
          <div className="consultation-info-container__avatar-fake">
            {item.firstname[0]}
            {item.lastname[0]}
          </div>
        )}
      </div>
      <div className="consultation-info-container__block">
        <div className="consultation-info-container__title">
          {item.firstname} {item.lastname}
        </div>
        <div className="consultation-info-container__body">{children}</div>
      </div>
    </div>
  );
};
