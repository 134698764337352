import React from 'react';
import { Layout } from 'antd';
import { useSelector } from 'react-redux';
// components
import MainHeader from '../../organisms/headers';
import MainMenu from '../../organisms/menus/mainMenu';
import ModalQuestions from 'components/organisms/modals/questions/StartQuestions';
import ModalRecQuestions from '../../organisms/modals/questions/recommendation/recommendation';
import ProgressModal from 'components/molecules/popovers/client/propgress';
// types
import { stateType } from 'types/types';
// styles
import './style.scss';

interface templateProps {
  children: React.ReactNode;
  leftSiderOff?: boolean;
  headerOff?: boolean;
  className?: string;
}

const MainTemplate: React.FC<templateProps> = (props: templateProps) => {
  const { leftSiderOff, headerOff, children, className } = props;

  const { propgressPopover } = useSelector((state: stateType) => state.main_menu);

  const menu: any = useSelector((state: any) => state.main_menu.menu);

  const classes = className ? 'main-template ' + className : 'main-template';
  return (
    <Layout className={classes}>
      {!headerOff && <MainHeader />}
      <section className="main-template__section">
        {!leftSiderOff && menu.length > 0 && <MainMenu />}
        <div className="main-template__content">{children}</div>
      </section>
      <ModalQuestions />
      <ModalRecQuestions />
      <ProgressModal propgressPopover={propgressPopover} />
    </Layout>
  );
};

export default MainTemplate;
