/*
 *
 * Therapists reducer
 *
 */
const initialState = {
  items: [],
  groups: [],
  sessions: [],
  list: [],
  item: {},
  count: 0,
  loader: false,
  loaderModal: false,
  loaderReminders: false,
  zoomClients: [],
  optionsIsReceived: '',
  rowIndex: undefined,
  params: {
    page: 1,
    per_page: 10,
    name: '',
  },
  sessionsParams: {
    page: 1,
    per_page: 5,
  },
  totalCount: 0,
};

export type therapistsInitStateType = typeof initialState;

interface therapistsActionTypes {
  type: string;
  payload: {
    items: [];
    groups: [];
    sessions: [];
    list: [];
    item: any;
    count: number;
    loader: boolean;
    loaderModal: boolean;
    loaderReminders: boolean;
    zoomClients: [];
    optionsIsReceived: string;
    rowIndex?: number;
    params: {
      page: number;
      per_page: number;
      name: string;
    };
    sessionsParams: {
      page: number;
      per_page: number;
    };
    totalCount: number;
  };
}

const therapists = (state = initialState, action: therapistsActionTypes): therapistsInitStateType => {
  switch (action.type) {
    case 'THERAPISTS':
      return Object.assign({}, state, {
        items: action.payload.items,
        count: action.payload.count,
      });
    case 'THERAPIST':
      return Object.assign({}, state, {
        item: action.payload.item,
      });
    case 'THERAPISTS_LIST':
      return Object.assign({}, state, {
        list: action.payload.list,
      });
    case 'THERAPIST_ZOOM_CLIENTS':
      return Object.assign({}, state, {
        zoomClients: action.payload.zoomClients,
      });
    case 'THERAPISTS_LOADER':
      return Object.assign({}, state, {
        loader: action.payload.loader,
      });
    case 'THERAPISTS_LOADER_MODAL':
      return Object.assign({}, state, {
        loaderModal: action.payload.loaderModal,
      });
    case 'THERAPIST_LOADER_REMINDERS':
      return Object.assign({}, state, {
        loaderReminders: action.payload.loaderReminders,
      });
    case 'SET_SESSIONS_HISTORY_PARAMS': {
      return Object.assign({}, state, {
        sessionsParams: action.payload.params,
      });
    }
    case 'THERAPISTS_PARAMS':
      return Object.assign({}, state, {
        params: action.payload.params,
      });
    case 'THERAPISTS_ROW_INDEX':
      return Object.assign({}, state, {
        rowIndex: action.payload.rowIndex,
      });
    case 'THERAPIST_GROUPS':
      return Object.assign({}, state, {
        groups: action.payload.groups,
      });
    case 'THERAPIST_SESSIONS':
      return Object.assign({}, state, {
        sessions: action.payload.sessions,
        totalCount: action.payload.totalCount,
      });
    case 'SET_THERAPIST_FILTER_OPTIONS':
      return Object.assign({}, state, {
        optionsIsReceived: action.payload.optionsIsReceived,
      });

    default:
      return state;
  }
};

export default therapists;
