import React, { useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Modal, Spin } from 'antd';
import { useForm } from 'react-hook-form';
// componets
import EditTherapistContainer from '../../../../../molecules/modals/edit/editTherapist';
// types
import { stateType } from '../../../../../../types/types';
// icons
import { ReactComponent as CloseIcon } from 'assets/image/account/close4.svg';

// styles
import '../style.scss';
// types
import { FormDataEditTherapist } from '../../../../../../types/edit';
import { Bio } from '../../../../../molecules/inputsBlocks/edit';

const EditTherapistBioModal: React.FC<any> = ({ btnClass, icon, name }: any) => {
  const [visible, setVisible] = useState(false);

  const loader = useSelector((state: stateType) => state.therapists.loaderModal, shallowEqual);

  const open = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <button className={btnClass} onClick={open}>
        {icon}
        <span>{name}</span>
      </button>
      {visible && (
        <Modal
          visible={visible}
          onOk={handleOk}
          closable={true}
          onCancel={handleCancel}
          centered
          maskTransitionName="none"
          footer={null}
          className="edit-group-modal"
          closeIcon={<CloseIcon />}
        >
          <Spin spinning={loader}>
            <ModalContainer handleCancel={handleCancel} />
          </Spin>
        </Modal>
      )}
    </>
  );
};

export default EditTherapistBioModal;

const ModalContainer = ({ handleCancel }: any) => {
  const {
    handleSubmit,
    setValue,
    register,
    control,
    formState: { errors },
  } = useForm<FormDataEditTherapist>();

  const therapists: any = useSelector((state: stateType) => state.therapists.item, shallowEqual);

  return (
    <EditTherapistContainer
      titleName="About Therapist Bio"
      buttonName="Save"
      handleCancel={handleCancel}
      setValue={setValue}
      handleSubmit={handleSubmit}
      isCancel
    >
      <Bio
        errors={errors.biography ? errors.biography.message : undefined}
        register={register}
        control={control}
        defaultValue={therapists.biography}
        label="Edit the therapist’s bio below"
      />
    </EditTherapistContainer>
  );
};
