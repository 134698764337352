/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { AxiosResponse } from 'axios';
// config
import http from '../instance';

export const apiUser = {
  self: async (role: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.get(`${role}/self`);
  },
};
