import { useContext } from 'react';
import { UserDataContext } from '../../components/video/UserDataProvider/UserDataProvider';

export default function useUserDataContext() {
  const context = useContext(UserDataContext);
  if (!context) {
    throw new Error('useUserDataContext must be used within a UserDataProvider');
  }
  return context;
}
