import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Modal, Spin } from 'antd';
// actions
import progressAction from '../../../../../redux/actions/progress';
import mainMenuAction from '../../../../../redux/actions/main_menu';
// types
import { stateType } from 'types/types';
// icons
import { ReactComponent as CloseIcon } from 'assets/image/account/close4.svg';
// styles
import './style.scss';

const ProgressModal: React.FC<any> = ({ propgressPopover }: any) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    propgressPopover && setVisible(propgressPopover);
  }, [propgressPopover]);

  const profile: any = useSelector((state: stateType) => state.user.profile, shallowEqual);
  const dispatch = useDispatch();

  const progressScoreType = (value: boolean) => {
    dispatch(mainMenuAction.propgressPopover(value));
  };

  const loader = useSelector((state: stateType) => state.progress.loaderModal, shallowEqual);

  const onClose = () => {
    setVisible(false);
    progressScoreType(false);
  };

  const handleCancel = () => {
    dispatch(progressAction.hold_over('progress-score'));
    onClose();
  };

  return visible ? (
    <Modal
      visible={visible}
      closable={true}
      onCancel={handleCancel}
      centered
      maskTransitionName="none"
      footer={null}
      className="check-in-modal"
      maskClosable={false}
      closeIcon={<CloseIcon />}
    >
      <Spin spinning={loader}>
        <ModalContainer type={profile.progressScoreType} setVisible={setVisible} reset={handleCancel} />
      </Spin>
    </Modal>
  ) : null;
};

const ModalContainer = ({ type, setVisible, reset }: any) => {
  const dispatch = useDispatch();

  const start = () => {
    dispatch(progressAction.start());
    setVisible(false);
  };

  const initText = () => {
    switch (type) {
      case 1:
        return (
          <>
            <div className="progress-modal-container__title">It’s time for your first check in</div>
            <div className="progress-modal-container__text">
              As part of our standard of care, we do active check ins with you to see how everything is going. Prior to
              your first session, please answer this brief set of questions, so your therapist can have a better sense
              of the most pressing challenges you’re facing.
            </div>
            <div className="progress-modal-container__sub-text">
              We’ll follow up with you every 3-weeks so you can track your progress, see what’s working and what needs
              improvement, and your therapist can understand how to best continually help you.
            </div>
          </>
        );

      case 2:
        return (
          <>
            <div className="progress-modal-container__title">It’s time for your next check in</div>
            <div className="progress-modal-container__text">
              Complete this short set of questions to continue tracking how your symptoms are changing over time. You
              and your therapist will use this information to continually ensure you are reaching your goals.
            </div>
          </>
        );

      default:
        break;
    }
  };
  return (
    <div className="progress-modal-container">
      {initText()}
      <div className="progress-modal-container__actions">
        <button className="btn-form" onClick={start}>
          Start
        </button>
        <div className="progress-modal-container__later" onClick={reset}>
          Do it later
        </div>
      </div>
    </div>
  );
};

export default ProgressModal;
