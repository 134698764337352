import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DatePicker, Select, Spin } from 'antd';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import moment from 'moment-timezone';
// style
import './style.scss';
// actions
import intakeConsultationAction from '../../../../redux/actions/intake_consultation';
import userActions from '../../../../redux/actions/user';
// types
import { stateType } from '../../../../types/types';
// components
import InputField from '../../../molecules/inputField';
// configs
import { CaretDownOutlined } from '@ant-design/icons';

interface FormData {
  date: any;
  time: any;
}

const FirstConsultation: FC = () => {
  const {
    handleSubmit,
    control,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<FormData>();
  const [disabledTime, setDisabledTime] = useState(false);
  const [currentMonth, setCurrentMonth] = useState('');

  const dispatch = useDispatch();
  const history = useHistory();

  const consultationDate = watch('date');

  const loader = useSelector((state: stateType) => state.intakeConsultation.loader);
  const loaderModal = useSelector((state: stateType) => state.intakeConsultation.loaderModal);
  const times = useSelector((state: stateType) => state.intakeConsultation.times);
  const dates: any = useSelector((state: stateType) => state.intakeConsultation.dates);

  const createDateErr = (e: any) => {
    setError('date', e);
  };

  const clearDateErr = () => {
    clearErrors('date');
  };

  useEffect(() => {
    if (!!consultationDate) {
      dispatch(
        intakeConsultationAction.getTime(
          { yearMonth: null, date: moment(consultationDate).format('YYYY-MM-DD') },
          clearDateErr,
          createDateErr,
        ),
      );
    } else {
      dispatch(intakeConsultationAction.setTimes([]));
    }
  }, [consultationDate]);

  useEffect(() => {
    if (!!consultationDate && !!times.length) {
      setDisabledTime(false);
    } else {
      setDisabledTime(true);
    }
  }, [times]);

  useEffect(() => {
    if (currentMonth) {
      dispatch(intakeConsultationAction.getDates({ yearMonth: currentMonth, date: null }));
    }
  }, [currentMonth]);

  const onSubmit = (data: FormData) => {
    dispatch(intakeConsultationAction.create({ datetime: data.time }, () => userActions.update(undefined, dispatch)));
  };

  return (
    <Spin spinning={loader || loaderModal}>
      <div className="custom-style">
        <div className="first-consultation box">
          <div className="first-consultation__logo" onClick={() => history.push('/login-customer')} />
          <div className="first-consultation__container">
            <h1 className="first-consultation__title">Welcome to Grouport!</h1>
            <p className="first-consultation__subtitle">
              <b>The last step </b>is an individual consultation. Schedule below!
            </p>
            <div className="first-consultation__about">
              <div className="first-consultation__about__content">
                <p className="first-consultation__about__text">
                  Choose a time to schedule a one-on-one consultation with one of our trained Grouport intake
                  coordinators. During this initial consultation, the intake coordinator will ensure that the group you
                  join is the best fit for you.
                </p>
              </div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className="first-consultation__form">
              <InputField
                name="date"
                label="Choose Date"
                errors={errors.date ? errors.date.message : undefined}
                icon={false}
              >
                <Controller
                  control={control}
                  name="date"
                  rules={{ required: 'choose at least one option' }}
                  render={({ field: { onChange } }) => (
                    <DatePicker
                      dropdownClassName="dropdown-datepicker first-consultation__dropdown-datepicker"
                      className="input-datepicker"
                      onChange={onChange}
                      placeholder="Select Consultation Date"
                      format="MMMM Do, YYYY"
                      suffixIcon={<CaretDownOutlined />}
                      showToday={false}
                      mode={'date'}
                      disabledDate={(current) => {
                        return current && !dates.some((item: any) => moment(item.date).isSame(current, 'day'));
                      }}
                      onPanelChange={(val) => setCurrentMonth(val.format('YYYY-MM'))}
                      onOpenChange={(isOpen) => isOpen && !currentMonth && setCurrentMonth(moment().format('YYYY-MM'))}
                    />
                  )}
                />
              </InputField>
              {!disabledTime && (
                <InputField
                  name="time"
                  label="Choose Time"
                  errors={errors.time ? errors.time.message : undefined}
                  icon={false}
                >
                  <Controller
                    control={control}
                    name="time"
                    rules={{ required: 'choose at least one option' }}
                    render={({ field: { onChange } }) => (
                      <Select
                        className="form-select"
                        dropdownClassName="form-dropdown"
                        placeholder="Select Consultation Time"
                        style={{ width: '100%' }}
                        onChange={(value: any) => {
                          onChange(value);
                        }}
                        options={times}
                        disabled={disabledTime}
                      />
                    )}
                  />
                </InputField>
              )}
              <button className="btn-form" disabled={disabledTime}>
                Continue
              </button>
            </form>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default FirstConsultation;
