import React, { FC, useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
// types
import { stateType } from '../../types/types';
// components
import LoadingPage from '../../components/app/loading';

const ResetPasswordImport: FC = () => {
  const profile = useSelector((state: stateType) => state.user.profile, shallowEqual);
  const history = useHistory();

  useEffect(() => {
    sessionStorage.setItem('email', profile.email || '');
    history.push(`/new-password?token=${profile?.resetPasswordToken}`);
  }, []);

  return <LoadingPage />;
};

export default ResetPasswordImport;
