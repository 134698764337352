import React, { useState, useRef } from 'react';
import AboutDialog from '../../AboutDialog/AboutDialog';
import BackgroundIcon from '../../../../icons/BackgroundIcon';
import DeviceSelectionDialog from '../../DeviceSelectionDialog/DeviceSelectionDialog';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoIconOutlined from '../../../../icons/InfoIconOutlined';
import MoreIcon from '../../../../icons/MoreIcon';
import StartRecordingIcon from '../../../../icons/StartRecordingIcon';
import StopRecordingIcon from '../../../../icons/StopRecordingIcon';
import SearchIcon from '@material-ui/icons/Search';
import SettingsIcon from '../../../../icons/SettingsIcon';
import {
  Button,
  styled,
  Theme,
  useMediaQuery,
  Menu as MenuContainer,
  MenuItem,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { isSupported } from '@twilio/video-processors';

import { useAppState } from '../../../../state';
import useChatContext from '../../../../hooks/useChatContext/useChatContext';
import useIsRecording from '../../../../hooks/useIsRecording/useIsRecording';
import useVideoContext from '../../../../hooks/useVideoContext/useVideoContext';
import FlipCameraIcon from '../../../../icons/FlipCameraIcon';
import useFlipCameraToggle from '../../../../hooks/useFlipCameraToggle/useFlipCameraToggle';
import { VideoRoomMonitor } from '@twilio/video-room-monitor';
import { shallowEqual, useSelector } from 'react-redux';
import useUserIsHost from '../../../../hooks/useUserIsHost/useUserIsHost';
import webinar from '../../../../pages/video/webinar';
import BackgroundSelectionDialog from '../../BackgroundSelectionDialog/BackgroundSelectionDialog';
import ChangeNameDialog from '../../CangeNameDialog/ChangeNameDialog';

const useStyles = makeStyles((theme: Theme) => ({
  menuContainer: {
    borderRadius: 16,
  },
  menuItem: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
  },
}));

export const IconContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  width: '1.5em',
  marginRight: '0.3em',
});

export default function Menu(props: { buttonClassName?: string }) {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const cssClasses = useStyles();

  const [aboutOpen, setAboutOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [changeNameOpen, setChangeNameOpen] = useState(false);

  const { isFetching, updateRecordingRules, roomType, meetingType } = useAppState();
  const { setIsChatWindowOpen } = useChatContext();
  const isRecording = useIsRecording();
  const { room, isBackgroundSelectionOpen, setIsBackgroundSelectionOpen } = useVideoContext();
  const isHost = useUserIsHost();
  const localParticipant = room!.localParticipant;

  const anchorRef = useRef<HTMLButtonElement>(null);
  const { flipCameraDisabled, toggleFacingMode, flipCameraSupported } = useFlipCameraToggle();
  const role = useSelector((state: any) => state.user.profile.role, shallowEqual);

  return (
    <>
      <Button
        onClick={() => setMenuOpen((isOpen) => !isOpen)}
        ref={anchorRef}
        className={props.buttonClassName}
        data-cy-more-button
      >
        <MoreIcon />
      </Button>
      <MenuContainer
        classes={{ paper: 'borderRadius: 16px' }}
        open={menuOpen}
        onClose={() => setMenuOpen((isOpen) => !isOpen)}
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: isMobile ? -55 : 'bottom',
          horizontal: 'center',
        }}
      >
        <MenuItem onClick={() => setSettingsOpen(true)}>
          <span className={cssClasses.menuItem}>Audio & Video Settings</span>
        </MenuItem>

        {isSupported && (
          <MenuItem
            onClick={() => {
              setIsBackgroundSelectionOpen(true);
              setIsChatWindowOpen(false);
              setMenuOpen(false);
            }}
          >
            <span className={cssClasses.menuItem}>Backgrounds</span>
          </MenuItem>
        )}

        {flipCameraSupported && (
          <MenuItem disabled={flipCameraDisabled} onClick={toggleFacingMode}>
            <span className={cssClasses.menuItem}>Flip Camera</span>
          </MenuItem>
        )}

        {meetingType == 'webinar' && (role == 'ADMIN' || role == 'THERAPIST') && (
          <MenuItem
            disabled={isFetching}
            onClick={() => {
              setMenuOpen(false);
              if (isRecording) {
                updateRecordingRules(room!.sid, [{ type: 'exclude', all: true }]);
              } else {
                if (meetingType == 'webinar') {
                  updateRecordingRules(room!.sid, [{ type: 'include', publisher: localParticipant.identity }]);
                } else {
                  updateRecordingRules(room!.sid, [{ type: 'include', all: true }]);
                }
              }
            }}
            data-cy-recording-button
          >
            <span className={cssClasses.menuItem}>{isRecording ? 'Stop' : 'Start'} Recording</span>
          </MenuItem>
        )}

        {role != 'CUSTOMER' && (
          <MenuItem
            onClick={() => {
              VideoRoomMonitor.toggleMonitor();
              setMenuOpen(false);
            }}
          >
            <span className={cssClasses.menuItem}>Room Monitor</span>
          </MenuItem>
        )}

        <MenuItem
          onClick={() => {
            setChangeNameOpen(true);
            setMenuOpen(false);
          }}
        >
          <span className={cssClasses.menuItem}>Change Name</span>
        </MenuItem>
      </MenuContainer>
      <AboutDialog
        open={aboutOpen}
        onClose={() => {
          setAboutOpen(false);
          setMenuOpen(false);
        }}
      />
      <ChangeNameDialog
        open={changeNameOpen}
        onClose={() => {
          setChangeNameOpen(false);
        }}
      />
      <DeviceSelectionDialog
        open={settingsOpen}
        onClose={() => {
          setSettingsOpen(false);
          setMenuOpen(false);
        }}
        openBackgroundDialog={() => {
          setSettingsOpen(false);
          setIsBackgroundSelectionOpen(true);
          setMenuOpen(false);
        }}
      />
      <BackgroundSelectionDialog
        open={isBackgroundSelectionOpen}
        onClose={() => {
          setIsBackgroundSelectionOpen(false);
          setMenuOpen(false);
        }}
        openDeviceDialog={() => {
          setIsBackgroundSelectionOpen(false);
          setSettingsOpen(true);
          setMenuOpen(false);
        }}
      />
    </>
  );
}
