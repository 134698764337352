import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Modal, Spin } from 'antd';
// components
import SmileScoreRange from 'components/molecules/SmileScoreRange';
import ArbitraryAnswer from 'components/molecules/groupQuestions/Answers/ArbitraryAnswer';
// actions
import questionsActions from 'redux/actions/questions';
import userActions from 'redux/actions/user';
// types
import { FlowType } from 'types/actions/common-types';
import { stateType } from 'types/types';
// style
import '../style.scss';
import './style.scss';
import eventAnalytics from '../../../../../redux/actions/analytics';

const ModalRecQuestions: React.FC = () => {
  const [visible, setVisible] = useState(false);
  const question: any = useSelector((state: stateType) => state.questions.item, shallowEqual);
  const status: string = useSelector((state: stateType) => state.questions.status, shallowEqual);
  const loader: boolean = useSelector((state: stateType) => state.questions.loader, shallowEqual);
  const state: string = useSelector((state: stateType) => state.user.state, shallowEqual);
  const role: string = useSelector((state: stateType) => state.user.profile.role, shallowEqual);

  const dispatch = useDispatch();

  const flowType = role === 'THERAPIST' ? FlowType.TherapistGrouportRecommendation : FlowType.GrouportRecommendation;

  useEffect(() => {
    if (state === 'recommendation') {
      dispatch(questionsActions.next(flowType));
      return setVisible(true);
    }

    if (status === '') {
      setVisible(false);
    }
  }, [state]);

  useEffect(() => {
    if (status === '') {
      setVisible(false);
    }
  }, [status]);

  const answerController = () => {
    switch (question.questionType) {
      case 'recommendation_rating':
        return <Rating question={question} flowType={flowType} />;

      case 'reason':
        return <Arbitrary question={question} flowType={flowType} />;

      default:
        break;
    }
  };

  return (
    <Modal visible={visible} closable={false} footer={null} className="questions-rating-modal">
      <Spin spinning={loader}>{!loader && answerController()}</Spin>
    </Modal>
  );
};

export default ModalRecQuestions;

const Rating = ({ question, flowType }: any) => {
  const [rating, setRating] = useState<number>(1);

  const dispatch = useDispatch();

  const click = (value: number) => {
    setRating(value);
    dispatch(eventAnalytics.analytics('nps-first'));
  };

  // useEffect(() => {
  // }, []);

  const submit = () => {
    const data = {
      rating: rating,
      questionId: question.id,
      answerType: question.answerType,
    };
    dispatch(
      questionsActions.answer(flowType, data, () => {
        return dispatch(questionsActions.next(flowType));
      }),
    );
    dispatch(eventAnalytics.analytics('nps-second', { rating }));
  };
  return (
    <div className="rating-container">
      <div className="rating-container__header">How likely are you to recommend Grouport to a friend or colleague?</div>
      <div className="rating-container__body center">
        <SmileScoreRange onSelect={click} />
      </div>
      <div className="rating-container__footer">
        <button className="btn-form" onClick={submit}>
          Ok
        </button>
      </div>
    </div>
  );
};

const Arbitrary = ({ question, flowType }: any) => {
  const [answerText, setAnswerText] = useState('');

  const dispatch = useDispatch();

  const submit = () => {
    const data = {
      answerText: answerText,
      questionId: question.id,
      answerType: question.answerType,
    };
    dispatch(
      questionsActions.answer(flowType, data, () => {
        userActions.state('group_joined');
        questionsActions.status('');
        return;
      }),
    );
    dispatch(eventAnalytics.analytics('nps-last'));
  };
  return (
    <div className="rating-container">
      <div className="rating-container__header">{question.question}</div>
      <div className="rating-container__body">
        <ArbitraryAnswer setAnswerText={setAnswerText} placeholder={'Type your answer here…'} />
      </div>
      <div className="rating-container__footer">
        <button className="btn-form" onClick={submit} disabled={answerText.length === 0}>
          Submit
        </button>
      </div>
    </div>
  );
};
