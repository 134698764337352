import _ from 'lodash';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { stateType } from 'types/types';
import React, { useEffect, useMemo } from 'react';
import Moment from 'react-moment';
import superbillAction from 'redux/actions/superbill';
import { useHistory } from 'react-router';
import accountAction from 'redux/actions/account';

export const AdminClientSuperbillList: React.FC<any> = (id: string) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const paymentInfo: any = useSelector((state: stateType) => state.superbill.info, shallowEqual);
  const npi = paymentInfo?.medicalGroup?.therapist.npi;
  const info: any = useSelector((state: stateType) => state.clients.item, shallowEqual);

  const getTotalPrice = (start: Date, end: Date) => {
    let price = 0;

    if (!paymentInfo.payments) return 0;

    for (let i = 0; i < paymentInfo.payments.length; i++) {
      const p = paymentInfo.payments[i];
      const pdate = new Date(p.date);
      if (pdate.getTime() >= start.getTime() && pdate.getTime() <= end.getTime()) {
        price = p.amount;
        break;
      }
    }
    return price;
  };

  const dateArray = useMemo(() => {
    if (!paymentInfo.startDate) return [];
    const nowDate = new Date();
    const array = [];
    let date = new Date(paymentInfo.startDate);
    for (let i = 0; date.getTime() < nowDate.getTime(); i++) {
      const startDate = new Date(date);
      const nextDate = new Date(date.setMonth(date.getMonth() + 1));
      let lastDate = new Date(nextDate);
      lastDate = new Date(lastDate.setDate(lastDate.getDate() - 1));
      if (getTotalPrice(startDate, lastDate)) {
        array.push({ start: startDate, end: lastDate });
      }
      date = nextDate;
    }
    return array.reverse();
  }, [paymentInfo.startDate]);

  useEffect(() => {
    _.isEmpty(paymentInfo) && dispatch(superbillAction.getAdminClientPaymentInfo(id));
  }, []);

  const goToDetails = (startDate: Date, endDate: Date) => {
    dispatch(superbillAction.selectDate(startDate, endDate));
    history.push('/client-superbill-details');
  };
  return (
    <div className="superbills">
      <div className="superbills__block">
        {npi && dateArray.length && info?.icd10 ? (
          dateArray.map((it, key) => {
            return (
              <div key={key} className="superbills__item" onClick={() => goToDetails(it.start, it.end)}>
                <div>
                  <Moment date={it.start} format="MMM DD, YYYY" />
                  <span className="superbills__dash">-</span>
                  <Moment date={it.end} format="MMM DD, YYYY" />
                </div>
                <div className="superbill-next-icon" />
              </div>
            );
          })
        ) : (
          <p className="superbills__item">No available superbills</p>
        )}
      </div>
    </div>
  );
};
