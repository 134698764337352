import React, { FC, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
// types
import { stateType } from 'types/types';
// styles
import './style/avatar.scss';
import cn from 'classnames';

interface IProfileAvatar {
  aside?: boolean;
}

const ProfileAvatar: FC<IProfileAvatar> = ({ aside }: IProfileAvatar) => {
  const [load, setLoad] = useState(false);
  const profile: any = useSelector((state: stateType) => state.user.profile);
  const page = useSelector((state: stateType) => state.app.page, shallowEqual);

  const onLoad = () => {
    setLoad(true);
  };

  return profile?.avatar?.url && !aside ? (
    <div className="profile-avatar">
      <img src={profile.avatar.url} alt="avatar" onLoad={onLoad} />
    </div>
  ) : (
    <div className={cn('profile-fake-avatar', { active: page === 'Profile' || !aside })}>
      {profile.firstname[0]}
      {profile.lastname[0]}
    </div>
  );
};

export default ProfileAvatar;
