export function displayName(name: string) {
  return name.replace(/(.*)( [0-9\-]+)/, '$1');
}

export function initials(name: string) {
  const words = name.trim().split(' ');
  let initials = words[0].length > 0 ? words[0][0].toUpperCase() : '';
  initials += words.length > 1 && words[1].length > 0 ? words[1][0].toUpperCase() : '';
  return initials;
}
