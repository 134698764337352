import React, { FC, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
// actions
import changeGroupActions from 'redux/actions/change_group';
// components
import CloseChangeBtn from '../CloseQuestion';
// icons
import icon from 'assets/image/group/around.png';
import cancelGroupActions from 'redux/actions/cancel_group';
import eventAnalytics from '../../../../redux/actions/analytics';

interface ChangeGroupBefore {
  isReschedule?: boolean;
}

const ChangeGroupBefore: React.FC<ChangeGroupBefore> = (isReschedule) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changeGroupActions.progress(0));
  }, []);

  const nextAnswer = useCallback(() => dispatch(changeGroupActions.next()), []);

  const onClick = () => {
    dispatch(cancelGroupActions.cancelRenewal('change-group', nextAnswer));
    !isReschedule && dispatch(eventAnalytics.analytics('change-group-continue'));
  };

  return (
    <div className="change-group-container">
      <div>
        <CloseChangeBtn />
      </div>
      <img className="change-group-container__before-image" src={icon} alt="logo" />
      <div className="change-group-container__main-title">Find a New Group</div>
      <div className="change-group-container__desc-text">
        Finding the right group fit is key to making progress. Just 5 quick questions, so we can identify your needs and
        make sure we find a group that’s better for you.
      </div>
      <button onClick={onClick} className="btn-form">
        Continue
      </button>
    </div>
  );
};

export default ChangeGroupBefore;
