/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { put, call, select } from 'redux-saga/effects';
import queryString from 'query-string';

// api
import { api } from '../../api/history';
// actions
import historyAction from '../actions/history';
import showError from './showError';
import appAction from '../actions/app';

export function* sagaGetHistory(action) {
  yield put(historyAction.loader(true));
  const getRole = (state) => state.user.profile.role;
  const getParams = (state) => state.history.params;
  const getHistory = (state) => state.history.items;
  const role = yield select(getRole);
  const params = yield select(getParams);
  const history = yield select(getHistory);

  try {
    const response = yield call(api.get, role.toLowerCase(), action.id, queryString.stringify(params));

    yield put(historyAction.loader(false));

    if (params.page > 1) {
      yield historyAction.create([...history, ...response.data.data.items], response.data.data.totalCount);
    } else {
      yield historyAction.create(response.data.data.items, response.data.data.totalCount);
    }
  } catch (e) {
    console.log(e);
    yield put(historyAction.loader(false));
    showError();
  }
}

export function* sagaGetClietnGroupHistory(action) {
  yield put(historyAction.loader(true));
  const getRole = (state) => state.user.profile.role;
  const getParams = (state) => state.history.historyGroupsParams;
  const params = yield select(getParams);
  const role = yield select(getRole);

  try {
    const response = yield call(api.getHistory, action.id, role.toLowerCase(), queryString.stringify(params));

    yield put(historyAction.loader(false));

    yield historyAction.setHistoryGroups(response.data.data.items, response.data.data.totalCount);
  } catch (e) {
    yield put(historyAction.loader(false));
    showError();
    console.log(e);
  }
}

export function* sagaGetClietnSessionsHistory(action) {
  yield put(historyAction.loader(true));
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);
  const getParams = (state) => state.history.historySessionsParams;
  const params = yield select(getParams);

  try {
    const response = yield call(api.getSessions, action.id, role.toLowerCase(), queryString.stringify(params));

    yield put(historyAction.loader(false));

    yield historyAction.setHistorySessions(response.data.data.items, response.data.data.totalCount);
  } catch (e) {
    yield put(historyAction.loader(false));
    showError();
    console.log(e);
  }
}
