import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Spin } from 'antd';
// componets
// actions
import groupsAction from '../../../../redux/actions/groups';
// types
import { stateType } from '../../../../types/types';
// styles
import './style.scss';
// icons
import { ReactComponent as CloseIcon } from 'assets/image/account/close4.svg';
import Icon from '@ant-design/icons';
import { ReactComponent as IconEditBlue } from '../../../../assets/image/account/edit-blue.svg';
import FormEditEvent from '../../forms/edit/events';

interface EditEventInterface {
  id: number;
  isDetails?: boolean;
}

const EditEvent: React.FC<EditEventInterface> = ({ id, isDetails }: EditEventInterface) => {
  const [visible, setVisible] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isDetails) {
      dispatch(groupsAction.getTopics());
      dispatch(groupsAction.getTherapists());
    }
  }, []);

  const loader = useSelector((state: stateType) => state.events.loaderModal);

  const open = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <button className="edit-event" type="button" onClick={open}>
        <Icon component={IconEditBlue} className="edit-event_icon" />
        <span className="edit-event_text">Edit</span>
      </button>
      {visible && (
        <Modal
          visible={visible}
          onOk={handleOk}
          closable={true}
          onCancel={handleCancel}
          centered
          maskTransitionName="none"
          footer={null}
          className="edit-event-modal"
          closeIcon={<CloseIcon />}
        >
          <Spin spinning={loader}>
            <ModalContainer setVisible={setVisible} id={id} />
          </Spin>
        </Modal>
      )}
    </>
  );
};

export default EditEvent;

const ModalContainer = ({ setVisible, id }: any) => {
  return (
    <div className="edit-event-container">
      <div className="edit-event-container__title">Edit Event</div>
      <FormEditEvent setVisible={setVisible} id={id} />
    </div>
  );
};
