const errorsMessages = {
  authErrors: {
    BAD_CREDENTIALS: 'Credentials are incorrect.',
    EMAIL_NOT_FOUND: 'Email not found.',
  },
  accountErrors: {
    INCORRECT_PASSWORD: 'Password is incorrect.',
    EXIST_EMAIL: 'Email already in use.',
  },
};

export const { authErrors, accountErrors } = errorsMessages;

export default errorsMessages;
