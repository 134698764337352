import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import Moment from 'react-moment';
import { useHistory } from 'react-router-dom';
import { Spin } from 'antd';
// components
import AdminChangeGroup from '../../../modals/adminChangeGroup';
import UserStatus from '../../../../atoms/status/user';
import PauseClient from '../../../modals/pause/client';
import ArchiveClient from '../../../modals/archive/client';
import CancelClient from '../../../modals/cansel/client';
import RestartSubscription from 'components/organisms/modals/restart/client';
import UnpauseClient from '../../../modals/unpause/client';
import EnabledNotifications from '../../../../molecules/enabledNotification';
import ShareAccount from '../../../shareAccount';
import UnarchiveClient from '../../../modals/unarchive';
// configs
import { week_days, timeConvertor } from '../../../../../configs/tables/admin/clients';
import { APP } from '../../../../../configs/app';
// icons
import Icon from '@ant-design/icons';
import { ReactComponent as IconUser } from '../../../../../assets/image/group/user.svg';
import { ReactComponent as IconTime } from '../../../../../assets/image/group/time.svg';
// types
import { stateType } from '../../../../../types/types';
import { CopyLinkRole } from '../../../../../types/actions/common-types';
// styles
import './style.scss';

const ClientCard: React.FC = () => {
  const item: any = useSelector((state: stateType) => state.clients.item, shallowEqual);
  const loader: any = useSelector((state: stateType) => state.clients.loaderReminders, shallowEqual);

  return (
    <div className="client-card">
      <div className="client-card__avatar">
        {item?.avatar?.url ? (
          <img src="#" alt="avatar" className="client-card__avatar-img" />
        ) : (
          <div className="client-card__avatar-fake">
            {item.firstname[0]}
            {item.lastname[0]}
          </div>
        )}
      </div>
      <div className="client-card__line" />
      <div className="client-card__title-name">
        <span>
          {item.firstname} {item.lastname}
        </span>
        <ShareAccount role={CopyLinkRole.Customer} id={item.id} />
      </div>
      <div className="client-card__item">
        <div className="client-card__label">Status</div>
        <div className="client-card__value">
          <UserStatus status={item.customerMarkers} />
          {item?.subscriptionData?.status?.value === 6 && item.status.value === 1 ? (
            <p style={{ marginTop: '15px', color: '#d33722' }}>Will be cancelled soon, but still active</p>
          ) : null}
        </div>
      </div>
      <div className="client-card__item">
        <div className="client-card__label">Date Joined</div>
        <div className="client-card__value">
          {item?.joinedDate && <Moment format="MMMM DD, YYYY">{item.joinedDate}</Moment>}
        </div>
      </div>
      {APP.MODE.IS_STAGE && (
        <div className="client-card__item">
          <Spin spinning={loader}>
            <EnabledNotifications classes="client-card" item={item} />
          </Spin>
        </div>
      )}

      {item.status.value !== 4 ? (
        <div className="client-card__block">
          <div className="client-card__label">Group</div>
          <CardDetails>
            <div className="client-card__block">
              {!item.features.canRestart && <AdminChangeGroup id={item.id} />}
              <CardDetailsButtons id={item.id} status={item.status} features={item.features} />
            </div>
          </CardDetails>
        </div>
      ) : (
        <div className="client-card__block">
          <CardDetails>
            <div className="client-card__block">
              <CardDetailsButtons id={item.id} status={item.status} features={item.features} />
            </div>
          </CardDetails>
        </div>
      )}
    </div>
  );
};

export default ClientCard;

export const CardDetails: React.FC = ({ children }: any) => {
  const { medicalGroup }: any = useSelector((state: stateType) => state.clients.item, shallowEqual);

  const history = useHistory();

  const groupRedirect = () => {
    history.push(`/all_groups/${medicalGroup.id}`);
  };

  return (
    <div className="card-details">
      {medicalGroup && (
        <div className="card-details__info">
          <div className="card-details__avatar">
            {medicalGroup?.avatar ? (
              <img src={medicalGroup.avatar?.url} alt="" className="card-details__avatar-img" />
            ) : (
              <div>{medicalGroup.groupTopic.name[0].toUpperCase()}</div>
            )}
          </div>
          <div className="card-details__desc">
            <div className="card-details__title" onClick={groupRedirect}>
              {medicalGroup.groupTopic.name} Group
            </div>
            <div className="card-details__name">
              <div className="card-details__icon">
                <Icon component={IconUser} style={{ fontSize: '14px' }} />
              </div>
              <div className="card-details__text">Therapist: &nbsp;</div>
              {medicalGroup?.therapist?.title
                ? `${medicalGroup?.therapist?.title}. ${medicalGroup?.therapist?.lastname}`
                : `${medicalGroup?.therapist?.firstname} ${medicalGroup?.therapist?.lastname}`}
            </div>
            <div className="card-details__date">
              <div className="card-details__icon">
                <Icon component={IconTime} style={{ fontSize: '14px' }} />
              </div>
              <div className="card-details__text">
                {week_days[medicalGroup.dayOfWeek - 1]}, {timeConvertor(medicalGroup.start)} -{' '}
                {timeConvertor(medicalGroup.end)}
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="card-details__childrens">{children}</div>
    </div>
  );
};

const CardDetailsButtons = ({ id, features }: any) => {
  return (
    <div className="card-details-buttons">
      {features.canRestart && <RestartSubscription />}

      {!features.canRestart && (
        <>
          {features.canPause && <PauseClient />}

          {features.canUnPause && <UnpauseClient />}

          {features.canCancel && <CancelClient id={id} />}
        </>
      )}
      {features.canArchive && <ArchiveClient />}
      {features.canUnArchive && <UnarchiveClient />}
    </div>
  );
};
