import React from 'react';
import _ from 'lodash';
// components
import { MembersMiniList } from '../../cards/members';
import StatusGroups from '../../../molecules/status/groups';
// helpers
import { week_days, timeConvertor, colorGenerator } from '../../../../configs/tables/admin/clients';
import { fixDayOfWeek } from '../../../../tools/helpFunctions';
// styles
import './style.scss';

const GroupsList: React.FC<any> = ({ groups }: any) => {
  return (
    <div className="groups-list">
      {_.isEmpty(groups) && <span>This Therapist has not yet active groups</span>}
      {groups && <GroupsListItems items={groups} />}
    </div>
  );
};

export default GroupsList;

const GroupsListItems = ({ items }: any) => {
  return (
    <div className="groups-list__items">
      {items &&
        items.map((item: any) => {
          return <GroupsListItem key={item.id} item={item} />;
        })}
    </div>
  );
};

const GroupsListItem = ({ item }: any) => {
  return (
    <div className="group-item">
      <div className="group-item__avatar">
        {item.avatar ? (
          <img src={item.avatar?.url} alt="" className="group-item__avatar-img" />
        ) : (
          <div className="group-item__avatar-txt">{item.groupTopic.name[0]}</div>
        )}
      </div>
      <div className="group-item__body">
        <div className="group-item__header">
          <div className="group-item__title">{item.groupTopic?.name}</div>
        </div>

        <div className="group-item__item">
          <div className="group-item__label">Day:</div>
          <div className="group-item__value">{week_days[fixDayOfWeek(item.dayOfWeek, item.start, 'to_ny') - 1]}</div>
        </div>

        <div className="group-item__item">
          <div className="group-item__label">Time:</div>
          <div className="group-item__value">
            {timeConvertor(item.start)}
            {' - '}
            {timeConvertor(item.end, 'type-time')} ET
          </div>
        </div>
        <div className="group-item__footer">
          <MembersMiniList
            members={item.members.map((item: any) => {
              return { id: item.id, firstname: item.firstname, lastname: item.lastname, color: colorGenerator() };
            })}
          />
          <StatusGroups status={item.status} />
        </div>
      </div>
    </div>
  );
};
