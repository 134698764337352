import React from 'react';

import './style.scss';
import RescheduleClientSession from '../../organisms/modals/reschedule/sessionClient';

const popupNotificationContainer: React.FC<any> = ({ data }: any) => {
  return (
    <div>
      <RescheduleClientSession session={data} showPopup />
    </div>
  );
};

export default popupNotificationContainer;
