import React, { FC } from 'react';
import { Select } from 'antd';

// icons

interface SearchSelectInterface {
  options: any;
  defaultValue?: any;
  change: (values: any) => void;
  placeholder: string;
  disabled: boolean;
  value?: any;
}

const EventsSearchSelect: FC<SearchSelectInterface> = ({
  options,
  defaultValue,
  change,
  placeholder,
  disabled,
  value,
}: SearchSelectInterface) => {
  const onChange = (values: any) => {
    change(values);
  };

  return (
    <Select
      className="form-select"
      dropdownClassName="form-dropdown"
      placeholder={placeholder}
      style={{ width: '100%' }}
      onChange={(value: any) => {
        onChange(value);
      }}
      value={value}
      options={options}
      allowClear
      showSearch
      suffixIcon={<div />}
      defaultValue={defaultValue}
      filterOption={(input, option: any): any => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      disabled={disabled}
    />
  );
};

export default EventsSearchSelect;
