import React, { useState } from 'react';
import { displayName } from '../Helpers/helpers';

type UserDataItem = {
  identity: string;
  name: string;
  isHost: boolean;
};

type UserDataContextType = {
  userData: Map<string, UserDataItem>;
  setUserData: (identity: string, name: string, isHost: boolean) => void;
  getUserData: (identity: string) => UserDataItem | undefined;
  setUserName: (identity: string, name: string) => void;
  setUserIsHost: (identity: string, isHost: boolean) => void;
  getUserName: (identity: string) => string;
};

export const UserDataContext = React.createContext<UserDataContextType>(null!);

export const UserDataProvider: React.FC = ({ children }) => {
  const [users, setUsers] = useState<Map<string, UserDataItem>>(new Map());

  const setUserData = (identity: string, name: string, isHost: boolean) => {
    setUsers((users) => {
      const newUsers = new Map(users);
      newUsers.set(identity, { identity, name, isHost });
      return newUsers;
    });
  };

  const setUserName = (identity: string, name: string) => {
    setUsers((users) => {
      const newUsers = new Map(users);
      const user = users.get(identity) || { identity, name: '', isHost: false };
      user.name = name;
      newUsers.set(identity, user);
      return newUsers;
    });
  };

  const setUserIsHost = (identity: string, isHost: boolean) => {
    setUsers((users) => {
      const newUsers = new Map(users);
      const user = users.get(identity) || { identity, name: '', isHost: false };
      newUsers.set(identity, { ...user, isHost });
      return newUsers;
    });
  };

  const getUserData = (identity: string) => {
    return users.get(identity);
  };

  const getUserName = (identity: string) => {
    return users.get(identity)?.name || displayName(identity);
  };

  return (
    <UserDataContext.Provider
      value={{ userData: users, getUserData, setUserData, setUserName, setUserIsHost, getUserName }}
    >
      {children}
    </UserDataContext.Provider>
  );
};
