/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/*
 * action creators
 */
import { appActionAccount, appActionAuth, appActionPage, appActionText, GetAccountStates } from '../../types/actions';
import { ActionCallback } from '../../types/actions/common-types';

interface appContainer {
  text: (value: { [text: string]: string }) => appActionText;
  page: (page: string) => appActionPage;
  auth: any;
  account: (value: string) => any;
  getHelpSlider: (callback?: ActionCallback) => any;
  connectToForum: (callback?: ActionCallback) => any;
  setReschedule: any;
  setHelpSlider: (list: []) => any;
  uploadFile: (data: any, callback?: ActionCallback) => any;
  setFile: (file: any) => any;
  uploadFileCreateEvent: (data: any, callback?: ActionCallback) => any;
  setUrl: (url: string) => any;
  setSupportStates: (value: []) => any;
  getSupportStates: (callback?: ActionCallback) => GetAccountStates;
}

const appAction: appContainer = {
  text(value: { [key: string]: string }): appActionText {
    return {
      type: 'APP_TEXT',
      payload: {
        text: value,
      },
    };
  },
  page(page: string): appActionPage {
    return {
      type: 'APP_PAGE',
      payload: {
        page: page,
      },
    };
  },
  auth(status: string): appActionAuth {
    return {
      type: 'APP_AUTH',
      payload: {
        auth: status,
      },
    };
  },
  setReschedule(data?: any): any {
    return {
      type: 'set_re',
      payload: {
        isReschedule: data,
      },
    };
  },
  account(value: string): appActionAccount {
    return {
      type: 'APP_ACCOUNT_TAB',
      payload: {
        account: value,
      },
    };
  },
  getHelpSlider(callback?: ActionCallback): any {
    return {
      type: 'GET_APP_HELP_SLIDER',
      callback,
    };
  },
  connectToForum(callback?: ActionCallback): any {
    return {
      type: 'APP_CONNECT_TO_FORUM',
      callback,
    };
  },
  setHelpSlider(list: []): any {
    return {
      type: 'APP_HELP_SLIDER',
      payload: {
        helpSlieder: list,
      },
    };
  },
  uploadFile(data: any, callback?: ActionCallback): any {
    return {
      type: 'APP_UPLOAD_FILE',
      data,
      callback,
    };
  },
  uploadFileCreateEvent(data: any, callback?: ActionCallback): any {
    return {
      type: 'APP_UPLOAD_FILE_ADMIN',
      data,
      callback,
    };
  },
  setFile(file: any): any {
    return {
      type: 'APP_FILE',
      payload: {
        file: file,
      },
    };
  },
  setUrl(url: string): any {
    return {
      type: 'APP_FORUM_URL',
      payload: {
        url: url,
      },
    };
  },
  setSupportStates(value: []): any {
    return {
      type: 'APP_SUPPORT_STATES',
      payload: {
        supportStates: value,
      },
    };
  },
  getSupportStates(callback?: ActionCallback): GetAccountStates {
    return {
      type: 'GET_SUPPORT_STATES',
      callback,
    };
  },
};

export default appAction;
