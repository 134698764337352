import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Spin } from 'antd';
// components
import BoxAccontContainer from '../../boxAccountContainer';
import AccountTherapist, { AccountTherapistTabs } from '../../../organisms/account/therapist';
// types
import { stateType } from '../../../../types/types';
// styles
import './style.scss';

const AccountTherapistContainer: React.FC = () => {
  const loader = useSelector((state: stateType) => state.account.loader, shallowEqual);
  return (
    <Spin spinning={loader}>
      <div className="account-therapist-container">
        <div className="account-therapist-container__column">
          <AccountTherapist />
        </div>
        <div className="account-therapist-container__column">
          <BoxAccontContainer>
            <AccountTherapistTabs />
          </BoxAccontContainer>
        </div>
      </div>
    </Spin>
  );
};

export default AccountTherapistContainer;
