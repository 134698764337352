import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

// components
import MainTemplate from '../../../components/templates/mainTemplate';
import GroupDetailsContainer from '../../../components/containers/groupDetailsContainer/therapist';
import { HeaderPageContainer } from '../../../components/containers/pageContainer';

// actions
import appAction from '../../../redux/actions/app';
import groupsAction from '../../../redux/actions/groups';
import sessionsAction from '../../../redux/actions/sessions';
import historyAction from '../../../redux/actions/history';
//types
import { stateType } from '../../../types/types';

const TherapistsGroupPage: React.FC = ({ computedMatch }: any) => {
  const params = useSelector((state: stateType) => state.history.params);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(appAction.page('My Groups'));
    dispatch(groupsAction.id(computedMatch.params.id));
    dispatch(sessionsAction.get(computedMatch.params.id));
    dispatch(historyAction.get(computedMatch.params.id));

    return () => {
      historyAction.params({ ...params, page: 1 });
      historyAction.create([]);
      groupsAction.clearItem();
    };
  }, []);
  return (
    <MainTemplate>
      <HeaderPageContainer>
        <h2 className="text-page-header title-back" onClick={() => history.goBack()}>
          Group Details
        </h2>
      </HeaderPageContainer>
      <GroupDetailsContainer paramsId={computedMatch.params.id} />
    </MainTemplate>
  );
};

export default TherapistsGroupPage;
