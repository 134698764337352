import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import InputField from '../../inputField';
import { Checkbox } from 'antd';
// styles
import '../style.scss';
// icons
import { PasswordEyeIcon } from '../../../atoms/utils/password-eye-icon';

interface InputsType {
  errors?: string;
  register: any;
  watch?: any;
  name: string;
  label: string;
}

interface passwordInputProps extends Omit<InputsType, 'name' | 'label'> {
  repeatError?: string;
  trigger: any;
}

type repeatPass = Omit<InputsType, 'name' | 'label'>;

export const RegistrationField: React.FC<InputsType> = ({ name, label, errors, register }: InputsType) => {
  return (
    <InputField name={name} label={label} errors={errors} icon={false}>
      <input id={name} className={errors && 'error'} placeholder="" {...register(name)} />
    </InputField>
  );
};

export const RegistrationInputPassword: React.FC<passwordInputProps> = ({
  errors,
  register,
  repeatError,
  trigger,
  watch,
}: passwordInputProps) => {
  const [show, setShow] = useState(false);

  const CurrentEyeIcon = PasswordEyeIcon({
    active: show,
    onClick: () => {
      setShow(!show);
    },
  });

  const watchPass = watch('password');

  useEffect(() => {
    if (repeatError) {
      trigger('password_repeat');
    }
  }, [repeatError, watchPass]);

  return (
    <InputField name="password" label="Password" errors={errors} icon={<CurrentEyeIcon />}>
      <input
        id="password"
        type={show ? 'text' : 'password'}
        className={errors && 'error'}
        placeholder=""
        {...register('password')}
      />
    </InputField>
  );
};

export const RegistrationInputRepeatPassword: React.FC<repeatPass> = ({ errors, register }: repeatPass) => {
  const [show, setShow] = useState(false);

  const CurrentEyeIcon = PasswordEyeIcon({
    active: show,
    onClick() {
      setShow(!show);
    },
  });

  return (
    <InputField name="password_repeat" label="Confirm Password" errors={errors} icon={<CurrentEyeIcon />}>
      <input
        id="password_repeat"
        type={show ? '' : 'password'}
        className={errors && 'error'}
        placeholder=""
        {...register('password_repeat')}
      />
    </InputField>
  );
};

export const RegistrationOptions: React.FC<any> = ({ errors, control }: any) => {
  return (
    <div className={errors.checker ? 'field-container_error' : 'field-container'}>
      <Controller
        control={control}
        name="checker"
        render={({ field: { onChange } }) => (
          <Checkbox onChange={onChange}>
            <span className="field-container__label">
              I am over 18 and agree to the{' '}
              <span
                className="text-link"
                onClick={() => window.open('https://www.grouporttherapy.com/terms-services/terms-of-service', '_blank')}
              >
                Terms of Use
              </span>{' '}
              and{' '}
              <span
                className="text-link"
                onClick={() => window.open('https://www.grouporttherapy.com/terms-services/privacy-policy', '_blank')}
              >
                Privacy Policy
              </span>
              .
            </span>
          </Checkbox>
        )}
      />
      <div className="input-field__error-container">
        {errors?.checker?.message && (
          <span role="alert" className="input-field__error-message">
            {errors?.checker?.message}
          </span>
        )}
      </div>
    </div>
  );
};

export const RegistrationSubmitButton: React.FC = () => {
  return (
    <button type="submit" className="btn-form">
      Sign Up
    </button>
  );
};
