import React from 'react';
// components
import { TherapistGroupSessionItem, TherapistHomeSessionItemFuture, TherapistHomeSessionItemNext } from '../../items';
// style
import '../../style.scss';

const SessionList: React.FC<any> = ({ sessions }: any) => {
  return (
    <div className="session-list">
      <div className="session-list__next">
        <div className="session-list__label">Next Group Session</div>
        {sessions.next.length === 0 && <span>This group has not had a session</span>}
        <div className="session-list__items">{sessions && <SessionListNext items={sessions.next} />}</div>
      </div>
      <div className="session-list__next">
        <div className="session-list__label">Later Sessions</div>
        {sessions.future.length === 0 && <span>This group does not have future sessions</span>}
        <div className="session-list__items">{sessions && <SessionListFuture items={sessions.future} />}</div>
      </div>
    </div>
  );
};

export default SessionList;

export const SessionGroupList: React.FC<any> = ({ sessions }: any) => {
  return (
    <div className="session-list">
      <div className="session-list__next">
        <div className="session-list__label">Next Group Session</div>
        {sessions.next.length === 0 && <span>This group has not had a session</span>}
        <div className="session-list__items">{sessions && <SessionGroupListNext items={sessions.next} />}</div>
      </div>
      <div className="session-list__next">
        <div className="session-list__label">Future Sessions</div>
        {sessions.future.length === 0 && <span>This group does not have future sessions</span>}
        <div className="session-list__items">{sessions && <SessionGroupListFuture items={sessions.future} />}</div>
      </div>
    </div>
  );
};

export const SessionListNext: React.FC<any> = ({ items }: any) => {
  return (
    <div className="session-list__items">
      {items &&
        items.map((item: any) => {
          return <TherapistHomeSessionItemNext key={item.id} item={item} />;
        })}
    </div>
  );
};

export const SessionListFuture: React.FC<any> = ({ items }: any) => {
  return (
    <div className="session-list__items">
      {items &&
        items.map((item: any) => {
          return <TherapistHomeSessionItemFuture key={item.id} item={item} />;
        })}
    </div>
  );
};

export const SessionGroupListNext: React.FC<any> = ({ items }: any) => {
  return (
    <div className="session-list__items">
      {items &&
        items.map((item: any) => {
          return <TherapistGroupSessionItem key={item.id} item={item} />;
        })}
    </div>
  );
};

export const SessionGroupListFuture: React.FC<any> = ({ items }: any) => {
  return (
    <div className="session-list__items">
      {items &&
        items.map((item: any) => {
          return <TherapistGroupSessionItem key={item.id} item={item} />;
        })}
    </div>
  );
};
