import React, { useState, useRef } from 'react';
import Button from '@material-ui/core/Button';
import MenuContainer from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreIcon from '@material-ui/icons/MoreVert';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme, useMediaQuery } from '@material-ui/core';

import AboutDialog from '../../../AboutDialog/AboutDialog';
import ConnectionOptionsDialog from '../../../ConnectionOptionsDialog/ConnectionOptionsDialog';
import DeviceSelectionDialog from '../../../DeviceSelectionDialog/DeviceSelectionDialog';
import SettingsIcon from '../../../../../icons/SettingsIcon';
import { useAppState } from '../../../../../state';
import BackgroundSelectionDialog from '../../../BackgroundSelectionDialog/BackgroundSelectionDialog';
import useVideoContext from '../../../../../hooks/useVideoContext/useVideoContext';
import { shallowEqual, useSelector } from 'react-redux';

const useStyles = makeStyles({
  settingsButton: {
    width: '44px',
    height: '44px',
    border: '1px solid #BDBDBD',
    borderRadius: '50%',
    display: 'inline-block',
    boxSizing: 'border-box',
    padding: 11,
    margin: ' 0 8px',
    minWidth: 0,
  },
});

export default function SettingsMenu({ mobileButtonClass }: { mobileButtonClass?: string }) {
  const classes = useStyles();
  const { roomType } = useAppState();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const [menuOpen, setMenuOpen] = useState(false);
  const [aboutOpen, setAboutOpen] = useState(false);
  const [deviceSettingsOpen, setDeviceSettingsOpen] = useState(false);
  const [connectionSettingsOpen, setConnectionSettingsOpen] = useState(false);
  const { room, isBackgroundSelectionOpen, setIsBackgroundSelectionOpen } = useVideoContext();
  const role = useSelector((state: any) => state.user.profile.role, shallowEqual);

  const anchorRef = useRef<HTMLButtonElement>(null);

  return (
    <>
      {isMobile ? (
        <Button
          ref={anchorRef}
          onClick={() => setMenuOpen(true)}
          startIcon={<MoreIcon />}
          className={mobileButtonClass}
        >
          More
        </Button>
      ) : (
        <Button ref={anchorRef} onClick={() => setMenuOpen(true)} className={classes.settingsButton}>
          <SettingsIcon />
        </Button>
      )}
      <MenuContainer
        open={menuOpen}
        onClose={() => setMenuOpen((isOpen) => !isOpen)}
        anchorEl={anchorRef.current}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'top',
          horizontal: isMobile ? 'left' : 'right',
        }}
        transformOrigin={{
          vertical: 0,
          horizontal: 'center',
        }}
      >
        <MenuItem onClick={() => setDeviceSettingsOpen(true)}>
          <Typography variant="body1">Audio and Video Settings</Typography>
        </MenuItem>
        {roomType !== 'peer-to-peer' && roomType !== 'go' && role != 'CUSTOMER' && (
          <MenuItem onClick={() => setConnectionSettingsOpen(true)}>
            <Typography variant="body1">Connection Settings</Typography>
          </MenuItem>
        )}
      </MenuContainer>
      <DeviceSelectionDialog
        open={deviceSettingsOpen}
        onClose={() => {
          setDeviceSettingsOpen(false);
          setMenuOpen(false);
        }}
        openBackgroundDialog={() => {
          setDeviceSettingsOpen(false);
          setIsBackgroundSelectionOpen(true);
          setMenuOpen(false);
        }}
      />
      <BackgroundSelectionDialog
        open={isBackgroundSelectionOpen}
        onClose={() => {
          setIsBackgroundSelectionOpen(false);
          setMenuOpen(false);
        }}
        openDeviceDialog={() => {
          setIsBackgroundSelectionOpen(false);
          setDeviceSettingsOpen(true);
          setMenuOpen(false);
        }}
      />
      <ConnectionOptionsDialog
        open={connectionSettingsOpen}
        onClose={() => {
          setConnectionSettingsOpen(false);
          setMenuOpen(false);
        }}
      />
    </>
  );
}
