import React, { useEffect } from 'react';
import _ from 'lodash';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Tabs } from 'antd';
import Moment from 'react-moment';
// components
import ContactsSupportForm from '../../forms/support';
import EditTherapitsModal from '../../modals/edit/admin/therapist';
import EditAccountModal from '../../modals/editAccount';
import PayInfoContainer from '../../stripe/payInfo';
import ArchiveTherapist from '../../modals/archive/therapist';
import ForgotPasswordButton from '../../modals/forgotPassword';
import EditTherapistNamesModal from 'components/organisms/modals/edit/admin/therapist/therapistNames';
import EditTherapistLastNames from '../../modals/edit/admin/therapist/therapistLastName';
import EditTherapistTitleModal from '../../modals/edit/admin/therapist/therapistTitle';
import UndoCancellation from '../../modals/restart/client/customer';
// actions
import appAction from '../../../../redux/actions/app';
// icons
import Icon from '@ant-design/icons';
import { ReactComponent as IconEditBlue } from '../../../../assets/image/account/edit-blue.svg';
import { ReactComponent as IconUser } from '../../../../assets/image/group/user.svg';
import { ReactComponent as IconTime } from '../../../../assets/image/group/time.svg';
import { ReactComponent as IconTopic } from '../../../../assets/image/group/group.svg';
// configs
import { timeConvertor, week_days } from '../../../../configs/tables/admin/clients';
// types
import { stateType } from '../../../../types/types';
// style
import './style.scss';
// helpers
import { createArray } from '../../../../configs/account/helpers';
import { capitalizeText } from '../../../../tools/helpFunctions';
import EditTherapistNPINumber from 'components/organisms/modals/edit/admin/therapist/therapistNPI';
import eventAnalytics from '../../../../redux/actions/analytics';
import eventsAction from 'redux/actions/admin_events';

const AccountCustomer: React.FC = () => {
  const { TabPane } = Tabs;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(eventAnalytics.analytics('account-details'));
    return () => {
      dispatch(appAction.account('details'));
    };
  }, []);

  const account = useSelector((state: stateType) => state.app.account);
  const { paymentInfo }: any = useSelector((state: stateType) => state.account.item, shallowEqual);
  const { info }: any = useSelector((state: stateType) => state.account);

  const onChange = (key: any) => {
    dispatch(appAction.account(key));
    if (key === 'subscription') {
      dispatch(eventAnalytics.analytics('manage-subscription'));
    } else if (key === 'details') {
      dispatch(eventAnalytics.analytics('account-details'));
    }
  };

  return (
    <Tabs
      defaultActiveKey={account}
      activeKey={account}
      onChange={onChange}
      className="account-tabs account-tabs__customer"
    >
      <TabPane tab="Account Details" key="details" className="account-tabs__item">
        <div className="account-edit-container">
          <AccountEditBlock title={'Profile'} />
          <AccountDetails edit={true} />
        </div>
        {paymentInfo && <PayInfoContainer paymentInfo={paymentInfo} />}
      </TabPane>
      <TabPane tab="Manage Subscription" key="subscription">
        <ManageSubscription>
          <ButtonSubscription info={info} />
        </ManageSubscription>
      </TabPane>
      <TabPane tab="Contact Support" key="support" className="account-tabs__contacts">
        <AccountEditBlock title={'Contact Support'} />
        <ContactsSupportForm />
      </TabPane>
    </Tabs>
  );
};

export default AccountCustomer;

export const AccountDetails: React.FC<any> = ({ edit }: any) => {
  const item: any = useSelector((state: stateType) => state.account.item, shallowEqual);
  return (
    <div className="account-details">
      {!_.isEmpty(item) && (
        <div className="account-details__items">
          {item.firstname && item.lastname && (
            <div className="account-details__item">
              <div className="account-details__label">Name</div>
              <div className="account-details__value">
                {item.firstname} {item.lastname}
                {edit && (
                  <EditAccountModal type={'name'}>
                    <button>
                      <Icon component={IconEditBlue} className="account-details__edit-icon" />
                      <span className="account-details__edit">Edit</span>
                    </button>
                  </EditAccountModal>
                )}
              </div>
            </div>
          )}
          {/* {item.forumUsername && (Group is missing
            <div className="account-details__item">
              <div className="account-details__label">Forum Name</div>
              <div className="account-details__value">
                {item.forumUsername}
                {edit && (
                  <EditAccountModal type={'forum-name'}>
                    <button>
                      <Icon component={IconEditBlue} className="account-details__edit-icon" />
                      <span className="account-details__edit">Edit</span>
                    </button>
                  </EditAccountModal>
                )}
              </div>
            </div>
          )} */}
          {item.phone && (
            <div className="account-details__item">
              <div className="account-details__label">Phone Number</div>
              <div className="account-details__value">
                <span className="account-details__number-first">{`+1`}</span>
                {item.phone}
                {edit && (
                  <EditAccountModal type={'phone'}>
                    <button>
                      <Icon component={IconEditBlue} className="account-details__edit-icon" />
                      <span className="account-details__edit">Edit</span>
                    </button>
                  </EditAccountModal>
                )}
              </div>
            </div>
          )}
          {item.email && (
            <div className="account-details__item">
              <div className="account-details__label">Email</div>
              <div className="account-details__value">
                {item.email}
                {edit && (
                  <EditAccountModal type={'email'}>
                    <button>
                      <Icon component={IconEditBlue} className="account-details__edit-icon" />
                      <span className="account-details__edit">Edit</span>
                    </button>
                  </EditAccountModal>
                )}
              </div>
            </div>
          )}
          <div className="account-details__item">
            <div className="account-details__label">Password</div>
            <div className="account-details__value">
              {createArray(10).map((item) => {
                return <div className="account-details__dots" key={item} />;
              })}
              {edit && (
                <EditAccountModal type={'password'}>
                  <button>
                    <Icon component={IconEditBlue} className="account-details__edit-icon" />
                    <span className="account-details__edit">Edit</span>
                  </button>
                </EditAccountModal>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export const AccountDetailsItem: React.FC<any> = ({ item, edit }: any) => {
  return (
    <div className="account-details">
      {!_.isEmpty(item) && (
        <div className="account-details__items">
          <div className="account-details__item">
            <div className="account-details__label">Name</div>
            <div className="account-details__value">
              {item.firstname} {item.lastname}
            </div>
          </div>
          <div className="account-details__item">
            <div className="account-details__label">Phone Number</div>
            <div className="account-details__value">
              <span className="account-details__number-first">{`+1`}</span>
              {item.phone}
              {edit && (
                <EditAccountModal type={'phone'} role="therapist">
                  <button>
                    <Icon component={IconEditBlue} className="account-details__edit-icon" />
                    <span className="account-details__edit">Edit</span>
                  </button>
                </EditAccountModal>
              )}
            </div>
          </div>
          <div className="account-details__item">
            <div className="account-details__label">Email</div>
            <div className="account-details__value">
              {item.email}
              {edit && (
                <EditAccountModal type={'email'}>
                  <button>
                    <Icon component={IconEditBlue} className="account-details__edit-icon" />
                    <span className="account-details__edit">Edit</span>
                  </button>
                </EditAccountModal>
              )}
            </div>
          </div>
          <div className="account-details__item">
            <div className="account-details__label">Password</div>
            <div className="account-details__value">
              {createArray(10).map((item) => {
                return <div className="account-details__dots" key={item} />;
              })}
              {edit && (
                <EditAccountModal type={'password'}>
                  <button>
                    <Icon component={IconEditBlue} className="account-details__edit-icon" />
                    <span className="account-details__edit">Edit</span>
                  </button>
                </EditAccountModal>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export const ManageSubscription: React.FC<any> = ({ children }: any) => {
  const { info, item, loader }: any = useSelector((state: stateType) => state.account, shallowEqual);
  const profile: any = useSelector((state: stateType) => state.user.profile);

  const activeSubscription = profile?.subscriptionStatus?.value !== 4 && profile?.subscriptionStatus?.value !== 6;

  return (
    <div className="manage-subscription">
      <div className="manage-subscription__block">
        <div className="manage-subscription__item">
          <div className="manage-subscription__title">Grouport Plan</div>
          {!loader && (
            <div className="manage-subscription__text">
              One session per week
              <br />
              <span className="manage-subscription__text_italic"> (60 min each)</span>
            </div>
          )}
        </div>
        <div className="manage-subscription__item">
          <div className="manage-subscription__title">Group Details</div>
          {!loader &&
            _.isEmpty(info.medicalGroup) &&
            (!_.isEmpty(profile.preferableGroupInfo) ? (
              item?.eugenFlow ? (
                <div className="manage-subscription__text">TBD Following Consultation</div>
              ) : (
                <PreferableGroup info={profile.preferableGroupInfo} />
              )
            ) : (
              <div className="manage-subscription__text" />
            ))}
          {!loader && !_.isEmpty(info.medicalGroup) && (
            <div className="manage-subscription__item-wrap">
              <div className="manage-subscription__text">
                <Icon component={IconUser} />
                <span className="manage-subscription__text_light">Therapist: </span>
                {info?.medicalGroup?.therapist?.firstname} {info?.medicalGroup?.therapist?.lastname}
              </div>
              <div className="manage-subscription__text">
                <Icon component={IconTopic} />
                <span className="manage-subscription__text_light">Topic: </span>
                {info?.medicalGroup.groupTopic?.name}
              </div>
              <div className="manage-subscription__text">
                <Icon component={IconTime} />
                {week_days[info?.medicalGroup.dayOfWeek - 1]}s, {timeConvertor(info?.medicalGroup.start)} -{' '}
                {timeConvertor(info?.medicalGroup.end)}
              </div>
            </div>
          )}
        </div>
        {info.productPeriodName ? (
          <div className="manage-subscription__item">
            <div className="manage-subscription__title">Billing Schedule</div>
            {!loader && (
              <div className="manage-subscription__item-wrap">
                <div className="manage-subscription__text">
                  {info?.productPeriodNameFuture && info?.productPeriodNameFuture !== info.productPeriodName
                    ? `Currently ${capitalizeText(
                        info.productPeriodName,
                      )}. Your next renewal will switch to ${capitalizeText(info?.productPeriodNameFuture)}.`
                    : capitalizeText(info.productPeriodName)}
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="manage-subscription__item">
            <div className="manage-subscription__title">Billing Schedule</div>
            {!loader && <div className="manage-subscription__text"> No Active Subscription </div>}
          </div>
        )}
        {info.nextRenewalDate && info.nextRenewalPrice >= 0 && !loader ? (
          <div className="manage-subscription__item">
            <div className="manage-subscription__title">
              {activeSubscription ? 'Next Renewal' : 'Subscription ends'}
            </div>
            <div className="manage-subscription__item-wrap">
              <div className="manage-subscription__text">
                {info.canCancel
                  ? `Your next payment is for $${info.nextRenewalPrice / 100}`
                  : 'Your billing cycle will end'}
                <br />
                <span className="manage-subscription__text_bold">
                  {` on `} <Moment format="MMM D, YYYY">{info.nextRenewalDate}</Moment>
                </span>
                {activeSubscription ? '' : `, and you will no longer be billed thereafter`}
              </div>
            </div>
          </div>
        ) : (
          <div className="manage-subscription__item">
            {!loader && (
              <>
                <div className="manage-subscription__title">Subscription Canceled</div>
                <div className="manage-subscription__text"> Your subscription ended </div>
              </>
            )}
          </div>
        )}
        {!_.isEmpty(info) && !loader && profile?.subscriptionStatus?.value !== 4 ? (
          <div className="manage-subscription__item">
            <div className="manage-subscription__title">
              Sessions Remaining in
              <br />
              Current Billing cycle
            </div>
            {info.remainingSessionsCount > 0 ||
            !_.isEmpty(info.medicalGroup) ||
            _.isEmpty(info.preferableGroup) ||
            (!_.isEmpty(info.preferableGroup) && !item?.eugenFlow) ? (
              <div className="manage-subscription__text">
                {info.remainingSessionsCount > 1
                  ? `${info.remainingSessionsCount} sessions remaining`
                  : `${info.remainingSessionsCount} session remaining`}
              </div>
            ) : (
              <div className="manage-subscription__text">4 sessions remaining</div>
            )}
          </div>
        ) : (
          <div className="manage-subscription__item">
            <div className="manage-subscription__title">
              Sessions Remaining in
              <br />
              Current Billing cycle
            </div>
            {!loader && <div className="manage-subscription__text"> 0 sessions remaining </div>}
          </div>
        )}
      </div>
      <div className="manage-subscription__footer">{children}</div>
    </div>
  );
};

const PreferableGroup: React.FC<any> = ({ info }) => {
  return (
    <div className="manage-subscription__item-wrap">
      <div className="manage-subscription__text">
        <Icon component={IconUser} />
        <span className="manage-subscription__text_light">Therapist: </span>
        {info?.therapist}
      </div>
      <div className="manage-subscription__text">
        <Icon component={IconTopic} />
        <span className="manage-subscription__text_light">Topic: </span>
        {info?.name}
      </div>
      <div className="manage-subscription__text">
        <Icon component={IconTime} />
        <Moment format="h:mmA">{info?.groupTimeFrom}</Moment>
        {' - '}
        <Moment format="h:mmA [ET] dddd">{info?.groupTimeTo}</Moment>s
      </div>
    </div>
  );
};

export const AccountEditBlock: React.FC<any> = ({ title, children }: any) => {
  return (
    <div className="account-edit-block">
      <div className="account-edit-block__title account-therapist-details__title">{title}</div>
      {children}
    </div>
  );
};

export const ButtonSubscription: React.FC<any> = ({ info }: any) => {
  const { canChangeGroup, canCancel, canRestart, canChangeBillingSchedule } = info;
  const dispatch = useDispatch();

  const history = useHistory();

  const onChangeGroup = () => {
    dispatch(eventAnalytics.analytics('change-group'));
    dispatch(appAction.setReschedule(false));
    history.push('/profile/change-group');
  };

  const onCancelGroup = () => {
    dispatch(eventAnalytics.analytics('cancel-subscription'));
    history.push('/profile/cancel-group');
  };

  const onClickChangeBillingSchedule = () => {
    dispatch(eventAnalytics.analytics('billing-change-start'));
    history.push('/change-billing');
  };

  const onViewSuperbills = () => {
    history.push('/superbill');
  };
  return (
    <>
      {canChangeGroup && (
        <button className="btn-form" onClick={onChangeGroup}>
          Change group
        </button>
      )}
      {canChangeBillingSchedule && (
        <button className="btn-form" onClick={onClickChangeBillingSchedule}>
          Change billing schedule
        </button>
      )}
      {canCancel && (
        <button className="btn-form btn-form__superbill" onClick={onViewSuperbills}>
          View superbills
        </button>
      )}
      {canCancel && (
        <button className="btn-form btn-form__cancel" onClick={onCancelGroup}>
          Cancel subscription renewal
        </button>
      )}
      {canRestart && (
        <>
          <UndoCancellation />
          <button className="btn-form btn-form__superbill" onClick={onViewSuperbills}>
            View superbills
          </button>
        </>
        // <button className="btn-form" onClick={restartSubscription}>
        //   Undo cancellation
        // </button>
      )}
      {/*{canRestart && <UndoCancellation />}*/}
    </>
  );
};

export const ButtonTherapistEdit: React.FC = () => {
  return (
    <EditTherapitsModal
      btnClass={'account-edit-block__edit-btn'}
      icon={<Icon component={IconEditBlue} />}
      name="Edit"
    />
  );
};

export const AdminTherapistAccountDetails: React.FC = () => {
  const item: any = useSelector((state: stateType) => state.account.item, shallowEqual);

  return (
    <div className="account-details account-therapist-details">
      {!_.isEmpty(item) && (
        <div className="account-details__items">
          <div className="account-details__item account-therapist-details__item">
            <div className="account-details__label account-therapist-details__label">Title</div>
            <div className="account-details__value account-therapist-details__value">
              {item?.title ? item.title.name : 'No Title'}
            </div>
            <div className="account-therapist-details__btn-edit">
              <EditTherapistTitleModal
                btnClass="account-edit-block__edit-btn"
                icon={<Icon component={IconEditBlue} />}
                name="Edit"
              />
            </div>
          </div>
          <div className="account-details__item account-therapist-details__item">
            <div className="account-details__label account-therapist-details__label">NPI number</div>
            <div className="account-details__value account-therapist-details__value">
              {item?.npi ? item.npi : 'No NPI'}
            </div>
            <div className="account-therapist-details__btn-edit">
              <EditTherapistNPINumber
                btnClass="account-edit-block__edit-btn"
                icon={<Icon component={IconEditBlue} />}
                name="Edit"
              />
            </div>
          </div>
          {item.firstname && (
            <div className="account-details__item account-therapist-details__item">
              <div className="account-details__label account-therapist-details__label">First Name</div>
              <div className="account-details__value account-therapist-details__value">{item.firstname}</div>
              <div className="account-therapist-details__btn-edit">
                <EditTherapistNamesModal
                  btnClass="account-edit-block__edit-btn"
                  icon={<Icon component={IconEditBlue} />}
                  name="Edit"
                />
              </div>
            </div>
          )}
          {item.lastname && (
            <div className="account-details__item account-therapist-details__item">
              <div className="account-details__label account-therapist-details__label">Last Name</div>
              <div className="account-details__value account-therapist-details__value">{item.lastname}</div>
              <div className="account-therapist-details__btn-edit">
                <EditTherapistLastNames
                  btnClass="account-edit-block__edit-btn"
                  icon={<Icon component={IconEditBlue} />}
                  name="Edit"
                />
              </div>
            </div>
          )}
          {item.phone && (
            <div className="account-details__item account-therapist-details__item">
              <div className="account-details__label account-therapist-details__label">Phone Number</div>
              <div className="account-details__value account-therapist-details__value">
                <span className="account-details__number-first">{`+1`}</span>
                {item.phone}
              </div>
              <div className="account-therapist-details__btn-edit" />
            </div>
          )}
          {item.email && (
            <div className="account-details__item account-therapist-details__item">
              <div className="account-details__label account-therapist-details__label">Email</div>
              <div className="account-details__value account-therapist-details__value">{item.email}</div>
              <div className="account-therapist-details__btn-edit" />
            </div>
          )}
          <div className="account-details__item account-therapist-details__item">
            <div className="account-details__label account-therapist-details__label">Password</div>
            <div className="account-details__value account-therapist-details__value">
              {createArray(10).map((item) => {
                return <div className="account-details__dots" key={item} />;
              })}
            </div>
            <div className="account-therapist-details__btn-edit" />
          </div>
          <div className="account-therapist-details__password-btn">
            <ForgotTherapistPassword item={item} />
          </div>
        </div>
      )}
    </div>
  );
};

export const DeleteArchiveButtons: React.FC<any> = ({ canDelete, canArchive }: any) => {
  return (
    <div className="buttons-container">
      <ArchiveTherapist typeAction="delete" canDelete={canDelete} />
      {canArchive && <ArchiveTherapist typeAction="archive" canDelete={canDelete} />}
    </div>
  );
};

export const ForgotTherapistPassword: React.FC<any> = ({ item }: any) => {
  return <>{!_.isEmpty(item) && <ForgotPasswordButton />}</>;
};
