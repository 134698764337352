import React, { FC } from 'react';
import { Dropdown } from 'antd';
// components
import MoreMenu from 'components/molecules/popovers/MoreMenu';
// types
import { IActionListItem } from 'types/app-types';
// icons
import moreIcon from 'assets/image/info/more.svg';
// style
import './style.scss';

interface IMoreDropdown {
  actions: Array<IActionListItem>;
}

const MoreDropdown: FC<IMoreDropdown> = ({ actions }: IMoreDropdown) => {
  return (
    <Dropdown
      overlay={<MoreMenu list={actions} />}
      placement="bottomRight"
      trigger={['click']}
      arrow
      getPopupContainer={(trigger) => trigger}
    >
      <div className="button-more">
        <img src={moreIcon} alt="More info" width={20} height={10} />
      </div>
    </Dropdown>
  );
};

export default MoreDropdown;
