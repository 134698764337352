/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { put, call } from 'redux-saga/effects';
// api
import { api } from '../../../api/clients';
// helpers
import showError from '../showError';
import { actionUpdateSelf } from '../admin/clients';
// actions
import restartAction from '../../actions/restart_subscriptions';
import { authActions } from '../../actions/auth';
import choiceGroupAction from '../../actions/choice_group';
import { apiChoiceGroup } from '../../../api/choiceGroup';
import { choiceGroupMapping } from '../../../api/mapping/response/choiceGroup';

export function* sagaCancelRestartSubscription(action) {
  yield put(restartAction.loader(true));

  try {
    const response = yield call(api.resetRestartSubscription);

    yield put(restartAction.loader(false));

    yield actionUpdateSelf(response, 'CUSTOMER');

    if (action.callback) {
      action.callback();
    }
  } catch (e) {
    yield put(restartAction.loader(false));
    showError();
  }
}

export function* sagaUpdateRegistrationData(action) {
  yield put(restartAction.loader(true));

  try {
    const response = yield call(api.updateRegistrationData, action.data);

    if (action.callback && response.data.data.item.stateFlow !== 2) {
      action.callback();
    } else {
      yield actionUpdateSelf(response, 'CUSTOMER');
    }

    yield put(restartAction.loader(false));
  } catch (e) {
    yield put(restartAction.loader(false));
    showError();
  }
}

export function* sagaWaitingGoBack(action) {
  yield put(authActions.loader(true));

  try {
    const response = yield call(api.waitingGoBack);

    if (action.callback) {
      action.callback();
    }

    actionUpdateSelf(response, 'CUSTOMER');

    yield put(authActions.loader(false));
  } catch (e) {
    yield put(authActions.loader(false));
    showError();
  }
}

export function* sagaJoinRestartGroup(action) {
  yield put(restartAction.loader(true));

  try {
    const response = yield call(apiChoiceGroup.join, action.id);

    yield choiceGroupAction.create(choiceGroupMapping.buildSmCardItem(response.data.data.item.medicalGroup));

    if (action.callback) {
      action.callback();
    }
  } catch (e) {
    showError();
  } finally {
    yield put(restartAction.loader(false));
  }
}
