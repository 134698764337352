import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';

// components
import SimpleHeader from '../../organisms/headers/simple/simple-header';
import BoxContainer from '../../containers/boxContainer';
// styles
import './style.scss';
import { animCard } from '../../../tools/animations';

interface SimpleTemplateType {
  children: React.ReactNode;
  beforeText?: React.ReactNode;
  title?: string;
  button?: React.ReactNode;
  prevButton?: React.ReactNode;
  id?: number;
  page?: number;
}
const SimpleTemplate: React.FC<SimpleTemplateType> = ({
  children,
  title,
  beforeText,
  button,
  prevButton,
  id,
  page,
}: SimpleTemplateType) => {
  return (
    <div className="simple-template">
      <SimpleHeader redirect_url="/login-customer" />
      <div className="simple-template__body box">
        <h2 className="simple-template__title">{title}</h2>
        <AnimatePresence exitBeforeEnter initial={false}>
          <motion.div
            key={id}
            className="simple-template__body"
            variants={animCard}
            initial="initial"
            animate="visible"
            exit="exit"
          >
            {page === 0 ? (
              <BoxContainer>
                <div className="simple-template__body_group-registration">
                  {prevButton}
                  {children}
                </div>
              </BoxContainer>
            ) : (
              <div className="simple-template__body_group-registration">
                {prevButton}
                {children}
              </div>
            )}
          </motion.div>
        </AnimatePresence>
        {page === 0 && button}
        <div className="simple-template__before">{beforeText}</div>
      </div>
    </div>
  );
};

export default SimpleTemplate;
