import React, { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Modal, Spin } from 'antd';
// componets
import FormRescheduleClientSession from '../../../forms/reschedule/sessionClient';

// types
import { stateType } from '../../../../../types/types';

// styles
import './style.scss';
import { ReactComponent as CloseIcon } from '../../../../../assets/image/account/close4.svg';
import eventAnalytics from '../../../../../redux/actions/analytics';

const RescheduleClientSession: React.FC<any> = ({ session, showPopup }: any) => {
  const [visible, setVisible] = useState(showPopup);
  const loader: any = useSelector((state: stateType) => state.sessions.loader, shallowEqual);
  const loaderModal = useSelector((state: stateType) => state.sessions.loaderModal);
  const dispatch = useDispatch();

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    dispatch(eventAnalytics.analytics('reschedule-group-skip'));
    setVisible(false);
  };

  return (
    <>
      {visible && (
        <Modal
          visible={visible}
          onOk={handleOk}
          closable={true}
          onCancel={handleCancel}
          centered
          maskTransitionName="none"
          footer={false}
          className="reschedule-session-modal"
          closeIcon={<CloseIcon />}
        >
          <Spin spinning={loader || loaderModal}>
            <ModalClientContainer session={session} close={() => handleOk()} />
          </Spin>
        </Modal>
      )}
    </>
  );
};

export default RescheduleClientSession;

const ModalClientContainer = ({ session, close }: any) => {
  const initTitle = (element: any) => {
    switch (element) {
      case 'group-rescheduled':
        return {
          stepOne: 'Your weekly group time has been rescheduled to the following time going forward. ',
          stepTwo: 'Thanks for letting us know.',
        };
      case 'session-rescheduled':
        return {
          stepOne: 'Your upcoming session has been rescheduled to the time listed below.',
          stepTwo: 'Thanks for letting us know.',
          stepThree: 'Ok, thanks for letting us know.',
        };
      case 'session-canceled':
        return {
          stepOne: 'Your therapist had to cancel the following session below.',
        };
      default:
        return '';
    }
  };

  const initDescription = (element: any) => {
    switch (element) {
      case 'group-rescheduled':
        return {
          stepOne: 'Does the new time listed below work for you?',
          stepTwo: 'Your group will be switched to the following time going forward.',
        };
      case 'session-rescheduled':
        return {
          stepOne: 'Will you be able to make the new time?',
          stepTwo: 'Your next session will happen at the date listed below.',
          stepThree:
            'You won’t be billed for the upcoming session, and you’ll resume in your group at its normal time the following week.',
        };
      case 'session-canceled':
        return {
          stepOne:
            'You won’t be charged for the cancelled session and your group wil resume at its normal time the following week.',
        };
      default:
        return '';
    }
  };

  return (
    <FormRescheduleClientSession
      session={session}
      close={close}
      title={initTitle(session.type.name)}
      description={initDescription(session.type.name)}
    />
  );
};
