/*
 *
 * Members reducer
 *
 */
const initialState = {
  items: [],
  loader: false,
  loaderModal: false,
};

export type membersInitStateType = typeof initialState;

interface membersActionTypes {
  type: string;
  payload: {
    items: Array<any>;
    loader: boolean;
    loaderModal: boolean;
  };
}

const members = (state = initialState, action: membersActionTypes): membersInitStateType => {
  switch (action.type) {
    case 'MEMBERS':
      return Object.assign({}, state, {
        items: action.payload.items,
      });

    case 'MEMBERS_LOADER':
      return Object.assign({}, state, {
        loader: action.payload.loader,
      });

    case 'MEMBERS_LOADER_MODAL':
      return Object.assign({}, state, {
        loaderModal: action.payload.loaderModal,
      });

    default:
      return state;
  }
};

export default members;
