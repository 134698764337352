import React, { FC, useEffect } from 'react';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { authActions } from '../../redux/actions/auth';
import userActions from '../../redux/actions/user';
import LoadingPage from '../../components/app/loading';

const RedirectLogin: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    localStorage.setItem('role', 'customer');

    authActions.success({
      token: String(queryString.parse(history.location.search).token),
      refreshToken: String(queryString.parse(history.location.search).refresh_token),
    });

    userActions.update(undefined, dispatch, () => history.push('/'));
  }, []);

  return <LoadingPage />;
};

export default RedirectLogin;
