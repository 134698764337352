/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/prop-types */

import React from 'react';
// components
import ErrorTemplate from '../templates/simpleTemplate/error';
// images
import ImageErorr from '../../assets/image/errors/error.gif';
// texts
import { ERROR_MESSAGE } from '../../redux/sagas/showError';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorInfo: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ errorInfo: error });
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorTemplate title={`${ERROR_MESSAGE}...`} description={this.state.errorInfo.message}>
          {/*<img src={ImageErorr} alt="error" style={{ width: '70%' }} />*/}
        </ErrorTemplate>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
