import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
// components
import FilterBox from '../../../../molecules/filters/filterBox';
import {
  FilterWeekDaysField,
  FilterGroupTopicField,
  FilterGroupStatusesField,
  FilterNumberOfMembersField,
  FilterTherapistsField,
} from '../../../../molecules/filters/foTables';
// actions
import groupsAction from 'redux/actions/groups';
// types
import { stateType } from 'types/types';
import { FilterOptionsRole } from '../../../../../types/actions/common-types';
// styles
import './style.scss';

const FilterGroups: React.FC<any> = ({ setVisible }: any) => {
  const params: any = useSelector((state: stateType) => state.groups.params);
  const optionsIsReceived: any = useSelector((state: stateType) => state.groups.optionsIsReceived);
  const role = useSelector((state: any) => state.user.profile.role, shallowEqual);

  const isAdmin = role === 'ADMIN';

  const dispatch = useDispatch();

  useEffect(() => {
    ((isAdmin && optionsIsReceived !== FilterOptionsRole.Admin) ||
      (!isAdmin && optionsIsReceived !== FilterOptionsRole.Therapist)) &&
      dispatch(groupsAction.getFilterOptions());
  }, []);

  const onChange = (type: string, values: any) => {
    switch (type) {
      case 'topic':
        groupsAction.params({ ...params, group_topic_ids: values });
        break;
      case 'week':
        groupsAction.params({ ...params, days_of_week: values });
        break;
      case 'statuses':
        groupsAction.params({ ...params, statuses: values });
        break;
      case 'members':
        groupsAction.params({ ...params, members_numbers: values });
        break;
      case 'therapist':
        groupsAction.params({ ...params, therapist_id: values });
        break;

      default:
        break;
    }
  };

  const onSubmit = () => {
    groupsAction.params({
      ...params,
      page: 1,
      per_page: 10,
    });
    dispatch(groupsAction.get());
    setVisible(false);
  };

  const clearAll = () => {
    groupsAction.params({
      ...params,
      page: 1,
      per_page: 10,
      group_topic_ids: [],
      days_of_week: [],
      statuses: [],
      members_numbers: [],
      therapist_id: [],
    });
    setVisible(false);
    dispatch(groupsAction.get());
  };

  return (
    <div className="filters-container">
      <div className="filters-container__title">Filters</div>
      <div className="filters-container__body">
        {isAdmin && (
          <>
            <FilterBox title="Therapist" type="select">
              <FilterTherapistsField change={(e: any) => onChange('therapist', e)} defaultValue={params.therapist_id} />
            </FilterBox>
            <div className="filters-container__line" />
          </>
        )}
        <FilterBox title="Group Topic">
          <FilterGroupTopicField change={(e: any) => onChange('topic', e)} defaultValue={params.group_topic_ids} />
        </FilterBox>
        <div className="filters-container__line" />
        {isAdmin && (
          <>
            <FilterBox title="# of members">
              <FilterNumberOfMembersField
                change={(e: any) => onChange('members', e)}
                defaultValue={params.members_numbers}
              />
            </FilterBox>
            <div className="filters-container__line" />
          </>
        )}
        <FilterBox title="Day of Session">
          <FilterWeekDaysField change={(e: any) => onChange('week', e)} defaultValue={params.days_of_week} />
        </FilterBox>
        <div className="filters-container__line" />
        <FilterBox title="Group Status">
          <FilterGroupStatusesField change={(e: any) => onChange('statuses', e)} defaultValue={params.statuses} />
        </FilterBox>
      </div>
      <div className="filters-container__footer">
        <button className="btn-form" onClick={onSubmit}>
          Apply filters
        </button>
        <button className="btn-clear-all" onClick={clearAll}>
          Clear all
        </button>
      </div>
    </div>
  );
};

export default FilterGroups;
