import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import cn from 'classnames';

// customer
import AccountCusstomerContainer from '../customer';
import AccountTherapistContainer from '../therapist';
import AccountAdminContainer from '../admin';
// types
import { stateType } from '../../../../types/types';

// style
import './style.scss';

const LayoutAccontContainer: React.FC = () => {
  const role = useSelector((state: stateType) => state.user.profile.role, shallowEqual);

  const controller = () => {
    switch (role) {
      case 'CUSTOMER':
        return <AccountCusstomerContainer />;
      case 'THERAPIST':
        return <AccountTherapistContainer />;
      case 'ADMIN':
        return <AccountAdminContainer />;

      default:
        return <></>;
    }
  };

  return (
    <div
      className={cn('layout-account', {
        'layout-account__therapist': role === 'THERAPIST',
      })}
    >
      <h2 className="text-page-header">Account</h2>
      {controller()}
    </div>
  );
};

export default LayoutAccontContainer;
