/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import moment from 'moment-timezone';

const mappingConsultation = {
  buildOptions(array) {
    return array.map((item) => {
      return {
        key: item.id,
        value: item.id,
        label: item.name,
      };
    });
  },
  buildOptionsTherapists(array) {
    return array.map((item) => {
      return {
        key: item.id,
        value: item.id,
        label: `${item?.title ? `${item.title.name}.` : ''} ${item.firstname} ${item.lastname} ${item.licenses.map(
          (item, i) => `  ${item.name}`,
        )}`,
      };
    });
  },
  buildIntakeTime(array) {
    return array.map((item) => {
      return {
        key: item.time,
        value: item.time,
        label: `${moment(item.time).format('h:mm A')} ET`,
      };
    });
  },
};

export default mappingConsultation;
