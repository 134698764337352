import React, { FC } from 'react';
// components
import PaddingCard from '../../organisms/cards/groupCard/cardGroupElements/PaddingCard';
import BorderCard from '../../organisms/cards/groupCard/cardGroupElements/BorderCard';
import CardGroup from '../../organisms/cards/groupCard/CardGroup';
import CardTherapist from '../../organisms/cards/therapist';
import GroupMeaning from '../../organisms/cards/groupCard/GroupMeaning';
import ButtonWrapper from '../../organisms/cards/groupCard/cardGroupElements/ButtonWrapper';
// style
import './style.scss';

const ThreeGroupSearch: FC<any> = ({ group }: any) => {
  return (
    <div className="groups-search">
      {group.map((item: any, i: number) => (
        <div key={i} className="groups-search__item">
          <PaddingCard>
            <CardGroup group={item.group} />
          </PaddingCard>
          <BorderCard>
            <CardTherapist therapist={item.therapist} />
          </BorderCard>
          <GroupMeaning group={item.group} />
          {
            <ButtonWrapper group={item.group}>
              <button className="btn-form">Join this group</button>
            </ButtonWrapper>
          }
        </div>
      ))}
    </div>
  );
};

export default ThreeGroupSearch;
