/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/*
 * account creators
 */

import { ActionCallback, errorsCallback } from '../../types/actions/common-types';

import {
  GetAdminClientSuperbillPaymentInfoAction,
  GetSuperbillPaymentInfoAction,
  SelectSuperbillDate,
  SetSuperbillPaymentInfoAction,
  SuperbillActions,
  SuperbillLoaderAction,
  SuperbillLoaderModalAction,
} from 'types/actions/superbill-actions';

const superbillAction: SuperbillActions = {
  getPaymentInfo(role?: string, callback?: ActionCallback): GetSuperbillPaymentInfoAction {
    return {
      type: 'GET_SUPERBILL_PAYMENT_INFO',
      role,
      callback,
    };
  },
  getAdminClientPaymentInfo(id?: string, callback?: ActionCallback): GetAdminClientSuperbillPaymentInfoAction {
    return {
      type: 'GET_ADMIN_CLIENT_SUPERBILL_PAYMENT_INFO',
      id,
      callback,
    };
  },
  setPaymentInfo(info: any): SetSuperbillPaymentInfoAction {
    return {
      type: 'SET_SUPERBILL_PAYMENT_INFO',
      payload: {
        info: info,
      },
    };
  },
  selectDate(start: Date, end: Date): SelectSuperbillDate {
    return {
      type: 'SELECT_SUPERBILL_DATE',
      payload: {
        date: { start, end },
      },
    };
  },
  loader(status: boolean): SuperbillLoaderAction {
    return {
      type: 'SUPERBILL_LOADING',
      payload: {
        loader: status,
      },
    };
  },
  loaderModal(status: boolean): SuperbillLoaderModalAction {
    return {
      type: 'SUPERBILL_LOADING_MODAL',
      payload: {
        loaderModal: status,
      },
    };
  },
};

export default superbillAction;
