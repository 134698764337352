import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Modal, Spin } from 'antd';
// styles
import './style.scss';
import { stateType } from 'types/types';
import SimpleHeader from 'components/organisms/headers/simple/simple-header';
import SuperbillContainer from 'components/containers/superbillContainer';
import accountAction from 'redux/actions/account';
import Box from 'components/containers/box';
import appAction from 'redux/actions/app';
import { AdminClientSuperbillList } from './superbillList';
import clientAction from 'redux/actions/client';

const AdminClientSuperbillPage: React.FC = ({ computedMatch }: any) => {
  const dispatch = useDispatch();
  const loader = useSelector((state: stateType) => state.superbill.loader, shallowEqual);
  const accountLoader = useSelector((state: stateType) => state.account.loader, shallowEqual);
  const role = useSelector((state: stateType) => state.user.profile.role, shallowEqual);

  useEffect(() => {
    dispatch(appAction.page('View superbills'));
    dispatch(accountAction.get(role.toLowerCase()));
    dispatch(clientAction.get(computedMatch.params.id));
  }, []);
  const paymentInfo: any = useSelector((state: stateType) => state.superbill.info, shallowEqual);

  const npi = paymentInfo?.medicalGroup?.therapist.npi;
  const info: any = useSelector((state: stateType) => state.clients.item, shallowEqual);

  return (
    <>
      <SimpleHeader />
      <SuperbillContainer>
        <div className="layout-superbill">
          <div className="superbill-page-container">
            <Spin spinning={accountLoader}>
              {npi && info?.icd10 ? (
                <p className="text-page-subtitle">
                  Superbills become available 3 days after the end of each billing cycle and contain all of the
                  information necessary for you to file an out of network insurance claim. Superbills require a
                  diagnosis code, so they will only be available once your therapist has submitted a diagnosis.
                </p>
              ) : !info?.icd10 ? (
                <p className="text-page-subtitle">
                  Superbills become available 3 days after the end of each billing cycle and contain all of the
                  information necessary for you to file an out of network insurance claim. Superbills require a
                  diagnosis code, so they will only be available once your therapist has submitted a diagnosis. We are
                  waiting for your therapist to provide a diagnosis code. Once we have this code, your superbill will be
                  generated.
                </p>
              ) : (
                <p className="text-page-subtitle">
                  Superbills become available 3 days after the end of each billing cycle and contain all of the
                  information necessary for you to file an out of network insurance claim. Superbills require a
                  diagnosis code, so they will only be available once your therapist has submitted a diagnosis.
                </p>
              )}
            </Spin>
            <Spin spinning={loader}>
              <Box classes="progress-block-box">
                <AdminClientSuperbillList id={computedMatch.params.id} />
              </Box>
            </Spin>
          </div>
        </div>
      </SuperbillContainer>
    </>
  );
};

export default AdminClientSuperbillPage;
