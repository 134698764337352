import React, { FC } from 'react';
import cn from 'classnames';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
// style
import './style.scss';
// icon
import { ReactComponent as ShareIcon } from '../../../assets/image/account/share.svg';
// actions
import { authActions } from '../../../redux/actions/auth';
// types
import { CopyLinkRole } from '../../../types/actions/common-types';

interface IShareAccount {
  role: CopyLinkRole;
  id: string | number;
}

const ShareAccount: FC<IShareAccount> = ({ role, id }) => {
  const loader: boolean = useSelector((state: any) => state.auth.loader, shallowEqual);

  const dispatch = useDispatch();

  const onCopy = () => {
    dispatch(authActions.getAuthLink(role, id));
  };

  return (
    <button className="share-btn" disabled={loader} onClick={onCopy}>
      <ShareIcon className={cn('share-btn__icon', { disabled: loader })} />
    </button>
  );
};

export default ShareAccount;
