/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/display-name */
import React from 'react';
import * as ColumnItems from '../../../../molecules/columnItems/admin/therapists';

export const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (value, row, index) => <ColumnItems.ItemName info={value} row={row} index={index} link="all_therapists" />,
    width: '330px',
    minWidth: '330px',
  },

  {
    title: 'State',
    dataIndex: 'state',
    key: 'state',
    render: (value, row, index) => {
      return <ColumnItems.ItemState info={value} row={row} index={index} />;
    },
    width: '200px',
  },

  {
    title: 'Active Groups',
    dataIndex: 'activeGroups',
    key: 'activeGroups',
    render: (value, row, index) => {
      return <ColumnItems.ItemActiveGroups info={value} row={row} index={index} />;
    },
    width: '100px',
  },

  {
    title: 'Possible Groups',
    dataIndex: 'possibleGroups',
    key: 'possibleGroups',
    render: (value, row, index) => <ColumnItems.ItemPossibleGroups info={value} row={row} index={index} />,
    width: '100px',
  },

  {
    title: 'Licensure',
    dataIndex: 'licensure',
    key: 'licensure',
    render: (value, row, index) => <ColumnItems.ItemLicensure info={value} row={row} index={index} />,
    width: '140px',
  },
];

columns.displayName = 'MyComponent';
