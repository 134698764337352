import React from 'react';
// style
import '../style.scss';
// icons
import { ReactComponent as BackIcon } from '../../../../assets/image/slider/back-icon-blue.svg';
import { Spin } from 'antd';

export interface SupportStateType {
  code: string;
  id: number;
  name: string;
}

type WaitingFlowPreviewTextType = {
  supportStates: Array<SupportStateType>;
  onClickDone: () => void;
  action?: () => void;
  loader?: any;
};

export const WaitingFlowPreviewText: React.FC<WaitingFlowPreviewTextType> = (props: WaitingFlowPreviewTextType) => {
  const { supportStates, onClickDone, action, loader } = props;

  return (
    <>
      <Spin spinning={loader}>
        <div className="preview-text__title-icon-container">
          <BackIcon className="preview-text__icon" onClick={action} />
          <div className="preview-text__title-container">
            <h2 className="preview-text__wait-desc_title">{'We’re sorry'}</h2>
          </div>
        </div>
        {supportStates.length ? (
          <p className="preview-text__wait-desc_center preview-text__wait-desc_text">
            At this time, we’re only in
            {supportStates.map((item, i) => (
              <span key={i}>{` ${item.code} ${i !== supportStates.length - 1 ? '&' : ''} `}</span>
            ))}
            given licensing laws that stipulate where our therapists can practice. We’ll be sure to add you to our
            waitlist and notify you by email once we’re in your state.
          </p>
        ) : (
          <p className="preview-text__wait-desc_center preview-text__wait-desc_text">
            At this time, we’re only in the US. We’ll be sure to add you to our waitlist and notify you by email once
            we’re in your country.
          </p>
        )}
        <p className="preview-text__wait-desc">
          Thanks, <br />
          The Grouport Team
          <span className="preview-text__wait-desc preview-text__wait-desc_logout" onClick={onClickDone}>
            Done{' '}
          </span>
        </p>
      </Spin>
    </>
  );
};
