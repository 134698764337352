import React from 'react';
import _ from 'lodash';
import { Tabs } from 'antd';
// components
import { AccountEditBlock, AccountDetails } from '../customer';

const AccountAdmin: React.FC = () => {
  const { TabPane } = Tabs;

  const callback = (key: any) => {
    console.log(key);
  };
  return (
    <Tabs defaultActiveKey="1" onChange={callback} className="account-tabs">
      <TabPane tab="Account Details" key="details" className="account-tabs__item">
        <AccountEditBlock title={'Profile'} />
        <AccountDetails />
      </TabPane>
    </Tabs>
  );
};

export default AccountAdmin;
