import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Menu, Modal, Spin, Dropdown } from 'antd';
import Moment from 'react-moment';
// actions
import sessionsAction from '../../../../../redux/actions/sessions';
// types
import { stateType } from '../../../../../types/types';
// components
import { SmallSessionCard } from '../../../cards/groupCard/SmallGroupCard';
// icons
import { ReactComponent as CloseIcon } from '../../../../../assets/image/account/close4.svg';
import { ReactComponent as IconCalendar } from '../../../../../assets/image/dropdown/calendar.svg';
import { EllipsisOutlined } from '@ant-design/icons';
import ImgAfter from '../../../../../assets/image/questions/after.svg';
// styles
import '../style.scss';
import CreateTwilioRoom from '../../../video/CreateTwilioRoom/CreateTwilioRoom';
import AddCoHostModal from '../../addCoHost';

const CanselSession: React.FC<any> = ({ item, group }: any) => {
  const [visible, setVisible] = useState(false);
  const [showCoHostModal, setShowCoHostModal] = useState(false);
  const [success, setSuccess] = useState(false);

  const loader = useSelector((state: stateType) => state.sessions.loaderModal, shallowEqual);

  const open = () => {
    setVisible(true);
  };

  const openCoHost = () => {
    setShowCoHostModal(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onSuccess = () => {
    setSuccess(true);
  };

  const coHostCancel = () => {
    setShowCoHostModal(false);
  };

  const coHostOk = () => {
    setShowCoHostModal(false);
  };

  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={openCoHost} style={{ color: '#171725' }}>
        Add Co-Host
      </Menu.Item>
      <Menu.Item key="0" onClick={open} style={{ color: '#e2351e' }}>
        Cancel Session
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
        <EllipsisOutlined style={{ fontSize: '18px' }} />
      </Dropdown>
      {visible && (
        <Modal
          visible={visible}
          onOk={handleOk}
          closable={true}
          onCancel={handleCancel}
          centered
          maskTransitionName="none"
          footer={null}
          className="cansel-group-modal"
          closeIcon={<CloseIcon />}
        >
          {!success && (
            <Spin spinning={loader}>
              <ModalContainer onSuccess={onSuccess} item={item} group={group} />
            </Spin>
          )}
          {success && <ModalSuccess handleCancel={handleCancel} item={item} group={group} />}
        </Modal>
      )}
      {showCoHostModal && (
        <AddCoHostModal
          visible={showCoHostModal}
          handleOk={coHostOk}
          handleCancel={coHostCancel}
          session={item}
          group={group}
        />
      )}
    </>
  );
};

export default CanselSession;

const ModalContainer = ({ onSuccess, item, group }: any) => {
  const dispatch = useDispatch();

  const action = () => {
    dispatch(sessionsAction.cancel(item.id, onSuccess));
  };

  return (
    <div className="cansel-session-container">
      <div className="cansel-session-container__title">Are you sure you want to cancel the following session?</div>
      <div className="cansel-session-container__main">
        <div className="cansel-session-container__label">
          <IconCalendar style={{ width: '20px', height: '20px' }} />
          <Moment format={'dddd, MMMM Do'}>{item.start}</Moment>
        </div>
        <div className="cansel-session-container__group-info">
          <SmallSessionCard item={item} group={group} />
        </div>
      </div>
      <div className="cansel-session-container__block">
        <button className="btn-form" onClick={action}>
          Cancel Session
        </button>
      </div>
    </div>
  );
};

const ModalSuccess = ({ handleCancel, item, group }: any) => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(sessionsAction.get(group.id));
    };
  }, []);

  return (
    <div className="cansel-session-container">
      <div className="cansel-session-container__title cansel-session-container__title_success">
        <img className="cansel-session-container__finish-ico" src={ImgAfter} alt="Finish icon" />
        <div>You’ve successfully cancelled the following session</div>
      </div>
      <div className="cansel-session-container__main">
        <div className="cansel-session-container__label">
          <IconCalendar style={{ width: '20px', height: '20px' }} />
          <Moment format={'dddd, MMMM Do'}>{item.start}</Moment>
        </div>
        <div className="cansel-session-container__group-info">
          <SmallSessionCard item={item} group={group} />
        </div>
      </div>
      <div className="cansel-session-container__block">
        <button className="btn-form" onClick={handleCancel}>
          Done
        </button>
      </div>
    </div>
  );
};
