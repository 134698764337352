import React from 'react';

export default function ChatIcon() {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18 10.0834C18.0029 11.1832 17.7459 12.2683 17.25 13.25C16.662 14.4265 15.7581 15.416 14.6395 16.1078C13.521 16.7995 12.2319 17.1662 10.9167 17.1667C9.81678 17.1696 8.73176 16.9126 7.75 16.4167L3 18L4.58333 13.25C4.08744 12.2683 3.83047 11.1832 3.83333 10.0834C3.83384 8.76815 4.20051 7.47907 4.89227 6.36048C5.58402 5.2419 6.57355 4.338 7.75 3.75002C8.73176 3.25413 9.81678 2.99716 10.9167 3.00002H11.3333C13.0703 3.09585 14.7109 3.82899 15.9409 5.05907C17.171 6.28915 17.9042 7.92973 18 9.66669V10.0834Z"
        stroke="white"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
