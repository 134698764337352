import React, { useState, useEffect, useRef } from 'react';
import { Input } from 'antd';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import debounce from 'lodash/debounce';
// components
import InputField from '../../../molecules/inputField';
// actions
import clientsAction from '../../../../redux/actions/clients';
// icons
import { ReactComponent as SearchIcon } from '../../../../assets/image/inputs/search.svg';
import Icon from '@ant-design/icons';
// styles
import '../style.scss';

const SearchClients: React.FC = () => {
  const [focus, setState] = useState(false);
  const dispatch = useDispatch();
  const inputField: any = useRef(null);
  const params: any = useSelector((state: any) => state.clients.params, shallowEqual);
  const loader = useSelector((state: any) => state.clients.loader, shallowEqual);

  useEffect(() => {
    if (focus) {
      !loader && inputField.current.focus();
    }
  }, [loader]);

  const onChange = async (e: any) => {
    try {
      await clientsAction.params({ ...params, name: e.target.value.trim(), page: 1 });
      dispatch(clientsAction.get());
    } catch (e) {
      console.log(e);
    }
  };

  const onFocus = () => {
    setState(true);
  };
  return (
    <div className="search-clients-field">
      <InputField>
        <Input
          prefix={<Icon component={SearchIcon} style={{ fontSize: 24, color: '#92929D' }} />}
          defaultValue={params.name ? params.name : ''}
          onFocus={onFocus}
          ref={inputField}
          disabled={loader}
          placeholder="Search client here..."
          className="search-clients-field__input"
          onChange={debounce(onChange, 600)}
        />
      </InputField>
    </div>
  );
};

export default SearchClients;
