import React from 'react';
// style
import './style.scss';

const UserStatus: React.FC<any> = ({ status }: any) => {
  const { value, id } = status;
  return (
    <div className={`user-status-container user-status-container__status-${id}`}>
      <span>{value}</span>
    </div>
  );
};

export default UserStatus;
