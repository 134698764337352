import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
// components
import ProgressTerm from '../../../../components/containers/progressTerm';
// actions
import appAction from '../../../../redux/actions/app';
import SimpleHeader from 'components/organisms/headers/simple/simple-header';
// styles
import './style.scss';

const ProgressTermPage: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(appAction.page('My Progress'));
  }, []);

  return (
    <div className="goals-template">
      <SimpleHeader />
      <div className="goals-template__body">
        <ProgressTerm />
      </div>
    </div>
  );
};

export default ProgressTermPage;
