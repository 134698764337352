import React from 'react';

export default function MoreIcon() {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.4997 11.3346C10.9599 11.3346 11.333 10.9615 11.333 10.5013C11.333 10.0411 10.9599 9.66797 10.4997 9.66797C10.0394 9.66797 9.66634 10.0411 9.66634 10.5013C9.66634 10.9615 10.0394 11.3346 10.4997 11.3346Z"
        stroke="white"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.333 11.3346C16.7932 11.3346 17.1663 10.9615 17.1663 10.5013C17.1663 10.0411 16.7932 9.66797 16.333 9.66797C15.8728 9.66797 15.4997 10.0411 15.4997 10.5013C15.4997 10.9615 15.8728 11.3346 16.333 11.3346Z"
        stroke="white"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.66634 11.3346C5.12658 11.3346 5.49967 10.9615 5.49967 10.5013C5.49967 10.0411 5.12658 9.66797 4.66634 9.66797C4.2061 9.66797 3.83301 10.0411 3.83301 10.5013C3.83301 10.9615 4.2061 11.3346 4.66634 11.3346Z"
        stroke="white"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
