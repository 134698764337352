import React, { FC, ReactNode, useState, useEffect, useRef } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import Slider from 'react-slick';
import { useWindowResize } from 'beautiful-react-hooks';
// components
import { NextArrow } from 'components/organisms/sliders/helpFullTips';
// types
import { stateType } from 'types/types';
// styles
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

interface IGroupSliderWrap {
  isSlider?: boolean;
  children: ReactNode;
  slidesCount?: number;
  number?: string;
}

const GroupSliderWrap: FC<IGroupSliderWrap> = ({ isSlider, children, slidesCount, number }: IGroupSliderWrap) => {
  const [width, setWidth] = useState(window.innerWidth);
  const totalCount: number = useSelector((state: stateType) => state.choice_group.totalCount, shallowEqual);
  const sliderRef = useRef<any>();
  const firstRender = useRef<boolean>(true);

  useWindowResize(() => {
    setWidth(window.innerWidth);
  });

  useEffect(() => {
    !firstRender.current &&
      slidesCount &&
      sliderRef.current?.slickGoTo(number && number === 'three' ? slidesCount - 3 : slidesCount - 4);
    firstRender.current = false;
  }, [totalCount, slidesCount]);

  const settings: any = {
    className: 'select-groups__items no-wrap',
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <NextArrow className="arrow next-arrow" />,
    nextArrow: <NextArrow className="arrow next-arrow" />,
    variableWidth: width > 500, // fix for library features
  };

  return isSlider ? (
    <Slider ref={sliderRef} {...settings}>
      {children}
    </Slider>
  ) : (
    <div className="select-groups__items">{children}</div>
  );
};

export default GroupSliderWrap;
