import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { Typography, makeStyles, TextField, Grid, Button, InputLabel, Theme } from '@material-ui/core';
import { useAppState } from '../../../../state';
import { userProfile } from '../../../../redux/reducers/user';
import { useSelector } from 'react-redux';
import { stateType } from '../../../../types/types';
import { displayName } from '../../Helpers/helpers';
import useRoomInfoContext from '../../../../hooks/useRoomInfoContext/useRoomInfoContext';
import Loader from '../../Loader/Loader';
import { Steps } from '../PreJoinScreens';
import CloseIcon from '../../../../icons/CloseIcon';

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1em',
    textTransform: 'capitalize',
    textAlign: 'center',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '22px',
    color: '#333333',
  },
  closeButton: {
    position: 'absolute',
    right: 20,
    width: 30,
    height: 30,
    backgroundColor: '#828282',
    borderRadius: '50%',
    padding: '7px',
  },
  sessionName: {
    textAlign: 'center',
  },
  nameLabel: {
    display: 'block',
    fontSize: 12,
    lineHeight: '16px',
    margin: '64px 34px 12px',
  },
  inputContainer: {
    display: 'block',
    justifyContent: 'space-between',
    margin: '1.5em 0 3.5em',
    '& div:not(:last-child)': {
      marginRight: '1em',
    },
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      margin: '1.5em 0 2em',
    },
  },
  textFieldContainer: {
    width: '100%',
  },
  usernameInput: {
    border: '1px solid rgba(208, 213, 221, 1)',
    borderRadius: 8,
    resize: 'none',
    fontSize: '14px',
    outline: 'none',
    textAlign: 'center',
    padding: '10px 14px',
    width: '100%',
  },
  continueButton: {
    backgroundColor: 'rgba(225, 157, 137, 1)',
    display: 'block',
    width: '100%',
    '&:hover': {
      backgroundColor: 'rgba(249, 112, 102, 1)',
    },
  },
  hostContainer: {
    padding: '20px 0 33px',
    textAlign: 'center',
  },
  hostContainerInner: {
    display: 'inline-block',
  },
  initials: {
    float: 'left',
    width: 32,
    height: 32,
    borderRadius: '50%',
    backgroundColor: '#98A2B3',
    color: '#ffffff',
    paddingTop: 8,
    marginRight: 10,
  },
  hostName: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '18px',
  },
  hostGroup: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '10px',
    lineHeight: '18px',
  },
}));

interface RoomNameScreenProps {
  name: string;
  roomName: string;
  setName: (name: string) => void;
  setRoomName: (roomName: string) => void;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
}

export default function RoomNameScreen({ name, roomName, setName, setRoomName, handleSubmit }: RoomNameScreenProps) {
  const classes = useStyles();
  const { user } = useAppState();
  const { meetingType } = useAppState();
  const { hostFirstName, hostLastName, hostTitle, hostInitials, hostLicenses } = useRoomInfoContext();
  const [loaded, setLoaded] = useState(false);

  const userProfile = useSelector((state: stateType) => state.user.profile);

  useEffect(() => {
    setName(
      userProfile.username
        ? userProfile.username +
            ' ' +
            Math.round(Math.random() * 100000)
              .toString()
              .padStart(6, '0')
        : '',
    );
  }, []);

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(
      event.target.value +
        ' ' +
        Math.round(Math.random() * 100000)
          .toString()
          .padStart(6, '0'),
    );
  };

  const handleRoomNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRoomName(event.target.value);
  };

  const hasUsername = !window.location.search.includes('customIdentity=true') && user?.displayName;
  const profile: userProfile = useSelector((state: stateType) => state.user.profile);

  return (
    <>
      {hostFirstName || hostLastName ? (
        <>
          <button
            className={classes.closeButton}
            onClick={() => {
              window.location.href = 'https://' + window.location.hostname;
            }}
          >
            <CloseIcon />
          </button>
          <h1 className={classes.gutterBottom}>Join {meetingType}</h1>
          <div className={classes.sessionName}>{displayName(roomName)}</div>
          <div className={classes.hostContainer}>
            <div className={classes.hostContainerInner}>
              <div className={classes.initials}>{hostInitials}</div>
              <span className={classes.hostName}>
                {hostTitle} {hostFirstName} {hostLastName} {hostLicenses.join(', ')}
              </span>
              <br />
              <span className={classes.hostGroup}>{displayName(roomName)}</span>
            </div>
          </div>
          <form onSubmit={handleSubmit}>
            <div className={classes.inputContainer}>
              {!hasUsername && (
                <div className={classes.textFieldContainer}>
                  <label className={classes.nameLabel} htmlFor="input-user-name">
                    Enter your name as you would like it displayed to your group
                  </label>
                  <input
                    value={displayName(name)}
                    className={classes.usernameInput}
                    onChange={handleNameChange}
                    placeholder={'Enter name'}
                  />
                </div>
              )}
            </div>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={!displayName(name).trim() || !roomName}
              className={classes.continueButton}
            >
              Join now
            </Button>
          </form>
        </>
      ) : (
        <>
          <div>
            <Loader />
          </div>
        </>
      )}
    </>
  );
}
