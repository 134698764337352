/*
 *
 * Assessment Questions reducer
 *
 */
const initialState = {
  status: 'before',
  item: {},
  loader: false,
  loaderModal: false,
  progress: 0,
  question_number: 1,
  score: [0, 0, 0, 0, 0, 0, 0],
};

export type assessmentQuestionsInitStateType = typeof initialState;

interface assessmentQuestionsActionTypes {
  type: string;
  payload: {
    status: string;
    item: any;
    loader: boolean;
    loaderModal: boolean;
    progress: number;
    question_number: number;
    score: Array<number>;
  };
}

const assessment_questions = (
  state = initialState,
  action: assessmentQuestionsActionTypes,
): assessmentQuestionsInitStateType => {
  switch (action.type) {
    case 'ASSESSMENT_QUESTION_STATUS':
      return Object.assign({}, state, {
        status: action.payload.status,
      });
    case 'ASSESSMENT_QUESTION_ITEM':
      return Object.assign({}, state, {
        item: action.payload.item,
      });
    case 'ASSESSMENT_QUESTION_LOADER':
      return Object.assign({}, state, {
        loader: action.payload.loader,
      });

    case 'ASSESSMENT_QUESTION_LOADER_MODAL':
      return Object.assign({}, state, {
        loaderModal: action.payload.loaderModal,
      });

    case 'ASSESSMENT_QUESTION_PROGRESS':
      return Object.assign({}, state, {
        progress: action.payload.progress,
      });

    case 'ASSESSMENT_QUESTION_NUMBER':
      return Object.assign({}, state, {
        question_number: action.payload.question_number,
      });

    case 'ASSESSMENT_PREV_QUESTION_NUMBER':
      return Object.assign({}, state, {
        question_number: state.question_number ? state.question_number - 1 : state.question_number,
      });

    case 'ASSESSMENT_SCORE':
      return Object.assign({}, state, {
        score: state.score.map((s, i) => (i + 1 == action.payload.question_number ? action.payload.score : s)),
      });

    case 'ASSESSMENT_ARRAY_SCORE':
      return Object.assign({}, state, {
        score: action.payload.score,
      });

    case 'CLEAR_ASSESSMENT_SCORE':
      return Object.assign({}, state, {
        score: [0, 0, 0, 0, 0, 0, 0],
      });

    default:
      return state;
  }
};

export default assessment_questions;
