import React from 'react';
// icons
import { ReactComponent as IconClock } from '../../../assets/image/questions/clock.svg';
// configs
import { dayToDate } from '../../../configs/tables/admin/clients';

const BegingInWeek: React.FC<any> = ({ item }: any) => {
  return (
    <div className={`begins__week`}>
      <IconClock />
      <span className="begins__text">
        {dayToDate({ numDay: item.group.date.dayOfWeek, time: item.group.date.end })}
      </span>
    </div>
  );
};

export default BegingInWeek;
