import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import InputField from '../../inputField';
import { Checkbox } from 'antd';
// icons
import { EyeOutlined } from '@ant-design/icons';
// styles
import '../style.scss';
import { PasswordEyeIcon } from '../../../atoms/utils/password-eye-icon';
import { useDispatch } from 'react-redux';
import eventAnalytics from 'redux/actions/analytics';

interface SubmitButtonType {
  name: string;
}

interface InputsType {
  errors: string | undefined;
  register: any;
  watch?: any;
}

export const LoginInputEmail: React.FC<InputsType> = ({ errors, register }: InputsType) => {
  return (
    <InputField name="email" label="Email" errors={errors} icon={false}>
      <input
        id="email"
        className={errors && 'error'}
        placeholder=""
        {...register('email', {
          required: 'enter your e-mail',
          pattern: {
            value: /^\s*[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\s*$/i,
            message: 'enter a valid e-mail address',
          },
        })}
      />
    </InputField>
  );
};

export const LoginInputPassword: React.FC<InputsType> = ({ errors, register }: InputsType) => {
  const [show, setShow] = useState<boolean>(false);

  const CurrentEyeIcon = PasswordEyeIcon({
    active: show,
    onClick: () => {
      setShow(!show);
    },
  });

  return (
    <InputField name="password" label="Password" errors={errors} icon={<CurrentEyeIcon />}>
      <input
        id="password"
        type={show ? '' : 'password'}
        className={errors && 'error'}
        placeholder=""
        {...register('password', {
          required: 'enter your password',
        })}
      />
    </InputField>
  );
};

export const LoginOptions: React.FC = () => {
  const dispatch = useDispatch();

  const onClickForgotPassword = () => {
    // dispatch(eventAnalytics.resetPassword());
  };

  const onChange = (e: any) => {
    if (e.target.checked) {
      return localStorage.setItem('remember', '1');
    }
    return localStorage.setItem('remember', '0');
  };
  return (
    <div className="field-container">
      <Checkbox onChange={onChange}>
        <span className="field-container__label">Remember Me</span>
      </Checkbox>
      <div onClick={onClickForgotPassword}>
        <Link to="/forgot-password" className="text-link">
          Forgot Password
        </Link>
      </div>
    </div>
  );
};

export const LoginSubmitButton: React.FC = () => {
  return (
    <button type="submit" className="btn-form">
      Sign In
    </button>
  );
};

export const SubmitButton: React.FC<SubmitButtonType> = ({ name }: SubmitButtonType) => {
  return (
    <button type="submit" className="btn-form">
      {name}
    </button>
  );
};

export const NewInputPassword: React.FC<InputsType> = ({ errors, register }: InputsType) => {
  const [show, setShow] = useState(false);
  return (
    <InputField
      name="password"
      label="Password"
      errors={errors}
      icon={
        <EyeOutlined
          style={{ color: '#92929d' }}
          onClick={() => {
            setShow(!show);
          }}
        />
      }
    >
      <input
        id="password"
        type={show ? '' : 'password'}
        className={errors && 'error'}
        placeholder=""
        {...register('password', {
          required: 'enter your password',
          minLength: {
            value: 6,
            message: 'Password must have at least 6 characters',
          },
        })}
      />
    </InputField>
  );
};

export const NewInputPasswordRepeatPassword: React.FC<InputsType> = ({ errors, register, watch }: InputsType) => {
  const [show, setShow] = useState(false);
  return (
    <>
      <InputField
        name="password_repeat"
        label="Confirm Password"
        errors={errors}
        icon={
          <EyeOutlined
            style={{ color: '#92929d' }}
            onClick={() => {
              setShow(!show);
            }}
          />
        }
      >
        <input
          id="password_repeat"
          type={show ? '' : 'password'}
          className={errors && 'error'}
          placeholder=""
          {...register('password_repeat', {
            required: 'enter your password',
            validate: (value: string) => value === watch('password') || 'The passwords do not match!',
          })}
        />
      </InputField>
    </>
  );
};
