import React, { FC } from 'react';
import { Spin } from 'antd';
import { useSelector } from 'react-redux';
// components
import RestartQuestions from '../../organisms/restartSubscriptions/firstStep';
import RecommendedGroupsFrom from '../../organisms/restartSubscriptions/recommendedGroupsForm';
import SuccessComponent from '../../organisms/restartSubscriptions/success';
import RestartSubscriptionsGroups from '../../organisms/restartSubscriptions/reccomendedGroups';
import ChangePayment from '../../organisms/restartSubscriptions/changePayment';
// types
import { stateType } from '../../../types/types';

const RestartSubscriptionsContainer: FC = () => {
  const state = useSelector((state: stateType) => state.restartSubscriptions.restartState);

  const stepController = () => {
    switch (state) {
      case 1:
        return <RestartQuestions />;

      case 2:
        return <RecommendedGroupsFrom />;

      case 3:
        return <RestartSubscriptionsGroups />;

      case 4:
        return <ChangePayment />;

      case 5:
        return <ChangePayment />;

      case 6:
        return <SuccessComponent />;

      default:
        return null;
    }
  };

  return (
    <Spin spinning={false} style={{ height: 'calc(100vh - 70px)' }}>
      {stepController()}
    </Spin>
  );
};

export default RestartSubscriptionsContainer;
