import React from 'react';
import { Controller } from 'react-hook-form';
import InputMask from 'react-input-mask';
// components
import InputField from '../../inputField';
// types
import { InputsType } from '../../../../types/form-data';

export const DateOfBirthInput: React.FC<InputsType> = ({ errors, control, defaultValue }: InputsType) => {
  return (
    <InputField name="date" label="Date of Birth " errors={errors}>
      <Controller
        control={control}
        name="date"
        render={({ field: { onChange, onBlur } }) => (
          <InputMask
            mask="99/99/9999"
            placeholder="MM/DD/YYY"
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={defaultValue ? defaultValue : ''}
          />
        )}
      />
    </InputField>
  );
};

export default DateOfBirthInput;
