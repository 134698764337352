import { LocalVideoTrack } from 'twilio-video';
import { useCallback, useState } from 'react';
import useVideoContext from '../useVideoContext/useVideoContext';
import useDevices from '../useDevices/useDevices';
import { useAppState } from '../../state';

export default function useLocalVideoToggle() {
  const { room, localTracks, getLocalVideoTrack, removeLocalVideoTrack, onError } = useVideoContext();
  const localParticipant = room?.localParticipant;
  const [isPublishing, setIspublishing] = useState(false);
  const { isVideoRemotelyLocked, setIsVideoRemotelyLocked } = useAppState();
  const { hasVideoInputDevices } = useDevices();

  const videoTrack = useCallback(() => {
    const track = localTracks.find(
      (track) => !track.name.includes('screen') && track.kind === 'video',
    ) as LocalVideoTrack;
    return track;
  }, [localTracks]);

  const disable = useCallback(() => {
    const localTrackPublication = localParticipant?.unpublishTrack(videoTrack());
    // TODO: remove when SDK implements this event. See: https://issues.corp.twilio.com/browse/JSDK-2592
    localParticipant?.emit('trackUnpublished', localTrackPublication);
    removeLocalVideoTrack();
  }, [videoTrack, localParticipant, removeLocalVideoTrack]);

  const enable = useCallback(() => {
    setIspublishing(true);
    getLocalVideoTrack()
      .then((track: LocalVideoTrack) => localParticipant?.publishTrack(track, { priority: 'low' }))
      .catch(onError)
      .finally(() => {
        setIspublishing(false);
      });
  }, [videoTrack, localParticipant, onError, getLocalVideoTrack]);

  const toggleVideoEnabled = useCallback(() => {
    if (!isVideoRemotelyLocked && !isPublishing) {
      if (videoTrack()) {
        disable();
      } else {
        enable();
      }
    }
  }, [videoTrack, isPublishing, isVideoRemotelyLocked, enable, disable]);

  const toggleVideoEnabledRemotely = useCallback(() => {
    if (!isPublishing) {
      if (videoTrack()) {
        disable();
        setIsVideoRemotelyLocked(true);
      } else {
        if (hasVideoInputDevices) {
          setIsVideoRemotelyLocked(false);
        }
        enable();
      }
    }
  }, [videoTrack, isPublishing, hasVideoInputDevices, enable, disable]);

  return [!!videoTrack(), toggleVideoEnabled, toggleVideoEnabledRemotely, isVideoRemotelyLocked] as const;
}
