import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
// actions
import { authActions } from '../../redux/actions/auth';
// components
import LoadingPage from './loading';

const HandlerAnotherRole: FC<any> = ({ role }: any) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const redirect_url = role ? `/login-${role}` : '/login-customer';

  useEffect(() => {
    localStorage.setItem(
      'redirect_url',
      JSON.stringify({ role: role || '', path: `${history.location.pathname}${history.location.search}` || '' }),
    );

    const data = {
      refreshToken: localStorage.getItem('refresh_token'),
    };

    dispatch(
      authActions.logout(data, () => {
        localStorage.removeItem('token');
        localStorage.removeItem('refresh_token');
        history.push(redirect_url);
      }),
    );
  }, []);
  return <LoadingPage />;
};

export default HandlerAnotherRole;
