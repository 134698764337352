import React, { createContext, Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { Client } from '@twilio/conversations';
import { Conversation } from '@twilio/conversations/lib/conversation';
import { Message } from '@twilio/conversations/lib/message';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import useParticipants from '../../../hooks/useParticipants/useParticipants';

type ChatContextType = {
  isChatWindowOpen: boolean;
  setIsChatWindowOpen: (isChatWindowOpen: boolean) => void;
  connect: (token: string) => void;
  hasUnreadMessages: boolean;
  messages: Message[];
  conversation: Conversation | null;
  connectPrivate: (token: string, identity: string, conversationUniqueName: string) => void;
  privateConversations: PrivateConversation[];
  privateMessages: PrivateMassages[];
  activeChatTab: string;
  setActiveChatTab: Dispatch<SetStateAction<string>>;
  tabHasUnreadMessages: string[];
};

type PrivateClient = {
  identity: string;
  conversationUniqueName: string;
  client: Client;
};

export type PrivateConversation = {
  identity: string;
  conversation: Conversation;
  handlerAssigned: boolean;
};

type PrivateMassages = {
  identity: string;
  messages: Message[];
};

export const ChatContext = createContext<ChatContextType>(null!);

export const ChatProvider: React.FC = ({ children }) => {
  const { room, onError } = useVideoContext();
  const isChatWindowOpenRef = useRef(false);
  const [isChatWindowOpen, setIsChatWindowOpen] = useState(false);
  const [conversation, setConversation] = useState<Conversation | null>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [hasUnreadMessages, setHasUnreadMessages] = useState(false);
  const [tabHasUnreadMessages, setTabHasUnreadMessages] = useState<string[]>([]);
  const [chatClient, setChatClient] = useState<Client>();
  const [privateChatClients, setPrivateChatClients] = useState<PrivateClient[]>([]);
  const [privateConversations, setPrivateConversations] = useState<PrivateConversation[]>([]);
  const [privateMessages, setPrivateMessages] = useState<PrivateMassages[]>([]);
  const [activeChatTab, setActiveChatTab] = useState('main');
  const participants = useParticipants();

  const connect = useCallback(
    (token: string) => {
      Client.create(token)
        .then((client) => {
          //@ts-ignore
          window.chatClient = client;
          setChatClient(client);
        })
        .catch((e) => {
          console.error(e);
          onError(new Error('There was a problem connecting to the Video Session'));
        });
    },
    [onError],
  );

  const connectPrivate = useCallback(
    (token: string, identity: string, conversationUniqueName: string) => {
      Client.create(token)
        .then((client) => {
          if (Array.isArray(privateChatClients)) {
            setPrivateChatClients([
              ...privateChatClients,
              { identity: identity, conversationUniqueName: conversationUniqueName, client: client },
            ]);
          } else {
            setPrivateChatClients([
              { identity: identity, conversationUniqueName: conversationUniqueName, client: client },
            ]);
          }
        })
        .catch((e) => {
          console.log('Error connecting to private chat');
          console.log(e);
        });
    },
    [onError],
  );

  useEffect(() => {
    if (conversation) {
      const handleMessageAdded = (message: Message) => {
        setMessages((oldMessages) => [...oldMessages, message]);
        setHasUnreadMessages(true);
        setTabHasUnreadMessages((tabs) => {
          if (!tabs.includes('main')) {
            tabs.push('main');
          }
          return tabs;
        });
      };
      conversation.getMessages().then((newMessages) => setMessages(newMessages.items));
      conversation.on('messageAdded', handleMessageAdded);
      return () => {
        conversation.off('messageAdded', handleMessageAdded);
      };
    }
  }, [conversation]);

  const handlePrivateMessageAdded = useCallback(
    (message, identity) => {
      if (Array.isArray(privateMessages) && privateMessages.length) {
        const messages = privateMessages.find((item) => {
          return item.identity == identity;
        });
        if (messages) {
          const otherMessages = privateMessages.filter((item) => item.identity != identity);
          setPrivateMessages([
            ...otherMessages,
            { identity: messages.identity, messages: [...messages.messages, message] },
          ]);
        } else {
          setPrivateMessages([...privateMessages, { identity: identity, messages: [message] }]);
        }
      } else {
        setPrivateMessages([{ identity: identity, messages: [message] }]);
      }
      setHasUnreadMessages(true);
      setTabHasUnreadMessages((tabs) => {
        if (!tabs.includes(identity)) {
          tabs.push(identity);
        }
        return tabs;
      });
    },
    [privateMessages],
  );

  useEffect(() => {
    if (Array.isArray(privateConversations) && privateConversations.length) {
      const cleanupHandlers = privateConversations.map((conversation) => {
        if (!conversation.handlerAssigned) {
          const handleMessageAdded = (message: Message) => {
            console.log('new message', message);
            handlePrivateMessageAdded(message, conversation.identity);
          };
          conversation.conversation.on('messageAdded', handleMessageAdded);
          return () => {
            conversation.conversation.off('messageAdded', handleMessageAdded);
          };
        }
      });
      return () => {
        cleanupHandlers.forEach((handler) => {
          if (typeof handler !== 'undefined') {
            handler();
          }
        });
      };
    }
  }, [privateConversations, handlePrivateMessageAdded]);

  useEffect(() => {
    console.log('HAS MESSAGES', isChatWindowOpen, tabHasUnreadMessages, activeChatTab, hasUnreadMessages);
    isChatWindowOpenRef.current = isChatWindowOpen;
    if (isChatWindowOpen && tabHasUnreadMessages.includes(activeChatTab)) {
      if (tabHasUnreadMessages.length <= 1) {
        setHasUnreadMessages(false);
      }
      setTabHasUnreadMessages((tabs) => {
        if (tabs.includes(activeChatTab)) {
          tabs.splice(tabs.indexOf(activeChatTab), 1);
        }
        return tabs;
      });
    }
  }, [isChatWindowOpen, activeChatTab, tabHasUnreadMessages, hasUnreadMessages]);

  useEffect(() => {
    if (room && chatClient) {
      console.log('Getting conversation by UniqueName: ' + room.sid);
      chatClient
        .getConversationByUniqueName(room.sid)
        .then((newConversation) => {
          //@ts-ignore
          window.chatConversation = newConversation;
          setConversation(newConversation);
        })
        .catch((e) => {
          console.error(e);
          onError(new Error('There was a problem getting the Conversation associated with this room.'));
        });
    }
  }, [room, chatClient, onError]);

  useEffect(() => {
    if (room && privateChatClients && privateChatClients.length) {
      privateChatClients.map((client) => {
        if (
          !Array.isArray(privateConversations) ||
          !privateConversations.length ||
          !privateConversations.some((item) => {
            return item.identity == client.identity;
          })
        ) {
          console.log(client.identity);
          client.client
            .getConversationByUniqueName(client.conversationUniqueName)
            .then((newConversation) => {
              if (Array.isArray(privateConversations)) {
                setPrivateConversations([
                  ...privateConversations,
                  { identity: client.identity, conversation: newConversation, handlerAssigned: false },
                ]);
              } else {
                setPrivateConversations([
                  { identity: client.identity, conversation: newConversation, handlerAssigned: false },
                ]);
              }
            })
            .catch((e) => {
              console.log(e);
            });
        }
      });
    }
  }, [room, privateChatClients]);

  return (
    <ChatContext.Provider
      value={{
        isChatWindowOpen,
        setIsChatWindowOpen,
        connect,
        hasUnreadMessages,
        messages,
        conversation,
        connectPrivate,
        privateConversations,
        privateMessages,
        activeChatTab,
        setActiveChatTab,
        tabHasUnreadMessages,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};
