import React from 'react';
import { makeStyles, Typography, Grid, Button, Theme, Hidden } from '@material-ui/core';
import LocalVideoPreview from './LocalVideoPreview/LocalVideoPreview';
import SettingsMenu from './SettingsMenu/SettingsMenu';
import { Steps } from '../PreJoinScreens';
import ToggleAudioButton from '../../Buttons/ToggleAudioButton/ToggleAudioButton';
import ToggleVideoButton from '../../Buttons/ToggleVideoButton/ToggleVideoButton';
import { useAppState } from '../../../../state';
import useChatContext from '../../../../hooks/useChatContext/useChatContext';
import useVideoContext from '../../../../hooks/useVideoContext/useVideoContext';
import { displayName } from '../../Helpers/helpers';
import CloseIcon from '../../../../icons/CloseIcon';
import useRoomInfoContext from '../../../../hooks/useRoomInfoContext/useRoomInfoContext';
import Loader from '../../Loader/Loader';
import { shallowEqual, useSelector } from 'react-redux';

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1em',
    textAlign: 'center',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '22px',
    color: '#333333',
  },
  sessionName: {
    textAlign: 'center',
  },
  marginTop: {
    marginTop: '1em',
  },
  buttons: {
    textAlign: 'center',
    padding: '23px 0 33px',
  },
  deviceButton: {
    width: '44px',
    height: '44px',
    border: '1px solid #BDBDBD',
    borderRadius: '50%',
    display: 'inline-block',
    boxSizing: 'border-box',
    textAlign: 'center',
    margin: '0 8px',
    padding: 11,
    minWidth: 0,
  },
  localPreviewContainer: {
    paddingRight: '0em',
    borderRadius: 9,
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      padding: '0 2.5em',
    },
  },
  joinButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      width: '80%',
      '& button': {
        margin: '0.5em 0',
      },
    },
  },
  mobileButtonBar: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '1.5em 0 1em',
    },
  },
  mobileButton: {
    padding: '0.8em 0',
    margin: 0,
  },
  continueButton: {
    backgroundColor: 'rgba(225, 157, 137, 1)',
    display: 'block',
    width: '100%',
    '&:hover': {
      backgroundColor: 'rgba(249, 112, 102, 1)',
    },
  },
  closeButton: {
    position: 'absolute',
    right: 20,
    width: 30,
    height: 30,
    backgroundColor: '#828282',
    borderRadius: '50%',
    padding: '7px',
  },
  hostContainer: {
    padding: '20px 0 33px',
    textAlign: 'center',
  },
  hostContainerInner: {
    display: 'inline-block',
  },
  initials: {
    float: 'left',
    width: 32,
    height: 32,
    borderRadius: '50%',
    backgroundColor: '#98A2B3',
    color: '#ffffff',
    paddingTop: 8,
    marginRight: 10,
  },
  hostName: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '18px',
  },
  hostGroup: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '10px',
    lineHeight: '18px',
  },
}));

interface DeviceSelectionScreenProps {
  name: string;
  roomName: string;
  setStep: (step: Steps) => void;
}

export default function DeviceSelectionScreen({ name, roomName, setStep }: DeviceSelectionScreenProps) {
  const classes = useStyles();
  const { getToken, isFetching, meetingType, setUserIsHost } = useAppState();
  const { connect: chatConnect } = useChatContext();
  const { connect: videoConnect, isAcquiringLocalTracks, isConnecting } = useVideoContext();
  const disableButtons = isFetching || isAcquiringLocalTracks || isConnecting;
  const { hostFirstName, hostLastName, hostTitle, hostInitials, hostLicenses } = useRoomInfoContext();
  const role = useSelector((state: any) => state.user.profile.role, shallowEqual);

  const handleJoin = () => {
    getToken(name, roomName, meetingType).then(({ token, is_host }) => {
      if (typeof is_host != 'undefined' && is_host) {
        setUserIsHost(true);
      }
      videoConnect(token);
      process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== 'true' && chatConnect(token);
    });
  };

  if (isFetching || isConnecting) {
    return (
      <Grid container justifyContent="center" alignItems="center" direction="column" style={{ height: '100%' }}>
        <div>
          <Loader />
        </div>
        <div>
          <Typography variant="body2" style={{ fontWeight: 'bold', fontSize: '16px' }}>
            Connecting
          </Typography>
        </div>
      </Grid>
    );
  }

  return (
    <>
      <button className={classes.closeButton} onClick={() => setStep(Steps.roomNameStep)}>
        <CloseIcon />
      </button>
      <h1 className={classes.gutterBottom}>Join {meetingType}</h1>
      <div className={classes.sessionName}>{displayName(roomName) + ' Group'}</div>
      <div className={classes.hostContainer}>
        <div className={classes.hostContainerInner}>
          <div className={classes.initials}>{hostInitials}</div>
          <span className={classes.hostName}>
            {hostTitle} {hostFirstName} {hostLastName} {hostLicenses.join(', ')}
          </span>
          <br />
          <span className={classes.hostGroup}>{displayName(roomName) + ' Group'}</span>
        </div>
      </div>
      <div className={classes.localPreviewContainer}>
        <LocalVideoPreview identity={name} />
      </div>
      <div className={classes.mobileButtonBar}>
        <Hidden mdUp>
          <ToggleAudioButton className={classes.mobileButton} disabled={disableButtons} iconColor={'#4F4F4F'} />
          <ToggleVideoButton className={classes.mobileButton} disabled={disableButtons} iconColor={'#4F4F4F'} />
        </Hidden>
      </div>
      <div className={classes.buttons}>
        <Hidden smDown>
          <ToggleAudioButton className={classes.deviceButton} disabled={disableButtons} iconColor={'#4F4F4F'} />
          <ToggleVideoButton className={classes.deviceButton} disabled={disableButtons} iconColor={'#4F4F4F'} />
          <SettingsMenu mobileButtonClass={classes.deviceButton} />
        </Hidden>
      </div>

      <div className={classes.joinButtons}>
        <Button
          variant="contained"
          className={classes.continueButton}
          color="primary"
          data-cy-join-now
          onClick={handleJoin}
          disabled={disableButtons}
        >
          Join Now
        </Button>
      </div>
    </>
  );
}
