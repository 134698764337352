import React, { FC } from 'react';
import { Modal } from 'antd';
import { ReactComponent as CloseIcon } from '../../../../assets/image/account/close4.svg';
import ImgAfter from '../../../../assets/image/questions/after.svg';

interface ForgotSuccessInterface {
  visible?: boolean;
  handleCancel: () => void;
}

const ForgotSuccessModal: FC<ForgotSuccessInterface> = ({ visible, handleCancel }: ForgotSuccessInterface) => {
  return (
    <>
      {visible && (
        <Modal
          closeIcon={<CloseIcon />}
          visible={visible}
          onOk={handleCancel}
          closable={true}
          onCancel={handleCancel}
          centered
          maskTransitionName="none"
          footer={null}
          className="forgot-password-modal"
        >
          <ModalSuccessContainer handleCancel={handleCancel} />
        </Modal>
      )}
    </>
  );
};

export default ForgotSuccessModal;

const ModalSuccessContainer = ({ handleCancel }: ForgotSuccessInterface) => {
  return (
    <div className="forgot-password-container">
      <div className="forgot-password-container__title">
        <img className="forgot-password-container__finish-ico" src={ImgAfter} alt="Finish icon" />
      </div>
      <div className="forgot-password-container__group-info">
        <p className="forgot-password-container__group-info_text">
          You’ve successfully submitted your password request. Please check your email.
        </p>
      </div>
      <div className="forgot-password-container__block">
        <button className="btn-form" onClick={handleCancel}>
          Done
        </button>
      </div>
    </div>
  );
};
