/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { call, put, select } from 'redux-saga/effects';
import queryString from 'query-string';
// api
import { apiAdminEvents } from '../../../api/events';
// actions
import eventsAction from '../../actions/admin_events';
import groupsAction from '../../actions/groups';
// helpers
import { mockEventsItems } from '../../../components/organisms/tables/admin/events';

export function* sagaGetAdminEvents() {
  yield put(eventsAction.loader(true));

  const getParams = (state) => state.events.params;
  const params = yield select(getParams);

  try {
    const response = yield call(apiAdminEvents.get, queryString.stringify(params));

    yield put(eventsAction.setEvents(response.data.data.items));

    yield put(eventsAction.loader(false));
  } catch (e) {
    // const error = e.response.data;
    // if (error.error_type === 'pagination_error' && /The currentPage must be inferior to/.test(error.message)) {
    //   const currentPage = Number(error.message.match(/\d+/g).pop());
    //
    //   yield eventsAction.params({ ...params, page: currentPage });
    //   yield put(eventsAction.get());
    // } else {
    //   yield put(eventsAction.loader(false));
    //
    //   showError();
    // }
    // TODO: add commented fragment and delete code after todo

    yield put(eventsAction.setEvents(mockEventsItems));
    yield put(eventsAction.loader(false));
  }
}

export function* sagaGetAdminEvent(action) {
  yield put(eventsAction.loader(true));

  try {
    const response = yield call(apiAdminEvents.getByID, action.id);
    const myArray = ['#82C43C', '#1891E5', '#FC5A5A', '#82C43C', '#1891E5', '#6C63FF', '#FC5A5A'];
    response.data.data.item.members.map((e) => {
      const randomItem = myArray[Math.floor(Math.random() * myArray.length)];
      e.color = randomItem;
      return e;
    });
    yield put(eventsAction.setEvent(response.data.data.item));
  } catch (e) {
    // showError(); TODO: add showError and delete code after todo

    const getItems = (state) => state.events.events;
    const items = yield select(getItems);
    const item = items.find((el) => el.id == action.id);

    yield put(eventsAction.setEvent(item));
  } finally {
    yield put(eventsAction.loader(false));
  }
}

export function* sagaAddEvent(action) {
  yield put(eventsAction.loaderModal(true));

  try {
    const response = yield call(apiAdminEvents.add, action.data);

    if (action.callback) {
      action.callback(response.data.data.item.id);
    }

    // yield sagaGetAdminEvents();
  } catch (e) {
    // showError();  TODO: add showError and delete code after todo
    const uid = new Date().getMilliseconds();
    yield put(
      eventsAction.setEvents([
        ...mockEventsItems,
        {
          ...action.data,
          id: uid,
        },
      ]),
    );
    if (action.callback) {
      action.callback(uid);
    }
  } finally {
    yield put(eventsAction.loaderModal(false));
  }
}

export function* sagaEditEvent(action) {
  yield put(eventsAction.loaderModal(true));

  try {
    const response = yield call(apiAdminEvents.edit, action.id, action.data);
    const myArray = ['#82C43C', '#1891E5', '#FC5A5A', '#82C43C', '#1891E5', '#6C63FF', '#FC5A5A'];
    response.data.data.item.members.map((e) => {
      const randomItem = myArray[Math.floor(Math.random() * myArray.length)];
      e.color = randomItem;
      return e;
    });
    yield put(eventsAction.setEvent(response.data.data.item));
    if (action.callback) {
      action.callback();
    }
  } catch (e) {
    // showError();  TODO: add showError and delete code after todo
    if (action.callback) {
      action.callback();
    }
  } finally {
    yield put(eventsAction.loaderModal(false));
  }
}

export function* sagaDeleteEventTopic(action) {
  yield put(eventsAction.loaderModal(true));

  try {
    const response = yield call(apiAdminEvents.deleteTopic, action.id, action.paramsId);

    if (action.callback) {
      action.callback();
    }
  } catch (e) {
    // showError();  TODO: add showError and delete code after todo
    if (action.callback) {
      action.callback();
    }
  } finally {
    yield put(eventsAction.loaderModal(false));
  }
}

export function* sagaSearchEventClients(action) {
  yield put(eventsAction.loaderModal(true));
  try {
    const response = yield call(apiAdminEvents.search, action.data);

    yield groupsAction.searchResult(response.data.data);
  } catch (e) {
    // showError();  TODO: add showError and delete code after todo
    //
    // yield groupsAction.searchResult({
    //   items: [{ id: 1, firstname: 'Test', lastname: 'User', licenses: null, title: null }],
    // });
  } finally {
    yield put(eventsAction.loaderModal(false));
  }
}

export function* sagaAddClientForEvent(action) {
  yield put(eventsAction.loaderModal(true));
  const getResult = (state) => state.groups.searchResult;
  const result = yield select(getResult);

  try {
    const response = yield call(apiAdminEvents.addClient, action.data.eventId, action.data.customerId);
    const myArray = ['#82C43C', '#1891E5', '#FC5A5A', '#82C43C', '#1891E5', '#6C63FF', '#FC5A5A'];
    response.data.data.item.members.map((e) => {
      const randomItem = myArray[Math.floor(Math.random() * myArray.length)];
      e.color = randomItem;
      return e;
    });
    yield put(eventsAction.setEvent(response.data.data.item));
  } catch (e) {
    // showError();  TODO: add showError and delete code after todo
  } finally {
    yield put(eventsAction.loaderModal(false));
  }
}

export function* sagaRemoveClientForEvent(action) {
  yield put(eventsAction.loaderModal(true));
  try {
    const response = yield call(apiAdminEvents.removeClient, action.data.eventId, action.data.customerId);
    const myArray = ['#82C43C', '#1891E5', '#FC5A5A', '#82C43C', '#1891E5', '#6C63FF', '#FC5A5A'];
    response.data.data.item.members.map((e) => {
      const randomItem = myArray[Math.floor(Math.random() * myArray.length)];
      e.color = randomItem;
      return e;
    });
    yield put(eventsAction.setEvent(response.data.data.item));
  } catch (e) {
    // showError();  TODO: add showError and delete code after todo
  } finally {
    yield put(eventsAction.loaderModal(false));
  }
}

export function* sagaRemoveEvent(action) {
  yield put(eventsAction.loader(true));
  try {
    const response = yield call(apiAdminEvents.removeEventById, action.payload.id);
    if (action.callback) {
      action.callback();
    }
  } catch (e) {
    console.log(e);
    yield put(eventsAction.loader(false));
    // showError();
  } finally {
    yield put(eventsAction.loader(false));
  }
}
