import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { shallowEqual, useSelector } from 'react-redux';
import { useWindowResize } from 'beautiful-react-hooks';
import { Select } from 'antd';
// components
import InputField from '../../inputField';
import { SelectTag } from '../../../atoms/utils/tag';
import SelectOption, { OptionType } from './SelectOption';
// icons
import { CaretDownOutlined } from '@ant-design/icons';
// types
import { stateType } from '../../../../types/types';
import { InputsType } from '../../../../types/form-data';

const { Option } = Select;

const ReasonsSelect: React.FC<InputsType> = ({ errors, control, defaultValue }: InputsType) => {
  const options: never[] = useSelector((state: stateType) => state.options.reasons, shallowEqual);
  const [width, setWidth] = useState(window.innerWidth);

  useWindowResize(() => {
    setWidth(window.innerWidth);
  });

  const getCount = () => {
    if (!width || width > 768) {
      return 'responsive';
    } else if (width < 768 && width > 480) {
      return 2;
    } else {
      return 1;
    }
  };

  return (
    <InputField name="reasons" label="I’ve been dealing with (Check all that apply)" errors={errors} icon={false}>
      <Controller
        control={control}
        name="reasons"
        render={({ field: { onChange, value, onBlur } }) => (
          <Select
            placeholder="Check all that apply"
            className={errors ? 'form-select form-select__error' : 'form-select'}
            dropdownClassName="form-dropdown"
            mode="multiple"
            style={{ width: '100%' }}
            onChange={onChange}
            onBlur={onBlur}
            showArrow
            allowClear
            showSearch={false}
            suffixIcon={<CaretDownOutlined />}
            searchValue={''}
            onDropdownVisibleChange={(open) => !open && onBlur()}
            defaultValue={defaultValue ? defaultValue : []}
            getPopupContainer={(trigger) => trigger.parentNode}
            maxTagCount={getCount()}
            tagRender={(props) => <SelectTag {...props} options={options} />}
            data-testid="reasons-test"
          >
            {options.map((option: OptionType) => (
              <Option key={option.key} value={option.value}>
                <SelectOption option={option} selectedValues={value} />
              </Option>
            ))}
          </Select>
        )}
      />
    </InputField>
  );
};

export default ReasonsSelect;
