import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
// components
import LoadingPage from './loading';
// actions
import { authActions } from '../../redux/actions/auth';
import { stateType } from 'types/types';
import assessmentQuestionsActions from 'redux/actions/assessment_questions';

interface LayoutDefault {
  component: React.ComponentType<RouteProps>;
  path?: string;
  exact?: boolean;
  role?: string;
}

const LayoutAuth: React.FC<LayoutDefault> = ({ component: Component, ...args }: LayoutDefault) => {
  const auth = useSelector((state: any) => state.app.auth);
  const status: string = useSelector((state: stateType) => state.assessment_questions.status, shallowEqual);
  const score = useSelector((state: stateType) => state.assessment_questions.score, shallowEqual);
  const dispatch = useDispatch();

  return (
    <Route
      render={() => {
        if (auth === 'loading') {
          return <LoadingPage />;
        } else {
          const token = localStorage.getItem('token');

          if (token && auth === 'auth' && args.path != '/anxiety-assessment') {
            const data = {
              refreshToken: localStorage.getItem('refresh_token'),
            };

            dispatch(
              authActions.logout(data, () => {
                localStorage.removeItem('token');
                localStorage.removeItem('refresh_token');
                assessmentQuestionsActions.status(status);
                assessmentQuestionsActions.setArrayScore(score);
              }),
            );
          }

          return (
            <Route
              path={args.path}
              render={() => {
                return <Component {...args} />;
              }}
            />
          );
        }
      }}
    />
  );
};

export default LayoutAuth;
