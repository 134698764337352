import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Spin } from 'antd';
import moment from 'moment-timezone';
import Moment from 'react-moment';
// componets
import FormRescheduleGroup from '../../../forms/reschedule/group';
import BeginsIn from 'components/molecules/beginsIn';
import { CancelButton } from 'components/atoms/form/CancelButton';
// types
import { stateType } from '../../../../../types/types';
// helps
import { mapping } from '../../../../../api/mapping/request/reschedule';
// configure
import { timeConvertor, week_days } from 'configs/tables/admin/clients';
// actions
import groupsAction from 'redux/actions/groups';
// icons
import Icon from '@ant-design/icons';
import { ReactComponent as CloseIcon } from '../../../../../assets/image/account/close4.svg';
import { ReactComponent as IconCalendar } from 'assets/image/dropdown/calendar.svg';
// styles
import './style.scss';
// actions
import sessionsAction from 'redux/actions/sessions';

const RescheduleGroup: React.FC<any> = () => {
  const [visible, setVisible] = useState(false);
  const [success, setSuccess] = useState(false);
  const [done, setDone] = useState(false);

  const loader = useSelector((state: stateType) => state.groups.loaderModal);
  const item = useSelector((state: stateType) => state.groups.item);
  const next: any = useSelector((state: stateType) => state.sessions.items.next);

  const [newDate, setNewDate] = useState({
    dayOfWeek: item.group.dayOfWeek,
    end: item.group.end,
    start: item.group.start,
  });

  const momentDayNumber = newDate.dayOfWeek !== 7 ? newDate.dayOfWeek : 0;

  const momentDay = moment(momentDayNumber, 'day');

  const startNextSession =
    next?.[0]?.start && visible ? moment(next?.[0]?.start).day(moment(momentDay, 'day').format('dddd')) : momentDay;

  const startTimeString = startNextSession.clone().add(newDate.start?.format('HH:mm')).isBefore(moment())
    ? startNextSession.add(1, 'week').toString()
    : startNextSession.set({ hour: newDate.start?.format('HH'), minute: newDate.start?.format('mm') }).toString();

  const properties = {
    groupDate: newDate.dayOfWeek,
    groupEnd: newDate.end,
    groupStart: newDate.start,

    date: item.group.date.dayOfWeek,
    start: item.group.date.start,
    end: item.group.date.end,
    startTimeString: startTimeString,
  };

  const open = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onSuccess = () => {
    setSuccess(true);
  };

  const onDone = () => {
    setDone(true);
  };

  return (
    <>
      <button className="btn-form" onClick={open}>
        Reschedule Group
      </button>
      {visible && (
        <Modal
          visible={visible}
          onOk={handleOk}
          closable={true}
          onCancel={handleCancel}
          centered
          maskTransitionName="none"
          footer={null}
          className="reschedule-group-modal"
          closeIcon={<CloseIcon />}
        >
          {!success && !done && (
            <Spin spinning={loader}>
              <ModalContainer onSuccess={onSuccess} groupID={item.group.id} setNewDate={setNewDate} />
            </Spin>
          )}
          {success && !done && (
            <Spin spinning={loader}>
              <ModalSuccess
                handleCancel={handleCancel}
                item={item}
                onDone={onDone}
                groupID={item.group.id}
                setSuccess={setSuccess}
                properties={properties}
                newDate={newDate}
              />
            </Spin>
          )}
          {done && (
            <Spin spinning={loader}>
              <ModalDone handleCancel={handleCancel} item={item} properties={properties} />
            </Spin>
          )}
        </Modal>
      )}
    </>
  );
};

export default RescheduleGroup;

const ModalContainer = ({ onSuccess, groupID, setNewDate }: any) => {
  return (
    <div className="reschedule-session-container">
      <div className="reschedule-session-container__title">
        What day and time would you like to Reschedule this group to?
      </div>
      <div className="reschedule-session-container__block">
        <FormRescheduleGroup onSuccess={onSuccess} groupID={groupID} setNewDate={setNewDate} />
      </div>
    </div>
  );
};

const ModalSuccess = ({ handleCancel, item, onDone, newDate, groupID, setSuccess, properties }: any) => {
  const { groupDate, start, end, date, groupStart, groupEnd, startTimeString } = properties;

  const dispatch = useDispatch();

  const onSubmit = (data: any) => {
    onDone();
    dispatch(groupsAction.reschedule(mapping.rescheduleGroupBuild(data), groupID));
  };

  useEffect(() => {
    return () => {
      setSuccess(false);
    };
  }, []);

  return (
    <div className="rechedule-session-container reschedule-group-success-container">
      <div className="reschedule-session-container__title">
        Are you sure you want to reschedule the group to <br />
        the following time below?
      </div>
      <div className="form-reschedule-session__main reschedule-success-container__main">
        <div className="form-reschedule-session__row">
          <div className="different-container">
            <div className="different-container__old">
              <div className="different-container__titles">
                <div className="different-container__title">Old Time</div>
                <div className="different-container__label">
                  <Icon component={IconCalendar} />
                  {`${week_days[date - 1]}s`}
                </div>
              </div>
              <div className="different-container__block">
                <div className="session-item">
                  <div className="session-item__avatar">
                    {item.medicalGroup?.avatar ? (
                      <img src={item.group?.avatar?.url} alt="" className="session-item__avatar-img" />
                    ) : (
                      <div className="session-item__home-avatar-txt">{item.group.name[0]}</div>
                    )}
                  </div>
                  <div className="session-item__body">
                    <div className="session-item__header">
                      <div className="session-item__title">{item.group.name} Group</div>
                    </div>
                    <div className="session-item__item">
                      <div className="session-item__label">Time:</div>
                      <div className="session-item__value">
                        {timeConvertor(start)}
                        {' - '}
                        {timeConvertor(end, 'type-time')} ET
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="different-container__new">
              <div className="different-container__titles">
                <div className="different-container__title">New Time</div>
                <div className="different-container__label">
                  <Icon component={IconCalendar} />
                  {`${week_days[groupDate - 1]}s`}
                </div>
              </div>
              <div className="different-container__block">
                <div className="session-item">
                  <div className="session-item__avatar">
                    {item.medicalGroup?.avatar ? (
                      <img src={item.group?.avatar?.url} alt="" className="session-item__avatar-img" />
                    ) : (
                      <div className="session-item__home-avatar-txt">{item.group.name[0]}</div>
                    )}
                  </div>
                  <div className="session-item__body">
                    <div className="session-item__header">
                      <div className="session-item__title">{item.group.name} Group</div>
                    </div>
                    <div className="session-item__item">
                      <div className="session-item__label">Time:</div>
                      <div className="session-item__value">
                        <Moment format="h:mm A">{groupStart}</Moment>
                        {' - '}
                        <Moment format="h:mm A">{groupEnd}</Moment> ET
                      </div>
                    </div>
                    <BeginsIn start={startTimeString} status={item.status} isAddBackground />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-reschedule-session__footer">
        <button className="btn-form" onClick={() => onSubmit(newDate)}>
          Yes
        </button>
        <CancelButton name={'Cancel'} cancel={handleCancel} />
      </div>
    </div>
  );
};

const ModalDone = ({ handleCancel, item, properties }: any) => {
  const { groupDate, start, end, date, groupStart, groupEnd, startTimeString } = properties;

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(groupsAction.id(item.group.id));
      dispatch(sessionsAction.get(item.group.id));
    };
  }, []);

  return (
    <div className="rechedule-session-container reschedule-group-success-container">
      <div className="reschedule-session-container__title">
        You’ve successfully rescheduled the group to the <br />
        following time below
      </div>
      <div className="form-reschedule-session__main reschedule-success-container__main">
        <div className="form-reschedule-session__row">
          <div className="different-container">
            <div className="different-container__old">
              <div className="different-container__titles">
                <div className="different-container__title">Old Time</div>
                <div className="different-container__label">
                  <Icon component={IconCalendar} />
                  {`${week_days[date - 1]}s`}
                </div>
              </div>
              <div className="different-container__block">
                <div className="session-item">
                  <div className="session-item__avatar">
                    {item.medicalGroup?.avatar ? (
                      <img src={item.group?.avatar?.url} alt="" className="session-item__avatar-img" />
                    ) : (
                      <div className="session-item__home-avatar-txt">{item.group.name[0]}</div>
                    )}
                  </div>
                  <div className="session-item__body">
                    <div className="session-item__header">
                      <div className="session-item__title">{item.group.name} Group</div>
                    </div>
                    <div className="session-item__item">
                      <div className="session-item__label">Time:</div>
                      <div className="session-item__value">
                        {timeConvertor(start)}
                        {' - '}
                        {timeConvertor(end, 'type-time')} ET
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="different-container__new">
              <div className="different-container__titles">
                <div className="different-container__title">New Time</div>
                <div className="different-container__label">
                  <Icon component={IconCalendar} />
                  {`${week_days[groupDate - 1]}s`}
                </div>
              </div>
              <div className="different-container__block">
                <div className="session-item">
                  <div className="session-item__avatar">
                    {item.medicalGroup?.avatar ? (
                      <img src={item.group?.avatar?.url} alt="" className="session-item__avatar-img" />
                    ) : (
                      <div className="session-item__home-avatar-txt">{item.group.name[0]}</div>
                    )}
                  </div>
                  <div className="session-item__body">
                    <div className="session-item__header">
                      <div className="session-item__title">{item.group.name} Group</div>
                    </div>
                    <div className="session-item__item">
                      <div className="session-item__label">Time:</div>
                      <div className="session-item__value">
                        <Moment tz="America/New_York" format="h:mm A">
                          {groupStart}
                        </Moment>
                        {' - '}
                        <Moment tz="America/New_York" format="h:mm A">
                          {groupEnd}
                        </Moment>{' '}
                        ET
                      </div>
                    </div>
                    <BeginsIn start={startTimeString} status={item.status} isAddBackground />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-reschedule-session__footer">
        <button className="btn-form" onClick={handleCancel}>
          Done
        </button>
      </div>
    </div>
  );
};
