import React, { FC } from 'react';
import { motion } from 'framer-motion';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
// components
import EventsSearchSelect from '../../../molecules/filters/events';
// configs
import { animSearch } from '../../../../tools/animations';
import useDidMountEffect from '../../../../configs/hooks/useDidMountEffect';
import { getSearchEvent } from '../../../../tools/helpFunctions';
// types
import { stateType } from '../../../../types/types';
import { roleType } from '../../../../types/commonPropTypes';
import upcomingAction from '../../../../redux/actions/upcoming_events';
import { Select } from 'antd';
// actions

const SearchEventsSelects: FC<roleType> = ({ role }: roleType) => {
  const allParams: any = useSelector((state: stateType) => state.upcoming_events.allParams, shallowEqual);
  // const loaderEvents: boolean = useSelector((state: stateType) => state.upcoming_events.loaderEvents, shallowEqual);
  // const loaderPasts: boolean = useSelector((state: stateType) => state.upcoming_events.loaderPosts, shallowEqual);
  const type: string = useSelector((state: stateType) => state.upcoming_events.searchType, shallowEqual);
  const lengthsFilter: any = useSelector((state: stateType) => state.upcoming_events.lengthsFilter, shallowEqual);
  const ordersFilter: any = useSelector((state: stateType) => state.upcoming_events.ordersFilter, shallowEqual);
  const topicsFilter: any = useSelector((state: stateType) => state.upcoming_events.topicsFilter, shallowEqual);
  const typesFilter: any = useSelector((state: stateType) => state.upcoming_events.typesFilter, shallowEqual);
  const dispatch = useDispatch();
  const defaultOptions = [
    { id: 1, name: 'Webinar' },
    { id: 2, name: 'Webinar' },
  ];

  const convertFormatSelect = (array: any) => {
    const newArray: { label: any; value: any }[] = [];
    array?.map((e: any) => {
      const b = {
        label: e.name,
        value: e?.value ? e?.value : e?.id,
      };
      newArray.push(b);
    });

    return newArray;
  };

  const onChange = (type: string, values: any) => {
    if (role === 'ADMIN') {
      switch (type) {
        case 'length':
          dispatch(upcomingAction.allParams({ ...allParams, length: values, page: 1, per_page: 10 }));
          break;
        case 'topic':
          dispatch(upcomingAction.allParams({ ...allParams, topic: values, page: 1, per_page: 10 }));
          break;
        case 'type':
          dispatch(upcomingAction.allParams({ ...allParams, type: values, page: 1, per_page: 10 }));
          break;
        case 'sort':
          dispatch(upcomingAction.allParams({ ...allParams, sort: values, page: 1, per_page: 10 }));
          break;
        default:
          break;
      }
    } else {
      switch (type) {
        case 'length':
          dispatch(upcomingAction.allParams({ ...allParams, length: values }));
          break;
        case 'topic':
          dispatch(upcomingAction.allParams({ ...allParams, topic: values }));
          break;
        case 'type':
          dispatch(upcomingAction.allParams({ ...allParams, type: values }));
          break;
        case 'sort':
          dispatch(upcomingAction.allParams({ ...allParams, sort: values }));
          break;
        case 'clear':
          dispatch(upcomingAction.clearAllParams());
          break;
        default:
          break;
      }
    }
  };

  const clearFilters = () => {
    onChange('clear', []);
  };

  useDidMountEffect(() => {
    getSearchEvent(type, dispatch);
  }, [allParams]);

  return (
    <motion.div
      className="navigation-table-items"
      variants={animSearch}
      initial="initial"
      animate="visible"
      exit="exit"
    >
      <div className="search-event-selects">
        <EventsSearchSelect
          options={convertFormatSelect(lengthsFilter?.items)}
          placeholder="Length"
          change={(e: any) => onChange('length', e)}
          defaultValue={allParams?.length}
          value={allParams?.length}
          disabled={false}
        />
        <EventsSearchSelect
          options={convertFormatSelect(topicsFilter?.items)}
          placeholder="Topic"
          change={(e: any) => onChange('topic', e)}
          defaultValue={allParams?.topic}
          value={allParams?.topic}
          disabled={false}
        />
        {type !== 'past' ? (
          <EventsSearchSelect
            options={convertFormatSelect(typesFilter?.items)}
            placeholder="Type"
            change={(e: any) => onChange('type', e)}
            defaultValue={allParams?.type}
            disabled={false}
            value={allParams?.type}
          />
        ) : (
          <Select
            className="form-select"
            dropdownClassName="form-dropdown"
            placeholder={'Webinar'}
            style={{ width: '100%' }}
            onChange={(value: any) => {
              console.log(value);
            }}
            options={convertFormatSelect(defaultOptions)}
            allowClear
            showSearch
            suffixIcon={<div />}
            defaultValue={'Webinar'}
            filterOption={(input, option: any): any => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            disabled={true}
          />
        )}
        <EventsSearchSelect
          options={convertFormatSelect(ordersFilter?.items)}
          placeholder="Sort"
          change={(e: any) => onChange('sort', e)}
          defaultValue={allParams?.sort}
          disabled={false}
          value={allParams?.sort}
        />
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
          <button
            style={{
              backgroundColor: '#f5f6fa',
              borderRadius: 10,
              fontSize: 14,
              color: '#1891e5',
              width: 200,
              height: 38,
            }}
            onClick={clearFilters}
          >
            Clear filters
          </button>
        </div>
      </div>
    </motion.div>
  );
};

export default SearchEventsSelects;
