import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Modal, Spin } from 'antd';
import cn from 'classnames';
// components
import { TherapistSmBox } from '../../adminChangeGroup';
// types
import { stateType } from '../../../../../types/types';
// styles
import './style.scss';
// icons
import { ReactComponent as CloseIcon } from 'assets/image/account/close4.svg';
import ImgAfter from '../../../../../assets/image/questions/after.svg';
// actions
import therapistAction from '../../../../../redux/actions/therapist';

type ArchiveTherapistType = {
  typeAction: 'archive' | 'delete';
  canDelete: any;
};

const ArchiveTherapist: React.FC<ArchiveTherapistType> = ({ typeAction, canDelete }: ArchiveTherapistType) => {
  const [visible, setVisible] = useState(false);
  const [success, setSuccess] = useState(false);
  const loader = useSelector((state: stateType) => state.therapists.loaderModal, shallowEqual);
  const item = useSelector((state: stateType) => state.therapists.item, shallowEqual);

  const open = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onSuccess = () => {
    setSuccess(true);
  };

  return (
    <>
      <button
        className={cn('', {
          'buttons-container__archive-button': typeAction === 'archive',
          'buttons-container__delete-button': typeAction === 'delete',
        })}
        onClick={open}
      >
        {typeAction === 'delete' ? 'Delete' : 'Archive'} Therapist Profile
      </button>
      {visible && (
        <Modal
          closeIcon={<CloseIcon />}
          visible={visible}
          onOk={handleOk}
          closable={true}
          onCancel={handleCancel}
          centered
          maskTransitionName="none"
          footer={null}
          className={canDelete ? 'archive-therapist-modal' : 'archive-therapist-modalSm'}
        >
          {!success ? (
            <Spin spinning={loader}>
              <ModalContainer
                onSuccess={onSuccess}
                item={item}
                typeAction={typeAction}
                canDelete={canDelete}
                handleCancel={handleCancel}
              />
            </Spin>
          ) : (
            <ModalSuccessContainer
              handleCancel={handleCancel}
              item={item}
              setSuccess={setSuccess}
              typeAction={typeAction}
            />
          )}
        </Modal>
      )}
    </>
  );
};

export default ArchiveTherapist;

const ModalContainer = ({ onSuccess, item, typeAction, canDelete, handleCancel }: any) => {
  const dispatch = useDispatch();
  const action = () => {
    if (typeAction === 'archive') {
      dispatch(therapistAction.archive(item.id, onSuccess));
    } else {
      dispatch(therapistAction.delete(item.id, onSuccess));
    }
  };

  if (!canDelete && typeAction === 'delete') {
    return (
      <div className="archive-therapist-container">
        <div className="archive-therapist-container__title">
          This therapist has an active group. Please, remove therapist from the active group
        </div>
        <div className="archive-therapist-container__block">
          <button className="btn-form" onClick={handleCancel}>
            Ok
          </button>
        </div>
      </div>
    );
  } else {
    return (
      <div className="archive-therapist-container">
        <div className="archive-therapist-container__title">
          Are you sure you want to {typeAction === 'delete' ? 'delete' : 'archive'} the following therapist?
        </div>
        <div className="archive-therapist-container__group-info">
          <TherapistSmBox item={item} />
        </div>
        <div className="archive-therapist-container__block">
          <button className="btn-form" onClick={action}>
            Confirm
          </button>
        </div>
      </div>
    );
  }
};

const ModalSuccessContainer = ({ handleCancel, item, setSuccess, typeAction }: any) => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    return () => {
      setSuccess(false);
      if (typeAction === 'archive') {
        dispatch(therapistAction.get(item.id));
      } else {
        history.push('/all_therapists');
      }
    };
  }, []);

  return (
    <div className="archive-therapist-container">
      <div className="archive-therapist-container__title">
        <img className="archive-therapist-container__finish-ico" src={ImgAfter} alt="Finish icon" />
        <div>You’ve successfully {typeAction === 'delete' ? 'delete' : 'archive'} the therapist!</div>
      </div>
      <div className="archive-therapist-container__group-info">
        <TherapistSmBox item={item} />
      </div>
      <div className="archive-therapist-container__block">
        <button className="btn-form" onClick={handleCancel}>
          Done
        </button>
      </div>
    </div>
  );
};
