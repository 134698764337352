import React, { useEffect, useState, FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import cn from 'classnames';
import _ from 'lodash';
// actions
import clientsAction from 'redux/actions/clients';
import groupsAction from 'redux/actions/groups';
// types
import { stateType } from 'types/types';
// icons
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
// styles
import './style.scss';

interface SortBlockType {
  type: 'clients' | 'groups';
}

export const SortBlock: FC<SortBlockType> = ({ type }: SortBlockType) => {
  const [action, setAction] = useState<any>(null);
  const sort: any = useSelector((state: stateType) => state[type].order);
  const dispatch = useDispatch();

  useEffect(() => {
    type === 'clients' && setAction(clientsAction);
    type === 'groups' && setAction(groupsAction);

    return () => {
      type === 'clients' && clientsAction.setOrder({});
      type === 'groups' && groupsAction.setOrder({});
    };
  }, []);

  const sortBy = (value: string) => {
    action.setOrder({
      [value]: sort[value] === 'asc' ? 'desc' : 'asc',
    });

    dispatch(action.get());
  };

  const buttonsWrapperController = () => {
    switch (type) {
      case 'clients':
        return (
          <>
            <button
              className={cn('btn-sort', { 'btn-sort btn-sort_active': sort?.name !== undefined })}
              onClick={() => sortBy('name')}
            >
              {sort?.name && sort?.name === 'desc' ? 'From Z to A' : 'From A to Z'}
            </button>
            <button
              className={cn('btn-sort', { 'btn-sort btn-sort_active': sort?.coming_session !== undefined })}
              onClick={() => sortBy('coming_session')}
            >
              {'Coming session'}
              {sort?.coming_session && sort?.coming_session === 'asc' && <CaretDownOutlined />}
              {sort?.coming_session && sort?.coming_session === 'desc' && <CaretUpOutlined />}
            </button>
          </>
        );
      case 'groups':
        return (
          <>
            <button
              className={cn('btn-sort', { 'btn-sort btn-sort_active': sort?.topic_group_name !== undefined })}
              onClick={() => sortBy('topic_group_name')}
            >
              {sort?.topic_group_name && sort?.topic_group_name === 'desc' ? 'From Z to A' : 'From A to Z'}
            </button>
            <button
              className={cn('btn-sort', { 'btn-sort btn-sort_active': sort?.coming_session !== undefined })}
              onClick={() => sortBy('coming_session')}
            >
              {'Coming session'}
              {sort?.coming_session && sort?.coming_session === 'asc' && <CaretDownOutlined />}
              {sort?.coming_session && sort?.coming_session === 'desc' && <CaretUpOutlined />}
            </button>
          </>
        );

      default:
        break;
    }
  };

  return (
    <div className="sort-block-wrap">
      <div className="sort-block-wrap__container">{buttonsWrapperController()}</div>
    </div>
  );
};

export const SortBlockGroups: FC = () => {
  return (
    <div className="sort-block-wrap">
      <div className="sort-block-wrap__container">
        <button className="btn-sort btn-sort_active">From A to Z</button>
        <button className="btn-sort">Coming session</button>
      </div>
    </div>
  );
};
