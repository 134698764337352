import React from 'react';
import { useDispatch } from 'react-redux';

// actions
import ChoiceGroupAction from 'redux/actions/choice_group';
import eventAnalytics from '../../../../../redux/actions/analytics';

interface ButtonWrapperInterface {
  children: React.ReactNode;
  group: any;
}

const ButtonWrapper: React.FC<ButtonWrapperInterface> = ({ children, group }: ButtonWrapperInterface) => {
  const dispatch = useDispatch();

  return (
    <div
      className="btn-form_join-group"
      onClick={() => {
        group?.id && dispatch(ChoiceGroupAction.join(group.id));
        group?.id &&
          dispatch(eventAnalytics.analytics('sign-up-three', { matchGroupIds: [group.id], newMatchGroupIds: [] }));
      }}
    >
      {children}
    </div>
  );
};

export default ButtonWrapper;
