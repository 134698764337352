import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core';
import ChatWindow from '../ChatWindow/ChatWindow';
import ParticipantList from '../ParticipantList/ParticipantList';
import MainParticipant from '../MainParticipant/MainParticipant';
import BackgroundSelectionDialog from '../BackgroundSelectionDialog/BackgroundSelectionDialog';
import useChatContext from '../../../hooks/useChatContext/useChatContext';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { useAppState } from '../../../state';
import useLocalAudioToggle from '../../../hooks/useLocalAudioToggle/useLocalAudioToggle';
import useLocalVideoToggle from '../../../hooks/useLocalVideoToggle/useLocalVideoToggle';
import FileWindow from '../FileWindow/FileWindow';
import useFilesContext from '../../../hooks/useFilesContext/useFilesContext';
import useParticipantMenuContext from '../../../hooks/useParticipantMenuContext/useParticipantMenuContext';
import ParticipantMenu from '../ParticipantMenu/ParticipantMenu';

const useStyles = makeStyles((theme: Theme) => {
  const totalMobileSidebarHeight = `${
    theme.sidebarMobileHeight + theme.sidebarMobilePadding * 2 + theme.participantBorderWidth
  }px`;
  return {
    container: {
      position: 'relative',
      height: `calc(100% - ${theme.mobileTopBarHeight}px)`,
      display: 'grid',
      gridTemplateColumns: `1fr ${theme.sidebarWidth}px`,
      gridTemplateRows: '100%',
      backgroundColor: `${theme.palette.background.default}`,
      padding: '0 32px',
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: `100%`,
        gridTemplateRows: `calc(100% - ${totalMobileSidebarHeight}) ${totalMobileSidebarHeight}`,
      },
    },
    header: {
      width: '100%',
      height: '94px',
      backgroundColor: `${theme.palette.background.default}`,
    },
    rightDrawerOpen: {
      gridTemplateColumns: `1fr ${theme.sidebarWidth}px ${theme.rightDrawerWidth}px`,
    },
  };
});

export default function Room() {
  const classes = useStyles();
  const { isChatWindowOpen } = useChatContext();
  const { isFileWindowOpen } = useFilesContext();
  const { isParticipantMenuOpen } = useParticipantMenuContext();
  const { isBackgroundSelectionOpen } = useVideoContext();
  const { userLayout, meetingType, userIsHost } = useAppState();
  const [isAudioEnabled, toggleAudioEnabled, toggleAudioEnabledRemotely] = useLocalAudioToggle();
  const [isVideoEnabled, toggleVideoEnabled, toggleVideoEnabledRemotely] = useLocalVideoToggle();

  useEffect(() => {
    if (meetingType == 'webinar' && !userIsHost) {
      if (isAudioEnabled) {
        toggleAudioEnabledRemotely();
      }
      if (isVideoEnabled) {
        toggleVideoEnabledRemotely();
      }
    }
  }, []);

  return (
    <div
      className={clsx(classes.container, {
        [classes.rightDrawerOpen]:
          isChatWindowOpen || isBackgroundSelectionOpen || isFileWindowOpen || isParticipantMenuOpen,
      })}
    >
      {userLayout != 'grid' || meetingType == 'webinar' ? <MainParticipant /> : ''}
      {meetingType != 'webinar' ? <ParticipantList /> : ''}
      <FileWindow />
      <ChatWindow />
      <ParticipantMenu />
    </div>
  );
}
