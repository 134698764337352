import React from 'react';
// components
import SimpleHeader from '../../organisms/headers/simple/simple-header';
// styles
import './style.scss';

interface SimpleTemplateType {
  children: React.ReactNode;
  title?: string;
  description?: string;
}
const ErrorTemplate: React.FC<SimpleTemplateType> = ({ children, title, description }: SimpleTemplateType) => {
  return (
    <div className="simple-template">
      <SimpleHeader />
      <div className="simple-template__body">
        <div className="simple-template__error-container">
          {!description && <h2 className="simple-template__error-text">{title}</h2>}
          <p className="simple-template__error-text">{description}</p>
          {children}
        </div>
      </div>
    </div>
  );
};

export default ErrorTemplate;
