import React from 'react';
import { Controller } from 'react-hook-form';
import moment from 'moment';
import InputMask from 'react-input-mask';
import { DatePicker } from 'antd';

// components
import InputField from '../../../inputField';
// styles
import '../../style.scss';

interface SubmitButtonType {
  name: string;
  disabled?: boolean;
}

interface InputsType {
  errors: string | undefined;
  register: any;
  watch?: any;
  control?: any;
}

export const RescheduleSessionRange: React.FC<InputsType> = ({ errors, control }: InputsType) => {
  return (
    <InputField name="range" label="Choose a Reschedule Date" errors={errors} icon={false}>
      <Controller
        control={control}
        name="range"
        rules={{ required: 'choose at least one option' }}
        render={({ field: { onChange } }) => (
          <DatePicker.RangePicker
            className="form-time-select"
            ranges={{
              Today: [moment(), moment()],
              'This Month': [moment().startOf('month'), moment().endOf('month')],
            }}
            disabledDate={(current) => {
              return current && current <= moment().subtract(1, 'day');
            }}
            showTime
            format="YYYY/MM/DD h:mm"
            onChange={onChange}
            use12Hours={true}
            suffixIcon={null}
          />
        )}
      />
    </InputField>
  );
};

export const RescheduleStart: React.FC<InputsType> = ({ errors, control }: InputsType) => {
  return (
    <InputField name="start" label="Start time" errors={errors}>
      <Controller
        control={control}
        name="start"
        rules={{ required: 'enter your time' }}
        render={({ field: { onChange } }) => (
          <InputMask
            mask="99:99"
            placeholder="00:00"
            onChange={(value: any) => {
              onChange(value);
            }}
          />
        )}
      />
    </InputField>
  );
};

export const RescheduleEnd: React.FC<InputsType> = ({ errors, control }: InputsType) => {
  return (
    <InputField name="end" label="End time" errors={errors}>
      <Controller
        control={control}
        name="end"
        rules={{ required: 'enter your time' }}
        render={({ field: { onChange } }) => (
          <InputMask
            mask="99:99"
            placeholder="00:00"
            onChange={(value: any) => {
              onChange(value);
            }}
          />
        )}
      />
    </InputField>
  );
};

export const SubmitButton: React.FC<SubmitButtonType> = ({ name, disabled }: SubmitButtonType) => {
  return (
    <button type="submit" className="btn-form" disabled={disabled ? disabled : false}>
      {name}
    </button>
  );
};
