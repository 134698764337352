import React, { FC } from 'react';
import { Switch } from 'antd';
import { useDispatch } from 'react-redux';
// style
import './style.scss';
// actions
import clientAction from '../../../redux/actions/client';
import therapistAction from '../../../redux/actions/therapist';

const EnabledNotifications: FC<any> = ({ item, classes, isTherapist }: any) => {
  const { enableEmails, enableSms, id }: any = item;

  const dispatch = useDispatch();

  const smsHandleChange = () => {
    if (isTherapist) {
      dispatch(therapistAction.enableSms(id, !enableSms));
    } else {
      dispatch(clientAction.enableSms(id, !enableSms));
    }
  };

  const emailsHandleChange = () => {
    if (isTherapist) {
      dispatch(therapistAction.enableEmails(id, !enableSms));
    } else {
      dispatch(clientAction.enableEmails(id, !enableEmails));
    }
  };

  return (
    <div className="enabled-notifications">
      <div className="enabled-notifications__item">
        <p className={`${classes}__label`}>Sms reminder</p>
        <Switch defaultChecked={enableSms} onChange={smsHandleChange} />
      </div>
      <div className="enabled-notifications__item">
        <p className="client-card__label">Emails reminder</p>
        <Switch defaultChecked={enableEmails} onChange={emailsHandleChange} />
      </div>
    </div>
  );
};

export default EnabledNotifications;
