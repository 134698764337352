import React from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import _ from 'lodash';
// components
import LoadingPage from './loading';
// types
import { stateType } from '../../types/types';

interface LayoutDefault {
  component: React.ComponentType<RouteProps>;
  path?: string;
  exact?: boolean;
  for?: Array<string>;
}

const LayoutChoiceGroup: React.FC<LayoutDefault> = ({ component: Component, ...args }: LayoutDefault) => {
  const auth = useSelector((state: stateType) => state.app.auth);
  const role = useSelector((state: stateType) => state.user.profile.role);
  const state = useSelector((state: stateType) => state.user.state);

  const redirect_url = localStorage.getItem('role') ? `/login-${localStorage.getItem('role')}` : '/login-customer';

  return (
    <Route
      render={() => {
        if (state && state === 'group_change') {
          if (auth === 'auth') {
            if (_.find(args.for, (element) => element === role)) {
              return (
                <Route
                  path={args.path}
                  render={() => {
                    return <Component {...args} />;
                  }}
                />
              );
            }
            return <Redirect to={'/'} />;
          } else if (auth === 'loading') {
            return <LoadingPage />;
          } else {
            return <Redirect to={redirect_url} />;
          }
        } else {
          if (auth === 'login') {
            return <Redirect to={'/profile/choice-group'} />;
          } else if (state && state === 'questioning') {
            return <Redirect to={'/profile/choice-group/confirm'} />;
          }
          return <Redirect to={'/'} />;
        }
      }}
    />
  );
};

export default LayoutChoiceGroup;
