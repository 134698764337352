import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';
import { useDispatch } from 'react-redux';
// components
import mainMenuAction from 'redux/actions/main_menu';
// icons
import Icon from '@ant-design/icons';

export interface IAsideItem {
  item: {
    readonly id: number;
    status: boolean;
    name: string;
    icon: any;
    disabled: boolean;
    link: string;
  };
}

const AsideItem: FC<IAsideItem> = ({ item }: IAsideItem) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const onClick = () => {
    if (!item.disabled) {
      history.push(item.link);
      dispatch(mainMenuAction.showDrawer(false));
    }
  };

  return (
    <div
      key={item.id}
      className={cn('main-menu__item', {
        disabled: item.disabled,
        active: item.status,
      })}
      onClick={onClick}
    >
      <Icon
        component={item.icon}
        className={cn({ 'main-menu__icon': item.status, 'forum-inactive': item.name === 'Forum' && true })} // TODO add conditional when to show a notification dot
        style={!item.status ? { color: '#1891e5' } : undefined}
      />
      <span className="main-menu__name">{item.name}</span>
    </div>
  );
};

export default AsideItem;
