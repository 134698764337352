import React, { useEffect, useState } from 'react';
import { Modal, Select, Spin } from 'antd';
import { ReactComponent as CloseIcon } from 'assets/image/account/close4.svg';
import './style.scss';
import InputField from '../../../molecules/inputField';
import { Controller, useForm } from 'react-hook-form';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { stateType } from '../../../../types/types';
import groupsAction from '../../../../redux/actions/groups';
import sessionsAction from '../../../../redux/actions/sessions';

export interface AddCoHostModalProps {
  visible: boolean;
  handleCancel: () => void;
  handleOk: () => void;
  session: any;
  group: any;
}
export default function AddCoHostModal({ visible, handleCancel, handleOk, session, group }: AddCoHostModalProps) {
  const placeholder = 'Choose a therapist as a co-host';
  const { control } = useForm();
  const [selectedValue, setSelectedValue] = useState(placeholder);
  const options: any = useSelector((state: stateType) => state.groups.therapists, shallowEqual);
  const [loading, setLoading] = useState(true);
  const apiUrl = process.env.REACT_APP_API_URL;
  const JWTtoken = localStorage.getItem('token');
  const dispatch = useDispatch();

  console.log('Therapoist options', options);

  useEffect(() => {
    if (session.coHost !== null) {
      setSelectedValue(session.coHost.id);
    }
  }, [session]);

  useEffect(() => {
    if (!options.length) {
      dispatch(groupsAction.getTherapists());
    } else {
      setLoading(false);
    }
  }, [options]);
  const _handleOk = (e: any) => {
    console.log(e, selectedValue, session);
    if (selectedValue !== placeholder) {
      console.log('selectedValue', selectedValue, session);
      fetch(`${apiUrl}admin/session/${session.id}/add_co_host`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${JWTtoken}`,
        },
        body: JSON.stringify({
          therapist_id: selectedValue,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          dispatch(sessionsAction.get(group.id));
        })
        .catch((err) => {
          console.log(err);
        });
    }
    handleOk();
  };

  const onChange = (value: any) => {
    console.log(value);
    setSelectedValue(value);
  };

  return (
    <Modal
      visible={visible}
      closeIcon={<CloseIcon />}
      onCancel={handleCancel}
      onOk={_handleOk}
      className="add-co-host-modal"
      centered
    >
      <Spin spinning={loading}>
        <div className="add-co-host-container">
          <div className="add-co-host-container__title">Add Co-Host</div>
          <div className="add-co-host-container__main">
            <InputField label="Select therapist">
              <Controller
                control={control}
                render={() => (
                  <Select
                    className="form-select"
                    options={options}
                    value={selectedValue}
                    onChange={(value: any) => {
                      onChange(value);
                    }}
                    placeholder={placeholder}
                  />
                )}
                name={'coHostId'}
              />
            </InputField>
          </div>
          <div className="add-co-host-container__footer"></div>
        </div>
      </Spin>
    </Modal>
  );
}
