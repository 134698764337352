/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { AxiosResponse } from 'axios';
// config
import http from '../instance';

export const api = {
  getNpsScore: async (id: any, params: any): Promise<AxiosResponse> => {
    const api = await http();
    return api.get(`admin/customer/${id}/nps-scores${params ? `?${params}` : ''}`);
  },
};
