import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, Spin } from 'antd';
import _ from 'lodash';
import cn from 'classnames';
// icons
import { FilterOutlined } from '@ant-design/icons';
import { ReactComponent as IconClose } from 'assets/image/account/close4.svg';
// types
import { stateType } from '../../../../../types/types';
// tools
import { checkOrdersValue } from 'tools/helpFunctions';
// styles
import './style.scss';

interface FiltersType {
  type: 'clients' | 'groups' | 'therapists';
  children: React.ReactNode;
}

const Filters: React.FC<FiltersType> = ({ type, children }: FiltersType) => {
  const [visible, setVisible] = useState(false);

  const params = useSelector((state: stateType) => state[type].params);
  const loader = useSelector((state: stateType) => state[type].loaderModal);
  const lisinerByFilters = checkOrdersValue(_.omit(params, ['name', 'page', 'per_page']));

  const open = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <div className={cn('btn-filter', { 'btn-filter__active': !lisinerByFilters })} onClick={open}>
        <FilterOutlined
          style={{ fontSize: '20px', color: !lisinerByFilters ? '#1891e5' : '#92929d', margin: '0 15px' }}
        />
        <span>Filter</span>
      </div>

      {visible && (
        <Modal
          visible={visible}
          onOk={handleOk}
          closable={true}
          closeIcon={<IconClose />}
          onCancel={handleCancel}
          centered
          maskTransitionName="none"
          footer={null}
          className="filters-modal"
        >
          <Spin spinning={loader}>
            {React.Children.map(children, (child: any) => React.cloneElement(child, { type, setVisible }))}
          </Spin>
        </Modal>
      )}
    </>
  );
};

export default Filters;
