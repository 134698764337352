/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ActionCallback } from '../../types/actions/common-types';
import {
  GetReasonsCallbackAction,
  GetTopicsCallbackAction,
  GetStatusesCallbackAction,
  GetGendersCallbackAction,
  GetStateCallbackAction,
  GetCountryCallbackAction,
  GetSpecialtiesCallbackAction,
  GetZoomClientsCallbackAction,
  GetLicensesCallbackAction,
  GetSpecificGroupCallbackAction,
  GetCustomerStatusesCallbackAction,
  ReasonsPayloadAction,
  TopicsOptionsAction,
  StatusesOptionsAction,
  GendersOptionsAction,
  StateOptionsAction,
  CountriesOptionsAction,
  SpecialtiesOptionsAction,
  ZoomClientsOptionsAction,
  LicensesOptionsAction,
  SpecificGroupOptionsAction,
  CustomerStatusesOptionsAction,
  GroupStatusesOptionsAction,
  NumberOfMembersOptionsAction,
  TitleOptionsAction,
  TherapistStatusesOptionsAction,
  TopicsExtendedOptionsAction,
  GetTopicsExtendedCallbackAction,
} from '../../types/actions/options';

const optionsAction = {
  get_reasons(callback?: ActionCallback): GetReasonsCallbackAction {
    return {
      type: 'GET_REASONS',
      callback,
    };
  },
  get_topics(callback?: ActionCallback): GetTopicsCallbackAction {
    return {
      type: 'GET_TOPICS',
      callback,
    };
  },
  get_topics_extended(callback?: ActionCallback): GetTopicsExtendedCallbackAction {
    return {
      type: 'GET_TOPICS_EXTENDED',
      callback,
    };
  },
  get_statuses(callback?: ActionCallback): GetStatusesCallbackAction {
    return {
      type: 'GET_STATUSES',
      callback,
    };
  },
  get_genders(callback?: ActionCallback): GetGendersCallbackAction {
    return {
      type: 'GET_GENDERS',
      callback,
    };
  },
  get_state(callback?: ActionCallback): GetStateCallbackAction {
    return {
      type: 'GET_STATE',
      callback,
    };
  },
  get_country(callback?: ActionCallback): GetCountryCallbackAction {
    return {
      type: 'GET_COUNTRY',
      callback,
    };
  },
  get_specialties(callback?: ActionCallback): GetSpecialtiesCallbackAction {
    return {
      type: 'GET_SPECIALTIES',
      callback,
    };
  },
  get_zoom_clients(callback?: ActionCallback): GetZoomClientsCallbackAction {
    return {
      type: 'GET_ZOOM_CLIENTS',
      callback,
    };
  },
  get_licenses(callback?: ActionCallback): GetLicensesCallbackAction {
    return {
      type: 'GET_LICENSES',
      callback,
    };
  },
  get_specific_groups(callback?: ActionCallback): GetSpecificGroupCallbackAction {
    return {
      type: 'GET_SPECIFIC_GROUPS',
      callback,
    };
  },
  get_customer_statuses(callback?: ActionCallback): GetCustomerStatusesCallbackAction {
    return {
      type: 'GET_CUSTOMER_STATUSES',
      callback,
    };
  },
  reasons(reasons: any): ReasonsPayloadAction {
    return {
      type: 'OPTIONS_FORM_REASONS',
      payload: {
        reasons: reasons,
      },
    };
  },
  topics(topics: any): TopicsOptionsAction {
    return {
      type: 'OPTIONS_FORM_TOPICS',
      payload: {
        topics: topics,
      },
    };
  },
  topics_extended(topicsExtended: any): TopicsExtendedOptionsAction {
    return {
      type: 'OPTIONS_FORM_TOPICS_EXTENDED',
      payload: {
        topicsExtended,
      },
    };
  },
  statuses(statuses: any): StatusesOptionsAction {
    return {
      type: 'OPTIONS_FORM_STATUSES',
      payload: {
        statuses: statuses,
      },
    };
  },
  genders(genders: any): GendersOptionsAction {
    return {
      type: 'OPTIONS_FORM_GENDERS',
      payload: {
        genders: genders,
      },
    };
  },
  state(state: any): StateOptionsAction {
    return {
      type: 'OPTIONS_FORM_STATE',
      payload: {
        state: state,
      },
    };
  },
  countries(countries: any): CountriesOptionsAction {
    return {
      type: 'OPTIONS_FORM_COUNTRIES',
      payload: {
        countries: countries,
      },
    };
  },
  specialties(specialties: any): SpecialtiesOptionsAction {
    return {
      type: 'OPTIONS_FORM_SPECIALTIES',
      payload: {
        specialties: specialties,
      },
    };
  },
  zoomClients(zoomClients: any): ZoomClientsOptionsAction {
    return {
      type: 'OPTIONS_FORM_ZOOM_CLIENTS',
      payload: {
        zoomClients: zoomClients,
      },
    };
  },
  licenses(licenses: any): LicensesOptionsAction {
    return {
      type: 'OPTIONS_FORM_LICENSES',
      payload: {
        licenses: licenses,
      },
    };
  },
  specificGroup(specificGroup: any): SpecificGroupOptionsAction {
    return {
      type: 'OPTIONS_FORM_SPECIFIC_GROUPS',
      payload: {
        specificGroup: specificGroup,
      },
    };
  },
  customerStatuses(customerStatuses: any): CustomerStatusesOptionsAction {
    return {
      type: 'OPTIONS_CUSTOMER_STATUSES',
      payload: {
        customerStatuses: customerStatuses,
      },
    };
  },
  therapistStatuses(therapistStatuses: any): TherapistStatusesOptionsAction {
    return {
      type: 'OPTIONS_THERAPIST_STATUSES',
      payload: {
        therapistStatuses,
      },
    };
  },
  groupStatuses(groupStatuses: any): GroupStatusesOptionsAction {
    return {
      type: 'OPTIONS_GROUP_STATUSES',
      payload: {
        groupStatuses: groupStatuses,
      },
    };
  },
  titles(titles: any): TitleOptionsAction {
    return {
      type: 'GET_THERAPIST_TITLES',
      payload: {
        titles: titles,
      },
    };
  },
  numberOfMembers(numberOfMembers: any): NumberOfMembersOptionsAction {
    return {
      type: 'OPTIONS_NUMBER_OF_MEMBERS',
      payload: {
        numberOfMembers: numberOfMembers,
      },
    };
  },
};

export default optionsAction;
