import React, { useEffect } from 'react';
// components
import ChangeGroupOrganism from '../../components/organisms/changeGroup/change';
// actions
import changeGroupActions from '../../redux/actions/change_group';
import questionsActions from '../../redux/actions/questions';
import { GroupActionStatus } from 'types/actions/common-types';
import SimpleHeader from 'components/organisms/headers/simple/simple-header';
// style
import './style.scss';

const ChangeGroupPage: React.FC = () => {
  useEffect(() => {
    return () => {
      questionsActions.clear();
      changeGroupActions.status(GroupActionStatus.Empty);
    };
  }, []);

  return (
    <div className="choice-group-page">
      <div className="change-group-template">
        <SimpleHeader />
        <div className="change-group-template__body">
          <ChangeGroupOrganism />
        </div>
      </div>
    </div>
  );
};

export default ChangeGroupPage;
