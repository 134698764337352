import React, { FC } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import mainMenuAction from 'redux/actions/main_menu';
import { ReactComponent as ArrowIcon } from '../../../assets/image/slider/back-icon-blue.svg';

const MenuToggler: FC = () => {
  const dispatch = useDispatch();
  const showMinimal = useSelector((state: any) => state.main_menu.showMinimal, shallowEqual);

  const onToogleMenu = () => {
    dispatch(mainMenuAction.showMinimal(!showMinimal));
  };

  return (
    <div className={`main-menu__toggler ${showMinimal ? 'close' : ''}`}>
      <button className="toggler-btn" onClick={onToogleMenu}>
        <div className="toggler-btn__icon">
          <ArrowIcon className="toggler-btn__icon" />
        </div>
      </button>
      <span className="toggler-line"></span>
    </div>
  );
};

export default MenuToggler;
