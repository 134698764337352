import React from 'react';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';
// components
import { UserAvatar } from '../../../../atoms/forTable';
// helpers
import { week_days, timeConvertor } from '../../../../../configs/tables/admin/clients';
// styles
import '../../style.scss';

export const ItemName: React.FC = (props: any) => {
  const { value, row, link } = props;
  const history = useHistory();
  return (
    <div
      className="table-item-box table-item-box_name"
      onClick={() => {
        history.push(`/${link}/${row.id}`);
      }}
    >
      <UserAvatar
        first_name={row.firstname}
        last_name={row.lastname}
        color={row.color}
        url={row?.avatar ? row.avatar.url : false}
      />
      <span className="table-item-box_name__value table-item-box__value-row-limit">
        {row.firstname} {row.lastname}
      </span>
    </div>
  );
};

export const ItemId: React.FC = (props: any) => {
  const { value, row } = props;
  return (
    <div className="table-item-box">
      <span className="table-item-box__id">{row.id}</span>
    </div>
  );
};

export const ItemTopic: React.FC = (props: any) => {
  const { value, row } = props;
  return (
    <div className="table-item-box">
      <span className="table-item-box__value">{row.topic}</span>
    </div>
  );
};

export const ItemDay: React.FC = (props: any) => {
  const { value, row } = props;
  return (
    <div className="table-item-box">
      <span className="table-item-box__value">
        {row.date && (
          <>
            <span
              className={cn('table-item-box__day', {
                'table-item-box__day-blue': row.day === 1,
                'table-item-box__day-green': row.day === 2,
                'table-item-box__day-pink': row.day === 3,
                'table-item-box__day-violet': row.day === 4,
                'table-item-box__day-yellow': row.day === 5,
                'table-item-box__day-ultraviolet': row.day === 6 || row.day === 7,
              })}
            >
              {week_days[row.day - 1]}
            </span>
          </>
        )}
      </span>
    </div>
  );
};

export const ItemTime: React.FC = (props: any) => {
  const { value, row } = props;
  return (
    <div className="table-item-box">
      <span className="table-item-box__value">
        {row.date && (
          <>
            {timeConvertor(row.date.start)} - {timeConvertor(row.date.end, 'type-time')} ET
          </>
        )}
      </span>
    </div>
  );
};

export const ItemTherapist: React.FC = (props: any) => {
  const { value, row } = props;
  return (
    <div className="table-item-box">
      <span className="table-item-box__value">{row.therapist}</span>
    </div>
  );
};

export const ItemStatus: React.FC = (props: any) => {
  const { value, row } = props;
  return (
    <div className="table-item-box">
      <div className={`table-item-box__status table-item-box__status-${row.markers.id}`}>
        <span>{row.markers.value}</span>
      </div>
    </div>
  );
};
