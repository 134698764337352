import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
// actions
import accountAction from 'redux/actions/account';
// components
import EditCardModal from '../../modals/editCard';
// helpers
import { createArray } from 'configs/account/helpers';
import { getCardIcon } from 'tools/helpFunctions';
// icons
import Icon from '@ant-design/icons';
import { ReactComponent as IconEdit } from 'assets/image/account/edit-light.svg';
// styles
import './style.scss';

const PayInfoContainer: React.FC<any> = ({ paymentInfo }: any) => {
  const dispatch = useDispatch();
  const { last4, brand, expMonth, expYear, name } = paymentInfo;
  const [visible, setVisible] = useState(false);

  const open = () => {
    setVisible(true);
    dispatch(accountAction.editCheckToken());
  };

  return (
    <div className="pay-info-container">
      <div className="pay-info">
        <div className="pay-info__body">
          <div className="pay-info__header">
            <div className="pay-info__header-text">
              {createArray(4).map((item) => {
                return <div className="pay-info__dots" key={item} />;
              })}
              <span className="pay-info__text">{last4}</span>
            </div>
            <Icon component={getCardIcon(brand)} style={{ fontSize: '50px' }} />
          </div>
          <div className="pay-info__footer">
            <div>
              <p className="pay-info__name">{name ? name : 'Your Card Name'}</p>
              <p className="pay-info__expires">{`Expires ${expMonth}/${String(expYear).slice(-2)}`}</p>
            </div>
            <div className="pay-info__edit" onClick={open}>
              <Icon component={IconEdit} style={{ fontSize: '12px' }} />
              <span className="pay-info__up">Edit</span>
            </div>
            <EditCardModal visible={visible} setVisible={setVisible} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PayInfoContainer;
