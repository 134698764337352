import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { message, Upload } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
// actions
import appAction from '../../../redux/actions/app';
// types
import { stateType } from '../../../types/types';
// icons
import uploadIcon from '../../../assets/image/group/upload-icon.svg';

const getBase64 = (img: any, callback: any) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

const beforeUpload = (file: any) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
};

const Uploader: React.FC<any> = ({ type, defaultValue }: any) => {
  const [loading, setLoading] = useState(false);
  const file: any = useSelector((state: stateType) => state.app.file);

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(appAction.setFile(null));
    };
  }, []);

  const handleChange = (info: any) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      setLoading(false);
    }
  };

  const action = ({ file }: any) => {
    const formData = new FormData();
    formData.append('file', file);
    dispatch(appAction.uploadFile({ type: type, data: formData }));
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined className="plus-outlined" />}
      <div style={{ marginTop: 8 }} className="upload-text">
        Add file
      </div>
    </div>
  );

  return (
    <div className="upload-container">
      <Upload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        customRequest={action}
        showUploadList={false}
        beforeUpload={beforeUpload}
        onChange={handleChange}
      >
        {defaultValue && !file && !loading && (
          <div className="edit-photo-container">
            <img src={uploadIcon} className="add-photo-icon" />
            <img src={defaultValue} alt="avatar" style={{ width: '100%' }} />
          </div>
        )}
        {file &&
          (file?.url ? (
            <img src={file?.urls?.default} alt="avatar" style={{ width: '100%', borderRadius: '10px' }} />
          ) : (
            uploadButton
          ))}
        {!file?.url && !defaultValue && uploadButton}
      </Upload>
    </div>
  );
};

export default Uploader;
