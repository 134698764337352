import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { displayName } from '../../../Helpers/helpers';
import moment from 'moment';

const useStyles = makeStyles(() =>
  createStyles({
    messageInfoContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '5px 0 0 48px',
      fontSize: '12px',
      color: '#606B85',
    },
  }),
);

interface MessageInfoProps {
  author: string;
  dateCreated: string;
  isLocalParticipant: boolean;
  date: Date;
}

export default function MessageInfo({ author, dateCreated, isLocalParticipant, date }: MessageInfoProps) {
  const classes = useStyles();

  const timeAgo = (dateCreated: Date) => {
    const now = moment(new Date());
    const start = moment(dateCreated);
    const duration = moment.duration(now.diff(start));
    if (duration.asSeconds() < 60) {
      return Math.floor(duration.asSeconds()) + ' sec ago';
    }
    if (duration.asMinutes() < 60) {
      return Math.floor(duration.asMinutes()) + ' min ago';
    }
    if (duration.asHours() < 24) {
      return Math.floor(duration.asHours()) + ' hours ago';
    }
    return Math.floor(duration.asDays()) + ' days ago';
  };

  return (
    <div className={classes.messageInfoContainer}>
      <div>{timeAgo(date)}</div>
    </div>
  );
}
