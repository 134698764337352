import React from 'react';
import { useHistory } from 'react-router-dom';
// components
import { UserAvatar } from '../../../../atoms/forTable';
// helpers
import { week_days, timeConvertor } from '../../../../../configs/tables/admin/clients';

// styles
import '../../style.scss';
import cn from 'classnames';

export const ItemName: React.FC = (props: any) => {
  const { value, row, link } = props;
  const history = useHistory();
  return (
    <div
      className="table-item-box table-item-box_name"
      onClick={() => {
        history.push(`/${link}/${row.id}`);
      }}
    >
      <UserAvatar first_name={row.name} color={row.color} url={row?.avatar?.url} />
      <span className="table-item-box_name__value table-item-box__value-row-limit">{row.name} Group</span>
    </div>
  );
};

export const ItemId: React.FC = (props: any) => {
  const { value, row } = props;
  return (
    <div className="table-item-box">
      <span className="table-item-box__id">{row.id}</span>
    </div>
  );
};

export const ItemTime: React.FC = (props: any) => {
  const { value, row, day, date } = props;
  return (
    <div className="table-item-box">
      <span className="table-item-box__value">
        {day && (
          <span
            className={cn('table-item-box__day', {
              'table-item-box__day-blue': row.date.day === 1,
              'table-item-box__day-green': row.date.day === 2,
              'table-item-box__day-pink': row.date.day === 3,
              'table-item-box__day-violet': row.date.day === 4,
              'table-item-box__day-yellow': row.date.day === 5,
              'table-item-box__day-ultraviolet': row.date.day === 6 || row.date.day === 7,
            })}
          >
            {week_days[row.date.day - 1]}
          </span>
        )}
        {date && row.date && (
          <>
            {timeConvertor(row.date.start)} - {timeConvertor(row.date.end, 'type-time')} ET
          </>
        )}
      </span>
    </div>
  );
};

export const ItemTherapist: React.FC = (props: any) => {
  const { value, row } = props;
  return (
    <div className="table-item-box">
      <span className="table-item-box__value table-item-box__value-row-limit">{row.therapist}</span>
    </div>
  );
};

export const ItemMembers: React.FC = (props: any) => {
  const { value, row, info } = props;
  return (
    <div className="table-item-box table-item-box_info-cart">
      <p className="table-item-box__value">{row.membersCount}</p>
      {info.length ? (
        <ul className="table-item-box_info-cart_list">
          {info.map((item: any, i: number) => (
            <li className="table-item-box_info-cart_list-item" key={i}>
              {`${item.firstname} ${item.lastname}`}
            </li>
          ))}
        </ul>
      ) : (
        ''
      )}
    </div>
  );
};

export const ItemStatus: React.FC = (props: any) => {
  const { value, row } = props;
  return (
    <div className="table-item-box">
      <div
        className={cn('table-item-box__status', {
          'table-item-box__status-4': row.status.value === 1,
          'table-item-box__status-3': row.status.value === 2,
          'table-item-box__status-1': row.status.value === 3,
          'table-item-box__status-2': row.status.value === 4,
          'table-item-box__status-5': row.status.value === 5,
        })}
      >
        <span>{row.status.name}</span>
      </div>
    </div>
  );
};
