import React from 'react';
import { useHistory } from 'react-router-dom';
import EditEvent from '../../../../organisms/modals/editEvent';
import moment from 'moment-timezone';

export const ItemName: React.FC = (props: any) => {
  const { row, link } = props;
  moment.tz.setDefault('America/New_York');

  const history = useHistory();
  return (
    <div
      className="table-item-box table-item-box_name"
      onClick={() => {
        history.push(`/${link}/${row.id}`);
      }}
    >
      <span className="table-item-box_name table-item-box__value-row-limit">{row.title}</span>
    </div>
  );
};

export const ItemTime: React.FC = (props: any) => {
  const { row } = props;
  return (
    <div className="table-item-box">
      <span className="table-item-box__value table-item-box__event-time">
        {`${moment(row?.start).tz('America/New_York').format('h:mm A')} - ${moment(row?.end)
          .tz('America/New_York')
          .format('h:mm A')} ET`}{' '}
        {moment(row?.start).tz('America/New_York').format('dddd, MMMM Do')}
      </span>
    </div>
  );
};

export const ItemTherapist: React.FC = (props: any) => {
  const { value, row } = props;
  return (
    <div className="table-item-box">
      <span className="table-item-box_name table-item-box__event-therapist">
        {row?.leader?.firstname ? `${row?.leader?.firstname} ` : ''}
        {row?.leader?.lastname ? row?.leader?.lastname : ''}
      </span>
    </div>
  );
};

export const ItemType: React.FC = (props: any) => {
  const { row } = props;
  return (
    <div className="table-item-box">
      <div className={`table-item-box_name table-item-box__type table-item-box__type_${row?.type?.toLowerCase()}`}>
        <span style={{ color: row?.communityEventType?.id === 1 ? 'red' : '#8af542' }}>
          {row?.communityEventType?.name}
        </span>
      </div>
    </div>
  );
};

export const ItemParticipants: React.FC = (props: any) => {
  const { row } = props;
  return (
    <div className="table-item-box">
      <div className={`table-item-box__status table-item-box__participant`}>
        <span>{row.maxAttendees} Attendees</span>
      </div>
    </div>
  );
};

export const ItemEdit: React.FC = (props: any) => {
  const { row } = props;
  const handleClick = (e: any) => {
    e.stopPropagation();
  };
  return (
    <div className="table-item-box" onClick={handleClick}>
      <EditEvent id={row.id} />
    </div>
  );
};
