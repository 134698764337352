import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { AnimatePresence, motion } from 'framer-motion';
// helpers
import { calculatePrising, capitalizeText } from 'tools/helpFunctions';
import { animScale } from 'tools/animations';
// types
import { stateType } from 'types/types';
// styles
import 'components/organisms/stripe/form/Summary/style.scss';
import stripeAction from '../../../../../redux/actions/stripe';

const SummaryRegister = ({ notCancelled }: any) => {
  const sub: any = useSelector((state: stateType) => state.stripe.item);
  const items: any = useSelector((state: stateType) => state.stripe.items, shallowEqual);
  const secred: any = useSelector((state: stateType) => state.stripe.secred, shallowEqual);
  const payment = calculatePrising({ items, sub: secred?.subscriptionPrice });
  const dataStripe: any = useSelector((state: stateType) => state.stripe);
  const [value, setValue] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    if (sub?.defaultCouponId) {
      dispatch(stripeAction.checkPromo({ couponName: sub?.defaultCouponId }));
    }
  }, [sub]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const checkPromo = () => {
    dispatch(stripeAction.checkPromo({ couponName: value }));
  };

  const choosePayment = sub?.productPeriodName === 'monthly' ? 1 : sub?.productPeriodName === 'quarterly' ? 3 : 6;
  const totalDueToday =
    sub?.saveAmountPercent || dataStripe?.data?.name
      ? dataStripe?.data?.name
        ? `$${
            dataStripe?.data?.percentOff
              ? (
                  payment.defaultMontPrice * choosePayment -
                  (((payment.defaultMontPrice * choosePayment - sub?.saveAmount / 100) * dataStripe?.data?.percentOff) /
                    100 +
                    sub?.saveAmount / 100)
                ).toFixed(2)
              : (
                  payment.defaultMontPrice * choosePayment -
                  (sub?.saveAmount + dataStripe?.data?.amountOff) / 100
                ).toFixed(2)
          }`
        : `$${(payment.defaultMontPrice * choosePayment - sub?.saveAmount / 100).toFixed(2)}`
      : `$${(payment.defaultMontPrice * choosePayment).toFixed(2)}`;
  const yourSaving =
    sub?.saveAmountPercent || dataStripe?.data?.name
      ? dataStripe?.data?.name
        ? `-$${(dataStripe?.data?.percentOff
            ? ((payment.defaultMontPrice * choosePayment - sub?.saveAmount / 100) * dataStripe?.data?.percentOff) /
                100 +
              sub?.saveAmount / 100
            : (sub?.saveAmount + dataStripe?.data?.amountOff) / 100
          ).toFixed(2)}`
        : `-${(sub?.saveAmount / 100).toFixed(2)}`
      : '-$0.00';
  const promo =
    '-' +
    (dataStripe?.data?.percentOff
      ? `${dataStripe?.data?.percentOff}%`
      : `$${(dataStripe?.data?.amountOff / 100).toFixed(2)}`);

  return (
    <div className="payment">
      <div className="payment__title">Summary</div>
      <div className="payment__summary-list">
        <div className="payment__item item-payment">
          <div className="item-payment__name">
            {choosePayment} Month billing ({choosePayment * 4} x ${payment.defaultMontPrice / 4})
          </div>
          <div className="item-payment__sum">${(payment.defaultMontPrice * choosePayment).toFixed(2)}</div>
        </div>
        <AnimatePresence initial={false}>
          {sub?.saveAmount && (
            <motion.div
              className="payment__item item-payment saving"
              variants={animScale}
              initial="initial"
              animate="visible"
              exit="exit"
            >
              <div className="item-payment__name">
                {capitalizeText(sub?.productPeriodName)} billing discount <span>({sub?.saveAmountPercent}% off)</span>
              </div>
              <div className="item-payment__sum">-${(sub?.saveAmount / 100).toFixed(2)}</div>
            </motion.div>
          )}
        </AnimatePresence>
        {dataStripe?.data?.valid && (
          <div className="payment__item item-payment saving">
            <div className="item-payment__name">
              {sub?.defaultCouponId !== dataStripe?.data?.name ? 'Promo' : 'First month promo'} {dataStripe?.data?.name}
            </div>
            <div className="item-payment__sum">{promo}</div>
          </div>
        )}
      </div>
      <div className="payment__savigns">
        <div className="item-payment saving">
          <div className="item-payment__name">Your savings</div>
          <div className="item-payment__sum">{yourSaving}</div>
        </div>
      </div>
      <div className="payment__total">
        <div className="item-payment total">
          <div className="item-payment__name">Total due today</div>
          <div className="item-payment__sum">{totalDueToday.includes('-') ? 0 : totalDueToday}</div>
        </div>
      </div>
      <div className="payment__promo promo">
        <div className="promo__question">Promo code?</div>
        <form className="promo__form">
          <input
            className="promo__input"
            placeholder={sub?.defaultCouponId}
            value={value}
            onClick={() => (dataStripe?.invalidPromo === value ? setValue('') : null)}
            onChange={onChange}
            style={{
              borderColor: dataStripe?.invalidPromo === value ? 'red' : 'gray',
            }}
          />
          <button type="button" className="btn-form promo__btn" onClick={checkPromo} disabled={!value}>
            Apply
          </button>
        </form>
        <div
          className="promo__applied"
          style={{
            color: dataStripe?.invalidPromo === value ? 'red' : '#3D5581FF',
          }}
        >
          {dataStripe?.invalidPromo === value
            ? 'The coupon is invalid'
            : dataStripe?.data?.valid
            ? 'Promo code applied'
            : ''}
        </div>
      </div>
    </div>
  );
};

export default SummaryRegister;
