import {
  CancelSessionAction,
  GetCommEventSessions,
  GetNextSessionsAction,
  GetSelfNextSessionsAction,
  GetSelfSessionsAction,
  GetSessionsAction,
  GetSessionsEventsAction,
  JoinSessionAction,
  RescheduleSessionAction,
  SessionEventsLoaderAction,
  SessionLoaderAction,
  SessionLoaderModalAction,
  SessionsActions,
  StartSessionAction,
  SetTwilioRoomAction,
} from '../../types/actions/sessions';
import store from '../store';
// actions

// import mainMenuAction from './main_menu';

const sessionsAction: SessionsActions = {
  get(data?: any, callback?: () => void): GetSessionsAction {
    return {
      type: 'GET_SESSIONS',
      data,
      callback,
    };
  },
  getNext(data?: any, callback?: () => void): GetNextSessionsAction {
    return {
      type: 'GET_NEXT_SESSIONS',
      data,
      callback,
    };
  },
  getCommEventSessions(data?: any, callback?: () => void): GetCommEventSessions {
    return {
      type: 'GET_COMM_EVENT_SESSIONS',
      data,
      callback,
    };
  },
  getEvents(callback?: () => void): GetSessionsEventsAction {
    return {
      type: 'GET_SESSIONS_EVENTS',
      callback,
    };
  },
  getSelf(data?: any, callback?: () => void): GetSelfSessionsAction {
    return {
      type: 'GET_SELF_SESSIONS',
      data,
      callback,
    };
  },
  getSelfNext(data?: any, callback?: () => void): GetSelfNextSessionsAction {
    return {
      type: 'GET_SELF_NEXT_SESSIONS',
      data,
      callback,
    };
  },
  loader(status: boolean): SessionLoaderAction {
    return {
      type: 'SESSIONS_LOADER',
      payload: {
        loader: status,
      },
    };
  },
  loaderModal(status: boolean): SessionLoaderModalAction {
    return {
      type: 'SESSIONS_LOADER_MODAL',
      payload: {
        loaderModal: status,
      },
    };
  },
  loaderEvents(status: boolean): SessionEventsLoaderAction {
    return {
      type: 'SESSIONS_EVENTS_LOADER',
      payload: {
        loaderEvents: status,
      },
    };
  },
  create: (data: any): void => {
    store.dispatch({
      type: 'SESSIONS',
      payload: {
        items: data,
      },
    });
  },
  setEvents: (data: any): void => {
    store.dispatch({
      type: 'SESSIONS_EVENTS',
      payload: {
        events: data,
      },
    });
  },
  setNextEventSessions: (data: any): void => {
    store.dispatch({
      type: 'SET_NEXT_EVENT_SESSIONS',
      payload: {
        events: data,
      },
    });
  },
  setFutureEventSessions: (data: any): void => {
    store.dispatch({
      type: 'SET_FUTURE_EVENT_SESSIONS',
      payload: {
        events: data,
      },
    });
  },
  clear: (): void => {
    store.dispatch({
      type: 'SESSIONS',
      payload: {
        items: { next: [], future: [] },
      },
    });
  },
  reschedule(data?: any, id?: any, callback?: () => void): RescheduleSessionAction {
    return {
      type: 'SESSION_RESCHEDULE',
      data,
      id,
      callback,
    };
  },
  cancel(id?: any, callback?: () => void): CancelSessionAction {
    return {
      type: 'SESSION_CANCEL',
      id,
      callback,
    };
  },

  join(id?: any, callback?: () => void): JoinSessionAction {
    return {
      type: 'SESSION_JOIN',
      id,
      callback,
    };
  },
  start(id?: any, paramsId?: string, callback?: () => void): StartSessionAction {
    return {
      type: 'SESSION_START',
      id,
      paramsId,
      callback,
    };
  },
  setTwilioRoom(id?: any): SetTwilioRoomAction {
    return {
      type: 'SET_TWILIO_ROOM',
      id,
    };
  },
};

export default sessionsAction;
