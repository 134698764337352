import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Modal, Spin } from 'antd';
// componets
import FormAddGroup from '../../forms/adds/group';
// actions
import groupsAction from '../../../../redux/actions/groups';
// types
import { stateType } from '../../../../types/types';
// styles
import './style.scss';
// icons
import { ReactComponent as CloseIcon } from 'assets/image/account/close4.svg';

const AddGroup: React.FC = () => {
  const [visible, setVisible] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(groupsAction.getTopics());
    dispatch(groupsAction.getTherapists());
  }, []);

  const loader = useSelector((state: stateType) => state.groups.loaderModal);

  const open = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <button className="btn-form" style={{ width: '263px' }} onClick={open}>
        + Add Group
      </button>
      {visible && (
        <Modal
          visible={visible}
          onOk={handleOk}
          closable={true}
          onCancel={handleCancel}
          centered
          maskTransitionName="none"
          footer={null}
          className="add-group-modal"
          closeIcon={<CloseIcon />}
        >
          <Spin spinning={loader}>
            <ModalContainer setVisible={setVisible} />
          </Spin>
        </Modal>
      )}
    </>
  );
};

export default AddGroup;

const ModalContainer = ({ setVisible }: any) => {
  return (
    <div className="add-group-container">
      <div className="add-group-container__title">Add a group</div>
      <FormAddGroup setVisible={setVisible} />
    </div>
  );
};
