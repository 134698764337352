import React from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';
import _ from 'lodash';
// components
import LoadingPage from './loading';
// types
import { stateType } from '../../types/types';
import { stateFlowType } from '../../redux/reducers/user';

interface LayoutDefault {
  component: React.ComponentType<RouteProps>;
  path?: string;
  exact?: boolean;
  for?: Array<string>;
}

const LayoutConsultationPage: React.FC<LayoutDefault> = ({ component: Component, ...args }: LayoutDefault) => {
  const auth = useSelector((state: stateType) => state.app.auth);
  const role = useSelector((state: stateType) => state.user.profile.role);
  const state: stateFlowType = useSelector((state: stateType) => state.user.state, shallowEqual);

  const redirect_url = localStorage.getItem('role') ? `/login-${localStorage.getItem('role')}` : '/login-customer';

  return (
    <Route
      render={() => {
        if (state && state !== 'group_joined' && state !== 'restart_cancel' && state !== 'restart_pre_cancel') {
          if (auth === 'auth' || auth === 'consultation') {
            if (_.find(args.for, (element) => element === role)) {
              return (
                <Route
                  path={args.path}
                  render={() => {
                    return <Component {...args} />;
                  }}
                />
              );
            }
            return <Redirect to={'/'} />;
          } else if (auth === 'loading') {
            return <LoadingPage />;
          } else {
            return <Redirect to={redirect_url} />;
          }
        } else {
          return <Redirect to={'/'} />;
        }
      }}
    />
  );
};

export default LayoutConsultationPage;
