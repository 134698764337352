import React from 'react';
// icons
import Icon from '@ant-design/icons';
import { ReactComponent as IconCalendar } from '../../../../../assets/image/questions/calendar.svg';
// configs
import { week_days } from '../../../../../configs/tables/admin/clients';
//components
import SmallGroupCard from '../../../cards/groupCard/SmallGroupCard';

interface ModalContainerType {
  cancel: () => void;
  confirm: () => void;
  item: any;
}

const ModalContainer: React.FC<ModalContainerType> = ({ cancel, confirm, item }: ModalContainerType) => {
  return (
    <div className="confirm-modal-container">
      <h2 className="confirm-modal-container__title">Are you sure you want to switch to the following group?</h2>
      <div className="confirm-modal-container__body">
        <div className="confirm-modal-container__body-title">
          <Icon component={IconCalendar} style={{ marginRight: '6px', fontSize: '20px' }} />
          Every {week_days[item.group.date.dayOfWeek - 1]}
        </div>
        <div className="confirm-modal-container__body-box">
          <SmallGroupCard item={item} isBegins />
        </div>
      </div>
      <div className="confirm-modal-container__buttons">
        <button
          className="btn-form"
          onClick={() => {
            confirm();
          }}
        >
          {`Yes, I'm sure`}
        </button>
        <button
          className="btn-default"
          onClick={() => {
            cancel();
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default ModalContainer;
