import React from 'react';
import { Controller } from 'react-hook-form';
import { useSelector, shallowEqual } from 'react-redux';
import moment from 'moment';

import InputField from '../../inputField';
import { Input, DatePicker } from 'antd';
import { Select } from 'antd';
// icons
import { CaretDownOutlined } from '@ant-design/icons';
// types
import { stateType } from '../../../../types/types';
// styles
import '../style.scss';
import './style.scss';

interface InputsType {
  errors: string | undefined;
  register: any;
  watch?: any;
  control?: any;
  defaultValue?: any;
  placeholder?: string;
  label?: string;
}

export const Bio: React.FC<InputsType> = ({ errors, control, defaultValue, label }: InputsType) => {
  return (
    <InputField name="biography" label={label ? label : 'My Bio'} errors={errors} icon={false}>
      <Controller
        control={control}
        name="biography"
        rules={{
          minLength: { value: 200, message: 'Enter a bio that is at least 200 letters long' },
        }}
        render={({ field: { onChange } }) => (
          <Input.TextArea
            rows={7}
            placeholder="Type in bio.."
            autoSize={false}
            onChange={(value: any) => {
              onChange(value);
            }}
            defaultValue={defaultValue}
          />
        )}
      />
    </InputField>
  );
};

export const FirstName: React.FC<InputsType> = ({ errors, register }: InputsType) => {
  const item: any = useSelector((state: stateType) => state.therapists.item);

  return (
    <InputField name="firstname" label="First name" errors={errors} icon={false}>
      <input
        id="firstname"
        className={errors && 'error'}
        defaultValue={item.firstname}
        placeholder="Enter Therapist First Name"
        {...register('firstname', {
          required: 'Enter Therapist First Name',
          minLength: {
            value: 3,
            message: 'First name must have at least 3 characters',
          },
        })}
      />
    </InputField>
  );
};

export const RepeatFirstName: React.FC<InputsType> = ({ errors, register, placeholder, watch }: InputsType) => {
  return (
    <InputField name="firstname_repeat" label="Confirm first name" errors={errors} icon={false}>
      <input
        id="firstname_repeat"
        className={errors && 'error'}
        placeholder={placeholder ? placeholder : ''}
        {...register('firstname_repeat', {
          required: 'Repeat Therapist First Name',
          minLength: {
            value: 3,
            message: 'First name must have at least 3 characters',
          },
          validate: (value: string) => value === watch('firstname') || 'The first name do not match!',
        })}
      />
    </InputField>
  );
};

export const LastName: React.FC<InputsType> = ({ errors, register }: InputsType) => {
  const item: any = useSelector((state: stateType) => state.therapists.item);

  return (
    <InputField name="lastname" label="Last name" errors={errors} icon={false}>
      <input
        id="lastname"
        defaultValue={item.lastname}
        className={errors && 'error'}
        placeholder="Enter Therapist Last Name"
        {...register('lastname', {
          required: 'Enter Therapist Last Name',
          minLength: {
            value: 3,
            message: 'Last name name must have at least 3 characters',
          },
        })}
      />
    </InputField>
  );
};

export const NPINumber: React.FC<InputsType> = ({ errors, register }: InputsType) => {
  const item: any = useSelector((state: stateType) => state.therapists.item);
  console.log(item);

  return (
    <InputField name="npiNumber" label="NPI number" errors={errors} icon={false}>
      <input
        id="npiNumber"
        defaultValue={item.npi}
        className={errors && 'error'}
        placeholder="Enter Therapist NPI Number"
        {...register('npiNumber', {
          required: 'Enter Therapist NPI Number',
          minLength: {
            value: 10,
            message: 'NPI number must have 10 characters',
          },
          maxLength: {
            value: 10,
            message: 'NPI number must have 10 characters',
          },
        })}
      />
    </InputField>
  );
};

export const RepeatLastName: React.FC<InputsType> = ({ errors, register, placeholder, watch }: InputsType) => {
  return (
    <InputField name="lastname_repeat" label="Confirm last name" errors={errors} icon={false}>
      <input
        id="lastname_repeat"
        className={errors && 'error'}
        placeholder={placeholder ? placeholder : ''}
        {...register('lastname_repeat', {
          required: 'Repeat Therapist Last Name',
          minLength: {
            value: 3,
            message: 'Last name must have at least 3 characters',
          },
          validate: (value: string) => value === watch('lastname') || 'The last name do not match!',
        })}
      />
    </InputField>
  );
};

export const SelectTitle: React.FC<InputsType> = ({ errors, defaultValue, control }: InputsType) => {
  const options: any = useSelector((state: stateType) => state.options.titles);

  return (
    <InputField name="titleId" label="Therapist Title" errors={errors} icon={false}>
      <Controller
        control={control}
        name="titleId"
        rules={{ required: 'Select Therapist Title' }}
        render={({ field: { onChange } }) => (
          <Select
            className="form-select"
            dropdownClassName="form-dropdown"
            placeholder="Select Therapist Title"
            style={{ width: '100%' }}
            onChange={(value: any) => {
              onChange(value);
            }}
            options={options}
            allowClear
            suffixIcon={<CaretDownOutlined />}
            defaultValue={defaultValue ? defaultValue : 1}
          />
        )}
      />
    </InputField>
  );
};

export const Description: React.FC<InputsType> = ({ errors, control, defaultValue }: InputsType) => {
  return (
    <InputField
      name="description"
      label="Add/edit the group description below"
      errors={errors}
      icon={false}
      classes="input-field-other__tags"
    >
      <Controller
        control={control}
        name="description"
        rules={{
          required: { value: true, message: 'Description is required' },
          minLength: { value: 500, message: 'Enter a description that is at least 500 letters long' },
        }}
        render={({ field: { onChange } }) => (
          <Input.TextArea
            rows={7}
            autoSize={false}
            onChange={(value: any) => {
              onChange(value);
            }}
            defaultValue={defaultValue}
          />
        )}
      />
    </InputField>
  );
};

export const YearsinPracticeInput: React.FC<InputsType> = ({ errors, register, defaultValue }: InputsType) => {
  return (
    <InputField name="practiceYears" label="Years in Practice" errors={errors} icon={false}>
      <input
        id="practiceYears"
        className={errors && 'error'}
        placeholder="Type in # of Years"
        {...register('practiceYears')}
        defaultValue={defaultValue}
      />
    </InputField>
  );
};

export const LicensureSelect: React.FC<InputsType> = ({ errors, control, defaultValue }: InputsType) => {
  const options: any = useSelector((state: stateType) => state.options.licenses, shallowEqual);

  return (
    <InputField name="licenseIds" label="Licensure" errors={errors} icon={false}>
      <Controller
        control={control}
        name="licenseIds"
        rules={{ required: 'choice your licensure' }}
        render={({ field: { onChange } }) => (
          <Select
            options={options}
            placeholder="Select licensure"
            className="form-select"
            dropdownClassName="form-dropdown"
            mode="tags"
            maxTagCount="responsive"
            style={{ width: '100%' }}
            onChange={(value: any) => {
              onChange(value);
            }}
            showArrow
            allowClear
            suffixIcon={<CaretDownOutlined />}
            defaultValue={defaultValue ? defaultValue : undefined}
          />
        )}
      />
    </InputField>
  );
};

export const SpecialtiesSelect: React.FC<InputsType> = ({ errors, control, defaultValue }: InputsType) => {
  const options: any = useSelector((state: stateType) => state.options.specialties, shallowEqual);

  return (
    <InputField name="specialtyIds" label="Specialties" errors={errors} icon={false}>
      <Controller
        control={control}
        name="specialtyIds"
        render={({ field: { onChange } }) => (
          <Select
            mode="tags"
            maxTagCount="responsive"
            className="form-select"
            dropdownClassName="form-dropdown"
            placeholder="Select Specialities"
            style={{ width: '100%' }}
            onChange={(value: any) => {
              onChange(value);
            }}
            options={options}
            allowClear
            suffixIcon={<CaretDownOutlined />}
            defaultValue={defaultValue ? defaultValue : undefined}
          />
        )}
      />
    </InputField>
  );
};

export const PossibleGroupTopics: React.FC<InputsType> = ({ errors, control, defaultValue }: InputsType) => {
  const options: any = useSelector((state: stateType) => state.options.topics, shallowEqual);

  return (
    <InputField name="groupTopicIds" label="Possible Group Topics" errors={errors} icon={false}>
      <Controller
        control={control}
        name="groupTopicIds"
        render={({ field: { onChange } }) => (
          <Select
            mode="tags"
            maxTagCount="responsive"
            className="form-select"
            dropdownClassName="form-dropdown"
            placeholder="Select Possible Group Topics"
            style={{ width: '100%' }}
            onChange={(value: any) => {
              onChange(value);
            }}
            options={options}
            allowClear
            suffixIcon={<CaretDownOutlined />}
            defaultValue={defaultValue ? defaultValue : undefined}
          />
        )}
      />
    </InputField>
  );
};

export const Date: React.FC<InputsType> = ({ errors, control, defaultValue }: InputsType) => {
  return (
    <InputField name="joinedDate" label="Date Joined" errors={errors} icon={false}>
      <Controller
        control={control}
        name="joinedDate"
        rules={{ required: 'choose at least one option' }}
        render={({ field: { onChange } }) => (
          <DatePicker
            className="form-time-select"
            onChange={onChange}
            placeholder="Select Date Joined"
            defaultValue={moment(defaultValue, 'YYYY/MM/DD')}
            format={'YYYY/MM/DD'}
            suffixIcon={<CaretDownOutlined />}
          />
        )}
      />
    </InputField>
  );
};

export const TagsSelect: React.FC<InputsType> = ({ errors, control, defaultValue }: InputsType) => {
  return (
    <InputField
      name="tags"
      label="Add/Edit the group tags below"
      errors={errors}
      icon={false}
      classes="input-field-other__tags"
    >
      <Controller
        control={control}
        name="tags"
        rules={{ required: 'choose at least one option' }}
        render={({ field: { onChange } }) => (
          <Select
            mode="tags"
            maxTagCount="responsive"
            className="form-select"
            dropdownClassName="form-dropdown"
            placeholder="Select your top choice"
            style={{ width: '100%' }}
            onChange={(value: any) => {
              onChange(value);
            }}
            allowClear
            suffixIcon={<CaretDownOutlined />}
            defaultValue={defaultValue}
          />
        )}
      />
    </InputField>
  );
};
