/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

export const mapping = {
  registrationBuild(data) {
    return {
      firstname: data.firstname,
      lastname: data.lastname,
      email: data.email,
      password: data.password,
      assessmentfunnel: data.assessment,
    };
  },

  forgotPasswordBuild(data) {
    return {
      email: data.email.trim(),
      url: `${window.location.origin}/new-password/`,
    };
  },

  queryParamsBuild(data) {
    return;
  },
};
