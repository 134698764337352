import store from '../store';
import {
  ChoiceGroupAction,
  ChoiceGroupLoaderAction,
  ChoiceGroupLoaderModalAction,
  GetChoiceGroupAction,
  JoinChoiceGroupAction,
} from '../../types/actions/choice-group';
import { ActionCallback } from '../../types/actions/common-types';

const choiceGroupAction: ChoiceGroupAction = {
  get(query, callback: ActionCallback): GetChoiceGroupAction {
    return {
      type: 'GET_CHOICE_GROUP',
      query,
      callback,
    };
  },

  join(id: number, callback?: ActionCallback): JoinChoiceGroupAction {
    return {
      type: 'JOIN_CHOICE_GROUP',
      id,
      callback,
    };
  },

  create(data: any, totalCount?: number) {
    store.dispatch({
      type: 'CHOICE_GROUP',
      payload: {
        item: data,
        totalCount: totalCount ? totalCount : 1,
      },
    });
  },

  params(params: any) {
    store.dispatch({
      type: 'CHOICE_GROUP_PARAMS',
      payload: {
        params: params,
      },
    });
  },

  loader(status: boolean): ChoiceGroupLoaderAction {
    return {
      type: 'CHOICE_GROUP_LOADER',
      payload: {
        loader: status,
      },
    };
  },

  loaderModal(status: boolean): ChoiceGroupLoaderModalAction {
    return {
      type: 'CHOICE_GROUP_LOADER_MODAL',
      payload: {
        loaderModal: status,
      },
    };
  },
};

export default choiceGroupAction;
