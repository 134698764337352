import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
// components
import MainTemplate from '../../../components/templates/mainTemplate';
// actions
import appAction from '../../../redux/actions/app';

const GroupsOfferedPage: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(appAction.page('Groups offered'));
  }, []);
  return (
    <MainTemplate>
      <h2>Groups Offered page</h2>
      <img
        src="https://admin.abc.sm/img/gallery/upload/4454/img_lastminute_295354_1568727364.jpg"
        alt="work"
        style={{ maxWidth: '100%' }}
      />
      <img
        src="https://admin.abc.sm/img/gallery/upload/4454/img_lastminute_295354_1568727364.jpg"
        alt="work"
        style={{ maxWidth: '100%' }}
      />
      <img
        src="https://admin.abc.sm/img/gallery/upload/4454/img_lastminute_295354_1568727364.jpg"
        alt="work"
        style={{ maxWidth: '100%' }}
      />
    </MainTemplate>
  );
};

export default GroupsOfferedPage;
