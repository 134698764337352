import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
// components
import ConsultationContainer from '../../../components/containers/consultaitionContainer';
// actions
import consultationAction from '../../../redux/actions/consultation';
import appAction from '../../../redux/actions/app';
import ChoiceGroupAction from '../../../redux/actions/choice_group';
// types
import { stateType } from 'types/types';
import { useHistory } from 'react-router-dom';

const ConsultationPage: React.FC = () => {
  const dispatch = useDispatch();

  const state: string = useSelector((state: stateType) => state.user.state, shallowEqual);
  const auth = useSelector((state: stateType) => state.app.auth);
  const [locationKeys, setLocationKeys] = useState([]);
  const history = useHistory();

  useEffect(() => {
    return history.listen((location) => {
      if (history.action === 'POP') {
        history.push('/registration');
      }
    });
  }, [locationKeys]);

  useEffect(() => {
    auth === 'consultation' && dispatch(appAction.auth('auth'));

    switch (state) {
      case 'registered':
        dispatch(consultationAction.get_options());
        break;
      case 'waiting':
        dispatch(appAction.getSupportStates());
        break;
      case 'group_search':
        dispatch(ChoiceGroupAction.get());
        break;

      default:
        break;
    }
  }, [state]);

  return (
    <div className="consultation-page custom-style">
      <ConsultationContainer />
    </div>
  );
};

export default ConsultationPage;
