import React, { useEffect, useState } from 'react';

// components
import CardGroup from '../../groupCard/CardGroup';
import CardTherapist from '../../therapist';
import BorderCard from '../../groupCard/cardGroupElements/BorderCard';
import GroupMeaning from '../../groupCard/GroupMeaning';

// style
import '../style.scss';
import cn from 'classnames';
import Members from '../../members';

const GroupDetailsCard: React.FC<any> = ({ group, therapist, members, sessions }: any) => {
  const [isSuccess, setIsSuccess] = useState(true);
  const checkFuture = sessions && sessions?.future?.every((e: any) => e?.customerSessionsCheck);
  const checkNext = sessions && sessions?.next?.every((e: any) => e?.customerSessionsCheck);
  const sessCheck = checkFuture && checkNext;

  useEffect(() => {
    if (sessCheck) {
      setIsSuccess(true);
    } else {
      setIsSuccess(false);
    }
  }, [sessions]);

  return (
    <div className="group-details-card group-details-card_admin">
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        {group?.status?.value && (
          <div
            className={cn('table-item-box__status', {
              'table-item-box__status-4': group.status.value === 1,
              'table-item-box__status-3': group.status.value === 2,
              'table-item-box__status-1': group.status.value === 3,
              'table-item-box__status-2': group.status.value === 4,
              'table-item-box__status-5': group.status.value === 5,
              'group-details-card-custom__status': true,
            })}
            style={{ paddingLeft: 15, paddingRight: 15, maxWidth: 220 }}
          >
            {group?.status.name} group
          </div>
        )}
        <div
          className={cn('table-item-box__status', {
            'group-details-card-custom__status': true,
          })}
          style={{
            width: 200,
            color: 'white',
            backgroundColor: isSuccess ? 'rgb(130, 196, 60)' : 'rgb(252, 90, 90)',
            borderRadius: 4,
            paddingLeft: 0,
            paddingRight: 0,
          }}
        >
          Sessions check : {isSuccess ? 'success' : 'warning'}
        </div>
      </div>
      <CardGroup group={group} isEdit members={members} />
      <BorderCard>
        <CardTherapist therapist={therapist} isEdit />
      </BorderCard>
      <GroupMeaning group={group} isEdit />
      <Members members={members} edit={true} group={group} />
    </div>
  );
};

export default GroupDetailsCard;
