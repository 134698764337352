import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
// types
import { stateType } from '../../../../../types/types';
// actions
import therapistsAction from '../../../../../redux/actions/therapists';
// components
import FilterBox from '../../../../molecules/filters/filterBox';
import {
  FilterGroupActiveSort,
  FilterGroupTopicField,
  FilterTherapistLicenses,
  FilterTherapistsState,
  FilterTherapistStatusesField,
} from '../../../../molecules/filters/foTables';
// style
import './style.scss';
// types
import { FilterOptionsRole } from '../../../../../types/actions/common-types';

const FilterTherapists: FC<any> = ({ setVisible }: any) => {
  const params: any = useSelector((state: stateType) => state.therapists.params);
  const optionsIsReceived: any = useSelector((state: stateType) => state.therapists.optionsIsReceived);
  const { watch, reset, setValue, control } = useForm();

  const dispatch = useDispatch();

  const licensureState = watch('state');

  useEffect(() => {
    optionsIsReceived !== FilterOptionsRole.Admin && dispatch(therapistsAction.getFilterOptions());

    setValue('state', params.licensure_state_id);
  }, []);

  useEffect(() => {
    therapistsAction.params({ ...params, licensure_state_id: licensureState });
  }, [licensureState]);

  const onChange = (type: string, values: any) => {
    switch (type) {
      case 'activeGroup':
        therapistsAction.params({ ...params, active_group_topic_ids: values });
        break;
      case 'possibleGroup':
        therapistsAction.params({ ...params, group_topic_ids: values });
        break;
      case 'state':
        therapistsAction.params({ ...params, licensure_state_id: values });
        break;
      case 'licensure':
        therapistsAction.params({ ...params, licensure_ids: values });
        break;
      case 'statuses':
        therapistsAction.params({ ...params, statuses: values });
        break;
      case 'sortGroups':
        therapistsAction.params({ ...params, 'order[active_groups_count]': values });
        break;

      default:
        break;
    }
  };

  const onSubmit = () => {
    therapistsAction.params({
      ...params,
      page: 1,
      per_page: 10,
    });
    dispatch(therapistsAction.get());
    setVisible(false);
  };

  const clearAll = () => {
    reset();
    therapistsAction.params({
      ...params,
      page: 1,
      per_page: 10,
      active_group_topic_ids: [],
      group_topic_ids: [],
      licensure_state_id: [],
      licensure_ids: [],
      statuses: [],
      'order[active_groups_count]': [],
    });
    setVisible(false);
    dispatch(therapistsAction.get());
  };

  return (
    <div className="filters-container">
      <div className="filters-container__title">Filters</div>
      <div className="filters-container__body">
        <FilterBox title="Active Group Topics" sm>
          <FilterGroupTopicField
            change={(e: any) => onChange('activeGroup', e)}
            defaultValue={params.active_group_topic_ids}
          />
        </FilterBox>
        <div className="filters-container__line" />
        <FilterBox title="Possible Group Topics" sm>
          <FilterGroupTopicField
            change={(e: any) => onChange('possibleGroup', e)}
            defaultValue={params.group_topic_ids}
          />
        </FilterBox>
        <div className="filters-container__line" />
        <FilterBox title="State of Licensure" sm type="select">
          <FilterTherapistsState defaultValue={params.licensure_state_id} control={control} />
        </FilterBox>
        <div className="filters-container__line" />
        <FilterBox title="Licensure Type" sm>
          <FilterTherapistLicenses change={(e: any) => onChange('licensure', e)} defaultValue={params.licensure_ids} />
        </FilterBox>
        <div className="filters-container__line" />
        <FilterBox title="Sort # active groups" sm>
          <FilterGroupActiveSort
            change={(e: any) => onChange('sortGroups', e)}
            defaultValue={params['order[active_groups_count]']}
          />
        </FilterBox>
        <div className="filters-container__line" />
        <FilterBox title="Therapist Status" sm>
          <FilterTherapistStatusesField change={(e: any) => onChange('statuses', e)} defaultValue={params.statuses} />
        </FilterBox>
      </div>
      <div className="filters-container__footer">
        <button className="btn-form" onClick={onSubmit}>
          Apply filters
        </button>
        <button className="btn-clear-all" onClick={clearAll}>
          Clear all
        </button>
      </div>
    </div>
  );
};

export default FilterTherapists;
