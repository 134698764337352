import React from 'react';
import { Spin } from 'antd';
import { useSelector } from 'react-redux';
import Moment from 'react-moment';
// components
import ShowAssesment from '../../../../organisms/modals/showAssesment';
// types
import { stateType } from '../../../../../types/types';
// styles
import './style.scss';

const AssasmentList: React.FC = () => {
  const assesments: any = useSelector((state: stateType) => state.assesments.items);
  const loader: boolean = useSelector((state: stateType) => state.assesments.loader);
  return (
    <Spin spinning={loader} style={{ minHeight: '480px' }}>
      {assesments.length === 0 && <span className="history-container__text">History is empty</span>}
      <span className="history-container__title">PHQ - ADS Assessments</span>
      {assesments?.length ? (
        <div className="assesments-items history-container__collapse">
          <div className="assesment-item__header">
            <div className="assesment-item__title">All results</div>
            <div className="assesment-item__title">Symptoms</div>
            <div className="assesment-item__title">Score</div>
            <div className="assesment-item__title">% Change</div>
          </div>
          {assesments?.length > 0 &&
            assesments.map((item: any) => {
              return <AssesmenItem item={item} key={item.id} />;
            })}
        </div>
      ) : (
        ''
      )}
    </Spin>
  );
};

export default AssasmentList;

const AssesmenItem = ({ item }: any) => {
  const { ranking, maxScore, comparativePercentage, end, totalScore } = item;

  return (
    <div className="assesment-item">
      <div className="assesment-item__body">
        <div className="assesment-item__label">
          <Moment format="MMM d, yyyy">{end}</Moment>
        </div>
        <div className={`assesment-item__rank ${ranking.toLowerCase()}`}>{ranking}</div>
        <div className="assesment-item__score">
          {totalScore}/{maxScore}
        </div>
        <div className="assesment-item__percent">
          {comparativePercentage || comparativePercentage === 0 ? `${comparativePercentage}%` : <>&mdash;</>}
        </div>
        <ShowAssesment id={item.id} />
      </div>
    </div>
  );
};
