/*
 *
 * History reducer
 *
 */
const initialState = {
  items: [],
  totalCount: 1,
  historyGroupsTotalCount: 1,
  historySessionsTotalCount: 1,
  historyGroups: [],
  historySessions: [],
  loader: false,
  loaderModal: false,
  params: {
    page: 1,
    per_page: 5,
  },
  historyGroupsParams: {
    page: 1,
    per_page: 2,
  },
  historySessionsParams: {
    page: 1,
    per_page: 4,
  },
};

export type historyInitStateType = typeof initialState;

interface historyActionTypes {
  type: string;
  payload: {
    items: Array<any>;
    totalCount: number;
    historyGroupsTotalCount: number;
    historySessionsTotalCount: number;
    historyGroups: [];
    historySessions: [];
    loader: boolean;
    loaderModal: boolean;
    params: {
      page: number;
      per_page: number;
    };
    historyGroupsParams: {
      page: number;
      per_page: number;
    };
    historySessionsParams: {
      page: number;
      per_page: number;
    };
  };
}

const history = (state = initialState, action: historyActionTypes): historyInitStateType => {
  switch (action.type) {
    case 'HISTORY':
      return Object.assign({}, state, {
        items: action.payload.items,
        totalCount: action.payload.totalCount,
      });
    case 'HISTORY_LOADER':
      return Object.assign({}, state, {
        loader: action.payload.loader,
      });
    case 'HISTORY_LOADER_MODAL':
      return Object.assign({}, state, {
        loaderModal: action.payload.loaderModal,
      });
    case 'CLIENT_HISTORY_GROUPS':
      return Object.assign({}, state, {
        historyGroups: action.payload.historyGroups,
        historyGroupsTotalCount: action.payload.totalCount,
      });
    case 'CLIENT_HISTORY_SESSIONS':
      return Object.assign({}, state, {
        historySessions: action.payload.historySessions,
        historySessionsTotalCount: action.payload.totalCount,
      });
    case 'SET_HISTORY_PARAMS':
      return Object.assign({}, state, {
        params: action.payload.params,
      });
    case 'SET_HISTORY_GROUPS_PARAMS':
      return Object.assign({}, state, {
        historyGroupsParams: action.payload.historyGroupsParams,
      });
    case 'SET_HISTORY_SESSIONS_PARAMS':
      return Object.assign({}, state, {
        historySessionsParams: action.payload.historySessionsParams,
      });

    default:
      return state;
  }
};

export default history;
