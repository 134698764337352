import React, { useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Modal, Spin } from 'antd';
import { useForm } from 'react-hook-form';
// componets
import EditTherapistContainer from '../../../../../molecules/modals/edit/editTherapist';
import Uploader from '../../../../files';
// types
import { stateType } from '../../../../../../types/types';
// icons
import { ReactComponent as CloseIcon } from 'assets/image/account/close4.svg';
import { ReactComponent as AccountEditPhoto } from 'assets/image/account/camera2.svg';

// styles
import '../style.scss';
// types
import { FormDataEditTherapist } from '../../../../../../types/edit';

const EditTherapistAvatarModal: React.FC<any> = ({ btnClass }: any) => {
  const [visible, setVisible] = useState(false);

  const loader = useSelector((state: stateType) => state.therapists.loaderModal, shallowEqual);

  const open = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <button className={btnClass} onClick={open}>
        <AccountEditPhoto />
      </button>
      {visible && (
        <Modal
          visible={visible}
          onOk={handleOk}
          closable={true}
          onCancel={handleCancel}
          centered
          maskTransitionName="none"
          footer={null}
          className="edit-group-modal"
          closeIcon={<CloseIcon />}
        >
          <Spin spinning={loader}>
            <ModalContainer handleCancel={handleCancel} />
          </Spin>
        </Modal>
      )}
    </>
  );
};

export default EditTherapistAvatarModal;

const ModalContainer = ({ handleCancel }: any) => {
  const { handleSubmit, setValue } = useForm<FormDataEditTherapist>();

  return (
    <EditTherapistContainer
      titleName="Change Therapist Photo"
      buttonName="CONFIRM CHANGE"
      handleCancel={handleCancel}
      setValue={setValue}
      handleSubmit={handleSubmit}
      isPhoto
    >
      <Uploader type={'user_avatar'} />
    </EditTherapistContainer>
  );
};
