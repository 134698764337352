import React, { FC } from 'react';
// style
import './style.scss';

interface EventTopicsInterface {
  topics: Array<any>;
}

interface TopicItemInterface {
  text: string;
  id: any;
}

const EventTopics: FC<EventTopicsInterface> = ({ topics }: EventTopicsInterface) => {
  return (
    <div className="event-topics">
      {topics?.map((item) => {
        return <TopicItem text={item.name} id={item.id} key={item.id} />;
      })}
    </div>
  );
};

export default EventTopics;

const TopicItem: FC<TopicItemInterface> = ({ text, id }: TopicItemInterface) => {
  return (
    <div className="event-topic">
      <p className="event-topic__text">{text}</p>
      {/*<DeleteTopic name={text} id={id} />*/}
    </div>
  );
};
