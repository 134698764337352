/*
 *
 * Sessions reducer
 *
 */
const initialState = {
  items: { next: [], future: [], coHosted: [] },
  events: [],
  nextEvents: [],
  futureEvents: [],
  loader: false,
  loaderModal: false,
  loaderEvents: false,
};

export type sessionsInitStateType = typeof initialState;

interface sessionsActionTypes {
  type: string;
  payload: {
    items: Array<any>;
    coHosted: Array<any>;
    events: [];
    nextEvents: [];
    futureEvents: [];
    loader: boolean;
    loaderModal: boolean;
    loaderEvents: boolean;
  };
}

const sessions = (state = initialState, action: sessionsActionTypes): sessionsInitStateType => {
  switch (action.type) {
    case 'SESSIONS':
      return Object.assign({}, state, {
        items: action.payload.items,
      });

    case 'SESSIONS_EVENTS':
      return Object.assign({}, state, {
        events: action.payload.events,
      });

    case 'SET_NEXT_EVENT_SESSIONS':
      return Object.assign({}, state, {
        nextEvents: action.payload.events,
      });

    case 'SET_FUTURE_EVENT_SESSIONS':
      return Object.assign({}, state, {
        futureEvents: action.payload.events,
      });

    case 'SESSIONS_LOADER':
      return Object.assign({}, state, {
        loader: action.payload.loader,
      });

    case 'SESSIONS_LOADER_MODAL':
      return Object.assign({}, state, {
        loaderModal: action.payload.loaderModal,
      });

    case 'SESSIONS_EVENTS_LOADER':
      return Object.assign({}, state, {
        loaderEvents: action.payload.loaderEvents,
      });

    default:
      return state;
  }
};

export default sessions;
