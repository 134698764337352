import React, { FC, useState } from 'react';
import { frequentlyChange, frequentlyRegister } from 'tools/consts';
import cn from 'classnames';
import { motion } from 'framer-motion';
//components
import FrequentlyQuestions from 'components/organisms/modals/questions/Frequently';
import Summary from 'components/organisms/stripe/form/Summary/SummaryRegister';
// helpers
import { animScale } from 'tools/animations';
// styles
import './style.scss';
import Group from '../Group';
import { useLocation } from 'react-router-dom';
import CardDetails from '../CardDetails';
import Benefits from '../Benefits';

interface IDetails {
  tab: 1 | 2;
  setTab: (tab: 1 | 2) => void;
  medicalGroup: any;
  notCancelled?: boolean;
}

const Details: FC<IDetails> = ({ tab, medicalGroup, notCancelled }: IDetails) => {
  const [visible, setVisible] = useState(false);
  const location = useLocation();
  const isChange = location.pathname.includes('change-billing');

  const onVisibleChange = (type: boolean) => setVisible(type);

  return (
    <motion.div className="stripe-det" variants={animScale} initial="initial" animate="visible" exit="exit">
      <div className={cn('stripe-det__toggle toggle', { second: tab === 2 })}>
        <div>{isChange ? 'Change Payment Schedule' : 'Review Details'}</div>
        <div>{isChange ? 'Confirm Details' : 'Complete Payment'}</div>
      </div>
      <div className={cn('stripe-det__card', { rotate: tab === 2 })}>
        {isChange ? (
          <>
            <div className="stripe-det__review stripe-det__face">
              <Group medicalGroup={medicalGroup} />
              <Benefits />
            </div>
            <div className="stripe-det__payment stripe-det__back">
              <div>
                <Group medicalGroup={medicalGroup} showDet />
                <CardDetails />
                <button className="stripe-det__frequently" onClick={() => setVisible(true)}>
                  Frequently asked questions
                </button>
                <FrequentlyQuestions visible={visible} setVisible={onVisibleChange} list={frequentlyChange} />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="stripe-det__review stripe-det__face">
              <Group medicalGroup={medicalGroup} />
              <Benefits />
            </div>
            <div className="stripe-det__payment stripe-det__back">
              <Summary notCancelled={notCancelled} />
              <button className="stripe-det__frequently" onClick={() => setVisible(true)}>
                Frequently asked questions
              </button>
              <FrequentlyQuestions visible={visible} setVisible={onVisibleChange} list={frequentlyRegister} />
            </div>
          </>
        )}
      </div>
    </motion.div>
  );
};

export default React.memo(Details);
