import React, { FC } from 'react';
import { Drawer } from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
// actions
import mainMenuAction from 'redux/actions/main_menu';
// components
import DefaultAside from './DefaultAside';
// types
import { stateType } from 'types/types';

const MobileAside: FC = () => {
  const dispatch = useDispatch();

  const { showDrawer } = useSelector((state: stateType) => state.main_menu, shallowEqual);

  const onClose = () => {
    dispatch(mainMenuAction.showDrawer(false));
  };

  return (
    <Drawer
      placement={'left'}
      closable={false}
      onClose={onClose}
      visible={showDrawer}
      key={'left'}
      className="main-menu-drawer"
    >
      <DefaultAside />
    </Drawer>
  );
};

export default MobileAside;
