import React, { useCallback } from 'react';

import AudioInputList from './AudioInputList/AudioInputList';
import AudioOutputList from './AudioOutputList/AudioOutputList';
import {
  DialogContent,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  Button,
  Theme,
  DialogTitle,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import VideoInputList from './VideoInputList/VideoInputList';
import CloseIcon from '../../../icons/CloseIcon';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '1200px',
    minHeight: '400px',
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100vw - 32px)',
    },
    '& .inputSelect': {
      width: 'calc(100% - 35px)',
    },
  },
  leftColumn: {
    float: 'left',
    width: 220,
    height: '100%',
    minHeight: 380,
  },
  leftMenuLink: {
    display: 'block',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    padding: '8px 12px',
    width: 175,
    borderRadius: 6,
    color: '#667085',
    '&:hover': {
      color: '#2B3C5C',
      backgroundColor: '#E6F2F7',
    },
  },
  rightColumn: {
    float: 'left',
  },
  button: {
    float: 'right',
    width: '30px',
    height: '30px',
    backgroundColor: '#828282',
    border: '0',
    borderRadius: '50%',
    padding: '6px',
  },
  paper: {
    borderRadius: 20,
    maxWidth: 1200,
    [theme.breakpoints.down('xs')]: {
      margin: '16px',
    },
  },
  headline: {
    marginBottom: '1.3em',
    fontSize: '1.1rem',
  },
  listSection: {
    margin: '2em 0 0.8em',
    '&:first-child': {
      margin: '1em 0 2em 0',
    },
  },
}));

export default function DeviceSelectionDialog({
  open,
  onClose,
  openBackgroundDialog,
}: {
  open: boolean;
  onClose: () => void;
  openBackgroundDialog: () => void;
}) {
  const classes = useStyles();

  const audioVideoClick = useCallback((e) => {
    return false;
  }, []);

  const virtualBackgroundClick = useCallback((e) => {
    openBackgroundDialog();
    return false;
  }, []);

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.paper }}>
      <DialogTitle>
        Settings
        <button className={classes.button} onClick={onClose}>
          <CloseIcon />
        </button>
      </DialogTitle>
      <DialogContent className={classes.container}>
        <div className={classes.leftColumn}>
          <a href="#" className={classes.leftMenuLink} onClick={audioVideoClick}>
            Audio & Video Settings
          </a>
          <a href="#" className={classes.leftMenuLink} onClick={virtualBackgroundClick}>
            Virtual Background
          </a>
        </div>
        <div className={classes.rightColumn}>
          <div className={classes.listSection}>
            <AudioInputList />
          </div>
          <div className={classes.listSection}>
            <AudioOutputList />
          </div>
          <div className={classes.listSection}>
            <VideoInputList />
          </div>
        </div>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
}
