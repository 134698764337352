import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Moment from 'react-moment';
import moment from 'moment-timezone';
import { useHistory } from 'react-router-dom';

import notificationClientAction from '../../../../../redux/actions/notificationClient';
import userActions from '../../../../../redux/actions/user';
import sessionsAction from '../../../../../redux/actions/sessions';

import { stateType } from '../../../../../types/types';

//style
import '../../style.scss';
import './style.scss';

//components
import BeginsIn from '../../../../molecules/beginsIn';
import { timeConvertor, week_days } from '../../../../../configs/tables/admin/clients';
import eventAnalytics from '../../../../../redux/actions/analytics';
import eventsAction from 'redux/actions/admin_events';
import appAction from '../../../../../redux/actions/app';

const FormRescheduleClientSession: React.FC<any> = ({ session, close, title, description }: any) => {
  const [step, setStep] = useState(1);

  useEffect(() => {
    if (step === 1) {
      dispatch(eventAnalytics.analytics('reschedule-group-notification'));
    }
  }, []);

  const initSession = (element: any) => {
    switch (element) {
      case 'session-rescheduled':
        return {
          id: session?.id,
          status:
            step !== 3
              ? session?.notificationData?.rescheduledGroupSession?.groupSession?.status
              : session?.notificationData?.nextGroupSession?.status,
          medicalGroup:
            step !== 3
              ? session?.notificationData?.rescheduledGroupSession?.groupSession?.medicalGroup
              : session?.notificationData?.nextGroupSession?.medicalGroup,

          newEnd:
            step !== 3
              ? session?.notificationData?.rescheduledGroupSession?.newEnd
              : session?.notificationData?.nextGroupSession?.end,
          newStart:
            step !== 3
              ? session?.notificationData?.rescheduledGroupSession?.newStart
              : session?.notificationData?.nextGroupSession?.start,

          oldEnd: session?.notificationData?.rescheduledGroupSession?.oldEnd,
          oldStart: session?.notificationData?.rescheduledGroupSession?.oldStart,
          type: session?.type?.name,
        };
      case 'group-rescheduled':
        return {
          id: session?.id,
          status: session?.notificationData?.medicalGroup?.status,
          medicalGroup: session?.notificationData?.medicalGroup,
          newDayOfWeek: week_days[session?.notificationData?.newDayOfWeek - 1],
          oldDayOfWeek: week_days[session?.notificationData?.oldDayOfWeek - 1],
          newEnd: moment(timeConvertor(session?.notificationData?.newEnd), 'hh:mm A'),
          newStart: moment(timeConvertor(session?.notificationData?.newStart), 'hh:mm A'),
          oldEnd: moment(timeConvertor(session?.notificationData?.oldEnd), 'hh:mm A'),
          oldStart: moment(timeConvertor(session?.notificationData?.oldStart), 'hh:mm A'),
          type: session?.type.name,
        };
      case 'session-canceled':
        return {
          id: session?.id,
          status: session?.notificationData?.status,
          medicalGroup: session?.notificationData?.groupSession?.medicalGroup,

          newEnd: session?.notificationData?.nextGroupSession?.end,

          newStart: session?.notificationData?.nextGroupSession?.start,

          oldEnd: session?.notificationData?.groupSession?.end,
          oldStart: session?.notificationData?.groupSession?.start,

          type: session?.type.name,
        };
      default:
        return '';
    }
  };

  const s: any = initSession(session.type.name);

  const dispatch = useDispatch();
  const profile: any = useSelector((state: stateType) => state.user.profile, shallowEqual);
  const rescheduledID = s.id;
  const history = useHistory();

  const cancelSessionCallback = (isDispatch?: boolean) => {
    isDispatch && dispatch(sessionsAction.getSelf());
  };

  const submitNewTime = () => {
    setStep(2);
    dispatch(notificationClientAction.readNotification(rescheduledID));
    dispatch(userActions.rewrite({ ...profile, notification: false }));
    dispatch(eventAnalytics.analytics('reschedule-group-choice', { confirm: true }));
  };

  const submitChangeGroup = () => {
    dispatch(notificationClientAction.readNotification(rescheduledID));
    dispatch(userActions.rewrite({ ...profile, notification: false }));
    history.push('/profile/change-group');
    dispatch(appAction.setReschedule(true));
    dispatch(eventAnalytics.analytics('reschedule-group-choice', { confirm: false }));
  };

  const readSessionRGroup = () => {
    const nextExist = session?.notificationData?.nextGroupSession?.start;
    nextExist ? setStep(3) : close();
    dispatch(notificationClientAction.readNotification(rescheduledID, false, () => cancelSessionCallback(!nextExist)));
  };

  const readSessionRNoGroup = () => {
    cancelSessionCallback(true);
    dispatch(userActions.rewrite({ ...profile, notification: false }));
  };

  const submitRead = () => {
    dispatch(notificationClientAction.readNotification(rescheduledID));
    dispatch(userActions.rewrite({ ...profile, notification: false }));
    close(false);
  };

  return (
    <>
      {step !== 3 && (
        <div className="reschedule-session-container">
          <div className="reschedule-session-container__title">{step == 1 ? title.stepOne : title.stepTwo}</div>
          <div className="reschedule-session-container__description">
            {step == 1 ? description.stepOne : description.stepTwo}
          </div>
          <div className="reschedule-session-container__block">
            <div className="form-reschedule-session client-reschedule-session">
              <div className="form-reschedule-session__main">
                <div className="form-reschedule-session__row">
                  <DifferentSessions session={s} step={step} />
                </div>
              </div>
              <div className="form-reschedule-session__footer">
                {step == 1 && (
                  <>
                    {session.type.name !== 'session-canceled' && (
                      <button className="button btn-form yes" onClick={() => submitNewTime()}>
                        Yes, the new time works for me
                      </button>
                    )}
                    {session.type.name === 'session-canceled' && (
                      <button className="button btn-form yes" onClick={() => submitRead()}>
                        {' '}
                        Done
                      </button>
                    )}
                    {session.type.name === 'session-rescheduled' && (
                      <button className="button no" onClick={() => readSessionRGroup()}>
                        {' '}
                        No, I can’t make the new time
                      </button>
                    )}
                    {session.type.name === 'group-rescheduled' && (
                      <button className="button no" onClick={() => submitChangeGroup()}>
                        {' '}
                        No, I’d like to see other group times
                      </button>
                    )}
                  </>
                )}
                {step == 2 && (
                  <button className="button btn-form yes" onClick={() => close(false)}>
                    Done
                  </button>
                )}
              </div>
            </div>{' '}
          </div>
        </div>
      )}
      {step === 3 && (
        <div className="reschedule-session-container">
          <div className="reschedule-session-container__title">{step == 3 && title.stepThree}</div>
          <div className="reschedule-session-container__description">{step == 3 && description.stepThree}</div>
          <div className="reschedule-session-container__block">
            <div className="form-reschedule-session client-reschedule-session">
              <div className="form-reschedule-session__main">
                <div className="form-reschedule-session__row">
                  <DifferentSessions session={s} step={step} />
                </div>
              </div>
              <div className="form-reschedule-session__footer">
                <button className="button btn-form yes" onClick={() => readSessionRNoGroup()}>
                  Done
                </button>
              </div>
            </div>{' '}
          </div>
        </div>
      )}
    </>
  );
};

export default FormRescheduleClientSession;

const DifferentSessions = ({ session, step }: any) => {
  const sessions: any = useSelector((state: stateType) => state.sessions.items, shallowEqual);

  const initTextOld = (element: any) => {
    switch (element) {
      case 'session-rescheduled':
        return 'Old  Time';
      case 'group-rescheduled':
        return 'Old  Time';
      case 'session-canceled':
        return 'Cancelled Session';
      default:
        return '';
    }
  };
  const initTextNew = (element: any) => {
    switch (element) {
      case 'session-rescheduled':
        return 'New  Time';
      case 'group-rescheduled':
        return 'New  Time';
      case 'session-canceled':
        return 'You’ll resume sessions on: ';
      default:
        return '';
    }
  };

  const beginsStart =
    session.type === 'group-rescheduled'
      ? sessions?.next?.[0]?.start
        ? sessions?.next?.[0]?.start
        : moment(session.newStart)?.isBefore(moment(), 'minute')
        ? session.newStart.clone().add(1, 'week')
        : session.newStart
      : session.newStart;

  return (
    <div className="different-container">
      {step == 1 && (
        <div className="different-container__old">
          <div className="different-container__titles">
            <div className="different-container__title">{initTextOld(session.type)}</div>
          </div>
          <div className="different-container__block">
            <SessionItemClientNext
              item={session}
              startDate={session.oldStart}
              endDate={session.oldEnd}
              showStatus={false}
              type={session.type}
              DayOfWeek={session.oldDayOfWeek}
            />
          </div>
        </div>
      )}
      {beginsStart && (
        <div className="different-container__new">
          <div className="different-container__titles">
            <div className="different-container__title">
              {step !== 3 ? initTextNew(session.type) : 'You’ll resume sessions on: '}
            </div>
          </div>
          <div className="different-container__block">
            <SessionItemClientNext
              item={session}
              startDate={session.newStart}
              endDate={session.newEnd}
              showStatus
              type={session.type}
              DayOfWeek={session.newDayOfWeek}
              beginsStart={beginsStart}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const SessionItemClientNext: React.FC<any> = ({
  item,
  startDate,
  endDate,
  showStatus,
  type,
  DayOfWeek,
  beginsStart,
}: any) => {
  return (
    <div className="session-item">
      <div className="session-item__avatar">
        {item.medicalGroup?.avatar && (
          <img src={item.medicalGroup?.avatar?.url} alt="" className="session-item__avatar-img" />
        )}
      </div>
      <div className="session-item__body">
        <div className="session-item__header">
          <div className="session-item__title">{item.medicalGroup?.groupTopic?.name} Group</div>
        </div>

        {(type === 'session-rescheduled' || type === 'session-canceled') && (
          <div className="session-item__item">
            <div className="session-item__label">Date:</div>
            <div className="session-item__value">
              <Moment format="dddd MMMM Do, YYYY">{startDate}</Moment>
            </div>
          </div>
        )}

        <div className="session-item__item">
          <div className="session-item__label">Time:</div>
          <div className="session-item__value">
            {type === 'group-rescheduled' && DayOfWeek + ', '}
            <Moment format="h:mm A">{startDate}</Moment>
            {' - '}
            <Moment format="h:mm A">{endDate}</Moment> ET
          </div>
        </div>
        {showStatus && <BeginsIn start={beginsStart} status={item.status} isAddBackground />}
      </div>
    </div>
  );
};
