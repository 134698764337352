import React, { useEffect, useState } from 'react';
// styles
import './style.scss';

interface ShowTextTypes {
  text: string;
  strLength: number;
}

const ShowText: React.FC<ShowTextTypes> = ({ text, strLength }: ShowTextTypes) => {
  const [show, setShow] = useState(false);
  const [string, setString] = useState('');

  useEffect(() => {
    if (text?.length > strLength + 10 && !show) {
      setString(`${text.slice(0, strLength)} `);
    } else {
      setString(text);
    }
  }, [text, show]);

  return (
    <span>
      {string}
      {text?.length > strLength + 10 && (
        <span className="show-more" onClick={() => setShow(!show)}>
          &nbsp;
          {show ? ' show less' : ' ...show more'}
        </span>
      )}
    </span>
  );
};

export default ShowText;
