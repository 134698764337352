import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Modal, Spin } from 'antd';
import moment from 'moment-timezone';
// componets
import InputField from '../../../../molecules/inputField';
import BorderCard from '../../../cards/groupCard/cardGroupElements/BorderCard';
// actions
import clientAction from '../../../../../redux/actions/client';
// types
import { stateType } from '../../../../../types/types';
// styles
import './style.scss';
// icons
import { ReactComponent as CloseIcon } from 'assets/image/account/close4.svg';

const PauseClient: React.FC<any> = () => {
  const [visible, setVisible] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [success, setSuccess] = useState(false);
  const [weeks, setWeeks] = useState(0);

  const loader = useSelector((state: stateType) => state.clients.loaderModal, shallowEqual);
  const item: any = useSelector((state: stateType) => state.clients.item, shallowEqual);

  const open = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onConfirm = useCallback(() => {
    setConfirm(true);
  }, []);

  const onSuccess = useCallback(() => {
    setSuccess(true);
  }, []);

  return (
    <>
      <button className="btn-form" onClick={open}>
        Pause Client
      </button>
      {visible && (
        <Modal
          closeIcon={<CloseIcon />}
          visible={visible}
          onOk={handleOk}
          closable={true}
          onCancel={handleCancel}
          centered
          maskTransitionName="none"
          footer={null}
          className="pause-client-modal"
        >
          {!confirm && !success && (
            <ModalContainer setWeeks={setWeeks} onConfirm={onConfirm} item={item} weeks={weeks} />
          )}
          {confirm && !success && (
            <Spin spinning={loader}>
              <ModalConfirm
                item={item}
                weeks={weeks}
                onSuccess={onSuccess}
                setConfirm={setConfirm}
                handleCancel={handleCancel}
              />
            </Spin>
          )}
          {success && (
            <Spin spinning={loader}>
              <ModalSuccess item={item} weeks={weeks} handleCancel={handleCancel} setSuccess={setSuccess} />
            </Spin>
          )}
        </Modal>
      )}
    </>
  );
};

export default PauseClient;

const ModalContainer = ({ setWeeks, item, weeks, onConfirm }: any) => {
  const onChange = (e: any) => {
    let val = e.target.value;
    val = val.replace(/\D/g, '');
    val = val.replace(/(\..*)\./g, '$1');

    setWeeks(+val);
  };

  return (
    <div className="pause-client-container">
      <div className="pause-client-container__title">
        How long would you like to put {item?.firstname} on <br /> pause for?
      </div>
      <div className="pause-client-container__group-info">
        <div>
          <InputField name="groupTopicId" label="Type in # of weeks" icon={false}>
            <input inputMode="numeric" placeholder="Type here.." onChange={onChange} value={weeks || ''} />
          </InputField>
        </div>
      </div>
      <div className="pause-client-container__block">
        <button className="btn-form btn-form__submit-btn" onClick={onConfirm} disabled={!weeks}>
          Submit
        </button>
      </div>
    </div>
  );
};

const ModalConfirm = ({ item, weeks, onSuccess, setConfirm, handleCancel }: any) => {
  const dispatch = useDispatch();

  const action = () => {
    dispatch(clientAction.pause(item?.id, { weeksCount: Number(weeks) }, onSuccess));
  };

  useEffect(() => {
    return () => {
      setConfirm(false);
    };
  }, []);

  return (
    <div className="pause-client-container">
      <div className="pause-client-container__title">
        Are you sure you want to put {item?.firstname} on pause for the following {weeks} of weeks?{' '}
      </div>
      <div className="pause-client-container__group-info pause-client-container__group-info_confirm">
        <PauseCard weeks={weeks} />
      </div>
      <div className="pause-client-container__block">
        <button className="btn-form" onClick={action}>
          Yes, I’m sure
        </button>
        <button className="btn-light" onClick={handleCancel}>
          Cancel
        </button>
      </div>
    </div>
  );
};

const ModalSuccess = ({ item, weeks, handleCancel, setSuccess }: any) => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      setSuccess(false);
      dispatch(clientAction.get(item.id));
    };
  }, []);
  return (
    <div className="pause-client-container">
      <div className="pause-client-container__title">You’ve successfully put {item?.firstname} on Pause!</div>
      <div className="pause-client-container__group-info pause-client-container__group-info_confirm">
        <PauseCard weeks={weeks} />
      </div>
      <div className="pause-client-container__block">
        <button className="btn-form" onClick={handleCancel}>
          Done
        </button>
      </div>
    </div>
  );
};

const PauseCard = ({ weeks }: any) => {
  return (
    <BorderCard white>
      <div className="pause-client-info">
        Put on Pause: <span>{weeks} weeks</span>
      </div>
      <div className="pause-client-info">
        Finish Pause: <span>{moment().add(weeks, 'weeks').format('dddd, MMMM Do, YYYY')}</span>
      </div>
    </BorderCard>
  );
};
