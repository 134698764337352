import { formattedDate } from '../../../../tools/helpFunctions';
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

export const mapping = {
  buildRequest(data) {
    if (data.statuses) {
      return {
        reasonIds: data.reasons,
        groupTopicId: data.topics,
        relationshipStatusId: data.statuses,
        birthday: formattedDate(data.date),
        genderId: data.genders,
        stateId: data.state ? data.state : null,
        countryId: data.countries ? data.countries : null,
        phone: data.phone.replace(/ /g, '').replace('+1', ''),
      };
    }
    return {
      reasonIds: data.reasons,
      groupTopicId: data.topics,
      birthday: formattedDate(data.date),
      genderId: data.genders,
      stateId: data.state ? data.state : null,
      countryId: data.countries ? data.countries : null,
      phone: data.phone.replace(/ /g, '').replace('+1', ''),
    };
  },
};
