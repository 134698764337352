import React, { useCallback } from 'react';
import moment from 'moment';
import { shallowEqual, useSelector } from 'react-redux';

interface AddToCalendarLinkProps {
  name: string;
  start: any;
  end: any;
}

export default function AddToCalendarLink({ name, start, end }: AddToCalendarLinkProps) {
  const startTime = moment(start);
  const endTime = moment(end);
  const role = useSelector((state: any) => state.user.profile.role, shallowEqual);
  const iscFileContent = [
    'BEGIN:VCALENDAR\n',
    'BEGIN:VEVENT\n',
    'DTSTART;TZID=America/New_York:' + startTime.format('YYYYMMDD') + 'T' + startTime.format('HHmmss') + '\n',
    'DTEND;TZID=America/New_York:' + endTime.format('YYYYMMDD') + 'T' + endTime.format('HHmmss') + '\n',
    'SUMMARY: Grouport Group Session\n',
    'DESCRIPTION: Log in at this link into your member portal here: ' +
      'https://' +
      window.location.hostname +
      '/login-' +
      role.toLowerCase() +
      ', and access your session from your home page.' +
      '\n',
    'RRULE:FREQ=WEEKLY;UNTIL=' + startTime.add(8, 'weeks').format('YYYYMMDD') + 'T' + endTime.format('hhmmss') + '\n',
    'END:VEVENT\n',
    'END:VCALENDAR',
  ];

  const blob = new Blob(iscFileContent, { type: 'text/calendar' });

  const onClick = useCallback((e) => {
    console.log('add to calendar');
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `session.ics`);
    document.body.appendChild(link);
    link.click();
    link.parentNode && link.parentNode.removeChild(link);
    e.preventDefault();
    return false;
  }, []);

  return (
    <a href={'#'} onClick={onClick}>
      Add to calendar
    </a>
  );
}
