import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';
import { AnimatePresence, motion } from 'framer-motion';
import cn from 'classnames';
// actions
import stripeAction from '../../../redux/actions/stripe';
import accountAction from 'redux/actions/account';
import { authActions } from '../../../redux/actions/auth';
// components
import SubscriptionList from '../../organisms/stripe/list';
import SubscriptionForm from '../../organisms/stripe/form';
import Details from 'components/organisms/stripe/form/Details';
// types
import { stateType } from 'types/types';
import { stateFlowType } from '../../../redux/reducers/user';
// helpers
import { animHeight } from 'tools/animations';
import useDidMountEffect from '../../../configs/hooks/useDidMountEffect';
// icons
import closeIcon from 'assets/image/common/close.svg';
// styles
import './style.scss';
import { mapping } from 'api/mapping/response/user';
import userActions from 'redux/actions/user';

const StripeContainer: React.FC = () => {
  const dispatch = useDispatch();
  const [state, setState] = useState<stateFlowType>('');
  const [promo, setPromo] = useState(false);
  const [tab, setTab] = useState<1 | 2 | null>(null);
  const loader = useSelector((state: stateType) => state.stripe.loader, shallowEqual);
  const authLoader: boolean = useSelector((state: any) => state.auth.loader, shallowEqual);
  const loaderRestart: boolean = useSelector((state: stateType) => state.restartSubscriptions.loader, shallowEqual);
  const secred: any = useSelector((state: stateType) => state.stripe.secred, shallowEqual);
  const items = useSelector((state: stateType) => state.stripe.items, shallowEqual);
  const stateFlow: stateFlowType = useSelector((state: stateType) => state.user.state, shallowEqual);
  const { medicalGroup }: any = useSelector((state: stateType) => state.account.info, shallowEqual);
  const profile = useSelector((state: stateType) => state.user.profile, shallowEqual);
  const restartState = useSelector((state: stateType) => state.restartSubscriptions.restartState);
  const { restartSubscriptionFlow: isRestart } = useSelector((state: stateType) => state.user.profile);
  const sub: any = useSelector((state: stateType) => state.stripe.item);

  useEffect(() => {
    dispatch(stripeAction.checked());
    (!medicalGroup || !tab) && dispatch(accountAction.getInfo(profile.role.toLowerCase()));
  }, []);

  useEffect(() => {
    if (items && (state === 'start_subscription' || (state === 'restart_cancel' && restartState === 4))) {
      dispatch(stripeAction.setItem(secred?.subscriptionPrice || items[0]));
    }
  }, [secred, items]);

  useDidMountEffect(() => {
    if (stateFlow === 'start_subscription' || (state === 'restart_cancel' && restartState === 4)) {
      dispatch(stripeAction.get());
      tab !== 1 && setTab(1);
    } else {
      !items.length && dispatch(stripeAction.get());
      tab !== 2 && setTab(2);
    }
  }, [state, restartState]);

  useEffect(() => {
    if (tab === 2 && !promo && !isRestart) {
      setPromo(true);
    }
  }, [tab]);

  useEffect(() => {
    setState(stateFlow);
  }, [stateFlow]);

  const action = () => {
    if (isRestart) {
      if (tab === 2) {
        dispatch(stripeAction.cansel());
        setTab(1);
      } else {
        userActions.state(mapping.stateFlowBuild(14));
      }
    } else {
      state === 'subscription_in_progress' && tab === 2
        ? userActions.state(mapping.stateFlowBuild(9))
        : dispatch(authActions.back('go-group-search'));
    }
  };

  const showDetailsHandler = () => {
    if (!medicalGroup || !tab) {
      return false;
    }

    if (state === 'subscription_in_progress' || (state === 'restart_cancel' && restartState === 5)) {
      return !!secred;
    } else if (state === 'start_subscription' || (state === 'restart_cancel' && restartState === 4)) {
      return true;
    }
  };

  const renderer = () => {
    if (loader) return null;
    if ((state === 'start_subscription' || (state === 'restart_cancel' && restartState === 4)) && items.length > 0) {
      return <SubscriptionList items={items} loader={loader} setTab={setTab} />;
    } else if (state === 'subscription_in_progress' || (state === 'restart_cancel' && restartState === 5)) {
      return <SubscriptionForm secred={secred} loader={loader} isTestUser={profile.testUser} />;
    }
  };

  return (
    <Spin spinning={authLoader || loaderRestart}>
      <div className="stripe-container custom-style">
        <AnimatePresence initial={false}>
          {tab !== 1 && promo && (
            <motion.div
              className="stripe-container__promo-alert"
              variants={animHeight}
              initial="initial"
              animate="visible"
              exit="exitSlow"
            >
              <div className="stripe-container__promo-top">
                Your promo {sub?.defaultCouponId} has been applied to your subscription
              </div>
              <button onClick={() => setPromo(false)}>
                <img src={closeIcon} alt="close promo alert" />
              </button>
            </motion.div>
          )}
        </AnimatePresence>
        <div
          className={cn('stripe-container__body box', {
            restart: state === 'restart_cancel',
          })}
        >
          <div className="stripe-container__top">
            <div className="stripe-container__back">
              <button className="stripe-container__cancel" onClick={action} />
            </div>
            <div className="stripe-container__title pad">You’re ready to join your group!</div>
          </div>
          <div className="stripe-container__wrap">
            <div className="stripe-container__back">
              <button className="stripe-container__cancel" onClick={action} />
            </div>
            <div className="stripe-container__card">
              <div className="stripe-container__row">
                <div className="stripe-container__col">
                  {tab && <Details tab={tab} setTab={setTab} medicalGroup={medicalGroup} notCancelled={!isRestart} />}
                </div>
                <div className={cn('stripe-container__col', { second: tab === 2 })}>
                  <Spin
                    spinning={
                      state === 'start_subscription' || state === 'restart_cancel'
                        ? loader || items.length === 0
                        : loader
                    }
                  >
                    <AnimatePresence initial={false}>{renderer()}</AnimatePresence>
                  </Spin>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default StripeContainer;
