import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import Moment from 'react-moment';
import 'moment-timezone';
import moment from 'moment-timezone';
import { yupResolver } from '@hookform/resolvers/yup';

// actions
import sessionsAction from '../../../../../redux/actions/sessions';

// helps
import { mapping } from '../../../../../api/mapping/request/reschedule';
// components
import { SessionItemNext } from '../../../lists/sessions/items';
import Button from '../../../../atoms/form/Button';
import { CancelButton } from '../../../../atoms/form/CancelButton';
import CustomDatepicker from '../../../../molecules/customDatepicker';
import { AddGroupEndTime, AddGroupStartTime } from '../../../../molecules/inputsBlocks/addGroup';

// icons
import Icon from '@ant-design/icons';
import { ReactComponent as IconCalendar } from 'assets/image/dropdown/calendar.svg';
// style
import '../../style.scss';
import './style.scss';
// configs
import { rescheduleSessionSchema } from 'tools/validations';

interface FormData {
  date: any;
  start: any;
  end: any;
}

const FormRescheduleSession: React.FC<any> = ({ onSuccess, setVisible, session, setNewDate }: any) => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(rescheduleSessionSchema),
  });

  const dispatch = useDispatch();

  const date = watch('date');
  const start = watch('start');
  const end = watch('end');

  const startDate = moment(date).add(moment(start).format('HH:mm'));
  const endDate = moment(date).add(moment(end).format('HH:mm'));
  const isData = date && start && end;

  const onSubmit = (data: FormData) => {
    setNewDate(data);
    dispatch(sessionsAction.reschedule(mapping.rescheduleSessionBuild(data), session.id, onSuccess));
  };

  useEffect(() => {
    setValue('date', moment(moment(session.start).format('YYYY MM DD'), 'YYYY/MM/DD'));
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="form-reschedule-session validation-form">
        <div className="form-reschedule-session__main">
          <CustomDatepicker
            errors={errors.date ? errors.date.message : undefined}
            control={control}
            name="date"
            label="Choose a Reschedule Date"
            placeholder="Select Reschedule Date"
            defaultValue={moment(session.start).format('YYYY MM DD')}
            format="MMMM Do, YYYY"
            disabled
          />

          <div className="form-reschedule-group__row">
            <div className="form-reschedule-group__column">
              <AddGroupStartTime
                errors={errors.start ? errors.start.message : undefined}
                register={register}
                control={control}
              />
            </div>
            <div className="form-reschedule-group__column">
              <AddGroupEndTime
                errors={errors.end ? errors.end.message : undefined}
                register={register}
                control={control}
              />
            </div>
          </div>

          <div className="form-reschedule-session__row">
            <DifferentSessions session={session} startDate={startDate} endDate={endDate} isData={isData} />
          </div>
        </div>
        <div className="form-reschedule-session__footer">
          <Button name="Reschedule to this time" />
          <CancelButton name={'Cancel'} cancel={() => setVisible(false)} />
        </div>
      </form>
    </>
  );
};

export default FormRescheduleSession;

export const DifferentSessions: React.FC<any> = ({ session, startDate, endDate, isData }: any) => {
  return (
    <div className="different-container">
      <div className="different-container__old">
        <div className="different-container__titles">
          <div className="different-container__title">Old Time</div>
          <div className="different-container__label">
            <Icon component={IconCalendar} />
            <Moment format="dddd MMMM Do, YYYY">{session.start}</Moment>
          </div>
        </div>
        <div className="different-container__block">
          <SessionItemNext item={session} />
        </div>
      </div>
      {isData && (
        <div className="different-container__new">
          <div className="different-container__titles">
            <div className="different-container__title">New Time</div>
            <div className="different-container__label">
              <Icon component={IconCalendar} />
              <Moment format="dddd MMMM Do, YYYY">{startDate}</Moment>
            </div>
          </div>
          <div className="different-container__block">
            <SessionItemNext item={session} startDate={startDate} endDate={endDate} />
          </div>
        </div>
      )}
    </div>
  );
};
