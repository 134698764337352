/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { ActionCallback } from '../../types/actions/common-types';
import { NPSscoreAction, NpsScoreLoaderAction } from '../../types/actions/nps_score';
import store from '../store';

const npsScoreAction = {
  getNpsScore(id?: any, callback?: ActionCallback): NPSscoreAction {
    return {
      type: 'GET_NPS_SCORE',
      id,
      callback,
    };
  },
  params(params: any) {
    store.dispatch({
      type: 'GET_NPS_SCORE_PARAMS',
      payload: {
        params: params,
      },
    });
  },
  loader(status: boolean): NpsScoreLoaderAction {
    return {
      type: 'NPS_SCORE_LOADER',
      payload: {
        loader: status,
      },
    };
  },
  create: (data: any, totalCount?: number): void => {
    store.dispatch({
      type: 'SCORE',
      payload: {
        items: data,
        totalCount,
      },
    });
  },
  clear(): void {
    store.dispatch({
      type: 'SCORE',
      payload: {
        items: [],
      },
    });
  },
};

export default npsScoreAction;
