import React, { FC, ReactNode } from 'react';
// components
import StepProgress from 'components/atoms/progress';
//types
import { IQuestion } from 'types/app-types';
// icons
import Icon from '@ant-design/icons';
import { ReactComponent as IconBack } from 'assets/image/questions/back.svg';
// styles
import './style.scss';

interface IQuestionProps {
  onBack: () => void;
  question: IQuestion;
  closeBtn?: ReactNode;
  children?: ReactNode;
  totalStepCount?: number;
  onChoose: (answerId: number) => void;
}

const SimpleQuestion: FC<IQuestionProps> = (props: IQuestionProps) => {
  const { onBack, question, closeBtn, children, totalStepCount = 1, onChoose } = props;

  return (
    <div className="questions">
      <div className="questions__header">
        <div className="questions__header-top no-border">
          <div className="questions__back" onClick={onBack}>
            <Icon component={IconBack} />
          </div>
          <div className="questions__progress">
            <StepProgress step={question.serialNumber} stepNumber={question.number} totalStepCount={totalStepCount} />
          </div>
          <div className="questions__close-btn">{closeBtn}</div>
        </div>
      </div>
      <div className="questions__body white">
        {children || (
          <>
            <div className="questions__single-question">{question.question}</div>
            <div className="questions__single-sub">{question.additions.subQuestion}</div>
          </>
        )}
      </div>
      <div className="questions__footer">
        <button className="btn-form" style={{ height: 60, flex: 1 }} onClick={() => onChoose(question.answers[0]?.id)}>
          {question.answers[0].answer}
        </button>
        {question.answers[1] && (
          <div
            className="questions__cancel"
            style={{ height: 60, marginTop: 0, flex: 1 }}
            onClick={() => onChoose(question.answers[1]?.id)}
          >
            {question.answers[1]?.answer}
          </div>
        )}
      </div>
    </div>
  );
};

export default SimpleQuestion;
