import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import background from '../../assets/image/headers/svg/background.svg';
import logo from '../../assets/image/headers/png/logoSecond.png';

// components
// components texts

const GoToSeries: React.FC = (props: any) => {
  const history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      history.push('/registration');
    }, 1000);
  }, []);

  return (
    <div
      style={{
        background: `url(${background})`,
        minHeight: 'calc(100vh)',
        backgroundSize: 'cover',
      }}
    >
      <div style={{ width: '100%', justifyContent: 'center', display: 'flex' }}>
        <div
          style={{
            width: 240,
            height: 72,
            marginTop: 50,
            background: `url(${logo}) center/contain no-repeat`,
          }}
        />
      </div>
    </div>
  );
};

export default GoToSeries;
