import React, { FC, useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
// style
import './style.scss';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { stateType } from '../../../../types/types';
import Icon, { FilterOutlined } from '@ant-design/icons';
import InputField from 'components/molecules/inputField';
import { HeaderPageContainer } from 'components/containers/pageContainer';
import MainTemplate from 'components/templates/mainTemplate';
import { useHistory } from 'react-router-dom';
import { Input, Modal, notification, Spin } from 'antd';
import Moment from 'react-moment';
import BeginsIn from 'components/molecules/beginsIn';
import { ReactComponent as CloseIcon } from '../../../../assets/image/account/close4.svg';
import eventsAction from 'redux/actions/admin_events';

import { ReactComponent as SearchIcon } from '../../../../assets/image/inputs/search.svg';
import '../../../organisms/sliders/upcomingEvents/style.scss';
import upcomingAction from '../../../../redux/actions/upcoming_events';
import Slider from 'react-slick';
import iconNextArr from '../../../../assets/image/slider/nextArr.svg';
import { useWindowResize } from 'beautiful-react-hooks';
import noteActive from '../../../../assets/image/info/noteEnabled.svg';
import noteUnActive from '../../../../assets/image/info/noteDisabled.svg';
import moment from 'moment-timezone';

const EventDetailsButtons: FC = () => {
  return (
    <div className="event-details-buttons">
      <PreviewEvent />
      <CopyLink />
      <DeleteEvent />
    </div>
  );
};

export default EventDetailsButtons;

const CopyLink = () => {
  const event: any = useSelector((state: stateType) => state.events.event, shallowEqual);
  return (
    <button
      disabled={!event?.startUrl}
      className="btn-form"
      style={{ height: 48 }}
      onClick={() => {
        navigator.clipboard.writeText(event?.startUrl);
        notification.success({
          message: 'Copied!',
          duration: 2,
        });
      }}
    >
      Copy Event Link
    </button>
  );
};

const PreviewEvent = () => {
  const event: any = useSelector((state: stateType) => state.events.event, shallowEqual);
  const history = useHistory();
  return (
    <>
      {moment(event.start) > moment() && (
        <button className="btn-form" onClick={() => history.push(`/event_details/${event.id}/preview`)}>
          Preview Event on Community Events Page
        </button>
      )}
    </>
  );
};

const DeleteEvent = () => {
  const event: any = useSelector((state: stateType) => state.events.event, shallowEqual);
  const dispatch = useDispatch();
  const history = useHistory();

  const RemoveEvent = () => {
    dispatch(eventsAction.removeEvent(event.id));
    history.push('/community_events');
  };
  return (
    <button className="btn-form btn-form__red" style={{ height: 48 }} onClick={() => RemoveEvent()}>
      Delete Event
    </button>
  );
};

export const EventDetailsPreview: FC = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [fourUpcomingEvents, setFourUpcomingEvents] = useState<any>();
  const loaderEvents: boolean = useSelector((state: stateType) => state.upcoming_events.loaderEvents, shallowEqual);
  const loaderPasts: boolean = useSelector((state: stateType) => state.upcoming_events.loaderPosts, shallowEqual);
  const events: any = useSelector((state: stateType) => state.events, shallowEqual);
  const dispatch = useDispatch();
  const history = useHistory();
  const sliderRef = useRef<any>();
  const computedMatch = history.location.pathname.match(/\d/g)?.join('');
  const loader = useSelector((state: stateType) => state.events.loader, shallowEqual);
  const componentRef = useRef<any>();
  const componentRefBlock = useRef<any>();
  const event: any = useSelector((state: stateType) => state.events.event, shallowEqual);
  const upcomingEventsPreview = events.upcomingEventsPreview?.upcoming?.items;
  const pastEventsPreview = events?.pastEventsPreview?.past?.items;
  const [widthDiv, setWidthDiv] = useState<any>();
  const [widthBlock, setWidthBlock] = useState<any>();

  useEffect(() => {
    if (upcomingEventsPreview?.length > 0 && event) {
      const indexEventsInArray = upcomingEventsPreview?.findIndex((e: any) => e.id === event.id);
      // TODO if something wrong with backend, and event is lost from backend, but event was created
      // if (indexEventsInArray === -1) {
      //   const findIndexFromTime = upcomingEventsPreview.findIndex((e: any) => e.start < event.start);
      //   console.log('findIndexFromTime', findIndexFromTime);
      //   if (findIndexFromTime === -1) {
      //     const fourOjb = [...upcomingEventsPreview];
      //     fourOjb.unshift(event);
      //     setFourUpcomingEvents(fourOjb.slice(0, 4));
      //   } else {
      //     console.log(11111);
      //   }
      // } else {
      const fourOjb =
        indexEventsInArray === 0
          ? upcomingEventsPreview?.slice(0, 4)
          : upcomingEventsPreview?.slice(indexEventsInArray - 1, indexEventsInArray + 3);
      setFourUpcomingEvents(fourOjb);
      // }
    }
  }, [upcomingEventsPreview, event]);

  const PrevArrow = ({ className, style, onClick, currentSlide }: any) => {
    if (currentSlide === 0) return null;
    return <div className={className} style={{ ...style, display: 'block' }} onClick={onClick} />;
  };

  const NextArrow = ({ className, style, onClick, currentSlide, slideCount, widthDiv, widthBlock }: any) => {
    const allSlide = (widthBlock * slideCount - widthDiv) / widthBlock;
    if (widthDiv / slideCount > widthBlock || currentSlide - 1 === Math.trunc(allSlide)) return null;
    return <div className={className} style={{ ...style, display: 'block' }} onClick={onClick} />;
  };

  const settingsPast: any = {
    className: 'slider',
    dots: false,
    draggable: false,
    infinite: false,
    slidesToShow: pastEventsPreview?.length < 3 ? 1 : width > 1500 ? 3 : width < 1100 ? 1 : 2,
    slidesToScroll: 1,
    rows: 2,
    variableWidth: width > 480,
    arrows: false,
  };

  const settingsUpcoming: any = {
    className: 'slider',
    dots: false,
    draggable: false,
    variableWidth: width > 450,
    infinite: false,
    prevArrow: <PrevArrow className="arrow next-arrow" />,
    nextArrow: <NextArrow className="arrow next-arrow" widthDiv={widthDiv} widthBlock={widthBlock} />,
  };

  useEffect(() => {
    setWidthDiv(componentRef.current ? componentRef.current.offsetWidth : 0);
  }, [componentRef.current]);

  useEffect(() => {
    setWidthBlock(componentRefBlock.current ? componentRefBlock.current.offsetWidth : 0);
  }, [componentRefBlock.current]);

  useEffect(() => {
    dispatch(eventsAction.getByID(computedMatch));
  }, []);

  useEffect(() => {
    dispatch(upcomingAction.getPastEventsAndPostsForPreview());
    dispatch(upcomingAction.getUpcomingEventsAndPostsForPreview());
  }, []);

  useWindowResize(() => {
    setWidth(window.innerWidth);
  });

  const PastEventContainer = ({ children, events }: any) => {
    return (
      <div className="past-events-container">
        {!loader && (
          <>
            {events.length > 0 && <h3 className="text-page-header--size-15">Past Events</h3>}
            {children}
          </>
        )}
      </div>
    );
  };

  return (
    <MainTemplate>
      <Spin spinning={loader}>
        <HeaderPageContainer>
          <h2 className="text-page-header">Community Events</h2>
          <div className={'view-text-under-header'}>
            <h2 className="text-page-header text-under-header">
              Register for free group sessions or webinars to explore other topics of interest, get additional skills,
              and be supported by others who get it. You can also listen to past webinars at any time. You can join all
              registered events from your home page.
            </h2>
          </div>
        </HeaderPageContainer>

        <div className="navigation-table-wrap">
          <div className="navigation-table-container">
            <div className="navigation-table-container__column">
              <div className="search-clients-field">
                <InputField>
                  <Input
                    prefix={<Icon component={SearchIcon} style={{ fontSize: 24, color: '#92929D' }} />}
                    placeholder="Search"
                    className="search-clients-field__input"
                  />
                </InputField>
              </div>
            </div>
            <div className="navigation-table-container__column">
              <div className="sort-block-wrap">
                <div className="sort-block-wrap__container">
                  <button className={'btn-sort btn-sort_sm'}>Upcoming</button>
                  <button className={'btn-sort btn-sort_sm'}>Past</button>
                  <button className={'btn-sort btn-sort_sm btn-sort_active'}>All</button>
                </div>
              </div>
            </div>
            <div className="navigation-table-container__column">
              <div className={'btn-filter btn-filter__events'}>
                <FilterOutlined style={{ fontSize: '20px', color: '#92929d', margin: '0 15px' }} />
                <span>Filter</span>
              </div>
            </div>
          </div>
        </div>
        <div className="upcoming-events-container">
          <div className="preview-events-page">
            <h3 className="text-page-header--size-15">Upcoming Events</h3>
            <div className="upcoming-events-slider">
              {fourUpcomingEvents?.length > 0 && (
                <Slider {...settingsUpcoming} ref={sliderRef}>
                  {fourUpcomingEvents?.map((item: any) => (
                    <SliderItem
                      key={item.id}
                      item={item}
                      componentRefBlock={componentRefBlock}
                      widthBlock={widthBlock}
                    />
                  ))}
                </Slider>
              )}
            </div>
          </div>
          <PastEventContainer loader={loaderPasts && loaderEvents} events={pastEventsPreview}>
            <Spin spinning={loaderPasts}>
              <div className="past-events" ref={componentRef}>
                <Slider {...settingsPast} ref={sliderRef}>
                  {pastEventsPreview?.map((item: any) => (
                    <PastEventItemHardcode key={item.id} item={item} componentRefBlock={componentRefBlock} />
                  ))}
                </Slider>
              </div>
            </Spin>
          </PastEventContainer>
        </div>
      </Spin>
    </MainTemplate>
  );
};

const PastEventItemHardcode = ({ item, componentRefBlock }: any) => {
  const { title, picture, playUrl } = item;
  const [visible, setVisible] = useState(false);

  return (
    <div className="slider-item" ref={componentRefBlock}>
      <div className="slider-item__body">
        <div className="slider-item__button slider-item__bg" style={{ cursor: 'default' }}>
          <img src={picture.url} alt="imgPlay" />
        </div>
        <div className="slider-item__title" style={{ cursor: 'default', zIndex: 1000 }}>
          {title}
        </div>
        <div style={{ zIndex: 1000 }} className="slider-item__link">
          <div className="slider-item__text" style={{ cursor: 'default' }}>
            What you’ll get out of it
          </div>
          <img src={iconNextArr} alt="show more arrow" style={{ cursor: 'default' }} />
        </div>
      </div>
      {visible ? (
        <Modal
          visible={true}
          onCancel={() => setVisible(false)}
          centered
          footer={null}
          closeIcon={<CloseIcon />}
          className="past-events__video"
        >
          <ReactPlayer url={playUrl} autoPlay controls playing={visible} />
        </Modal>
      ) : null}
    </div>
  );
};

const SliderItem = ({ item, componentRefBlock, widthBlock }: any) => {
  const [loader, setLoader] = useState(false);
  const { bookmark, picture, title, subtitle, start, end, registered, communityEventType, maxAttendees } = item;
  return (
    <div className="slider-item" ref={componentRefBlock}>
      <div className="slider-item__header">
        <img className="slider-item__bg" src={picture?.url} alt="upcoming event image" />
        <div className="slider-item__start-time">
          <Moment format="MMMM Do, YYYY">{start}</Moment> <Moment format="h:mm A">{start}</Moment>
          {' - '}
          <Moment format="h:mm A">{end}</Moment> ET
        </div>
        <img className="slider-item__note" src={bookmark ? noteActive : noteUnActive} alt="make a note" />
      </div>
      <div className="slider-item__body" style={{ maxHeight: 172 }}>
        <div className="slider-item__title-header">
          <div
            style={{
              backgroundColor: communityEventType?.id === 1 ? '#e2351e' : '#82c43c',
              width: 120,
              padding: '3px 22px',
              marginLeft: -25,
              borderTopRightRadius: 15,
            }}
          >
            <span style={{ color: '#fff' }}> {communityEventType?.name}</span>
          </div>
          <div className="slider-item__label">{`${maxAttendees} Max`}</div>
        </div>
        <div className="slider-item__main" style={{ overflowY: 'scroll', maxHeight: 50, maxWidth: 356 }}>
          <div className="slider-item__title" style={{ maxWidth: widthBlock ? widthBlock - 50 : 300 }}>
            {title}
          </div>
          <div style={{ flexDirection: 'row', display: 'flex' }}>
            <div
              className="slider-item__sub-title"
              style={{ paddingRight: 10, maxWidth: widthBlock ? widthBlock - 50 : 300 }}
            >
              {subtitle}
            </div>
          </div>
        </div>
        <div className="slider-item__footer">
          <button className="btn-item slider-item__btn" style={{ backgroundColor: registered ? '#b5b5be' : '#1891e5' }}>
            {loader ? <Spin spinning={true} /> : <div>{'Register'}</div>}
          </button>
          <div>
            <BeginsIn start={start} />
          </div>
        </div>
      </div>
    </div>
  );
};

function cn(arg0: string, arg1: { 'btn-sort_active': boolean }): string | undefined {
  throw new Error('Function not implemented.');
}
