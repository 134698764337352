import React from 'react';
import cn from 'classnames';
// icons
import { UserAvatar } from '../../../../atoms/forTable';
// helps
import _ from 'lodash';
// style
import './style.scss';

const HistoryMemberItem: React.FC<any> = ({ item }: any) => {
  const { members } = item;
  const colors = ['#6C63FF', '#1891E5', '#FC5A5A', '#82C43C'];

  const getNumberWithOrdinal = (n: number) => {
    const s = ['th', 'st', 'nd', 'rd'],
      v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  };

  return (
    <div className="history-item">
      <div className="history-item__members">
        {members &&
          members.map((item: any) => {
            const { customer, id, serialNumber } = item;
            return (
              <div className="history-item__member" key={id}>
                <UserAvatar
                  first_name={customer.firstname}
                  last_name={customer.lastname}
                  color={colors[_.random(0, 3)]}
                />
                <div className="history-item__body">
                  <div className="history-item__name">
                    {customer.firstname} {customer.lastname}
                  </div>
                  <div className="history-item__row">
                    <div className="history-item__text">
                      {serialNumber ? getNumberWithOrdinal(serialNumber) : serialNumber} Session
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default React.memo(HistoryMemberItem);

export const SessionStatus: React.FC<any> = ({ status }: any) => {
  return (
    <div
      className={cn(`session-item-status`, {
        cancelled: status.value === 6,
        missed: status.value === 5,
        attended: status.value === 4,
      })}
    >
      {status.name}
    </div>
  );
};
