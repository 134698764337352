import { LocalAudioTrack } from 'twilio-video';
import { useCallback, useState } from 'react';
import useIsTrackEnabled from '../useIsTrackEnabled/useIsTrackEnabled';
import useVideoContext from '../useVideoContext/useVideoContext';
import { useAppState } from '../../state';

export default function useLocalAudioToggle() {
  const { localTracks } = useVideoContext();
  const audioTrack = localTracks.find((track) => track.kind === 'audio') as LocalAudioTrack;
  const isEnabled = useIsTrackEnabled(audioTrack);
  const { isAudioRemotelyLocked, setIsAudioRemotelyLocked } = useAppState();

  const toggleAudioEnabled = useCallback(() => {
    console.log('TOGGLE AUDIO', isAudioRemotelyLocked);
    if (audioTrack && !isAudioRemotelyLocked) {
      audioTrack.isEnabled ? audioTrack.disable() : audioTrack.enable();
    }
  }, [audioTrack, isAudioRemotelyLocked]);

  const toggleAudioEnabledRemotely = useCallback(() => {
    if (audioTrack) {
      console.log('TOGGLE AUDIO REMOTE', audioTrack.isEnabled);
      if (audioTrack.isEnabled) {
        audioTrack.disable();
        setIsAudioRemotelyLocked(true);
      } else {
        audioTrack.enable();
        setIsAudioRemotelyLocked(false);
      }
    }
  }, [audioTrack, setIsAudioRemotelyLocked]);

  return [isEnabled, toggleAudioEnabled, toggleAudioEnabledRemotely, isAudioRemotelyLocked] as const;
}
