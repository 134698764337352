import React from 'react';
// styles
import './style.scss';

interface BoxContainerType {
  classes?: string;
  children: React.ReactNode;
}

const BoxAccontContainer: React.FC<BoxContainerType> = ({ classes, children }: BoxContainerType) => {
  return <div className={classes ? `${classes} box-account-container` : 'box-account-container'}>{children}</div>;
};

export default BoxAccontContainer;
