import React from 'react';
// components
import SearchClients from '../../../components/organisms/searchs/clients';
import SearchGroups from '../../../components/organisms/searchs/groups';
import SearchTherapists from '../../organisms/searchs/therapists';
import { SortBlock } from 'components/molecules/sortBlock';

import FilterButton from '../../../components/organisms/modals/filters/forTable';
import FilterClients from 'components/organisms/modals/filters/containers/clients';
import FilterGroups from 'components/organisms/modals/filters/containers/groups';
import FilterTherapists from '../../organisms/modals/filters/containers/therapists';

// styles
import './style.scss';

interface TableContainerType {
  name?: string;
  table?: string;
  children?: React.ReactNode;
}

const NavigationContainer: React.FC<TableContainerType> = ({ name, table }: TableContainerType) => {
  const switchController = () => {
    switch (name) {
      case 'admin':
        switch (table) {
          case 'clients':
            return (
              <>
                <div className="navigation-table-container__column">
                  <SearchClients />
                </div>
                <div className="navigation-table-container__column">
                  <SortBlock type={'clients'} />
                </div>
                <div className="navigation-table-container__column">
                  <FilterButton type="clients">
                    <FilterClients />
                  </FilterButton>
                </div>
              </>
            );
          case 'groups':
            return (
              <>
                <div className="navigation-table-container__column">
                  <SearchGroups />
                </div>
                <div className="navigation-table-container__column">
                  <SortBlock type={'groups'} />
                </div>
                <div className="navigation-table-container__column">
                  <FilterButton type="groups">
                    <FilterGroups />
                  </FilterButton>
                </div>
              </>
            );
          case 'therapists':
            return (
              <>
                <div className="navigation-table-container__column therapist">
                  <SearchTherapists />
                </div>
                <div className="navigation-table-container__column therapist">
                  <FilterButton type="therapists">
                    <FilterTherapists />
                  </FilterButton>
                </div>
              </>
            );
          default:
            return <div />;
        }

      case 'therapist':
        switch (table) {
          case 'clients':
            return (
              <>
                <div className="navigation-table-container__column">
                  <SearchClients />
                </div>
                <div className="navigation-table-container__column">
                  <SortBlock type={'clients'} />
                </div>
                <div className="navigation-table-container__column">
                  <FilterButton type="clients">
                    <FilterClients />
                  </FilterButton>
                </div>
              </>
            );
          case 'groups':
            return (
              <>
                <div className="navigation-table-container__column">
                  <SearchGroups />
                </div>
                <div className="navigation-table-container__column">
                  <SortBlock type={'groups'} />
                </div>
                <div className="navigation-table-container__column">
                  <FilterButton type="groups">
                    <FilterGroups />
                  </FilterButton>
                </div>
              </>
            );
          default:
            return <div />;
        }

      default:
        return <div />;
    }
  };
  return <div className="navigation-table-container">{switchController()}</div>;
};

export default NavigationContainer;
