import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
// components
import MainTemplate from '../../../components/templates/mainTemplate';
import GroupsTable from '../../../components/organisms/tables/therapist/groups';
import TableContainer from '../../../components/containers/tableContainer';
import { HeaderPageContainer } from '../../../components/containers/pageContainer';
import NavigationContainer from '../../../components/containers/navigationContainer';
// actions
import appAction from '../../../redux/actions/app';
import groupsAction from '../../../redux/actions/groups';
// types
import { FilterOptionsRole } from '../../../types/actions/common-types';
import eventAnalytics from '../../../redux/actions/analytics';

const TherapistsGroupsPage: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(appAction.page('My Groups'));
    dispatch(groupsAction.get());
    dispatch(eventAnalytics.analytics('view-my-groups'));

    return () => {
      dispatch(groupsAction.setOptionsIsReceived(FilterOptionsRole.Empty));
    };
  }, []);

  return (
    <MainTemplate>
      <HeaderPageContainer>
        <h2 className="text-page-header">My Groups</h2>
      </HeaderPageContainer>
      <NavigationContainer name="therapist" table="groups" />
      <TableContainer>
        <GroupsTable />
      </TableContainer>
    </MainTemplate>
  );
};

export default TherapistsGroupsPage;
