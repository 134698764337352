import React from 'react';
import { Input, DatePicker } from 'antd';
import { Controller } from 'react-hook-form';
import { useSelector, shallowEqual } from 'react-redux';
import InputMask from 'react-input-mask';

import InputField from '../../inputField';
import { Select } from 'antd';
// icons
import { CaretDownOutlined } from '@ant-design/icons';
// types
import { stateType } from '../../../../types/types';
// styles
import '../style.scss';

interface SubmitButtonType {
  name: string;
}

interface InputsType {
  errors: string | undefined;
  register?: any;
  watch?: any;
  control?: any;
  options?: any;
  defaultValue?: any;
}

export const AddTherapistFirstName: React.FC<InputsType> = ({ errors, register }: InputsType) => {
  return (
    <InputField name="firstname" label="First Name" errors={errors} icon={false}>
      <input
        id="firstname"
        className={errors && 'error'}
        placeholder="Enter Therapist First Name"
        {...register('firstname', {
          required: 'Enter Therapist First Name',
          minLength: {
            value: 3,
            message: 'First name must have at least 3 characters',
          },
        })}
      />
    </InputField>
  );
};

export const AddTherapistLastName: React.FC<InputsType> = ({ errors, register }: InputsType) => {
  return (
    <InputField name="lastname" label="Last Name" errors={errors} icon={false}>
      <input
        id="lastname"
        className={errors && 'error'}
        placeholder="Enter Therapist Last Name"
        {...register('lastname', {
          required: 'Enter Therapist Last Name',
          minLength: {
            value: 3,
            message: 'Last name must have at least 3 characters',
          },
        })}
      />
    </InputField>
  );
};

export const AddTherapistEmail: React.FC<InputsType> = ({ errors, register }: InputsType) => {
  return (
    <InputField name="email" label="Email" errors={errors} icon={false}>
      <input
        id="email"
        className={errors && 'error'}
        placeholder="Enter Therapist Email"
        {...register('email', {
          required: 'Enter Therapist Email',
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
            message: 'enter a valid e-mail address',
          },
        })}
      />
    </InputField>
  );
};

export const ForumInputName: React.FC<InputsType> = ({ errors, register }: InputsType) => {
  return (
    <InputField name="forumUsername" label="" errors={errors} icon={false}>
      <input
        id="forumUsername"
        className={errors && 'error'}
        placeholder="forum name"
        {...register('forumUsername', {
          required: 'enter your forum name',
          minLength: {
            value: 3,
            message: 'Last name must have at least 3 characters',
          },
        })}
      />
    </InputField>
  );
};

export const PhonesInput: React.FC<InputsType> = ({ errors, control }: InputsType) => {
  return (
    <InputField name="phone" label="Phone Number" errors={errors}>
      <Controller
        control={control}
        name="phone"
        rules={{
          required: 'Enter a Phone Number',
          pattern: {
            value: /[0-9]{3} [0-9]{3} [0-9]{4}/,
            message: 'Please add a valid phone number',
          },
        }}
        render={({ field: { onChange } }) => (
          <InputMask
            mask="+1 999 999 9999"
            placeholder="Enter a Phone Number"
            onChange={(value: any) => {
              onChange(value);
            }}
          />
        )}
      />
    </InputField>
  );
};

export const AddTherapistLicensure: React.FC<InputsType> = ({ errors, control }: InputsType) => {
  const options: any = useSelector((state: stateType) => state.options.licenses, shallowEqual);
  return (
    <InputField name="licenseIds" label="Licensure" errors={errors} icon={false}>
      <Controller
        control={control}
        name="licenseIds"
        rules={{ required: 'select Therapist licensure' }}
        render={({ field: { onChange } }) => (
          <Select
            options={options}
            placeholder="Enter Licensure"
            className="form-select"
            dropdownClassName="form-dropdown"
            mode="tags"
            style={{ width: '100%' }}
            onChange={(value: any) => {
              onChange(value);
            }}
            maxTagCount={'responsive'}
            showArrow
            allowClear
            suffixIcon={<CaretDownOutlined />}
          />
        )}
      />
    </InputField>
  );
};

export const AddTherapistSelectZoom: React.FC<InputsType> = ({ errors, control }: InputsType) => {
  const options: any = useSelector((state: stateType) => state.therapists.zoomClients, shallowEqual);

  return (
    <InputField name="zoomClientId" label="Zoom" errors={errors} icon={false}>
      <Controller
        control={control}
        name="zoomClientId"
        render={({ field: { onChange } }) => (
          <Select
            className="form-select"
            dropdownClassName="form-dropdown"
            placeholder="Zoom"
            style={{ width: '100%' }}
            onChange={(value: any) => {
              onChange(value);
            }}
            options={options}
            allowClear
            suffixIcon={<CaretDownOutlined />}
            defaultValue={undefined}
          />
        )}
      />
    </InputField>
  );
};

export const SelectTitle: React.FC<InputsType> = ({ errors, control }: InputsType) => {
  const options: any = useSelector((state: stateType) => state.options.titles);

  return (
    <InputField name="titleId" label="Therapist Title" errors={errors} icon={false}>
      <Controller
        control={control}
        name="titleId"
        render={({ field: { onChange } }) => (
          <Select
            className="form-select"
            dropdownClassName="form-dropdown"
            placeholder="Select Therapist Title"
            style={{ width: '100%' }}
            onChange={(value: any) => {
              onChange(value);
            }}
            options={options}
            allowClear
            suffixIcon={<CaretDownOutlined />}
            defaultValue={undefined}
          />
        )}
      />
    </InputField>
  );
};

export const Bio: React.FC<InputsType> = ({ errors, control, defaultValue }: InputsType) => {
  return (
    <InputField name="biography" label="Bio" errors={errors} icon={false}>
      <Controller
        control={control}
        name="biography"
        rules={{
          minLength: { value: 200, message: 'Enter a bio that is at least 200 letters long' },
        }}
        render={({ field: { onChange } }) => (
          <Input.TextArea
            rows={7}
            autoSize={false}
            onChange={(value: any) => {
              onChange(value);
            }}
            defaultValue={defaultValue}
          />
        )}
      />
    </InputField>
  );
};

export const YearsinPracticeInput: React.FC<InputsType> = ({ errors, register, defaultValue }: InputsType) => {
  return (
    <InputField name="practiceYears" label="Years in Practice" errors={errors} icon={false}>
      <input
        className={errors && 'error'}
        placeholder="Type in # of Years"
        {...register('practiceYears')}
        defaultValue={defaultValue}
      />
    </InputField>
  );
};

export const JoinedDate: React.FC<InputsType> = ({ errors, control }: InputsType) => {
  return (
    <InputField name="joinedDate" label="Date Joined" errors={errors} icon={false}>
      <Controller
        control={control}
        name="joinedDate"
        render={({ field: { onChange } }) => (
          <DatePicker
            className="form-time-select"
            onChange={onChange}
            placeholder="Select Therapist Date Joined"
            format={'YYYY/MM/DD'}
          />
        )}
      />
    </InputField>
  );
};

export const SpecialtiesSelect: React.FC<InputsType> = ({ errors, control, defaultValue }: InputsType) => {
  const options: any = useSelector((state: stateType) => state.options.specialties, shallowEqual);

  return (
    <InputField name="specialtyIds" label="Specialties" errors={errors} icon={false}>
      <Controller
        control={control}
        name="specialtyIds"
        render={({ field: { onChange } }) => (
          <Select
            mode="tags"
            maxTagCount="responsive"
            className="form-select"
            dropdownClassName="form-dropdown"
            placeholder="Select Specialities"
            style={{ width: '100%' }}
            onChange={(value: any) => {
              onChange(value);
            }}
            options={options}
            allowClear
            suffixIcon={<CaretDownOutlined />}
            defaultValue={defaultValue ? defaultValue : undefined}
          />
        )}
      />
    </InputField>
  );
};

export const PossibleGroupTopics: React.FC<InputsType> = ({ errors, control, defaultValue }: InputsType) => {
  const options: any = useSelector((state: stateType) => state.options.topics, shallowEqual);

  return (
    <InputField name="groupTopicIds" label="Possible Group Topics" errors={errors} icon={false}>
      <Controller
        control={control}
        name="groupTopicIds"
        render={({ field: { onChange } }) => (
          <Select
            mode="tags"
            maxTagCount="responsive"
            className="form-select"
            dropdownClassName="form-dropdown"
            placeholder="Select Possible Group Topics"
            style={{ width: '100%' }}
            onChange={(value: any) => {
              onChange(value);
            }}
            suffixIcon={<CaretDownOutlined />}
            options={options}
            allowClear
            defaultValue={defaultValue ? defaultValue : undefined}
          />
        )}
      />
    </InputField>
  );
};

export const SelectZoom: React.FC<InputsType> = ({ errors, control, options, defaultValue }: InputsType) => {
  return (
    <InputField name="zoomClientId" label="" errors={errors} icon={false}>
      <Controller
        control={control}
        name="zoomClientId"
        rules={{ required: 'select Therapist licensure' }}
        render={({ field: { onChange, onBlur, value } }) => (
          <Select
            className={
              errors ? 'form-select form-select__search form-select__error' : 'form-select form-select__search'
            }
            dropdownClassName="form-dropdown"
            placeholder="Search for an email address"
            style={{ width: '100%' }}
            onChange={(value: any) => {
              onChange(value);
            }}
            onBlur={() => {
              !value && onChange(null);
              onBlur();
            }}
            onFocus={() => {
              onChange('');
            }}
            options={options}
            allowClear
            showSearch
            suffixIcon={<CaretDownOutlined />}
            filterOption={(input: string, option: any) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            onDropdownVisibleChange={(open) => !open && onBlur()}
            getPopupContainer={(trigger) => trigger.parentNode}
          />
        )}
      />
    </InputField>
  );
};

export const SubmitButton: React.FC<SubmitButtonType> = ({ name }: SubmitButtonType) => {
  return (
    <button type="submit" className="btn-form">
      {name}
    </button>
  );
};
