/*
 *
 * Progress reducer
 *
 */
import { ProgressCoreItemType, LongTermItemType, LogItemType } from '../../types/actions/progress';
const initialState = {
  list: [] as ProgressCoreItemType[],
  term: [] as LongTermItemType[],
  logs: [] as LogItemType[],
  log: {} as LogItemType,
  loader: false,
  loaderModal: false,
};

export type progressInitStateType = typeof initialState;

interface progressActionTypes {
  type: string;
  payload: {
    list: ProgressCoreItemType[];
    term: LongTermItemType[];
    logs: LogItemType[];
    log: LogItemType;
    loader: boolean;
    loaderModal: boolean;
  };
}

const progress = (state = initialState, action: progressActionTypes): progressInitStateType => {
  switch (action.type) {
    case 'PROGRESS_LIST':
      return Object.assign({}, state, {
        list: action.payload.list,
      });

    case 'PROGRESS_TERM_LIST':
      return Object.assign({}, state, {
        term: action.payload.term,
      });

    case 'PROGRESS_LOGS':
      return Object.assign({}, state, {
        logs: action.payload.logs,
      });

    case 'PROGRESS_LOG_ITEM':
      return Object.assign({}, state, {
        log: action.payload.log,
      });

    case 'PROGRESS_LOADER':
      return Object.assign({}, state, {
        loader: action.payload.loader,
      });

    case 'PROGRESS_LOADER_MODAL':
      return Object.assign({}, state, {
        loaderModal: action.payload.loaderModal,
      });

    default:
      return state;
  }
};

export default progress;
