import React, { useCallback, useEffect, useState } from 'react';
import {
  RemoteParticipant,
  LocalDataTrack,
  LocalParticipant,
  RemoteVideoTrackPublication,
  LocalVideoTrackPublication,
  RemoteAudioTrackPublication,
  LocalAudioTrackPublication,
  RemoteAudioTrack,
  LocalAudioTrack,
  LocalVideoTrack,
  RemoteVideoTrack,
} from 'twilio-video';

import { createStyles, makeStyles, Theme } from '@material-ui/core';
import VideoOnIcon from '../../../icons/VideoOnIcon';
import MicIcon from '../../../icons/MicIcon';
import VideoOffIcon from '../../../icons/VideoOffIcon';
import MicOffIcon from '../../../icons/MicOffIcon';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import usePublications from '../../../hooks/usePublications/usePublications';
import useTrack from '../../../hooks/useTrack/useTrack';
import useIsTrackEnabled from '../../../hooks/useIsTrackEnabled/useIsTrackEnabled';
import { displayName, initials } from '../Helpers/helpers';
import useUserDataContext from '../../../hooks/useUserDataContext/useUserDataContext';

interface ParticipantMenuItemProps {
  participant: RemoteParticipant | LocalParticipant;
  isLocal?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    participantMenuItem: {
      margin: '5px 21px',
      padding: 10,
      clear: 'both',
      borderRadius: 8,
      '&:hover': {
        backgroundColor: '#E6F2F7',
      },
      '&::after': {
        content: '""',
        display: 'block',
        clear: 'both',
      },
    },
    itemLine: {
      paddingTop: 11,
    },
    participantName: {
      maxWidth: '134px',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      float: 'left',
    },
    participantMenuButton: {
      padding: '8px 8px 0 8px',
      float: 'right',
      position: 'relative',
      top: '-8px',
    },
    initials: {
      width: 40,
      height: 40,
      borderRadius: '50%',
      backgroundColor: 'rgba(152, 162, 179, 1)',
      float: 'left',
      color: '#ffffff',
      textAlign: 'center',
      paddingTop: 11,
      marginRight: 11,
    },
  }),
);

export default function ParticipantMenuItem({ participant, isLocal }: ParticipantMenuItemProps) {
  if (typeof isLocal == 'undefined') {
    isLocal = false;
  }

  const cssClasses = useStyles();
  const { room, localTracks } = useVideoContext();
  const localParticipant = room!.localParticipant;
  const publications = usePublications(participant);
  const audioPublication = publications.find((p) => p.kind === 'audio');
  const videoPublication = publications.find((p) => !p.trackName.includes('screen') && p.kind === 'video');
  const audioTrack = useTrack(audioPublication);
  const videoTrack = useTrack(videoPublication);

  const audioEnabled = useIsTrackEnabled(audioTrack as LocalAudioTrack | RemoteAudioTrack);
  const videoEnabled = useIsTrackEnabled(videoTrack as LocalVideoTrack | RemoteVideoTrack);

  const { getUserName } = useUserDataContext();

  const toggleParticipantVideo = useCallback(() => {
    localTracks
      .filter((track) => track.kind == 'data')
      .forEach((track) => {
        //@ts-ignore
        track.send(JSON.stringify({ command: 'toggle-video', recipient: participant.sid }));
      });
  }, []);

  const toggleParticipantAudio = useCallback(() => {
    localTracks
      .filter((track) => track.kind == 'data')
      .forEach((track) => {
        //@ts-ignore
        track.send(JSON.stringify({ command: 'toggle-audio', recipient: participant.sid }));
      });
  }, []);

  return (
    <div className={cssClasses.participantMenuItem}>
      <div className={cssClasses.initials}>{initials(participant.identity)}</div>
      <div className={cssClasses.itemLine}>
        <div className={cssClasses.participantName}>
          {participant.identity == localParticipant.identity ? 'You' : getUserName(participant.identity)}
        </div>
        {!isLocal ? (
          <>
            <button
              className={cssClasses.participantMenuButton}
              style={{ display: 'none' }}
              onClick={toggleParticipantVideo}
            >
              {videoEnabled ? (
                <VideoOnIcon size={18} color={'#667085'} />
              ) : (
                <VideoOffIcon size={18} color={'#E19D89'} />
              )}
            </button>
            <button className={cssClasses.participantMenuButton} onClick={toggleParticipantAudio}>
              {audioEnabled ? <MicIcon size={18} color={'#667085'} /> : <MicOffIcon size={18} color={'#E19D89'} />}
            </button>
          </>
        ) : (
          ''
        )}
      </div>
    </div>
  );
}
