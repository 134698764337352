import React, { useEffect } from 'react';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
// components
import SimpleHeader from '../../components/organisms/headers/simple/simple-header';
import RestartSubscriptionsContainer from '../../components/containers/restartSubscriptionsTemplate';
// actions
import accountAction from '../../redux/actions/account';
import restartAction from '../../redux/actions/restart_subscriptions';
import userActions from '../../redux/actions/user';
// types
import { stateType } from '../../types/types';
// styles
import './style.scss';

const RestartSubscriptionsPage: React.FC = () => {
  const info: any = useSelector((state: stateType) => state.account.info, shallowEqual);
  const state = useSelector((state: stateType) => state.restartSubscriptions.restartState);

  const dispatch = useDispatch();

  const history = useHistory();

  const onClose = () => {
    if (state === 6) {
      userActions.state('group_joined');
      history.push('/');
    } else {
      dispatch(restartAction.cancel(() => history.push('/')));
    }
  };

  useEffect(() => {
    _.isEmpty(info) && dispatch(accountAction.getInfo());
    dispatch(restartAction.state(1));

    return () => {
      dispatch(accountAction.set({}));
      dispatch(restartAction.clear());
    };
  }, []);

  return (
    <div className="choice-group-page">
      <div className="change-group-template custom-style">
        <SimpleHeader />
        <div className="change-group-template__body box">
          <div className="change-group-template__header">
            <div className="change-group-template__close" onClick={onClose}>
              <button />
            </div>
          </div>
          <RestartSubscriptionsContainer />
        </div>
      </div>
    </div>
  );
};

export default RestartSubscriptionsPage;
