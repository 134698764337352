/*
 *
 * User reducer
 *
 */

export enum userRoles {
  customer = 'CUSTOMER',
  admin = 'ADMIN',
  therapist = 'THERAPIST',
}

export type userProfile = {
  stateFlow: number;
  username: string;
  create: string;
  role: userRoles;
  avatar?: string;
  firstname?: string;
  lastname?: string;
  email?: string;
  status?: { value: number; name: string };
  subscriptionStatus?: { value: number; name: string };
  progressScoreType?: boolean;
  restartSubscriptionFlow?: boolean;
  testUser?: boolean;
  resetPasswordToken?: any;
};

export type stateFlowType =
  | 'registered'
  | 'waiting'
  | 'group_search'
  | 'questioning'
  | 'group_joined'
  | 'group_change'
  | 'recommendation'
  | 'progress'
  | 'start_subscription'
  | 'subscription_in_progress'
  | 'approve_consultations'
  | 'redirect_to_reset_password'
  | 'restart_cancel'
  | 'restart_pre_cancel'
  | '';

const initialState = {
  profile: {} as userProfile,
  state: '' as stateFlowType,
};

export type userInitStateType = typeof initialState;

interface userActionsTypes {
  type: string;
  payload: {
    profile: userProfile;
    state: stateFlowType;
  };
}

const user = (state = initialState, action: userActionsTypes): userInitStateType => {
  switch (action.type) {
    case 'USER':
      return Object.assign({}, state, {
        profile: action.payload.profile,
      });

    case 'USER_STATE':
      return Object.assign({}, state, {
        state: action.payload.state,
      });

    default:
      return state;
  }
};

export default user;
