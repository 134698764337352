import React from 'react';

interface SubmitButtonType {
  name: string;
  action?: (...arg: Array<any>) => void;
  disabled?: boolean;
  className?: string;
}

const Button: React.FC<SubmitButtonType> = ({ name, action, disabled, className }: SubmitButtonType) => {
  return (
    <button type="submit" onClick={action} disabled={disabled} className={`btn-form ${className}`}>
      {name}
    </button>
  );
};

export default Button;
