import React, { FC } from 'react';
import cn from 'classnames';
import { Checkbox } from 'antd';
// types
import { IAnswer, IQuestion } from 'types/app-types';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';

interface IMultiQuestion {
  question: IQuestion;
  onChangeCheck: (checkedValues: CheckboxValueType[]) => void;
  checkedValues: Array<number>;
  small?: boolean;
}

const MultiQuestion: FC<IMultiQuestion> = ({ question, onChangeCheck, checkedValues, small }: IMultiQuestion) => {
  return (
    <Checkbox.Group
      style={{ width: '100%' }}
      onChange={onChangeCheck}
      defaultValue={(question.defaultValue?.answerIds || []) as Array<number>}
    >
      {question.answers.map((el: IAnswer) => (
        <div
          key={el.id}
          className={cn('questions__answer', 'questions__check-answer', {
            selected: checkedValues.some((item: number) => item === el.id),
            small,
          })}
          onClick={() => onChangeCheck}
        >
          <Checkbox value={el.id}>
            <div className="questions__answer-text">{el.answer}</div>
          </Checkbox>
        </div>
      ))}
    </Checkbox.Group>
  );
};

export default MultiQuestion;
