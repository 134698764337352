import React from 'react';

interface CancelButtonType {
  name: string;
  cancel: () => void;
}

export const CancelButton: React.FC<CancelButtonType> = ({ name, cancel }: CancelButtonType) => {
  return (
    <div className="cancel-button">
      <button type="button" className="btn-light btn-light_cancel" onClick={cancel}>
        {name}
      </button>
    </div>
  );
};
