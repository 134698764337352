import React, { FC, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Modal, Spin, Tooltip } from 'antd';
import { CancelButton } from 'components/atoms/form/CancelButton';
import debounce from 'lodash/debounce';
// components
import InputField from '../../../molecules/inputField';
// icons
import Icon, { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { ReactComponent as CloseIcon } from 'assets/image/account/close4.svg';
import { ReactComponent as IconEditBlue } from '../../../../assets/image/account/edit-blue.svg';
// types
import { stateType } from '../../../../types/types';
import { IMember } from 'types/app-types';
import { ObjectType } from '../../../../types/actions/common-types';
// actions
import groupsAction from '../../../../redux/actions/groups';
import eventsAction from '../../../../redux/actions/admin_events';
// style
import './style.scss';
import eventAnalytics from '../../../../redux/actions/analytics';

const Members: React.FC<any> = ({ members, edit, group }: any) => {
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const open = () => {
    dispatch(eventAnalytics.analytics('view-group-members', { groupId: group.id }));
    setVisible(true);
  };

  return (
    <>
      <div className="members-block">
        <div className="members-block__header">
          <div className="members-block__title">
            Current Members{' '}
            {members && (
              <>
                <div className="members-block__dot" />
                {members.length}
              </>
            )}
          </div>
          <div className="members-block__show" onClick={open}>
            See more
          </div>
        </div>
        <div className="members-block__body">
          {members &&
            members.map((item: any) => {
              return (
                <div key={item.id} className="members-block__member" style={{ backgroundColor: item.color }}>
                  {item.firstname[0]}
                  {item.lastname[0]}
                </div>
              );
            })}
        </div>
      </div>
      {visible && <MembersModal visible={visible} setVisible={setVisible} members={members} edit={edit} />}
    </>
  );
};

export default Members;

export const EventEditMembers: FC<any> = ({ members, isEvent }: any) => {
  const [visible, setVisible] = useState(false);

  const open = () => {
    setVisible(true);
  };

  return (
    <>
      <div className="members-block__show" onClick={open}>
        <Icon component={IconEditBlue} />
        <span>Edit</span>
      </div>
      {visible && (
        <MembersModal visible={visible} setVisible={setVisible} members={members} edit={true} isEvent={isEvent} />
      )}
    </>
  );
};

const MembersModal = ({ visible, setVisible, members, edit, isEvent }: any) => {
  const loader: boolean = useSelector((state: stateType) => state.groups.loaderModal);
  const result: any = useSelector((state: stateType) => state.groups.searchResult);
  const group: any = useSelector((state: stateType) => state.groups.item.group);

  const loaderEvent: boolean = useSelector((state: stateType) => state.events.loaderModal);
  const event: ObjectType = useSelector((state: stateType) => state.events.event);

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      visible={visible}
      onOk={handleOk}
      closable={true}
      onCancel={handleCancel}
      centered
      maskTransitionName="none"
      footer={null}
      className="members-modal"
      closeIcon={<CloseIcon />}
    >
      <Spin spinning={loader || loaderEvent}>
        <MembersList members={members} result={result} group={group} event={event} edit={edit} isEvent={isEvent} />
      </Spin>
    </Modal>
  );
};

const MembersList = ({ members, result, group, edit, event, isEvent }: any) => {
  const role = useSelector((state: any) => state.user.profile.role, shallowEqual);

  const [list, setList] = useState(true);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [clickedMember, setClickedMember] = useState();

  const dispatch = useDispatch();

  const remove = (id: any) => {
    if (isEvent) {
      dispatch(eventsAction.removeClient({ eventId: event.id, customerId: id }));
      toggleModal();
      // dispatch(eventsAction.getByID(event.id));
    } else {
      dispatch(groupsAction.removeClient({ groupId: group.id, customerId: id }));
    }
  };

  const switchController = () => {
    setList(!list);
  };

  const onClickMember = (item: any) => {
    dispatch(eventAnalytics.analytics('view-member-details', { userId: item?.id }));
  };

  const saveClickedMember = (itemId: any) => {
    setClickedMember(itemId);
    setOpenDeleteModal(true);
  };

  const toggleModal = () => {
    setOpenDeleteModal(!openDeleteModal);
  };

  return (
    <>
      {!openDeleteModal && (
        <div className="members-list">
          <div className="members-list__header">
            <div className="members-list__title">Current Members ({members?.length})</div>
            {edit && (
              <div className="members-list__switch" onClick={switchController}>
                {list ? 'Find Members' : 'List Members'}
              </div>
            )}
          </div>

          {edit && !list && (group || isEvent) && (
            <SearchMembers result={result} group={group} event={event} isEvent={isEvent} />
          )}
          <div className="members-list__modal">
            {list &&
              members &&
              members.map((item: any) => {
                return (
                  <div key={item.id} className="members-list__item" onClick={() => onClickMember(item)}>
                    <a
                      className="members-list__item-user"
                      href={`/${role === 'ADMIN' ? `all_clients/${item.id}` : `clients/${item.clientId}`}`}
                    >
                      <div className="members-list__member" style={{ backgroundColor: item.color }}>
                        {item.firstname[0]}
                        {item.lastname[0]}
                      </div>
                      <span>
                        {item.firstname} {item.lastname}
                      </span>
                    </a>
                    {edit && (
                      <MinusOutlined
                        label={'remove'}
                        onClick={() => saveClickedMember(item.id)}
                        style={{ cursor: 'pointer' }}
                      />
                    )}
                  </div>
                );
              })}
          </div>
        </div>
      )}
      {openDeleteModal && (
        <div className="restart-client-form">
          <div className="restart-client-form__container">
            <div className="success-change-group-container__title-img">
              <p className="restart-client-form__customer-title">Remove member</p>
            </div>
            <div className="restart-client-form__main restart-client-form__select-group">
              <p className="restart-client-form__text">Are you sure you’d like to remove this person from the group?</p>
            </div>
            <div className="restart-client-form__confirm-button">
              <button
                className="btn-form"
                onClick={() => {
                  toggleModal();
                  remove(clickedMember);
                }}
              >
                Yes
              </button>
              <CancelButton
                name="No"
                cancel={() => {
                  toggleModal();
                }}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

interface IMembersMiniList {
  members: Array<any>;
  title?: string;
}

export const MembersMiniList: React.FC<IMembersMiniList> = ({ members, title }: IMembersMiniList) => {
  const role = useSelector((state: any) => state.user.profile.role, shallowEqual);

  const [visible, setVisible] = useState(false);

  return (
    <>
      <div className="members-mini-list" onClick={() => setVisible(true)}>
        {members?.length ? (
          <>
            <span className="members-mini-list__total">
              {members.length} {members.length === 1 ? 'member' : 'members'}
            </span>
            {members
              .slice(0, 3)
              .reverse()
              .map((item: IMember, index: number) => (
                <span
                  key={index}
                  className="members-mini-list__item"
                  style={{ backgroundColor: item.color ? item.color : 'black' }}
                >
                  <Tooltip placement="top" title={`${item.firstname}`}>
                    {item.firstname[0]}
                    {item.lastname[0]}
                  </Tooltip>
                </span>
              ))}
          </>
        ) : (
          <span className="members-mini-list__total">{members.length} members</span>
        )}
      </div>
      <Modal
        visible={visible}
        centered
        footer={null}
        onCancel={() => setVisible(false)}
        className="members-modal"
        closeIcon={<CloseIcon />}
      >
        <div className="members-modal__body">
          <div className="members-modal__header">
            {title ? title : 'Enrolled Members'} ({members.length})
          </div>
          <div className="members-modal__list">
            {members.map((member: IMember, i: number) => (
              <a
                key={i}
                className="members-modal__item"
                href={`/${role === 'ADMIN' ? `all_clients/${member.id}` : `clients/${member.clientId}`}`}
                role="button"
              >
                <div className="members-modal__avatar" style={{ backgroundColor: member?.color }}>
                  {member.firstname[0]}
                  {member.lastname[0]}
                </div>
                <div className="members-modal__name">
                  {member.firstname} {member.lastname}
                </div>
              </a>
            ))}
          </div>
        </div>
      </Modal>
    </>
  );
};

const SearchMembers = ({ result, group, event, isEvent }: any) => {
  const dispatch = useDispatch();
  let filterMembers: any = [];
  filterMembers = result.filter((e: any) => {
    return !event?.members?.find((el: any) => {
      return e?.id === el?.id;
    });
  });
  useEffect(() => {
    return () => {
      groupsAction.searchResult([], false);
    };
  }, []);

  const add = (id: any) => {
    if (isEvent) {
      dispatch(eventsAction.addClient({ eventId: event.id, customerId: id }));
    } else {
      dispatch(groupsAction.addClient({ groupId: group.id, customerId: id }));
    }
  };

  const onChange = async (e: any) => {
    try {
      if (isEvent) {
        dispatch(eventsAction.search(e.target.value));
      } else {
        dispatch(groupsAction.search(e.target.value));
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="search-members">
      <div className="search-members__container">
        <InputField name="search" label="Search" errors={false} icon={false} classes="search-members__search-label">
          <input onChange={debounce(onChange, 400)} />
        </InputField>
      </div>
      <div className="search-members__result">
        {result &&
          result.map((item: any) => {
            return (
              <div key={item.id} className="members-list__item">
                <div className="members-list__item-user">
                  <div className="members-list__member" style={{ backgroundColor: item.color }}>
                    {item.firstname[0]}
                    {item.lastname[0]}
                  </div>
                  {item.firstname} {item.lastname}
                </div>
                <PlusOutlined onClick={() => add(item.id)} style={{ cursor: 'pointer' }} />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export const EventMembers: React.FC<IMembersMiniList> = ({ members }: IMembersMiniList) => {
  return (
    <div className="members-modal__list">
      {members?.map((member: any) => {
        return (
          <a key={member.id} className="members-modal__item" href={`/all_clients/${member.id}`} role="button">
            <div className="members-modal__avatar" style={{ backgroundColor: member.color }}>
              {member.firstname[0]}
              {member.lastname[0]}
            </div>
            <div className="members-modal__name">
              {member.firstname} {member.lastname}
            </div>
          </a>
        );
      })}
    </div>
  );
};
