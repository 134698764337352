import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
// components
import CustomDatepicker from '../../../molecules/customDatepicker';
// styles
import './style.scss';
import therapistAction from '../../../../redux/actions/therapist';
import { useParams } from 'react-router-dom';

interface FormData {
  start: any;
  end: any;
}

type DocumentType = 'pdf' | 'word';

const UploadHistoryContainer = ({ setVisible }: any) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();

  const onSubmit = (data: FormData, type: DocumentType) => {
    dispatch(therapistAction.getInvoice({ data, type, id: params }, () => setVisible(false)));
  };

  return (
    <div className="upload-history">
      <div className="upload-history__title">Session History Statements</div>

      <form>
        <div className="upload-history__main">
          <CustomDatepicker
            errors={errors.start ? errors.start.message : undefined}
            control={control}
            name="start"
            label="Choose a Start Date"
            placeholder="Select Start Date"
            format="MMMM Do, YYYY"
            disabled={false}
          />
          <CustomDatepicker
            errors={errors.end ? errors.end.message : undefined}
            control={control}
            name="end"
            label="Choose a End Date"
            placeholder="Select End Date"
            format="MMMM Do, YYYY"
            disabled={false}
          />
        </div>
        <div className="upload-history__buttons">
          <button className="btn-form" onClick={handleSubmit((data) => onSubmit(data, 'word'))}>
            DOWNLOAD WORD STATEMENT
          </button>
          <button className="btn-form" onClick={handleSubmit((data) => onSubmit(data, 'pdf'))}>
            DOWNLOAD PDF STATEMENT
          </button>
        </div>
      </form>
    </div>
  );
};

export default UploadHistoryContainer;
