import { call, select } from 'redux-saga/effects';
import showError from './showError';
import { apiAnalytics } from '../../api/analytics';

export function* sagaSendAnalytics(action) {
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);
  try {
    if (role !== 'ADMIN') {
      yield call(apiAnalytics.analytics, role.toLowerCase(), action);
    }
  } catch (e) {
    showError();
  } finally {
  }
}

export function* sagaResetPasswordAnalytics() {
  try {
    yield call(apiAnalytics.resetPassword);
  } catch (e) {
    console.log(e);
    showError();
  } finally {
  }
}
