import React from 'react';
import { Controller } from 'react-hook-form';
import { shallowEqual, useSelector } from 'react-redux';

import { DatePicker, Select } from 'antd';
import InputField from '../../inputField';
// types
import { stateType } from '../../../../types/types';
// helps
import { week_days } from '../../../../configs/tables/admin/clients';
// icons
import { CaretDownOutlined } from '@ant-design/icons';
// styles
import '../style.scss';

interface SubmitButtonType {
  name: string;
}

interface InputsType {
  errors: string | undefined;
  register: any;
  watch?: any;
  control?: any;
  defaultValue?: any;
}

export const AddGroupSelectTopics: React.FC<InputsType> = ({ errors, control, defaultValue }: InputsType) => {
  const options: any = useSelector((state: stateType) => state.groups.topics, shallowEqual);

  return (
    <InputField name="groupTopicId" label="Group topic" errors={errors} icon={false}>
      <Controller
        control={control}
        name="groupTopicId"
        rules={{ required: 'choose at least one option' }}
        render={({ field: { onChange } }) => (
          <Select
            className="form-select"
            dropdownClassName="form-dropdown"
            placeholder="Choose a group topic"
            style={{ width: '100%' }}
            onChange={(value: any) => {
              onChange(value);
            }}
            options={options}
            allowClear
            defaultValue={defaultValue}
          />
        )}
      />
    </InputField>
  );
};

export const AddGroupSelectTherapists: React.FC<InputsType> = ({ errors, control, defaultValue }: InputsType) => {
  const options: any = useSelector((state: stateType) => state.groups.therapists, shallowEqual);

  return (
    <InputField name="therapistId" label="Therapist" errors={errors} icon={false}>
      <Controller
        control={control}
        name="therapistId"
        rules={{ required: 'choose at least one option' }}
        render={({ field: { onChange } }) => (
          <Select
            className="form-select"
            dropdownClassName="form-dropdown"
            placeholder="Select the therapist"
            style={{ width: '100%' }}
            onChange={(value: any) => {
              onChange(value);
            }}
            options={options}
            allowClear
            showSearch
            suffixIcon={<CaretDownOutlined />}
            defaultValue={defaultValue}
            filterOption={(input, option: any): any => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          />
        )}
      />
    </InputField>
  );
};

export const AddGroupSelectDays: React.FC<InputsType> = ({ errors, control }: InputsType) => {
  const options = week_days.map((item, index) => {
    return { value: index + 1, label: item + 's' };
  });

  return (
    <InputField name="dayOfWeek" label="Day" errors={errors} icon={false}>
      <Controller
        control={control}
        name="dayOfWeek"
        rules={{ required: 'choose at least one option' }}
        render={({ field: { onChange } }) => (
          <Select
            className="form-select"
            dropdownClassName="form-dropdown"
            placeholder="Select day"
            style={{ width: '100%' }}
            onChange={(value: any) => {
              onChange(value);
            }}
            options={options}
            allowClear
            suffixIcon={<CaretDownOutlined />}
            defaultValue={undefined}
          />
        )}
      />
    </InputField>
  );
};

export const AddGroupStartTime: React.FC<InputsType> = ({ errors, control }: InputsType) => {
  return (
    <InputField name="start" label="Start time" errors={errors} icon={false}>
      <Controller
        control={control}
        name="start"
        rules={{ required: 'Choose start group time' }}
        render={({ field: { onChange } }) => (
          <DatePicker
            className="form-time-select"
            picker={'time'}
            suffixIcon={null}
            onChange={onChange}
            format="h:mm A"
            use12Hours={true}
            minuteStep={5}
            placeholder="e.g. 6 PM, 12:30 PM"
          />
        )}
      />
    </InputField>
  );
};

export const AddGroupEndTime: React.FC<InputsType> = ({ errors, control }: InputsType) => {
  return (
    <InputField name="end" label="End time" errors={errors} icon={false}>
      <Controller
        control={control}
        name="end"
        rules={{ required: 'Choose end group time' }}
        render={({ field: { onChange } }) => (
          <DatePicker
            className="form-time-select"
            picker={'time'}
            suffixIcon={null}
            onChange={onChange}
            format="h:mm A"
            use12Hours={true}
            minuteStep={5}
            placeholder="e.g. 6 PM, 12:30 PM"
          />
        )}
      />
    </InputField>
  );
};

export const SubmitButton: React.FC<SubmitButtonType> = ({ name }: SubmitButtonType) => {
  return (
    <button type="submit" className="btn-form">
      {name}
    </button>
  );
};
