import React, { useCallback } from 'react';
import { Button } from '@material-ui/core';
import useParticipantMenuContext from '../../../../hooks/useParticipantMenuContext/useParticipantMenuContext';
import useFilesContext from '../../../../hooks/useFilesContext/useFilesContext';
import useChatContext from '../../../../hooks/useChatContext/useChatContext';
import useVideoContext from '../../../../hooks/useVideoContext/useVideoContext';
import ParticipantsIcon from '../../../../icons/ParticipantsIcon';

export default function ToggleParticipantMenuButton(props: { className?: string }) {
  const { isParticipantMenuOpen, setIsParticipantMenuOpen } = useParticipantMenuContext();
  const { setIsFileWindowOpen } = useFilesContext();
  const { setIsChatWindowOpen } = useChatContext();
  const { setIsBackgroundSelectionOpen } = useVideoContext();

  const toggleParticipants = useCallback(() => {
    setIsParticipantMenuOpen(!isParticipantMenuOpen);
    setIsChatWindowOpen(false);
    setIsFileWindowOpen(false);
    setIsBackgroundSelectionOpen(false);
  }, [isParticipantMenuOpen]);

  return (
    <Button onClick={toggleParticipants} className={props.className}>
      <ParticipantsIcon />
    </Button>
  );
}
