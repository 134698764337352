import React from 'react';

export default function CloseIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5627_246558)">
        <path
          d="M11.5294 4.47266L4.47058 11.5315"
          stroke="white"
          strokeWidth="2.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.47058 4.47266L11.5294 11.5315"
          stroke="white"
          strokeWidth="2.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5627_246558">
          <rect width="14.1176" height="14.1176" fill="white" transform="translate(0.941162 0.941406)" />
        </clipPath>
      </defs>
    </svg>
  );
}
