/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { put, call, select } from 'redux-saga/effects';
import showError from '../showError';
// api
import { apiAssessmentQuestions } from '../../../api/question';
// actions
import questionsAction from '../../actions/assessment_questions';
// helpers
import mappingQuestions from '../../../api/mapping/response/assessmentQuestions';

export function* sagaAssessmentNextQuestion(action) {
  yield put(questionsAction.loader(true));
  yield questionsAction.create({});
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);
  const getQuestionNumber = (state) => state.assessment_questions.question_number;
  const questionNumber = yield select(getQuestionNumber);
  try {
    const response = yield call(
      apiAssessmentQuestions.next,
      action ? questionNumber : null,
      action.flowType,
      role ? role.toLowerCase() : 'customer',
    );
    yield questionsAction.status('step');
    yield questionsAction.create(mappingQuestions.buildQuestion(response.data.data.item, 'next'));
    yield put(questionsAction.loader(false));
  } catch (e) {
    console.log(e);
    yield put(questionsAction.loader(false));
    showError();
  }
}

export function* sagaAssessmentAnswer(action) {
  yield put(questionsAction.loader(true));
  // alert(JSON.stringify(action));

  const getQuestionNumber = (state) => state.assessment_questions.question_number;
  const questionNumber = yield select(getQuestionNumber);
  try {
    yield questionsAction.setScore(questionNumber, action.data.score);
    yield questionsAction.setQuestionNumber(questionNumber + 1);
    // yield call(apiAssessmentQuestions.answer, action.data, action.flowType, role.toLowerCase());

    yield put(questionsAction.loader(false));
    action.callback && action.callback();
  } catch (e) {
    console.log(e);
    yield put(questionsAction.loader(false));
    showError();
  }
}

export function* sagaGetAssessmentScore() {
  yield put(progressAction.loader(true));
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);

  try {
    const response = yield call(apiProgress.get, role.toLowerCase());

    yield put(progressAction.set(response.data.data.items));
    yield put(progressAction.loader(false));
  } catch (e) {
    yield put(progressAction.loader(false));
    showError();
  }
}

export function* sagaAssessmentAfterQuestion(action) {
  yield put(questionsAction.loader(true));
  yield questionsAction.create({});
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);
  const getQuestionNumber = (state) => state.assessment_questions.question_number;
  try {
    yield questionsAction.prevQuestionNumber();
    const questionNumber = yield select(getQuestionNumber);
    const response = yield call(
      apiAssessmentQuestions.next,
      action ? questionNumber : null,
      action.flowType,
      role ? role.toLowerCase() : 'customer',
    );
    yield questionsAction.status('step');
    yield questionsAction.create(mappingQuestions.buildQuestion(response.data.data.item, 'next'));
    yield put(questionsAction.loader(false));
  } catch (e) {
    console.log(e);
    yield put(questionsAction.loader(false));
    showError();
  }
}

export function* sagaAssessmentSaveScore(action) {
  yield put(questionsAction.loader(true));
  yield questionsAction.create({});
  const getScore = (state) => state.assessment_questions.score;

  try {
    const score = yield select(getScore);
    yield questionsAction.prevQuestionNumber();
    const response = yield call(apiAssessmentQuestions.saveScore, score);
    console.log(response);
    yield put(questionsAction.loader(false));
    yield questionsAction.clearScore();
  } catch (e) {
    console.log(e);
    yield put(questionsAction.loader(false));
    showError();
  }
}
