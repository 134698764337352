import React, { FC, useState } from 'react';
import { Modal } from 'antd';
import cn from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
// helpers
import { animHeight } from 'tools/animations';
// icons
import { ReactComponent as CloseIcon } from 'assets/image/account/close4.svg';
// style
import './style.scss';

interface IFrequentlyQuestions {
  visible: boolean;
  setVisible: (type: boolean) => void;
  list: Array<{
    title: string;
    text: string | React.ReactElement;
  }>;
}

const FrequentlyQuestions: FC<IFrequentlyQuestions> = ({ visible, setVisible, list }: IFrequentlyQuestions) => {
  return (
    <Modal
      className="frequently-modal"
      visible={visible}
      footer={null}
      closeIcon={<CloseIcon />}
      onCancel={() => setVisible(false)}
    >
      <div className="frequently">
        <div className="frequently__header">Frequently asked questions</div>
        <div className="frequently__body">
          {list.map((item, i) => (
            <FrequentQuestion key={i} item={item} />
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default FrequentlyQuestions;

interface IQuestion {
  title: string;
  text: string | React.ReactElement;
}

interface IFrequentQuestion {
  item: IQuestion;
}

const FrequentQuestion: FC<IFrequentQuestion> = ({ item }: IFrequentQuestion) => {
  const [open, setOpen] = useState(false);

  return (
    <div className={cn('question', { active: open })}>
      <div className="question__header" onClick={() => setOpen(!open)}>
        <div className="question__title">{item.title}</div>
        <div className="question__icon">
          <span />
          <span />
        </div>
      </div>
      <AnimatePresence>
        {open && (
          <motion.div variants={animHeight} initial="initial" animate="visibleQuestions" exit="exit">
            <div className="question__body">{item.text}</div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
