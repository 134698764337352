import React from 'react';
// components
import StripeContainer from 'components/containers/stripeContainer/StripeContainer';
import SimpleHeader from 'components/organisms/headers/simple/simple-header';

const StripePage: React.FC = () => {
  return (
    <>
      <SimpleHeader redirect_url="/login-customer" />
      <StripeContainer />
    </>
  );
};

export default StripePage;
