/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { AxiosResponse } from 'axios';
// config
import http from '../instance';

export const apiQuestions = {
  next: async (number: any, flowType: string, role: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.get(
      `${role ? role : 'customer'}/questionnaire/${flowType}/next-answer${number ? `?question_number=${number}` : ''}`,
    );
  },

  answer: async (data: any, flowType: string, role: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.post(`${role ? role : 'customer'}/questionnaire/${flowType}/answer`, data);
  },

  after: async (number: any, flowType: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.patch(`customer/questionnaire/${flowType}/prev-answer${number ? `?question_number=${number}` : ''}`);
  },

  goBack: async (flowType: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.patch(`customer/questionnaire/${flowType}/last-answer`);
  },

  cansel: async (): Promise<AxiosResponse> => {
    const api = await http();
    return api.patch(`customer/change-group-cancel`);
  },
};

export const apiAssessmentQuestions = {
  next: async (number: any, flowType: string, role: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.get(`customer/questionnaire/${flowType}/guest-next-answer${number ? `?question_number=${number}` : ''}`);
  },

  answer: async (data: any, flowType: string, role: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.post(`customer/questionnaire/${flowType}/answer`, data);
  },

  saveScore: async (score: number): Promise<AxiosResponse> => {
    const api = await http();
    return api.post(`customer/assessment-progress-score/save`, { score });
  },

  after: async (number: any, flowType: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.patch(
      `customer/questionnaire/${flowType}/guest-prev-answer${number ? `?question_number=${number}` : ''}`,
    );
  },

  goBack: async (flowType: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.patch(`customer/questionnaire/${flowType}/last-answer`);
  },

  cansel: async (): Promise<AxiosResponse> => {
    const api = await http();
    return api.patch(`customer/change-group-cancel`);
  },
};
