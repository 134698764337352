/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { put, call } from 'redux-saga/effects';

import { apiNotification } from '../../api/notificationClient';
import notificationClientAction from '../actions/notificationClient';
import showError from './showError';

export function* sagaGetNotification() {
  yield put(notificationClientAction.loader(true));

  try {
    const response = yield call(apiNotification.getNotification);
    yield put(notificationClientAction.loader(false));
    if (response.data?.data?.item) {
      yield notificationClientAction.create(response.data.data.item);
    }
  } catch (e) {
    console.log(e);
    yield put(notificationClientAction.loader(false));
    showError();
  }
}

export function* sagaReadNotification(action) {
  yield put(notificationClientAction.loader(true));

  try {
    yield call(apiNotification.readNotification, action.id, action.answerParams);
    yield put(notificationClientAction.loader(false));

    if (action.callback) {
      action.callback();
    }
  } catch (e) {
    yield put(notificationClientAction.loader(false));
    console.log(e);
    showError();
  }
}
