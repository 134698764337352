/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { AxiosResponse } from 'axios';
// config
import http from '../instance';

export const api = {
  getHelpfulTip: async (role: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.get(`${role}/helpful-tips`);
  },

  file: async (type: string, data: any): Promise<AxiosResponse> => {
    console.log('file');
    const api = await http();
    return api.post(`file/type/${type}`, data);
  },

  fileCreateEvent: async (data: any): Promise<AxiosResponse> => {
    console.log('fileCreateEvent');
    const api = await http();
    return api.post(`file/type/community_event_picture`, data);
  },

  getUrl: async (): Promise<AxiosResponse> => {
    const api = await http();
    return api.post(`forum/url`);
  },

  getSupportStates: async (role: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.get(`${role}/available-states`);
  },
};
