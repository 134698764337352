import React, { FC, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import cn from 'classnames';
import { motion } from 'framer-motion';
import { useHistory, useLocation } from 'react-router-dom';
// actions
import stripeAction from '../../../../redux/actions/stripe';
// types
import { stateType } from 'types/types';
import { IStripeItem } from 'types/app-types';
// icons
import checkIconTeal from 'assets/image/common/checkLightTeal.svg';
// helpers
import { animScale } from 'tools/animations';
// styles
import './style.scss';
// configs
import { calculatePrising } from '../../../../tools/helpFunctions';
import eventAnalytics from '../../../../redux/actions/analytics';
import userActions from '../../../../redux/actions/user';
import { mapping } from '../../../../api/mapping/response/user';

interface ISubscriptionList {
  items: any;
  loader: boolean;
  setTab: (state: 1 | 2) => void;
  setTitle?: any;
  isRestart?: boolean;
}

const SubscriptionList: FC<ISubscriptionList> = ({ items, loader, setTab, setTitle, isRestart }: ISubscriptionList) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const sub: any = useSelector((state: stateType) => state.stripe.item, shallowEqual);
  const { info }: any = useSelector((state: stateType) => state.account);
  const location = useLocation();
  const isChange = location.pathname.includes('change-billing');
  const periodName = info?.productPeriodNameFuture ? info.productPeriodNameFuture : info?.productPeriodName;

  useEffect(() => {
    const item = items.find((item: any) => item.productPeriodName === periodName);
    const selectedSub = _.isEmpty(sub) ? null : sub;
    dispatch(stripeAction.setItem(selectedSub || item || info || items[0]));
  }, [info, items]);

  const isInteger = (num: any) => {
    return (num ^ 0) === num;
  };

  const amountConvert = (price: any) => {
    const value: any = price / 100 / 4;
    if (isInteger(value)) {
      return value.toFixed(0);
    }
    return value.toFixed(0); // TODO here was toFixed(2)
  };

  const choose = (item: IStripeItem) => {
    if (isChange) {
      periodName !== item?.productPeriodName && dispatch(stripeAction.setItem(item));
    } else {
      dispatch(stripeAction.setItem(item));
    }
  };

  const subscription = () => {
    if (setTitle) {
      setTitle('Review your billing change');
    }
    if (isChange) {
      setTab(2);
      !isRestart && history.push('/change-billing/confirm');
    } else if (sub && !isChange) {
      //here
      // dispatch(stripeAction.choose(sub.stripePriceId, 'y2xDn4Qz'));
      userActions.state(mapping.stateFlowBuild(10));
    }
    dispatch(eventAnalytics.analytics('sign-up-five', { billingCycle: sub?.productPeriodName }));
  };

  const onDisabled = () => {
    return _.isEmpty(sub) || (isChange && periodName === sub?.productPeriodName);
  };

  return (
    <motion.div className="stripe-list" variants={animScale} initial="initial" animate="visible" exit="exit">
      <div className="stripe-list__title">
        {isChange ? 'Change how you pay' : 'Choose how you’d like to pay'}
        {isChange && (
          <div className="stripe-list__sub-title">
            Pay for 3 months or 6 months at a time, and you’ll get savings on your sessions
          </div>
        )}
      </div>
      <div className="stripe-list__list">
        {items.map((item: any) => {
          const {
            amount,
            stripePriceId,
            productName,
            saveAmount,
            weeklyAmount,
            saveAmountPercent,
            productPeriodName,
          } = item;

          const payment = calculatePrising({ items, sub: item });

          return (
            <div
              className={cn('stripe-list__item', {
                active:
                  sub?.productPeriodName === item?.productPeriodName &&
                  (!isChange || periodName !== item?.productPeriodName),
              })}
              key={stripePriceId}
              onClick={() => choose(item)}
            >
              {isChange && periodName === item?.productPeriodName && (
                <div className="stripe-list__current-item">Your Current Billing Schedule</div>
              )}
              <div className="stripe-list__paid">
                <div
                  className={cn('stripe-list__check', {
                    none: isChange && periodName === item?.productPeriodName,
                  })}
                >
                  <img src={checkIconTeal} alt="" />
                </div>
                <div className="stripe-list__main-info">
                  <div className="stripe-list__paid-title">{productName}</div>
                  <div className="stripe-list__summary">
                    {payment.totalPrice !== amount / 100 && <s>${payment.totalPrice}</s>} ${amount / 100}{' '}
                    {productPeriodName}
                    {productPeriodName.substr(-2) !== 'ly' ? 'ly ' : ' '}
                    {saveAmount && <span className="stripe-list__save">{`Save $${saveAmount / 100}`}</span>}
                  </div>
                  {saveAmountPercent && <div className="stripe-list__discount">{`-${saveAmountPercent}%`}</div>}
                </div>
              </div>
              <div className="stripe-list__amount">
                <span className="stripe-list__sum">${amountConvert(weeklyAmount)}</span>{' '}
                <span className="stripe-list__week">/week</span>
              </div>
            </div>
          );
        })}
        {!loader && (
          <button className="btn-form stripe-list__continue" disabled={onDisabled()} onClick={subscription}>
            Continue to checkout
          </button>
        )}
      </div>
    </motion.div>
  );
};

export default SubscriptionList;
