import React from 'react';
// styles
import './style.scss';

interface PreviewContainerType {
  children: React.ReactNode;
  headerComponent: React.ReactNode;
  text_before_form?: React.ReactNode;
  box_down?: boolean;
}

const PreviewAfterContainer: React.FC<PreviewContainerType> = ({
  children,
  text_before_form,
  headerComponent,
  box_down,
}: PreviewContainerType) => {
  return (
    <div className="preview-container">
      <div className="preview-container__header">{headerComponent}</div>
      <div className={`preview-container__after-box ${box_down ? 'preview-container__box_down' : ''}`}>{children}</div>
      <div className="preview-container__text-before-box">{text_before_form}</div>
    </div>
  );
};

export default PreviewAfterContainer;
