/*
 *
 * Upcoming Events
 *
 */
const initialState = {
  events: [],
  posts: [],
  loaderEvents: false,
  loaderPosts: false,
  loaderReg: false,
  params: { page: 1, per_page: 8 },
  allParams: {},
  count: 1,
  searchType: 'all',
  lengthsFilter: null,
  typesFilter: null,
  ordersFilter: null,
  topicsFilter: null,
};

export type upcomingEventsInitStateType = typeof initialState;

interface upcomingEventsType {
  type: string;
  payload: {
    dataFilters: {
      data: any;
    };
    events: Array<any>;
    posts: Array<any>;
    loaderEvents: boolean;
    loaderPosts: boolean;
    loaderReg: boolean;
    params: {
      page: number;
      per_page: number;
    };
    typesFilter: any;
    lengthsFilter: any;
    ordersFilter: any;
    topicsFilter: any;
    allParams: Record<string, any>;
    totalCount: number;
    searchType: string;
  };
}

const upcoming_events = (state = initialState, action: upcomingEventsType): upcomingEventsInitStateType => {
  switch (action.type) {
    case 'UPCOMING_EVENTS':
      return Object.assign({}, state, {
        events: action.payload.events,
      });

    case 'UPCOMING_POSTS':
      return Object.assign({}, state, {
        posts: action.payload.posts,
        count: action.payload.totalCount,
      });

    case 'UPCOMING_EVENTS_LOADER':
      return Object.assign({}, state, {
        loaderEvents: action.payload.loaderEvents,
      });

    case 'UPCOMING_POSTS_LOADER':
      return Object.assign({}, state, {
        loaderPosts: action.payload.loaderPosts,
      });

    case 'UPCOMING_REG_LOADER':
      return Object.assign({}, state, {
        loaderReg: action.payload.loaderReg,
      });

    case 'UPCOMING_PAST_PARAMS':
      return Object.assign({}, state, {
        params: action.payload.params,
      });

    case 'EVENTS_SEARCH_TYPE':
      return Object.assign({}, state, {
        searchType: action.payload.searchType,
      });

    case 'EVENTS_ALL_PARAMS':
      return Object.assign({}, state, {
        allParams: action.payload.params,
      });
    case 'CLEAR_ALL_PARAMS':
      return Object.assign({}, state, {
        allParams: {},
      });

    case 'SET_DEFAULT_ALL':
      return Object.assign({}, state, {
        events: [],
        posts: [],
        loaderEvents: false,
        loaderPosts: false,
        loaderReg: false,
        params: { page: 1, per_page: 8 },
        allParams: {},
        count: 1,
        searchType: 'all',
        lengthsFilter: null,
        typesFilter: null,
        ordersFilter: null,
        topicsFilter: null,
      });

    case 'SET_TYPES_FILTERS':
      return Object.assign({}, state, {
        typesFilter: action.payload.dataFilters.data,
      });

    case 'SET_TOPICS_FILTERS':
      return Object.assign({}, state, {
        topicsFilter: action.payload.dataFilters.data,
      });

    case 'SET_ORDERS_FILTERS':
      return Object.assign({}, state, {
        ordersFilter: action.payload.dataFilters.data,
      });

    case 'SET_LENGTHS_FILTERS':
      return Object.assign({}, state, {
        lengthsFilter: action.payload.dataFilters.data,
      });

    default:
      return state;
  }
};

export default upcoming_events;
