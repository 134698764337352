import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// pages auth
import LoginPage from '../../pages/auth/login-page';
import RegistrationPage from '../../pages/auth/registration-page';
import GoToSeries from '../../pages/auth/go-to-series';
import ForgotPassword from '../../pages/auth/forgot-password';
import NewPassword from '../../pages/auth/new-password';
import NewPasswordTherapist from '../../pages/auth/new-password-therapist';
import FirstConsultationsPage from '../../pages/customer/consultation/firstConsultations';
import RedirectLogin from '../../pages/auth/redirect-login';
// pages for admin
import HomePage from '../../pages/home';
import AnalyticsPage from '../../pages/analytics';
import GroupsOfferedPage from '../../pages/admin/groupsOfferedPage';
import ClientsPage from '../../pages/admin/clientsPage';
import ClientPage from '../../pages/admin/clientsPage/client';
import GroupsPage from '../../pages/admin/groupsPage';
import GroupPage from '../../pages/admin/groupsPage/group';
import TherapistsPage from '../../pages/admin/therapistsPage';
import TherapistPage from '../../pages/admin/therapistsPage/therapist';
import EventDetailsPage from '../../pages/admin/eventsPage/events';
import AdminEventsPage from '../../pages/admin/eventsPage';

import TherapistsClientsPage from '../../pages/therapist/clients';
import TherapistsClientPage from '../../pages/therapist/clients/client';
import TherapistsGroupsPage from '../../pages/therapist/groups';
import EventsPage from '../../pages/customer/events';
import TherapistsGroupPage from '../../pages/therapist/groups/group';

import ForumPage from '../../pages/customer/forum';
import ProgressPage from '../../pages/customer/progress';
import ProgressTermPage from '../../pages/customer/progress/term';
import ProgressLogsPage from '../../pages/customer/progress/logs';
import ProgressQuestionsPage from '../../pages/customer/progress/questions';
import ConsultationPage from '../../pages/customer/consultation';
import StripePage from '../../pages/customer/stripe';
import AssessmentProgressQuestionsPage from '../../pages/customer/assessment';

import AccountPage from '../../pages/account';
import ChangeGroupPage from '../../pages/account/change';
import CancelGroupPage from '../../pages/account/cancel';
import ChoiceGroupsPage from '../../pages/account/choice';
import ConfirmChoice from '../../pages/account/confirmChoice';

import RestartSubscriptionsPage from '../../pages/account/restart';

import VideoPage from '../../pages/video/video';
import WebinarPage from '../../pages/video/webinar';
import LoginByKey from '../../pages/auth/login-by-key';

// Components
import LayoutAuth from './LayoutAuth';
import LayoutPage from './LayoutPage';
import LayoutConsultationPage from './LayoutConsultationPage';
import LayoutChoiceGroup from './LayoutChoiceGroup';
import LayoutProgressQuestionsPage from './LayoutProgressQuestionsPage';
import LayoutStripePage from './LayoutStripePage';
import Component404 from './404';
import ChangeBillingPage from 'pages/customer/billing';
import LayoutFirstConsultationPage from './LayoutFirstConsultationPage';
import ContactSupport from '../../pages/customer/contactSupport';
import EmailsLinkHandler from '../../pages/emailsHandler';
import LayoutAlternativeAuth from './LayoutAlternativeAuth';
import LayoutRestartSubscriptions from './LayoutRestartSubscriptions';

// Errors
import ErrorBoundary from './ErrorBoundary';

// actions
import mainMenuAction from '../../redux/actions/main_menu';
import { authActions } from '../../redux/actions/auth';

// Styles
import 'antd/dist/antd.min.css';
import '../../assets/main.scss';
import SuperbillPage from 'pages/customer/superbill';
import SuperbillNamePage from 'pages/customer/superbill/superbillConfirmName';
import SuperbillDetailsPage from 'pages/customer/superbill/superbillDetails';
import AdminClientSuperbillPage from 'pages/admin/clientsPage/superbill';
import AdminSuperbillDetailsPage from 'pages/admin/clientsPage/superbill/superbillDetails';
import LayoutAlternativeAuth2 from './LayoutAlternativeAuth2';
import { EventDetailsPreview } from 'components/molecules/events/detailsButtons';
import NotificationPage from 'pages/notification';

const App: React.FC = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    console.log('APP is loading ...');
    authActions.authChecker(dispatch);
  }, []);

  const auth = useSelector((state: any) => state.app.auth, shallowEqual);
  const role = useSelector((state: any) => state.user.profile.role, shallowEqual);

  useEffect(() => {
    if (auth === 'auth') {
      role && dispatch(mainMenuAction.init(role));
    }
  }, [auth, role]);

  return (
    <>
      <Helmet>{auth === 'auth' ? <title>Grouport Therapy</title> : <title>Sign-in</title>}</Helmet>
      <ErrorBoundary>
        <Router>
          <Switch>
            <LayoutAuth exact path="/login-customer" component={LoginPage} role={'customer'} />
            <LayoutAuth exact path="/login-admin" component={LoginPage} role={'admin'} />
            <LayoutAuth exact path="/login-therapist" component={LoginPage} role={'therapist'} />
            <LayoutAlternativeAuth2 exact path="/login-redirect/:token?" component={RedirectLogin} role={'customer'} />
            <LayoutAlternativeAuth exact path="/auth_by_key" component={LoginByKey} role={'customer'} />

            <LayoutAuth exact path="/registration" component={RegistrationPage} />
            <LayoutAuth exact path="/go-to-series" component={GoToSeries} />
            <LayoutAuth exact path="/forgot-password" component={ForgotPassword} />
            <LayoutAuth exact path="/new-password/:token?" component={NewPassword} />
            <LayoutAuth exact path="/therapist-password/:token?" component={NewPasswordTherapist} />

            <LayoutAuth exact path="/anxiety-assessment" component={AssessmentProgressQuestionsPage} />

            <LayoutPage exact path="/groups_offered" component={GroupsOfferedPage} for={['ADMIN']} />
            <LayoutPage exact path="/all_clients" component={ClientsPage} for={['ADMIN']} />
            <LayoutPage exact path="/all_clients/:id" component={ClientPage} for={['ADMIN']} />
            <LayoutPage exact path="/client-superbills/:id" component={AdminClientSuperbillPage} for={['ADMIN']} />
            <LayoutPage exact path="/client-superbill-details" component={AdminSuperbillDetailsPage} for={['ADMIN']} />
            <LayoutPage exact path="/all_groups" component={GroupsPage} for={['ADMIN']} />
            <LayoutPage exact path="/all_groups/:id" component={GroupPage} for={['ADMIN']} />
            <LayoutPage exact path="/all_therapists" component={TherapistsPage} for={['ADMIN']} />
            <LayoutPage exact path="/all_therapists/:id" component={TherapistPage} for={['ADMIN']} />
            <LayoutPage exact path="/community_events" component={AdminEventsPage} for={['ADMIN']} />
            <LayoutPage exact path="/event_details/:id" component={EventDetailsPage} for={['ADMIN']} />
            <LayoutPage exact path="/event_details/:id/preview" component={EventDetailsPreview} for={['ADMIN']} />

            <LayoutPage exact path="/clients" component={TherapistsClientsPage} for={['THERAPIST']} />
            <LayoutPage exact path="/clients/:id" component={TherapistsClientPage} for={['THERAPIST']} />
            <LayoutPage exact path="/groups" component={TherapistsGroupsPage} for={['THERAPIST']} />
            <LayoutPage exact path="/groups/:id" component={TherapistsGroupPage} for={['THERAPIST']} />

            <LayoutPage exact path="/forum" component={ForumPage} for={['ADMIN', 'CUSTOMER', 'THERAPIST']} />

            <LayoutPage
              exact
              path="/notifications"
              component={NotificationPage}
              for={['ADMIN', 'CUSTOMER', 'THERAPIST']}
            />

            <LayoutPage exact path="/events" component={EventsPage} for={['CUSTOMER']} />
            <LayoutPage exact path="/progress" component={ProgressPage} for={['CUSTOMER']} />
            <LayoutPage exact path="/superbill" component={SuperbillPage} for={['CUSTOMER']} />
            <LayoutPage exact path="/superbill-name" component={SuperbillNamePage} for={['CUSTOMER']} />
            <LayoutPage exact path="/superbill-details" component={SuperbillDetailsPage} for={['CUSTOMER']} />
            <LayoutPage exact path="/customer-profile/:type?/:action?" component={ContactSupport} for={['CUSTOMER']} />
            <LayoutPage exact path="/progress/term/:add?" component={ProgressTermPage} for={['CUSTOMER']} />
            <LayoutPage
              exact
              path="/progress/logs/:type(add|edit)?/:id?"
              component={ProgressLogsPage}
              for={['CUSTOMER']}
            />
            <LayoutProgressQuestionsPage
              exact
              path="/progress-questions"
              component={ProgressQuestionsPage}
              for={['CUSTOMER']}
            />
            <LayoutConsultationPage exact path="/consultation" component={ConsultationPage} for={['CUSTOMER']} />

            <LayoutPage exact path="/" component={HomePage} for={['ADMIN', 'CUSTOMER', 'THERAPIST']} />
            <LayoutPage exact path="/analytics" component={AnalyticsPage} for={['ADMIN']} />
            <LayoutPage exact path="/profile" component={AccountPage} for={['ADMIN', 'CUSTOMER', 'THERAPIST']} />
            <LayoutPage exact path="/profile/change-group" component={ChangeGroupPage} for={['CUSTOMER']} />
            <LayoutPage exact path="/profile/cancel-group" component={CancelGroupPage} for={['CUSTOMER']} />
            <LayoutRestartSubscriptions
              exact
              path="/profile/restart-subscriptions"
              component={RestartSubscriptionsPage}
              for={['CUSTOMER']}
            />
            <LayoutChoiceGroup exact path="/profile/choice-group" component={ChoiceGroupsPage} for={['CUSTOMER']} />
            <LayoutStripePage exact path="/stripe" component={StripePage} for={['CUSTOMER']} />
            <LayoutPage
              exact
              path="/change-billing/:action(confirm)?"
              component={ChangeBillingPage}
              for={['CUSTOMER']}
            />
            <LayoutPage exact path="/profile/choice-group/confirm" component={ConfirmChoice} for={['CUSTOMER']} />
            <LayoutFirstConsultationPage
              exact
              path="/approve-consultations"
              component={FirstConsultationsPage}
              for={['CUSTOMER']}
            />

            <LayoutPage exact path="/email/:type/:action/:id?" component={EmailsLinkHandler} for={['CUSTOMER']} />

            <LayoutPage exact path="/video" component={VideoPage} for={['ADMIN', 'CUSTOMER', 'THERAPIST']} />
            <LayoutPage
              exact
              path="/room/:URLRoomName"
              component={VideoPage}
              for={['ADMIN', 'CUSTOMER', 'THERAPIST']}
            />
            <LayoutPage
              exact
              path="/webinar/:URLRoomName"
              component={WebinarPage}
              for={['ADMIN', 'CUSTOMER', 'THERAPIST']}
            />

            <Route path="*" exact={true} component={Component404} />
          </Switch>
        </Router>
      </ErrorBoundary>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default App;
