/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { AxiosResponse } from 'axios';
// config
import http from '../instance';

export const apiChoiceGroup = {
  get: async (params: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.get(`customer/search-group${params ? `?${params}` : ''}`);
  },

  getRecommended: async (query?: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.get(`customer/search-recommended-group${query ? `?flow=${query}` : ''}`);
  },

  join: async (id: any): Promise<AxiosResponse> => {
    const api = await http();
    return api.patch(`customer/group/${id}/join`);
  },
};
