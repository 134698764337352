import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// styles
import './style.scss';
// actions
import { authActions } from '../../../redux/actions/auth';

interface AuthLogoType {
  redirect_url: string;
}

export const AuthLogo: React.FC<AuthLogoType> = ({ redirect_url }: AuthLogoType) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const onLogout = useCallback(() => {
    const token = localStorage.getItem('refresh_token');

    if (token) {
      const data = {
        refreshToken: token,
      };
      dispatch(
        authActions.logout(data, () => {
          localStorage.removeItem('token');
          localStorage.removeItem('refresh_token');
          history.push(redirect_url);
        }),
      );
    } else {
      history.push(redirect_url);
    }
  }, []);

  return <div className="simple-logo" onClick={onLogout} />;
};
