import React, { FC } from 'react';
// icons
import Img1 from 'assets/image/stripe/1.png';
import Img2 from 'assets/image/stripe/2.png';
import Img3 from 'assets/image/stripe/3.png';

const SslFooter: FC = () => {
  return (
    <div className="subscription-container__footer">
      <span className="subscription-container__text">This is a secure SSL encrypted payment</span>
      <div className="subscription-container__images">
        <div>
          <img src={Img1} alt="Img1" />
        </div>
        <div>
          <img src={Img2} alt="Img2" />
        </div>
        <div>
          <img src={Img3} alt="Img3" />
        </div>
      </div>
    </div>
  );
};

export default SslFooter;
