/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { call, put, select } from 'redux-saga/effects';
import queryString from 'query-string';
import _ from 'lodash';
// api
import { api } from '../../../api/groups';
// actions
import groupsAction from '../../actions/groups';
// helpers
import mappingConsultation from '../../../api/mapping/response/consultation';
import showError from '../showError';
import { getSortValue } from 'tools/helpFunctions';
import eventsAction from '../../actions/admin_events';

export function* sagaGetGroups() {
  yield put(groupsAction.loader(true));
  const getParams = (state) => state.groups.params;
  const getRole = (state) => state.user.profile.role;
  const getOrder = (state) => state.groups.order;
  const params = yield select(getParams);
  const role = yield select(getRole);
  const order = yield select(getOrder);

  let stringOrder = getSortValue(order);

  try {
    const response = yield call(
      api.get,
      role.toLowerCase(),
      `${queryString.stringify(params, { arrayFormat: 'comma' })}${stringOrder ? stringOrder : ''}`,
    );

    yield put(groupsAction.loader(false));

    yield groupsAction.create(response.data.data, role);
  } catch (e) {
    const error = e.response.data;
    if (error.error_type === 'pagination_error' && /The currentPage must be inferior to/.test(error.message)) {
      const currentPage = Number(error.message.match(/\d+/g).pop());

      yield groupsAction.params({ ...params, page: currentPage });
      yield put(groupsAction.get());
    } else {
      yield put(groupsAction.loader(false));

      showError();
    }
  }
}

export function* sagaGetGroup(action) {
  yield put(groupsAction.loader(true));
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);

  try {
    const response = yield call(api.getByID, role.toLowerCase(), action.id);

    yield put(groupsAction.loader(false));

    yield groupsAction.self(response.data.data, role);
  } catch (e) {
    yield put(groupsAction.loader(false));
    showError();
  }
}

export function* sagaAddClientForGroup(action) {
  yield put(groupsAction.loaderModal(true));
  const getRole = (state) => state.user.profile.role;
  const getResult = (state) => state.groups.searchResult;
  const role = yield select(getRole);
  const result = yield select(getResult);

  try {
    const response = yield call(api.addClient, role.toLowerCase(), action.data.groupId, action.data.customerId);
    yield put(eventsAction.setEvent(response.data.data.item));
    yield put(groupsAction.loaderModal(false));

    yield groupsAction.self(response.data.data, role);
    yield groupsAction.searchResult(
      _.filter(result, (o) => {
        return o.id !== action.data.customerId;
      }),
      false,
    );
  } catch (e) {
    yield put(groupsAction.loaderModal(false));
    showError();
  }
}

export function* sagaRemoveClientForGroup(action) {
  yield put(groupsAction.loaderModal(true));
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);

  try {
    const response = yield call(api.removeClient, role.toLowerCase(), action.data.groupId, action.data.customerId);

    yield put(groupsAction.loaderModal(false));

    yield groupsAction.self(response.data.data, role);
  } catch (e) {
    yield put(groupsAction.loaderModal(false));
    showError();
  }
}

export function* sagaEditGroup(action) {
  yield put(groupsAction.loaderModal(true));
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);

  try {
    const response = yield call(api.edit, role.toLowerCase(), action.id, action.data);

    yield put(groupsAction.loaderModal(false));

    yield groupsAction.self(response.data.data, role);
    action.callback && action.callback();
  } catch (e) {
    yield put(groupsAction.loaderModal(false));
    showError();
  }
}

export function* sagaRescheduleGroup(action) {
  yield put(groupsAction.loaderModal(true));
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);

  try {
    yield call(api.reschedule, role.toLowerCase(), action.id, action.data);

    yield put(groupsAction.loaderModal(false));

    if (action.callback) {
      action.callback();
    }
  } catch (e) {
    yield put(groupsAction.loaderModal(false));
  }
}

export function* sagaArchiveGroup(action) {
  yield put(groupsAction.loaderModal(true));
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);

  try {
    yield call(api.archive, role.toLowerCase(), action.id);

    yield put(groupsAction.loaderModal(false));

    if (action.callback) {
      action.callback();
    }
  } catch (e) {
    yield put(groupsAction.loaderModal(false));
    showError();
  }
}

export function* sagaCanselGroup(action) {
  yield put(groupsAction.loaderModal(true));
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);

  try {
    // response
    yield call(api.cansel, role.toLowerCase(), action.id);

    yield put(groupsAction.loaderModal(false));
    if (action.callback) {
      action.callback();
    }
  } catch (e) {
    yield put(groupsAction.loaderModal(false));
    showError();
  }
}

export function* sagaRestartGroup(action) {
  yield put(groupsAction.loaderModal(true));
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);

  try {
    // response
    yield call(api.restart, role.toLowerCase(), action.id);

    yield put(groupsAction.loaderModal(false));
    if (action.callback) {
      action.callback();
    }
  } catch (e) {
    yield put(groupsAction.loaderModal(false));
    showError();
  }
}

export function* getTopicsGroup() {
  yield put(groupsAction.loaderModal(true));

  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);

  try {
    const { data } = yield call(api.getTopics, role);

    yield put(groupsAction.loaderModal(false));

    yield put(groupsAction.topics(mappingConsultation.buildOptions(data.data.items)));
  } catch (e) {
    showError();
  }
}

export function* getTherapistsGroup() {
  try {
    const { data } = yield call(api.getTherapists);
    yield put(groupsAction.therapists(mappingConsultation.buildOptionsTherapists(data.data.items)));
  } catch (e) {
    showError();
  }
}

export function* sagaGroupCreated(action) {
  yield put(groupsAction.loaderModal(true));
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);

  try {
    const response = yield call(api.created, role.toLowerCase(), action.data);

    yield put(groupsAction.loaderModal(false));

    if (action.callback) {
      action.callback(response.data.data.item.id);
    }

    yield sagaGetGroups();
  } catch (e) {
    yield put(groupsAction.loaderModal(false));
    showError();
  }
}

export function* sagaSearchClients(action) {
  yield put(groupsAction.loaderModal(true));
  const getParams = (state) => state.clients.params;
  const getRole = (state) => state.user.profile.role;
  yield select(getParams); // params
  const role = yield select(getRole);

  try {
    const response = yield call(api.search, role.toLowerCase(), action.value);
    yield put(groupsAction.loaderModal(false));
    yield groupsAction.searchResult(response.data.data);
  } catch (e) {
    yield put(groupsAction.loaderModal(false));
    showError();
  }
}
