/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

export const mapping = {
  editTherapistBuild(data) {
    return {
      titleId: data.titleId,
      biography: data.biography ? data.biography : '',
      practiceYears: data.practiceYears ? Number(data.practiceYears) : 0,
      stateId: data.stateId ? data.stateId : 0,
      licenseIds: data.licenseIds ? data.licenseIds : '',
      specialtyIds: data.specialtyIds ? data.specialtyIds : [],
      groupTopicIds: data.groupTopicIds ? data.groupTopicIds : [],
      joinedDate: data.joinedDate,
      avatarUuid: data.avatarUuid ? data.avatarUuid : '',
      npi: data.npiNumber,
    };
  },

  editTherapistNames(data) {
    return {
      firstname: data.firstname,
      lastname: data.lastname,
      phone: data.phone,
    };
  },
};
