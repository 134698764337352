import React from 'react';
import { VideoProvider } from './VideoProvider';
import ErrorDialog from './ErrorDialog/ErrorDialog';
import { ChatProvider } from './ChatProvider';
import { useAppState } from '../../state';
import useConnectionOptions from '../../utils/useConnectionOptions/useConnectionOptions';
import App from './App';
import { MuiThemeProvider } from '@material-ui/core';
import theme from '../../theme';
import { FilesProvider } from './FilesProvider/FilesProvider';
import { ParticipantMenuProvider } from './ParticipantMenu/ParticipantMenuProvider';
import { RoomInfoProvider } from './RoomInfoProvider/RoomInfoProvider';
import { UserDataProvider } from './UserDataProvider/UserDataProvider';

const VideoApp = () => {
  const { error, setError } = useAppState();
  const connectionOptions = useConnectionOptions();

  return (
    <MuiThemeProvider theme={theme}>
      <VideoProvider options={connectionOptions} onError={setError}>
        <ErrorDialog dismissError={() => setError(null)} error={error} />
        <ChatProvider>
          <FilesProvider>
            <ParticipantMenuProvider>
              <RoomInfoProvider>
                <UserDataProvider>
                  <App />
                </UserDataProvider>
              </RoomInfoProvider>
            </ParticipantMenuProvider>
          </FilesProvider>
        </ChatProvider>
      </VideoProvider>
    </MuiThemeProvider>
  );
};

export default VideoApp;
