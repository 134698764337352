import React, { FC, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Modal, Spin } from 'antd';
import cn from 'classnames';
// componets
import Tracker from '../../tracker';
import QuestionCard from 'components/molecules/groupQuestions/QuestionCard';
import BooleanChoice from 'components/molecules/groupQuestions/Answers/BooleanChoice';
// actions
import userActions from '../../../../redux/actions/user';
import questionsActions from '../../../../redux/actions/questions';
import mainMenuAction from '../../../../redux/actions/main_menu';
// helpers
import mappingQuestions from '../../../../api/mapping/response/questions';
// types
import { stateType } from 'types/types';
// icons
import { ReactComponent as CloseIcon, ReactComponent as IconClose } from 'assets/image/account/close4.svg';
import icon from 'assets/image/group/around.png';
import ImgAfter from 'assets/image/questions/after.png';
// styles
import './style.scss';
import eventAnalytics from '../../../../redux/actions/analytics';

const flowType = 'progress';

const ProgressQuestions: React.FC<any> = () => {
  // const history = useHistory();
  const loader = useSelector((state: stateType) => state.questions.loader, shallowEqual);
  const question: any = useSelector((state: stateType) => state.questions.item, shallowEqual);
  const status: string = useSelector((state: stateType) => state.questions.status, shallowEqual);
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();

  console.log(question);

  const onClick = () => {
    dispatch(questionsActions.next(flowType));
    dispatch(eventAnalytics.analytics('assessment-start'));
  };

  useEffect(() => {
    if (status && status !== 'before' && !visible) {
      setVisible(true);
    }
  }, [status]);

  // const onClose = () => {
  //   userActions.state('group_joined');
  //   questionsActions.status('');
  //   history.push('/progress');
  // };

  const questionController = () => {
    switch (status) {
      case 'before2':
        return <Before />;
      case 'step':
        return <Step question={question} />;
      case 'after':
        return <After />;

      default:
        return null;
    }
  };

  return (
    <>
      {!visible && (
        <div className="check-in-start">
          <div className="change-group-container">
            {/*<div className="change-group-container__btn-close"><IconClose onClick={onClose} /></div>*/}
            <img className="change-group-container__before-image mb-sm" src={icon} alt="logo" />
            <div className="change-group-container__main-title mb-sm">How have you been feeling?</div>
            <div className="change-group-container__desc-text">
              To help make the most of your time in group and to give your therapist a better sense of what you’re going
              through, please complete the following two sets of questions.
              <div className="change-group-container__sub-text">
                The first set of questions will ask about your level of worry. Each set is less than 10 questions, and
                will take less than 1 minute to complete.
              </div>
            </div>
            <button onClick={onClick} className="btn-form">
              Begin
            </button>
          </div>
        </div>
      )}
      <Modal
        visible={visible}
        closable={false}
        centered
        maskTransitionName="none"
        footer={null}
        maskClosable={false}
        className={cn({
          'before-after-modal': status.includes('before'),
          'questions-modal height-lg': status === 'step',
          'symptoms-modal': status === 'after',
        })}
      >
        <Spin spinning={loader}>{!loader && questionController()}</Spin>
      </Modal>
    </>
  );
};

export default ProgressQuestions;

const Before = () => {
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(questionsActions.next(flowType));
  };

  return (
    <div className="progress-questions-container">
      <div className="questions">
        <div className="questions__finish-body mb-sm">
          <img className="questions__finish-ico" src={ImgAfter} alt="Finish icon" />
          <div className="questions__congrad">Part 1 of 2 complete</div>
          <div className="questions__finish-text">
            The next set of questions will ask about feelings of sadness and low mood that are common for people to
            experience.
          </div>
        </div>
      </div>
      <button className="btn-form" onClick={onClick}>
        Begin
      </button>
    </div>
  );
};

const After: FC = () => {
  const history = useHistory();
  const [visible, setVisible] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      //questionsActions.status('');
    };
  }, []);

  const close = () => {
    userActions.state('group_joined');
    questionsActions.status('');
    history.push('/progress');
    dispatch(mainMenuAction.propgressPopover(false));
  };

  return (
    <div className="tracker-container">
      <div className="tracker-container__header">
        <div className="tracker-container__title">Your Progress</div>
        <IconClose onClick={close} />
      </div>
      <Tracker moreText="Learn more" onMore={() => setVisible(true)} />
      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        centered
        footer={null}
        closeIcon={<CloseIcon />}
        className="details-tracker"
      >
        <div className="details-tracker__body">
          <div className="details-tracker__title">
            {/*TODO add a real text from server*/}
            Anxiety & <br /> Depression Assessment
          </div>
          <div className="details-tracker__descr">
            {/*TODO add a real text from server*/}
            Your score is based on the PHQ-ADS, an assessment developed by doctors which combines the PHQ-9 and GAD-7
            scales that measure symptoms of anxiety and depression.
          </div>
          <button className="btn-form details-tracker__btn" onClick={() => setVisible(false)}>
            OK
          </button>
        </div>
      </Modal>
    </div>
  );
};

enum QuestionType {
  MultiScore = 'single_score',
}

const Step = ({ question }: any) => {
  const [checkedValues, setCheckedValues] = useState<any>([]);
  console.log(checkedValues);
  const dispatch = useDispatch();

  useEffect(() => {
    initDefaultValue();
  }, []);

  const initDefaultValue = () => {
    if (question.defaultValue) {
      switch (question.questionType) {
        case QuestionType.MultiScore:
          return setCheckedValues([question.defaultValue.answerId]);

        default:
          return;
      }
    }
  };

  const answerController = () => {
    switch (question.questionType) {
      case 'single_score':
        return <BooleanChoice question={question} onChangeRadio={onChangeRadio} value={checkedValues[0]} />;

      default:
        return null;
    }
  };

  const onChangeRadio = (value: any) => {
    setCheckedValues([value]);
  };

  const onNext = () => {
    const data = {
      questionId: question.id,
      answersIds: checkedValues,
      answerText: '',
    };

    const analyticData = {
      assessmentQuestionNumber: question.id,
      assessmentAnswer: question.answers[checkedValues[0] - 1].answer,
    };

    dispatch(
      questionsActions.answer(flowType, mappingQuestions.buildAnswer(data, question.answerType), () => {
        if (question.additions.serialNumber === 7 && question.additions.part === 1) {
          questionsActions.status('before2');
          return;
        }
        if (question.additions.serialNumber === 9 && question.additions.part === 2) {
          dispatch(eventAnalytics.analytics('assessment-finish'));
          questionsActions.status('after');
          return;
        }
        return dispatch(questionsActions.next(flowType));
      }),
    );
    dispatch(eventAnalytics.analytics('assessment-answer', analyticData));
  };

  return (
    <QuestionCard
      onBack={() => dispatch(questionsActions.after(flowType))}
      question={question}
      renderController={answerController}
      onNext={onNext}
      nextDisabled={checkedValues.length === 0}
      apply={false}
      showBack={question.number !== 1}
      totalStepCount={question.maxQuestionNumber}
      subQuestion={
        <>
          Over the last <b>2 weeks</b>, how often have you noticed the following?
        </>
      }
    />
  );
};
