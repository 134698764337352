/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { all, call, put, select } from 'redux-saga/effects';
import showError from '../showError';
import queryString from 'query-string';
// api
import { api } from '../../../api/therapists';
// actions
import therapistsAction from '../../actions/therapists';
import therapistAction from '../../actions/therapist';
import accountAction from '../../actions/account';
import clientsAction from '../../actions/clients';
// sagas
import { getLicenses, getSpecialties, getState, getTitles, getTopics } from '../options';
// helpers
import mappingTherapistsList from '../../../api/mapping/response/therapists/list';
import { accountMapping } from '../../../api/mapping/response/account';

export function* sagaGetTherapists() {
  yield put(therapistsAction.loader(true));
  const getParams = (state) => state.therapists.params;
  const getRole = (state) => state.user.profile.role;
  const params = yield select(getParams);
  const role = yield select(getRole);

  try {
    const response = yield call(api.get, role.toLowerCase(), queryString.stringify(params, { arrayFormat: 'comma' }));

    yield put(therapistsAction.loader(false));

    yield therapistsAction.create(response.data.data, role);
  } catch (e) {
    const error = e.response.data;
    if (error.error_type === 'pagination_error' && /The currentPage must be inferior to/.test(error.message)) {
      const currentPage = Number(error.message.match(/\d+/g).pop());

      yield therapistsAction.params({ ...params, page: currentPage });
      yield put(therapistsAction.get());
    } else {
      yield put(therapistsAction.loader(false));

      showError();
    }
  }
}

export function* sagaGetListTherapists(action) {
  yield put(clientsAction.loaderModal(true));
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);

  try {
    const response = yield call(api.list, role.toLowerCase(), action.id);

    yield put(clientsAction.loaderModal(false));

    yield therapistsAction.create_list(mappingTherapistsList.buildOptions(response.data.data.items));
  } catch (e) {
    yield put(clientsAction.loaderModal(false));
    console.log(e);
    showError();
  }
}

export function* sagaGetTherapist(action) {
  yield put(therapistAction.loader(true));
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);

  try {
    const response = yield call(api.getByID, action.id, role.toLowerCase());

    yield put(therapistAction.loader(false));

    yield therapistAction.create(accountMapping.build(response.data.data.item, role), role);
    yield put(accountAction.set(response.data.data.item));
  } catch (e) {
    yield put(therapistAction.loader(false));
    console.log(e);
    showError();
  }
}

export function* sagaEnabledSmsTherapist(action) {
  yield put(therapistAction.loaderReminders(true));
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);

  try {
    const { data } = yield call(api.enableSms, action.id, action.enable);

    yield put(therapistAction.loaderReminders(false));

    yield therapistAction.create(accountMapping.build(data.data.item, role), role);
  } catch (e) {
    yield put(therapistAction.loaderReminders(false));
    showError();
  }
}

export function* sagaEnabledEmailTherapist(action) {
  yield put(therapistAction.loaderReminders(true));
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);

  try {
    const { data } = yield call(api.enableEmails, action.id, action.enable);

    yield put(therapistAction.loaderReminders(false));

    yield therapistAction.create(accountMapping.build(data.data.item, role), role);
  } catch (e) {
    yield put(therapistAction.loaderReminders(false));
    showError();
  }
}

export function* sagaAddTherapist(action) {
  yield put(therapistAction.loader(true));
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);

  try {
    const response = yield call(api.add, action.data, role.toLowerCase());

    yield put(therapistAction.loader(false));

    if (action.callback) {
      action.callback(response.data.data.item.id);
    }

    yield sagaGetTherapists();
  } catch (e) {
    yield put(therapistAction.loader(false));
    showError();
  }
}

export function* sagaEditTherapist(action) {
  yield put(therapistAction.loaderModal(true));
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);

  try {
    const response = yield call(api.edit, action.id, action.data, role.toLowerCase());

    yield put(therapistAction.loaderModal(false));

    yield therapistAction.create(accountMapping.build(response.data.data.item, role), role);
    yield put(accountAction.set(response.data.data.item));

    if (action.callback) {
      action.callback();
    }
  } catch (e) {
    yield put(therapistAction.loaderModal(false));
    showError();
  }
}

export function* sagaArchiveTherapist(action) {
  yield put(therapistAction.loaderModal(true));
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);

  try {
    yield call(api.archive, action.id, role.toLowerCase());

    yield put(therapistAction.loaderModal(false));

    if (action.callback) {
      action.callback();
    }
  } catch (e) {
    yield put(therapistAction.loaderModal(false));
    showError();
  }
}

export function* sagaDeleteTherapist(action) {
  yield put(therapistAction.loaderModal(true));
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);

  try {
    yield call(api.delete, action.id, role.toLowerCase());

    yield put(therapistAction.loaderModal(false));

    if (action.callback) {
      action.callback();
    }
  } catch (e) {
    yield put(therapistAction.loaderModal(false));
    showError();
  }
}

export function* sagaEditTherapistNames(action) {
  yield put(therapistAction.loaderModal(true));
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);

  try {
    const response = yield call(api.editNames, action.id, action.data, role.toLowerCase());

    yield put(therapistAction.loaderModal(false));

    yield therapistAction.create(accountMapping.build(response.data.data.item, role), role);
    yield put(accountAction.set(response.data.data.item));

    if (action.callback) {
      action.callback();
    }
  } catch (e) {
    yield put(therapistAction.loaderModal(false));
    showError();
  }
}

export function* sagaCreateTherapistForm() {
  yield put(therapistAction.loaderModal(true));
  try {
    yield all([
      call(getZoomClients),
      call(getLicenses),
      call(getState),
      call(getTitles),
      call(getTopics),
      call(getSpecialties),
    ]);
    yield put(therapistAction.loaderModal(false));
  } catch (e) {
    yield put(therapistAction.loaderModal(false));
    showError();
  }
}

export function* sagaGetTherapistTitle() {
  yield put(therapistAction.loaderModal(true));
  try {
    yield call(getTitles);
    yield put(therapistAction.loaderModal(false));
  } catch (e) {
    yield put(therapistAction.loaderModal(false));
    showError();
  }
}

export function* sagaResetTherapist(action) {
  yield put(therapistAction.loaderModal(true));
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);

  try {
    yield call(api.resetPassword, action.id, action.data, role.toLowerCase());
    yield put(therapistAction.loaderModal(false));

    if (action.callback) {
      action.callback();
    }
  } catch (e) {
    yield put(therapistAction.loaderModal(false));
    showError();
  }
}

export function* sagaSetZoomTherapist(action) {
  yield put(therapistAction.loaderModal(true));
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);

  try {
    const response = yield call(api.set_zoom, role.toLowerCase(), action.id, action.data);
    yield put(therapistAction.loaderModal(false));
    yield put(accountAction.set(response.data.data.item));

    if (action.callback) {
      action.callback();
    }
  } catch (e) {
    yield put(therapistAction.loaderModal(false));
    showError();
  }
}

export function* sagaTherapistGroups(action) {
  yield put(therapistAction.loaderModal(true));
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);
  try {
    const response = yield call(api.groups, role.toLowerCase(), action.id);
    yield put(therapistAction.groups(response.data.data.items));
  } catch (e) {
    showError();
  } finally {
    yield put(therapistAction.loaderModal(false));
  }
}

export function* sagaTherapistInvoice(action) {
  yield put(therapistAction.loaderModal(true));
  try {
    const response = yield call(api.getInvoice, action.data);
    if (response?.data?.data?.item?.urls?.default) {
      if (response?.data?.data?.item?.urls?.default.includes('.docx')) {
        const link = document.createElement('a');
        link.href = response?.data?.data?.item?.urls?.default;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        window.open(response?.data?.data?.item?.urls?.default);
      }
    }
    if (action.callback) {
      action.callback();
    }
  } catch (e) {
    yield put(therapistAction.loaderModal(false));
    if (action.callback) {
      action.callback();
    }
    showError();
  } finally {
    yield put(therapistAction.loaderModal(false));
  }
}

export function* sagaTherapistSessionsHistory(action) {
  yield put(therapistAction.loaderModal(true));
  const getRole = (state) => state.user.profile.role;
  const getParams = (state) => state.therapists.sessionsParams;
  const getHistory = (state) => state.therapists.sessions;
  const role = yield select(getRole);
  const params = yield select(getParams);
  const history = yield select(getHistory);

  try {
    const response = yield call(api.sessionsHistory, role.toLowerCase(), action.id, queryString.stringify(params));

    yield put(therapistAction.loaderModal(false));

    if (params.page > 1) {
      yield put(
        therapistAction.sessionsHistory(
          {
            ...history,
            ...mappingTherapistsList.buildSessionHistory(response.data.data.items),
          },
          response.data.data.totalCount,
        ),
      );
    } else {
      yield put(
        therapistAction.sessionsHistory(
          mappingTherapistsList.buildSessionHistory(response.data.data.items),
          response.data.data.totalCount,
        ),
      );
    }
  } catch (e) {
    yield put(therapistAction.loaderModal(false));
    showError();
  }
}

function* getZoomClients() {
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);

  try {
    const { data } = yield call(api.get_zoom_clients, role.toLowerCase());

    yield therapistsAction.zoomClients(mappingTherapistsList.buildSelectOptions(data.data.items));
  } catch (e) {
    showError();
    console.log('e', e);
  }
}
