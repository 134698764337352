import React from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useForm } from 'react-hook-form';
// actions
import therapistAction from '../../../../redux/actions/therapist';
// components
import { SelectZoom, SubmitButton } from '../../../../components/molecules/inputsBlocks/addTherapist';
// types
import { stateType } from '../../../../types/types';
// style
import '../style.scss';
import './style.scss';

interface FormData {
  zoomClientId: number;
}

const FormZoomIntegration: React.FC<any> = ({ setVisible }: any) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormData>();

  const dispatch = useDispatch();

  const options: any = useSelector((state: stateType) => state.options.zoomClients, shallowEqual);
  const item: any = useSelector((state: stateType) => state.account.item, shallowEqual);

  const onSubmit = (data: FormData) => {
    dispatch(therapistAction.setZoom(item.id, data, () => setVisible(false)));
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="form-zoom-integration validation-form">
        <div className="form-zoom-integration__select-zoom">
          <p className="form-zoom-integration__title">Choose the correct zoom account</p>
          <div className="form-zoom-integration__zoom-input">
            <SelectZoom
              errors={errors.zoomClientId ? errors.zoomClientId.message : undefined}
              register={register}
              control={control}
              options={options}
              defaultValue={item?.zoomClient?.email}
            />
          </div>
        </div>
        <div className="form-zoom-integration__integration-btn">
          <SubmitButton name="Confirm" />
        </div>
      </form>
    </>
  );
};

export default FormZoomIntegration;
