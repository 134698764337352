/*
 *
 * npsScore reducer
 *
 */
const initialState = {
  items: [],
  loader: false,
  loaderModal: false,
  params: {
    page: 1,
    per_page: 5,
  },
  totalCount: 1,
};
export type npsScoreInitStateType = typeof initialState;

interface npsScoreActionTypes {
  type: string;
  payload: {
    items: Array<any>;
    loader: boolean;
    loaderModal: boolean;
    params: {
      page: number;
      per_page: number;
    };
    totalCount: number;
  };
}

const nps_score = (state = initialState, action: npsScoreActionTypes): npsScoreInitStateType => {
  switch (action.type) {
    case 'SCORE':
      return Object.assign({}, state, {
        items: action.payload.items,
        totalCount: action.payload.totalCount,
      });
    case 'GET_NPS_SCORE_PARAMS':
      return Object.assign({}, state, {
        params: action.payload.params,
      });
    case 'NPS_SCORE_LOADER':
      return Object.assign({}, state, {
        loader: action.payload.loader,
      });
    default:
      return state;
  }
};

export default nps_score;
