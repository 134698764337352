import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Modal, Spin } from 'antd';
// componets
import FormAddTherapist from '../../forms/adds/therapist';
// actions
import therapistsAction from '../../../../redux/actions/therapists';
// types
import { stateType } from '../../../../types/types';
// styles
import './style.scss';
// icons
import { ReactComponent as CloseIcon } from 'assets/image/account/close4.svg';

const AddTherapist: React.FC = () => {
  const [visible, setVisible] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    visible && dispatch(therapistsAction.getOptions());
  }, [visible]);

  const loader = useSelector((state: stateType) => state.therapists.loader, shallowEqual);

  const open = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <button className="btn-form" style={{ width: '263px' }} onClick={open}>
        + Add Therapist
      </button>
      {visible && (
        <Modal
          visible={visible}
          onOk={handleOk}
          closable={true}
          onCancel={handleCancel}
          centered
          maskTransitionName="none"
          footer={null}
          className="add-therapits-modal"
          closeIcon={<CloseIcon />}
        >
          <Spin spinning={loader}>
            <ModalContainer setVisible={setVisible} />
          </Spin>
        </Modal>
      )}
    </>
  );
};

export default AddTherapist;

const ModalContainer = ({ setVisible }: any) => {
  return (
    <div className="add-therapist-container">
      <div className="add-therapist-container__title">Add a therapist</div>
      <FormAddTherapist setVisible={setVisible} />
    </div>
  );
};
