import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Modal, Spin } from 'antd';
// components
import MainTemplate from '../../../components/templates/mainTemplate';
// actions
import appAction from '../../../redux/actions/app';
import Box from '../../../components/containers/box';
// styles
import './style.scss';
import { stateType } from 'types/types';
import accountAction from 'redux/actions/account';
import _ from 'lodash';
import { useHistory } from 'react-router';
import EditAccountModal from 'components/organisms/modals/editAccount';
import SimpleHeader from 'components/organisms/headers/simple/simple-header';
import SuperbillContainer from 'components/containers/superbillContainer';

const SuperbillNamePage: React.FC = () => {
  const dispatch = useDispatch();
  const info: any = useSelector((state: stateType) => state.account.item, shallowEqual);
  const date: any = useSelector((state: stateType) => state.superbill.date, shallowEqual);
  const loader = useSelector((state: stateType) => state.account.loader, shallowEqual);
  const role = useSelector((state: stateType) => state.user.profile.role, shallowEqual);
  const history = useHistory();

  useEffect(() => {
    if (!date || !date.start || !date.end) {
      history.push('/superbill');
      return;
    }
    dispatch(appAction.page('View superbills'));
    _.isEmpty(info) && dispatch(accountAction.get(role.toLowerCase()));
  }, []);

  const onEditPersonalDetails = () => {
    console.log('edit personal details');
  };
  return (
    <>
      <SimpleHeader />
      <SuperbillContainer>
        <div className="layout-superbill">
          <div className="superbill-page-container">
            <p className="superbill-page-subtitle">
              {!info?.icd10 ? (
                <>
                  The therapist did not provide your ICD-10 code. Please ask your ICD-10 code to generate your
                  superbill.
                </>
              ) : (
                <>
                  To generate your superbill, confirm that you&#39;ve entered your full name exactly as it appears on
                  your insurance card.
                </>
              )}
            </p>
            <div className="superbill-page-description">Name that will be on your superbill</div>
            <Spin spinning={loader}>
              <Box classes="progress-block-box name-block-box">
                <span className="superbill-name">
                  {info?.firstname} {info?.lastname}
                </span>
              </Box>
              {info && info?.icd10 && (
                <button className="btn-form btn-form__confirm" onClick={() => history.push('/superbill-details')}>
                  Confirm name
                </button>
              )}
              {info && info?.icd10 && (
                <EditAccountModal type={'name'}>
                  <button className="btn-form btn-form__cancel" onClick={onEditPersonalDetails}>
                    Edit name
                  </button>
                </EditAccountModal>
              )}
            </Spin>
          </div>
        </div>
      </SuperbillContainer>
    </>
  );
};

export default SuperbillNamePage;
