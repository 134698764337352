/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { call, put, select } from 'redux-saga/effects';
import showError from '../showError';
// api
import { apiQuestions } from '../../../api/question';
import { api } from '../../../api/clients';
// actions
import questionsAction from '../../actions/questions';
import userActions from '../../actions/user';
import clientAction from '../../actions/client';
import changeGroupActions from '../../actions/change_group';
import notificationClientAction from '../../actions/notificationClient';
// helpers
import mappingQuestions from '../../../api/mapping/response/questions';
import { mapping } from '../../../api/mapping/response/user';
// saga`s
import { sagaGetClient } from '../admin/clients';

export function* sagaCancelNextQuestion(action) {
  yield put(questionsAction.loader(true));
  yield questionsAction.create({});
  try {
    const response = yield call(apiQuestions.next, action ? action.number : null, 'cancel-subscription');
    yield questionsAction.status('step');
    yield questionsAction.create(mappingQuestions.buildQuestion(response.data.data.item, 'next'));
    yield put(questionsAction.loader(false));
  } catch (e) {
    yield put(questionsAction.loader(false));
    showError();
  }
}

export function* sagaCancelAnswer(action) {
  yield put(questionsAction.loader(true));
  try {
    const response = yield call(apiQuestions.answer, action.data, 'cancel-subscription');

    userActions.create(response.data.data.item);
    yield put(questionsAction.loader(false));
    action.callback && action.callback();

    if (action.data?.answerId === 44) {
      yield put(notificationClientAction.notificationDiscount(true));
    }
  } catch (e) {
    yield put(questionsAction.loader(false));
    showError();
  }
}

export function* sagaCancelAfterQuestion(action) {
  yield put(questionsAction.loader(true));
  yield questionsAction.create({});
  try {
    const response = yield call(apiQuestions.after, action ? action.number : null, 'cancel-subscription');
    yield questionsAction.status('step');
    yield questionsAction.create(mappingQuestions.buildQuestion(response.data.data.item, 'after'));
    yield put(questionsAction.loader(false));
  } catch (e) {
    yield put(questionsAction.loader(false));
    showError();
  }
}

export function* sagaCanselQuestionGroup(action) {
  yield put(questionsAction.loaderModal(true));
  try {
    const response = yield call(apiQuestions.cansel);

    // userActions.create(response.data.data.item);
    userActions.state(mapping.stateFlowBuild(response.data.data.item.stateFlow, response.data.data.item));
    yield put(questionsAction.loaderModal(false));
    changeGroupActions.status('');
  } catch (e) {
    yield put(questionsAction.loaderModal(false));
    showError();
  }
}

export function* sagaCancelGroup(action) {
  yield put(clientAction.loaderModal(true));
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);
  try {
    yield call(api.change, role.toLowerCase(), action.data.customerId, action.data.groupId);
    yield put(clientAction.loaderModal(false));

    yield sagaGetClient({ id: action.data.customerId });
  } catch (e) {
    yield put(clientAction.loaderModal(false));
    showError();
  }
}

export function* sagaCancelSubscriptionRenewal(action) {
  yield put(questionsAction.loader(true));

  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);

  try {
    yield call(api.cancelSubscriptionCleanAnswer, role.toLowerCase(), action.flowType);

    if (action.callback) {
      action.callback();
    }
  } catch (e) {
    yield put(questionsAction.loader(false));
    showError();
  }
}
