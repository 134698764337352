/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { AxiosResponse } from 'axios';
// config
import http from '../instance';

export const apiAccount = {
  get: async (role: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.get(`${role}/account`);
  },

  sendSupport: async (data: any): Promise<AxiosResponse> => {
    const api = await http();
    return api.post(`customer/contact-support`, data);
  },

  editCheckToken: async (token: any, role: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.post(`${role}/check-change-profile`, { token });
  },

  getEditToken: async (data: any, role: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.post(`${role}/get-change-profile-token`, data);
  },

  edit: async (data: any, role: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.patch(`${role}/change-profile-data`, data);
  },

  subscription: async (role: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.get(`${role}/subscription-info`);
  },

  setAvatar: async (data: any, role: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.patch(`${role}/avatar`, data);
  },

  getNotifications: async () => {
    const api = await http();
    return api.get(`/forum/notifications`);
  },

  readNewNotifications: async (data: any) => {
    const api = await http();
    return api.patch(`/forum/notifications`, data);
  },

  checkNotifications: async (): Promise<AxiosResponse> => {
    const api = await http();
    return api.get(`/forum/notification/check`);
  },
};
