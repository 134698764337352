/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { fixDayOfWeek, ParseArray } from '../../../../tools/helpFunctions';
export const accountMapping = {
  build(data, role) {
    switch (role) {
      case 'ADMIN':
        return {
          id: data.id,
          name: data.name,
          firstname: data.firstname,
          lastname: data.lastname,
          forumUsername: data.forumUsername,
          phone: data.phone,
          avatar: data.avatar,
          email: data.email,
          biography: data.biography,
          practiceYears: data.practiceYears,
          title: data.title,
          state: data.state,
          licenses: data.licenses,
          licensure: data.licenses ? ParseArray(data.licenses) : '',
          canArchive: data?.canArchive,
          canDelete: data?.canDelete,
          specialties: data.specialties,
          enableEmails: data?.enableEmails,
          enableSms: data?.enableSms,
          npi: data?.npi,
          specialtiesString:
            data.specialties && data.specialties.length > 0
              ? data.specialties.map((item, index) => {
                  if (index > 0) {
                    return `, ${item.name}`;
                  }

                  return `${item.name}`;
                })
              : [],
          groupTopics: data.groupTopics,
          groupTopicsString:
            data.groupTopics && data.groupTopics.length > 0
              ? data.groupTopics.map((item, index) => {
                  if (index > 0) {
                    return `, ${item.name}`;
                  }

                  return `${item.name}`;
                })
              : [],
          joinedDate: data.joinedDate,
        };

      case 'CUSTOMER':
        return data;

      case 'THERAPIST':
        return {
          id: data.id,
          name: data.name,
          firstname: data.firstname,
          lastname: data.lastname,
          forumUsername: data.forumUsername,
          phone: data.phone,
          avatar: data.avatar,
          email: data.email,
          biography: data.biography,
          practiceYears: data.practiceYears,
          state: data.state,
          title: data.title,
          licenses: data.licenses,
          licensure: data?.licenses ? ParseArray(data.licenses) : '',
          specialties: data.specialties,
          specialtiesString:
            data.specialties && data.specialties.length > 0
              ? data.specialties.map((item, index) => {
                  if (index > 0) {
                    return `, ${item.name}`;
                  }

                  return `${item.name}`;
                })
              : [],
          groupTopics: data.groupTopics,
          groupTopicsString:
            data.groupTopics && data.groupTopics.length > 0
              ? data.groupTopics.map((item, index) => {
                  if (index > 0) {
                    return `, ${item.name}`;
                  }

                  return `${item.name}`;
                })
              : [],
          joinedDate: data.joinedDate,
        };

      default:
        return data;
    }
  },

  customerInfoBuild(data) {
    return {
      ...data,
      medicalGroup: data?.medicalGroup
        ? {
            ...data.medicalGroup,
            dayOfWeek: fixDayOfWeek(data.medicalGroup.dayOfWeek, data.medicalGroup.start, 'to_ny'),
          }
        : null,
    };
  },
};
