import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spin, Tabs } from 'antd';
import _ from 'lodash';
import cn from 'classnames';
// components
import GroupDetailsCard from '../../../organisms/cards/groupDetails/admin';
import SessionList from '../../../organisms/lists/sessions/admin/group';
import RescheduleGroup from '../../../organisms/modals/reschedule/group';
import HistoryList from '../../../organisms/lists/history/admin';
import ArchiveGroup from '../../../organisms/modals/archive/group';
import CanselGroup from '../../../organisms/modals/cansel/group';
import RestartGroup from '../../../organisms/modals/restart/group';
// types
import { stateType } from '../../../../types/types';

// style
import '../style.scss';
// actions
import therapistAction from '../../../../redux/actions/therapist';
// hooks
import useDidMountEffect from '../../../../configs/hooks/useDidMountEffect';

const GroupDetailsContainer: React.FC<any> = ({ paramsId }: any) => {
  const group: any = useSelector((state: stateType) => state.groups.item.group);
  const therapist: any = useSelector((state: stateType) => state.groups.item.therapist);
  const members: any = useSelector((state: stateType) => state.groups.item.members);
  const sessions: any = useSelector((state: stateType) => state.sessions.items);
  const groupLoader: any = useSelector((state: stateType) => state.groups.loader);
  const sessionsLoader: any = useSelector((state: stateType) => state.sessions.loader);
  const dispatch = useDispatch();
  useDidMountEffect(() => {
    if (!_.isEmpty(therapist)) {
      dispatch(therapistAction.get(therapist.id));
    }
  }, [therapist]);

  return (
    <Spin spinning={groupLoader || sessionsLoader}>
      <div className="group-details-container">
        {!groupLoader && (
          <div className="group-details-container__column">
            <GroupDetailsCard group={group} therapist={therapist} members={members} sessions={sessions} />
          </div>
        )}

        {!sessionsLoader && !groupLoader && (
          <div className="group-details-container__column">
            <SessionTabBox>
              <GroupDetailsTabs sessions={sessions} paramsId={paramsId} />
            </SessionTabBox>
            {group && <GroupButtons group={group} />}
          </div>
        )}
      </div>
    </Spin>
  );
};

export default GroupDetailsContainer;

const GroupDetailsTabs = ({ sessions, paramsId }: any) => {
  const { TabPane } = Tabs;

  const callback = (key: any) => {
    console.log(key);
  };

  return (
    <Tabs defaultActiveKey="1" onChange={callback} className="account-tabs">
      <TabPane tab="Upcoming Sessions" key="sessions" className="account-tabs__item">
        <SessionList sessions={sessions} />
      </TabPane>
      <TabPane tab="Group History" key="history" className="account-tabs__item">
        <HistoryList paramsId={paramsId} />
      </TabPane>
    </Tabs>
  );
};

const SessionTabBox = ({ children }: any) => {
  return <div className="session-tab-box">{children}</div>;
};

const GroupButtons = ({ group }: any) => {
  const session: any = useSelector((state: stateType) => state.sessions.items);

  const { features } = group;
  return (
    <div className={cn('group-btns', { end: features.canRestart })}>
      {features.canReschedule && <RescheduleGroup session={session} />}
      {features.canCancel && <CanselGroup />}
      {features.canArchive && <ArchiveGroup />}
      {features.canRestart && <RestartGroup />}
    </div>
  );
};
