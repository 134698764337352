import { Checkbox } from 'antd';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';
// actions
import progressAction from '../../../redux/actions/progress';
import { LongTermItemType } from 'types/actions/progress';
// components
import OutsideClickHandler from 'react-outside-click-handler';
// icons
import deleteIcon from 'assets/image/common/delete.svg';
// styles
import './style.scss';
import useDidMountEffect from '../../../configs/hooks/useDidMountEffect';
import eventAnalytics from '../../../redux/actions/analytics';

interface AddListType {
  list: LongTermItemType[];
  loader?: boolean;
}

export const AddList: React.FC<AddListType> = ({ list }: AddListType) => {
  const [value, setValue] = useState('');
  const [listLength, setListLength] = useState(0);
  const [isAdd, setIsAdd] = useState(false);
  const [checked, setChecked] = useState(false);
  const [items, setItems] = useState<any>([]);
  const [editID, setEditID] = useState<any>(null);
  const scrollRef = useRef<HTMLHeadingElement>(null);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    setItems(list);
  }, [list]);

  useEffect(() => {
    setListLength(list.length);
  }, []);

  useDidMountEffect(() => {
    if (listLength !== items.length && isAdd) {
      scrollRef.current?.scrollTo({ top: scrollRef.current.scrollHeight, behavior: 'smooth' });
      setIsAdd(false);
    }
  }, [items.length]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const onCheckChange = (e: any) => {
    setChecked(e.target.checked);
  };

  const add = () => {
    if (value.trim()) {
      dispatch(progressAction.set_term([...items, { id: null, goal: value.trim(), completed: checked }]));
      setChecked(false);
      setValue('');
      setIsAdd(true);
      dispatch(eventAnalytics.analytics('add-new-goal'));
    }
  };

  const save = () => {
    const array = [];
    if (value.length > 0) {
      array.push({ id: null, goal: value.trim(), completed: checked });
    }
    dispatch(
      progressAction.post_term({ longTermGoalCreateAndEditDataList: [...items, ...array] }, () => {
        history.push('/progress/term');
      }),
    );
    dispatch(eventAnalytics.analytics('save-goal'));
  };

  const cancel = () => {
    history.push('/progress/term');
    dispatch(progressAction.get_term());
  };

  const remove = (index: number) => {
    const array = list.map((item: LongTermItemType, i: number) => {
      if (i !== index) {
        return item;
      }
    });
    dispatch(
      progressAction.set_term(
        _.filter(array, (el) => {
          if (el) {
            return el;
          }
        }),
      ),
    );
  };

  const clickEdit = (e: React.MouseEvent<HTMLDivElement>, id: any) => {
    if (!('checked' in e.target)) {
      setEditID(id);
    }
  };

  const onBlurEdit = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    if (!e.target.value.length) {
      remove(index);
    }
  };

  const onEdit = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const result = [...items];
    result[index].goal = e.target.value;

    setItems(result);
  };

  const onChangeStatus = (e: any, index: number) => {
    const result = [...items];
    result[index].completed = e.target.checked;

    setItems(result);
  };

  return (
    <div className="term-list">
      <OutsideClickHandler
        onOutsideClick={() => {
          setEditID(null);
        }}
      >
        <div className="term-list__body">
          <div className="term-list__title">My long-term goals</div>
          <div ref={scrollRef} className="term-list__goals-list">
            {items?.map((item: LongTermItemType, index: number) => (
              <div className="term-list__item" key={index} onClick={(e) => clickEdit(e, item.id)}>
                {editID === item.id ? (
                  <div className="term-list__input-wrap">
                    <Checkbox checked={item?.completed} onChange={(e) => onChangeStatus(e, index)} />
                    <div className="term-list__input-rm">
                      <input
                        onChange={(e) => onEdit(e, index)}
                        className="term-list__item-input_edit"
                        value={item.goal}
                        onBlur={(e) => onBlurEdit(e, index)}
                      />
                      <button className="term-list__remove-btn" onClick={() => remove(index)} draggable={false}>
                        <img src={deleteIcon} alt="delete item" draggable={false} />
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="term-list__input-wrap">
                    <Checkbox checked={item?.completed} onChange={(e) => onChangeStatus(e, index)} />
                    <p className="term-list__item-text">{item.goal}</p>
                    <button className="term-list__remove-btn" onClick={() => remove(index)} draggable={false}>
                      <img src={deleteIcon} alt="delete item" draggable={false} />
                    </button>
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="term-list__item-add">
            <div className="term-list__input-wrap">
              <Checkbox checked={checked} onChange={onCheckChange} />
              <input onChange={onChange} className="term-list__item-input" value={value} />
            </div>
            <div onClick={add} className={cn('term-list__add', { disabled: !value.trim() })}>
              +Add goal
            </div>
          </div>
        </div>
        <div className="term-list__footer">
          <button className="btn-form" onClick={save}>
            Save
          </button>
          <button className="btn-light term-list__btn" onClick={cancel}>
            Cancel
          </button>
        </div>
      </OutsideClickHandler>
    </div>
  );
};
