/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { AxiosResponse } from 'axios';
// config
import http from '../instance';
// types
import { ObjectType } from '../../types/actions/common-types';

export const api = {
  get: async (role: string, params: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.get(`${role}/clients${params ? `?${params}` : ''}`);
  },

  getMissingICDClients: async (role: string, params: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.get(`${role}/clients/missing-codes${params ? `?${params}` : ''}`);
  },

  getByID: async (id: string, role: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.get(`${role}/customer/${id}/info`);
  },

  enableSms: async (id: string, enable: boolean): Promise<AxiosResponse> => {
    const api = await http();
    return api.patch(`admin/customer/${id}/enable-sms?enable_sms=${enable}`);
  },

  enableEmails: async (id: string, enable: boolean): Promise<AxiosResponse> => {
    const api = await http();
    return api.patch(`admin/customer/${id}/enable-emails?enable_emails=${enable}`);
  },

  setICD10: async (id: string, role: string, data: any): Promise<AxiosResponse> => {
    const api = await http();
    if (role == 'admin') {
      return api.patch(`admin/customer/${id}/icd10?icd10=${data.code}`);
    } else {
      return api.post(`${role}/customer/${id}/icd10`, data);
    }
  },

  edit: async (role: string, id: any, data: any): Promise<AxiosResponse> => {
    const api = await http();
    return api.patch(`${role}/customer/${id}/info`, data);
  },

  change: async (role: string, customerId: string, groupId: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.patch(`${role}/customer/${customerId}/change-group/${groupId}`);
  },

  restartSubscription: async (role: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.patch(`${role}/restart-subscription`);
  },

  updateRegistrationData: async (data: ObjectType): Promise<AxiosResponse> => {
    const api = await http();
    return api.patch(`customer/update-registration-data`, data);
  },

  resetRestartSubscription: async (): Promise<AxiosResponse> => {
    const api = await http();
    return api.patch(`customer/restart-customer-reset`);
  },

  restartSubscriptionByID: async (role: string, customerId: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.patch(`${role}/customer/${customerId}/restart-subscription`);
  },

  waitingGoBack: async (): Promise<AxiosResponse> => {
    const api = await http();
    return api.patch(`customer/back-restart-step`);
  },

  cancelSubscriptionByID: async (role: string, customerId: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.patch(`${role}/customer/${customerId}/cancel`);
  },

  cancelSubscriptionCleanAnswer: async (role: string, flowTypeName: any): Promise<AxiosResponse> => {
    const api = await http();
    return api.patch(`${role}/questionnaire/${flowTypeName}/reset-answer-number`);
  },

  archiveByID: async (role: string, customerId: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.delete(`${role}/customer/${customerId}/archive`);
  },

  unarchiveByID: async (role: string, customerId: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.patch(`${role}/customer/${customerId}/restore`);
  },

  approveByID: async (role: string, customerId: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.patch(`${role}/customer/${customerId}/approve`);
  },

  noShow: async (role: string, customerId: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.patch(`${role}/customer/${customerId}/no-show`);
  },

  pauseByID: async (role: string, customerId: string, data: any): Promise<AxiosResponse> => {
    const api = await http();
    return api.patch(`${role}/customer/${customerId}/pause`, data);
  },

  unpauseByID: async (role: string, customerId: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.patch(`${role}/customer/${customerId}/unpause`);
  },
};
