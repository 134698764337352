import React from 'react';
import _ from 'lodash';
import Icon from '@ant-design/icons';
import cn from 'classnames';

// icons
import { ReactComponent as IconEdit } from '../../../../assets/image/account/edit-blue.svg';
// style
import './style.scss';
// components
import ShowText from '../../../molecules/showText';
import EditTherapistBioModal from '../../modals/edit/admin/therapist/therapistBio';

const CardTherapist: React.FC<any> = ({ therapist, isEdit }: any) => {
  return (
    <div className="card-therapist">
      {!_.isEmpty(therapist) && (
        <>
          <div className="card-therapist__body">
            <div className="card-therapist__avatar">
              {therapist.avatar ? <img src={therapist.avatar} alt="" /> : <div>{therapist.name[0].toUpperCase()}</div>}
            </div>
            <div className="card-therapist__info">
              <div className="card-therapist__name">
                {therapist?.therapist_title?.name ? `${therapist?.therapist_title.name}.` : ''} {therapist.name}
              </div>
              <div className="card-therapist__item">
                <div className="card-therapist__before">Licensure:</div>
                <div className="card-therapist__value">{therapist.licensure}</div>
              </div>
              {!!therapist?.practiceYears && (
                <div className="card-therapist__item">
                  <div className="card-therapist__before">Years in Practice:</div>
                  <div className="card-therapist__value">{therapist.practiceYears}</div>
                </div>
              )}
              <div className="card-therapist__item">
                <div className="card-therapist__before">Specialties:</div>
                <div className="card-therapist__value">{therapist.specialties}</div>
              </div>
            </div>
          </div>
          <div className={cn('card-therapist__label', { 'card-therapist__edit': isEdit })}>
            <span>About the Therapist</span>
            {isEdit && (
              <EditTherapistBioModal
                btnClass={'account-edit-block__edit-btn'}
                icon={<Icon component={IconEdit} />}
                name={'Edit'}
              />
            )}
          </div>
          <div className="card-therapist__description">
            <ShowText text={therapist.description} strLength={300} />
          </div>
        </>
      )}
    </div>
  );
};

export default CardTherapist;
