import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
// actions
// components
// types
import { stateType } from 'types/types';
// styles
import './style.scss';
import appAction from 'redux/actions/app';

type SuperbillContainerType = {
  title?: string;
  children?: React.ReactChild | React.ReactChild[];
};

const SuperbillContainer: React.FC<SuperbillContainerType> = ({
  title = 'View superbills',
  children,
}: SuperbillContainerType) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const role = useSelector((state: stateType) => state.user.profile.role, shallowEqual);
  // const [title] = useState('View superbills');

  const onGoBack = () => {
    history.goBack();
  };

  const onClose = () => {
    if (role == 'ADMIN') {
      if (window.location.pathname == '/client-superbill-details') {
        history.go(-2);
      } else {
        history.goBack();
      }
    } else {
      history.push('/profile');
      dispatch(appAction.account('subscription'));
    }
  };

  return (
    <div className="superbill-container change">
      <div className="superbill-container__body box">
        <div className="superbill-container__top">
          {window.location.pathname != '/superbill' && (
            <div className="superbill-container__back">
              <button className="superbill-container__cancel" onClick={onGoBack} />
            </div>
          )}

          <div className="superbill-container__title">{title}</div>

          <div className="superbill-container__close" onClick={onClose}>
            <button />
          </div>
        </div>
        <div className="superbill-container__wrap">
          <div className="superbill-container__card">
            <div>{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuperbillContainer;
