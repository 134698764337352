import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// styles
import './style.scss';
// actions
import mainMenuAction from '../../../redux/actions/main_menu';

interface SimpleLogoType {
  click?: boolean;
}

export const SimpleLogo: React.FC<SimpleLogoType> = ({ click }: SimpleLogoType) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const actions = () => {
    dispatch(mainMenuAction.showDrawer(false));
    history.push('/');
  };

  const clicker = () => {
    click && actions();
  };
  return <div className="simple-logo" onClick={clicker} />;
};
