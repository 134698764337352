/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import store from '../store';
import { mapping } from '../../api/mapping/response/user';
// api
import { apiUser } from 'api/user';
// actions
import appAction from './app';
import mainMenuAction from './main_menu';

const userActions = {
  create: (data: any, role: any = false): any => {
    store.dispatch({
      type: 'USER',
      payload: {
        profile: mapping.userBuild(data, role),
      },
    });
  },

  state: (state: string): any => {
    store.dispatch({
      type: 'USER_STATE',
      payload: {
        state: state,
      },
    });
  },

  update: async (data?: any, dispatch?: any, redirect?: any): Promise<any> => {
    const role = data?.roles && mapping.initRole(data.roles).toLowerCase();
    try {
      const { data } = await apiUser.self(role ? role : 'customer');
      const { item } = data.data;

      const register = sessionStorage.getItem('register');

      if (role === 'customer' && (item?.registrationFlow || item?.stateFlow === 11) && !register) {
        dispatch(appAction.auth('registration'));

        localStorage.removeItem('token');
        localStorage.removeItem('refresh_token');
      } else {
        dispatch(appAction.auth('auth'));
        userActions.create(item);
        dispatch(mainMenuAction.propgressPopover(item?.progressScoreType ? true : false));
        if (role !== 'admin') {
          userActions.state(mapping.stateFlowBuild(item.stateFlow, item));
        }
      }
    } catch (e: any) {
      if (e.response) {
        localStorage.removeItem('token');
        localStorage.removeItem('refresh_token');

        dispatch(appAction.auth('login'));
      }
      if (redirect) {
        redirect();
      }
    }
  },

  rewrite: (data: any): any => {
    return {
      type: 'USER',
      payload: {
        profile: data,
      },
    };
  },
};

export default userActions;
