/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import _ from 'lodash';

const colors = ['#6C63FF', '#1891E5', '#FC5A5A', '#82C43C'];

export const sessionMap = {
  buildSessionsNext(array) {
    return array.map((el) => {
      return {
        ...el,
        members: el.members
          ? el.members.map((item, index) => {
              return {
                id: index,
                clientId: item.id,
                firstname: item.firstname,
                lastname: item.lastname,
                color: colors[_.random(0, 3)],
              };
            })
          : [],
      };
    });
  },
};
