import React from 'react';
// styles
import './style.scss';

const StatusGroups: React.FC<any> = ({ status }: any) => {
  return (
    <div className={`group-status-box group-status-box__${status.value}`}>
      <span>{status.name}</span>
    </div>
  );
};

export default StatusGroups;
