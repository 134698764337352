import React from 'react';

export default function ParticipantsIcon() {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.00033 9.66667C9.84128 9.66667 11.3337 8.17428 11.3337 6.33333C11.3337 4.49238 9.84128 3 8.00033 3C6.15938 3 4.66699 4.49238 4.66699 6.33333C4.66699 8.17428 6.15938 9.66667 8.00033 9.66667Z"
        stroke="white"
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 18V16.3333C3 15.4493 3.35119 14.6014 3.97631 13.9763C4.60143 13.3512 5.44928 13 6.33333 13H9.66667C10.5507 13 11.3986 13.3512 12.0237 13.9763C12.6488 14.6014 13 15.4493 13 16.3333V18"
        stroke="white"
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.833 3.10938C14.55 3.29296 15.1855 3.70996 15.6394 4.29463C16.0932 4.87931 16.3395 5.5984 16.3395 6.33854C16.3395 7.07868 16.0932 7.79778 15.6394 8.38245C15.1855 8.96712 14.55 9.38412 13.833 9.56771"
        stroke="white"
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 18V16.3333C17.9958 15.5976 17.7483 14.884 17.2961 14.3037C16.8439 13.7233 16.2124 13.3089 15.5 13.125"
        stroke="white"
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
