import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import CloseIcon from '../../../../icons/CloseIcon';

import useChatContext from '../../../../hooks/useChatContext/useChatContext';
import { PrivateConversation } from '../../ChatProvider';
import { displayName } from '../../Helpers/helpers';
import { useAppState } from '../../../../state';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      height: '56px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0 1em',
    },
    text: {
      fontWeight: 'bold',
    },
    closeChatWindow: {
      cursor: 'pointer',
      display: 'flex',
      background: 'transparent',
      width: '30px',
      height: '30px',
      backgroundColor: '#828282',
      border: '0',
      borderRadius: '50%',
      padding: '7px',
    },
  }),
);

interface ChatWindowHeaderProps {
  activeChatTab: string;
  setActiveChatTab: Dispatch<SetStateAction<string>>;
  privateConversations: PrivateConversation[];
}

export default function ChatWindowHeader({
  activeChatTab,
  setActiveChatTab,
  privateConversations,
}: ChatWindowHeaderProps) {
  const classes = useStyles();
  const { setIsChatWindowOpen } = useChatContext();

  return (
    <div className={classes.container}>
      <div className={classes.text}>{activeChatTab == 'main' ? <>Main Group Chat</> : <>Private Message</>}</div>
      <button className={classes.closeChatWindow} onClick={() => setIsChatWindowOpen(false)}>
        <CloseIcon />
      </button>
    </div>
  );
}
