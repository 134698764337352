import React, { useRef } from 'react';
import { Collapse, Spin } from 'antd';
import Moment from 'react-moment';
import { useSelector } from 'react-redux';
// components
import { TherapistSessionHistoryItem } from '../../sessions/items';
// types
import { stateType } from 'types/types';
// styles
import './style.scss';
// helpers
import useDidMountEffect from '../../../../../configs/hooks/useDidMountEffect';
import { scrollToBottom } from '../../../../../tools/helpFunctions';

const SessionList: React.FC<any> = ({ list, onShowMore, params }: any) => {
  const refScrollTo = useRef<HTMLDivElement>(null);

  const totalCount = useSelector((state: stateType) => state.therapists.totalCount);
  const loader = useSelector((state: stateType) => state.therapists.loaderModal);

  useDidMountEffect(() => {
    scrollToBottom(refScrollTo);
  }, [list]);

  return (
    <Spin spinning={loader}>
      <div className="session-history-list">
        {list &&
          Object.keys(list).map((item: any, index: number) => {
            return (
              <Collapse className="session-history-list__collapse" accordion key={index}>
                <Collapse.Panel
                  showArrow={false}
                  header={<Moment format="MMMM D, YYYY">{item}</Moment>}
                  key={item.id}
                  className="session-history-list__panel"
                >
                  {list[item].map((el: any) => {
                    return <TherapistSessionHistoryItem key={el.id} item={el} />;
                  })}
                </Collapse.Panel>
              </Collapse>
            );
          })}
        {totalCount > 5 && (
          <div className="history-container__button-container">
            {params.page * 5 < totalCount && (
              <button className="history-container__button" type="button" onClick={onShowMore}>
                Show more
              </button>
            )}
          </div>
        )}
        <div ref={refScrollTo} />
      </div>
    </Spin>
  );
};

export default SessionList;
