import React, { FC } from 'react';
import { Controller } from 'react-hook-form';
import { DatePicker } from 'antd';
import moment from 'moment';
import { CaretDownOutlined } from '@ant-design/icons';

// components
import InputField from '../inputField';

// styles
import './style.scss';

interface DatepickerType {
  errors: string | undefined;
  control?: any;
  defaultValue?: any;
  name: string;
  label: string;
  placeholder?: string;
  format?: string;
  disabled: boolean;
}

const CustomDatepicker: FC<DatepickerType> = ({
  errors,
  control,
  defaultValue,
  name,
  label,
  placeholder,
  format,
  disabled,
}: DatepickerType) => {
  return (
    <InputField name={name} label={label} errors={errors} icon={false}>
      <Controller
        control={control}
        name={name}
        rules={{ required: 'choose at least one option' }}
        render={({ field: { onChange } }) => (
          <DatePicker
            disabledDate={(current) => {
              const customDate = moment().format('YYYY-MM-DD');
              return current && current < moment(customDate, 'YYYY-MM-DD');
            }}
            dropdownClassName="dropdown-datepicker"
            className="input-datepicker"
            onChange={onChange}
            placeholder={placeholder ? placeholder : 'Select Date'}
            defaultValue={defaultValue && moment(defaultValue, 'YYYY/MM/DD')}
            format={format ? format : 'YYYY/DD/MM'}
            suffixIcon={<CaretDownOutlined />}
            showToday={false}
            mode={'date'}
            // disabledDate={(current) => {
            //   return disabled && current && current <= moment().subtract(1, 'day');
            // }}
          />
        )}
      />
    </InputField>
  );
};

export default CustomDatepicker;
