/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { put, call, select } from 'redux-saga/effects';
import showError from '../showError';
// api
import { apiQuestions } from '../../../api/question';
// actions
import questionsAction from '../../actions/questions';
// helpers
import mappingQuestions from '../../../api/mapping/response/questions';

export function* sagaNextQuestion(action) {
  yield put(questionsAction.loader(true));
  yield questionsAction.create({});
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);
  try {
    const response = yield call(
      apiQuestions.next,
      action ? action.number : null,
      action.flowType,
      role ? role.toLowerCase() : 'customer',
    );
    yield questionsAction.status('step');
    yield questionsAction.create(mappingQuestions.buildQuestion(response.data.data.item, 'next'));
    yield put(questionsAction.loader(false));
  } catch (e) {
    alert(e);
    console.log(e);
    yield put(questionsAction.loader(false));
    showError();
  }
}

export function* sagaAnswer(action) {
  yield put(questionsAction.loader(true));
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);

  try {
    yield call(apiQuestions.answer, action.data, action.flowType, role.toLowerCase());

    yield put(questionsAction.loader(false));
    action.callback && action.callback();
  } catch (e) {
    console.log(e);
    yield put(questionsAction.loader(false));
    showError();
  }
}

export function* sagaAfterQuestion(action) {
  yield put(questionsAction.loader(true));
  yield questionsAction.create({});
  try {
    const response = yield call(apiQuestions.after, action ? action.number : null, action.flowType);
    yield questionsAction.status('step');
    yield questionsAction.create(mappingQuestions.buildQuestion(response.data.data.item, 'after'));
    yield put(questionsAction.loader(false));
  } catch (e) {
    console.log(e);
    yield put(questionsAction.loader(false));
    showError();
  }
}
