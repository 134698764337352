import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Spin } from 'antd';
// components
import AccountCustomer from '../../../organisms/account/customer';
import BoxAccontContainer from '../../boxAccountContainer';
// types
import { stateType } from '../../../../types/types';

const AccountCusstomerContainer: React.FC = () => {
  const loader = useSelector((state: stateType) => state.account.loader, shallowEqual);
  return (
    <BoxAccontContainer>
      <Spin spinning={loader}>
        <AccountCustomer />
      </Spin>
    </BoxAccontContainer>
  );
};

export default AccountCusstomerContainer;
