import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import { useWindowResize } from 'beautiful-react-hooks';
import ReactPlayer from 'react-player';
import { Modal } from 'antd';
// images
import imgPlay from 'assets/image/slider/playBtn.svg';
import iconNextArr from 'assets/image/slider/nextArr.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/image/account/close4.svg';
// styles
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './style.scss';
import eventAnalytics from '../../../../redux/actions/analytics';
import { useDispatch } from 'react-redux';

export const NextArrow: React.FC<any> = ({ className, style, onClick }: any) => {
  return <div className={className} style={{ ...style, display: 'block' }} onClick={onClick} />;
};

const HelpFullSlider: React.FC<any> = ({ list }: any) => {
  const [width, setWidth] = useState(window.innerWidth);

  useWindowResize(() => {
    setWidth(window.innerWidth);
  });

  const settings: any = {
    className: 'slider variable-width',
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 600,
    nextArrow: <NextArrow className="arrow next-arrow" />,
    prevArrow: <NextArrow className="arrow next-arrow" />,
    variableWidth: width > 380, // fix for library features
  };

  return (
    <div className="help-full-slider">
      <Slider {...settings}>
        {list?.map((item: any) => (
          <SliderItem key={item.id} item={item} />
        ))}
      </Slider>
    </div>
  );
};

export default HelpFullSlider;

const SliderItem = ({ item }: any) => {
  const { picture, title, topic, playUrl, id } = item;
  const [visible, setVisible] = useState<boolean>(false);
  const dispatch = useDispatch();

  const onPlay = () => {
    if (playUrl) {
      setVisible(true);
    }
    dispatch(eventAnalytics.analytics('watch-video', { videoName: title }));
  };

  return (
    <div className="slider-item">
      <div className="slider-item__body">
        <div className="slider-item__button" onClick={onPlay}>
          <img src={imgPlay} alt="imgPlay" />
        </div>
        <div className="slider-item__title">{title}</div>
        <div className="slider-item__link">
          <div className="slider-item__text">{topic}</div>
          <img src={iconNextArr} alt="show more arrow" />
        </div>
      </div>

      <div className="slider-item__bg">{picture?.url && <img src={picture.url} alt="slider card image" />}</div>
      {visible && <VideoModal visible={visible} setVisible={setVisible} playUrl={playUrl} videoId={id} />}
    </div>
  );
};

const VideoModal = ({ visible, setVisible, playUrl, videoId }: any) => {
  const playerRef = useRef<any>();

  const oneClose = () => {
    sessionStorage.setItem(`Video${videoId}`, playerRef.current.getCurrentTime());
    setVisible(false);
  };

  useEffect(() => {
    playerRef.current.seekTo(sessionStorage.getItem(`Video${videoId}`));
  }, []);

  return (
    <Modal
      visible={true}
      onCancel={oneClose}
      centered
      footer={null}
      closeIcon={<CloseIcon />}
      className="past-events__video"
    >
      <ReactPlayer url={playUrl} autoPlay controls playing={visible} ref={playerRef} volume={0.5} />
    </Modal>
  );
};
