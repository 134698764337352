import React from 'react';
import cn from 'classnames';
// types
import { CardType } from '../../../../../types/commonPropTypes';

interface BorderCardType extends CardType {
  small?: any;
  white?: boolean;
}

const BorderCard: React.FC<BorderCardType> = ({ children, small, white }: BorderCardType) => {
  return <div className={cn('border-card', { sm: small, white })}>{children}</div>;
};

export default BorderCard;
