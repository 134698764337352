import store from '../store';
import { mapping } from '../../api/mapping/response/tables';
import { ActionCallback, FilterOptionsRole } from '../../types/actions/common-types';
import { GetFilterOptionsActions, setOptionsIsReceivedActions } from '../../types/actions/therapist';
// actions
// import mainMenuAction from './main_menu';

const therapistsAction: any = {
  get(data?: any, callback?: () => void): any {
    return {
      type: 'GET_THERAPISTS',
      data,
      callback,
    };
  },
  getList(id?: any, callback?: () => void): any {
    return {
      type: 'GET_THERAPISTS_LIST',
      id,
      callback,
    };
  },
  getOptions(callback?: () => void): any {
    return {
      type: 'GET_THERAPIST_FORM_OPTIONS',
      callback,
    };
  },
  getTitleOptions(callback?: () => void): any {
    return {
      type: 'GET_THERAPIST_TITLE',
      callback,
    };
  },
  loader(status: boolean): any {
    return {
      type: 'THERAPISTS_LOADER',
      payload: {
        loader: status,
      },
    };
  },
  create: (data: any): any => {
    store.dispatch({
      type: 'THERAPISTS',
      payload: {
        items: mapping.therapistsBuild(data.items),
        count: data.totalCount,
      },
    });
  },
  create_list: (data: any): any => {
    store.dispatch({
      type: 'THERAPISTS_LIST',
      payload: {
        list: data,
      },
    });
  },
  clear: (): any => {
    store.dispatch({
      type: 'THERAPISTS',
      payload: {
        items: [],
        count: 0,
      },
    });
  },
  clear_list: (): any => {
    store.dispatch({
      type: 'THERAPISTS_LIST',
      payload: {
        list: [],
      },
    });
  },

  zoomClients: (data: any): any => {
    store.dispatch({
      type: 'THERAPIST_ZOOM_CLIENTS',
      payload: {
        zoomClients: data,
      },
    });
  },

  params: (params: any): any => {
    store.dispatch({
      type: 'THERAPISTS_PARAMS',
      payload: {
        params: params,
      },
    });
  },

  rowIndex: (rowIndex?: number): void => {
    store.dispatch({
      type: 'THERAPISTS_ROW_INDEX',
      payload: {
        rowIndex,
      },
    });
  },

  getFilterOptions(callback?: ActionCallback): GetFilterOptionsActions {
    return {
      type: 'GET_THERAPIST_FILTER_OPTIONS',
      callback,
    };
  },

  setOptionsIsReceived(optionsIsReceived: FilterOptionsRole): setOptionsIsReceivedActions {
    return {
      type: 'SET_THERAPIST_FILTER_OPTIONS',
      payload: {
        optionsIsReceived: optionsIsReceived,
      },
    };
  },
};

export default therapistsAction;
