import React, { useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { Modal, Spin } from 'antd';
// componets
import Uploader from 'components/organisms/files';
// types
import { stateType } from '../../../../types/types';
// icons
import AccountEditPhoto from '../../../../assets/image/account/camera2.svg';
import { ReactComponent as CloseIcon } from 'assets/image/account/close4.svg';
// styles
import './styles.scss';
import accountAction from 'redux/actions/account';

const EditTherapistPhoto: React.FC = () => {
  const [visible, setVisible] = useState(false);

  const loader = useSelector((state: stateType) => state.account.loaderModal, shallowEqual);

  const open = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <div className="account-edit-icon">
        <img src={AccountEditPhoto} className="account-therapist__avatar-edit-icon" onClick={open} alt="" />
      </div>
      {visible && (
        <Modal
          visible={visible}
          onOk={handleOk}
          closable={true}
          onCancel={handleCancel}
          centered
          maskTransitionName="none"
          footer={null}
          className="edit-therapist-photo-modal"
          closeIcon={<CloseIcon />}
        >
          <Spin spinning={loader}>
            <ModalContainer handleCancel={handleCancel} />
          </Spin>
        </Modal>
      )}
    </>
  );
};

export default EditTherapistPhoto;

const ModalContainer = ({ handleCancel }: any) => {
  const dispatch = useDispatch();
  const file: any = useSelector((state: stateType) => state.app.file);

  const confirmation = () => {
    dispatch(accountAction.setAvatar(file, handleCancel));
  };

  return (
    <div className="edit-therapist-photo-container edit-therapist-photo-container__therapist">
      <div className="edit-container__title">Edit Photo</div>
      <div className="edit-therapist-photo-container__download-photo">
        <Uploader type={'user_avatar'} file={file} />
      </div>
      <div className="edit-therapist-photo-container__button">
        <button className="btn-form" onClick={confirmation}>
          Confirm
        </button>
      </div>
    </div>
  );
};
