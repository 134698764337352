import React, { useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Modal, Spin } from 'antd';
// componets
import FormEdit from '../../../../forms/edit/admin/client';
// types
import { stateType } from '../../../../../../types/types';
// styles
import '../style.scss';
import { FormEditICD10Container } from 'components/organisms/modals/editAccount';
import { ReactComponent as CloseIcon } from '../../../../../../assets/image/account/close4.svg';
import cn from 'classnames';

const EditClientICDModal: React.FC<any> = ({ children, icon, name }: any) => {
  const [visible, setVisible] = useState(false);

  const loader = useSelector((state: stateType) => state.clients.loaderModal, shallowEqual);
  const showMinimal = useSelector((state: any) => state.main_menu.showMinimal, shallowEqual);

  const open = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <div onClick={open}>
        {icon}
        {children}
      </div>
      {visible && (
        <Modal
          visible={visible}
          onOk={handleOk}
          closable={true}
          onCancel={handleCancel}
          centered
          maskTransitionName="none"
          footer={null}
          closeIcon={<CloseIcon />}
          className={cn('edit-account-modal', {
            'edit-account-modal__minimal': showMinimal,
          })}
        >
          <Spin spinning={loader}>
            <FormEditICD10Container setVisible={setVisible} />
          </Spin>
        </Modal>
      )}
    </>
  );
};

export default EditClientICDModal;
