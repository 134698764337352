import React, { useCallback, useContext, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles, Theme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { UserDataContext } from '../UserDataProvider/UserDataProvider';
import { displayName } from '../Helpers/helpers';

interface ChangeNameDialogProps {
  open: boolean;
  onClose: () => void;
}

const useStyle = makeStyles((theme: Theme) => ({
  dialog: {
    width: '100%',
  },
  dialogContent: {
    width: 400,
  },
  nameInput: {
    border: '1px solid rgba(208, 213, 221, 1)',
    borderRadius: 8,
    resize: 'none',
    fontSize: '14px',
    outline: 'none',
    textAlign: 'center',
    padding: '10px 14px',
    width: '100%',
  },
  cancelButton: {
    border: '1px solid rgba(208, 213, 221, 1)',
    backgroundColor: '#ffffff',
    color: '#000000',
    display: 'block',
    width: '50%',
    '&:hover': {
      backgroundColor: '#e1e1e1',
    },
  },
  saveButton: {
    backgroundColor: 'rgba(225, 157, 137, 1)',
    display: 'block',
    width: '50%',
    '&:hover': {
      backgroundColor: 'rgba(249, 112, 102, 1)',
    },
  },
}));

export default function ChangeNameDialog({ open, onClose }: ChangeNameDialogProps) {
  const { room, localTracks } = useVideoContext();
  const localParticipant = room!.localParticipant;
  const [name, setName] = React.useState(displayName(localParticipant.identity));
  const { userData, getUserData, setUserName } = useContext(UserDataContext);
  const cssClasses = useStyle();

  useEffect(() => {
    const name = getUserData(localParticipant.identity)?.name || displayName(localParticipant.identity);
    setName(name);
  }, [userData, localParticipant.identity, getUserData]);

  const onSave = useCallback(() => {
    setUserName(localParticipant.identity, name.trim());
    localTracks
      .filter((track) => track.kind == 'data')
      .forEach((track) => {
        //@ts-ignore
        track.send(JSON.stringify({ command: 'my-name-is', identity: localParticipant.identity, name: name.trim() }));
      });
    onClose();
  }, [name, setUserName]);

  const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  }, []);

  return (
    <Dialog open={open} className={cssClasses.dialog}>
      <DialogTitle>Change Your Name</DialogTitle>
      <DialogContent className={cssClasses.dialogContent}>
        <input value={name} onChange={onChange} className={cssClasses.nameInput} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="contained" type="button" className={cssClasses.cancelButton}>
          Cancel
        </Button>
        <Button
          onClick={onSave}
          color="primary"
          variant="contained"
          type="submit"
          disabled={!name.trim()}
          className={cssClasses.saveButton}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
