import React, { FC } from 'react';
// types
import { IActionListItem } from 'types/app-types';
// components
import MoreDropdown from 'components/molecules/popovers/MoreDropdown';
// styles
import './style.scss';

interface IHomeCard {
  title: string;
  actions?: Array<IActionListItem>;
  children: React.ReactNode;
  noSession?: boolean;
  scrollHeight?: number;
  isCancel?: boolean;
}

const HomeCard: FC<IHomeCard> = ({ title, actions, children, noSession, scrollHeight, isCancel }: IHomeCard) => {
  return (
    <div className="card">
      <div className="card__header">
        <div className="card__title">{title}</div>
        {actions && <MoreDropdown actions={actions} />}
      </div>
      <div className="session-list__items" style={{ maxHeight: scrollHeight || 535 }}>
        {noSession &&
          (isCancel ? (
            <span>To restart your subscription, visit your account settings in the manage subscription tab</span>
          ) : (
            <span>You have no sessions scheduled yet</span>
          ))}
        {children}
      </div>
    </div>
  );
};

export default HomeCard;
