import store from '../store';
import { mapping } from '../../api/mapping/response/tables';
import { ActionCallback, FilterOptionsRole } from '../../types/actions/common-types';
import {
  ClientsActions,
  GetClientsActions,
  LoaderClientsActions,
  LoaderModalClientsActions,
  GetFilterOptionsActions,
  setOptionsIsReceivedActions,
  GetMissingICDClientsActions,
} from '../../types/actions/clients';

const clientsAction: ClientsActions = {
  get(data?: any, callback?: ActionCallback): GetClientsActions {
    return {
      type: 'GET_CLIENTS',
      data,
      callback,
    };
  },
  getMissingICDClients(data?: any, callback?: ActionCallback): GetMissingICDClientsActions {
    return {
      type: 'GET_MISSING_ICD_CLIENTS',
      data,
      callback,
    };
  },
  loader(status: boolean): LoaderClientsActions {
    return {
      type: 'CLIENTS_LOADER',
      payload: {
        loader: status,
      },
    };
  },
  loaderModal(status: boolean): LoaderModalClientsActions {
    return {
      type: 'CLIENTS_LOADER_MODAL',
      payload: {
        loaderModal: status,
      },
    };
  },
  create: (data: any, role: string): void => {
    store.dispatch({
      type: 'CLIENTS',
      payload: {
        items: mapping.clientsBuild(data.items, role),
        count: data.totalCount,
      },
    });
  },
  createMissingICD: (data: any, role: string): void => {
    console.log(data);
    store.dispatch({
      type: 'MISSING_ICD_CLIENTS',
      payload: {
        missingClients: mapping.clientsBuild(data.items, role),
        missingClientCount: data.totalCount,
      },
    });
  },
  clear: (): void => {
    store.dispatch({
      type: 'CLIENTS',
      payload: {
        items: [],
        count: 0,
      },
    });
    store.dispatch({
      type: 'CLIENTS_PARAMS',
      payload: {
        params: {
          page: 1,
          per_page: 10,
          name: '',
        },
      },
    });
  },
  params: (params: any): void => {
    store.dispatch({
      type: 'CLIENTS_PARAMS',
      payload: {
        params,
      },
    });
  },
  rowIndex: (rowIndex?: number): void => {
    store.dispatch({
      type: 'CLIENTS_ROW_INDEX',
      payload: {
        rowIndex,
      },
    });
  },
  setOrder: (order: any): void => {
    store.dispatch({
      type: 'CLIENTS_ORDER',
      payload: {
        order: order,
      },
    });
  },

  getFilterOptions(callback?: ActionCallback): GetFilterOptionsActions {
    return {
      type: 'GET_CLIENTS_FILTER_OPTIONS',
      callback,
    };
  },

  setOptionsIsReceived(optionsIsReceived: FilterOptionsRole): setOptionsIsReceivedActions {
    return {
      type: 'SET_CLIENTS_FILTER_OPTIONS',
      payload: {
        optionsIsReceived,
      },
    };
  },
};

export default clientsAction;
