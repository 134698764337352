import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
// components
import MainTemplate from '../../../components/templates/mainTemplate';
import { HeaderPageContainer, BodyPageContainer } from '../../../components/containers/pageContainer';
import TherapistInfoContainer from '../../../components/containers/therapistInfoContainer/admin';
// actions
import appAction from '../../../redux/actions/app';
import therapistAction from '../../../redux/actions/therapist';

const TherapistPage: React.FC = ({ computedMatch }: any) => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(appAction.page('All Therapists'));
    dispatch(therapistAction.get(computedMatch.params.id));
    dispatch(therapistAction.getGroups(computedMatch.params.id));
    dispatch(therapistAction.getSessionsHistory(computedMatch.params.id));

    return () => {
      therapistAction.clear();
    };
  }, []);

  return (
    <MainTemplate>
      <HeaderPageContainer>
        <h2 className="text-page-header title-back" onClick={() => history.push('/all_therapists')}>
          Account
        </h2>
      </HeaderPageContainer>
      <BodyPageContainer>
        <TherapistInfoContainer paramsId={computedMatch.params.id} />
      </BodyPageContainer>
    </MainTemplate>
  );
};

export default TherapistPage;
