import React, { FC } from 'react';

interface IArbitraryAnswer {
  answerText?: string;
  setAnswerText: (text: string) => void;
  placeholder?: string;
}

const ArbitraryAnswer: FC<IArbitraryAnswer> = ({ answerText, setAnswerText, placeholder }: IArbitraryAnswer) => {
  return (
    <>
      <label htmlFor="area" className="questions__label">
        Please leave your confidential response here
      </label>
      <textarea
        id="area"
        className="questions__textarea"
        value={answerText}
        onChange={(e) => setAnswerText(e.target.value)}
        placeholder={placeholder || 'Type here..'}
      />
    </>
  );
};

export default ArbitraryAnswer;
