import React from 'react';
import Moment from 'react-moment';
import moment from 'moment';
// configs
import { timeConvertor, week_days } from '../../../../configs/tables/admin/clients';
// components
import BegingInWeek from '../../../molecules/beginsIn/BegingInWeek';
// style
import './style.scss';

const SmallGroupCard: React.FC<any> = ({ item, isBegins }: any) => {
  return (
    <div className="small-group-card">
      <div className="small-group-card__avatar">
        {item.group.url ? (
          <img src={item.group.url} alt="avatar" />
        ) : (
          <div className="small-group-card__avatar-txt">{item.group.name[0]}</div>
        )}
      </div>
      <div className="small-group-card__content">
        <div className="small-group-card__title">{`${item.group.name} Group`}</div>
        <div className="small-group-card__info">
          <div className="small-group-card__when">
            <span>When:</span>{' '}
            {`${week_days[item.group.date.dayOfWeek - 1]}s, ${timeConvertor(item.group.date.start)} - 
            ${timeConvertor(item.group.date.end, 'type-time')} ET`}
          </div>
          <div className="small-group-card__therapist">
            Therapist:&nbsp;
            <span>
              {item?.group?.therapist_title?.name ? `${item?.group?.therapist_title.name}.` : ''}{' '}
              {item.group.therapist_name}
            </span>
          </div>
        </div>
        {isBegins && (
          <div className="small-group-card__time">
            <BegingInWeek item={item} />
          </div>
        )}
      </div>
    </div>
  );
};

export default SmallGroupCard;

export const SmallSessionCard: React.FC<any> = ({ item, group }: any) => {
  return (
    <div className="small-group-card">
      <div className="small-group-card__avatar">
        {item?.medicalGroup?.avatar ? (
          <img src={item.medicalGroup.avatar.url} alt="avatar" />
        ) : (
          <div className="small-group-card__avatar-txt">{item.medicalGroup?.groupTopic?.name[0]}</div>
        )}
      </div>
      <div className="small-group-card__content">
        <div className="small-group-card__title">{`${item.medicalGroup.groupTopic.name} Group`}</div>
        <div className="small-group-card__info">
          <div className="small-group-card__when">
            <span>When:</span> <Moment format={'dddd, MMMM Do'}>{item.start}</Moment>
          </div>
          <div className="small-group-card__when">
            <span>Time:</span>
            {` ${moment(item.start).format('h:mm A')} -
            ${moment(item.end).format('h:mm A')} ET`}
          </div>
          <div className="small-group-card__when small-group-card__therapist-item">
            <span>Therapist:</span>&nbsp;
            <span>{`${group?.therapist_title?.name ? `${group.therapist_title.name}. ` : ''}${
              group.therapist_name
            }`}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export const CustomerGroupCard: React.FC<any> = ({ item, group }: any) => {
  return (
    <div className="small-group-card">
      <div className="small-group-card__avatar">
        {item?.medicalGroup?.avatar ? (
          <img src={item.medicalGroup.avatar.url} alt="avatar" />
        ) : (
          <div className="small-group-card__avatar-txt">{item.medicalGroup?.groupTopic?.name[0]}</div>
        )}
      </div>
      <div className="small-group-card__content">
        <div className="small-group-card__title">{`${item.medicalGroup.groupTopic.name} Group`}</div>
        <div className="small-group-card__info">
          <div className="small-group-card__when">
            <span>When:</span> {`${week_days[item?.medicalGroup?.dayOfWeek - 1]}s`}
          </div>
          <div className="small-group-card__when">
            <span>Time:</span>
            {` ${timeConvertor(item?.medicalGroup?.start)} -
            ${timeConvertor(item?.medicalGroup?.end, 'type-time')} ET`}
          </div>
          <div className="small-group-card__when small-group-card__therapist-item">
            <span>Therapist:</span>&nbsp;
            <span>{`${group?.therapist_title?.name ? `${group.therapist_title.name}. ` : ''}${
              group.therapist_name
            }`}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export const RestartSuccessGroupCard: React.FC<any> = ({ item }: any) => {
  const beginsItem = {
    group: {
      date: {
        dayOfWeek: item?.dayOfWeek,
        end: item?.end,
      },
    },
  };

  return (
    <div className="small-group-card">
      <div className="small-group-card__avatar">
        {item?.avatar?.url ? (
          <img src={item.avatar.url} alt="avatar" />
        ) : (
          <div className="small-group-card__avatar-txt">{item?.groupTopic?.name[0]}</div>
        )}
      </div>
      <div className="small-group-card__content">
        <div className="small-group-card__title">{`${item?.groupTopic?.name} Group`}</div>
        <div className="small-group-card__info">
          <div className="small-group-card__when">
            <span>When:</span>{' '}
            {`${week_days[item?.dayOfWeek - 1]}s, ${timeConvertor(item?.start)} - 
            ${timeConvertor(item?.end, 'type-time')} ET`}
          </div>
          <div className="small-group-card__therapist">
            Therapist:&nbsp;
            <span>
              {item?.therapist?.title?.name ? `${item?.group?.therapist?.title?.name}.` : ''}{' '}
              {item?.therapist?.firstname} {item?.therapist?.lastname}
            </span>
          </div>
        </div>
        <div className="small-group-card__time">
          <BegingInWeek item={beginsItem} />
        </div>
      </div>
    </div>
  );
};

export const TimeGroupCard: React.FC<any> = ({ medicalGroup }: any) => {
  const beginsItem = {
    group: {
      date: {
        dayOfWeek: medicalGroup?.dayOfWeek,
        end: medicalGroup?.end,
      },
    },
  };

  return (
    <div className="small-group-card">
      <div className="small-group-card__avatar">
        {medicalGroup?.avatar ? (
          <img src={medicalGroup.avatar.url} alt="avatar" />
        ) : (
          <div className="small-group-card__avatar-txt">{medicalGroup?.groupTopic?.name[0]}</div>
        )}
      </div>
      <div className="small-group-card__content">
        <div className="small-group-card__title">{`${medicalGroup ? medicalGroup?.groupTopic?.name : ''} Group`}</div>
        <div className="small-group-card__info">
          <div className="small-group-card__when">
            <span>Time: </span>
            {medicalGroup ? (
              <>
                {`${week_days[medicalGroup?.dayOfWeek - 1]}s`},
                {` ${timeConvertor(medicalGroup?.start)} - ${timeConvertor(medicalGroup?.end)} ET`}
              </>
            ) : (
              ''
            )}
          </div>
          <div className="small-group-card__time">
            <BegingInWeek item={beginsItem} />
          </div>
        </div>
      </div>
    </div>
  );
};
