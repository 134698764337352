import React from 'react';
import clsx from 'clsx';
import { Link } from '@material-ui/core';
import linkify from 'linkify-it';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  messageContainer: {
    borderRadius: '12px',
    display: 'inline-flex',
    alignItems: 'center',
    padding: '8px 16px',
    margin: '0 0 0 18px',
    wordBreak: 'break-word',
    backgroundColor: 'rgba(62, 78, 97, 0.08)',
    hyphens: 'auto',
    whiteSpace: 'pre-wrap',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
    color: '#333333',
    width: 'calc(100% - 48px)',
  },
  isLocalParticipant: {
    backgroundColor: 'rgba(230, 242, 247, 1)',
  },
  isFromTherapist: {
    backgroundColor: 'rgba(242, 220, 213, 1)',
  },
});

interface TextMessageProps {
  body: string;
  isLocalParticipant: boolean;
}

function addLinks(text: string) {
  const matches = linkify().match(text);
  if (!matches) return text;

  const results = [];
  let lastIndex = 0;

  matches.forEach((match, i) => {
    results.push(text.slice(lastIndex, match.index));
    results.push(
      <Link target="_blank" rel="noreferrer" href={match.url} key={i}>
        {match.text}
      </Link>,
    );
    lastIndex = match.lastIndex;
  });

  results.push(text.slice(lastIndex, text.length));

  return results;
}

export default function TextMessage({ body, isLocalParticipant }: TextMessageProps) {
  const classes = useStyles();

  return (
    <div>
      <div
        className={clsx(classes.messageContainer, {
          [classes.isLocalParticipant]: isLocalParticipant,
        })}
      >
        <div>{addLinks(body)}</div>
      </div>
    </div>
  );
}
