import React, { FC, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { AnimatePresence, motion } from 'framer-motion';
import { Modal } from 'antd';
import { useHistory } from 'react-router-dom';
import Moment from 'react-moment';
// components
import SslFooter from 'components/organisms/stripe/form/SslFooter';
// helpers
import { calculatePrising, capitalizeText } from 'tools/helpFunctions';
import { animScale } from 'tools/animations';
// types
import { stateType } from 'types/types';
// icons
import afterImg from 'assets/image/questions/after.png';
// actions
import stripeAction from '../../../../../redux/actions/stripe';
// styles
import './style.scss';
import eventAnalytics from '../../../../../redux/actions/analytics';

const SummaryChange: FC = () => {
  const history = useHistory();
  const info: any = useSelector((state: stateType) => state.account.info, shallowEqual);
  const items: any = useSelector((state: stateType) => state.stripe.items, shallowEqual);
  const sub: any = useSelector((state: stateType) => state.stripe.item, shallowEqual);
  const payment = calculatePrising({ items, sub });
  const [visible, setVisible] = useState(false);

  const dispatch = useDispatch();

  const dispatchCallback = () => setVisible(true);

  const confirmBilling = () => {
    dispatch(stripeAction.change({ priceId: sub.stripePriceId }, dispatchCallback));
  };

  useEffect(() => {
    dispatch(eventAnalytics.analytics('billing-change-view', { productPeriodName: sub?.productPeriodName }));
  }, [sub?.productPeriodName]);

  return (
    <motion.div className="payment payment_light" variants={animScale} initial="initial" animate="visible" exit="exit">
      <div className="payment__title">Summary</div>
      <div className="payment__summary-list">
        <div className="payment__item item-payment">
          <div className="item-payment__name">
            {payment.monthsCount} Month billing ({payment.monthsCount * 4} x ${payment.defaultMontPrice / 4})
          </div>
          <div className="item-payment__sum">${payment.totalPrice}</div>
        </div>
        <AnimatePresence initial={false}>
          {sub?.saveAmountPercent && (
            <motion.div
              className="payment__item item-payment saving"
              variants={animScale}
              initial="initial"
              animate="visible"
              exit="exit"
            >
              <div className="item-payment__name">
                {capitalizeText(sub?.productPeriodName)} billing discount <span>({sub?.saveAmountPercent}% off)</span>
              </div>
              <div className="item-payment__sum">-${sub?.saveAmount / 100}</div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      {sub?.saveAmount / 100 ? (
        <div className="payment__savigns">
          <div className="item-payment saving">
            <div className="item-payment__name">Your savings</div>
            <div className="item-payment__sum">-${sub?.saveAmount / 100}</div>
          </div>
        </div>
      ) : null}
      <div className="payment__total">
        <div className="item-payment total">
          <div className="item-payment__name">Total due today</div>
          {/*TODO change promo summ*/}
          <div className="item-payment__sum">${sub?.amount / 100}</div>
        </div>
      </div>
      <button className="payment__confirm btn-form" onClick={confirmBilling}>
        Confirm billing change
      </button>
      <SslFooter />
      <Modal className="confirm-billing" visible={visible} closable={false} centered footer={null}>
        <div className="confirm-billing__body">
          <img src={afterImg} alt="" />
          <p className="confirm-billing__title">You’ve successfully changed your billing schedule! </p>
          <p className="confirm-billing__sub-title">
            Your {capitalizeText(sub?.productPeriodName)} billing cycle change will take effect on{' '}
            <Moment format="MMMM D, YYYY">{info.nextRenewalDate}</Moment>
          </p>
          <button className="confirm-billing__btn btn-form" onClick={() => history.push('/')}>
            ok
          </button>
        </div>
      </Modal>
    </motion.div>
  );
};

export default SummaryChange;
