import React, { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { Button, Grid, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { Conversation } from '@twilio/conversations/lib/conversation';
import FileAttachmentIcon from '../../../../icons/FileAttachmentIcon';
import { isMobile } from '../../../../utils';
import SendMessageIcon from '../../../../icons/SendMessageIcon';
import Snackbar from '../../Snackbar/Snackbar';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { useAppState } from '../../../../state';
import { PrivateConversation } from '../../ChatProvider';
import Loader from '../../Loader/Loader';
import useParticipants from '../../../../hooks/useParticipants/useParticipants';
import EmojiPicker from 'emoji-picker-react';
import useUserDataContext from '../../../../hooks/useUserDataContext/useUserDataContext';

const useStyles = makeStyles((theme) => ({
  chatInputContainer: {
    borderBottom: '1px solid #e4e7e9',
    padding: '1em 1.2em 1em',
  },
  textArea: {
    width: '100%',
    border: '1px solid rgba(208, 213, 221, 1)',
    borderRadius: 8,
    padding: '10px 14px 10px 30px',
    resize: 'none',
    fontSize: '14px',
    outline: 'none',
  },
  button: {
    padding: '10px 21px',
    minWidth: 'auto',
    height: '35px',
    margin: '0 0 0 8px',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    backgroundColor: 'rgba(225, 157, 137, 1)',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: 'rgba(249, 112, 102, 1)',
    },
    '&:disabled': {
      backgroundColor: 'rgba(225, 157, 137, 1)',
      color: '#ffffff',
      '& path': {
        fill: '#d8d8d8',
      },
    },
  },
  buttonContainer: {
    margin: '1em 0 0 1em',
    display: 'flex',
  },
  fileButtonContainer: {
    position: 'relative',
    marginRight: '1em',
  },
  fileButtonLoadingSpinner: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  textAreaContainer: {
    display: 'flex',
    marginTop: '0.4em',
    padding: '0',
    border: '2px solid transparent',
  },
  isTextareaFocused: {
    borderRadius: '4px',
  },
  chatSelect: {
    backgroundColor: '#ffffff',
    padding: '8px 14px',
    border: '1px solid rgba(208, 213, 221, 1)',
    borderRadius: 8,
    width: 274,
  },
  emojiAnchor: {
    position: 'relative',
    height: 0,
  },
  emojiContainer: {
    position: 'absolute',
    bottom: 0,
  },
  emojiIcon: {
    position: 'absolute',
    top: 8,
    left: 8,
  },
}));

interface ChatInputProps {
  conversation: Conversation;
  isChatWindowOpen: boolean;
  activeChatTab: string;
  setActiveChatTab: Dispatch<SetStateAction<string>>;
  privateConversations: PrivateConversation[];
}

const ALLOWED_FILE_TYPES =
  'audio/*, image/*, text/*, video/*, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document .xslx, .ppt, .pdf, .key, .svg, .csv';

export default function ChatInput({
  conversation,
  isChatWindowOpen,
  activeChatTab,
  setActiveChatTab,
  privateConversations,
}: ChatInputProps) {
  const classes = useStyles();
  const [messageBody, setMessageBody] = useState('');
  const [isSendingFile, setIsSendingFile] = useState(false);
  const [fileSendError, setFileSendError] = useState<string | null>(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const isValidMessage = /\S/.test(messageBody);
  const textInputRef = useRef<HTMLTextAreaElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isTextareaFocused, setIsTextareaFocused] = useState(false);
  const { meetingType } = useAppState();
  const participants = useParticipants();
  const { getUserName } = useUserDataContext();

  useEffect(() => {
    if (isChatWindowOpen) {
      // When the chat window is opened, we will focus on the text input.
      // This is so the user doesn't have to click on it to begin typing a message.
      textInputRef.current?.focus();
    }
  }, [isChatWindowOpen]);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessageBody(event.target.value);
  };

  // ensures pressing enter + shift creates a new line, so that enter on its own only sends the message:
  const handleReturnKeyPress = (event: React.KeyboardEvent) => {
    if (!isMobile && event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage(messageBody);
    }
  };

  const handleSendMessage = (message: string) => {
    if (isValidMessage) {
      conversation.sendMessage(message.trim());
      setMessageBody('');
    }
  };

  const handleSendFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const formData = new FormData();
      formData.append('userfile', file);
      setIsSendingFile(true);
      setFileSendError(null);
      conversation
        .sendMessage(formData)
        .catch((e) => {
          if (e.code === 413) {
            setFileSendError('File size is too large. Maximum file size is 150MB.');
          } else {
            setFileSendError('There was a problem uploading the file. Please try again.');
          }
          console.log('Problem sending file: ', e);
        })
        .finally(() => {
          setIsSendingFile(false);
        });
    }
  };

  const chatSelectChange = useCallback((event) => {
    console.log(event.target.value);
    setActiveChatTab(event.target.value);
  }, []);

  const emojiClick = useCallback(
    (emoji) => {
      console.log(emoji);
      setMessageBody(messageBody + emoji.emoji);
      setShowEmojiPicker(false);
    },
    [messageBody],
  );

  return (
    <div className={classes.chatInputContainer}>
      <Snackbar
        open={Boolean(fileSendError)}
        headline="Error"
        message={fileSendError || ''}
        variant="error"
        handleClose={() => setFileSendError(null)}
      />
      <div>
        {meetingType != 'webinar' ? (
          <select value={activeChatTab} onChange={chatSelectChange} className={classes.chatSelect}>
            <option value="main">Everyone</option>
            {privateConversations?.map((item) => {
              return participants.find((participant) => {
                return participant.identity == item.identity;
              }) ? (
                <option key={item.identity} value={item.identity}>
                  {getUserName(item.identity)}
                </option>
              ) : (
                ''
              );
            })}
          </select>
        ) : (
          ''
        )}
      </div>
      <div className={clsx(classes.textAreaContainer, { [classes.isTextareaFocused]: isTextareaFocused })}>
        {/* 
        Here we add the "isTextareaFocused" class when the user is focused on the TextareaAutosize component.
        This helps to ensure a consistent appearance across all browsers. Adding padding to the TextareaAutosize
        component does not work well in Firefox. See: https://github.com/twilio/twilio-video-app-react/issues/498
        */}
        <div className={classes.emojiAnchor}>
          <div className={classes.emojiContainer} style={{ display: showEmojiPicker ? 'block' : 'none' }}>
            <EmojiPicker width={280} onEmojiClick={emojiClick} />
          </div>
          <svg
            className={classes.emojiIcon}
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => {
              setShowEmojiPicker(!showEmojiPicker);
            }}
          >
            <path
              d="M6.66675 12.168C6.66675 12.168 7.91675 13.8346 10.0001 13.8346C12.0834 13.8346 13.3334 12.168 13.3334 12.168M7.50008 8.0013H7.50842M12.5001 8.0013H12.5084M18.3334 10.5013C18.3334 15.1037 14.6025 18.8346 10.0001 18.8346C5.39771 18.8346 1.66675 15.1037 1.66675 10.5013C1.66675 5.89893 5.39771 2.16797 10.0001 2.16797C14.6025 2.16797 18.3334 5.89893 18.3334 10.5013Z"
              stroke="#E19D89"
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <TextareaAutosize
          minRows={1}
          maxRows={3}
          className={classes.textArea}
          aria-label="chat input"
          placeholder="Type..."
          onKeyPress={handleReturnKeyPress}
          onChange={handleChange}
          value={messageBody}
          data-cy-chat-input
          ref={textInputRef}
          onFocus={() => setIsTextareaFocused(true)}
          onBlur={() => setIsTextareaFocused(false)}
        />
        <Button
          className={classes.button}
          onClick={() => handleSendMessage(messageBody)}
          variant="contained"
          disabled={!isValidMessage}
          data-cy-send-message-button
        >
          Send
        </Button>
      </div>

      <Grid container alignItems="flex-end" justifyContent="flex-end" wrap="nowrap">
        {/* Since the file input element is invisible, we can hardcode an empty string as its value.
        This allows users to upload the same file multiple times. */}
        <input
          ref={fileInputRef}
          type="file"
          style={{ display: 'none' }}
          onChange={handleSendFile}
          value={''}
          accept={ALLOWED_FILE_TYPES}
        />
        <div className={classes.buttonContainer}>
          <div className={classes.fileButtonContainer}>{isSendingFile && <Loader />}</div>
        </div>
      </Grid>
    </div>
  );
}
