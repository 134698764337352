/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { fixDayOfWeek, ParseArray } from '../../../../tools/helpFunctions';
export const superbillMapping = {
  superbillInfoBuild(data) {
    let startDate = null;
    if (!data?.payments?.length) {
      startDate = null;
    } else {
      startDate = new Date(data.payments[0].date);
      var payments = data.payments;
      for (let i = 0; i < payments.length; i++) {
        const date = new Date(payments[i].date);
        if (date.getTime() < startDate.getTime()) {
          startDate = date;
        }
        if (payments[i].amount == 37800) {
          for (let j = 0; j < 3; j++) {
            var newDate = new Date(date.getTime());
            newDate = new Date(newDate.setMonth(newDate.getMonth() + j));
            payments.push({ date: newDate.toString(), amount: 12600 });
          }
          payments.splice(i, 1);
          i--;
        } else if (payments[i].amount == 31500) {
          for (let j = 0; j < 3; j++) {
            var newDate = new Date(date.getTime());
            newDate = new Date(newDate.setMonth(newDate.getMonth() + j));
            if (j == 0) payments.push({ date: newDate.toString(), amount: 6300 });
            else payments.push({ date: newDate.toString(), amount: 12600 });
          }
          payments.splice(i, 1);
          i--;
        } else if (payments[i].amount == 67200) {
          for (let j = 0; j < 6; j++) {
            var newDate = new Date(date.getTime());
            newDate = new Date(newDate.setMonth(newDate.getMonth() + j));
            payments.push({ date: newDate.toString(), amount: 11200 });
          }
          payments.splice(i, 1);
          i--;
        } else if (payments[i].amount == 61600) {
          for (let j = 0; j < 6; j++) {
            var newDate = new Date(date.getTime());
            newDate = new Date(newDate.setMonth(newDate.getMonth() + j));
            if (j == 0) payments.push({ date: newDate.toString(), amount: 5600 });
            else payments.push({ date: newDate.toString(), amount: 11200 });
          }
          payments.splice(i, 1);
          i--;
        }
      }
    }
    payments = payments ? payments.sort((a, b) => new Date(a.date) - new Date(b.date)) : [];
    return {
      ...data,
      dayOfWeek: data?.medicalGroup.dayOfWeek,
      startDate,
      medicalGroup: data?.medicalGroup
        ? {
            ...data.medicalGroup,
            dayOfWeek: fixDayOfWeek(data.medicalGroup.dayOfWeek, data.medicalGroup.start, 'to_ny'),
          }
        : null,
      payments,
    };
  },
};
