import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { Select } from 'antd';
// components
import FilterBox from '../../../../molecules/filters/filterBox';
import {
  FilterGroupTopicField,
  FilterStatusesField,
  FilterTherapistsField,
  FilterWeekDaysField,
} from '../../../../molecules/filters/foTables';
import InputField from '../../../../molecules/inputField';
// actions
import clientsAction from '../../../../../redux/actions/clients';
// types
import { stateType } from 'types/types';
import { FilterOptionsRole } from '../../../../../types/actions/common-types';
// styles
import './style.scss';

const FilterClients: React.FC<any> = ({ setVisible }: any) => {
  const params: any = useSelector((state: stateType) => state.clients.params, shallowEqual);
  const optionsIsReceived: string = useSelector((state: stateType) => state.clients.optionsIsReceived);
  const role = useSelector((state: any) => state.user.profile.role, shallowEqual);

  const { watch, reset, setValue, control } = useForm();

  const options: any = useSelector((state: stateType) => state.options.specificGroup);
  const dispatch = useDispatch();

  const isAdmin = role === 'ADMIN';

  const specificGroup = watch('specificGroup');

  useEffect(() => {
    ((isAdmin && optionsIsReceived !== FilterOptionsRole.Admin) ||
      (!isAdmin && optionsIsReceived !== FilterOptionsRole.Therapist)) &&
      dispatch(clientsAction.getFilterOptions());

    setValue('specificGroup', params.group_id);
  }, []);

  useEffect(() => {
    clientsAction.params({ ...params, group_id: specificGroup });
  }, [specificGroup]);

  const onChange = (type: string, values: any) => {
    switch (type) {
      case 'topic':
        clientsAction.params({ ...params, group_topic_ids: values });
        break;
      case 'week':
        clientsAction.params({ ...params, days_of_week: values });
        break;
      case 'statuses':
        clientsAction.params({ ...params, statuses: values });
        break;
      case 'therapist':
        clientsAction.params({ ...params, therapist_id: values });
        break;

      default:
        break;
    }
  };

  const onSubmit = () => {
    clientsAction.params({
      ...params,
      page: 1,
      per_page: 10,
    });
    dispatch(clientsAction.get());
    setVisible(false);
  };

  const clearAll = () => {
    reset();
    clientsAction.params({
      ...params,
      page: 1,
      per_page: 10,
      group_id: undefined,
      group_topic_ids: [],
      days_of_week: [],
      statuses: [],
      therapist_id: [],
    });
    setVisible(false);
    dispatch(clientsAction.get());
  };

  const onClearSelect = () => {
    reset();
  };
  return (
    <div className="filters-container">
      <div className="filters-container__title">Filters</div>

      <div className="filters-container__body">
        <FilterBox title="Specific Group" type="select">
          <InputField name={'specificGroup'} errors={undefined} icon={false}>
            <Controller
              control={control}
              name={'specificGroup'}
              render={({ field: { onChange, onBlur, value } }) => (
                <Select
                  className={'form-select'}
                  dropdownClassName="form-dropdown"
                  placeholder={'Select Group'}
                  style={{ width: '100%' }}
                  onChange={onChange}
                  onBlur={onBlur}
                  showSearch
                  searchValue={''}
                  options={options}
                  allowClear
                  onClear={onClearSelect}
                  defaultValue={params.group_id}
                  onDropdownVisibleChange={(open) => !open && onBlur()}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  value={value}
                />
              )}
            />
          </InputField>
        </FilterBox>
        <div className="filters-container__line" />
        {isAdmin && (
          <>
            <FilterBox title="Therapist" type="select">
              <FilterTherapistsField change={(e: any) => onChange('therapist', e)} defaultValue={params.therapist_id} />
            </FilterBox>
            <div className="filters-container__line" />
          </>
        )}
        <FilterBox title="Group Topic">
          <FilterGroupTopicField change={(e: any) => onChange('topic', e)} defaultValue={params.group_topic_ids} />
        </FilterBox>
        <div className="filters-container__line" />
        <FilterBox title="Day of Session">
          <FilterWeekDaysField change={(e: any) => onChange('week', e)} defaultValue={params.days_of_week} />
        </FilterBox>
        <div className="filters-container__line" />
        <FilterBox title="Client Status">
          <FilterStatusesField change={(e: any) => onChange('statuses', e)} defaultValue={params.statuses} />
        </FilterBox>
      </div>
      <div className="filters-container__footer">
        <button className="btn-form" onClick={onSubmit}>
          Apply filters
        </button>
        <button className="btn-clear-all" onClick={clearAll}>
          Clear all
        </button>
      </div>
    </div>
  );
};

export default FilterClients;
