import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
// components
import LoadingPage from './loading';

interface LayoutDefault {
  component: React.ComponentType<RouteProps>;
  path?: string;
  exact?: boolean;
  role?: string;
}

const LayoutAlternativeAuth: React.FC<LayoutDefault> = ({ component: Component, ...args }: LayoutDefault) => {
  const auth = useSelector((state: any) => state.app.auth);
  return (
    <Route
      render={() => {
        if (auth === 'login' || auth === 'logout') {
          return (
            <Route
              path={args.path}
              render={() => {
                return <Component {...args} />;
              }}
            />
          );
        } else if (auth === 'loading') {
          return <LoadingPage />;
        } else {
          return <Redirect to={'/'} />;
        }
      }}
    />
  );
};

export default LayoutAlternativeAuth;
