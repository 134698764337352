import React from 'react';

export default function LoadingIcon() {
  return (
    <svg width="35" height="36" viewBox="0 0 35 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.5 7V1.5" stroke="#79716B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M25.292 10.2073L29.2337 6.26562"
        stroke="#79716B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M28.5 18H34" stroke="#79716B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M17.5 29V34.5" stroke="#79716B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M9.70827 25.793L5.7666 29.7346"
        stroke="#79716B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M6.5 18H1" stroke="#79716B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M9.70827 10.2073L5.7666 6.26562"
        stroke="#79716B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
