import React from 'react';

// components
import SimpleHeader from '../../organisms/headers/simple/simple-header';
// types
import { CardType } from '../../../types/commonPropTypes';
// styles
import './style.scss';

const AssessmentProgressTemplate: React.FC<CardType> = ({ children }: CardType) => {
  return (
    <div className="simple-template">
      <SimpleHeader />
      <div className="simple-template__assessment-body">
        <div className="progress-template__container">{children}</div>
      </div>
    </div>
  );
};

export default AssessmentProgressTemplate;
