/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { AxiosResponse } from 'axios';
// config
import http from '../instance';

export const api = {
  get: async (role: string, params: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.get(`${role}/groups${params ? `?${params}` : ''}`);
  },

  getByID: async (role: string, id: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.get(`${role}/group/${id}/details`);
  },

  reschedule: async (role: string, id: string, data: any): Promise<AxiosResponse> => {
    const api = await http();
    return api.patch(`${role}/group/${id}/reschedule`, data);
  },

  edit: async (role: string, id: string, data: any): Promise<AxiosResponse> => {
    const api = await http();
    return api.patch(`${role}/group/${id}/details`, data);
  },

  archive: async (role: string, id: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.delete(`${role}/group/${id}/archive`);
  },

  cansel: async (role: string, id: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.patch(`${role}/group/${id}/cancel`);
  },

  restart: async (role: string, id: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.patch(`${role}/group/${id}/restart`);
  },

  addClient: async (role: string, groupId: string, customerId: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.patch(`${role}/group/${groupId}/add/client/${customerId}`);
  },

  removeClient: async (role: string, groupId: string, customerId: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.patch(`${role}/group/${groupId}/remove/client/${customerId}`);
  },

  getTopics: async (role: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.get(`customer/admin/group-topics`);
  },

  getTherapists: async (): Promise<AxiosResponse> => {
    const api = await http();
    return api.get(`admin/brief-therapists?all`);
  },

  created: async (role: string, data: any): Promise<AxiosResponse> => {
    const api = await http();
    return api.post(`${role}/group`, data);
  },

  search: async (role: string, value: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.get(`${role}/group-search-clients?name=${value}&per_page=10`);
  },
};
