import React, { useState, useEffect } from 'react';
import { Spin } from 'antd';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import _ from 'lodash';
import Moment from 'react-moment';
import cn from 'classnames';
import { useWindowResize } from 'beautiful-react-hooks';
import { useParams } from 'react-router-dom';

// components
import { SessionStatus } from '../../organisms/lists/history/items';
import MoreDropdown from '../../molecules/popovers/MoreDropdown';
// actions
import historyAction from '../../../redux/actions/history';
// types
import { stateType } from '../../../types/types';
// tools
import { MAX_WIDTH } from '../../../tools/consts';
import { timeConvertor, week_days } from '../../../configs/tables/admin/clients';
import { createLicenseList } from '../../../tools/helpFunctions';
// styles
import './style.scss';
// icons
import manIcon from '../../../assets/image/dropdown/man.svg';
import timeIcon from '../../../assets/image/dropdown/time.svg';

const HistoryContainer: React.FC = () => {
  const [focus, setFocus] = useState(0);
  const [width, setWidth] = useState(window.innerWidth);
  const params = useParams<{ id: string }>();

  const groups: any = useSelector((state: stateType) => state.history.historyGroups, shallowEqual);
  const groupsParams: any = useSelector((state: stateType) => state.history.historyGroupsParams, shallowEqual);
  const groupsTotalCount: any = useSelector((state: stateType) => state.history.historyGroupsTotalCount, shallowEqual);
  const sessions: any = useSelector((state: stateType) => state.history.historySessions, shallowEqual);
  const sessionsParams: any = useSelector((state: stateType) => state.history.historySessionsParams, shallowEqual);
  const sessionsTotalCount: any = useSelector(
    (state: stateType) => state.history.historySessionsTotalCount,
    shallowEqual,
  );
  const loader: any = useSelector((state: stateType) => state.history.loader, shallowEqual);

  useWindowResize(() => {
    setWidth(window.innerWidth);
  });

  const isFullDate = width >= MAX_WIDTH;

  const dispatch = useDispatch();

  const action = (id: any) => {
    setFocus(id);
    historyAction.historySessionsParams({ ...sessionsParams, per_page: 4 });
    dispatch(historyAction.getHistorySessions(id));
  };

  useEffect(() => {
    groups?.[0]?.id && action(groups[0].id);
  }, []);

  const onShowAllGroup = () => {
    historyAction.historyGroupsParams({ ...groupsParams, per_page: groupsTotalCount });
    dispatch(historyAction.getHistoryGroups(params.id));
  };

  const onShowAllSessions = () => {
    historyAction.historySessionsParams({ ...sessionsParams, per_page: sessionsTotalCount });
    dispatch(historyAction.getHistorySessions(focus));
  };

  return (
    <div className="history-container history-container_admin">
      <Spin spinning={loader}>
        <>
          <div className="history-container_admin__title">Group History</div>
          <div
            className={cn('history-container__box history-container__box_group', {
              'history-container__box_group_lg': groups.length > 2 && groups.length === groupsTotalCount,
            })}
          >
            {(groups.length === 0 || Array.isArray(groups[0])) && (
              <span className="history-container__text">List is empty</span>
            )}
            {groups.length > 0 && !Array.isArray(groups[0]) ? (
              <GroupList items={groups} action={action} focus={focus} isFullDate={isFullDate} />
            ) : null}
            {groups.length > 0 && groups.length < groupsTotalCount && (
              <div className="history-container__btn">
                <button type="button" onClick={onShowAllGroup}>
                  Show all group history
                </button>
              </div>
            )}
          </div>

          <div className="history-container_admin__title">Session History</div>
          <div className="history-container__box history-container__box_session">
            {sessions.length === 0 && <span className="history-container__text">List is empty</span>}
            {sessions.length > 0 && <SessionList items={sessions} isFullDate={isFullDate} />}
            {sessions.length > 0 && sessions.length < sessionsTotalCount && (
              <div className="history-container__btn" onClick={onShowAllSessions}>
                <button type="button">Show all session history</button>
              </div>
            )}
          </div>
        </>
      </Spin>
    </div>
  );
};

export default HistoryContainer;

const GroupList = ({ items, action, focus, isFullDate }: any) => {
  return (
    <div className="history-list" style={{ columnCount: items.length > 1 ? 2 : 1 }}>
      {items &&
        items.map((item: any) => {
          return (
            <CardHistoryGroupItem key={item.id} group={item} action={action} focus={focus} isFullDate={isFullDate} />
          );
        })}
    </div>
  );
};

export const CardHistoryGroupItem: React.FC<any> = ({ group, action, focus, isFullDate }: any) => {
  const { medicalGroup } = group;

  const actions = [
    {
      text: (
        <>
          <p style={{ color: '#696974' }}>Time:</p>
          <p>
            {week_days[medicalGroup.dayOfWeek - 1]}{' '}
            {timeConvertor(medicalGroup.start, 'small-format') +
              ' - ' +
              timeConvertor(medicalGroup.end, 'small-format') +
              ' ET'}
          </p>
        </>
      ),
      icon: timeIcon,
    },
    {
      text: (
        <>
          <p style={{ color: '#696974' }}>Therapist:</p>
          <p>
            {medicalGroup?.therapist?.title ? `${medicalGroup?.therapist?.title}. ` : ''}
            {medicalGroup?.therapist?.firstname} {medicalGroup?.therapist?.lastname}{' '}
            {medicalGroup?.therapist?.licenses.length && createLicenseList(medicalGroup?.therapist?.licenses, true)}
          </p>
        </>
      ),
      icon: manIcon,
    },
  ];

  return (
    <div
      className={cn('group-history-item group-history-item_group', { 'group-history-item_focus': focus === group.id })}
      onClick={() => action(group.id)}
    >
      {!_.isEmpty(group) && (
        <>
          <div className="group-history-item__header">
            <div className={cn('group-history-item__type', { past: group?.current })}>
              {group?.current ? `Current` : 'Past'}
            </div>
            <div className="group-history-item__more" onClick={(e) => e.stopPropagation()}>
              <MoreDropdown actions={actions} />
            </div>
          </div>
          <div className="group-history-item__body">
            <div className="group-history-item__avatar">
              {group.medicalGroup.avatar ? (
                <img src={group.medicalGroup.avatar.url} alt="" />
              ) : (
                <div className="group-history-item__avatar-txt">{group.medicalGroup.groupTopic.name[0]}</div>
              )}
            </div>
            <div className="group-history-item__info">
              <div className="group-history-item__title">{group.medicalGroup.groupTopic.name}</div>
              <div className="group-history-item__about">
                {group.joinedAt && (
                  <>
                    <div className="group-history-item__label">Start Date:</div>
                    <p className="group-history-item__about-text">
                      {
                        <Moment format={isFullDate ? 'dddd, MMMM Do, YYYY' : 'ddd., MMM. D, YYYY'}>
                          {group.joinedAt}
                        </Moment>
                      }
                    </p>
                  </>
                )}
                {group.detachAt && (
                  <>
                    <div className="group-history-item__label">End Date:</div>
                    <p className="group-history-item__about-text">
                      {
                        <Moment format={isFullDate ? 'dddd, MMMM Do, YYYY' : 'ddd., MMM. D, YYYY'}>
                          {group.detachAt}
                        </Moment>
                      }
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const SessionList = ({ items, isFullDate }: any) => {
  const initDay = (date: any) => {
    const result: any = new Date().getTime() - new Date(date).getTime();
    const days: any = result / 86400000;

    if (days > 0) {
      if (days < 1) {
        return `Today`;
      }
      return `${parseInt(days)} Days ago`;
    }

    return '';
  };
  return (
    <div className="history-list" style={{ columnCount: items.length > 1 ? 2 : 1 }}>
      {items &&
        items.map((item: any, index: number) => {
          const { groupSessionHistory } = item;

          return (
            <div className="group-history-item" key={index}>
              <div className="group-history-item__body">
                <div className="group-history-item__avatar">
                  {groupSessionHistory.medicalGroup?.avatar ? (
                    <img src={groupSessionHistory.medicalGroup.avatar.url} alt="" />
                  ) : (
                    <div className="group-item__avatar-txt">{groupSessionHistory.medicalGroup.groupTopic.name[0]}</div>
                  )}
                </div>
                <div className="group-history-item__info">
                  <div className="group-history-item__title">{groupSessionHistory.medicalGroup.groupTopic.name}</div>
                  <div className="group-history-item__about">
                    <span className="group-history-item__about-text">
                      <Moment format={isFullDate ? 'dddd, MMMM Do, YYYY' : 'ddd., MMM. D, YYYY'}>
                        {groupSessionHistory.start}
                      </Moment>
                    </span>

                    <p className="group-history-item__about-text">
                      <span className="group-history-item__label">Time:</span>{' '}
                      <Moment format="h:mma">{groupSessionHistory.start}</Moment>
                      {' - '}
                      <Moment format="h:mma">{groupSessionHistory.end}</Moment>
                    </p>
                  </div>
                </div>
              </div>
              <div className="group-history-item__footer">
                <span className="group-history-item__day-name">{initDay(groupSessionHistory.end)}</span>
                <SessionStatus status={groupSessionHistory.status} />
              </div>
            </div>
          );
        })}
    </div>
  );
};
