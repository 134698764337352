import React, { FC, useEffect, useState } from 'react';
import moment from 'moment-timezone';
// icons
import { ReactComponent as IconClock } from 'assets/image/questions/clock.svg';
// styles
import './style.scss';

interface IBeginsIn {
  start: string;
  status?: {
    name: string;
  };
  isAddBackground?: boolean;
}

const BeginsIn: FC<IBeginsIn> = ({ start, status, isAddBackground }: IBeginsIn) => {
  const [result, setResult] = useState(0);

  const startTimestamp = moment(start).tz('America/New_York', true).format('x');

  useEffect(() => {
    setResult(+startTimestamp - new Date().getTime());
  }, [start]);

  const days = result / 86400000;
  const hours = moment(start).tz('America/New_York', true).diff(moment().tz('America/New_York'), 'hours');
  let beginsStatus = '';

  if (parseInt(String(days / 7)) === 2) {
    beginsStatus = 'two-weeks';
  }

  if (parseInt(String(days / 7)) >= 3) {
    beginsStatus = 'three-weeks';
  }

  if (parseInt(String(days / 7)) >= 4) {
    beginsStatus = 'four-weeks';
  }

  const textGenerator = () => {
    if (result < 0 && status) {
      return `Session ${status.name}`;
    }

    if (result > 0) {
      if (parseInt(String(days)) === 0) {
        return hours ? `Begins in ${hours} hour${hours > 1 ? 's' : ''}` : 'Begins soon';
      }

      if (parseInt(String(days)) >= 1 && parseInt(String(days)) < 2 && parseInt(String(hours)) <= 24) {
        return `Begins tomorrow`;
      }

      if (parseInt(String(days)) >= 1 && parseInt(String(days)) < 2) {
        return `Begins in 1 days and ${hours - 24} hour${hours > 1 ? 's' : ''}`;
      }

      if (parseInt(String(days)) > 1 && parseInt(String(days / 7)) < 2) {
        return `Begins in ${parseInt(String(days))} days`;
      }

      if (parseInt(String(days)) >= 14) {
        return `Begins in ${parseInt(String(days / 7))} weeks`;
      }
    }
  };
  return (
    <div className={`begins ${beginsStatus} ${isAddBackground ? 'background' : ''}`}>
      <IconClock />
      <span className="begins__text">{moment(start) > moment() ? textGenerator() : 'Past Event'}</span>
    </div>
  );
};

export default BeginsIn;
