import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Select, Spin } from 'antd';
import Moment from 'react-moment';
// actions
import therapistsAction from '../../../../redux/actions/therapists';
import clientAction from '../../../../redux/actions/client';
import groupsAction from 'redux/actions/groups';
import sessionsAction from 'redux/actions/sessions';
// types
import { stateType } from '../../../../types/types';
// helps
import { timeConvertor, week_days } from '../../../../configs/tables/admin/clients';
// styles
import './style.scss';
// icons
import Icon from '@ant-design/icons';
import { ReactComponent as CloseIcon } from 'assets/image/account/close4.svg';
import { ReactComponent as IconClock } from 'assets/image/questions/clock.svg';
import { ReactComponent as IconCalendar } from 'assets/image/dropdown/calendar.svg';
import IconDone from 'assets/image/questions/after.png';
// components
import BeginsIn from 'components/molecules/beginsIn';

const AdminChangeGroup: React.FC<any> = ({ id, status }: any) => {
  const [visible, setVisible] = useState(false);
  const [success, setSuccess] = useState(false);

  const dispatch = useDispatch();

  const loader = useSelector((state: stateType) => state.clients.loaderModal);
  const loaderGroup = useSelector((state: stateType) => state.groups.loaderModal);
  const item: any = useSelector((state: stateType) => state.clients.item);
  const group = useSelector((state: stateType) => state.groups.item);
  const session = useSelector((state: stateType) => state.sessions.items.next);
  const options = useSelector((state: stateType) => state.therapists.list);

  useEffect(() => {
    dispatch(therapistsAction.getList(id));
  }, []);

  const open = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <button className="btn-form" onClick={open}>
        {status === 5 || status === 6
          ? 'Change Client Group Choice'
          : `${item?.medicalGroup ? 'Change' : 'Add'} Client’s Group`}
      </button>
      {visible && (
        <Modal
          visible={visible}
          onOk={handleOk}
          closable={true}
          onCancel={handleCancel}
          centered
          maskTransitionName="none"
          footer={null}
          className="admin-chage-group-modal"
          closeIcon={<CloseIcon />}
        >
          {!success ? (
            <Spin spinning={loader || loaderGroup}>
              <ModalContainer id={id} item={item} setSuccess={setSuccess} options={options} />
            </Spin>
          ) : (
            <Spin spinning={loader || loaderGroup}>
              <SuccessModal id={id} item={item} group={group} handleCancel={handleCancel} session={session} />
            </Spin>
          )}
        </Modal>
      )}
    </>
  );
};

export default AdminChangeGroup;

const ModalContainer = ({ id, item, setSuccess, options }: any) => {
  const [groupID, setGroupID] = useState(null);
  const dispatch = useDispatch();

  const onChange = (value: any) => {
    if (value) {
      return setGroupID(value);
    }
    return setGroupID(null);
  };

  const submit = () => {
    const data = {
      customerId: id,
      groupId: groupID,
    };

    item.medicalGroup || item.preferableGroupInfo
      ? dispatch(clientAction.change(data))
      : dispatch(groupsAction.addClient(data));
    dispatch(groupsAction.id(groupID));
    dispatch(sessionsAction.get(groupID));
    setSuccess(true);
  };

  return (
    <div className="add-therapist-container">
      <div className="add-therapist-container__header">
        <ClientBox item={item} />
        {item?.medicalGroup && (
          <div className="add-therapist-container__group-column-container">
            <div className="add-therapist-container__column">
              <GroupBox item={item} />
            </div>
            <div className="add-therapist-container__column">
              <TherapistBox item={item} />
            </div>
          </div>
        )}
      </div>
      <div className="add-therapist-container__group-change-form">
        <div className="add-therapist-container__label">
          Which group will they be {item.medicalGroup ? 'changed' : 'added'} to?
        </div>
        <Select
          className="form-select"
          dropdownClassName="form-dropdown"
          placeholder="Choose your top choice"
          style={{ width: '100%' }}
          onChange={(value: any) => {
            onChange(value);
          }}
          showSearch
          options={options}
          allowClear
          defaultValue={undefined}
          filterOption={(input, option: any): any => option.groupname.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        />
      </div>
      <div className="btn-container">
        <button className="btn-form" onClick={submit} disabled={!groupID}>
          Confirm Group Change
        </button>
      </div>
    </div>
  );
};

export const ClientBox: React.FC<any> = ({ item }: any) => {
  const { firstname, lastname } = item;
  return (
    <div className="m-client-box">
      <div className="m-client-box__avatar">
        {firstname[0]}
        {lastname[0]}
      </div>
      <div className="m-client-box__header">
        <p>Client</p>
        <p className="m-client-box__username">
          {firstname} &nbsp;
          {lastname}
        </p>
      </div>
    </div>
  );
};

export const TherapistSmBox: React.FC<any> = ({ item }: any) => {
  const { firstname, lastname, avatar, title } = item;
  return (
    <div className="m-client-box">
      <div className="m-client-box__avatar">
        {avatar?.url ? (
          <img src={avatar.url} alt="therapist avatar" />
        ) : (
          <>
            {firstname[0]}
            {lastname[0]}
          </>
        )}
      </div>
      <div className="m-client-box__header">
        <p>Therapist</p>
        <p className="m-client-box__username">
          {title?.name ? `${title.name}.` : ''} {firstname} &nbsp;
          {lastname}
        </p>
      </div>
    </div>
  );
};

const GroupBox = ({ item }: any) => {
  return (
    <div className="m-group-box">
      <div className="m-group-box__group-avatar">
        {item.medicalGroup?.avatar?.url ? (
          <div className="m-group-box__group-avatar">
            <img src={item.medicalGroup?.avatar?.url} alt="" className="m-therapist-box__img" />
          </div>
        ) : (
          <div className="m-group-box__avatar">{item.medicalGroup?.groupTopic?.name[0]}</div>
        )}
      </div>
      <div className="m-group-box__info">
        <p className="m-group-box__header">Current Group</p>
        <p className="m-group-box__name">{item.medicalGroup?.groupTopic?.name}</p>
        <div className="m-group-box__date">
          <IconClock />
          {week_days[item?.medicalGroup?.dayOfWeek - 1]}s {timeConvertor(item?.medicalGroup?.start, 'small')}-
          {timeConvertor(item?.medicalGroup?.end, 'type-time')} ET
        </div>
      </div>
    </div>
  );
};

const TherapistBox = ({ item }: any) => {
  const { medicalGroup } = item;
  return (
    <div className="m-therapist-box">
      <div className="m-therapist-box__avatar">
        {!item.medicalGroup?.therapist?.avatar?.url &&
          `${medicalGroup?.therapist?.firstname[0]} ${medicalGroup?.therapist?.lastname[0]}`}
        {item.medicalGroup?.therapist?.avatar?.url && (
          <div className="m-therapist-box__avatar">
            <img src={item.medicalGroup?.therapist?.avatar?.url} alt="" className="m-therapist-box__img" />
          </div>
        )}
      </div>
      <div className="m-therapist-box__info">
        <div className="m-therapist-box__header">Current Therapist</div>
        <div className="m-therapist-box__name">
          {medicalGroup?.therapist.firstname} {medicalGroup?.therapist.lastname}
        </div>
      </div>
    </div>
  );
};

const SuccessModal = ({ id, item, group, handleCancel, session }: any) => {
  const dispatch = useDispatch();

  const groupDate = group.group?.date.dayOfWeek;
  const groupEnd = group.group?.date.end;
  const groupStart = group.group?.date.start;
  const nextSession = session[0]?.start;

  useEffect(() => {
    return () => {
      dispatch(clientAction.get(id));
    };
  }, []);

  return (
    <div className="success-change-group-container">
      <div className="success-change-group-container__title-img">
        <img src={IconDone} alt="" className="success-change-group-container__img" />
        <p className="success-change-group-container__title">
          You’ve successfully {item.medicalGroup ? 'switched' : 'added'} {item.firstname} to the following Group!
        </p>
      </div>
      <div className="success-change-group-container__group-card-container">
        <div className="different-container__label success-change-group-container__label">
          <Icon component={IconCalendar} />
          Every {`${week_days[groupDate - 1]}`}
        </div>
        <div className="small-group-card">
          <div className="small-group-card__avatar">
            {group.group?.avatar ? <img src={group.group?.avatar.url} alt="avatar" /> : <div />}
          </div>
          <div className="small-group-card__content">
            <div className="small-group-card__title">{`${group.group?.name} Group`}</div>
            <div className="small-group-card__info">
              <div className="small-group-card__when">
                <span>When:</span> {`${week_days[groupDate - 1]}s`} at{` `}
                {timeConvertor(groupStart)}
                {' - '}
                {timeConvertor(groupEnd, 'type-time')} ET
              </div>
              {nextSession && (
                <div className="small-group-card__when small-group-card__next-session">
                  <span>Next Session:</span> {` `}
                  <Moment format="MMMM Do, yyyy">{nextSession}</Moment>
                </div>
              )}
              <div className="small-group-card__when small-group-card__therapist-item">
                <span>Therapist:</span>&nbsp;
                <span className="small-group-card__therapist-name">{`${
                  group.group?.therapist_title?.name ? `${group.group?.therapist_title?.name}.` : ''
                }${group.group?.therapist_name}`}</span>
              </div>
              {nextSession && (
                <div className="small-group-card__begins-in">
                  <BeginsIn start={nextSession} status={item.status} isAddBackground />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="success-change-group-container__button">
        <button className="btn-form" onClick={handleCancel}>
          Done
        </button>
      </div>
    </div>
  );
};
