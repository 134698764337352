import Rate from 'antd/lib/rate';
import React, { FC, useEffect, useState } from 'react';
// types
import { IQuestion } from 'types/app-types';
// icons
import smilesImg from 'assets/image/questions/smiles.svg';
import { ReactComponent as ClearStar } from 'assets/image/questions/star.svg';
import { StarFilled } from '@ant-design/icons';

interface IRateQuestion {
  question: IQuestion;
  rating: any;
  onChangeRate: (value: number) => void;
}

const RateQuestion: FC<IRateQuestion> = ({ question, rating, onChangeRate }: IRateQuestion) => {
  const [hovered, setHovered] = useState(rating - 1);

  useEffect(() => setHovered(rating - 1), [rating]);

  return (
    <div className="questions__rate-body">
      <img className="questions__smiles" src={smilesImg} alt="smiles icon" />
      <div className="questions__question">{question.question}</div>
      <div className="questions__sub-question">{question.additions.subQuestion}</div>
      <div className="questions__rating">
        <Rate
          tooltips={['terrible', 'bad', 'normal', 'good', 'wonderful']}
          onChange={onChangeRate}
          value={rating}
          style={{ fontSize: '36px', color: '#ff974a' }}
          character={({ index }: any) => {
            if (index < rating || hovered >= 0) {
              return index > hovered ? <ClearStar /> : <StarFilled />;
            } else {
              return <ClearStar />;
            }
          }}
          onHoverChange={(value) => {
            setHovered(value - 1);
          }}
        />
      </div>
    </div>
  );
};

export default RateQuestion;
