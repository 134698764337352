import React from 'react';
// style
import '../style.scss';

interface LoginTextProps {
  role: string;
}

export const LoginPreviewText: React.FC<LoginTextProps> = (props: LoginTextProps) => {
  return (
    <div className="preview-text">
      <h1 className="preview-text__title">Sign In</h1>
      {props.role === 'customer' ? (
        <p className="preview-text__description">
          {'Sign in to your account to continue getting better'}
          <br />
          {'with the group that understands you best'}
        </p>
      ) : (
        <p className="preview-text__description">Log in with your account information below</p>
      )}
    </div>
  );
};

export const RegistrationPreviewText: React.FC = () => {
  return (
    <div className="preview-text">
      <h1 className="preview-text__title">Create an Account</h1>
      <p className="preview-text__description">
        {"Sign up to get started and we'll find the group that's best for you"}
      </p>
    </div>
  );
};

export const RegistrationAfterPreviewText: React.FC = () => {
  return (
    <div className="preview-text">
      <h1 className="preview-text__after-title">
        Create an account
        <br />
        to see your results
      </h1>
      <p className="preview-text__description">
        {'Answer these quick questions to view your assessment results and recommended group for treatment'}
      </p>
    </div>
  );
};

export const ForgotPasswordPreviewText: React.FC = () => {
  return (
    <div className="preview-text">
      <h1 className="preview-text__title">Forgot your password</h1>
      <p className="preview-text__description">
        {'Please enter your email address, and we’ll send you a link to change your password'}
      </p>
    </div>
  );
};

export const NewPasswordPreviewText: React.FC = () => {
  return (
    <div className="preview-text">
      <h1 className="preview-text__title">New password</h1>
      <p className="preview-text__description">{'Enter your new password'}</p>
    </div>
  );
};

export const ConsultationPreviewText: React.FC = () => {
  return (
    <div className="preview-text">
      <h2 className="preview-text__title">Welcome!</h2>
      <p className="preview-text__description preview-text__description_consultation">
        {'We’re excited to help you on your path to feeling better.'}
      </p>
    </div>
  );
};
