import {
  CancelRestartSubscriptionsAction,
  GoBackWaitingAction,
  JoinRestartGroupAction,
  RestartSubscriptionsActions,
  RestartSubscriptionsChoiceAction,
  RestartSubscriptionsClearAction,
  RestartSubscriptionsLoaderAction,
  RestartSubscriptionsStateAction,
  UpdateRegistrationDataAction,
} from '../../types/actions/restart_subscriptions';
import { ActionCallback, ObjectType } from '../../types/actions/common-types';

const restartAction: RestartSubscriptionsActions = {
  state(restartState: number): RestartSubscriptionsStateAction {
    return {
      type: 'RESTART_SUBSCRIPTIONS_STATE',
      payload: {
        restartState,
      },
    };
  },
  choice(isTheSame: boolean): RestartSubscriptionsChoiceAction {
    return {
      type: 'RESTART_SUBSCRIPTIONS_CHOICE',
      payload: {
        isTheSame,
      },
    };
  },
  cancel(callback?: ActionCallback): CancelRestartSubscriptionsAction {
    return {
      type: 'CANCEL_RESTART_SUBSCRIPTIONS',
      callback,
    };
  },
  loader(status: boolean): RestartSubscriptionsLoaderAction {
    return {
      type: 'RESTART_SUBSCRIPTIONS_LOADER',
      payload: {
        loader: status,
      },
    };
  },
  updateData(data?: ObjectType, callback?: ActionCallback): UpdateRegistrationDataAction {
    return {
      type: 'UPDATE_REGISTRATION_DATA',
      data,
      callback,
    };
  },
  goBack(callback?: ActionCallback): GoBackWaitingAction {
    return {
      type: 'GO_BACK_WAITING',
      callback,
    };
  },
  join(id: any, callback?: ActionCallback): JoinRestartGroupAction {
    return {
      type: 'JOIN_RESTART_GROUP',
      id,
      callback,
    };
  },
  clear(): RestartSubscriptionsClearAction {
    return {
      type: 'RESTART_SUBSCRIPTIONS_CLEAR',
      payload: {
        restartState: 0,
        isTheSame: undefined,
      },
    };
  },
};

export default restartAction;
