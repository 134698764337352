/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

// configs
import { week_days, timeConvertor } from '../../../../../configs/tables/admin/clients';
import { createLicenseList, fixDayOfWeek } from '../../../../../tools/helpFunctions';

const mappingTherapistsList = {
  buildOptions(array) {
    return array.map((item) => {
      return {
        key: item.id,
        value: item.id,
        label:
          item.groupTopic.name +
          ' - ' +
          week_days[fixDayOfWeek(item.dayOfWeek, item.start, 'to_ny') - 1] +
          's' +
          ' ' +
          timeConvertor(item.start) +
          '-' +
          timeConvertor(item.end, 'type-time') +
          ' ET' +
          ' with ' +
          `${item?.therapist?.title ? `${item.therapist.title}.` : ''} ` +
          item.therapist.firstname +
          ' ' +
          item.therapist.lastname +
          createLicenseList(item?.therapist?.licenses),
        name: item.therapist.firstname + ' ' + item.therapist.lastname,
        groupname: item.groupTopic.name,
      };
    });
  },

  buildSelectOptions(array) {
    return array.map((item) => {
      return {
        key: item.id,
        value: item.id,
        label: item.email,
      };
    });
  },

  buildLicensesOptions(array) {
    return array.map((item) => {
      return {
        key: item.id,
        value: item.id,
        label: item.name,
      };
    });
  },

  buildSessionHistory(data) {
    let group = data.reduce((r, a) => {
      r[a.end] = [...(r[a.end] || []), a];
      return r;
    }, {});
    return group;
  },

  buildGroup(data, role) {
    return data.map((item) => {
      const viewID = role === 'ADMIN' ? `, #${item.id}` : '';
      return {
        key: item.id,
        value: item.id,
        label: `${
          item?.therapist
            ? `${item.therapist.title ? item.therapist.title + '.' : ''} ${item.therapist.firstname} ${
                item.therapist.lastname
              },`
            : ''
        } ${item.groupTopic.name} on ${week_days[fixDayOfWeek(item.dayOfWeek, item.start, 'to_ny') - 1]}s from ${
          timeConvertor(item.start, 'small-format') + '-' + timeConvertor(item.end, 'small-format')
        } ${viewID} `,
      };
    });
  },
  buildStatuses(data) {
    return data.map((item) => {
      return {
        key: item.value,
        value: item.value,
        label: item.name,
      };
    });
  },
  buildNumberOfMembers(data) {
    return data.map((item) => {
      return {
        key: item.id,
        value: item.id,
        label: item.name,
      };
    });
  },
};

export default mappingTherapistsList;
