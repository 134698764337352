import React from 'react';
// styles
import './style.scss';

interface InputFieldType {
  classes?: string;
  children: React.ReactNode;
  name?: string;
  label?: string;
  icon?: React.ReactNode;
  errors?: any;
}

const InputField: React.FC<InputFieldType> = ({ classes, children, name, label, icon, errors }: InputFieldType) => {
  const inputFieldClasses = errors ? 'input-field input-field_error' : 'input-field';
  return (
    <div className={classes ? `${inputFieldClasses} ${classes}` : inputFieldClasses}>
      <label htmlFor={name} className={errors ? 'input-field__label input-field__label_error' : 'input-field__label'}>
        {label}
      </label>
      <div className={errors ? 'input-field__input input-field__input_error' : 'input-field__input'}>
        {children}
        {icon && <div className="input-field__icon">{icon}</div>}
        <div className="input-field__error-container">
          {errors && (
            <span role="alert" className="input-field__error-message">
              {errors}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default InputField;

export const InputFieldOther: React.FC<InputFieldType> = ({
  classes,
  children,
  name,
  label,
  icon,
  errors,
}: InputFieldType) => {
  const inputFieldClasses = errors ? 'input-field-other input-field-other_error' : 'input-field-other';
  return (
    <div className={classes ? `${inputFieldClasses} ${classes}` : inputFieldClasses}>
      <label
        htmlFor={name}
        className={errors ? 'input-field-other__label input-field-other__label_error' : 'input-field-other__label'}
      >
        {label}
        {errors && <span role="alert"> - {errors}</span>}
      </label>
      <div className={errors ? 'input-field-other__input input-field-other__input_error' : 'input-field-other__input'}>
        {children}
        {icon && <div className="input-field-other__icon">{icon}</div>}
      </div>
    </div>
  );
};
