import React, { useEffect } from 'react';
import cn from 'classnames';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
// actions
import upcomingAction from '../../../redux/actions/upcoming_events';
// types
import { stateType } from '../../../types/types';
// configs
import useDidMountEffect from '../../../configs/hooks/useDidMountEffect';
import { getSearchEvent } from '../../../tools/helpFunctions';
// style
import './style.scss';
import { FilterOutlined } from '@ant-design/icons';

const EventSort = ({ role, show, setShow }: any) => {
  const type: string = useSelector((state: stateType) => state.upcoming_events.searchType, shallowEqual);
  const dispatch = useDispatch();

  const onSort = (type: string): void => {
    if (role.toLowerCase() === 'admin') {
      dispatch(upcomingAction.allParams({ period_type: type }));
    }
    dispatch(upcomingAction.eventsSearchType(type));
  };

  useEffect(() => {
    return () => {
      dispatch(upcomingAction.setDefault());
    };
  }, []);

  useDidMountEffect(() => {
    getSearchEvent(type, dispatch);
  }, [type]);

  return (
    <div className="sort-block-wrap">
      <div className="sort-block-wrap__container">
        <button
          className={cn('btn-sort btn-sort_sm minus-margin_right', {
            'btn-sort_active': type === 'upcoming',
          })}
          onClick={() => onSort('upcoming')}
        >
          Upcoming
        </button>
        <button
          className={cn('btn-sort btn-sort_sm btc-sort-adaptive', {
            'btn-sort_active': type === 'past',
          })}
          onClick={() => onSort('past')}
        >
          Past
        </button>
        <button
          className={cn('btn-sort btn-sort_sm btc-sort-adaptive', {
            'btn-sort_active': type === 'all',
          })}
          onClick={() => onSort('all')}
        >
          All
        </button>
        <div style={{ display: 'flex', height: 42, alignItems: 'flex-end' }}>
          <div
            className={cn('btn-filter btn-filter__events', { 'btn-filter__active': show })}
            onClick={() => setShow(!show)}
          >
            <FilterOutlined style={{ fontSize: '20px', color: show ? '#1891e5' : '#92929d', margin: '0 15px' }} />
            <span>Filter</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventSort;
