import React, { useState } from 'react';
import { PasswordEyeIcon } from '../../../atoms/utils/password-eye-icon';
import InputField from '../../inputField';
// styles
import '../style.scss';

interface InputsType {
  errors: string | undefined;
  register: any;
  watch?: any;
  defaultValue?: any;
  placeholder?: string;
}
interface SubmitButtonType {
  name: string;
  cancelName?: string;
  cancel?: () => void;
}

export const InputFirstName: React.FC<InputsType> = ({ errors, register, defaultValue, placeholder }: InputsType) => {
  return (
    <InputField name="firstname" label="First Name" errors={errors} icon={false}>
      <input
        id="firstname"
        className={errors && 'error'}
        placeholder={placeholder ? placeholder : ''}
        {...register('firstname', {
          required: 'enter your first name',
          minLength: {
            value: 3,
            message: 'First name must have at least 3 characters',
          },
        })}
        defaultValue={defaultValue ? defaultValue : ''}
      />
    </InputField>
  );
};

export const InputLastName: React.FC<InputsType> = ({ errors, register, defaultValue, placeholder }: InputsType) => {
  return (
    <InputField name="lastname" label="Last Name" errors={errors} icon={false}>
      <input
        id="lastname"
        className={errors && 'error'}
        placeholder={placeholder ? placeholder : ''}
        {...register('lastname', {
          required: 'enter your lastname',
          minLength: {
            value: 3,
            message: 'Last name must have at least 3 characters',
          },
        })}
        defaultValue={defaultValue ? defaultValue : ''}
      />
    </InputField>
  );
};

export const InputforumUserName: React.FC<InputsType> = ({
  errors,
  register,
  defaultValue,
  placeholder,
}: InputsType) => {
  return (
    <InputField name="forumUsername" label="Forum Name" errors={errors} icon={false}>
      <input
        id="forumUsername"
        className={errors && 'error'}
        placeholder={placeholder ? placeholder : ''}
        {...register('forumUsername', {
          required: 'enter your forum name',
          minLength: {
            value: 3,
            message: 'Last name must have at least 3 characters',
          },
        })}
        defaultValue={defaultValue ? defaultValue : ''}
      />
    </InputField>
  );
};

export const InputPhone: React.FC<InputsType> = ({ errors, register, placeholder }: InputsType) => {
  return (
    <InputField name="phone" label="Phone" errors={errors} icon={false}>
      <input
        id="phone"
        maxLength={10}
        className={errors && 'error'}
        placeholder={placeholder ? placeholder : ''}
        {...register('phone', {
          required: 'enter your phone',
          minLength: {
            value: 10,
            message: 'Phone must have at least 10 characters',
          },
          maxLength: {
            value: 10,
            message: 'Phone must have at least 10 characters',
          },
        })}
      />
    </InputField>
  );
};

export const RepeatPhone: React.FC<InputsType> = ({ errors, register, placeholder, watch }: InputsType) => {
  return (
    <InputField name="phone_repeat" label="Confirm phone" errors={errors} icon={false}>
      <input
        id="phone_repeat"
        maxLength={10}
        className={errors && 'error'}
        placeholder={placeholder ? placeholder : ''}
        {...register('phone_repeat', {
          required: 'repeat your phone',
          minLength: {
            value: 10,
            message: 'Phone must have at least 10 characters',
          },
          maxLength: {
            value: 10,
            message: 'Phone must have at least 10 characters',
          },
          validate: (value: string) => value === watch('phone') || 'The phone do not match!',
        })}
      />
    </InputField>
  );
};

export const InputEmail: React.FC<InputsType> = ({ errors, register, placeholder }: InputsType) => {
  return (
    <InputField name="email" label="Email" errors={errors} icon={false}>
      <input
        id="email"
        className={errors && 'error'}
        placeholder={placeholder ? placeholder : ''}
        {...register('email', {
          required: 'enter your e-mail',
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
            message: 'enter a valid e-mail address',
          },
        })}
      />
    </InputField>
  );
};

export const RepeatEmail: React.FC<InputsType> = ({ errors, register, placeholder, watch }: InputsType) => {
  return (
    <InputField name="email_repeat" label="Confirm email" errors={errors} icon={false}>
      <input
        id="email_repeat"
        className={errors && 'error'}
        placeholder={placeholder ? placeholder : ''}
        {...register('email_repeat', {
          required: 'repeat your e-mail',
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
            message: 'enter a valid e-mail address',
          },
          validate: (value: string) => value === watch('email') || 'The email do not match!',
        })}
      />
    </InputField>
  );
};

export const InputPassword: React.FC<InputsType> = ({ errors, register, placeholder }: InputsType) => {
  const [show, setShow] = useState(false);

  const CurrentEyeIcon = PasswordEyeIcon({
    active: show,
    onClick: () => {
      setShow(!show);
    },
  });

  return (
    <InputField name="password" label="Password" errors={errors} icon={<CurrentEyeIcon />}>
      <input
        id="password"
        type={show ? '' : 'password'}
        className={errors && 'error'}
        placeholder={placeholder ? placeholder : ''}
        {...register('password', {
          required: 'enter your password',
          minLength: {
            value: 6,
            message: 'Password must have at least 6 characters',
          },
        })}
      />
    </InputField>
  );
};

export const RepeatPassword: React.FC<InputsType> = ({ errors, register, watch, placeholder }: InputsType) => {
  const [show, setShow] = useState(false);

  const CurrentEyeIcon = PasswordEyeIcon({
    active: show,
    onClick: () => {
      setShow(!show);
    },
  });

  return (
    <>
      <InputField name="password_repeat" label="Confirm Password" errors={errors} icon={<CurrentEyeIcon />}>
        <input
          id="password_repeat"
          type={show ? '' : 'password'}
          className={errors && 'error'}
          placeholder={placeholder ? placeholder : ''}
          {...register('password_repeat', {
            required: 'enter your password',
            validate: (value: string) => value === watch('password') || 'The passwords do not match!',
          })}
        />
      </InputField>
    </>
  );
};

export const SubmitButton: React.FC<SubmitButtonType> = ({ name, cancelName, cancel }: SubmitButtonType) => {
  return (
    <div className="edit-account-form__buttons">
      <button type="submit" className="btn-form">
        {name}
      </button>
      {cancelName && (
        <button type="button" className="btn-light" onClick={cancel}>
          {cancelName}
        </button>
      )}
    </div>
  );
};
