import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import userActions from '../../../../../redux/actions/user';
import { useForm } from 'react-hook-form';
// actions
import accountAction from '../../../../../redux/actions/account';
// components
import {
  InputEmail,
  InputFirstName,
  InputforumUserName,
  InputLastName,
  InputPassword,
  InputPhone,
  RepeatEmail,
  RepeatPassword,
  RepeatPhone,
  SubmitButton,
} from '../../../../molecules/inputsBlocks/editAccounts';
// types
import { stateType } from '../../../../../types/types';
import {
  EditEmailFormRequest,
  EditForumNameFormRequest,
  EditNameFormRequest,
  EditPasswordFormRequest,
  EditPhoneFormRequest,
} from '../../../../../types/request-types';
import {
  FormChangePassword,
  FormDataEmail,
  FormDataForumName,
  FormDataICD10,
  FormDataName,
  FormDataPassword,
  FormDataPhone,
} from '../../../../../types/form-data';
// style
import '../../style.scss';

import { getErrorsCallback } from '../../../../../tools/helpFunctions';
import { accountErrors } from 'redux/sagas/error-messages';
import clientAction from 'redux/actions/client';
import { FormSearchSelect } from 'components/molecules/inputsBlocks/select';
import eventAnalytics from '../../../../../redux/actions/analytics';

interface EditFormType {
  setVisible: any;
}

export const EditTypeFormName: React.FC<EditFormType> = ({ setVisible }: EditFormType) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm<FormDataName>();

  const { firstname, lastname }: any = useSelector((state: stateType) => state.account.item);
  const { profile } = useSelector((state: stateType) => state.user, shallowEqual);

  useEffect(() => {
    setValue('firstname', firstname);
    setValue('lastname', lastname);
  }, [firstname, lastname]);

  const dispatch = useDispatch();

  const cancel = () => {
    reset({});
    setVisible(false);
  };

  const onSubmit = (data: FormDataName) => {
    const request: EditNameFormRequest = {
      firstname: data.firstname,
      lastname: data.lastname,
      token: localStorage.getItem('edit_token'),
      refreshToken: localStorage.getItem('refresh_token'),
      type: 'name',
    };

    const rewriteNames = () => {
      dispatch(
        userActions.rewrite({
          ...profile,
          firstname: data.firstname,
          lastname: data.lastname,
          username: `${data.firstname} ${data.lastname}`,
        }),
      );
      setVisible(false);
      dispatch(eventAnalytics.analytics('change-name-completed'));
    };

    dispatch(accountAction.edit(request, rewriteNames));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="form-edit">
      <div className="edit-account-form__inputs">
        <InputFirstName
          errors={errors.firstname ? errors.firstname.message : undefined}
          register={register}
          placeholder="Enter new first name"
        />
        <InputLastName
          errors={errors.lastname ? errors.lastname.message : undefined}
          register={register}
          placeholder="Enter new last name"
        />
      </div>
      <SubmitButton name="Save" cancelName="Cancel" cancel={cancel} />
    </form>
  );
};

export const EditTypeFormForumName: React.FC<EditFormType> = ({ setVisible }: EditFormType) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm<FormDataForumName>();

  const { forumUsername }: any = useSelector((state: stateType) => state.account.item);

  useEffect(() => {
    setValue('forumUsername', forumUsername);
  }, [forumUsername]);

  const dispatch = useDispatch();

  const cancel = () => {
    reset({});
    setVisible(false);
  };

  const onSubmit = (data: FormDataForumName) => {
    const request: EditForumNameFormRequest = {
      forumUsername: data.forumUsername,
      token: localStorage.getItem('edit_token'),
      refreshToken: localStorage.getItem('refresh_token'),
      type: 'forum-username',
    };
    dispatch(accountAction.edit(request, () => setVisible(false)));
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="form-edit">
      <div className="edit-account-form__inputs">
        <InputforumUserName
          errors={errors.forumUsername ? errors.forumUsername.message : undefined}
          register={register}
          placeholder="Enter new forum name"
        />
      </div>
      <SubmitButton name="Save" cancelName="Cancel" cancel={cancel} />
    </form>
  );
};

export const EditTypeFormPhone: React.FC<EditFormType> = ({ setVisible }: EditFormType) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
    watch,
  } = useForm<FormDataPhone>();

  const { phone }: any = useSelector((state: stateType) => state.account.item);

  useEffect(() => {
    setValue('phone', phone);
  }, [phone]);

  const dispatch = useDispatch();

  const cancel = () => {
    reset({});
    setVisible(false);
  };

  const onSubmit = (data: FormDataPhone) => {
    const request: EditPhoneFormRequest = {
      phone: data.phone,
      token: localStorage.getItem('edit_token'),
      refreshToken: localStorage.getItem('refresh_token'),
      type: 'phone',
    };
    // dispatch(accountAction.edit(request, () => setVisible(false)));
    dispatch(accountAction.edit(request, callbackAfterRequest));
  };

  const callbackAfterRequest = () => {
    dispatch(eventAnalytics.analytics('change-number-completed'));
    setVisible(false);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="form-edit">
      <div className="edit-account-form__inputs">
        <InputPhone
          errors={errors.phone ? errors.phone.message : undefined}
          register={register}
          placeholder="Enter new phone number"
        />
        <RepeatPhone
          errors={errors.phone_repeat ? errors.phone_repeat.message : undefined}
          register={register}
          watch={watch}
          placeholder="Confirm new phone number"
        />
      </div>
      <SubmitButton name="Save" cancelName="Cancel" cancel={cancel} />
    </form>
  );
};

export const EditTypeICD10: React.FC<EditFormType> = ({ setVisible }: EditFormType) => {
  const {
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
    watch,
    control,
  } = useForm<FormDataICD10>();

  const { icd10 }: any = useSelector((state: stateType) => state.clients.item, shallowEqual);

  const params = useParams<{ id: string }>();

  useEffect(() => {
    setValue('icd10', icd10);
  }, [icd10]);

  const dispatch = useDispatch();

  const cancel = () => {
    reset({});
    setVisible(false);
  };

  const onSubmit = (data: FormDataICD10) => {
    dispatch(clientAction.setICD10(parseInt(params.id), data.icd10));
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="form-edit">
      <div className="edit-account-form__inputs">
        <FormSearchSelect
          name="icd10"
          label="ICD-10 Code"
          errors={errors.icd10 ? errors.icd10.message : undefined}
          control={control}
          placeholder="Enter the ICD Code"
          filterOption={(input: string, option: any) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        />
      </div>
      <SubmitButton name="Save" cancelName="Cancel" cancel={cancel} />
    </form>
  );
};

export const EditTypeFormEmail: React.FC<EditFormType> = ({ setVisible }: EditFormType) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
    watch,
    setError,
  } = useForm<FormDataEmail>();

  const { email }: any = useSelector((state: stateType) => state.account.item);

  useEffect(() => {
    setValue('email', email);
  }, [email]);

  const dispatch = useDispatch();

  const cancel = () => {
    reset({});
    setVisible(false);
  };

  const onSubmit = (data: FormDataEmail) => {
    if (email === data.email) {
      setError('email', { type: 'manual', message: accountErrors.EXIST_EMAIL });
      return;
    }

    const request: EditEmailFormRequest = {
      email: data.email,
      token: localStorage.getItem('edit_token'),
      refreshToken: localStorage.getItem('refresh_token'),
      type: 'email',
    };
    const b = dispatch(accountAction.edit(request, callbackAfterRequest));
    // console.log('b', b);
  };

  const callbackAfterRequest = () => {
    setVisible(false);
    dispatch(eventAnalytics.analytics('change-email-completed'));
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="form-edit">
      <div className="edit-account-form__inputs">
        <InputEmail
          errors={errors.email ? errors.email.message : undefined}
          register={register}
          placeholder="Enter new email address"
        />
        <RepeatEmail
          errors={errors.email_repeat ? errors.email_repeat.message : undefined}
          register={register}
          watch={watch}
          placeholder="Confirm new email address"
        />
      </div>
      <SubmitButton name="Save" cancelName="Cancel" cancel={cancel} />
    </form>
  );
};

export const EditTypeFormChangePassword: React.FC<EditFormType> = ({ setVisible }: EditFormType) => {
  const {
    watch,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormChangePassword>();

  const dispatch = useDispatch();

  const cancel = () => {
    reset({});
    setVisible(false);
  };

  const onSubmit = (data: FormChangePassword) => {
    const request: EditPasswordFormRequest = {
      password: data.password,
      token: localStorage.getItem('edit_token'),
      refreshToken: localStorage.getItem('refresh_token'),
      type: 'password',
    };
    dispatch(accountAction.edit(request, () => setVisible(false)));
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="form-edit">
      <div className="edit-account-form__inputs">
        <InputPassword
          errors={errors.password ? errors.password.message : undefined}
          register={register}
          placeholder="Enter new password"
        />
        <RepeatPassword
          errors={errors.password_repeat ? errors.password_repeat.message : undefined}
          register={register}
          watch={watch}
          placeholder="Confirm new password"
        />
      </div>
      <SubmitButton name="Save" cancelName="Cancel" cancel={cancel} />
    </form>
  );
};

export const EditTypeFormPassword: React.FC<any> = ({ setVisible, formProps }: any) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
  } = formProps;

  const dispatch = useDispatch();

  const onSubmit = (data: FormDataPassword) => {
    dispatch(accountAction.getEditToken(data, undefined, getErrorsCallback(setError)));
  };

  const cancel = () => {
    setVisible(false);
    reset({});
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="form-edit">
      <div className="edit-account-form__inputs">
        <InputPassword
          errors={errors.password ? errors.password.message : undefined}
          register={register}
          placeholder="Enter Current Password"
        />
      </div>
      <SubmitButton name="Continue" cancelName="Cancel" cancel={cancel} />
    </form>
  );
};
