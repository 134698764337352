import React, { FC } from 'react';
import { Progress } from 'antd';
// styles
import './style.scss';

interface IStepProps {
  stepNumber: number;
  step: number;
  totalStepCount: number;
}

const StepProgress: FC<IStepProps> = ({ step, stepNumber, totalStepCount }: IStepProps) => {
  return (
    <Progress
      type="circle"
      percent={(100 / totalStepCount) * stepNumber}
      format={() => <div className="step-number">{step}</div>}
      width={44}
    />
  );
};

export default StepProgress;
