import React, { useEffect } from 'react';
// types
import { BooleanChoiceProps, GroupAnswerProps, SingleLargeAnswerProps } from '../../../types/commonPropTypes';

export const SingleLargeAnswer: React.FC<any> = ({ answers, setCheckedValues }: SingleLargeAnswerProps) => {
  return (
    <div className="single-large-answers">
      <button className="btn-form" key={answers[0].id} onClick={() => setCheckedValues(answers[0].id)}>
        {answers[0].answer}
      </button>
      {answers[1] && (
        <span
          className="single-large-answers__text"
          key={answers[1].id}
          onClick={() => setCheckedValues(answers[1].id)}
        >
          {answers[1].answer}
        </span>
      )}
    </div>
  );
};

export const GroupAnswer: React.FC<GroupAnswerProps> = ({ question, goNext, onChange }: GroupAnswerProps) => {
  return (
    <div className="single-large-answers">
      <button className="btn-item" onClick={onChange}>
        See other group options
      </button>
      <div onClick={goNext} className="single-large-answers__text" style={{ paddingLeft: 8, paddingRight: 8 }}>
        {question.additions?.disclaimerText}
      </div>
    </div>
  );
};

export const BooleanChoice: React.FC<BooleanChoiceProps> = ({ question, onChangeRadio, value }: BooleanChoiceProps) => {
  useEffect(() => {
    onChangeRadio(question.defaultValue ? question.defaultValue.answerId : question.answers[0].id);
  }, []);

  const click = (id: string): void => {
    onChangeRadio(id);
  };

  return (
    <div className="boolean-container">
      {question.answers.map((item: any) => {
        return (
          <div
            key={item.id}
            className={
              value === item.id ? 'boolean-container__item boolean-container__item-active' : 'boolean-container__item'
            }
          >
            <div
              className={
                value === item.id ? 'boolean-container__box boolean-container__box-active' : 'boolean-container__box'
              }
              onClick={() => click(item.id)}
            />
            <span
              className={
                value === item.id
                  ? 'boolean-container__label boolean-container__label-active'
                  : 'boolean-container__label'
              }
            >
              {item.answer}
            </span>
          </div>
        );
      })}
    </div>
  );
};
