import React, { FC } from 'react';
// actions
import questionsActions from 'redux/actions/questions';
// images
import ImgAfter from 'assets/image/questions/after.png';

interface IAfterProps {
  setVisible: (visible: boolean) => void;
}

const StartedAfter: FC<IAfterProps> = ({ setVisible }: IAfterProps) => {
  const onClick = () => {
    setVisible(false);
    questionsActions.status('');
  };

  return (
    <div className="questions">
      <div className="questions__finish-body">
        <img className="questions__finish-ico" src={ImgAfter} alt="Finish icon" />
        <div className="questions__congrad">Congratulations!</div>
        <div className="questions__finish-text">You’ve completed the questions and are ready to join your group</div>
      </div>
      <button className="btn-form" onClick={onClick}>
        Done
      </button>
    </div>
  );
};

export default StartedAfter;
