import store from '../store';
// actions
import { ChangeGroupActions } from 'types/actions/change-group';

const changeGroupActions: ChangeGroupActions = {
  next(number: any, callback: () => void): any {
    return {
      type: 'GET_CHANGE_NEXT_QUESTION',
      number,
      callback,
    };
  },

  after(number: any, callback: () => void): any {
    return {
      type: 'GET_CHANGE_AFTER_QUESTION',
      number,
      callback,
    };
  },

  answer(data: any, questionNumber, callback: () => void): any {
    return {
      type: 'PUSH_CHANGE_ANSWER_QUESTION',
      data,
      questionNumber,
      callback,
    };
  },

  cansel(callback?: () => void) {
    return {
      type: 'CANCEL_CHANGE_GROUP',
      callback,
    };
  },

  status: (status: any): any => {
    store.dispatch({
      type: 'QUESTION_STATUS',
      payload: {
        status: status,
      },
    });
  },

  create: (data: any): any => {
    store.dispatch({
      type: 'QUESTION_ITEM',
      payload: {
        item: data,
      },
    });
  },

  loader(status: boolean): any {
    return {
      type: 'QUESTION_LOADER',
      payload: {
        loader: status,
      },
    };
  },

  progress(value: number): any {
    return {
      type: 'QUESTION_PROGRESS',
      payload: {
        progress: value,
      },
    };
  },

  goBack(callback?: () => void): any {
    return {
      type: 'CHANGE_GROUP_GO_BACK',
      callback,
    };
  },
};

export default changeGroupActions;
