import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Modal, Spin } from 'antd';
import cn from 'classnames';
// components
import StartedBefore from 'components/molecules/groupQuestions/BeforeAfter/StartedBefore';
import StartedAfter from 'components/molecules/groupQuestions/BeforeAfter/StartedAfter';
import Step from 'components/organisms/modals/questions/StartQuestions/Step';
// types
import { stateType } from 'types/types';
// style
import 'components/organisms/modals/questions/style.scss';

const ModalQuestions: React.FC = () => {
  const [visible, setVisible] = useState<boolean>(false);
  const question: any = useSelector((state: stateType) => state.questions.item, shallowEqual);
  const status: string = useSelector((state: stateType) => state.questions.status, shallowEqual);
  const loader: boolean = useSelector((state: stateType) => state.questions.loader, shallowEqual);
  const state: string = useSelector((state: stateType) => state.user.state, shallowEqual);

  useEffect(() => {
    if (state === 'questioning') {
      return setVisible(true);
    }

    if (status === '') {
      setVisible(false);
    }
  }, [state]);

  const onVisibleChange = (visible: boolean) => setVisible(visible);

  const questionController = () => {
    switch (status) {
      case 'before':
        return <StartedBefore />;
      case 'step':
        return <Step question={question} />;
      case 'after':
        return <StartedAfter setVisible={onVisibleChange} />;
      default:
        return <StartedBefore />;
    }
  };

  return (
    <>
      {visible && (
        <Modal
          visible={visible}
          closable={false}
          centered
          footer={null}
          className={cn({ 'before-after-modal': status !== 'step', 'questions-modal': status === 'step' })}
        >
          <Spin spinning={loader}>{!loader && questionController()}</Spin>
        </Modal>
      )}
    </>
  );
};

export default ModalQuestions;
