/*
 *
 * Consultation reducer
 *
 */
const initialState = {
  loader: false,
  consultationData: {},
};

export type consultationInitStateType = typeof initialState;

interface consultationActionTypes {
  type: string;
  payload: {
    loader: boolean;
    consultationData: any;
  };
}

const consultation_form = (state = initialState, action: consultationActionTypes): consultationInitStateType => {
  switch (action.type) {
    case 'CONSULTATION_FORM_LOADER':
      return Object.assign({}, state, {
        loader: action.payload.loader,
      });

    case 'CONSULTATION_FORM_DATA':
      return Object.assign({}, state, {
        consultationData: action.payload.consultationData,
      });

    default:
      return state;
  }
};

export default consultation_form;
