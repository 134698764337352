import { FC } from 'react';

export enum MainMenuActions {
  Admin = 'ADMIN',
  Customer = 'CUSTOMER',
  Therapist = 'THERAPIST',
}

export interface MainMenuElement {
  id: number;
  status: boolean;
  name: string;
  icon: FC;
  link?: string;
  disabled?: boolean;
}

export interface InitMainMenuPayloadAction {
  type: 'MENU';
  payload: {
    menu: MainMenuElement[];
  };
}

export interface MenuShowMinimalAction {
  type: 'MENU_SHOW_MINIMAL';
  payload: {
    showMinimal: boolean;
  };
}

export interface MenuShowDrawerAction {
  type: 'MENU_SHOW_DRAWER';
  payload: {
    showDrawer: boolean;
  };
}

export interface MenuPropgressPopover {
  type: 'MENU_PROGRESS_POPOVER';
  payload: {
    propgressPopover: boolean | number;
  };
}

export type InitMainMenuAction = InitMainMenuPayloadAction | [];
