import React, { useCallback } from 'react';

import Button from '@material-ui/core/Button';
import MicIcon from '../../../../icons/MicIcon';
import MicOffIcon from '../../../../icons/MicOffIcon';

import useLocalAudioToggle from '../../../../hooks/useLocalAudioToggle/useLocalAudioToggle';
import useVideoContext from '../../../../hooks/useVideoContext/useVideoContext';

export default function ToggleAudioButton(props: {
  disabled?: boolean;
  className?: string;
  iconSize?: number;
  iconColor?: string;
}) {
  const [isAudioEnabled, toggleAudioEnabled, toggleAudioEnabledRemotely, isRemotelyLocked] = useLocalAudioToggle();
  const { localTracks } = useVideoContext();
  const hasAudioTrack = localTracks.some((track) => track.kind === 'audio');

  const toggle = useCallback(() => {
    toggleAudioEnabled();
  }, [toggleAudioEnabled]);

  return (
    <Button
      className={props.className}
      onClick={toggle}
      disabled={!hasAudioTrack || isRemotelyLocked || props.disabled}
      data-cy-audio-toggle
    >
      {isAudioEnabled ? (
        <MicIcon size={props.iconSize || 20} color={props.iconColor || '#ffffff'} />
      ) : (
        <MicOffIcon size={props.iconSize || 20} color={props.iconColor || '#ffffff'} />
      )}
    </Button>
  );
}
