/*
 *
 * Restart subscriptions reducer
 *
 */

const initialState = {
  restartState: 0,
  isTheSame: undefined,
  loader: false,
};

export type restartSubscriptionsInitStateType = typeof initialState;

interface restartSubscriptionsActionTypes {
  type: string;
  payload: {
    restartState: number;
    isTheSame?: boolean;
    loader: boolean;
  };
}

const restartSubscriptions = (
  state = initialState,
  action: restartSubscriptionsActionTypes,
): restartSubscriptionsInitStateType => {
  switch (action.type) {
    case 'RESTART_SUBSCRIPTIONS_STATE':
      return Object.assign({}, state, {
        restartState: action.payload.restartState,
      });
    case 'RESTART_SUBSCRIPTIONS_CHOICE':
      return Object.assign({}, state, {
        isTheSame: action.payload.isTheSame,
      });
    case 'RESTART_SUBSCRIPTIONS_LOADER':
      return Object.assign({}, state, {
        loader: action.payload.loader,
      });
    case 'RESTART_SUBSCRIPTIONS_CLEAR':
      return Object.assign({}, state, {
        restartState: action.payload.restartState,
        isTheSame: action.payload.isTheSame,
      });

    default:
      return state;
  }
};

export default restartSubscriptions;
