import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
// components
import MainTemplate from '../../components/templates/mainTemplate';
import LayoutAccontContainer from '../../components/containers/accountContainers/layout';
// actions
import appAction from '../../redux/actions/app';
import accountAction from '../../redux/actions/account';
// types
import { stateType } from '../../types/types';

const AccountPage: React.FC = () => {
  const dispatch = useDispatch();

  const role = useSelector((state: stateType) => state.user.profile.role, shallowEqual);

  useEffect(() => {
    dispatch(appAction.page('Profile'));
    dispatch(accountAction.get(role.toLowerCase()));
    role === 'CUSTOMER' && dispatch(accountAction.getInfo());
    return () => {
      dispatch(accountAction.set({}));
    };
  }, []);

  return (
    <MainTemplate>
      <LayoutAccontContainer />
    </MainTemplate>
  );
};

export default AccountPage;
