/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { put, call, select } from 'redux-saga/effects';
import showError from './showError';
// api
import { apiAccount } from '../../api/account';

// actions
import accountAction from '../actions/account';

import stripeAction from '../actions/stripe';
// help
import { accountMapping } from '../../api/mapping/response/account';
import { accountErrors } from './error-messages';
import { apiSuperbill } from '../../api/superbill';
import superbillAction from '../actions/superbill';
import { superbillMapping } from 'api/mapping/response/superbill';

export function* sagaSuperbillPaymentInfo() {
  yield put(superbillAction.loader(true));
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);

  try {
    const response = yield call(apiSuperbill.paymentInfo, role.toLowerCase());
    yield put(superbillAction.setPaymentInfo(superbillMapping.superbillInfoBuild(response.data.data.item)));
    yield put(superbillAction.loader(false));
  } catch (e) {
    console.log(e);
    yield put(superbillAction.loader(false));
    showError();
  }
}

export function* sagaAdminClientSuperbillPaymentInfo(action) {
  yield put(superbillAction.loader(true));
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);

  try {
    const response = yield call(apiSuperbill.adminClientPaymentInfo, action.id);
    yield put(superbillAction.setPaymentInfo(superbillMapping.superbillInfoBuild(response.data.data.item)));
    yield put(superbillAction.loader(false));
  } catch (e) {
    console.log(e);
    yield put(superbillAction.loader(false));
    showError();
  }
}
