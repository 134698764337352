/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/display-name */
import React from 'react';
import * as ColumnItems from '../../../../molecules/columnItems/admin/groups';

export const columns = [
  {
    title: 'Topic',
    dataIndex: 'topic',
    key: 'topic',
    render: (value, row, index) => <ColumnItems.ItemName info={value} row={row} index={index} link="groups" />,
    width: '350px',
  },

  {
    title: 'Day',
    dataIndex: 'day',
    key: 'day',
    render: (value, row, index) => (
      <ColumnItems.ItemTime info={value} row={row} index={index} day={true} date={false} />
    ),
    width: '220px',
  },

  {
    title: 'Time',
    dataIndex: 'time',
    key: 'time',
    render: (value, row, index) => (
      <ColumnItems.ItemTime info={value} row={row} index={index} day={false} date={true} />
    ),
    width: '240px',
  },

  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (value, row, index) => <ColumnItems.ItemStatus info={value} row={row} index={index} />,
    width: '140px',
  },
];

columns.displayName = 'MyComponent';
