import React, { FC, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Modal, Spin } from 'antd';
import cn from 'classnames';
// componets
import Tracker from '../../tracker';
import AssessmentQuestionCard from 'components/molecules/groupQuestions/AssessmentQuestionCard';
import BooleanChoice from 'components/molecules/groupQuestions/Answers/BooleanChoice';
// actions
import userActions from '../../../../redux/actions/user';
import assessmentQuestionsActions from '../../../../redux/actions/assessment_questions';
import mainMenuAction from '../../../../redux/actions/main_menu';
// helpers
import mappingQuestions from '../../../../api/mapping/response/assessmentQuestions';
// types
import { stateType } from 'types/types';
// icons
import { ReactComponent as CloseIcon, ReactComponent as IconClose } from 'assets/image/account/close4.svg';
import icon from 'assets/image/group/around.png';
import ImgAfter from 'assets/image/questions/after.png';
// styles
import './style.scss';
import ArcProgress from 'react-arc-progress';

const flowType = 'progress';

const AssessmentProgressQuestions: React.FC<any> = () => {
  const history = useHistory();

  const loader = useSelector((state: stateType) => state.assessment_questions.loader, shallowEqual);
  const question: any = useSelector((state: stateType) => state.assessment_questions.item, shallowEqual);
  const status: string = useSelector((state: stateType) => state.assessment_questions.status, shallowEqual);
  const score: number = useSelector((state: stateType) => state.assessment_questions.score, shallowEqual).reduce(
    (a, b) => a + b,
    0,
  );
  const stateFlow = useSelector((state: stateType) => state.user.state, shallowEqual);
  const question_number: number = useSelector(
    (state: stateType) => state.assessment_questions.question_number,
    shallowEqual,
  );
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(assessmentQuestionsActions.next(flowType, question_number));
  };

  useEffect(() => {
    if (stateFlow == 'registered' && status == 'before' && score == 0) {
      assessmentQuestionsActions.status('');
      history.push('/consultation');
      // dispatch(mainMenuAction.propgressPopover(false));
    }
    if (!stateFlow && status == 'after') {
      history.push('/registration');
    }
    if (status && status !== 'before' && !visible) {
      setVisible(true);
    }
  }, [status]);

  const questionController = () => {
    switch (status) {
      case 'before2':
        return <Before />;
      case 'step':
        return <Step question={question} />;
      case 'after':
        return <After />;

      default:
        return null;
    }
  };

  return (
    <>
      {!visible && (
        <div className="check-in-start">
          <div className="change-group-container">
            <div className="change-group-container__main-title mb-sm">
              {"Let's take the first step"}
              <br />
              {'toward healing, together'}
            </div>
            <div className="change-group-container__desc-text">
              The following statements relate to experiences many people
              <br />
              have in their everyday lives. Choose the answer that best <br />
              describes your level of distress during the past month.
            </div>
            <div className="btn-container">
              <button onClick={onClick} className="btn-form btn-form-start">
                Start Your Free
                <br />
                Anxiety Assessment
              </button>
            </div>
            <div className="change-group-container__sub-text assessment-start-bottom-text">
              Your results will help us find the perfect Grouport
              <br />
              group to meet your specific needs.
            </div>
          </div>
          <div className="note-section">
            Please note: Online screening tools are not diagnostic instruments. You are encouraged to share your result
            with a physican or healthcare provider. Grouport Therapy disclaims any liability, loss, or risk incurred as
            a consequence, directly or indirectly, from the use and application of this screening.
          </div>
        </div>
      )}
      <Modal
        visible={visible}
        closable={false}
        centered
        maskTransitionName="none"
        footer={null}
        maskClosable={false}
        className={cn({
          'before-after-modal': status.includes('before'),
          'anxiety-questions-modal height-lg': status === 'step',
          'symptoms-modal': status === 'after',
        })}
      >
        <Spin spinning={loader}>{!loader && questionController()}</Spin>
      </Modal>
    </>
  );
};

export default AssessmentProgressQuestions;

const Before: FC = () => {
  const dispatch = useDispatch();
  const question_number: number = useSelector(
    (state: stateType) => state.assessment_questions.question_number,
    shallowEqual,
  );

  const onClick = () => {
    dispatch(assessmentQuestionsActions.next(flowType, question_number));
  };

  return (
    <div className="progress-questions-container">
      <div className="questions">
        <div className="questions__finish-body mb-sm">
          <img className="questions__finish-ico" src={ImgAfter} alt="Finish icon" />
          <div className="questions__congrad">Part 1 of 2 complete</div>
          <div className="questions__finish-text">
            The next set of questions will ask about feelings of sadness and low mood that are common for people to
            experience.
          </div>
        </div>
      </div>
      <button className="btn-form" onClick={onClick}>
        Begin
      </button>
    </div>
  );
};

const After: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const score: number = useSelector((state: stateType) => state.assessment_questions.score, shallowEqual).reduce(
    (a, b) => a + b,
    0,
  );

  const index = Math.min(Math.floor(score / 5), 3);

  console.log(index, score);
  const titles = ['Minimal', 'Mild', 'Moderate', 'Severe'];
  const descriptions = [
    `You are experiencing minimal symptoms of Anxiety. However, minimal symptoms can still impact your daily life. Improve your wellbeing and diminish the risk of escalation by incorporating group therapy, where you'll learn to reduce stress, maintain awareness, and shift negative habits.`,
    `You are experiencing mild symptoms of Anxiety. Leverage the power of group therapy to help you manage and reduce your symptoms further for a better quality of life and reduced risk of long-term mental health complications.`,
    `You are experiencing a multitude of Anxiety symptoms. Group therapy is a powerful tool that can help you reduce symptoms over time for better quality of life and a greater sense of emotional freedom.`,
    `You are experiencing a high degree of Anxiety symptoms, and taking action is a critical next step for you. With consistent group therapy and continued practice over time, Grouport can help you address your current roadblocks and reduce your symptoms for better mental health & wellbeing, long-term`,
  ];

  const close = () => {
    // userActions.state('group_joined');
    assessmentQuestionsActions.status('');
    assessmentQuestionsActions.saveScore();
    // assessmentQuestionsActions.clearScore();
    history.push('/consultation');
    dispatch(mainMenuAction.propgressPopover(false));
  };

  return (
    <div className="assessment-tracker-container">
      <div className="assessment-tracker-container__header">
        <ArcProgress
          progress={score / 21}
          text={`${score}`}
          animation={false}
          arcStart={180}
          arcEnd={360}
          thickness={17}
          fillColor="#d69e8c"
          textStyle={{ size: '30px', font: 'sans-serif' }}
          className="assessment-tracker-container__graph"
        >
          <div className="assessment-tracker-container__graph-desc1">
            0-4
            <br />
            Minimal
          </div>
          <div className="assessment-tracker-container__graph-desc2">
            5-9
            <br />
            Mild
          </div>
          <div className="assessment-tracker-container__graph-desc3">
            10-14
            <br />
            Moderate
          </div>
          <div className="assessment-tracker-container__graph-desc4">
            15-21
            <br />
            Severe
          </div>
        </ArcProgress>
        <div className="assessment-tracker-container__title">{titles[index]}</div>
      </div>
      <div>
        <div className="details-tracker__body">
          <div className="details-tracker__title">
            Your Anxiety test score is: <span style={{ color: '#d69e8c', paddingLeft: '2px' }}>{titles[index]}</span>
          </div>
          <div className="details-tracker__descr">{descriptions[index]}</div>
          <button className="btn-form details-tracker__btn" onClick={close}>
            Match with a Group
          </button>
        </div>
      </div>
    </div>
  );
};

enum QuestionType {
  MultiScore = 'single_score',
}

const Step = ({ question }: any) => {
  const [checkedValues, setCheckedValues] = useState<any>([]);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    initDefaultValue();
  }, []);

  const initDefaultValue = () => {
    if (question.defaultValue) {
      switch (question.questionType) {
        case QuestionType.MultiScore:
          return setCheckedValues([question.defaultValue.answerId]);

        default:
          return;
      }
    }
  };

  const answerController = () => {
    switch (question.questionType) {
      case 'single_score':
        return <BooleanChoice question={question} onChangeRadio={onChangeRadio} value={checkedValues[0]} />;

      default:
        return null;
    }
  };
  const question_number: number = useSelector(
    (state: stateType) => state.assessment_questions.question_number,
    shallowEqual,
  );

  const onChangeRadio = (value: any) => {
    setCheckedValues([value]);
  };

  const onNext = () => {
    const data = {
      questionId: question.id,
      answersIds: checkedValues,
      answerText: '',
      score: checkedValues - 1,
    };

    dispatch(
      assessmentQuestionsActions.answer(flowType, mappingQuestions.buildAnswer(data, question.answerType), () => {
        if (question.additions.serialNumber === 7 && question.additions.part === 1) {
          assessmentQuestionsActions.status('after');
          history.push('/registration');
          return;
        }
        dispatch(assessmentQuestionsActions.next(flowType, question_number));
      }),
    );
  };

  const onBefore = () => {
    if (question_number == 1) {
      window.location.reload();
    } else {
      dispatch(assessmentQuestionsActions.after(flowType, question_number));
    }
  };

  return (
    <AssessmentQuestionCard
      onBack={onBefore}
      question={{ ...question, serialNumber: question_number }}
      renderController={answerController}
      onNext={onNext}
      nextDisabled={checkedValues.length === 0}
      apply={false}
      totalStepCount={7}
      subQuestion={<>Over the last 2 weeks, how often have you been bothered by the following problem?</>}
    />
  );
};
