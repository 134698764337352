import React, { useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Spin } from 'antd';

// components
import AuthTemplate from '../../components/templates/authTemplate';
import LoginForm from '../../components/organisms/forms/login';
// components texts
import { LoginPreviewText } from '../../components/atoms/texts/authTemplate/preview-text';
import { APP } from '../../configs/app';

const LoginPage: React.FC<any> = (props: any) => {
  const textFooter = '©2022 Grouport Inc. All rights reserved.';
  const textBeforeForm = () => {
    if (props.role === 'customer') {
      return (
        <>
          <div>
            {`Don't have a Grouport account?`}{' '}
            <a href={`${APP.API.URL_SIGN_UP}${props.location.search}`} className="text-link" rel="noreferrer">
              Sign up
            </a>
          </div>
          <div>
            {`Grouport therapist?`}{' '}
            <Link to="/login-therapist" className="text-link" data-testid="login-therapist">
              Sign in
            </Link>
          </div>
        </>
      );
    } else if (props.role === 'therapist') {
      return (
        <div>
          {`Grouport member?`}{' '}
          <Link to="/login-customer" className="text-link" data-testid="login-customer">
            Sign in
          </Link>
        </div>
      );
    }
  };

  useEffect(() => {
    localStorage.setItem('role', props.role);
  }, [props.role]);

  const loader: boolean = useSelector((state: any) => state.auth.loader, shallowEqual);

  return (
    <div className="login-page custom-style">
      <AuthTemplate
        text_footer={textFooter}
        headerComponent={<LoginPreviewText role={props.role} />}
        text_before_form={textBeforeForm()}
      >
        <Spin tip="Loading..." spinning={loader}>
          <LoginForm />
        </Spin>
      </AuthTemplate>
    </div>
  );
};

export default LoginPage;
