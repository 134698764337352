import React from 'react';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
// helps
import { colors } from 'tools/consts';
// styles
import './style.scss';
import { useDispatch } from 'react-redux';
import eventAnalytics from '../../../../redux/actions/analytics';

const SelfAwarenessLog: React.FC<any> = ({ logs, loader }: any) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const onClickGoals = () => {
    history.push('/progress/logs');
    dispatch(eventAnalytics.analytics('self-awareness-log'));
  };

  return (
    <div className="log-container">
      <div className="log-container__header">
        Keep track of your thoughts and emotions on a consistent basis to help you build self awareness, acceptance, and
        mindfulness. Building these skills are all important parts of making progress.
      </div>
      {!loader && logs.length > 0 && <LogsList list={logs} />}
      {!loader && (
        <span onClick={onClickGoals} className="log-container__link">
          {logs.length === 0 ? 'Add to your log' : 'See your logs'}
        </span>
      )}
    </div>
  );
};

export default SelfAwarenessLog;

const LogsList = ({ list }: any) => {
  return (
    <div className="log-container__items">
      {list?.splice(0, 3).map((item: any) => {
        const find: any = _.find(colors, { id: item.colorNumber });
        return (
          <div key={item.id} className="log-container__wrap">
            <div className="log-container__item" style={{ backgroundColor: find?.color || '#e6f4ff' }}>
              <p className="log-container__note">{item.note}</p>
              <div className="log-container__item-footer" />
            </div>
          </div>
        );
      })}
    </div>
  );
};
