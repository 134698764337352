import _ from 'lodash';
import { UseFormSetError } from 'react-hook-form/dist/types/form';
import { IErrors } from '../types/actions/common-types';
import { ReactComponent as IconVisa } from 'assets/image/stripe/cards/visa.svg';
import { ReactComponent as IconMC } from 'assets/image/stripe/cards/mastercard.svg';
import { ReactComponent as IconAmEx } from 'assets/image/stripe/cards/american_express.svg';
import { ReactComponent as IconBancaires } from 'assets/image/stripe/cards/bancaires.svg';
import { ReactComponent as IconDiners } from 'assets/image/stripe/cards/discover_diners.svg';
import { ReactComponent as IconDiscover } from 'assets/image/stripe/cards/discover.svg';
import { ReactComponent as IconJCB } from 'assets/image/stripe/cards/jcb.svg';
import { ReactComponent as IconUnion } from 'assets/image/stripe/cards/china_pay.svg';
import upcomingAction from '../redux/actions/upcoming_events';
import moment from 'moment-timezone';
import { Moment } from 'moment';
import { timeConvertor } from '../configs/tables/admin/clients';
import { RefObject } from 'react';

interface ParseArrayType {
  id: number;
  name: string;
}

export const ParseArray = (array: ParseArrayType[]): string[] => {
  const result: string[] =
    array.length > 0
      ? array.map((item, index) => {
          if (index > 0) {
            return `, ${item.name}`;
          }

          return `${item.name}`;
        })
      : [];
  return result;
};

export const redirectValidation = (redirect: string): boolean => {
  const values: string[] = ['/forum', '/events', '/progress'];

  const find = _.find(values, (item) => {
    return item === redirect;
  });

  if (find) {
    return true;
  }

  return false;
};

export const setFormErrors = (setError: UseFormSetError<any>, errors: Array<IErrors>): void => {
  errors.forEach(({ propertyPath, message }: IErrors) =>
    setError(propertyPath, {
      type: 'manual',
      message: message,
    }),
  );
};

interface InputError {
  input: string;
  message: string;
}

export type setErrorsCallback = (errors: InputError | InputError[]) => void;

export const getErrorsCallback = (setError: UseFormSetError<any>): setErrorsCallback => {
  return (errors: InputError | InputError[]) => {
    if (errors instanceof Array) {
      const mappedErrors = errors.map(({ input, message }) => ({ propertyPath: input, message }));
      setFormErrors(setError, mappedErrors);

      return;
    }

    setFormErrors(setError, [
      {
        propertyPath: errors.input,
        message: errors.message,
      },
    ]);
  };
};

type fieldName = { name: string; formName: string };

export const getConsultationErrors = (errors: Array<IErrors>): IErrors[] => {
  const nameFields: Array<fieldName> = [
    { name: 'reasonIds', formName: 'reasons' },
    { name: 'groupTopicId', formName: 'topics' },
    { name: 'relationshipStatusId', formName: 'statuses' },
    { name: 'birthday', formName: 'date' },
    { name: 'genderId', formName: 'genders' },
    { name: 'stateId', formName: 'state' },
    { name: 'countryId', formName: 'countries' },
    { name: 'phone', formName: 'phone' },
  ];

  nameFields.forEach(({ name, formName }: fieldName) => {
    const fieldError = errors.find(({ propertyPath }) => propertyPath === name);

    if (fieldError) {
      fieldError.propertyPath = formName;
    }
  });

  return errors;
};

export const calculatePrising: any = ({ items, sub }: any) => {
  const month = sub?.productName?.replace(/\D/g, '');
  const monthsCount = month || 1;
  const defaultMontPrice = items[0]?.amount / 100 || 1;
  const totalPrice = monthsCount * defaultMontPrice;

  return {
    monthsCount,
    defaultMontPrice,
    totalPrice,
  };
};

export const getCardIcon: any = (brand: string) => {
  switch (brand) {
    case 'visa':
      return IconVisa;
    case 'mastercard':
      return IconMC;
    case 'amex':
      return IconAmEx;
    case 'cartes_bancaires':
      return IconBancaires;
    case 'diners_club':
      return IconDiners;
    case 'discover':
      return IconDiscover;
    case 'jcb':
      return IconJCB;
    case 'unionpay':
      return IconUnion;
  }
};

export const capitalizeText = (text: string): string => (text ? text[0].toUpperCase() + text.slice(1) : '');

export const getSortValue: any = (order: any) => {
  let stringOrder = '';
  const ordername = Object.keys(order).map((key) => {
    return key;
  });
  const ordervalue = Object.keys(order).map((key) => {
    return order[key];
  });

  if (!_.isEmpty(ordername) && !_.isEmpty(ordervalue)) {
    stringOrder = `&order%5B${ordername}%5D=${ordervalue}`;
  }

  return stringOrder;
};

export const checkOrdersValue: any = (order: any) => {
  const ordervalue = Object.keys(order).map((key) => {
    return order[key];
  });
  let flag = true;
  ordervalue.map((item) => {
    if (_.isArray(item)) {
      if (item.length > 0) {
        flag = false;
      }
    } else {
      if (item !== undefined) {
        flag = false;
      }
    }
  });
  return flag;
};

export function ordinal(number: number): string {
  switch (number) {
    case 1:
    case 21:
    case 31:
      return 'st';
    case 2:
    case 22:
      return 'nd';
    case 3:
    case 23:
      return 'rd';
    default:
      return 'th';
  }
}
export const momentOrdinal = (date: Moment | string): string =>
  moment(date).tz('America/New_York').format('Do').substr(-2, 2);

export const createLicenseList = (arr: Array<any> | null | undefined, isPossessive?: boolean): string | undefined => {
  if (arr?.length) {
    return arr
      ?.reduce((acc: string, item: any): string => {
        return acc + ` ${item?.name}${isPossessive ? "'s" : ''},`;
      }, '')
      .slice(0, -1);
  }

  return '';
};

export const getSearchEvent = (type: string, dispatch: any): void => {
  dispatch(upcomingAction.params({ page: 1, per_page: 8 }));
  dispatch(upcomingAction.getEventsAndPosts());
};

export const arrayOptions = (num: number): Array<{ label: number; value: number }> => {
  const arr = [];

  for (let i = 1; i <= num; i++) {
    arr.push({ label: i, value: i });
  }

  return arr;
};

export const fixDayOfWeek = (day: number, start: number, type: 'to_utc' | 'to_ny'): number => {
  const momentDay = day !== 7 ? day : 0;

  const newDayNum = type === 'to_utc' ? fixDayOfWeekToUTC(momentDay, start) : fixDayOfWeekToNY(momentDay, start);

  return +newDayNum !== 0 ? +newDayNum : 7;
};

const fixDayOfWeekToUTC = (day: number, start: number): string => {
  return moment(day, 'days').add(moment(start).subtract(1, 'days').format('HH:mm'), 'hours').format('d');
};

const fixDayOfWeekToNY = (day: number, start: number): string => {
  return moment(day, 'days')
    .add(timeConvertor(start, 'utc'), 'hours')
    .tz('UTC', true)
    .tz('America/New_York')
    .format('d');
};

export const scrollToBottom = (ref: RefObject<HTMLDivElement>): void => {
  ref.current?.scrollIntoView({ block: 'end', behavior: 'smooth' });
};

export const formattedDate = (date: Date): string => {
  return new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }).format(date);
};
