import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Spin } from 'antd';
import _ from 'lodash';
// components
import BoxAccontContainer from '../../boxAccountContainer';
import { TherapistInfoTabs, AccountTherapistItem } from '../../../organisms/account/therapist';
import { DeleteArchiveButtons } from 'components/organisms/account/customer';
import ZoomIntegrationContainer from 'components/containers/zoomIntegrationContainer';
// types
import { stateType } from '../../../../types/types';
// styles
import './style.scss';

const TherapistInfoContainer: React.FC<any> = ({ paramsId }: any) => {
  const loader = useSelector((state: stateType) => state.therapists.loader, shallowEqual);
  const loaderReminders: any = useSelector((state: stateType) => state.therapists.loaderReminders, shallowEqual);
  const item: any = useSelector((state: stateType) => state.therapists.item, shallowEqual);

  return (
    <Spin spinning={loader} style={{ height: '480px' }}>
      <div className="account-therapist-container">
        {!loader && (
          <>
            <div className="account-therapist-container__column">
              <AccountTherapistItem item={item} loader={loaderReminders} />
              <ZoomIntegrationContainer />
            </div>
            <div className="account-therapist-container__column">
              <BoxAccontContainer>
                <TherapistInfoTabs item={item} paramsId={paramsId} />
              </BoxAccontContainer>
              {!_.isEmpty(item) && <DeleteArchiveButtons canDelete={item.canDelete} canArchive={item.canArchive} />}
            </div>
          </>
        )}
      </div>
    </Spin>
  );
};

export default TherapistInfoContainer;
