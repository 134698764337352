// actions
import { ActionCallback } from '../../types/actions/common-types';
import {
  AddTherapistAction,
  ArchiveTherapistAction,
  DeleteTherapistAction,
  EditTherapistAction,
  EditTherapistNamesAction,
  EnableTherapistEmailsAction,
  EnableTherapistSmsAction,
  GetTherapistAction,
  GetTherapistGroupsAction,
  GetTherapistInvoice,
  GetTherapistSessionsHistoryAction,
  ResetTherapistPasswordAction,
  SessionsHistoryParamsAction,
  SetTherapistZoomAction,
  TherapistActions,
  TherapistGroupsAction,
  TherapistRemindersLoaderAction,
  TherapistSessionsHistoryAction,
  TherapistsLoaderAction,
  TherapistsLoaderModal,
} from '../../types/actions/therapist';
import store from '../store';

const therapistAction: TherapistActions = {
  get(id?: any, callback?: ActionCallback): GetTherapistAction {
    return {
      type: 'GET_THERAPIST',
      id,
      callback,
    };
  },

  add(data?: any, callback?: (id: any) => void): AddTherapistAction {
    return {
      type: 'ADD_THERAPIST',
      data,
      callback,
    };
  },

  edit(id?: any, data?: any, callback?: ActionCallback): EditTherapistAction {
    return {
      type: 'EDIT_THERAPIST',
      id,
      data,
      callback,
    };
  },

  getInvoice(data?: any, callback?: ActionCallback): GetTherapistInvoice {
    return {
      type: 'GET_THERAPIST_INVOICE',
      data,
      callback,
    };
  },

  editNames(id?: any, data?: any, callback?: ActionCallback): EditTherapistNamesAction {
    return {
      type: 'EDIT_THERAPIST_NAMES',
      id,
      data,
      callback,
    };
  },

  getGroups(id?: any, callback?: ActionCallback): GetTherapistGroupsAction {
    return {
      type: 'GET_THERAPIST_GROUPS',
      id,
      callback,
    };
  },

  getSessionsHistory(id?: any, callback?: ActionCallback): GetTherapistSessionsHistoryAction {
    return {
      type: 'GET_THERAPIST_SESSIONS_HISTORY',
      id,
      callback,
    };
  },

  sessionsHistoryParams(params: any): SessionsHistoryParamsAction {
    return {
      type: 'SET_SESSIONS_HISTORY_PARAMS',
      payload: {
        params,
      },
    };
  },

  setZoom(id?: any, data?: any, callback?: ActionCallback): SetTherapistZoomAction {
    return {
      type: 'SET_ZOOM_THERAPIST',
      id,
      data,
      callback,
    };
  },

  loader(status: boolean): TherapistsLoaderAction {
    return {
      type: 'THERAPISTS_LOADER',
      payload: {
        loader: status,
      },
    };
  },

  loaderReminders(status: boolean): TherapistRemindersLoaderAction {
    return {
      type: 'THERAPIST_LOADER_REMINDERS',
      payload: {
        loaderReminders: status,
      },
    };
  },

  groups(groups: any): TherapistGroupsAction {
    return {
      type: 'THERAPIST_GROUPS',
      payload: {
        groups: groups,
      },
    };
  },

  sessionsHistory(sessions: any, totalCount: number): TherapistSessionsHistoryAction {
    return {
      type: 'THERAPIST_SESSIONS',
      payload: {
        sessions,
        totalCount,
      },
    };
  },

  loaderModal(status: boolean): TherapistsLoaderModal {
    return {
      type: 'THERAPISTS_LOADER_MODAL',
      payload: {
        loaderModal: status,
      },
    };
  },

  create: (data: any): void => {
    store.dispatch({
      type: 'THERAPIST',
      payload: {
        item: data,
      },
    });
  },

  clear: (): void => {
    store.dispatch({
      type: 'THERAPIST',
      payload: {
        item: {},
      },
    });
  },

  resetPassword(id?: any, data?: any, callback?: ActionCallback): ResetTherapistPasswordAction {
    return {
      type: 'THERAPIST_RESET',
      id,
      data,
      callback,
    };
  },

  archive(id?: any, callback?: ActionCallback): ArchiveTherapistAction {
    return {
      type: 'ARCHIVE_THERAPIST',
      id,
      callback,
    };
  },

  delete(id?: any, callback?: ActionCallback): DeleteTherapistAction {
    return {
      type: 'DELETE_THERAPIST',
      id,
      callback,
    };
  },

  enableSms(id: number, enable: boolean, callback?: ActionCallback): EnableTherapistSmsAction {
    return {
      type: 'THERAPIST_ENABLED_SMS',
      id,
      enable,
      callback,
    };
  },

  enableEmails(id: number, enable: boolean, callback?: ActionCallback): EnableTherapistEmailsAction {
    return {
      type: 'THERAPIST_ENABLED_EMAILS',
      id,
      enable,
      callback,
    };
  },
};

export default therapistAction;
