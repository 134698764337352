import React, { FC } from 'react';
// components
import StripeContainer from '../../../containers/stripeContainer/StripeContainer';
// style
import './style.scss';

const ChangePayment: FC = () => {
  return (
    <div className="restart-subscriptions-payment">
      <StripeContainer />
    </div>
  );
};

export default ChangePayment;
