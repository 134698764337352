/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { formattedDate } from '../../../../tools/helpFunctions';

export const mapping = {
  recommendedGroups(data) {
    return {
      reasonIds: data.reasons,
      groupTopicId: data.topics,
      birthDay: formattedDate(data.date),
      gender: data.genders,
      stateId: data.state ? data.state : null,
    };
  },
};
