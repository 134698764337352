/*
 *
 * Intake consultation reducer
 *
 */

const initialState = {
  times: [],
  dates: [],
  loader: false,
  loaderModal: false,
};

export type intakeConsultationInitStateType = typeof initialState;

interface intakeConsultationActionTypes {
  type: string;
  payload: {
    times: [];
    dates: [];
    loader: boolean;
    loaderModal: boolean;
  };
}

const intakeConsultation = (
  state = initialState,
  action: intakeConsultationActionTypes,
): intakeConsultationInitStateType => {
  switch (action.type) {
    case 'SET_CONSULTATION_TIME':
      return Object.assign({}, state, {
        times: action.payload.times,
      });
    case 'SET_CONSULTATION_DATES':
      return Object.assign({}, state, {
        dates: action.payload.dates,
      });
    case 'INTAKE_CONSULTATION_LOADER':
      return Object.assign({}, state, {
        loader: action.payload.loader,
      });
    case 'INTAKE_CONSULTATION_LOADER_MODAL':
      return Object.assign({}, state, {
        loaderModal: action.payload.loaderModal,
      });

    default:
      return state;
  }
};

export default intakeConsultation;
