import React from 'react';
import { FormControl, MenuItem, Typography, Select, Theme } from '@material-ui/core';
import { useAppState } from '../../../../state';
import useDevices from '../../../../hooks/useDevices/useDevices';
import { makeStyles } from '@material-ui/core/styles';
import useLocalAudioToggle from '../../../../hooks/useLocalAudioToggle/useLocalAudioToggle';

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#344054',
  },
  select: {
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '28px',
    padding: '10px 14px',
    border: '1px solid #D0D5DD',
    borderRadius: 8,
  },
  testContainer: {
    paddingTop: 16,
  },
  testButton: {
    fontWeight: 400,
    fontSize: '0.9rem',
    lineHeight: '28px',
    padding: 8,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#D0D5DD',
    borderRadius: 8,
  },
}));

export default function AudioOutputList() {
  const { audioOutputDevices } = useDevices();
  const { activeSinkId, setActiveSinkId } = useAppState();
  const activeOutputLabel = audioOutputDevices.find((device) => device.deviceId === activeSinkId)?.label;
  const cssClasses = useStyles();
  const audioTest = new Audio('/assets/sound-test.mp3');
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();

  const audioTestClick = () => {
    const wasAudioEnabled = isAudioEnabled;
    if (!isAudioEnabled) {
      toggleAudioEnabled();
    }
    audioTest.play().catch((error) => {
      console.log('sound error', error);
    });
    setTimeout(() => {
      if (!wasAudioEnabled) {
        toggleAudioEnabled();
      }
    }, 2000);
  };

  return (
    <div className="inputSelect">
      {audioOutputDevices.length > 1 ? (
        <FormControl fullWidth>
          <label className={cssClasses.label}>Speaker</label>
          <Select onChange={(e) => setActiveSinkId(e.target.value as string)} value={activeSinkId} variant="outlined">
            {audioOutputDevices.map((device) => (
              <MenuItem value={device.deviceId} key={device.deviceId}>
                {device.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <>
          <Typography variant="subtitle2">Audio Output</Typography>
          <Typography>{activeOutputLabel || 'System Default Audio Output'}</Typography>
        </>
      )}
      <div className={cssClasses.testContainer}>
        <button className={cssClasses.testButton} onClick={audioTestClick}>
          Test speaker
        </button>
      </div>
    </div>
  );
}
