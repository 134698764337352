/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

export const mapping = {
  addTherapistBuild(data) {
    return {
      firstname: data.firstname,
      lastname: data.lastname,
      email: data.email,
      phone: data.phone.replace(/ /g, '').replace('+1', ''),
      licenseIds: data.licenseIds,
      stateId: data.stateId,
      zoomClientId: data.zoomClientId ? data.zoomClientId : null,
      setPasswordUrl: `${window.location.origin}/therapist-password/`,

      biography: data.biography,
      practiceYears: Number(data.practiceYears),
      joinedDate: data.joinedDate,
      avatarUuid: data.avatarUuid,
      specialtyIds: data.specialtyIds,
      groupTopicIds: data.groupTopicIds,
      titleId: data.titleId,
    };
  },
};
