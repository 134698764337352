import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'antd';
// components
import MainTemplate from '../../../components/templates/mainTemplate';
import Tracker from '../../../components/organisms/tracker';
// actions
import appAction from '../../../redux/actions/app';
import Box from '../../../components/containers/box';
import ProgressTabs from '../../../components/containers/progressTabs';
// icons
import { ReactComponent as CloseIcon } from 'assets/image/account/close4.svg';
// styles
import './style.scss';
import eventAnalytics from '../../../redux/actions/analytics';

const ProgressPage: React.FC = () => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    dispatch(appAction.page('My Progress'));
    dispatch(eventAnalytics.analytics('symptom-tracker'));
  }, []);

  return (
    <MainTemplate>
      <div className="progress-page-container">
        <h2 className="text-page-header">My Progress</h2>
        <p className="text-page-subtitle">
          Below, you can see how your symptoms are changing over time. This will give you a clear picture of your
          progress and how you can stay on track to reach your long term goals.
        </p>
        <Box classes="progress-block-box">
          <Tracker onMore={() => setVisible(!visible)} />
        </Box>
        <Box classes="progress-block-box">
          <ProgressTabs />
        </Box>
      </div>
      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        centered
        footer={null}
        closeIcon={<CloseIcon />}
        className="more-tracker"
      >
        <Tracker
          onMore={() => setVisible(!visible)}
          moreText="See less"
          // TODO add a real text from server
          description="* Your score is based on the PHQ-ADS, an assessment developed by doctors which combines the PHQ-9 and GAD-7 scales that measures symptoms of anxiety and depression."
        />
      </Modal>
    </MainTemplate>
  );
};

export default ProgressPage;
