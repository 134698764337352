/*
 *
 * Main menu reducer
 *
 */
const initialState = {
  menu: [],
  showMinimal: false,
  showDrawer: false,
  propgressPopover: false,
};

export type menuInitStateType = typeof initialState;

interface menuActionTypes {
  type: string;
  payload: {
    menu: [];
    showMinimal: boolean;
    showDrawer: boolean;
    propgressPopover: boolean;
  };
}

const main_menu = (state = initialState, action: menuActionTypes): menuInitStateType => {
  switch (action.type) {
    case 'MENU':
      return Object.assign({}, state, {
        menu: action.payload.menu,
      });

    case 'MENU_SHOW_MINIMAL':
      return Object.assign({}, state, {
        showMinimal: action.payload.showMinimal,
      });
    case 'MENU_SHOW_DRAWER':
      return Object.assign({}, state, {
        showDrawer: action.payload.showDrawer,
      });
    case 'MENU_PROGRESS_POPOVER':
      return Object.assign({}, state, {
        propgressPopover: action.payload.propgressPopover,
      });

    default:
      return state;
  }
};

export default main_menu;
