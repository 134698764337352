/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { AxiosResponse } from 'axios';
// config
import http from '../instance';

export const apiAcuity = {
  getTimes: async (data: any): Promise<AxiosResponse> => {
    const api = await http();
    return api.post(`customer/get-available-time`, data);
  },
  getDates: async (data: any): Promise<AxiosResponse> => {
    const api = await http();
    return api.post(`customer/get-available-dates`, data);
  },
  created: async (data: any): Promise<AxiosResponse> => {
    const api = await http();
    return api.patch(`customer/create-appointments`, data);
  },
};
