import React, { useState } from 'react';
// components
import InputField from '../../inputField';
import { useWindowResize } from 'beautiful-react-hooks';
import { Controller } from 'react-hook-form';
import { DatePicker, Select } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { SelectTag } from '../../../atoms/utils/tag';
import SelectOption, { OptionType } from '../select/SelectOption';
import moment from 'moment-timezone';

const { Option } = Select;

interface InputsType {
  errors: string | undefined;
  register?: any;
  watch?: any;
  control?: any;
  options?: any;
  defaultValue?: any;
  name?: string;
  label?: string;
  placeholder?: string;
}

export const AddEventInput: React.FC<InputsType> = ({
  errors,
  register,
  name,
  label,
  placeholder,
  defaultValue,
}: InputsType) => {
  return (
    <InputField name={name} label={label} errors={errors} icon={false}>
      <input
        id={name}
        className={errors && 'error'}
        placeholder={placeholder}
        {...register(`${name}`, {
          required: `Enter Events ${label}`,
          minLength: {
            value: 3,
            message: `${label} must have at least 3 characters`,
          },
          maxLength: {
            value: name === 'title' ? 200 : 1020,
            message: `maximum ${name === 'title' ? 200 : 1020} characters`,
          },
        })}
        defaultValue={defaultValue ? defaultValue : ''}
      />
    </InputField>
  );
};

export const CheckboxSelect: React.FC<InputsType> = ({
  errors,
  control,
  options,
  name,
  label,
  placeholder,
  defaultValue,
}: InputsType) => {
  const [width, setWidth] = useState(window.innerWidth);

  useWindowResize(() => {
    setWidth(window.innerWidth);
  });

  const getCount = () => {
    if (!width || width > 768) {
      return 'responsive';
    } else if (width < 768 && width > 480) {
      return 2;
    } else {
      return 1;
    }
  };

  return (
    <InputField name={name} label={label} errors={errors} icon={false}>
      <Controller
        control={control}
        name={name || ''}
        rules={{ required: 'choose at least one option' }}
        render={({ field: { onChange, value, onBlur } }) => (
          <Select
            placeholder={placeholder}
            className={errors ? 'form-select form-select__error' : 'form-select'}
            dropdownClassName="form-dropdown"
            mode="multiple"
            style={{ width: '100%' }}
            onChange={onChange}
            onBlur={onBlur}
            showArrow
            allowClear
            showSearch={false}
            suffixIcon={<CaretDownOutlined />}
            searchValue={''}
            onDropdownVisibleChange={(open) => !open && onBlur()}
            getPopupContainer={(trigger) => trigger.parentNode}
            maxTagCount={getCount()}
            tagRender={(props) => <SelectTag {...props} options={options} />}
            defaultValue={defaultValue ? defaultValue : []}
          >
            {options.map((option: OptionType) => (
              <Option key={option.key} value={option.value}>
                <SelectOption option={option} selectedValues={value} />
              </Option>
            ))}
          </Select>
        )}
      />
    </InputField>
  );
};

export const AddTime: React.FC<InputsType> = ({
  errors,
  control,
  name,
  label,
  placeholder,
  defaultValue,
}: InputsType) => {
  return (
    <InputField name={name} label={label} errors={errors} icon={false}>
      <Controller
        control={control}
        name={name || ''}
        rules={{ required: `Choose ${name} time` }}
        render={({ field: { onChange } }) => {
          return (
            <DatePicker
              className="form-time-select"
              picker={'time'}
              suffixIcon={null}
              onChange={onChange}
              format="h:mm A"
              use12Hours={true}
              minuteStep={5}
              placeholder={placeholder}
              defaultValue={defaultValue && moment(defaultValue).tz('America/New_York')}
            />
          );
        }}
      />
    </InputField>
  );
};

export const EventsSelect: React.FC<InputsType> = ({
  errors,
  control,
  options,
  name,
  label,
  placeholder,
  defaultValue,
}: InputsType) => {
  return (
    <InputField name={name} label={label} errors={errors} icon={false}>
      <Controller
        control={control}
        name={name || ''}
        rules={{ required: 'choose at least one option' }}
        render={({ field: { onChange } }) => (
          <Select
            className="form-select"
            dropdownClassName="form-dropdown"
            placeholder={placeholder}
            style={{ width: '100%' }}
            onChange={(value: any) => {
              onChange(value);
            }}
            options={options}
            allowClear
            suffixIcon={<CaretDownOutlined />}
            defaultValue={defaultValue ? defaultValue : undefined}
          />
        )}
      />
    </InputField>
  );
};
