import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './redux/store';
import App from './components/app/App';
import AppStateProvider from './state';

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

// serviceWorker disable by default
// import * as serviceWorker from './serviceWorker';

// React.StrictMode add this for find errors app
ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <AppStateProvider>
        <GoogleReCaptchaProvider
          reCaptchaKey="6LexXjkoAAAAAGHbnwZSjEAQPjWX3nSg7pzcqjzk"
          scriptProps={{ async: false, defer: false, appendTo: 'head', nonce: undefined }}
        >
          <App />
        </GoogleReCaptchaProvider>
      </AppStateProvider>
    </Provider>
  </BrowserRouter>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
