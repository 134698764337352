import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';

// actions
import groupsAction from '../../../../../redux/actions/groups';
import therapistAction from '../../../../../redux/actions/therapist';
// components
import {
  Bio,
  YearsinPracticeInput,
  LicensureSelect,
  SpecialtiesSelect,
  Date,
  PossibleGroupTopics,
} from '../../../../molecules/inputsBlocks/edit';
import SubmitButton from '../../../../atoms/form/Button';
import { FormSearchSelect } from '../../../../molecules/inputsBlocks/select';
import { CancelButton } from 'components/atoms/form/CancelButton';
// helps
import { mapping } from 'api/mapping/request/editTherapist';
// types
import { stateType } from 'types/types';
// style
import './style.scss';
import therapistsAction from '../../../../../redux/actions/therapists';

interface FormData {
  biography: string;
  titleId: any;
  practiceYears?: number;
  stateId?: number;
  licenseIds?: any;
  specialtyIds?: any;
  groupTopicIds: any;
  joinedDate?: string;
  avatarUuid?: string;
}

const FormEdit: React.FC<any> = ({ handleCancel }: any) => {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<FormData>();
  const dispatch = useDispatch();

  const params = useParams<{ id: string }>();
  const history = useHistory();

  const isGroup = history.location.pathname.includes('all_groups');

  const {
    id,
    biography,
    practiceYears,
    licenses,
    joinedDate,
    specialties,
    state,
    groupTopics,
    avatar,
    title,
  }: any = useSelector((state: stateType) => state.therapists.item);
  const file: any = useSelector((state: stateType) => state.app.file);

  useEffect(() => {
    dispatch(therapistsAction.getOptions());
  }, []);

  useEffect(() => {
    setValue('biography', biography);
    setValue('practiceYears', practiceYears);
    setValue(
      'stateId',
      state.length > 0
        ? state.map((item: any) => {
            return item.id;
          })
        : [],
    );
    setValue(
      'licenseIds',
      licenses.length > 0 &&
        licenses.map((item: any) => {
          return item.id;
        }),
    );
    setValue(
      'specialtyIds',
      specialties.length > 0 &&
        specialties.map((item: any) => {
          return item.id;
        }),
    );
    setValue(
      'groupTopicIds',
      groupTopics.length > 0 &&
        groupTopics.map((item: any) => {
          return item.id;
        }),
    );
    setValue('joinedDate', joinedDate);
    setValue('avatarUuid', avatar?.uuid);
    setValue('titleId', title?.id || 1);
  }, []);

  useEffect(() => {
    file && setValue('avatarUuid', file.uuid);
  }, [file]);

  const submitCallback = () => {
    handleCancel();

    isGroup && dispatch(groupsAction.id(params.id));
  };

  const onSubmit = (data: FormData) => {
    dispatch(therapistAction.edit(id, mapping.editTherapistBuild(data), submitCallback));
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="form-edit validation-form">
        <div className="form-edit__container form-edit-therapist">
          <div className="form-edit-therapist__rows-container">
            <Bio
              errors={errors.biography ? errors.biography.message : undefined}
              register={register}
              control={control}
              defaultValue={biography}
            />
            <div className="form-edit__rows-container">
              <div className="form-edit__left-rows">
                <div className="form-edit__row">
                  <div className="form-edit__column">
                    <YearsinPracticeInput
                      errors={errors.practiceYears ? errors.practiceYears.message : undefined}
                      register={register}
                      defaultValue={practiceYears}
                    />
                  </div>
                  <div className="form-edit__column">
                    <LicensureSelect
                      errors={errors.licenseIds ? errors.licenseIds.message : undefined}
                      register={register}
                      control={control}
                      defaultValue={
                        licenses.length > 0 &&
                        licenses.map((item: any) => {
                          return item.id;
                        })
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="form-edit__right-rows">
                <div className="form-edit__row">
                  <div className="form-edit__column">
                    <FormSearchSelect
                      name="stateId"
                      optionsName="state"
                      label="State"
                      errors={errors.stateId?.message}
                      control={control}
                      mode="multiple"
                      maxTagCount="responsive"
                      placeholder="Select state"
                      defaultValue={
                        state.length > 0
                          ? state.map((item: any) => {
                              return item.id;
                            })
                          : []
                      }
                      rules={{ required: 'Choice state' }}
                      filterOption={(input: string, option: any) =>
                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    />
                  </div>
                  <div className="form-edit__column">
                    <Date
                      errors={errors.joinedDate ? errors.joinedDate.message : undefined}
                      register={register}
                      control={control}
                      defaultValue={joinedDate}
                    />
                  </div>
                </div>
              </div>
            </div>

            <SpecialtiesSelect
              errors={errors.specialtyIds ? errors.specialtyIds.message : undefined}
              register={register}
              control={control}
              defaultValue={
                specialties.length > 0 &&
                specialties.map((item: any) => {
                  return item.id;
                })
              }
            />
            <PossibleGroupTopics
              errors={errors.groupTopicIds?.message}
              control={control}
              register={register}
              defaultValue={
                groupTopics.length > 0 &&
                groupTopics.map((item: any) => {
                  return item.id;
                })
              }
            />
          </div>
        </div>
        <div className="form-edit-therapist__btn-container">
          <div className="buttons form-edit-therapist__buttons">
            <SubmitButton name="Save" className="form-edit__submit-btn" />
            <CancelButton name="Cancel" cancel={handleCancel} />
          </div>
        </div>
      </form>
    </>
  );
};

export default FormEdit;
