import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
// components
import MainTemplate from '../../../components/templates/mainTemplate';
import GroupsTable from '../../../components/organisms/tables/admin/groups';
import TableContainer from '../../../components/containers/tableContainer';
import { HeaderPageContainer } from '../../../components/containers/pageContainer';
import NavigationContainer from '../../../components/containers/navigationContainer';
import AddGroup from '../../../components/organisms/modals/addGroup';
// actions
import appAction from '../../../redux/actions/app';
import groupsAction from '../../../redux/actions/groups';
// types
import { FilterOptionsRole } from '../../../types/actions/common-types';

const GroupsPage: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(appAction.page('All Groups'));
    dispatch(groupsAction.get());

    return () => {
      dispatch(groupsAction.setOptionsIsReceived(FilterOptionsRole.Empty));
    };
  }, []);

  return (
    <MainTemplate>
      <HeaderPageContainer>
        <h2 className="text-page-header">All Groups</h2>
        <AddGroup />
      </HeaderPageContainer>
      <NavigationContainer name="admin" table="groups" />
      <TableContainer>
        <GroupsTable />
      </TableContainer>
    </MainTemplate>
  );
};

export default GroupsPage;
