import {
  AddEventAction,
  AddEventClientAction,
  DeleteEventTopicAction,
  EditEventAction,
  EventLoaderAction,
  EventLoaderModalAction,
  EventsAction,
  GetByIDEventAction,
  GetEventAction,
  RemoveEventClientAction,
  SearchEventClientsAction,
} from '../../types/actions/admin-events';
import { ActionCallback, ActionIdCallback } from '../../types/actions/common-types';
import { mappingEvents } from '../../api/mapping/response/events';

const eventsAction: EventsAction = {
  get(callback?: ActionCallback): GetEventAction {
    return {
      type: 'GET_COMMUNITY_EVENTS',
      callback,
    };
  },
  setEvents(data?: any) {
    return {
      type: 'SET_EVENTS',
      payload: {
        events: mappingEvents.buildArray(data?.items ? data.items : data),
        count: data?.totalCount ? data?.totalCount : data?.length,
      },
    };
  },
  setPastEventsPreview(data?: any) {
    return {
      type: 'SET_PAST_EVENTS_PREVIEW',
      payload: {
        pastEventsPreview: data,
      },
    };
  },
  setUpcomingEventsPreview(data?: any) {
    return {
      type: 'SET_UPCOMING_EVENTS_PREVIEW',
      payload: {
        upcomingEventsPreview: data,
      },
    };
  },
  getByID(id: any, callback?: ActionCallback): GetByIDEventAction {
    return {
      type: 'GET_COMMUNITY_EVENT',
      id,
      callback,
    };
  },
  setEvent(data?: Record<string, unknown>) {
    return {
      type: 'SET_EVENT',
      payload: {
        event: mappingEvents.buildItem(data),
      },
    };
  },
  add(data: any, callback?: ActionIdCallback<any>): AddEventAction {
    return {
      type: 'ADD_COMMUNITY_EVENT',
      data,
      callback,
    };
  },
  edit(id?: any, data?: any, callback?: ActionCallback): EditEventAction {
    return {
      type: 'EDIT_COMMUNITY_EVENT',
      id,
      data,
      callback,
    };
  },
  deleteTopic(id: any, paramsId: any, callback?: ActionCallback): DeleteEventTopicAction {
    return {
      type: 'DELETE_COMMUNITY_EVENT_TOPIC',
      id,
      paramsId,
      callback,
    };
  },
  search(data?: string, callback?: ActionCallback): SearchEventClientsAction {
    return {
      type: 'EVENT_CLIENTS_SEARCH',
      data,
      callback,
    };
  },
  addClient(data?: any, callback?: ActionCallback): AddEventClientAction {
    return {
      type: 'EVENT_ADD_CLIENT',
      data,
      callback,
    };
  },
  removeClient(data?: any, callback?: ActionCallback): RemoveEventClientAction {
    return {
      type: 'EVENT_REMOVE_CLIENT',
      data,
      callback,
    };
  },
  params(params: Record<string, unknown>) {
    return {
      type: 'COMMUNITY_EVENTS_PARAMS',
      payload: {
        params: params,
      },
    };
  },
  removeEvent(id: any, callback: any) {
    return {
      type: 'COMMUNITY_EVENTS_DELETE',
      payload: {
        id: id,
      },
      callback,
    };
  },
  loader(status: boolean): EventLoaderAction {
    return {
      type: 'COMMUNITY_EVENTS_LOADER',
      payload: {
        loader: status,
      },
    };
  },
  loaderModal(status: boolean): EventLoaderModalAction {
    return {
      type: 'COMMUNITY_EVENTS_LOADER_MODAL',
      payload: {
        loaderModal: status,
      },
    };
  },
};

export default eventsAction;
