/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { AxiosResponse } from 'axios';
// config
import http from '../instance';

export const apiEvents = {
  getEvents: async (role: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.get(`${role}/community-events/upcoming`);
  },

  getEventsAndPosts: async (role: string, allParams: any, period_type: string): Promise<AxiosResponse> => {
    const sort = allParams?.sort ? `order_id=${allParams?.sort}&` : '';
    const length = allParams?.length ? `length_id=${allParams?.length}&` : '';
    const topic = allParams?.topic ? `group_topic_ids=${allParams?.topic}&` : '';
    const type = period_type === 'past' ? `type_id=2&` : allParams.type ? `type_id=${allParams?.type}&` : '';
    const name = allParams.name ? `name=${allParams?.name}` : '';
    const api = await http();
    return api.get(
      `${role}/community-events?${sort}&${length}${topic}${type}${name.replace(
        '&',
        '%26',
      )}&period_type=${period_type}&per_page=${
        period_type === 'upcoming'
          ? '100'
          : allParams?.page && allParams?.per_page
          ? allParams?.page * allParams?.per_page
          : '8'
      }&page=1`,
    );
  },

  getEventsAndPostsAdmin: async (role: string, allParams: any): Promise<AxiosResponse> => {
    const sort = allParams?.sort ? `order_id=${allParams?.sort}&` : '';
    const length = allParams?.length ? `length_id=${allParams?.length}&` : '';
    const topic = allParams?.topic ? `group_topic_ids=${allParams?.topic}&` : '';
    const per_page = allParams?.per_page ? `per_page=${allParams?.per_page}&` : 'per_page=10&';
    const period_type = allParams?.period_type ? `period_type=${allParams?.period_type}&` : '';
    const type = allParams?.period_type === 'past' ? `type_id=2&` : allParams.type ? `type_id=${allParams?.type}&` : '';
    const name = allParams.name ? `name=${allParams?.name}` : '';
    const api = await http();
    return api.get(
      `${role}/community-events?${sort}&${length}${topic}${type}${name.replace(
        '&',
        '%26',
      )}&${period_type}${per_page}page=${allParams?.page ? allParams?.page : 1}`,
    );
  },

  getPastEventsAndPostsAdminPreview: async (): Promise<AxiosResponse> => {
    const api = await http();
    return api.get(`admin/community-events/client/null/preview?&type_id=2&&period_type=past&per_page=8&page=1`);
  },

  getUpcomingEventsAndPostsAdminPreview: async (): Promise<AxiosResponse> => {
    const api = await http();
    return api.get(`admin/community-events/client/null/preview?period_type=upcoming&per_page=8&page=1`);
  },

  getPosts: async (role: string, params?: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.get(`${role}/community-events/past${params ? `?${params}` : ''}`);
  },

  changeMarkEvent: async (role: string, id?: number): Promise<AxiosResponse> => {
    const api = await http();
    return api.patch(`${role}/community-event/${id}/mark`);
  },

  getLengthsFilters: async (): Promise<AxiosResponse> => {
    const api = await http();
    return api.get(`/community-event/time-lengths`);
  },

  getTypesFilters: async (): Promise<AxiosResponse> => {
    const api = await http();
    return api.get(`/community-event/types`);
  },

  getTopicsFilters: async (): Promise<AxiosResponse> => {
    const api = await http();
    return api.get(`/customer/group-topics-extended`);
  },

  getOrdersFilters: async (role: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.get(`${role}/community-event/orders`);
  },

  register: async (id: number, role: string, isRegister: boolean): Promise<AxiosResponse> => {
    const api = await http();
    return api.patch(`${role}/community-event/${id}/${isRegister ? 'unregister' : 'register'}`);
  },
};
