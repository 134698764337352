/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

const mappingQuestions = {
  buildOptions(array) {
    return array.map((item) => {
      return {
        key: item.id,
        value: item.id,
        label: item.answer,
      };
    });
  },
  buildAnswer(data, type) {
    const { questionId, answersIds, answerText, rating, score } = data;

    switch (type) {
      case 'multi':
        return {
          answerIds: answersIds,
          questionId: questionId,
          answerType: type,
        };

      case 'single_score':
        return {
          answerId: answersIds[0],
          questionId: questionId,
          answerType: type,
          score,
        };

      case 'single':
        return {
          answerId: answersIds[0],
          questionId: questionId,
          answerType: type,
        };

      case 'arbitrary':
        return {
          answerText: answerText,
          questionId: questionId,
          answerType: type,
        };

      case 'evaluation':
        return {
          rating: rating,
          answerText: answerText,
          questionId: questionId,
          answerType: type,
        };

      case 'multi_with_arbitrary':
        return {
          answerIds: answersIds,
          answerText: answerText,
          questionId: questionId,
          answerType: type,
        };

      case 'group':
        return {
          groupId: null,
          questionId: questionId,
          answerType: type,
        };

      default:
        return {};
    }
  },

  buildQuestion(item, type) {
    switch (type) {
      case 'next':
        return {
          ...item.question,
          answers: item.answers.map((item, i) => {
            item.id = i + 1;
            return item;
          }),
          defaultValue: item.value,
          maxQuestionNumber: item.maxQuestionNumber,
        };

      case 'after':
        return {
          ...item.question,
          answers: item.answers.map((item, i) => {
            item.id = i + 1;
            return item;
          }),
          defaultValue: item.value,
          maxQuestionNumber: item.maxQuestionNumber,
        };

      default:
        break;
    }
  },
};

export default mappingQuestions;
