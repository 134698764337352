/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { ActionCallback } from '../../types/actions/common-types';
import store from '../store';

import {
  NotificationClientAction,
  NotificationClientLoaderAction,
  NotificationDiscountAction,
} from '../../types/actions/notificationClient';

const notificationClientAction = {
  getNotification(callback?: ActionCallback): NotificationClientAction {
    return {
      type: 'GET_NOTIFICATION',
      callback,
    };
  },
  readNotification(id?: any, answerParams = true, callback?: () => void): any {
    return {
      type: 'POST_NOTIFICATION',
      id,
      answerParams,
      callback,
    };
  },
  loader(status: boolean): NotificationClientLoaderAction {
    return {
      type: 'NOTIFICATION_CLIENT_LOADER',
      payload: {
        loader: status,
      },
    };
  },
  create(data: any) {
    store.dispatch({
      type: 'NOTIFICATION_CLIENT',
      payload: {
        item: data,
      },
    });
  },
  notificationDiscount(isDiscount: boolean): NotificationDiscountAction {
    return {
      type: 'NOTIFICATION_CLIENT_DISCOUNT',
      payload: {
        isDiscount,
      },
    };
  },
};

export default notificationClientAction;
