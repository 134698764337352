export const animHeight = {
  initial: {
    height: 0,
    opacity: 0,
  },
  visible: {
    height: 'auto',
    opacity: 1,
    transition: {
      delay: 0.5,
      duration: 0.5,
    },
  },
  visibleQuestions: {
    height: 'auto',
    opacity: 1,
    overflow: 'hidden',
    transition: {
      duration: 0.4,
      ease: 'easeOut',
    },
  },
  exit: {
    height: 0,
    opacity: 0,
    paddingBottom: 0,
    paddingTop: 0,
    transition: {
      duration: 0.5,
    },
  },
  exitSlow: {
    height: 0,
    opacity: 0,
  },
};

export const animSearch = {
  initial: {
    height: 0,
    opacity: 0,
  },
  visible: {
    height: 'auto',
    opacity: 1,
    overflow: 'hidden',
    transition: {
      duration: 0.5,
      ease: 'easeOut',
    },
  },
  exit: {
    height: 0,
    opacity: 0,
    transition: {
      duration: 0.5,
      ease: 'easeOut',
    },
  },
};

export const animTextarea = {
  initial: {
    height: 0,
    opacity: 0,
  },
  visible: {
    height: 'auto',
    opacity: 1,
    transition: {
      duration: 0.9,
    },
  },
  exit: {
    height: 0,
    opacity: 0,
    transition: {
      duration: 0.9,
    },
  },
};

export const animScale = {
  initial: {
    scale: 0,
  },
  visible: {
    scale: 1,
  },
  exit: {
    scale: 0,
    transition: {
      duration: 0.4,
    },
  },
};

export const showTherapist = {
  initial: {
    height: 0,
    opacity: 0,
  },
  visible: {
    height: 'auto',
    opacity: 1,
    overflow: 'hidden',
    transition: {
      duration: 0.5,
    },
  },
  exit: {
    height: 0,
    opacity: 0,
    transition: {
      duration: 0.5,
    },
  },
};

export const animCard = {
  initial: {
    x: -100,
    opacity: 0,
  },
  visible: {
    x: 0,
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.8,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.5,
    },
  },
};
