import { ActionCallback, GroupActionStatus } from 'types/actions/common-types';
import store from '../store';
// actions
// import mainMenuAction from './main_menu';
import { СancelGroupActions } from 'types/actions/cancel-group';

// TODO: change 'cansel' to 'cancel' project-wide

const cancelGroupActions: СancelGroupActions = {
  next(number?: number, callback?: ActionCallback): any {
    return {
      type: 'GET_CANCEL_NEXT_QUESTION',
      number,
      callback,
    };
  },

  after(number?: number, callback?: ActionCallback): any {
    return {
      type: 'GET_CANCEL_AFTER_QUESTION',
      number,
      callback,
    };
  },

  answer(data: any, callback: ActionCallback): any {
    return {
      type: 'PUSH_CANCEL_ANSWER_QUESTION',
      data,
      callback,
    };
  },

  cansel(callback?: ActionCallback) {
    return {
      type: 'CANCEL_CHANGE_GROUP',
      callback,
    };
  },

  status: (status: GroupActionStatus): void => {
    store.dispatch({
      type: 'QUESTION_STATUS',
      payload: {
        status: status,
      },
    });
  },

  create: (data: any): any => {
    store.dispatch({
      type: 'QUESTION_ITEM',
      payload: {
        item: data,
      },
    });
  },

  loader(status: boolean): any {
    return {
      type: 'QUESTION_LOADER',
      payload: {
        loader: status,
      },
    };
  },

  progress(value: number): any {
    return {
      type: 'QUESTION_PROGRESS',
      payload: {
        progress: value,
      },
    };
  },

  cancelRenewal(flowType: string, callback: ActionCallback): any {
    return {
      type: 'CANCEL_SUBSCRIPTION_RENEWAL',
      flowType: flowType,
      callback,
    };
  },
};

export default cancelGroupActions;
