import React, { FC } from 'react';
import cn from 'classnames';
import { useHistory } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
// components
import ProfileAvatar from '../profile/avatar';
import AsideItem from './AsideItem';
// types
import { stateType } from 'types/types';
import { SimpleLogo } from 'components/atoms/logos';
import { MenuFooterTextBox } from 'components/atoms/texts/mainMenu';
// actions
import mainMenuAction from 'redux/actions/main_menu';
import MenuToggler from './MenuToggler';
import eventAnalytics from '../../../redux/actions/analytics';

const DefaultAside: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { page } = useSelector((state: stateType) => state.app, shallowEqual);
  const { menu } = useSelector((state: stateType) => state.main_menu);
  const { profile } = useSelector((state: stateType) => state.user, shallowEqual);
  const { showMinimal } = useSelector((state: stateType) => state.main_menu, shallowEqual);

  const onClick = () => {
    dispatch(mainMenuAction.showDrawer(false));
    dispatch(eventAnalytics.analytics('visit-profile', { location: 'sidebar' }));
    history.push('/profile');
  };

  return (
    <>
      <div className="main-menu__logo-header">
        <SimpleLogo click={true} />
      </div>
      <div className={cn('main-menu', { mini: showMinimal })}>
        <div className="main-menu__top">
          <div className={cn('main-menu__profile', { active: page === 'Profile' })} onClick={onClick}>
            <ProfileAvatar aside={true} />
            <span className="main-menu__profile-name">{profile.username ? profile.username : profile.email}</span>
          </div>
          <div className="main-menu__list">
            {menu?.map((item: any) => (
              <AsideItem key={item.name} item={item} />
            ))}
          </div>
          <MenuToggler />
        </div>
        <div className="main-menu__footer">
          <MenuFooterTextBox />
        </div>
      </div>
    </>
  );
};

export default DefaultAside;
