import React, { FC, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
// components
import EditCardModal from 'components/organisms/modals/editCard';
// actions
import accountAction from 'redux/actions/account';
import { getCardIcon, capitalizeText } from 'tools/helpFunctions';
// icons
import Icon from '@ant-design/icons';
// types
import { stateType } from 'types/types';
// styles
import './style.scss';

const CardDetails: FC = () => {
  const dispatch = useDispatch();
  const { paymentInfo }: any = useSelector((state: stateType) => state.account.item, shallowEqual);
  const [visible, setVisible] = useState(false);

  const open = () => {
    setVisible(true);
    dispatch(accountAction.editCheckToken());
  };

  return (
    <div className="customer-card">
      <div className="customer-card__card">
        {paymentInfo?.brand && <Icon component={getCardIcon(paymentInfo?.brand)} style={{ fontSize: '60px' }} />}
      </div>
      <div className="customer-card__body">
        <div className="customer-card__payments">
          <div className="customer-card__det">Payment details </div>
          <button className="customer-card__btn" onClick={open}>
            Change
          </button>
        </div>
        <div className="customer-card__ending">
          {capitalizeText(paymentInfo?.brand)} ending in {paymentInfo?.last4}
        </div>
        <div className="customer-card__expires">
          Expires {paymentInfo?.expMonth}/{String(paymentInfo?.expYear)?.slice(-2)}
        </div>
      </div>
      <EditCardModal visible={visible} setVisible={setVisible} />
    </div>
  );
};

export default CardDetails;
