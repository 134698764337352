import React, { createContext, useState } from 'react';

type ParticipantMenuContextType = {
  isParticipantMenuOpen: boolean;
  setIsParticipantMenuOpen: (isParticipantMenuOpen: boolean) => void;
};

export const ParticipantMenuContext = createContext<ParticipantMenuContextType>(null!);

export const ParticipantMenuProvider: React.FC = ({ children }) => {
  const [isParticipantMenuOpen, setIsParticipantMenuOpen] = useState(false);

  return (
    <ParticipantMenuContext.Provider
      value={{
        isParticipantMenuOpen,
        setIsParticipantMenuOpen,
      }}
    >
      {children}
    </ParticipantMenuContext.Provider>
  );
};
