import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
// components
import SelectGroups from '../../changeGroup/groups';
import LoadingModal from '../../../molecules/modals';
// style
import './style.scss';
// types
import { stateType } from '../../../../types/types';
import { SearchGroupQuery } from '../../../../types/actions/common-types';
// actions
import choiceGroupAction from '../../../../redux/actions/choice_group';
import restartAction from '../../../../redux/actions/restart_subscriptions';
// configs
import { TOTAL_GROUPS_NUMBER } from '../../../../tools/consts';

const RestartSubscriptionsGroups: FC = () => {
  const ref = useRef<HTMLDivElement>(null);

  const [page, setPage] = useState<number>(1);

  const dispatch = useDispatch();

  const { loaderModal, item }: any = useSelector((state: stateType) => state.choice_group, shallowEqual);

  useEffect(() => {
    dispatch(choiceGroupAction.get(SearchGroupQuery.Restart));
    return () => {
      setPage(1);
      choiceGroupAction.params({ page: 1 });
    };
  }, []);

  const onGoBack = useCallback((): void => {
    if (page > 1) {
      setPage(page - 1);
    } else {
      dispatch(restartAction.state(2));
    }
  }, []);

  const onSeeOtherGroup = (): void => {
    if (page * TOTAL_GROUPS_NUMBER < item.length) {
      setPage(page + 1);
    } else {
      setPage(1);
    }
  };

  useEffect(() => {
    ref?.current?.scrollIntoView();
  }, [page]);

  return (
    <div className="restart-subscriptions-groups custom-style-group" ref={ref}>
      {!loaderModal && Array.isArray(item) && (
        <SelectGroups
          onSeeOtherGroup={onSeeOtherGroup}
          onGoBack={onGoBack}
          groups={item.slice((page - 1) * TOTAL_GROUPS_NUMBER, page * TOTAL_GROUPS_NUMBER)}
        />
      )}
      {loaderModal && (
        <LoadingModal
          visible={loaderModal}
          title="You’re all set!"
          text="Hang on while we find the group that’s best for you."
        />
      )}
    </div>
  );
};

export default RestartSubscriptionsGroups;
