import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
// components
import MainTemplate from '../../components/templates/mainTemplate';
import { HeaderPageContainer } from '../../components/containers/pageContainer';
import HomePageContainer from '../../components/containers/homePageContainer';
import PopupNotificationContainer from '../../components/containers/popupNotificationContainer';
import NotificationsDiscountModal from '../../components/organisms/modals/notoficationsDiscount';
// actions
import appAction from '../../redux/actions/app';
import sessionsAction from '../../redux/actions/sessions';
import notificationClientAction from '../../redux/actions/notificationClient';
// types
import { stateType } from '../../types/types';

const HomePage: React.FC = () => {
  const dispatch = useDispatch();

  const getSessionController = () => {
    if (role === 'THERAPIST') {
      dispatch(sessionsAction.getSelf());
    }

    if (role === 'CUSTOMER' && (status.name === 'active' || status.name === 'paused')) {
      if (status.value !== 2) {
        dispatch(sessionsAction.getSelf());
      }
    }
  };

  useEffect(() => {
    dispatch(appAction.page('Home'));
    getSessionController();
    role === 'CUSTOMER' && dispatch(appAction.getHelpSlider());
    // role === 'CUSTOMER' && dispatch(sessionsAction.getEvents());
    // if (role === 'CUSTOMER' && status.value !== 2) {
    //   dispatch(sessionsAction.getCommEventSessions());
    // }
    // role === 'THERAPIST' && dispatch(sessionsAction.getCommEventSessions());
    if (stateFlow === 5 && notification === true) {
      dispatch(notificationClientAction.getNotification());
    }

    return () => {
      sessionsAction.clear();
    };
  }, []);

  const firstname: string | undefined = useSelector((state: stateType) => state.user.profile.firstname, shallowEqual);
  const lastname: string | undefined = useSelector((state: stateType) => state.user.profile.lastname, shallowEqual);
  const { role, status, notification, stateFlow }: any = useSelector(
    (state: stateType) => state.user.profile,
    shallowEqual,
  );
  const { item, isDiscount } = useSelector((state: stateType) => state.notificationClient, shallowEqual);

  return (
    <MainTemplate>
      <HeaderPageContainer>
        <h2 className="text-page-header">
          {`Hi ${firstname} ${lastname},`}
          <span className="text-page-header-before">
            {role === 'THERAPIST' ? 'here’s your upcoming sessions' : 'Welcome Back'}
          </span>
        </h2>
      </HeaderPageContainer>
      {role !== 'ADMIN' && <HomePageContainer />}
      {item?.notificationData && stateFlow === 5 && notification === true && role !== 'ADMIN' && (
        <PopupNotificationContainer data={item} />
      )}
      {isDiscount && <NotificationsDiscountModal />}
    </MainTemplate>
  );
};

export default HomePage;
