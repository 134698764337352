import React from 'react';
import { Controller } from 'react-hook-form';
import { Input } from 'antd';
import cn from 'classnames';

// styles
import '../../inputField/style.scss';
import './style.scss';

interface SubmitButtonType {
  name: string;
  clearName?: string;
  reset?: any;
}

interface InputsType {
  errors: string | undefined;
  register: any;
  watch?: any;
  control?: any;
}

export const SubjectInput: React.FC<InputsType> = ({ errors, register }: InputsType) => {
  return (
    <div className="subject-block">
      <label htmlFor="subject" className="subject-block__prefix">
        Subject
      </label>
      <input
        id="subject"
        className={cn('subject-block__input', {
          errors,
        })}
        placeholder=""
        {...register('subject', {
          required: 'enter your subject',
        })}
      />
      <div className="input-field__error-container">
        {errors && (
          <span role="alert" className="input-field__error-message">
            {errors}
          </span>
        )}
      </div>
    </div>
  );
};

export const SubjectBody: React.FC<InputsType> = ({ control, errors }: InputsType) => {
  return (
    <div className="subject-block">
      <label htmlFor="message" className="subject-block__prefix">
        Message
      </label>
      <Controller
        control={control}
        name="message"
        rules={{ required: 'enter your message' }}
        render={({ field: { onChange, value } }) => (
          <Input.TextArea
            rows={7}
            autoSize={false}
            onChange={(value: any) => {
              onChange(value);
            }}
            value={value}
            className={cn('support-message-textarea', { errors })}
            id="message"
          />
        )}
      />
      <div className="input-field__error-container">
        {errors && (
          <span role="alert" className="input-field__error-message">
            {errors}
          </span>
        )}
      </div>
    </div>
  );
};

export const SubjectButton: React.FC<SubmitButtonType> = ({ name, clearName, reset }: SubmitButtonType) => {
  return (
    <div className="subject-button">
      <button type="button" className="btn-form btn-form__reset" onClick={() => reset({})}>
        {clearName}
      </button>
      <button type="submit" className="btn-form">
        {name}
      </button>
    </div>
  );
};
