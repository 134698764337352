import axios from 'axios';
import { APP } from '../configs/app';
import store from '../redux/store';
import appAction from '../redux/actions/app';
import { authActions } from '../redux/actions/auth';

let isRefreshing = false;
let refreshSubscribers: Array<any> = [];

const http = async () => {
  const header: any = {
    'Access-Control-Allow-Origin': '*',
    'Content-type': 'application/json',
  };

  const token = localStorage.getItem('token');

  if (token) {
    header.Authorization = `Bearer ${token}`;
  }

  const authorizedInstance = axios.create({
    baseURL: APP.API.URL,
    headers: header,
  });

  authorizedInstance.interceptors.request.use(
    function (config) {
      // Do something before request is sent
      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    },
  );

  authorizedInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const {
        config,
        response: { status },
      } = error;
      const originalRequest = config;

      if (status === 401 && error.response.data.error_type !== 'authentication_failure') {
        if (!isRefreshing) {
          isRefreshing = true;
          authorizedInstance
            .post('refresh-token', {
              refreshToken: localStorage.getItem('refresh_token'),
            })
            .then(({ data }) => {
              isRefreshing = false;
              const { token, refreshToken } = data.data;
              localStorage.setItem('token', token);
              localStorage.setItem('refresh_token', refreshToken);
              onRrefreshed(data.data.token);
            })
            .catch((e) => {
              localStorage.removeItem('token');
              localStorage.removeItem('refresh_token');
              store.dispatch(appAction.auth('login'));
              isRefreshing = false;
            });
        }

        const retryOrigReq = new Promise((resolve, reject) => {
          subscribeTokenRefresh((token: any) => {
            // replace the expired accessToken and retry
            originalRequest.headers['Authorization'] = 'Bearer ' + token;
            resolve(axios(originalRequest));
          });
        });
        return retryOrigReq;
      } else if (
        status === 403 &&
        store.getState().user.profile.role &&
        store.getState().user.profile.role.toLowerCase() !== localStorage.getItem('role')
      ) {
        window.location.reload();
      } else {
        store.dispatch(authActions.loader(false));
        return Promise.reject(error);
      }
    },
  );

  function subscribeTokenRefresh(cb: any) {
    refreshSubscribers.push(cb);
  }

  function onRrefreshed(token: any) {
    refreshSubscribers.map((cb) => cb(token));
    refreshSubscribers = [];
  }

  return authorizedInstance;
};

export default http;
