import React, { FC } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Spin } from 'antd';
// components
import AuthTemplate from '../../../components/templates/authTemplate';
// types
import { stateType } from '../../../types/types';
import { WaitingFlow } from 'components/templates/waitingTemplate';

const PageWaiting: FC = () => {
  const loader: boolean = useSelector((state: stateType) => state.consultation_form.loader, shallowEqual);

  return (
    <AuthTemplate mask={<div className="auth-mask-layout" />}>
      <Spin tip="Loading..." spinning={loader}>
        <WaitingFlow />
      </Spin>
    </AuthTemplate>
  );
};

export default PageWaiting;
