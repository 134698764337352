import React from 'react';

export default function ScreenShareIcon() {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18 10.4987V12.9987C18 13.2197 17.9122 13.4317 17.7559 13.588C17.5996 13.7442 17.3877 13.832 17.1667 13.832H3.83333C3.61232 13.832 3.40036 13.7442 3.24408 13.588C3.0878 13.4317 3 13.2197 3 12.9987V4.66536C3 4.44435 3.0878 4.23239 3.24408 4.07611C3.40036 3.91983 3.61232 3.83203 3.83333 3.83203H11.3333"
        stroke="white"
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.33301 17.168H14.6663"
        stroke="white"
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M8 13.832V17.1654" stroke="white" strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M13 13.832V17.1654" stroke="white" strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M14.667 3.83203H18.0003V7.16536"
        stroke="white"
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.833 7.9987L17.9997 3.83203"
        stroke="white"
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
