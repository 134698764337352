import React, { useState } from 'react';
// styles
import './style.scss';

interface UserAvatarType {
  first_name: string;
  last_name?: string;
  color: string;
  url?: string;
}

export const UserAvatar: React.FC<UserAvatarType> = ({ first_name, last_name, url, color }: UserAvatarType) => {
  const [load, setLoad] = useState<any>(null);

  const onLoad = () => {
    setLoad(true);
  };

  const onError = () => {
    setLoad(false);
  };

  return (
    <div className="user-avatar">
      {url && load !== false ? (
        <img src={url} alt="avatar" className="user-avatar__img-avatar" onLoad={onLoad} onError={onError} />
      ) : (
        <div className="user-avatar__prev-avatar" style={{ background: `${color}` }}>
          {first_name && first_name[0]}
          {last_name && last_name[0]}
        </div>
      )}
    </div>
  );
};
