import { ReactComponent as IconAnalitic } from '../../assets/image/menu/svg/analytic.svg';
import { ReactComponent as IconGroups } from '../../assets/image/menu/svg/groups.svg';
import { ReactComponent as IconClients } from '../../assets/image/menu/svg/clients.svg';
import { ReactComponent as IconTherapist } from '../../assets/image/menu/svg/therapists.svg';
import { ReactComponent as IconEvents } from '../../assets/image/menu/svg/events.svg';
import { ReactComponent as IconProgress } from '../../assets/image/menu/svg/progress.svg';
import { ReactComponent as iconForum } from '../../assets/image/menu/svg/forum.svg';

// Actions
import {
  InitMainMenuAction,
  MainMenuActions,
  MainMenuElement,
  MenuPropgressPopover,
  MenuShowDrawerAction,
  MenuShowMinimalAction,
} from '../../types/actions/main-menu';

const mainMenuAction = {
  init(role: MainMenuActions): InitMainMenuAction {
    let menu: MainMenuElement[] = [];

    switch (role) {
      case MainMenuActions.Admin:
        menu = [
          {
            id: 3,
            status: false,
            name: 'All Groups',
            icon: IconGroups,
            link: '/all_groups',
          },
          {
            id: 4,
            status: false,
            name: 'All Clients',
            icon: IconClients,
            link: '/all_clients',
          },
          {
            id: 5,
            status: false,
            name: 'All Therapists',
            icon: IconTherapist,
            link: '/all_therapists',
          },
          // {
          //   id: 6,
          //   status: false,
          //   name: 'Forum',
          //   icon: iconForum,
          //   link: '/forum',
          //   disabled: false,
          // },
          {
            id: 7,
            status: false,
            name: 'Community Events',
            icon: IconEvents,
            link: '/community_events',
            disabled: false,
          },
        ];
        break;

      case MainMenuActions.Customer:
        menu = [
          {
            id: 1,
            status: false,
            name: 'Home',
            icon: IconAnalitic,
            link: '/',
          },
          // {
          //   id: 2,
          //   status: false,
          //   name: 'Forum',
          //   icon: iconForum,
          //   link: '/forum',
          //   disabled: false,
          // },
          {
            id: 3,
            status: false,
            name: 'Community Events',
            icon: IconEvents,
            link: '/events',
            disabled: false,
          },
          {
            id: 4,
            status: false,
            name: 'My Progress',
            icon: IconProgress,
            link: '/progress',
            disabled: false,
          },
        ];
        break;

      case MainMenuActions.Therapist:
        menu = [
          {
            id: 1,
            status: false,
            name: 'Home',
            icon: IconAnalitic,
            link: '/',
          },
          {
            id: 2,
            status: false,
            name: 'My Groups',
            icon: IconGroups,
            link: '/groups',
          },
          {
            id: 3,
            status: false,
            name: 'My Clients',
            icon: IconClients,
            link: '/clients',
          },
          // {
          //   id: 4,
          //   status: false,
          //   name: 'Forum',
          //   icon: iconForum,
          //   link: '/forum',
          //   disabled: false
          // },
        ];
        break;

      default:
        return [];
    }

    return { type: 'MENU', payload: { menu } };
  },

  changeStatus: (page: string, menu: MainMenuElement[]): InitMainMenuAction => {
    const obj: MainMenuElement[] = menu.map((item: MainMenuElement) => {
      item.status = false;
      if (item.name === page) {
        item.status = true;
      }
      return item;
    });

    return {
      type: 'MENU',
      payload: {
        menu: obj,
      },
    };
  },

  showMinimal(status: boolean): MenuShowMinimalAction {
    return {
      type: 'MENU_SHOW_MINIMAL',
      payload: {
        showMinimal: status,
      },
    };
  },

  showDrawer(status: boolean): MenuShowDrawerAction {
    return {
      type: 'MENU_SHOW_DRAWER',
      payload: {
        showDrawer: status,
      },
    };
  },

  propgressPopover(value: boolean | number): MenuPropgressPopover {
    return {
      type: 'MENU_PROGRESS_POPOVER',
      payload: {
        propgressPopover: value,
      },
    };
  },
};

export default mainMenuAction;
