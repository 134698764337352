import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
// actions
import therapistAction from '../../../../../redux/actions/therapist';
// helps
import { mapping } from '../../../../../api/mapping/request/addTherapist';
// components
import {
  AddTherapistFirstName,
  AddTherapistLastName,
  AddTherapistEmail,
  AddTherapistLicensure,
  PhonesInput,
  AddTherapistSelectZoom,
  SubmitButton,
  SelectTitle,
  Bio,
  YearsinPracticeInput,
  JoinedDate,
  SpecialtiesSelect,
  PossibleGroupTopics,
} from '../../../../molecules/inputsBlocks/addTherapist';
import { FormSearchSelect } from '../../../../molecules/inputsBlocks/select';
import { CancelButton } from 'components/atoms/form/CancelButton';
import Uploader from '../../../files';

// style
import '../../style.scss';
import './style.scss';
import { stateType } from 'types/types';
import { useHistory } from 'react-router-dom';

interface FormData {
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  licenseIds: any;
  stateId: any;
  zoomClientId: number;

  biography: string;
  titleId: string;
  practiceYears: string;
  joinedDate: string;
  avatarUuid: any;
  specialtyIds: any;
  groupTopicIds: any;
}

const FormAddTherapist: React.FC<any> = ({ setVisible }: any) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm<FormData>();
  const file: any = useSelector((state: stateType) => state.app.file);

  const dispatch = useDispatch();
  const history = useHistory();

  const addTherapistCallback = (id: any) => {
    setVisible(false);
    history.push(`/all_therapists/${id}`);
  };

  const onSubmit = (data: FormData) => {
    dispatch(therapistAction.add(mapping.addTherapistBuild(data), addTherapistCallback));
  };

  const handleCancel = () => {
    setVisible(false);
  };

  useEffect(() => {
    file && setValue('avatarUuid', file.uuid);
  }, [file]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="form-add-therapist validation-form">
        <div className="form-add-therapist__avatar-bio">
          <div className="form-add-therapist__avatar">
            <Uploader type={'user_avatar'} className="form-add-therapist__avatar-uploader" />
            {/* <div className="form-add-therapist__edit-icon-container">
              <img src={editIcon} className="form-add-therapist__edit-icon" />
            </div> */}
            <div className="form-add-therapist__avatar-top" />
            <div className="form-add-therapist__avatar-bottom" />
          </div>
          <Bio errors={errors.biography?.message} control={control} />
        </div>
        <div className="form-add-therapist__container">
          <div className="form-add-therapist__info">
            <SelectTitle errors={errors.titleId?.message} control={control} />
            <div className="form-add-therapist__personal-info">
              <div className="form-add-therapist__first-name">
                <AddTherapistFirstName
                  errors={errors.firstname ? errors.firstname.message : undefined}
                  register={register}
                />
                <AddTherapistEmail errors={errors.email ? errors.email.message : undefined} register={register} />
                <AddTherapistLicensure
                  errors={errors.licenseIds ? errors.licenseIds.message : undefined}
                  control={control}
                />
                <YearsinPracticeInput errors={errors.practiceYears?.message} control={control} register={register} />
              </div>
              <div className="form-add-therapist__second-name">
                <AddTherapistLastName
                  errors={errors.lastname ? errors.lastname.message : undefined}
                  register={register}
                />
                <PhonesInput
                  errors={errors.phone ? errors.phone.message : undefined}
                  register={register}
                  control={control}
                />
                <FormSearchSelect
                  name="stateId"
                  optionsName="state"
                  label="State"
                  errors={errors.stateId?.message}
                  control={control}
                  mode="multiple"
                  maxTagCount="responsive"
                  placeholder="Select state"
                  rules={{ required: 'Select Therapist State' }}
                  filterOption={(input: string, option: any) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                />
                <JoinedDate errors={errors.joinedDate?.message} control={control} />
              </div>
            </div>

            <SpecialtiesSelect errors={errors.specialtyIds?.message} control={control} />
            <PossibleGroupTopics errors={errors.groupTopicIds?.message} control={control} />
            <AddTherapistSelectZoom
              errors={errors.zoomClientId ? errors.zoomClientId.message : undefined}
              register={register}
              control={control}
            />
          </div>
          <div className="form-add-therapist__buttons">
            <SubmitButton name="Add Therapist" />
            <CancelButton name="Cancel" cancel={handleCancel} />
          </div>
        </div>
      </form>
    </>
  );
};

export default FormAddTherapist;
