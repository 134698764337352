import React, { FC, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
// components
import PageRegistered from '../../../pages/customer/consultation/registered';
import PageWaiting from '../../../pages/customer/consultation/waiting';
import PageGroupSearch from '../../../pages/customer/consultation/groupSearch';
// types
import { stateType } from '../../../types/types';

const ConsultationContainer: FC = () => {
  const [state, setState] = useState<string>('');

  const stateFlow: string = useSelector((state: stateType) => state.user.state, shallowEqual);

  useEffect(() => {
    setState(stateFlow);
  }, [stateFlow]);

  const stateController = (state: string) => {
    switch (state) {
      case 'registered':
        return <PageRegistered />;

      case 'waiting':
        return <PageWaiting />;

      case 'group_search':
        return <PageGroupSearch />;

      case 'questioning':
        return <Redirect to="/" />;

      case 'start_subscription':
        return <Redirect to="/stripe" />;

      case 'subscription_in_progress':
        return <Redirect to="/stripe" />;
      case 'approve_consultations':
        return <Redirect to="approve-consultations" />;

      default:
        return <>{'loading ...'}</>;
    }
  };

  return <>{stateController(state)}</>;
};

export default ConsultationContainer;
