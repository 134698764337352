import React, { FC, useEffect, useState } from 'react';
import { NestedValue, useForm } from 'react-hook-form';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import { useWindowResize } from 'beautiful-react-hooks';
// style
import './style.scss';
import '../../forms/consultation/style.scss';
// icons
import Icon from '@ant-design/icons';
import { ReactComponent as IconPointer } from '../../../../assets/image/forms/pointer.svg';
// actions
import consultationAction from '../../../../redux/actions/consultation';
import restartAction from '../../../../redux/actions/restart_subscriptions';
// types
import { stateType } from '../../../../types/types';
// configs
import { restartSubscriptionSchema } from '../../../../tools/validations';
import { mapping } from '../../../../api/mapping/request/restartSubscriptions';
// components
import ReasonsSelect from '../../../molecules/inputsBlocks/select/ReasonsSelect';
import FormSelect, { FormSearchSelect } from '../../../molecules/inputsBlocks/select';
import DateOfBirthInput from '../../../molecules/inputsBlocks/consultation/DateOfBirthInput';

interface FormData {
  reasons: NestedValue<number[]>;
  topics: number;
  genders: number;
  state: number;
  date: string;
}

const RecommendedGroupsFrom: FC = () => {
  const [width, setWidth] = useState<number>(window.innerWidth);

  const {
    handleSubmit,
    control,
    register,
    setValue,
    formState: { errors },
  } = useForm<FormData>({ mode: 'onBlur', resolver: yupResolver(restartSubscriptionSchema) });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(consultationAction.get_options());
  }, []);

  const loader: boolean = useSelector((state: stateType) => state.consultation_form.loader, shallowEqual);
  const loaderRestart: boolean = useSelector((state: stateType) => state.restartSubscriptions.loader, shallowEqual);

  const consultationData: any = useSelector(
    (state: stateType) => state.consultation_form.consultationData,
    shallowEqual,
  );

  const updateDataCb = () => {
    dispatch(restartAction.state(3));
  };

  const onSubmit = (data: FormData) => {
    dispatch(consultationAction.setData(mapping.recommendedGroups(data)));
    dispatch(restartAction.updateData(mapping.recommendedGroups(data), updateDataCb));
  };

  useEffect(() => {
    if (!_.isEmpty(consultationData)) {
      setValue('reasons', consultationData.reasonIds);
      setValue('topics', consultationData.groupTopicId);
      setValue('genders', consultationData.gender);
      setValue('state', consultationData.stateId);
      setValue('date', consultationData.birthDay);
    }
  }, [consultationData]);

  useWindowResize(() => {
    setWidth(window.innerWidth);
  });

  return (
    <div className="recommended-groups-from">
      <h2 className="recommended-groups-from__title">
        Based on your responses below, we’ll provide a selection of recommended groups for you to choose from.
      </h2>
      <form onSubmit={handleSubmit(onSubmit)} className="form-consultation form-restart validation-form">
        <Spin spinning={loader || loaderRestart}>
          <ReasonsSelect
            errors={errors.reasons?.message}
            register={register}
            control={control}
            defaultValue={consultationData?.reasonIds}
          />
          <FormSelect
            name="topics"
            label={
              width > 375
                ? 'Which group do you think would best suit your needs?'
                : 'Which group would best suit your needs?'
            }
            errors={errors.topics?.message}
            control={control}
            placeholder="Select your top choice"
            optionsName="topicsExtended"
          />
          <div className="form-consultation__row">
            <div className="form-consultation__column">
              <DateOfBirthInput
                errors={errors.date?.message}
                control={control}
                defaultValue={consultationData?.birthDay}
              />
            </div>
            <div className="form-consultation__column">
              <FormSelect
                name="genders"
                label="Gender"
                errors={errors.genders?.message}
                control={control}
                placeholder="Select Gender"
              />
            </div>
          </div>
          <FormSearchSelect
            name="state"
            label="What’s your state of residence?"
            errors={errors.state?.message}
            control={control}
            placeholder={
              <>
                <Icon component={IconPointer} style={{ fontSize: '15px', marginBottom: '2px', marginRight: '5px' }} />
                {'Select State'}
              </>
            }
            filterOption={(input: string, option: any) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          />

          <button className="btn-form">Find my Group</button>
        </Spin>
      </form>
    </div>
  );
};

export default RecommendedGroupsFrom;
