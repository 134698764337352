import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
// actions
import changeGroupActions from 'redux/actions/cancel_group';
// types
import { GroupActionStatus } from 'types/actions/common-types';
// icons
import ImgAfter from 'assets/image/questions/after.png';
import CloseChangeBtn from '../CloseQuestion';
import eventAnalytics from '../../../../redux/actions/analytics';
import { useDispatch } from 'react-redux';

const CancelAfter: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(eventAnalytics.analytics('cancel-confirm'));
    changeGroupActions.status(GroupActionStatus.Empty);
    history.push('/');
  };

  return (
    <div className="change-group-container qustion-container_after">
      <div>
        <CloseChangeBtn />
      </div>
      <img src={ImgAfter} alt="canceled subscription icon" />
      <div className="questions__congrad">Cancelled Subscription</div>
      <div className="questions__finish-title">
        You’ve successfully canceled your subscription. You will no longer be billed after your current billing cycle.
      </div>
      <div className="questions__finish-text">
        If you wish to re-join or try a new group, you can simply click restart subscription or reach out to our support
        team and we’ll get you started again right away.
      </div>
      <button className="questions__done btn-form" onClick={onClick}>
        Done
      </button>
    </div>
  );
};

export default CancelAfter;
