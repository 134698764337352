import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
// components
import MainTemplate from '../../../components/templates/mainTemplate';
import ForumInitContainer from '../../../components/containers/forumInitContainer';
// actions
import appAction from '../../../redux/actions/app';

const ForumPage: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(appAction.page('Forum'));
  }, []);
  return (
    <MainTemplate>
      <ForumInitContainer />
    </MainTemplate>
  );
};

export default ForumPage;
