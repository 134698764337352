/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { ActionCallback } from '../../types/actions/common-types';
import {
  HistoryLoaderModalAction,
  HistoryLoaderAction,
  HistoryGetGroupsAction,
  HistoryGetSessionsAction,
} from '../../types/actions/history';
import store from '../store';
// actions
// import mainMenuAction from './main_menu';

const historyAction = {
  get(id?: any, callback?: () => void): any {
    return {
      type: 'GET_HISTORY',
      id,
      callback,
    };
  },

  loader(status: boolean): HistoryLoaderAction {
    return {
      type: 'HISTORY_LOADER',
      payload: {
        loader: status,
      },
    };
  },
  loaderModal(status: boolean): HistoryLoaderModalAction {
    return {
      type: 'HISTORY_LOADER_MODAL',
      payload: {
        loaderModal: status,
      },
    };
  },
  create: (data: any, totalCount?: number): void => {
    store.dispatch({
      type: 'HISTORY',
      payload: {
        items: data,
        totalCount: totalCount,
      },
    });
  },
  getHistoryGroups(id?: any, callback?: ActionCallback): HistoryGetGroupsAction {
    return {
      type: 'GET_HISTORY_GROUPS',
      id,
      callback,
    };
  },
  getHistorySessions(id?: any, callback?: () => void): HistoryGetSessionsAction {
    return {
      type: 'GET_HISTORY_SESSIONS',
      id,
      callback,
    };
  },
  setHistoryGroups: (data: any, totalCount?: number): void => {
    store.dispatch({
      type: 'CLIENT_HISTORY_GROUPS',
      payload: {
        historyGroups: data,
        totalCount: totalCount,
      },
    });
  },
  setHistorySessions: (data: any, totalCount?: number): void => {
    store.dispatch({
      type: 'CLIENT_HISTORY_SESSIONS',
      payload: {
        historySessions: data,
        totalCount: totalCount,
      },
    });
  },
  params(params: any) {
    store.dispatch({
      type: 'SET_HISTORY_PARAMS',
      payload: {
        params: params,
      },
    });
  },
  historyGroupsParams(historyGroupsParams: any) {
    store.dispatch({
      type: 'SET_HISTORY_GROUPS_PARAMS',
      payload: {
        historyGroupsParams,
      },
    });
  },
  historySessionsParams(historySessionsParams: any) {
    store.dispatch({
      type: 'SET_HISTORY_SESSIONS_PARAMS',
      payload: {
        historySessionsParams,
      },
    });
  },
  clear(): void {
    store.dispatch({
      type: 'HISTORY',
      payload: {
        items: [],
      },
    });
  },
};

export default historyAction;
