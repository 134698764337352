/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { AxiosResponse } from 'axios';
// config
import http from '../instance';

export const api = {
  get: async (role: string, id: any, params: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.get(`${role}/group/${id}/sessions?scope=${params}`);
  },

  getCommEventSessions: async (role: string, params: string): Promise<AxiosResponse> => {
    const api = await http();
    if (role === 'therapist') {
      const query = params === 'next' ? '' : '&page=1&per_page=2';
      return api.get(`${role}/community-event/sessions?scope=${params}${query}`);
    } else {
      return api.get(`${role}/community-event/sessions?scope=${params}&page=1&per_page=3`);
    }
  },

  getEvents: async (role: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.get(`${role}/community-event-sessions`);
  },

  getSelf: async (role: string, params: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.get(`${role}/sessions-extended?scope=${params}&page=1&per_page=12`);
  },

  reschedule: async (role: string, data: any, id: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.patch(`${role}/session/${id}/reschedule`, data);
  },

  cancel: async (role: string, id: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.patch(`${role}/session/${id}/cancel`);
  },

  start: async (role: string, id: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.patch(`${role}/session/${id}/start`);
  },

  join: async (role: string, id: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.patch(`${role}/session/${id}/join`);
  },

  finishConsultations: async (role: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.patch(`${role}/signup-consultation`);
  },
};
