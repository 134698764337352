import React from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useHistory, Link } from 'react-router-dom';
// actions
import { authActions } from '../../../../redux/actions/auth';
// components
import {
  LoginInputEmail,
  LoginInputPassword,
  LoginSubmitButton,
  LoginOptions,
} from '../../../molecules/inputsBlocks/login';
// style
import '../style.scss';

import { setFormErrors } from '../../../../tools/helpFunctions';
import { IErrors } from '../../../../types/actions/common-types';

interface FormData {
  email: string;
  password: string;
}

const FormLogin: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<FormData>();

  const dispatch = useDispatch();
  const history = useHistory();

  const onSubmit = (data: FormData) => {
    const request = { ...data, email: data.email.trim() };

    const setErrors = (errors: Array<IErrors>) => setFormErrors(setError, errors);
    dispatch(authActions.login(request, history, setErrors));
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="form-edit">
        <LoginInputEmail errors={errors.email ? errors.email.message : undefined} register={register} />
        <LoginInputPassword errors={errors.password ? errors.password.message : undefined} register={register} />
        <LoginOptions />

        <LoginSubmitButton />
      </form>
    </>
  );
};

export default FormLogin;
