import React from 'react';
import cn from 'classnames';
// styles
import './style.scss';

interface FilterBoxType {
  title: string;
  children: React.ReactNode;
  type?: 'select';
  sm?: boolean;
}

const FilterBox: React.FC<FilterBoxType> = ({ title, children, type, sm }: FilterBoxType) => {
  return (
    <div className="filter-box">
      <div className="filter-box__column">
        <div className={cn('filter-box__title', { sm })} style={{ paddingTop: type === 'select' ? '8px' : '0' }}>
          {title}
        </div>
      </div>
      <div className="filter-box__column">
        <div className="filter-box__wrap">{children}</div>
      </div>
    </div>
  );
};

export default FilterBox;
