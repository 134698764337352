/*
 *
 * Stripe reducer
 *
 */
const initialState = {
  item: {},
  items: [],
  secred: '',
  page: 'list',
  loader: false,
  loaderModal: false,
};

export type stripeInitStateType = typeof initialState;

interface stripeActionTypes {
  type: string;
  payload: {
    invalidPromo: any;
    data: any;
    item: any;
    items: Array<any>;
    secred: string;
    page: string;
    loader: boolean;
    loaderModal: boolean;
  };
}

const stripe = (state = initialState, action: stripeActionTypes): stripeInitStateType => {
  switch (action.type) {
    case 'STRIPE_LIST':
      return Object.assign({}, state, {
        items: action.payload.items,
      });

    case 'STRIPE_ITEM':
      return Object.assign({}, state, {
        item: action.payload.item,
      });

    case 'STRIPE_SECRED':
      return Object.assign({}, state, {
        secred: action.payload.secred,
      });

    case 'PROMO_INFO':
      return Object.assign({}, state, {
        data: action.payload,
      });

    case 'INVALID_PROMO':
      return Object.assign({}, state, {
        invalidPromo: action.payload,
      });

    case 'STRIPE_PAGE':
      return Object.assign({}, state, {
        page: action.payload.page,
      });

    case 'STRIPE_LOADER':
      return Object.assign({}, state, {
        loader: action.payload.loader,
      });

    case 'STRIPE_LOADER_MODAL':
      return Object.assign({}, state, {
        loaderModal: action.payload.loaderModal,
      });

    default:
      return state;
  }
};

export default stripe;
