import React, { FC, useEffect } from 'react';
import cn from 'classnames';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
// components
import SubmitButton from '../../../../atoms/form/Button';
import { CancelButton } from '../../../../atoms/form/CancelButton';
// types
import { stateType } from '../../../../../types/types';
import { EditModalInterface } from '../../../../../types/edit';
// style
import '../style.scss';
// helpers
import { mapping } from '../../../../../api/mapping/request/editTherapist';
// actions
import therapistAction from '../../../../../redux/actions/therapist';

const EditTherapistNamesContainer: FC<EditModalInterface> = ({
  handleCancel,
  titleName,
  buttonName,
  children,
  setValue,
  handleSubmit,
  isCancel,
  isPhoto,
  undertitle,
}: EditModalInterface) => {
  const dispatch = useDispatch();

  const therapists: any = useSelector((state: stateType) => state.therapists.item, shallowEqual);

  useEffect(() => {
    setValue('firstname', therapists?.firstname);
    setValue('lastname', therapists?.lastname);
    setValue('phone', therapists?.phone);
  }, [therapists]);

  const onSubmit = (data: FormData) => {
    dispatch(therapistAction.editNames(therapists?.id, mapping.editTherapistNames(data), handleCancel));
  };

  return (
    <div className="edit-details-container">
      <div className="edit-details-container__title edit-details-container__header">
        {titleName}
        <p className="edit-details-container__header-undertitle">{undertitle}</p>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="form-edit validation-form">
        <div
          className={cn('edit-details-container__main', {
            photo: isPhoto,
          })}
        >
          {children}
        </div>
        <div className="edit-details-container__buttons">
          <SubmitButton name={buttonName} className="edit-details-container__submit-btn" />
          {isCancel && <CancelButton name="Cancel" cancel={handleCancel} />}
        </div>
      </form>
    </div>
  );
};

export default EditTherapistNamesContainer;
