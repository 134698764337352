import React, { useState } from 'react';
import { Modal, Spin } from 'antd';
// icons
import { ReactComponent as CloseIcon } from '../../../../assets/image/account/close4.svg';
import { ReactComponent as UploadIcon } from '../../../../assets/image/common/upload.svg';
//components
import UploadHistoryContainer from '../../forms/uploadHistory';
// style
import './style.scss';

const UploadHistoryStatements: React.FC<any> = () => {
  const [visible, setVisible] = useState(false);

  const open = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <UploadIcon onClick={open} className="upload-history-modal__icon" />
      {visible && (
        <Modal
          visible={visible}
          onOk={handleOk}
          closable={true}
          onCancel={handleCancel}
          centered
          maskTransitionName="none"
          footer={null}
          className="upload-history-modal"
          closeIcon={<CloseIcon />}
        >
          <Spin spinning={false}>
            {/*TODO add spinning*/}
            <UploadHistoryContainer setVisible={setVisible} />
          </Spin>
        </Modal>
      )}
    </>
  );
};

export default UploadHistoryStatements;
