import React, { useContext, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Modal, Spin } from 'antd';
// actions
import ChoiceGroupAction from '../../../../../redux/actions/choice_group';
import restartAction from '../../../../../redux/actions/restart_subscriptions';
// components
import ModalContainer from './ModalContainer';
// types
import { stateType } from '../../../../../types/types';
import { stateFlowType } from '../../../../../redux/reducers/user';
// style
import './style.scss';
// context
import { SelectGroupsContext } from '../../../changeGroup/groups/SelectGroupsList';
import eventAnalytics from '../../../../../redux/actions/analytics';

const ModalConfirmChoice: React.FC<any> = ({ id, item }: any) => {
  const [visible, setVisible] = useState<boolean>(false);
  const redirect = useContext(SelectGroupsContext);
  const dispatch = useDispatch();
  const history = useHistory();

  const loader: boolean = useSelector((state: stateType) => state.choice_group.loader, shallowEqual);
  const notificationClient = useSelector((state: stateType) => state.notificationClient, shallowEqual);
  const loaderRestart: boolean = useSelector((state: stateType) => state.restartSubscriptions.loader, shallowEqual);
  const state: stateFlowType = useSelector((state: stateType) => state.user.state);
  const isReschedule: any = useSelector((state: stateType) => state.app.isReschedule);
  const { restartSubscriptionFlow: isRestart } = useSelector((state: stateType) => state.user.profile);

  const choiceGroupCallback = () => {
    if (redirect) {
      history.push('/profile/choice-group/confirm');
    }
    if (state === 'restart_cancel') {
      dispatch(restartAction.state(4));
    }
    if (state === 'restart_pre_cancel') {
      dispatch(restartAction.state(6));
    }
    if (state === 'group_change') {
      if (!isReschedule) {
        dispatch(eventAnalytics.analytics('change-group-done'));
      }
    }
  };

  const confirm = () => {
    if (isRestart) {
      dispatch(restartAction.join(id, choiceGroupCallback));
    } else {
      dispatch(ChoiceGroupAction.join(id, choiceGroupCallback));
    }
    if (notificationClient?.item?.type?.name === 'group-rescheduled' || isReschedule) {
      isReschedule && dispatch(eventAnalytics.analytics('reschedule-group-confirm', { groupId: id }));
    } else {
      if (state === 'group_change') {
        dispatch(eventAnalytics.analytics('change-group-join', { groupId: id }));
      } else {
        dispatch(eventAnalytics.analytics('update-payment-completed'));
      }
    }
  };

  const cancel = () => {
    setVisible(false);
  };

  const open = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };
  return (
    <>
      <button className="btn-form btn-select" onClick={open}>
        Select
      </button>
      {visible && (
        <Modal
          visible={visible}
          onOk={handleOk}
          closable={false}
          onCancel={handleCancel}
          centered
          maskTransitionName="none"
          footer={null}
          className="questions-modal questions-modal__select"
        >
          <Spin spinning={loader || loaderRestart}>
            <ModalContainer cancel={cancel} confirm={confirm} item={item} />
          </Spin>
        </Modal>
      )}
    </>
  );
};

export default ModalConfirmChoice;
