import React from 'react';
import { useAppState } from '../../state';
import { shallowEqual, useSelector } from 'react-redux';

export default function useUserIsHost() {
  const { meetingType, userIsHost } = useAppState();
  const role = useSelector((state: any) => state.user.profile.role, shallowEqual);
  const isHost = meetingType == 'webinar' ? userIsHost : role == 'THERAPIST' || role == 'ADMIN';

  return isHost;
}
