import React, { useEffect, useMemo, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Modal, Spin } from 'antd';
// styles
import './style.scss';
import { stateType } from 'types/types';
import accountAction from 'redux/actions/account';
import _ from 'lodash';
import { useHistory } from 'react-router';
import Moment from 'react-moment';
import backIcon from 'assets/image/common/download.svg';
import darkBackIcon from 'assets/image/common/download-dark.svg';
import logoImage from 'assets/image/headers/png/logo.png';
import jsPDF, { HTMLOptions } from 'jspdf';
import SimpleHeader from 'components/organisms/headers/simple/simple-header';
import SuperbillContainer from 'components/containers/superbillContainer';
import moment from 'moment';
import appAction from 'redux/actions/app';

const AdminSuperbillDetailsPage: React.FC = () => {
  const dispatch = useDispatch();
  const accountItem: any = useSelector((state: stateType) => state.clients.item, shallowEqual);
  const role = useSelector((state: stateType) => state.user.profile.role, shallowEqual);
  const { info, date }: any = useSelector((state: stateType) => state.superbill, shallowEqual);

  const history = useHistory();
  useEffect(() => {
    if (!info.payments || !date || !date.start || !date.end) {
      history.goBack();
      return;
    }
    dispatch(appAction.page('View superbills'));
    _.isEmpty(info) && dispatch(accountAction.get(role.toLowerCase()));
  }, []);

  const totalPrice = useMemo(() => {
    let price = 0;

    if (!info.payments) return 0;

    for (let i = 0; i < info.payments.length; i++) {
      const p = info.payments[i];
      const pdate = new Date(p.date);
      if (pdate.getTime() >= date.start.getTime() && pdate.getTime() <= date.end.getTime()) {
        price = p.amount;
        break;
      }
    }
    return price;
  }, [info.dayOfWeek, date]);

  const tableData = useMemo(() => {
    const indexDate = new Date(date.start);
    const endDate = new Date(date.end);
    let array = [];
    while (indexDate.getTime() <= endDate.getTime()) {
      if (indexDate.getDay() == info.dayOfWeek) {
        array.push({
          date: new Date(indexDate),
          cpt: '90853',
          modifier: '95',
          icd10: accountItem?.icd10,
          amount: '10',
        });
      }
      indexDate.setDate(indexDate.getDate() + 1);
    }

    if (!array.length) {
      history.goBack();
    } else {
      array = array.map((a) => ({
        ...a,
        amount: `${(totalPrice / array.length / 100.0).toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`,
      }));
    }
    return array;
  }, [info.dayOfWeek, date, totalPrice]);

  const elem = document.createElement('div') as HTMLDivElement;
  const elementRef = useRef(elem);

  const dateRange = `${moment(date.start).format('MMM DD, YYYY')} - ${moment(date.end).format('MMM DD, YYYY')}`;

  const download = () => {
    const doc = new jsPDF({
      orientation: 'p',
      unit: 'pt',
      format: 'letter',
    });
    const element = elementRef.current;
    const options: HTMLOptions = {
      width: 522,
      callback: function (pdf: any) {
        pdf.save(`${dateRange}.pdf`);
      },
      margin: [60, 40, 40, 40],
    };
    doc.setFont('Helvetica');
    doc.html(element, options);
  };

  return (
    <>
      <SimpleHeader />
      <SuperbillContainer title={dateRange}>
        <div className="superbill-icon-layout">
          <button className="superbill-icon-button" onClick={download}>
            <img src={darkBackIcon} alt="back icon" className="superbill-details-download-icon" />
          </button>
        </div>
        <div className="layout-superbill">
          <div ref={elementRef} className="superbill-page-container">
            <div className="superbill-details-header">
              <img src={logoImage} className="superbill-logo" />
              <div className="flex-grow superbill-details-header__text">
                <p>Grouport Inc.</p>
                <p>420 Lexinton Avenue #2400</p>
                <p>New York, NY 10170</p>
                <p>(201) 228-0136</p>
                <p>www.grouporttherapy.com</p>
                <p className="flex-grow superbill-details-header__title">Tax ID</p>
                <p>84-2932604</p>
              </div>
            </div>
            <div className="superbill-details-middle">
              <div className="flex-grow superbill-details-middle__text">
                <div className="flex-grow superbill-details-header__title">Provider Name</div>
                <div>
                  {info?.medicalGroup?.therapist.title}. {info?.medicalGroup?.therapist.firstname}{' '}
                  {info?.medicalGroup?.therapist.lastname}
                </div>
                <div className="flex-grow superbill-details-header__title">Patient</div>
                <div>
                  {accountItem?.firstname} {accountItem?.lastname}
                </div>
              </div>
              <div className="flex-grow superbill-details-middle__text second-line">
                <div className="flex-grow superbill-details-header__title">NPI</div>
                <div>{info?.medicalGroup?.therapist.npi}</div>
                <div className="flex-grow superbill-details-header__title">Description of services</div>
                <div>Online Group Therapy</div>
              </div>
            </div>
            <div>
              <table className="superbill-table">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>CPT</th>
                    <th>Modifier</th>
                    <th>ICD - 10</th>
                    <th>Amount Paid</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData &&
                    tableData.length &&
                    tableData.map((data: any, key: any) => (
                      <tr key={key}>
                        <td>
                          <Moment date={data.date} format="MM/DD/YY" />
                        </td>
                        <td>{data.cpt}</td>
                        <td>{data.modifier}</td>
                        <td>{data.icd10}</td>
                        <td>${data.amount}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <div className="superbill-total-price">
                <div>
                  Total amount charged
                  <span className="superbill-total-price-amount">
                    $
                    {(totalPrice / 100.0).toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div>
            <button className="btn-form superbill-details-download-button" onClick={download}>
              Download
              <img src={backIcon} alt="back icon" className="superbill-details-download-icon" />
            </button>
          </div>
        </div>
      </SuperbillContainer>
    </>
  );
};

export default AdminSuperbillDetailsPage;
