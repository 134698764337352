import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { Modal, Spin } from 'antd';
import { useForm } from 'react-hook-form';
// componets
import EditGroupModalContainer from '../../../../../molecules/modals/edit/editGroup';
import Uploader from '../../../../files';
// types
import { stateType } from '../../../../../../types/types';
// icons
import { ReactComponent as CloseIcon } from 'assets/image/account/close4.svg';
// styles
import '../style.scss';
// actions
import groupsAction from '../../../../../../redux/actions/groups';

const EditGroupAvatarModal: React.FC<any> = ({ btnClass, icon }: any) => {
  const [visible, setVisible] = useState(false);

  const loader = useSelector((state: stateType) => state.groups.loaderModal, shallowEqual);

  const open = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <button className={btnClass} onClick={open}>
        {icon}
      </button>
      {visible && (
        <Modal
          visible={visible}
          onOk={handleOk}
          closable={true}
          onCancel={handleCancel}
          centered
          maskTransitionName="none"
          footer={null}
          className="edit-group-modal"
          closeIcon={<CloseIcon />}
        >
          <Spin spinning={loader}>
            <ModalContainer handleCancel={handleCancel} />
          </Spin>
        </Modal>
      )}
    </>
  );
};

export default EditGroupAvatarModal;

const ModalContainer = ({ handleCancel }: any) => {
  const { handleSubmit, setValue } = useForm<FormData>();

  const therapists: any = useSelector((state: stateType) => state.groups.therapists, shallowEqual);
  const topics: any = useSelector((state: stateType) => state.groups.topics, shallowEqual);

  const { group }: any = useSelector((state: stateType) => state.groups.item);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!therapists.length || !topics.length) {
      dispatch(groupsAction.getTopics());
      dispatch(groupsAction.getTherapists());
    }
  }, []);

  return (
    <EditGroupModalContainer
      titleName="Change Group Cover Photo"
      buttonName="CONFIRM CHANGE"
      handleCancel={handleCancel}
      setValue={setValue}
      handleSubmit={handleSubmit}
      isPhoto
    >
      <Uploader
        type={'group_avatar'}
        defaultValue={group.avatar ? group.avatar.url : ''}
        className="form-edit-container__group-avatar"
      />
    </EditGroupModalContainer>
  );
};
