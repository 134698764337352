import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';
import cn from 'classnames';
// components
import Question from 'components/molecules/groupQuestions/QuestionCard';
import CloseChangeBtn from 'components/molecules/groupQuestions/CloseQuestion';
import ChangeGroupBefore from 'components/molecules/groupQuestions/BeforeAfter/ChangeGroupBefore';
import ArbitraryAnswer from 'components/molecules/groupQuestions/Answers/ArbitraryAnswer';
import MultiQuestion from 'components/molecules/groupQuestions/Answers/MultiQuestion';
import RateQuestion from 'components/molecules/groupQuestions/RateQuestion/RateQuestion';
import Select from 'components/atoms/form/Select';
import MultiArbitrary from 'components/molecules/groupQuestions/Answers/MultiArbitrary';
// actions
import changeGroupActions from '../../../redux/actions/change_group';
// helpers
import mappingQuestions from 'api/mapping/response/questions';
// types
import { stateType } from 'types/types';
import { GroupActionStatus } from 'types/actions/common-types';
// style
import './style.scss';
import eventAnalytics from '../../../redux/actions/analytics';

const ChangeGroupOrganism: React.FC = () => {
  const question: any = useSelector((state: stateType) => state.questions.item, shallowEqual);
  const status: string = useSelector((state: stateType) => state.questions.status, shallowEqual);
  const loader: boolean = useSelector((state: stateType) => state.questions.loader, shallowEqual);
  const isReschedule: any = useSelector((state: stateType) => state.app.isReschedule);

  useEffect(() => {
    status !== 'go_back' && changeGroupActions.status(GroupActionStatus.Before);
  }, []);

  return (
    <div className={cn('change-group-wrap', { 'change-group_pad': status === 'before' })}>
      <div className="change-group">
        <Spin spinning={loader}>
          {!loader ? (
            status === 'before' ? (
              <ChangeGroupBefore isReschedule={isReschedule} />
            ) : (
              <Step question={question} isReschedule={isReschedule} />
            )
          ) : null}
        </Spin>
      </div>
    </div>
  );
};

export default ChangeGroupOrganism;

const Step = ({ question, isReschedule }: any) => {
  const [checkedValues, setCheckedValues] = useState<any>([]);
  const [answerText, setAnswerText] = useState('');
  const [answerTextController, setAnswerTextController] = useState(true);
  const [rating, setRating] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    initDefaultValue();
  }, []);

  const initDefaultValue = () => {
    if (question.number) {
      dispatch(changeGroupActions.progress(question.number * 20));
    }

    if (question.defaultValue) {
      switch (question.questionType) {
        case 'single':
          return setCheckedValues([question.defaultValue.answerId]);

        case 'multi_large':
          return setCheckedValues([
            Array.isArray(question.defaultValue.answerIds)
              ? question.defaultValue.answerIds?.[0]
              : question.defaultValue.answerIds,
          ]);

        case 'evaluation':
          setRating(question.defaultValue.rating);
          return setAnswerText(question.defaultValue.answerText);

        case 'multi_with_arbitrary':
          setAnswerTextController(true);

          setCheckedValues(question.defaultValue.answerIds);
          return setAnswerText(question.defaultValue.answerText);

        default:
          return;
      }
    } else {
      if (answerText !== '' && question.questionType === 'multi_with_arbitrary') {
        setAnswerTextController(false);
      } else {
        setAnswerTextController(true);
      }
    }
  };

  const onChangeRate = (value: number) => {
    setRating(value);
  };

  const onChange = (checkedValues: any) => {
    setCheckedValues(checkedValues);
  };

  const caseTimeAction = () => {
    !isReschedule && dispatch(eventAnalytics.analytics('change-group-new-time', { answerIds: checkedValues }));
    isReschedule && dispatch(eventAnalytics.analytics('reschedule-group-view-more', { answerIds: checkedValues }));
  };

  const goNext = () => {
    const data = {
      questionId: question.id,
      rating: rating,
      answersIds: checkedValues,
      answerText: answerTextController ? answerText : '',
    };

    dispatch(
      changeGroupActions.answer(mappingQuestions.buildAnswer(data, question.answerType), question.number, () => {
        if (question.number === 5) {
          dispatch(changeGroupActions.progress(0));
          return;
        }
        return dispatch(changeGroupActions.next());
      }),
    );
    switch (question?.number) {
      case 1:
        return !isReschedule && dispatch(eventAnalytics.analytics('change-group-rating', { rating }));
      case 2:
        return !isReschedule && dispatch(eventAnalytics.analytics('change-group-experience', { rating }));
      case 3:
        return (
          !isReschedule &&
          dispatch(
            eventAnalytics.analytics('change-group-reason', {
              answerIds: checkedValues,
              answerText: answerTextController ? answerText : '',
            }),
          )
        );
      case 4:
        return (
          !isReschedule && dispatch(eventAnalytics.analytics('change-group-new-group', { answerIds: checkedValues }))
        );
      case 5:
        return caseTimeAction();
      default:
        return console.log('err');
    }
  };

  const goBack = () => {
    if (question.serialNumber > 1) {
      dispatch(changeGroupActions.after());
    } else {
      changeGroupActions.status(GroupActionStatus.Before);
    }
  };

  const disabledButton = () => {
    return (
      (question.number <= 2 && !rating) ||
      (question.number === 3 && !checkedValues.length && (!answerTextController || !answerText)) ||
      (question.number === 4 && (!checkedValues.length || !checkedValues[0])) ||
      (question.number === 5 && !checkedValues.length)
    );
  };

  const onSelectChange = (value: number) => {
    setCheckedValues([value]);
  };

  const answerController = () => {
    switch (question.questionType) {
      case 'multi':
        return <MultiQuestion question={question} checkedValues={question.answers} onChangeCheck={setCheckedValues} />;

      case 'single':
        return (
          <Select
            options={mappingQuestions.buildOptions(question.answers)}
            onChange={onSelectChange}
            defaultValue={question.defaultValue?.answerId}
            placeholder={question?.additions?.placeholder || undefined}
          />
        );

      case 'multi_large':
        return (
          <MultiArbitrary
            question={question}
            checkedValues={checkedValues}
            onChange={onChange}
            setAnswerText={(text) => setAnswerText(text)}
            answerText={answerText}
            placeholder="Type here.."
          />
        );

      case 'evaluation':
        return (
          <ArbitraryAnswer
            answerText={answerText}
            setAnswerText={(text) => setAnswerText(text)}
            placeholder={question?.additions?.placeholder || undefined}
          />
        );

      case 'multi_with_arbitrary':
        return (
          <MultiArbitrary
            question={question}
            checkedValues={checkedValues}
            onChange={onChange}
            setAnswerText={(text) => setAnswerText(text)}
            answerText={answerText}
            placeholder="Type here.."
            isOther
          />
        );

      default:
        return null;
    }
  };

  return (
    <Question
      question={question}
      answerText={answerText}
      nextDisabled={disabledButton()}
      onNext={goNext}
      renderController={answerController}
      onBack={goBack}
      closeBtn={<CloseChangeBtn />}
      apply={question.questionType === 'multi' || question.questionType === 'multi_with_arbitrary'}
      totalStepCount={question.maxQuestionNumber}
      firstStep={question.number <= 2}
    >
      {question.number < 3 && <RateQuestion question={question} onChangeRate={onChangeRate} rating={rating} />}
    </Question>
  );
};
