/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/display-name */
import React from 'react';
import * as ColumnItems from '../../../../molecules/columnItems/admin/groups';

export const columns = [
  {
    title: 'Topic',
    dataIndex: 'topic',
    key: 'topic',
    render: (value, row, index) => <ColumnItems.ItemName info={value} row={row} index={index} link="all_groups" />,
    width: '220px',
    minWidth: '220px',
  },

  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    render: (value, row, index) => <ColumnItems.ItemId info={value} row={row} />,
    width: '50px',
    minWidth: '50px',
  },

  {
    title: 'Therapist',
    dataIndex: 'therapist',
    key: 'therapist',
    render: (value, row, index) => <ColumnItems.ItemTherapist info={value} row={row} index={index} />,
    width: '130px',
  },

  {
    title: 'Time',
    dataIndex: 'time',
    key: 'time',
    render: (value, row, index) => <ColumnItems.ItemTime info={value} row={row} index={index} date={true} day={true} />,
    width: '225px',
  },

  {
    title: '# of members',
    dataIndex: 'members',
    key: 'members',
    render: (value, row, index) => <ColumnItems.ItemMembers info={value} row={row} index={index} />,
    width: '100px',
  },

  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (value, row, index) => <ColumnItems.ItemStatus info={value} row={row} index={index} />,
    width: '110px',
  },
];

columns.displayName = 'MyComponent';
