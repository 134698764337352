const upcomingAction: any = {
  getEvents(callback?: () => void): any {
    return {
      type: 'GET_UPCOMING_EVENTS',
      callback,
    };
  },

  getEventsAndPosts: (array: any, totalCount: number): any => {
    return {
      type: 'GET_EVENTS_AND_POSTS',
      payload: {
        array: array,
        totalCount,
      },
    };
  },

  getPastEventsAndPostsForPreview: (array: any, totalCount: number): any => {
    return {
      type: 'GET_PAST_EVENTS_AND_POSTS_PREVIEW',
      payload: {
        array: array,
        totalCount,
      },
    };
  },

  getUpcomingEventsAndPostsForPreview: (array: any, totalCount: number): any => {
    return {
      type: 'GET_UPCOMING_EVENTS_AND_POSTS_PREVIEW',
      payload: {
        array: array,
        totalCount,
      },
    };
  },

  getPastPosts: (array: any, totalCount: number): any => {
    return {
      type: 'GET_PAST_POSTS',
      payload: {
        array: array,
        totalCount,
      },
    };
  },

  clearAllParams: () => {
    return {
      type: 'CLEAR_ALL_PARAMS',
    };
  },

  getLengthsFilters(callback?: () => void): any {
    return {
      type: 'GET_LENGTHS_FILTERS',
      callback,
    };
  },

  getTypesFilters(callback?: () => void): any {
    return {
      type: 'GET_TYPES_FILTERS',
      callback,
    };
  },

  getTopicsFilters(callback?: () => void): any {
    return {
      type: 'GET_TOPICS_FILTERS',
      callback,
    };
  },

  getOrdersFilters(callback?: () => void): any {
    return {
      type: 'GET_ORDERS_FILTERS',
      callback,
    };
  },

  setLengthsFilters(dataFilters: any): any {
    return {
      type: 'SET_LENGTHS_FILTERS',
      payload: {
        dataFilters,
      },
    };
  },

  setOrdersFilters(dataFilters: any): any {
    return {
      type: 'SET_ORDERS_FILTERS',
      payload: {
        dataFilters,
      },
    };
  },

  setTopicsFilters(dataFilters: any): any {
    return {
      type: 'SET_TOPICS_FILTERS',
      payload: {
        dataFilters,
      },
    };
  },

  setTypesFilters(dataFilters: any): any {
    return {
      type: 'SET_TYPES_FILTERS',
      payload: {
        dataFilters,
      },
    };
  },

  changeBookMark(data: any): any {
    return {
      type: 'BOOKMARK_EVENTS',
      data,
    };
  },

  getPost(callback?: () => void): any {
    return {
      type: 'GET_UPCOMING_POSTS',
      callback,
    };
  },

  setEvents: (array: any): any => {
    return {
      type: 'UPCOMING_EVENTS',
      payload: {
        events: array,
      },
    };
  },

  setPost: (array: any, totalCount: number): any => {
    return {
      type: 'UPCOMING_POSTS',
      payload: {
        posts: array,
        totalCount,
      },
    };
  },

  setDefault: (): any => {
    return {
      type: 'SET_DEFAULT_ALL',
    };
  },

  register: (id: any, isRegister: boolean, callback: any): any => {
    return {
      type: 'UPCOMING_REGISTER',
      id,
      isRegister,
      callback,
    };
  },

  loaderEvents: (status: any): any => {
    return {
      type: 'UPCOMING_EVENTS_LOADER',
      payload: {
        loaderEvents: status,
      },
    };
  },

  loaderPosts: (status: any): any => {
    return {
      type: 'UPCOMING_POSTS_LOADER',
      payload: {
        loaderPosts: status,
      },
    };
  },

  loaderReg: (status: any): any => {
    return {
      type: 'UPCOMING_REG_LOADER',
      payload: {
        loaderReg: status,
      },
    };
  },

  params: (params: any): any => {
    return {
      type: 'UPCOMING_PAST_PARAMS',
      payload: {
        params,
      },
    };
  },

  eventsSearchType(searchType: string): any {
    return {
      type: 'EVENTS_SEARCH_TYPE',
      payload: {
        searchType,
      },
    };
  },

  eventsSearchTypeDefault(): any {
    return {
      type: 'EVENTS_SEARCH_TYPE',
      payload: {
        searchType: 'all',
      },
    };
  },

  allParams(params: string): any {
    return {
      type: 'EVENTS_ALL_PARAMS',
      payload: {
        params,
      },
    };
  },
};

export default upcomingAction;
