/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { put, call, select } from 'redux-saga/effects';
// api
import { optionsApi } from '../../api/options';
// actions
import optionsAction from '../actions/options';
// helps
import mappingConsultation from '../../api/mapping/response/consultation';
import mappingTherapistsList from '../../api/mapping/response/therapists/list';
import showError from './showError';
import appAction from '../actions/app';

export function* getReasons() {
  try {
    const { data } = yield call(optionsApi.get_reasons);
    yield put(optionsAction.reasons(mappingConsultation.buildOptions(data.data.items)));
  } catch (e) {
    if (e.response.status === 401) {
      yield put(appAction.auth('login'));
    } else {
      showError();
    }
    console.log('e', e);
  }
}

export function* getTopics() {
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);

  try {
    const { data } = yield call(optionsApi.get_topics, role);
    yield put(optionsAction.topics(mappingConsultation.buildOptions(data.data.items)));
  } catch (e) {
    if (e.response.status === 401) {
      yield put(appAction.auth('login'));
    } else {
      showError();
    }
    console.log('e', e);
  }
}

export function* getTopicsExtended() {
  try {
    const { data } = yield call(optionsApi.get_topics_extended);
    yield put(optionsAction.topics_extended(mappingConsultation.buildOptions(data.data.items)));
  } catch (e) {
    showError();
    console.log('e', e);
  }
}

export function* getStatuses() {
  try {
    const { data } = yield call(optionsApi.get_statuses);
    yield put(optionsAction.statuses(mappingConsultation.buildOptions(data.data.items)));
  } catch (e) {
    if (e.response.status === 401) {
      yield put(appAction.auth('login'));
    } else {
      showError();
    }
    console.log('e', e);
  }
}

export function* getGenders() {
  try {
    const { data } = yield call(optionsApi.get_genders);
    yield put(optionsAction.genders(mappingConsultation.buildOptions(data.data.items)));
  } catch (e) {
    if (e.response.status === 401) {
      yield put(appAction.auth('login'));
    } else {
      showError();
    }
    console.log('e', e);
  }
}

export function* getState() {
  try {
    const { data } = yield call(optionsApi.get_state);
    yield put(optionsAction.state(mappingConsultation.buildOptions(data.data.items)));
  } catch (e) {
    if (e.response.status === 401) {
      yield put(appAction.auth('login'));
    } else {
      showError();
    }
    console.log('e', e);
  }
}

export function* getCountry() {
  try {
    const { data } = yield call(optionsApi.get_countries);
    yield put(optionsAction.countries(mappingConsultation.buildOptions(data.data.items)));
  } catch (e) {
    if (e.response.status === 401) {
      yield put(appAction.auth('login'));
    } else {
      showError();
    }
    console.log('e', e);
  }
}

export function* getSpecialties() {
  try {
    const { data } = yield call(optionsApi.get_specialties);
    yield put(optionsAction.specialties(mappingConsultation.buildOptions(data.data.items)));
  } catch (e) {
    if (e.response.status === 401) {
      yield put(appAction.auth('login'));
    } else {
      showError();
    }
    console.log('e', e);
  }
}

export function* getZoomClients() {
  try {
    const { data } = yield call(optionsApi.get_zoom_clients);
    yield put(optionsAction.zoomClients(mappingTherapistsList.buildSelectOptions(data.data.items)));
  } catch (e) {
    if (e.response.status === 401) {
      yield put(appAction.auth('login'));
    } else {
      showError();
    }
    console.log('e', e);
  }
}

export function* getLicenses() {
  try {
    const { data } = yield call(optionsApi.get_licenses);
    yield put(optionsAction.licenses(mappingTherapistsList.buildLicensesOptions(data.data.items)));
  } catch (e) {
    if (e.response.status === 401) {
      yield put(appAction.auth('login'));
    } else {
      showError();
    }
    console.log('e', e);
  }
}

export function* getSpecificGroup() {
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);
  try {
    const { data } = yield call(optionsApi.get_specific_groups, role.toLowerCase());
    yield put(optionsAction.specificGroup(mappingTherapistsList.buildGroup(data.data.items, role)));
  } catch (e) {
    if (e.response.status === 401) {
      yield put(appAction.auth('login'));
    } else {
      showError();
    }
    console.log('e', e);
  }
}

export function* getCustomerStatuses() {
  //const getRole = (state) => state.user.profile.role;
  //const role = yield select(getRole);
  try {
    const { data } = yield call(optionsApi.get_customer_statuses);
    yield put(optionsAction.customerStatuses(mappingTherapistsList.buildStatuses(data.data.items)));
  } catch (e) {
    if (e.response.status === 401) {
      yield put(appAction.auth('login'));
    } else {
      showError();
    }
    console.log('e', e);
  }
}

export function* getTherapistStatuses() {
  try {
    const { data } = yield call(optionsApi.get_therapist_statuses);
    yield put(optionsAction.therapistStatuses(mappingTherapistsList.buildStatuses(data.data.items)));
  } catch (e) {
    showError();
    console.log('e', e);
  }
}

export function* getGroupStatuses() {
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);
  try {
    const { data } = yield call(optionsApi.get_group_statuses, role === 'ADMIN' ? role.toLowerCase() : '');
    yield put(optionsAction.groupStatuses(mappingTherapistsList.buildStatuses(data.data.items)));
  } catch (e) {
    if (e.response.status === 401) {
      yield put(appAction.auth('login'));
    } else {
      showError();
    }
    console.log('e', e);
  }
}

export function* getNumbersOfMembers() {
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);
  try {
    const { data } = yield call(optionsApi.get_numbers_of_members, role.toLowerCase());
    yield put(optionsAction.numberOfMembers(mappingTherapistsList.buildNumberOfMembers(data.data.items)));
  } catch (e) {
    if (e.response.status === 401) {
      yield put(appAction.auth('login'));
    } else {
      showError();
    }
    console.log('e', e);
  }
}

export function* getTitles() {
  try {
    const { data } = yield call(optionsApi.get_titles_ids);
    yield put(optionsAction.titles(mappingConsultation.buildOptions(data.data.items)));
  } catch (e) {
    if (e.response.status === 401) {
      yield put(appAction.auth('login'));
    } else {
      showError();
    }
    console.log('e', e);
  }
}
