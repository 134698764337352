/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/*
 * account creators
 */

import {
  AccountActions,
  GetAccountAction,
  GetInfoAction,
  Account,
  SetAccountAction,
  SetInfoAction,
  SendSupportFormData,
  SendSupportAccountAction,
  EditCheckTokenAction,
  EditAccountAction,
  LoaderAction,
  LoaderModalAction,
  VariableTokenAction,
  SetAccountAvatarAction,
} from '../../types/actions/account-actions';
import { ActionCallback, errorsCallback } from '../../types/actions/common-types';
import { EditFormRequest } from '../../types/request-types';
import { setErrorsCallback } from 'tools/helpFunctions';

const accountAction: AccountActions = {
  get(role: string, callback?: ActionCallback): GetAccountAction {
    return {
      type: 'GET_ACCOUNT',
      role,
      callback,
    };
  },
  getInfo(role?: string, callback?: ActionCallback): GetInfoAction {
    return {
      type: 'GET_ACCOUNT_INFO',
      role,
      callback,
    };
  },
  set(item: Account): SetAccountAction {
    return {
      type: 'ACCOUNT',
      payload: {
        item,
      },
    };
  },
  setInfo(info: any): SetInfoAction {
    return {
      type: 'ACCOUNT_INFO',
      payload: {
        info: info,
      },
    };
  },
  sendSupport(data?: SendSupportFormData, callback?: ActionCallback): SendSupportAccountAction {
    return {
      type: 'SEND_SUPPORT_MESSAGE',
      data,
      callback,
    };
  },
  editCheckToken(callback?: ActionCallback): EditCheckTokenAction {
    return {
      type: 'EDIT_CHECK_TOKEN',
      callback,
    };
  },
  getEditToken(data?: any, callback?: ActionCallback, errorsCallback?: setErrorsCallback): any {
    return {
      type: 'GET_EDIT_TOKEN',
      data,
      callback,
      errorsCallback,
    };
  },
  edit(data?: EditFormRequest, callback?: ActionCallback): EditAccountAction {
    return {
      type: 'EDIT_ACCOUNT',
      data: data,
      callback,
    };
  },
  loader(status: boolean): LoaderAction {
    return {
      type: 'ACCOUNT_LOADING',
      payload: {
        loader: status,
      },
    };
  },
  loaderModal(status: boolean): LoaderModalAction {
    return {
      type: 'ACCOUNT_LOADING_MODAL',
      payload: {
        loaderModal: status,
      },
    };
  },
  varibleToken(status: boolean): VariableTokenAction {
    return {
      type: 'ACCOUNT_VARIBLE_TOKEN',
      payload: {
        varibleToken: status,
      },
    };
  },
  setAvatar(file: any, callback?: ActionCallback): SetAccountAvatarAction {
    return {
      type: 'SET_ACCOUNT_AVATAR',
      file,
      callback,
    };
  },

  getNotifications(notifications?: any, callback?: ActionCallback): any {
    return {
      type: 'GET_ACCOUNT_NOTIFICATIONS',
      payload: {
        notification: notifications,
      },
      callback,
    };
  },

  readNotifications(data?: any, callback?: ActionCallback): any {
    return {
      type: 'READ_ACCOUNT_NOTIFICATIONS',
      data,
      callback,
    };
  },

  checkNotifications(checkNotification?: any, callback?: ActionCallback): any {
    return {
      type: 'CHECK_NOTIFICATIONS',
      payload: {
        checkNotification: checkNotification,
      },
      callback,
    };
  },
};

export default accountAction;
