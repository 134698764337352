import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
// components
import MainTemplate from '../../../components/templates/mainTemplate';
import { HeaderPageContainer, BodyPageContainer } from '../../../components/containers/pageContainer';
import AdminClientInfoContaiener from '../../../components/containers/clientInfoContainer/admin';
// actions
import appAction from '../../../redux/actions/app';
import clientAction from '../../../redux/actions/client';
import historyAction from '../../../redux/actions/history';
import npsScoreAction from '../../../redux/actions/nps_score';
import assesmentsAction from '../../../redux/actions/assesments';

const ClientPage: React.FC = ({ computedMatch }: any) => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(appAction.page('All Clients'));
    dispatch(clientAction.get(computedMatch.params.id));
    dispatch(npsScoreAction.getNpsScore(computedMatch.params.id));
    dispatch(historyAction.getHistoryGroups(computedMatch.params.id));
    dispatch(assesmentsAction.get(computedMatch.params.id));

    return () => {
      npsScoreAction.clear();
      historyAction.historyGroupsParams({ page: 1, per_page: 2 });
      historyAction.historySessionsParams({ page: 1, per_page: 4 });

      clientAction.clear();
      historyAction.setHistoryGroups([]);
      historyAction.setHistorySessions([]);
    };
  }, []);

  return (
    <MainTemplate>
      <HeaderPageContainer>
        <h2 className="text-page-header title-back" onClick={() => history.push('/all_clients')}>
          Client’s Info
        </h2>
      </HeaderPageContainer>
      <BodyPageContainer>
        <AdminClientInfoContaiener />
      </BodyPageContainer>
    </MainTemplate>
  );
};

export default ClientPage;
