import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Modal, Spin } from 'antd';
// componets
import { ClientBox } from '../../adminChangeGroup';
// actions
import clientAction from '../../../../../redux/actions/client';
// types
import { stateType } from '../../../../../types/types';
// styles
import './style.scss';
// icons
import { ReactComponent as CloseIcon } from 'assets/image/account/close4.svg';
import doneIcon from '../../../../../assets/image/questions/after.svg';

const ArchiveClient: React.FC<any> = () => {
  const [visible, setVisible] = useState(false);
  const [confirm, setConfirm] = useState(false);

  const loader = useSelector((state: stateType) => state.clients.loaderModal, shallowEqual);
  const item: any = useSelector((state: stateType) => state.clients.item, shallowEqual);

  const open = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <button className="btn-form" onClick={open}>
        Archive Client
      </button>
      {visible && (
        <Modal
          closeIcon={<CloseIcon />}
          visible={visible}
          onOk={handleOk}
          closable={true}
          onCancel={handleCancel}
          centered
          maskTransitionName="none"
          footer={null}
          className="archive-client-modal"
        >
          {!confirm ? (
            <Spin spinning={loader}>
              <ModalContainer item={item} clientID={item.id} setConfirm={setConfirm} />
            </Spin>
          ) : (
            <Spin spinning={loader}>
              <ModalSuccess item={item} handleCancel={handleCancel} />
            </Spin>
          )}
        </Modal>
      )}
    </>
  );
};

export default ArchiveClient;

const ModalContainer = ({ item, clientID, setConfirm }: any) => {
  const dispatch = useDispatch();

  const action = () => {
    dispatch(clientAction.archive(clientID, () => setConfirm(true)));
  };
  return (
    <div className="archive-client-container">
      <div className="archive-client-container__title">Are you sure you want to archive the following client?</div>
      <div className="archive-client-container__group-info">
        <ClientBox item={item} />
      </div>
      <div className="archive-client-container__block">
        <button className="btn-form" onClick={action}>
          Confirm
        </button>
      </div>
    </div>
  );
};

const ModalSuccess = ({ item, handleCancel }: any) => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(clientAction.get(item.id));
    };
  }, []);

  return (
    <div className="archive-client-container">
      <img src={doneIcon} alt="success" className="archive-client-success__success-img" />
      <div className="archive-client-container__title">You’ve successfully archived the following client:</div>
      <div className="archive-client-container__group-info archive-client-success__group-info">
        <ClientBox item={item} />
      </div>
      <div className="archive-client-container__block">
        <button className="btn-form" onClick={handleCancel}>
          Done
        </button>
      </div>
    </div>
  );
};
