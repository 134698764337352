/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { put, call } from 'redux-saga/effects';
import showError from '../showError';
// api
import { apiChoiceGroup } from '../../../api/choiceGroup';
// actions
import choiceGroupAction from '../../actions/choice_group';
import userActions from '../../actions/user';
import questionsAction from '../../actions/questions';
// helpers
import { choiceGroupMapping } from '../../../api/mapping/response/choiceGroup';
import { mapping } from '../../../api/mapping/response/user';

export function* sagaGetChoiceGroup(action) {
  yield put(choiceGroupAction.loader(true));
  yield put(choiceGroupAction.loaderModal(true));

  try {
    const response = yield call(apiChoiceGroup.getRecommended, action.query);
    yield put(choiceGroupAction.loader(false));
    yield put(choiceGroupAction.loaderModal(false));

    yield choiceGroupAction.create(choiceGroupMapping.buildArray(response.data.data.items));
  } catch (e) {
    yield put(choiceGroupAction.loader(false));
    yield put(choiceGroupAction.loaderModal(false));
    showError();
  }
}

export function* sagaJoinChoiceGroup(action) {
  yield put(choiceGroupAction.loader(true));

  try {
    const response = yield call(apiChoiceGroup.join, action.id);
    yield userActions.state(mapping.stateFlowBuild(response.data.data.item.stateFlow, response.data.data.item));
    yield questionsAction.status('before');

    yield choiceGroupAction.create(choiceGroupMapping.buildSmCardItem(response.data.data.item.medicalGroup));

    if (action.callback) {
      action.callback();
    }
  } catch (e) {
    showError();
  } finally {
    yield put(choiceGroupAction.loader(false));
  }
}
