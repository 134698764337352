import React from 'react';
import { Link } from 'react-router-dom';
// components
import SelectGroupsList from './SelectGroupsList';
// styles
import './style.scss';
import { useDispatch, useSelector } from 'react-redux';
import eventAnalytics from '../../../../redux/actions/analytics';
import { stateType } from 'types/types';

export interface SelectGroupsListType {
  groups: Array<any>;
  onSeeOtherGroup?: () => void;
  onGoBack?: () => void;
  number?: string;
  redirect?: boolean;
  reschedule?: boolean;
}

const SelectGroups: React.FC<SelectGroupsListType> = ({
  onSeeOtherGroup,
  onGoBack,
  groups,
  reschedule,
  redirect,
}: SelectGroupsListType) => {
  const dispatch = useDispatch();
  const isReschedule = useSelector((state: stateType) => state.app.isReschedule);

  const onClickSeeMoreGroups = () => {
    const arrayGroups = groups?.map((e: any) => e?.group);
    const answerIds = arrayGroups?.map((e: any) => e.id);
    if (isReschedule) {
      dispatch(eventAnalytics.analytics('reschedule-group-view-more', { answerIds }));
    } else {
      dispatch(eventAnalytics.analytics('change-group-see-more'));
    }
  };

  return (
    <>
      <div className="select-groups">
        <div className="select-groups__title">{`Your Top Group Match${
          groups?.length && groups?.length > 1 ? 'es' : ''
        }`}</div>

        <div className="select-groups__items">
          <SelectGroupsList onGoBack={onGoBack} groups={groups} number={'three'} redirect={redirect} />
        </div>

        <div className="select-groups__before" onClick={onClickSeeMoreGroups}>
          <Link to={'#'} onClick={onSeeOtherGroup}>
            {' '}
            See more groups
          </Link>
        </div>
      </div>
    </>
  );
};

export default SelectGroups;
