import React, { FC } from 'react';
// components
// style
import '../../style.scss';
import cn from 'classnames';
import Moment from 'react-moment';
import { momentOrdinal } from '../../../../../../tools/helpFunctions';
import BeginsIn from '../../../../../molecules/beginsIn';
import moment from 'moment-timezone';
import calendarIcon from '../../../../../../assets/image/dropdown/calendar.svg';
import timeIcon from '../../../../../../assets/image/dropdown/time.svg';
import manIcon from '../../../../../../assets/image/dropdown/man.svg';
import skillsIcon from '../../../../../../assets/image/dropdown/skills.svg';
import MoreDropdown from '../../../../../molecules/popovers/MoreDropdown';
import { SessionButton } from '../../items';

interface NextAndFutureSessions {
  e: any;
  commSession: boolean;
  item?: any;
  isPaused?: any;
  type?: any;
}

export const NextAndFutureSessions: FC<NextAndFutureSessions> = ({
  e,
  commSession = false,
  item,
  isPaused,
  type,
}: NextAndFutureSessions) => {
  moment.tz.setDefault('America/New_York');

  const actions = [
    {
      text: e?.title,
      icon: skillsIcon,
    },
    {
      text: `${e?.leader?.firstname ? `${e?.leader?.firstname}.` : ''} ${
        e?.leader?.lastname ? `${e?.leader?.lastname}.` : ''
      }`,
      icon: manIcon,
    },
    {
      text: moment(e?.start).tz('America/New_York').format('dddd, MMMM Do'),
      icon: calendarIcon,
    },
    {
      text: `${moment(e?.start).tz('America/New_York').format('h:mm A')} - ${moment(e?.end)
        .tz('America/New_York')
        .format('h:mm A')} ET`,
      icon: timeIcon,
    },
  ];

  return (
    <div key={e.id} className={cn('session-item')}>
      <div className={cn('session-item__status-history')} />
      <div className="session-item__wrap">
        <div className="session-item__avatar">
          {e?.picture?.url && <img src={e?.picture?.url} alt="session avatar" className="session-item__avatar-img" />}
          {commSession && (
            <div
              className={cn('session-item__event-type', { webinar: true, session: false })}
              style={{
                backgroundColor: e?.communityEventType?.name === 'Webinar' ? '#82c43c' : '#e2351e',
                borderBottomLeftRadius: 10,
                borderBottomRightRadius: 10,
              }}
            >
              {`Community ${e?.communityEventType?.name}`}
            </div>
          )}
        </div>
        <div className="session-item__body">
          <div style={{ position: 'absolute', top: 20, right: 20 }}>
            <MoreDropdown actions={actions} />
          </div>
          <div className="session-item__header">
            <div className="session-item__title">{e?.title}</div>
          </div>
          <div className="session-item__time-block">
            <div className="session-item__item">
              <div className="session-item__label">Date:</div>
              <div className="session-item__value">
                <Moment format="dddd, MMMM">{e?.start}</Moment>
                <Moment format="D">{e?.start}</Moment>
                <sup>{momentOrdinal(e?.start)}</sup>
              </div>
            </div>

            <div className="session-item__item">
              <div className="session-item__label">Time:</div>
              <div className="session-item__value">
                <Moment format="h:mm A">{e?.start}</Moment>
                {' - '}
                <Moment format="h:mm A">{e?.end}</Moment> ET
              </div>
            </div>
          </div>
          <div className="session-item__footer footer-top" style={{ display: 'flex' }}>
            <div>
              <BeginsIn start={e?.start} status={e?.status} />
            </div>
            {type && !isPaused && (
              <SessionButton item={item} name={type === 'join' ? 'Join this session' : 'Start session'} type={type} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
