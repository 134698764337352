/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { call, put, select } from 'redux-saga/effects';
import showError from '../showError';
import queryString from 'query-string';

// api
import { apiEvents } from '../../../api/eventsPage';
// actions
import upcomingAction from '../../actions/upcoming_events';
import eventsAction from '../../actions/admin_events';

export function* sagaUpcomingEvents() {
  yield put(upcomingAction.loaderEvents(true));
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);
  try {
    const response = yield call(apiEvents.getEvents, role.toLowerCase());
    yield put(upcomingAction.setEvents(response.data.data.items));
    yield put(upcomingAction.loaderEvents(false));
  } catch (e) {
    yield put(upcomingAction.loaderEvents(false));
    showError();
  }
}
export function* sagaGetPastEventsAndPostsPreview() {
  yield put(eventsAction.loader(true));
  try {
    const responsePaginationAdmin = yield call(apiEvents.getPastEventsAndPostsAdminPreview);
    yield put(eventsAction.setPastEventsPreview(responsePaginationAdmin.data.data));
    yield put(eventsAction.loader(false));
  } catch (e) {
    yield put(eventsAction.loader(false));
    showError();
  }
}

export function* sagaGetUpcomingEventsAndPostsPreview() {
  yield put(eventsAction.loader(true));
  try {
    const responsePaginationAdmin = yield call(apiEvents.getUpcomingEventsAndPostsAdminPreview);
    yield put(eventsAction.setUpcomingEventsPreview(responsePaginationAdmin.data.data));
    yield put(eventsAction.loader(false));
  } catch (e) {
    yield put(eventsAction.loader(false));
    showError();
  }
}

export function* sagaGetEventsAndPosts() {
  const getRole = (state) => state.user.profile.role;
  const getAllParams = (state) => state.upcoming_events.allParams;
  const typeOfEvents = (state) => state.upcoming_events.searchType;
  const role = yield select(getRole);
  const type = yield select(typeOfEvents);
  const allParams = yield select(getAllParams);
  if (role?.toLowerCase() === 'admin') {
    yield put(eventsAction.loader(true));
  } else {
    yield put(upcomingAction.loaderEvents(true));
  }
  try {
    if (role?.toLowerCase() === 'admin') {
      const responsePaginationAdmin = yield call(apiEvents.getEventsAndPostsAdmin, role.toLowerCase(), allParams);
      yield put(eventsAction.setEvents(responsePaginationAdmin.data.data));
    } else {
      // console.log(1111, allParams);
      // if (allParams?.page) {
      //   const responsePaginationPosts = yield call(apiEvents.getEventsAndPosts, role.toLowerCase(), allParams, 'past');
      //   yield put(
      //     upcomingAction.setPost(
      //       responsePaginationPosts.data.data.past.items,
      //       responsePaginationPosts.data.data.past.totalCount,
      //     ),
      //   );
      // } else {
      if (type !== 'past') {
        const responseAllEvents = yield call(apiEvents.getEventsAndPosts, role.toLowerCase(), allParams, 'upcoming');
        yield put(upcomingAction.setEvents(responseAllEvents.data.data.upcoming.items));
      }
      if (type !== 'upcoming') {
        const responsePaginationPosts = yield call(apiEvents.getEventsAndPosts, role.toLowerCase(), allParams, 'past');
        yield put(
          upcomingAction.setPost(
            responsePaginationPosts.data.data.past.items,
            responsePaginationPosts.data.data.past.totalCount,
          ),
        );
      }
    }
    // }
    if (role?.toLowerCase() === 'admin') {
      yield put(eventsAction.loader(false));
    } else {
      yield put(upcomingAction.loaderEvents(false));
    }
  } catch (e) {
    if (role?.toLowerCase() === 'admin') {
      yield put(eventsAction.loader(false));
    } else {
      yield put(upcomingAction.loaderEvents(false));
    }
    showError();
  }
}

export function* sagaGetPastPosts() {
  const getRole = (state) => state.user.profile.role;
  const getAllParams = (state) => state.upcoming_events.allParams;
  const role = yield select(getRole);
  const allParams = yield select(getAllParams);
  try {
    const responsePaginationPosts = yield call(apiEvents.getEventsAndPosts, role.toLowerCase(), allParams, 'past');
    yield put(
      upcomingAction.setPost(
        responsePaginationPosts.data.data.past.items,
        responsePaginationPosts.data.data.past.totalCount,
      ),
    );
  } catch (e) {
    showError();
  }
}

export function* sagaGetLengthsFilters() {
  yield put(upcomingAction.loaderEvents(true));
  try {
    const response = yield call(apiEvents.getLengthsFilters);
    yield put(upcomingAction.setLengthsFilters(response.data));
  } catch (e) {
    yield put(upcomingAction.loaderReg(false));
    showError();
  }
}

export function* sagaGetTypesFilters() {
  yield put(upcomingAction.loaderEvents(true));
  try {
    const response = yield call(apiEvents.getTypesFilters);
    yield put(upcomingAction.setTypesFilters(response.data));
  } catch (e) {
    yield put(upcomingAction.loaderReg(false));
    showError();
  }
}

export function* sagaGetTopicsFilters() {
  yield put(upcomingAction.loaderEvents(true));
  try {
    const response = yield call(apiEvents.getTopicsFilters);
    yield put(upcomingAction.setTopicsFilters(response.data));
  } catch (e) {
    yield put(upcomingAction.loaderReg(false));
    showError();
  }
}

export function* sagaGetOrdersFilters() {
  yield put(upcomingAction.loaderEvents(true));
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);
  try {
    const response = yield call(apiEvents.getOrdersFilters, role.toLowerCase());
    yield put(upcomingAction.setOrdersFilters(response.data));
  } catch (e) {
    yield put(upcomingAction.loaderReg(false));
    showError();
  }
}

export function* sagaBookMarkEvents(action) {
  const getRole = (state) => state.user.profile.role;
  const getAllParams = (state) => state.upcoming_events.allParams;
  const allParams = yield select(getAllParams);
  const role = yield select(getRole);
  try {
    const response = yield call(apiEvents.changeMarkEvent, role.toLowerCase(), action.data.id);
    if (response.data.status === 'success') {
      if (action.data.events === 'past') {
        yield call(sagaGetPastPosts);
      } else {
        if (allParams.sort === 3) {
          yield call(sagaGetOrdersFilters);
        } else {
          yield call(sagaUpcomingEvents);
        }
      }
    }
  } catch (e) {
    showError();
  }
}

export function* sagaPosts() {
  yield put(upcomingAction.loaderPosts(true));
  const getRole = (state) => state.user.profile.role;
  const getParams = (state) => state.upcoming_events.params;
  const getAllParams = (state) => state.upcoming_events.allParams;
  const getPastEvents = (state) => state.upcoming_events.posts;
  const role = yield select(getRole);
  const params = yield select(getParams);
  const pastEvents = yield select(getPastEvents);
  const allParams = yield select(getAllParams);

  // const searchParams = Object.assign({}, params, allParams); TODO: create Community Events search

  try {
    const response = yield call(apiEvents.getPosts, role.toLowerCase(), queryString.stringify(params));
    yield put(upcomingAction.loaderPosts(false));

    if (params.page > 1) {
      yield put(upcomingAction.setPost([...pastEvents, ...response.data.data.items], response.data.data.totalCount));
    } else {
      yield put(upcomingAction.setPost(response.data.data.items, response.data.data.totalCount));
    }
  } catch (e) {
    yield put(upcomingAction.loaderPosts(false));
    showError();
  }
}

export function* sagaRegister(action) {
  yield put(upcomingAction.loaderReg(true));
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);
  try {
    yield call(apiEvents.register, action.id, role.toLowerCase(), action.isRegister);
    yield put(upcomingAction.loaderReg(false));
    action.callback && action.callback();
  } catch (e) {
    yield put(upcomingAction.loaderReg(false));
    showError();
  }
}
