import React from 'react';
import Moment from 'react-moment';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Icon from '@ant-design/icons';
import { Menu, Spin } from 'antd';
// components
import ProfileAvatar from './avatar';
// actions
import { authActions } from 'redux/actions/auth';
import appAction from 'redux/actions/app';
// icons
import { ReactComponent as IconPeople } from 'assets/image/headers/svg/people.svg';
import { ReactComponent as IconUser } from 'assets/image/headers/svg/user.svg';
import { ReactComponent as IconPhone } from 'assets/image/headers/svg/phone.svg';
import { ReactComponent as Logout } from 'assets/image/headers/svg/logout.svg';
// styles
import { stateType } from 'types/types';
import { userProfile } from 'redux/reducers/user';
// styles
import './style/menu.scss';
import eventAnalytics from '../../../redux/actions/analytics';

const ProfileMenu: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const profile: userProfile = useSelector((state: stateType) => state.user.profile);

  const onClickManageTab = (route: string) => {
    dispatch(eventAnalytics.analytics(route));
    if (route === 'account-details') {
      dispatch(eventAnalytics.analytics('visit-profile', { location: 'navbar' }));
    }
  };

  const menuController = (role: string) => {
    switch (role) {
      case 'ADMIN':
        return (
          <Menu className="profile-dropdown-menu">
            <Menu.Item key={'profile'}>
              <div className="profile-dropdown-menu__user">
                <div className="profile-dropdown-menu__user-container">
                  <ProfileAvatar />
                  <div className="profile-dropdown-menu__user-info">
                    <div className="profile-dropdown-menu__user-name">
                      {profile.username ? profile.username : profile.email}
                    </div>
                  </div>
                </div>
              </div>
            </Menu.Item>

            <ButtonLogout />
          </Menu>
        );

      case 'CUSTOMER':
        return (
          <Menu className="profile-dropdown-menu">
            <Menu.Item key={'profile'}>
              <div className="profile-dropdown-menu__user">
                <div className="profile-dropdown-menu__user-container">
                  <ProfileAvatar />
                  <div className="profile-dropdown-menu__user-info">
                    <div className="profile-dropdown-menu__user-name">{profile.username}</div>
                    <div className="profile-dropdown-menu__session-time">
                      Joined <Moment format="MMMM DD YYYY">{profile.create}</Moment>
                    </div>
                  </div>
                </div>
              </div>
            </Menu.Item>

            <Menu.Item
              key={'details'}
              className="profile-dropdown-menu__item"
              onClick={() => {
                dispatch(appAction.account('details'));
                history.push('/profile');
              }}
            >
              <div className="profile-dropdown-menu__item-wrap">
                <Icon component={IconUser} style={{ fontSize: '20px' }} />
                <div className="profile-dropdown-menu__label" onClick={() => onClickManageTab('account-details')}>
                  Account Details
                </div>
              </div>
            </Menu.Item>

            <Menu.Item
              key={'subscription'}
              className="profile-dropdown-menu__item"
              onClick={() => {
                dispatch(appAction.account('subscription'));
                history.push('/profile');
              }}
            >
              <div className="profile-dropdown-menu__item-wrap">
                <Icon component={IconPeople} style={{ fontSize: '20px' }} />
                <div className="profile-dropdown-menu__label" onClick={() => onClickManageTab('manage-subscription')}>
                  Manage Subscription
                </div>
              </div>
            </Menu.Item>

            <Menu.Item
              key={'support'}
              className="profile-dropdown-menu__item"
              onClick={() => {
                dispatch(appAction.account('support'));
                history.push('/profile');
              }}
            >
              <div className="profile-dropdown-menu__item-wrap">
                <Icon component={IconPhone} style={{ fontSize: '20px' }} />
                <div className="profile-dropdown-menu__label">Contact Support</div>
              </div>
            </Menu.Item>

            <ButtonLogout />
          </Menu>
        );

      case 'THERAPIST':
        return (
          <Menu className="profile-dropdown-menu">
            <Menu.Item key={'profile'}>
              <div className="profile-dropdown-menu__user">
                <div className="profile-dropdown-menu__user-container">
                  <ProfileAvatar />
                  <div className="profile-dropdown-menu__user-info">
                    <div className="profile-dropdown-menu__user-name">{profile.username}</div>
                  </div>
                </div>
              </div>
            </Menu.Item>

            <Menu.Item
              key={'details'}
              className="profile-dropdown-menu__item"
              onClick={() => {
                history.push('/profile');
              }}
            >
              <div className="profile-dropdown-menu__item-wrap">
                <Icon component={IconUser} style={{ fontSize: '20px' }} />
                <div className="profile-dropdown-menu__label">Account Details</div>
              </div>
            </Menu.Item>

            <ButtonLogout />
          </Menu>
        );

      default:
        return null;
    }
  };
  return menuController(profile.role);
};

export default ProfileMenu;

const ButtonLogout = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const loader: boolean = useSelector((state: stateType) => state.auth.loader);

  const redirect_url = localStorage.getItem('role') ? `/login-${localStorage.getItem('role')}` : '/login-customer';

  const logoutAction = () => {
    //here
    if (!loader) {
      const data = {
        refreshToken: localStorage.getItem('refresh_token'),
      };

      dispatch(
        authActions.logout(data, () => {
          localStorage.removeItem('token');
          localStorage.removeItem('refresh_token');
          history.push(redirect_url);
        }),
      );
    }
  };

  return (
    <Menu.Item key={'logout'} className="profile-dropdown-menu__item profile-dropdown-menu__item_logout">
      <div className="profile-dropdown-menu__item-wrap" onClick={logoutAction}>
        {loader ? <Spin size="small" /> : <Icon component={Logout} style={{ fontSize: '20px' }} />}
        <div className="profile-dropdown-menu__label">Log out</div>
      </div>
    </Menu.Item>
  );
};
