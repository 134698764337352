import React from 'react';
import { Dropdown } from 'antd';
// components
import ProfileMenu from './menu';
import ProfileAvatar from './avatar';
// icons
import { CaretDownOutlined } from '@ant-design/icons';
// styles
import './style/profile.scss';

const ProfileDropDown: React.FC = () => {
  return (
    <Dropdown
      overlay={<ProfileMenu />}
      placement="bottomRight"
      trigger={['click']}
      arrow
      getPopupContainer={(trigger) => trigger}
    >
      <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
        <ProfileButton />
      </a>
    </Dropdown>
  );
};

export default ProfileDropDown;

interface ProfileButtonType {
  name?: string;
  url?: string;
}

const ProfileButton: React.FC<ProfileButtonType> = ({ name, url }: ProfileButtonType) => {
  return (
    <div className="profile-button">
      <ProfileAvatar />
      <CaretDownOutlined style={{ color: '#92929d', marginLeft: '7px' }} />
    </div>
  );
};
