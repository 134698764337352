import React from 'react';
// styles
import './style.scss';

interface TableContainerType {
  children: React.ReactNode;
}

const TableContainer: React.FC<TableContainerType> = ({ children }: TableContainerType) => {
  return <div className="table-container">{children}</div>;
};

export default TableContainer;

const EmptyTable = () => {
  return (
    <div className="empty-table">
      <div className="empty-table__header">
        <div className="empty-table__title">He</div>
        <div className="empty-table__sub-title">Name</div>
      </div>
      <div className="empty-table__body">
        <img src="" alt="" />
      </div>
      <div className="empty-table__footer">
        <p>
          {`Your group is currently being marketed and awaiting group members. We'll notify you when the first member
          joins.`}
        </p>
      </div>
    </div>
  );
};
