import React, { useCallback, useEffect, useState } from 'react';
import useFilesContext from '../../../../hooks/useFilesContext/useFilesContext';
import { Button, makeStyles } from '@material-ui/core';
import useChatContext from '../../../../hooks/useChatContext/useChatContext';
import useParticipantMenuContext from '../../../../hooks/useParticipantMenuContext/useParticipantMenuContext';
import useVideoContext from '../../../../hooks/useVideoContext/useVideoContext';
import FolderIcon from '../../../../icons/FolderIcon';
import clsx from 'clsx';

export const ANIMATION_DURATION = 700;

const useStyles = makeStyles({
  iconContainer: {
    position: 'relative',
    display: 'flex',
  },
  circle: {
    width: '20px',
    height: '20px',
    backgroundColor: '#E19D89',
    borderRadius: '50%',
    position: 'absolute',
    top: '-8px',
    left: '8px',
    opacity: 0,
    transition: `opacity ${ANIMATION_DURATION * 0.5}ms ease-in`,
  },
  hasNewFiles: {
    opacity: 1,
  },
  ring: {
    border: '3px solid #E19D89',
    borderRadius: '30px',
    height: '14px',
    width: '14px',
    position: 'absolute',
    left: '11px',
    top: '-5px',
    opacity: 0,
  },
  animateRing: {
    animation: `$expand ${ANIMATION_DURATION}ms ease-out`,
    animationIterationCount: 1,
  },
  '@keyframes expand': {
    '0%': {
      transform: 'scale(0.1, 0.1)',
      opacity: 0,
    },
    '50%': {
      opacity: 1,
    },
    '100%': {
      transform: 'scale(1.4, 1.4)',
      opacity: 0,
    },
  },
});
export default function ToggleFilesButton(props: { className?: string }) {
  const { isFileWindowOpen, setIsFileWindowOpen, hasNewFiles, fileList } = useFilesContext();
  const { setIsChatWindowOpen } = useChatContext();
  const { setIsParticipantMenuOpen } = useParticipantMenuContext();
  const { setIsBackgroundSelectionOpen } = useVideoContext();
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const [previousCount, setPreviousCount] = useState(0);
  const cssClasses = useStyles();

  useEffect(() => {
    if (shouldAnimate) {
      setTimeout(() => setShouldAnimate(false), ANIMATION_DURATION);
    }
  }, [shouldAnimate]);

  useEffect(() => {
    if (!isFileWindowOpen) {
      if (fileList.length > previousCount) {
        setShouldAnimate(true);
        setPreviousCount(fileList.length);
      }
    } else {
      setShouldAnimate(false);
      if (fileList.length > previousCount) {
        setPreviousCount(fileList.length);
      }
    }
  }, [fileList, isFileWindowOpen, previousCount]);

  const toggleFiles = useCallback(
    (e) => {
      setIsFileWindowOpen(!isFileWindowOpen);
      setIsChatWindowOpen(false);
      setIsParticipantMenuOpen(false);
      setIsBackgroundSelectionOpen(false);
    },
    [isFileWindowOpen],
  );

  return (
    <Button onClick={toggleFiles} className={props.className}>
      <FolderIcon size={20} color={'#ffffff'} />
      <div className={clsx(cssClasses.ring, { [cssClasses.animateRing]: shouldAnimate })} />
      <div className={clsx(cssClasses.circle, { [cssClasses.hasNewFiles]: hasNewFiles })} />
    </Button>
  );
}
