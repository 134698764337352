import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

// components
import MainTemplate from '../../../components/templates/mainTemplate';
import { HeaderPageContainer, BodyPageContainer } from '../../../components/containers/pageContainer';
import TherapistClientInfoContainer from '../../../components/containers/clientInfoContainer/therapist';
// actions
import appAction from '../../../redux/actions/app';
import clientAction from '../../../redux/actions/client';
import assesementsAction from '../../../redux/actions/assesments';

const TherapistsClientsPage: React.FC = ({ computedMatch }: any) => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(appAction.page('My Clients'));
    dispatch(clientAction.get(computedMatch.params.id));
    dispatch(assesementsAction.get(computedMatch.params.id));
  }, []);
  return (
    <MainTemplate>
      <HeaderPageContainer>
        <h2 className="text-page-header title-back" onClick={() => history.goBack()}>
          Client’s Info
        </h2>
      </HeaderPageContainer>
      <BodyPageContainer>
        <TherapistClientInfoContainer />
      </BodyPageContainer>
    </MainTemplate>
  );
};

export default TherapistsClientsPage;
