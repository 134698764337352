/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { call, put } from 'redux-saga/effects';
// api
import { apiAuth } from '../../api/auth';
// actions
import { authActions } from '../actions/auth';
import userActions from '../actions/user';
import appAction from '../actions/app';
import mainMenuAction from '../actions/main_menu';
import choiceGroupAction from 'redux/actions/choice_group';
// helps
import { mapping } from '../../api/mapping/response/user';
import { authErrors } from './error-messages';
import showError from './showError';
import { notification } from 'antd';

export function* sagaLogin(action) {
  const redirect = JSON.parse(localStorage.getItem('redirect_url'));

  yield put(authActions.loader(true));
  try {
    let initRole = localStorage.getItem('role') ? localStorage.getItem('role') : 'customer';

    const response = yield call(apiAuth.login, action.data, initRole);
    if (response.data.data.item?.stateFlow === 2 && response?.data?.data?.item?.roles[0] === 'ROLE_CUSTOMER') {
      yield put(authActions.loader(false));
      window.location.href = `${process.env.REACT_APP_SERIES_DOMEN}`;
    } else {
      yield put(authActions.loader(false));
      yield put(appAction.auth('auth'));

      yield userActions.create(response.data.data.item);
      yield userActions.state(mapping.stateFlowBuild(response.data.data.item.stateFlow, response.data.data.item));
      yield authActions.success(response.data.data);
      yield put(mainMenuAction.propgressPopover(response.data.data.item?.progressScoreType ? true : false));

      sessionStorage.setItem('register', '1');

      if (action.callback) {
        if (initRole === redirect?.role) {
          action.callback.push(redirect?.path || '/');
        } else {
          action.callback.push('/');
        }
        localStorage.removeItem('redirect_url');
      }
    }
  } catch (e) {
    console.log(e);
    yield put(authActions.loader(false));
    yield appAction.auth('login');

    if (e.response.status === 401) {
      action.errorsCallback([
        {
          propertyPath: 'email',
          message: authErrors.BAD_CREDENTIALS,
        },
        {
          propertyPath: 'password',
          message: authErrors.BAD_CREDENTIALS,
        },
      ]);
    } else {
      showError();
    }
  }
}

export function* sagaRegistration(action) {
  yield put(authActions.loader(true));
  try {
    const responseCheckEmail = yield call(apiAuth.checkEmail, action.data);
    if (responseCheckEmail?.data?.data?.item?.isExists) {
      if (!responseCheckEmail.data.data.item?.customer) {
        action.errorsCallback([{ propertyPath: 'email', message: 'This user exists.' }]);
      } else {
        if (responseCheckEmail?.data?.data?.item?.paid) {
          action.errorsCallback([{ propertyPath: 'email', message: 'This user exists.' }]);
        } else {
          const responseLoginOpen = yield call(apiAuth.openLoginLead, action.data);
          yield put(appAction.auth('consultation'));
          yield authActions.success(responseLoginOpen.data.data);
          yield userActions.create(responseLoginOpen.data.data.item);
          yield userActions.state(
            mapping.stateFlowBuild(responseLoginOpen.data.data.item.stateFlow, responseLoginOpen.data.data.item),
          );
          yield put(
            mainMenuAction.propgressPopover(responseLoginOpen.data.data.item?.progressScoreType ? true : false),
          );
          sessionStorage.setItem('register', '1');
          action.callback && action.callback();
        }
      }
    } else {
      let initRole = localStorage.getItem('role') ? localStorage.getItem('role') : 'customer';
      yield call(apiAuth.registration, action.data);
      const response = yield call(
        apiAuth.login,
        { email: action.data.email, password: action.data.password },
        initRole,
      );
      yield put(appAction.auth('consultation'));
      yield authActions.success(response.data.data);
      yield userActions.create(response.data.data.item);
      yield userActions.state(mapping.stateFlowBuild(response.data.data.item.stateFlow, response.data.data.item));
      yield put(mainMenuAction.propgressPopover(response.data.data.item?.progressScoreType ? true : false));
      sessionStorage.setItem('register', '1');
      action.callback && action.callback();
    }
  } catch (e) {
    if (e.response.status === 400 && e.response.data.violations) {
      action.errorsCallback(e.response.data.violations);
    } else if (e.response.status === 500) {
      // TODO: need fix this error to backend
      action.errorsCallback([{ propertyPath: 'email', message: 'This user exists.' }]);
    }
  } finally {
    yield put(authActions.loader(false));
  }
}

export function* sagaForgotPassword(action) {
  yield put(authActions.loader(true));
  try {
    yield call(apiAuth.forgotPassword, action.data);
    yield put(authActions.loader(false));

    if (action.callback) {
      action.callback();
    }
  } catch (e) {
    yield put(authActions.loader(false));

    if (e.response.status === 404) {
      action.errorsCallback([{ propertyPath: 'email', message: authErrors.EMAIL_NOT_FOUND }]);
    } else {
      showError();
    }
  }
}

export function* sagaNewPassword(action) {
  yield put(authActions.loader(true));
  try {
    yield call(apiAuth.newPassword, action.data);
    yield put(authActions.loader(false));

    if (action.callback) {
      action.callback();
    }
  } catch (e) {
    yield put(authActions.loader(false));

    showError();
  }
}

export function* sagaNewPasswordTherapist(action) {
  yield put(authActions.loader(true));
  try {
    yield call(apiAuth.newPasswordTherapist, action.data);
    yield put(authActions.loader(false));

    if (action.callback) {
      action.callback();
    }
  } catch (e) {
    yield put(authActions.loader(false));

    showError();
  }
}

export function* sagalogout(action) {
  yield put(authActions.loader(true));
  try {
    yield call(apiAuth.logout, action.data);
    yield put(authActions.loader(false));
    yield put(authActions.reset());

    yield put(appAction.auth('logout'));

    if (action.callback) {
      action.callback();
    }
  } catch (e) {
    yield put(authActions.loader(false));
    showError();
  }
}

export function* sagaAuthBack(action) {
  yield put(authActions.loader(true));
  yield put(choiceGroupAction.loader(true));

  try {
    const response = yield call(apiAuth.back, action.typeGoBack);
    yield userActions.state(mapping.stateFlowBuild(response.data.data.item.stateFlow, response.data.data.item));

    yield put(authActions.loader(false));

    if (action.callback) {
      action.callback();
    }
  } catch (e) {
    yield put(authActions.loader(false));

    showError();
  }
}

export function* sagaGetAuthKey(action) {
  yield put(authActions.loader(true));

  try {
    const { data } = yield call(apiAuth.getKey, action.id);
    yield navigator.clipboard.writeText(
      `${window.location.origin}/auth_by_key?role=${action.data}&key=${data.data.item.key}&id=${action.id}`,
    );

    notification.success({
      message: 'Copied!',
      duration: 2,
    });

    yield put(authActions.loader(false));
  } catch (e) {
    notification.error({
      message: 'Failed to copy!',
      duration: 2,
    });

    yield put(authActions.loader(false));
    showError();
  }
}

export function* sagaLoginByKey(action) {
  try {
    const { data } = yield call(apiAuth.loginByKey, action.id, action.data);
    yield put(appAction.auth('auth'));

    yield authActions.success(data.data.item);

    yield window.location.reload();

    if (action.callback) {
      action.callback();
    }
  } catch (e) {
    showError();
  } finally {
  }
}
