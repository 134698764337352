import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Spin } from 'antd';

// components
import AuthTemplate from '../../components/templates/authTemplate';
import NewPasswordTherapistForm from '../../components/organisms/forms/newPasswordTherapist';
// components texts
import { NewPasswordPreviewText } from '../../components/atoms/texts/authTemplate/preview-text';

const NewPasswordPage: React.FC = () => {
  useEffect(() => {
    localStorage.removeItem('token');
    localStorage.removeItem('refresh_token');
  }, []);

  const [textFooter] = useState('©2022 Grouport Inc. All rights reserved.');

  const loader: boolean = useSelector((state: any) => state.auth.loader, shallowEqual);

  return (
    <div className="login-page custom-style">
      <AuthTemplate text_footer={textFooter} headerComponent={<NewPasswordPreviewText />}>
        <Spin tip="Loading..." spinning={loader}>
          <NewPasswordTherapistForm />
        </Spin>
      </AuthTemplate>
    </div>
  );
};

export default NewPasswordPage;
