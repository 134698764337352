import React, { FC, useEffect } from 'react';
import { Spin } from 'antd';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
// actions
import appAction from '../../../redux/actions/app';

const ContactSupport: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams<{ action: any; type: any }>();

  useEffect(() => {
    switch (params.type) {
      case 'support':
        dispatch(appAction.account('support'));
        break;
      case 'manage-subscription':
        dispatch(appAction.account('subscription'));
    }
    history.push('/profile');
  }, []);
  return <Spin spinning={true} style={{ height: '100vh', width: '100vw' }} />;
};

export default ContactSupport;
