import React from 'react';
import { Message } from '@twilio/conversations/lib/message';
import MessageInfo from './MessageInfo/MessageInfo';
import MessageListScrollContainer from './MessageListScrollContainer/MessageListScrollContainer';
import TextMessage from './TextMessage/TextMessage';
import useVideoContext from '../../../../hooks/useVideoContext/useVideoContext';
import MediaMessage from './MediaMessage/MediaMessage';
import { makeStyles } from '@material-ui/core';
import { displayName, initials } from '../../Helpers/helpers';
import useUserDataContext from '../../../../hooks/useUserDataContext/useUserDataContext';

interface MessageListProps {
  messages: Message[];
}

const getFormattedTime = (message?: Message) =>
  message?.dateCreated.toLocaleTimeString('en-us', { hour: 'numeric', minute: 'numeric' }).toLowerCase();

const useStyles = makeStyles((theme) => ({
  messageContainer: {
    display: 'block',
    padding: '0 0 20px',
  },
  initials: {
    width: 30,
    height: 30,
    backgroundColor: 'rgba(152, 162, 179, 1)',
    borderRadius: '50%',
    textAlign: 'center',
    color: '#ffffff',
    paddingTop: '7px',
    float: 'left',
    cursor: 'default',
  },
}));

export default function MessageList({ messages }: MessageListProps) {
  const { room } = useVideoContext();
  const localParticipant = room!.localParticipant;
  const cssClasses = useStyles();
  const { getUserName } = useUserDataContext();

  return (
    <MessageListScrollContainer messages={messages}>
      {messages.map((message, idx) => {
        const time = getFormattedTime(message)!;
        const previousTime = getFormattedTime(messages[idx - 1]);

        // Display the MessageInfo component when the author or formatted timestamp differs from the previous message
        const shouldDisplayMessageInfo = time !== previousTime || message.author !== messages[idx - 1]?.author;

        const isLocalParticipant = localParticipant.identity === message.author;

        return (
          <React.Fragment key={message.sid}>
            <div className={cssClasses.messageContainer}>
              <div className={cssClasses.initials} title={getUserName(message.author)}>
                {initials(message.author)}
              </div>
              {message.type === 'text' && <TextMessage body={message.body} isLocalParticipant={isLocalParticipant} />}
              {message.type === 'media' && <MediaMessage media={message.media} />}
              {shouldDisplayMessageInfo && (
                <MessageInfo
                  author={message.author}
                  isLocalParticipant={isLocalParticipant}
                  dateCreated={time}
                  date={message.dateCreated}
                />
              )}
            </div>
          </React.Fragment>
        );
      })}
    </MessageListScrollContainer>
  );
}
