import React, { createContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

type RoomInfoContextType = {
  roomType: string;
  hostFirstName: string;
  hostLastName: string;
  hostTitle: string;
  hostInitials: string;
  hostLicenses: string[];
};

export const RoomInfoContext = createContext<RoomInfoContextType>(null!);

export const RoomInfoProvider: React.FC = ({ children }) => {
  const [roomType, setRoomType] = useState('session');
  const [hostFirstName, setHostFirstName] = useState('');
  const [hostLastName, setHostLastName] = useState('');
  const [hostTitle, setHostTitle] = useState('');
  const [hostInitials, setHostInitials] = useState('');
  const [hostLicenses, setHostLicences] = useState([]);

  const { URLRoomName } = useParams<{ URLRoomName: string }>();

  useEffect(() => {
    const endpoint = process.env.REACT_APP_API_URL + 'twilio/room_info';
    const JWTtoken = localStorage.getItem('token');
    fetch(endpoint, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${JWTtoken}`,
      },
      body: JSON.stringify({
        room_name: URLRoomName,
      }),
    })
      .then((response) => response.json())
      .then((responseData) => {
        console.log(responseData);
        if (responseData.sessionFound) {
          setHostFirstName(responseData.hostFirstName);
          setHostLastName(responseData.hostLastName);
          setHostTitle(responseData.hostTitle);
          setHostInitials(responseData.hostFirstName.toUpperCase()[0] + responseData.hostLastName.toUpperCase()[0]);
          setHostLicences(responseData.hostLicenses);
        } else {
          setHostFirstName('');
          setHostLastName('');
          setHostTitle('');
          setHostInitials('');
          setHostLicences([]);
        }
      });
  }, [URLRoomName]);

  return (
    <RoomInfoContext.Provider
      value={{
        roomType,
        hostFirstName,
        hostLastName,
        hostTitle,
        hostInitials,
        hostLicenses,
      }}
    >
      {children}
    </RoomInfoContext.Provider>
  );
};
