import React, { FC, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Modal, Spin } from 'antd';
// components
import { ClientBox } from '../adminChangeGroup';
import { CustomerGroupCard } from '../../cards/groupCard/SmallGroupCard';
import BorderCard from '../../cards/groupCard/cardGroupElements/BorderCard';
// types
import { stateType } from '../../../../types/types';
// actions
import clientAction from '../../../../redux/actions/client';
// images
import ImgAfter from '../../../../assets/image/questions/after.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/image/account/close4.svg';
// style
import './style.scss';

const ApproveClientButton: FC<any> = ({ disabled }: any) => {
  const [visible, setVisible] = useState(false);
  const [success, setSuccess] = useState(false);

  const loader = useSelector((state: stateType) => state.clients.loaderModal, shallowEqual);
  const item: any = useSelector((state: stateType) => state.clients.item, shallowEqual);

  const open = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onSuccess = () => {
    setSuccess(true);
  };

  return (
    <>
      <button className="btn-form" onClick={open} disabled={disabled}>
        Confirm Client Group Choice
      </button>
      {visible && (
        <Modal
          closeIcon={<CloseIcon />}
          visible={visible}
          onOk={handleOk}
          closable={true}
          onCancel={handleCancel}
          centered
          maskTransitionName="none"
          footer={null}
          className="approve-client-modal"
        >
          {!success ? (
            <Spin spinning={loader}>
              <ModalContainer onSuccess={onSuccess} item={item} clientID={item.id} />
            </Spin>
          ) : (
            <ModalSuccessContainer handleCancel={handleCancel} item={item} clientID={item.id} setSuccess={setSuccess} />
          )}
        </Modal>
      )}
    </>
  );
};

export default ApproveClientButton;

const ModalContainer = ({ onSuccess, item, clientID }: any) => {
  const groupData = {
    therapist_title: item.medicalGroup?.therapist.title ? { name: item.medicalGroup?.therapist.title } : null,
    therapist_name: `${item.medicalGroup?.therapist.firstname} ${item.medicalGroup?.therapist.lastname}`,
  };
  const dispatch = useDispatch();

  const action = () => {
    dispatch(clientAction.approve(clientID, onSuccess));
  };

  return (
    <div className="approve-client-container">
      <div className="approve-client-container__title">
        Are you sure you want to approve {item?.firstname} {item?.lastname} for the following group?
      </div>
      <div className="approve-client-container__group-info">
        <BorderCard white>
          <CustomerGroupCard item={item} group={groupData} />
        </BorderCard>
      </div>
      <div className="approve-client-container__block">
        <button className="btn-form" onClick={action}>
          Confirm
        </button>
      </div>
    </div>
  );
};

const ModalSuccessContainer = ({ handleCancel, item, clientID, setSuccess }: any) => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      setSuccess(false);
      dispatch(clientAction.get(clientID));
    };
  }, []);

  return (
    <div className="approve-client-container">
      <div className="approve-client-container__title">
        <img className="approve-client-container__finish-ico" src={ImgAfter} alt="Finish icon" />
        <div>You’ve successfully approved the client!</div>
      </div>
      <div className="approve-client-container__group-info">
        <ClientBox item={item} />
      </div>
      <div className="approve-client-container__block">
        <button className="btn-form" onClick={handleCancel}>
          Done
        </button>
      </div>
    </div>
  );
};
