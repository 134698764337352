/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

export const mappingEvents = {
  buildArray(array) {
    if (array) {
      return array;
    }
    return [];
  },

  buildItem(object) {
    if (object) {
      return object;
    }
    return {};
  },
};
