import React from 'react';
// styles
import './style.scss';

interface BoxContainerType {
  classes: string;
  children: React.ReactNode;
}

const Box: React.FC<BoxContainerType> = ({ classes, children }: BoxContainerType) => {
  return <div className={classes}>{children}</div>;
};

export default Box;
