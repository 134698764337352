import React from 'react';
import { Button } from '@material-ui/core';
import { useAppState } from '../../../../state';
import GridIcon from '../../../../icons/GridIcon';

export default function ToggleLayoutButton(props: { className?: string }) {
  const { toggleUserLayout } = useAppState();
  return (
    <Button onClick={toggleUserLayout} className={props.className}>
      <GridIcon size={20} color={'#ffffff'} />
    </Button>
  );
}
