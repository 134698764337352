import React from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
// actions
import { authActions } from '../../../../redux/actions/auth';
import { mapping } from '../../../../api/mapping/request/auth';
// components
import { LoginInputEmail, SubmitButton } from '../../../molecules/inputsBlocks/login';
// style
import '../style.scss';

import { setFormErrors } from '../../../../tools/helpFunctions';
import { IErrors } from '../../../../types/actions/common-types';
import eventAnalytics from 'redux/actions/analytics';
import { apiAnalytics } from 'api/analytics';

interface FormData {
  email: string;
}

interface ForgotPasswordInterface {
  openSuccess?: () => void;
}

const FormForgotPassword: React.FC<ForgotPasswordInterface> = ({ openSuccess }: ForgotPasswordInterface) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<FormData>();

  const dispatch = useDispatch();

  const onSubmit = (data: FormData) => {
    const setErrors = (errors: Array<IErrors>) => setFormErrors(setError, errors);

    dispatch(authActions.forgotPassword(mapping.forgotPasswordBuild(data), openSuccess, setErrors));
    dispatch(apiAnalytics.resetPassword({ params: { userEmail: data.email } }));
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="form-edit">
      <LoginInputEmail errors={errors.email ? errors.email.message : undefined} register={register} />
      <SubmitButton name={'Reset password'} />
    </form>
  );
};

export default FormForgotPassword;
