import React, { FC, useEffect, useRef, useState } from 'react';
import { Input } from 'antd';
import debounce from 'lodash/debounce';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
// icons
import { ReactComponent as SearchIcon } from '../../../../assets/image/inputs/search.svg';
import Icon from '@ant-design/icons';
// components
import InputField from '../../../molecules/inputField';
// actions
import upcomingAction from '../../../../redux/actions/upcoming_events';
// types
import { stateType } from '../../../../types/types';
import { roleType } from '../../../../types/commonPropTypes';
// configs
import { getSearchEvent } from '../../../../tools/helpFunctions';
// style
import '../style.scss';

const SearchEvents: FC<roleType> = ({ role }: roleType) => {
  const [focus, setState] = useState(false);
  const dispatch = useDispatch();
  const inputField: any = useRef(null);
  const allParams: any = useSelector((state: stateType) => state.upcoming_events.allParams, shallowEqual);
  const type: string = useSelector((state: stateType) => state.upcoming_events.searchType, shallowEqual);
  const loaderEvents: boolean = useSelector((state: stateType) => state.upcoming_events.loaderEvents, shallowEqual);
  const loaderPasts: boolean = useSelector((state: stateType) => state.upcoming_events.loaderPosts, shallowEqual);

  useEffect(() => {
    if (focus) {
      !loaderEvents && !loaderPasts && inputField.current.focus();
    }
  }, [loaderEvents, loaderPasts]);

  const onChange = async (e: any) => {
    try {
      await dispatch(upcomingAction.allParams({ ...allParams, name: e.target.value.trim() }));
      getSearchEvent(type, dispatch);
    } catch (e) {
      console.log(e);
    }
  };

  const onFocus = () => {
    setState(true);
  };
  return (
    <div className="search-clients-field">
      <InputField>
        <Input
          prefix={<Icon component={SearchIcon} style={{ fontSize: 24, color: '#92929D' }} />}
          defaultValue={allParams.name ? allParams.name : ''}
          onFocus={onFocus}
          ref={inputField}
          // disabled={loaderEvents || loaderPasts}
          placeholder="Search"
          className="search-clients-field__input"
          onChange={debounce(onChange, 600)}
        />
      </InputField>
    </div>
  );
};

export default SearchEvents;
