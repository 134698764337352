import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
// actions
import questionsActions from 'redux/actions/questions';
// types
import { FlowType } from 'types/actions/common-types';

const flowType = FlowType.StartGroup;

const StartedBefore: FC = () => {
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(questionsActions.next(flowType));
  };

  return (
    <div className="questions before">
      <div className="questions__title">
        Before getting started in your group, please answer these few questions which will give your therapist a better
        sense of your situation ahead of your first session.
      </div>
      <div className="questions__sub-title">This will take less than 1 minute to complete.</div>
      <button className="btn-form" onClick={onClick}>
        Continue
      </button>
    </div>
  );
};

export default StartedBefore;
