import React, { useEffect, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Modal, Spin } from 'antd';
import Moment from 'react-moment';
// components
import Slider from 'react-slick';
// types
import { stateType } from '../../../../types/types';
// actions
import upcomingAction from '../../../../redux/actions/upcoming_events';
// styles
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './style.scss';
// icons
import noteUnActive from 'assets/image/info/noteDisabled.svg';
import noteActive from 'assets/image/info/noteEnabled.svg';
import BeginsIn from 'components/molecules/beginsIn';
import { useWindowResize } from 'beautiful-react-hooks';
import { ReactComponent as IconCalendar } from '../../../../assets/image/dropdown/calendar.svg';
import moment from 'moment';
import { ReactComponent as CloseIcon } from '../../../../assets/image/account/close4.svg';

const PrevArrow = ({ className, style, onClick, currentSlide, slideCount }: any) => {
  if (currentSlide === 0) return null;
  return <div className={className} style={{ ...style, display: 'block' }} onClick={onClick} />;
};

const NextArrow = ({ className, style, onClick, currentSlide, slideCount, widthDiv, widthBlock }: any) => {
  const allSlide = (widthBlock * slideCount - widthDiv) / widthBlock;
  if (widthDiv / slideCount > widthBlock || currentSlide - 1 === Math.trunc(allSlide)) return null;
  return <div className={className} style={{ ...style, display: 'block' }} onClick={onClick} />;
};

const UpcomingEvents: React.FC = () => {
  const componentRef = useRef<any>();
  const componentRefBlock = useRef<any>();
  const [widthDiv, setWidthDiv] = useState<any>();
  const [widthBlock, setWidthBlock] = useState<any>();
  useEffect(() => {
    setWidthDiv(componentRef.current ? componentRef.current.offsetWidth : 0);
  }, [componentRef.current]);

  useEffect(() => {
    setWidthBlock(componentRefBlock.current ? componentRefBlock.current.offsetWidth : 0);
  }, [componentRefBlock.current]);

  const loader: boolean = useSelector((state: stateType) => state.upcoming_events.loaderEvents, shallowEqual);
  const events: any = useSelector((state: stateType) => state.upcoming_events.events, shallowEqual);
  const [width, setWidth] = useState(window.innerWidth);
  const allParams: any = useSelector((state: stateType) => state.upcoming_events.allParams, shallowEqual);
  const [wasNotEmptyFiler, setWasNotEmptyFiler] = useState(false);
  const sliderRef = useRef<any>();
  const firstRender = useRef<boolean>(true);

  const [selectedEventForRm, setSelectedEventForRm] = useState({
    start: undefined,
    end: undefined,
    leader: { firstname: undefined, lastname: undefined },
    picture: { url: undefined },
    title: undefined,
    id: undefined,
  });
  const dispatch = useDispatch();

  useWindowResize(() => {
    setWidth(window.innerWidth);
  });

  const cleanSelectedEvent = () => {
    setSelectedEventForRm({
      start: undefined,
      end: undefined,
      leader: { firstname: undefined, lastname: undefined },
      picture: { url: undefined },
      title: undefined,
      id: undefined,
    });
  };

  const unregister = () => {
    dispatch(
      upcomingAction.register(selectedEventForRm?.id, true, () => {
        const array = events.map((el: any) => {
          if (el.id === selectedEventForRm?.id) {
            el.registered = false;
          }
          cleanSelectedEvent();
          return el;
        });
        dispatch(upcomingAction.setEvents(array));
      }),
    );
  };

  const settings: any = {
    className: 'slider',
    dots: false,
    draggable: false,
    variableWidth: width > 450,
    infinite: false,
    prevArrow: <PrevArrow className="arrow next-arrow" />,
    nextArrow: <NextArrow className="arrow next-arrow" widthDiv={widthDiv} widthBlock={widthBlock} />,
  };

  useEffect(() => {
    const isEmptyFilters = !allParams.length && !allParams.sort && !allParams.topic && !allParams.type;
    if (
      allParams.length ||
      allParams.sort ||
      allParams.topic ||
      allParams.type ||
      (isEmptyFilters && wasNotEmptyFiler)
    ) {
      setWasNotEmptyFiler(true);
      sliderRef.current?.slickGoTo(0);
      firstRender.current = false;
    }
  }, [allParams]);

  return (
    <div className="upcoming-events-container" ref={componentRef}>
      <Spin spinning={loader}>
        <h3 className="text-page-header--size-15">Upcoming Events</h3>
        <div className="upcoming-events-slider">
          <Slider {...settings} ref={sliderRef}>
            {events?.map((item: any, index: number) => (
              <SliderItem
                widthBlock={widthBlock}
                key={index}
                item={item}
                setSelectedEventForRm={setSelectedEventForRm}
                componentRefBlock={componentRefBlock}
              />
            ))}
          </Slider>
        </div>
      </Spin>
      {selectedEventForRm.start && (
        <Modal
          visible={selectedEventForRm.start}
          closable={true}
          onCancel={cleanSelectedEvent}
          centered
          maskTransitionName="none"
          footer={null}
          className="cansel-group-modal"
          closeIcon={<CloseIcon />}
        >
          <div className="cansel-session-container">
            <div className="cansel-session-container__title">
              Are you sure you wish to unregister from the following event?
            </div>
            <div className="cansel-session-container__main">
              <div className="cansel-session-container__label">
                <IconCalendar style={{ width: '20px', height: '20px' }} />
                <Moment format={'dddd, MMMM Do'}>{selectedEventForRm?.start}</Moment>
              </div>
              <div className="cansel-session-container__group-info">
                <div className="small-group-card">
                  <div className="small-group-card__avatar">
                    <img src={selectedEventForRm?.picture?.url} alt="avatar" />
                  </div>
                  <div className="small-group-card__content">
                    <div className="small-group-card__title">{selectedEventForRm?.title}</div>
                    <div className="small-group-card__info">
                      <div className="small-group-card__when">
                        <span>When:</span> <Moment format={'dddd, MMMM Do'}>{selectedEventForRm?.start}</Moment>
                      </div>
                      <div className="small-group-card__when">
                        <span>Time:</span>
                        {` ${moment(selectedEventForRm?.start).format('h:mm A')} -
                          ${moment(selectedEventForRm?.end).format('h:mm A')} ET`}
                      </div>
                      <div className="small-group-card__when small-group-card__therapist-item">
                        <span>Therapist:</span>&nbsp;
                        <span>{`${selectedEventForRm?.leader?.firstname} ${selectedEventForRm?.leader?.lastname}`}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="cansel-session-container__block">
              <button className="btn-form" onClick={unregister}>
                {`Yes, I'm sure I'd like to Unregister`}
              </button>
              <div
                className="change-group-container__cancel"
                style={{ marginTop: 0, width: '100%' }}
                onClick={cleanSelectedEvent}
              >
                {`No, I'd like to remain Registered and attend`}
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default UpcomingEvents;

const SliderItem = ({ item, setSelectedEventForRm, componentRefBlock, widthBlock }: any) => {
  const [loader, setLoader] = useState(false);
  const {
    bookmark,
    id,
    picture,
    title,
    groupTopics,
    leader,
    subtitle,
    start,
    end,
    registered,
    communityEventType,
    maxAttendees,
  } = item;
  const events: any = useSelector((state: stateType) => state.upcoming_events.events, shallowEqual);
  const dispatch = useDispatch();
  const [isBookMarked, setIsBookMarked] = useState(false);

  const click = (isRegister: boolean) => {
    setLoader(true);
    dispatch(
      upcomingAction.register(id, isRegister, () => {
        const array = events.map((el: any) => {
          if (el.id === item.id) {
            el.registered = !isRegister;
          }
          return el;
        });
        setLoader(false);
        dispatch(upcomingAction.setEvents(array));
      }),
    );
  };

  const unregister = () => {
    events.map((el: any) => {
      if (el.id === item.id) {
        setSelectedEventForRm(el);
      }
      return el;
    });
  };

  useEffect(() => {
    setIsBookMarked(false);
  }, [item]);

  const clickBookMark = (id?: number) => {
    setIsBookMarked(true);
    dispatch(upcomingAction.changeBookMark({ id, events: 'future' }));
  };

  return (
    <div className="slider-item" ref={componentRefBlock}>
      <div className="slider-item__header">
        <img className="slider-item__bg" src={picture?.url} alt="upcoming event image" />
        <div className="slider-item__start-time">
          <Moment format="MMMM Do, YYYY">{start}</Moment> <Moment format="h:mm A">{start}</Moment>
          {' - '}
          <Moment format="h:mm A">{end}</Moment> ET
        </div>
        <img
          className="slider-item__note"
          src={bookmark ? noteActive : noteUnActive}
          alt="make a note"
          onClick={() => (isBookMarked ? {} : clickBookMark(id))}
        />
      </div>
      <div className="slider-item__body">
        <div className="slider-item__title-header">
          <div
            style={{
              backgroundColor: communityEventType?.id === 1 ? '#e2351e' : '#82c43c',
              width: 120,
              padding: '3px 22px',
              marginLeft: -25,
              borderTopRightRadius: 15,
            }}
          >
            <span style={{ color: '#fff' }}> {communityEventType?.name}</span>
          </div>
          <div className="slider-item__label">{`${maxAttendees} Max`}</div>
        </div>
        <div className="slider-item__main" style={{ overflowY: 'scroll', height: 50 }}>
          <div className="slider-item__title" style={{ maxWidth: widthBlock ? widthBlock - 50 : 200 }}>
            {title}
          </div>
          <div style={{ flexDirection: 'row', display: 'flex' }}>
            <div
              className="slider-item__sub-title"
              style={{ paddingRight: 10, maxWidth: widthBlock ? widthBlock - 50 : 200 }}
            >
              {subtitle}
            </div>
          </div>
          {/* <div className="slider-item__lead">Led by {`${leader?.firstname} ${leader?.lastname}`}</div> */}
        </div>
        <div className="slider-item__footer">
          <button
            className="btn-item slider-item__btn"
            style={{ backgroundColor: registered ? '#b5b5be' : '#1891e5' }}
            onClick={registered ? () => unregister() : () => click(registered)}
          >
            {loader ? <Spin spinning={true} /> : <div>{registered ? 'Unregister' : 'Register'}</div>}
          </button>
          <div>
            <BeginsIn start={start} />
          </div>
        </div>
      </div>
    </div>
  );
};
