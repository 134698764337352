import AssessmentProgressQuestions from 'components/organisms/modals/assessmentProgress';
import React, { useEffect } from 'react';
// components

import AssessmentProgressTemplate from '../../../components/templates/assessmentProgressTemplate';

const AssessmentProgressQuestionsPage: React.FC = () => {
  useEffect(() => {
    document.title = 'Quick Assessment';
  }, []);
  return (
    <AssessmentProgressTemplate>
      <AssessmentProgressQuestions />
    </AssessmentProgressTemplate>
  );
};

export default AssessmentProgressQuestionsPage;
