import React, { FC, useEffect } from 'react';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
// components
import SubmitButton from '../../../../atoms/form/Button';
import { CancelButton } from '../../../../atoms/form/CancelButton';
// types
import { stateType } from '../../../../../types/types';
import { EditModalInterface } from '../../../../../types/edit';
// style
import '../style.scss';
// helpers
import { mapping } from '../../../../../api/mapping/request/editTherapist';
// actions
import therapistAction from '../../../../../redux/actions/therapist';
import { useHistory, useParams } from 'react-router-dom';
import groupsAction from '../../../../../redux/actions/groups';

const EditTherapistContainer: FC<EditModalInterface> = ({
  handleCancel,
  titleName,
  npiNumber,
  buttonName,
  children,
  setValue,
  handleSubmit,
  isCancel,
  isPhoto,
  undertitle,
}: EditModalInterface) => {
  const dispatch = useDispatch();

  const params = useParams<{ id: string }>();
  const history = useHistory();

  const isGroup = history.location.pathname.includes('all_groups');

  const {
    id,
    biography,
    practiceYears,
    licenses,
    joinedDate,
    specialties,
    state,
    groupTopics,
    avatar,
    title,
    npi,
  }: any = useSelector((state: stateType) => state.therapists.item);
  const file: any = useSelector((state: stateType) => state.app.file);

  useEffect(() => {
    setValue('biography', biography);
    setValue('practiceYears', practiceYears);
    setValue(
      'stateId',
      state.length > 0 &&
        state.map((item: any) => {
          return item.id;
        }),
    );
    setValue(
      'licenseIds',
      licenses.length > 0 &&
        licenses.map((item: any) => {
          return item.id;
        }),
    );
    setValue(
      'specialtyIds',
      specialties.length > 0 &&
        specialties.map((item: any) => {
          return item.id;
        }),
    );
    setValue(
      'groupTopicIds',
      groupTopics.length > 0 &&
        groupTopics.map((item: any) => {
          return item.id;
        }),
    );
    setValue('joinedDate', joinedDate);
    setValue('avatarUuid', avatar?.uuid);
    setValue('titleId', title?.id || 1);
    setValue('npiNumber', npi);
  }, []);

  useEffect(() => {
    file && setValue('avatarUuid', file.uuid);
  }, [file]);

  const submitCallback = () => {
    handleCancel();

    isGroup && dispatch(groupsAction.id(params.id));
  };

  const onSubmit = (data: FormData) => {
    console.log(data);
    dispatch(therapistAction.edit(id, mapping.editTherapistBuild(data), submitCallback));
  };

  return (
    <div className="edit-details-container">
      <div className="edit-details-container__title edit-details-container__header">
        {titleName}
        <p className="edit-details-container__header-undertitle">{undertitle}</p>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="form-edit validation-form">
        <div
          className={cn('edit-details-container__main', {
            photo: isPhoto,
          })}
        >
          {children}
        </div>
        <div className="edit-details-container__buttons">
          <SubmitButton name={buttonName} className="edit-details-container__submit-btn" />
          {isCancel && <CancelButton name="Cancel" cancel={handleCancel} />}
        </div>
      </form>
    </div>
  );
};

export default EditTherapistContainer;
