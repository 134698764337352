import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
// configs
import { week_days, timeConvertor } from '../../../../../../configs/tables/admin/clients';
import { capitalizeText } from '../../../../../../tools/helpFunctions';
// icons
import Icon from '@ant-design/icons';
import { ReactComponent as IconTime } from '../../../../../../assets/image/group/time.svg';
// types
import { stateType } from '../../../../../../types/types';
// styles
import './style.scss';

const ClientCardMinimal: React.FC = () => {
  const item: any = useSelector((state: stateType) => state.clients.item, shallowEqual);
  const { medicalGroup }: any = useSelector((state: stateType) => state.clients.item, shallowEqual);
  return (
    <div className="client-card-mini">
      <div className="client-card-mini__header">
        <div className="client-card-mini__avatar">
          {item?.avatar?.url ? (
            <img src="#" alt="avatar" className="client-card-mini__avatar-img" />
          ) : (
            <div className="client-card-mini__avatar-fake">
              {item.firstname[0]}
              {item.lastname[0]}
            </div>
          )}
        </div>
        <div className="client-card-mini__content">
          <div className="client-card-mini__title">
            {item.firstname} {item.lastname}
          </div>
          <div className="client-card-mini__status">
            <div className="client-card-mini__label">Status</div>
            <div className={`client-card-mini__status-value client-card-mini__status-${item.customerMarkers.id}`}>
              {capitalizeText(item.customerMarkers.value)}
            </div>
          </div>
        </div>
      </div>
      <div className="client-card-mini__label">Group</div>
      <div className="client-card-mini__block">
        <div className="client-card-mini__avatat-group">
          {medicalGroup?.avatar && (
            <img src={medicalGroup?.avatar?.url} alt="avatar" className="client-card-mini__avatar-img-group" />
          )}
        </div>

        <div className="client-card-mini__body">
          <div className="client-card-mini__name">{`${medicalGroup.groupTopic.name} Group`}</div>
          <div className="card-details__date">
            <div className="card-details__icon">
              <Icon component={IconTime} style={{ fontSize: '14px' }} />
            </div>
            <div className="card-details__text">
              {week_days[medicalGroup.dayOfWeek - 1]}, {timeConvertor(medicalGroup.start)} -{' '}
              {timeConvertor(medicalGroup.end)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientCardMinimal;
