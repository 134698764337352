/*
 *
 * Auth reducer
 *
 */
const initialState = {
  token: '',
  status: null,
  loader: false,
};

export type authInitStateType = typeof initialState;

interface authActionTypes {
  type: string;
  payload: {
    token: string;
    status: boolean | null;
    loader: boolean;
    redirect: string;
  };
}

const auth = (state = initialState, action: authActionTypes): authInitStateType => {
  switch (action.type) {
    case 'AUTH_TOKEN':
      return Object.assign({}, state, {
        token: action.payload.token,
      });
    case 'AUTH_STATUS':
      return Object.assign({}, state, {
        status: action.payload.status,
      });
    case 'AUTH_LOADER':
      return Object.assign({}, state, {
        loader: action.payload.loader,
      });
    default:
      return state;
  }
};

export default auth;
