import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { useHistory } from 'react-router-dom';
// types
import { stateType } from 'types/types';
// icons
import Icon from '@ant-design/icons';
import { ReactComponent as IconClose } from 'assets/image/account/close4.svg';
import changeGroupActions from '../../../../redux/actions/change_group';
import eventAnalytics from '../../../../redux/actions/analytics';

interface CloseChangeBtnType {
  circle?: boolean;
}

const CloseChangeBtn: React.FC<CloseChangeBtnType> = ({ circle }: CloseChangeBtnType) => {
  const dispatch = useDispatch();

  const state: string = useSelector((state: stateType) => state.user.state, shallowEqual);

  const history = useHistory();

  const onClick = () => {
    if (state === 'group_change') {
      dispatch(changeGroupActions.cansel());
    } else {
      history.push('/');
    }
    dispatch(eventAnalytics.analytics('reschedule-group-skip-confirm'));
  };

  return (
    <div className={cn('change-group-container__btn-close', { circle })}>
      <Icon component={IconClose} onClick={onClick} />
    </div>
  );
};

export default CloseChangeBtn;
