import React, { FC } from 'react';
import { Menu } from 'antd';
import { useHistory } from 'react-router-dom';
// types
import { IActionListItem } from 'types/app-types';
// styles
import './style.scss';

interface IMoreMenu {
  list: Array<IActionListItem>;
}

const MoreMenu: FC<IMoreMenu> = ({ list }: IMoreMenu) => {
  const history = useHistory();

  const onRedirect = (link: string | undefined) => link && history.push(link);

  return (
    <Menu className="dropdown-menu">
      {list.map((item, i) => (
        <Menu.Item key={i} onClick={() => item?.onClick?.() || onRedirect(item?.link)}>
          <div className="dropdown-menu__item">
            <img src={item.icon} alt="item icon" />
            <span className="dropdown-menu__text">{item.text}</span>
          </div>
        </Menu.Item>
      ))}
    </Menu>
  );
};

export default MoreMenu;
