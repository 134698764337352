// types
import { exampleValue } from '../../types/app-types';
/*
 *
 * App reducer
 *
 */
const initialState = {
  ready: false,
  error: null,
  value: {} as exampleValue,
  array: [],
  text: {},
  page: '',
  isReschedule: false,
  auth: 'loading',
  account: 'details',
  helpSlieder: [],
  file: '',
  url: '',
  supportStates: [],
};

export type appInitialStateType = typeof initialState;

interface appActionTypes {
  type: string;
  payload: {
    isReschedule: boolean;
    value: exampleValue;
    array: [];
    text: { [key: string]: string };
    page: string;
    auth: string;
    account: string;
    helpSlieder: [];
    file: any;
    url: string;
    supportStates: [];
  };
}

const app = (state = initialState, action: appActionTypes): appInitialStateType => {
  switch (action.type) {
    case 'APP_READY':
      return Object.assign({}, state, {
        value: action.payload.value,
      });
    case 'APP_ARRAY':
      return Object.assign({}, state, {
        array: action.payload.array,
      });
    case 'APP_TEXT':
      return Object.assign({}, state, {
        text: action.payload.text,
      });
    case 'APP_AUTH':
      return Object.assign({}, state, {
        auth: action.payload.auth,
      });
    case 'APP_PAGE':
      return Object.assign({}, state, {
        page: action.payload.page,
      });
    case 'APP_ACCOUNT_TAB':
      return Object.assign({}, state, {
        account: action.payload.account,
      });
    case 'set_re':
      return Object.assign({}, state, {
        isReschedule: action.payload.isReschedule,
      });
    case 'APP_HELP_SLIDER':
      return Object.assign({}, state, {
        helpSlieder: action.payload.helpSlieder,
      });
    case 'APP_FILE':
      return Object.assign({}, state, {
        file: action.payload.file,
      });
    // case 'APP_FILE_CREATE_EVENT':
    //   return Object.assign({}, state, {
    //     file: action.payload.file,
    //   });
    case 'APP_FORUM_URL':
      return Object.assign({}, state, {
        url: action.payload.url,
      });
    case 'APP_SUPPORT_STATES':
      return Object.assign({}, state, {
        supportStates: action.payload.supportStates,
      });
    default:
      return state;
  }
};

export default app;
