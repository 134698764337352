import React from 'react';
import EyeActiveImage from '../../../assets/image/forms/eye-icon.svg';
import EyeInActiveImage from '../../../assets/image/forms/eye-icon-closed.svg';

type PasswordEyeProps = {
  active: boolean;
  onClick: () => void;
};

// import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';

type PasswordEyeIcon = ({ active, onClick }: PasswordEyeProps) => ({}) => React.ReactElement;

export const PasswordEyeIcon: PasswordEyeIcon = ({ active, ...props }: PasswordEyeProps) => {
  const d = () => <img src={active ? EyeInActiveImage : EyeActiveImage} {...props} />;
  return d;
};
