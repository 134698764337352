import React, { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
// strips
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
// actions
import stripeAction from '../../../../redux/actions/stripe';
import stripeCullMethods from '../../../../api/stripe/methods';
// helps
import { APP } from '../../../../configs/app';
// styles
import './style.scss';
// types
import { stateType } from '../../../../types/types';

const nameSchema = yup.string().matches(/^([a-zA-Z'`-]{2,20}\s?){1,5}$/, 'Input a correct card name');

const UpdateForm: React.FC<any> = ({ setVisible }: any) => {
  const profile = useSelector((state: stateType) => state.user.profile, shallowEqual);
  const stripePromise = loadStripe(profile.testUser ? APP.STRIPE.TEST_TOKEN : APP.STRIPE.TOKEN);

  return (
    <div className="subscription-container subscription-container__update">
      <Elements stripe={stripePromise} options={{ locale: 'en' }}>
        <CheckoutForm setVisible={setVisible} />
      </Elements>
    </div>
  );
};

export default UpdateForm;

const CheckoutForm = ({ setVisible }: any) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState<any>(null);
  const [cardName, setCardName] = useState({ text: '', error: '' });
  const [cardComplete, setCardComplete] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(null);

  const dispatch = useDispatch();

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    if (!cardName.text) {
      setCardName((state) => ({ ...state, error: 'Card name is required field' }));
    }

    if (error) {
      return;
    }

    if (cardComplete) {
      setProcessing(true);
    }

    dispatch(stripeAction.loader(true));

    // Create payment method and confirm payment intent.
    stripeCullMethods.createPaymentMethod(
      stripe,
      elements,
      setPaymentMethod,
      setProcessing,
      setError,
      setVisible,
      dispatch,
      cardName.text,
    );
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setCardName((state) => ({ ...state, text: value }));
  };

  const onBlurInput = async () => {
    const isValid = await nameSchema.isValid(cardName.text);
    if (!isValid) {
      setCardName((state) => ({
        ...state,
        error: cardName.text ? 'Input a correct card name' : 'Card name is required field',
      }));
    }
  };

  const reset = () => {
    setError(null);
    setProcessing(false);
    setPaymentMethod(null);
    setVisible(false);
    setCardName({ text: '', error: '' });
  };

  return paymentMethod ? (
    <div className="Result">
      <div className="card-form__main">
        <div className="ResultTitle card-form__desc" role="alert">
          Payment successful
        </div>
        <div className="ResultMessage card-form__desc">
          Thanks for trying Stripe Elements. No money was charged, but we generated a PaymentMethod:{' '}
        </div>
      </div>
      <div className="card-form__buttons">
        <ResetButton onClick={reset} />
      </div>
    </div>
  ) : (
    <form className="card-form" onSubmit={handleSubmit}>
      <div className="card-form__main">
        <div style={{ position: 'relative' }}>
          <label htmlFor="name" className="input-field__label">
            Name on card
          </label>
          <input
            className="card-form__input card-form__input_edit"
            name="name"
            placeholder="Enter cardholder name"
            onFocus={() => setCardName((state) => ({ ...state, error: '' }))}
            value={cardName.text}
            onChange={onInputChange}
            onBlur={onBlurInput}
            autoComplete="on"
            id="name"
          />
          <div className="card-form__error card-form__error_edit">{cardName.error}</div>
        </div>
        <CardField
          onChange={(e: any) => {
            setError(e.error);
            setCardComplete(e.complete);
          }}
        />
        <div className="card-form__error card-form__error_edit">{error?.message}</div>
      </div>
      <div className="card-form__buttons">
        <SubmitButton processing={processing} error={error} disabled={!stripe}>
          Save
        </SubmitButton>
        <ResetButton onClick={reset} />
      </div>
    </form>
  );
};

const CARD_OPTIONS = {
  hidePostalCode: true,
  style: {
    base: {
      iconColor: '#92929d',
      color: '#92929d',
      fontWeight: 400,
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      borderColor: '#92929d',
      backgroundColor: '#fff',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '#92929d',
      },
      '::placeholder': {
        color: '#92929d',
      },
    },
    invalid: {
      iconColor: '#ffc7ee',
      color: '#92929d',
    },
  },
};

const CardField = ({ onChange }: any) => (
  <div className="card-element">
    <label htmlFor="card" className="input-field__label">
      Credit card number
    </label>
    <CardElement options={CARD_OPTIONS} onChange={onChange} id="card" />
  </div>
);

const SubmitButton = ({ processing, children, disabled, style }: any) => (
  <button className={`btn-form`} type="submit" disabled={processing || disabled} style={style}>
    {processing ? 'Processing...' : children}
  </button>
);

const ResetButton = ({ onClick }: any) => (
  <button type="button" className="term-list__btn btn-light" onClick={onClick}>
    Cancel
  </button>
);
