/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { AxiosResponse } from 'axios';
// config
import http from '../instance';

export const optionsApi = {
  get_reasons: async (): Promise<AxiosResponse> => {
    const api = await http();
    return api.get(`customer/reasons`);
  },

  get_topics: async (role: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.get(`customer/admin/group-topics`);
  },

  get_topics_extended: async (): Promise<AxiosResponse> => {
    const api = await http();
    return api.get(`customer/group-topics-extended `);
  },

  get_statuses: async (): Promise<AxiosResponse> => {
    const api = await http();
    return api.get(`relationship-statuses`);
  },

  get_genders: async (): Promise<AxiosResponse> => {
    const api = await http();
    return api.get(`genders`);
  },

  get_state: async (): Promise<AxiosResponse> => {
    const api = await http();
    return api.get(`states`);
  },

  get_countries: async (): Promise<AxiosResponse> => {
    const api = await http();
    return api.get(`countries`);
  },

  get_specialties: async (): Promise<AxiosResponse> => {
    const api = await http();
    return api.get(`specialties`);
  },

  get_zoom_clients: async (): Promise<AxiosResponse> => {
    const api = await http();
    return api.get(`admin/zoom/clients?scope=free`);
  },

  get_licenses: async (): Promise<AxiosResponse> => {
    const api = await http();
    return api.get(`licenses`);
  },

  get_specific_groups: async (role: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.get(`${role}/groups?restart_subscription=true`);
  },

  get_customer_statuses: async (): Promise<AxiosResponse> => {
    const api = await http();
    return api.get(`customer-statuses`);
  },

  get_therapist_statuses: async (): Promise<AxiosResponse> => {
    const api = await http();
    return api.get(`admin/therapist-statuses`);
  },

  get_group_statuses: async (role: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.get(`${role ? `${role}/` : ''}group-statuses`);
  },

  get_numbers_of_members: async (role: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.get(`${role}/number-of-group-members-filters`);
  },

  get_titles_ids: async (): Promise<AxiosResponse> => {
    const api = await http();
    return api.get(`titles`);
  },
};
