import React, { FC } from 'react';
import { Checkbox } from 'antd';

export interface OptionType {
  key: number;
  value: number;
  label: string;
}

interface SelectOptionProps {
  option: OptionType;
  selectedValues: Array<number> | undefined;
}

const SelectOption: FC<SelectOptionProps> = ({ option, selectedValues }: SelectOptionProps) => {
  const isChecked = selectedValues?.some((value) => value === option.value);

  return (
    <div className="form-select__option">
      <Checkbox checked={isChecked} />
      <div className="form-select__label">{option.label}</div>
    </div>
  );
};

export default SelectOption;
