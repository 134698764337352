import store from '../store';
import { mapping } from '../../api/mapping/response/tables';
// actions
import { ActionCallback, FilterOptionsRole } from '../../types/actions/common-types';
import {
  AddGroupClientAction,
  ArchiveGroupAction,
  CancelGroupAction,
  EditGroupAction,
  GetGroupAction,
  GetGroupsAction,
  GetGroupsTherapists,
  GetGroupsTopics,
  GroupCreatedAction,
  GroupsAction,
  GroupsLoaderAction,
  GroupsLoaderModalAction,
  GroupTherapistsAction,
  GroupTopicsAction,
  RemoveClientAction,
  RescheduleGroupAction,
  SearchClientsAction,
  SetGroupsAction,
  SetGroupsDataType,
  GetFilterOptionsActions,
  RestartGroupAction,
  setOptionsIsReceivedActions,
} from '../../types/actions/groups';

const groupsAction: GroupsAction = {
  id(id?: any, callback?: ActionCallback): GetGroupAction {
    return {
      type: 'GET_GROUP',
      id,
      callback,
    };
  },
  get(data?: any, callback?: ActionCallback): GetGroupsAction {
    return {
      type: 'GET_GROUPS',
      data,
      callback,
    };
  },

  set(data: SetGroupsDataType): SetGroupsAction {
    return {
      type: 'GROUPS',
      payload: {
        items: data.items,
        count: data.totalCount,
      },
    };
  },

  search(value?: any, callback?: ActionCallback): SearchClientsAction {
    return {
      type: 'GROUP_CLIENTS_SEARCH',
      value,
      callback,
    };
  },

  searchResult(data: any, doMap?: boolean): void {
    store.dispatch({
      type: 'GROUP_SEARCH_RESULT',
      payload: {
        searchResult: doMap === false ? data : mapping.membersResultBuild(data.items),
      },
    });
  },

  getTopics(data?: any, callback?: ActionCallback): GetGroupsTopics {
    return {
      type: 'GET_GROUPS_TOPICS',
      data,
      callback,
    };
  },

  getTherapists(data?: any, callback?: ActionCallback): GetGroupsTherapists {
    return {
      type: 'GET_GROUPS_THERAPISTS',
      data,
      callback,
    };
  },
  loader(status: boolean): GroupsLoaderAction {
    return {
      type: 'GROUPS_LOADER',
      payload: {
        loader: status,
      },
    };
  },

  loaderModal(status: boolean): GroupsLoaderModalAction {
    return {
      type: 'GROUPS_LOADER_MODAL',
      payload: {
        loaderModal: status,
      },
    };
  },
  create(data: any, role: string): void {
    store.dispatch({
      type: 'GROUPS',
      payload: {
        items: mapping.groupsBuild(data.items, role),
        count: data.totalCount,
      },
    });
  },

  self(data: any, role: string): void {
    store.dispatch({
      type: 'GROUP',
      payload: {
        item: mapping.groupBuild(data.item, role),
      },
    });
  },

  clear(): void {
    store.dispatch({
      type: 'GROUPS',
      payload: {
        items: [],
        count: 0,
      },
    });
    store.dispatch({
      type: 'GROUPS_PARAMS',
      payload: {
        params: {
          page: 1,
          per_page: 10,
          name: '',
        },
      },
    });
  },

  clearItem(): void {
    store.dispatch({
      type: 'GROUP',
      payload: {
        item: {},
      },
    });
  },

  params(params: any): void {
    store.dispatch({
      type: 'GROUPS_PARAMS',
      payload: {
        params: params,
      },
    });
  },

  rowIndex: (rowIndex?: number): void => {
    store.dispatch({
      type: 'GROUPS_ROW_INDEX',
      payload: {
        rowIndex,
      },
    });
  },

  addClient(data?: any, callback?: ActionCallback): AddGroupClientAction {
    return {
      type: 'GROUP_ADD_CLIENT',
      data,
      callback,
    };
  },

  removeClient(data?: any, callback?: ActionCallback): RemoveClientAction {
    return {
      type: 'GROUP_REMOVE_CLIENT',
      data,
      callback,
    };
  },

  reschedule(data?: any, id?: any, callback?: ActionCallback): RescheduleGroupAction {
    return {
      type: 'GROUP_RESCHEDULE',
      data,
      id,
      callback,
    };
  },

  edit(id?: any, data?: any, callback?: ActionCallback): EditGroupAction {
    return {
      type: 'GROUP_EDIT',
      id,
      data,
      callback,
    };
  },

  archive(id?: any, callback?: ActionCallback): ArchiveGroupAction {
    return {
      type: 'GROUP_ARCHIVE',
      id,
      callback,
    };
  },

  cancel(id?: any, callback?: ActionCallback): CancelGroupAction {
    return {
      type: 'GROUP_CANCEL',
      id,
      callback,
    };
  },

  restart(id?: any, callback?: ActionCallback): RestartGroupAction {
    return {
      type: 'GROUP_RESTART',
      id,
      callback,
    };
  },

  topics: (topics: any): GroupTopicsAction => {
    return {
      type: 'GROUP_FORM_TOPICS',
      payload: {
        topics: topics,
      },
    };
  },

  therapists: (therapists: any): GroupTherapistsAction => {
    return {
      type: 'GROUP_FORM_THERAPISTS',
      payload: {
        therapists: therapists,
      },
    };
  },

  created(data?: any, callback?: (id: any) => void): GroupCreatedAction {
    return {
      type: 'GROUP_CREATED',
      data,
      callback,
    };
  },

  setOrder: (order: any): void => {
    store.dispatch({
      type: 'GROUPS_ORDER',
      payload: {
        order: order,
      },
    });
  },

  getFilterOptions(callback?: ActionCallback): GetFilterOptionsActions {
    return {
      type: 'GET_GROUPS_FILTER_OPTIONS',
      callback,
    };
  },

  setOptionsIsReceived(optionsIsReceived: FilterOptionsRole): setOptionsIsReceivedActions {
    return {
      type: 'SET_GROUPS_FILTER_OPTIONS',
      payload: {
        optionsIsReceived,
      },
    };
  },
};

export default groupsAction;
