import React, { FC } from 'react';
// components
import FirstConsultation from '../../../components/organisms/consultations/firstConsultation';

const FirstConsultationsPage: FC = () => {
  return (
    <>
      <FirstConsultation />
    </>
  );
};

export default FirstConsultationsPage;
