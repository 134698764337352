import React from 'react';
import { Participant, Track } from 'twilio-video';
import Publication from '../Publication/Publication';
import usePublications from '../../../hooks/usePublications/usePublications';

interface HiddenParticipantProps {
  participant: Participant;
}
export default function HiddenParticipant({ participant }: HiddenParticipantProps) {
  const publications = usePublications(participant);

  const filteredPublications = publications.filter((p) => p.kind == 'data');

  return (
    <>
      {filteredPublications.map((publication) => (
        <Publication
          key={publication.kind}
          publication={publication}
          participant={participant}
          isLocalParticipant={false}
          videoOnly={false}
          videoPriority={null}
        />
      ))}
    </>
  );
}
