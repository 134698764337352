import React from 'react';
import Icon from '@ant-design/icons';
import cn from 'classnames';
// icons
import { ReactComponent as IconEdit } from '../../../../assets/image/account/edit-blue.svg';
// style
import './style.scss';
// components
import EditGroupTagsModal from '../../modals/edit/admin/group/groupTags';

const GroupMeaning: React.FC<any> = ({ group, isEdit }: any) => {
  return (
    <div className="card-group__tags-container">
      <div className={cn('card-group__label', { 'card-group__edit': isEdit })}>
        {isEdit || group?.tags?.length ? <span>What your group will give you</span> : null}
        {isEdit && (
          <EditGroupTagsModal
            btnClass={'group-details-card__edit-btn'}
            icon={<Icon component={IconEdit} />}
            name={'Edit'}
          />
        )}
      </div>
      {group?.tags && (
        <div className="card-group__tags">
          {group.tags.map((name: string, index: number) => {
            return (
              <div key={index} className="card-group__tag-item">
                {name}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default GroupMeaning;
