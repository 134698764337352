import React, { FC, useEffect, useState } from 'react';
import { Modal, Spin } from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
// types
import { stateType } from '../../../../types/types';
// icons
import { ReactComponent as CloseIcon } from '../../../../assets/image/account/close4.svg';
import ImgAfter from '../../../../assets/image/questions/after.svg';
// components
import { TherapistSmBox } from '../adminChangeGroup';
// actions
import therapistAction from '../../../../redux/actions/therapist';
// style
import './style.scss';

const ForgotPasswordButton: FC = () => {
  const [visible, setVisible] = useState(false);
  const [success, setSuccess] = useState(false);

  const loader = useSelector((state: stateType) => state.therapists.loaderModal, shallowEqual);
  const item = useSelector((state: stateType) => state.therapists.item, shallowEqual);

  const open = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onSuccess = () => {
    setSuccess(true);
  };

  return (
    <>
      <button onClick={open} className="account-edit-block__edit-btn">
        Forgot password
      </button>
      {visible && (
        <Modal
          closeIcon={<CloseIcon />}
          visible={visible}
          onOk={handleOk}
          closable={true}
          onCancel={handleCancel}
          centered
          maskTransitionName="none"
          footer={null}
          className="forgot-password-modal"
        >
          {!success ? (
            <Spin spinning={loader}>
              <ModalContainer onSuccess={onSuccess} item={item} />
            </Spin>
          ) : (
            <ModalSuccessContainer handleCancel={handleCancel} item={item} setSuccess={setSuccess} />
          )}
        </Modal>
      )}
    </>
  );
};

export default ForgotPasswordButton;

const ModalContainer = ({ onSuccess, item }: any) => {
  const dispatch = useDispatch();

  const action = () => {
    dispatch(
      therapistAction.resetPassword(
        item.id,
        { setPasswordUrl: `${window.location.origin}/therapist-password/` },
        onSuccess,
      ),
    );
  };

  return (
    <div className="forgot-password-container">
      <div className="forgot-password-container__title">
        Are you sure you want to send a forgot password form to the following therapist?
      </div>
      <div className="forgot-password-container__group-info">
        <TherapistSmBox item={item} />
      </div>
      <div className="forgot-password-container__block">
        <button className="btn-form" onClick={action}>
          Forgot password
        </button>
      </div>
    </div>
  );
};

const ModalSuccessContainer = ({ handleCancel, item, setSuccess }: any) => {
  useEffect(() => {
    return () => {
      setSuccess(false);
    };
  }, []);

  return (
    <div className="forgot-password-container">
      <div className="forgot-password-container__title">
        <img className="forgot-password-container__finish-ico" src={ImgAfter} alt="Finish icon" />
        <div>You’ve successfully sent forgot password form to the therapist!</div>
      </div>
      <div className="forgot-password-container__group-info">
        <TherapistSmBox item={item} />
      </div>
      <div className="forgot-password-container__block">
        <button className="btn-form" onClick={handleCancel}>
          Done
        </button>
      </div>
    </div>
  );
};
