/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { ActionCallback } from '../../types/actions/common-types';
import AssesmentsAction, {
  GetAssesmentsAction,
  SetAssesmentsAction,
  GetByIDAssesmentsAction,
  SetByIDAssesmentsAction,
  LoaderAction,
  LoaderModalAction,
} from '../../types/actions/assesments-actions';

const assesmentsAction: AssesmentsAction = {
  get(id?: number, callback?: () => ActionCallback): GetAssesmentsAction {
    return {
      type: 'GET_ASSESMENTS',
      id,
      callback,
    };
  },
  set(items: []): SetAssesmentsAction {
    return {
      type: 'ASSESMENTS',
      payload: {
        items: items,
      },
    };
  },
  getByID(id?: number, callback?: () => ActionCallback): GetByIDAssesmentsAction {
    return {
      type: 'GET_ASSESMENT',
      id,
      callback,
    };
  },
  setByID(item: any): SetByIDAssesmentsAction {
    return {
      type: 'ASSESMENT',
      payload: {
        item: item,
      },
    };
  },
  loader(status: boolean): LoaderAction {
    return {
      type: 'ASSESMENTS_LOADER',
      payload: {
        loader: status,
      },
    };
  },
  loaderModal(status: boolean): LoaderModalAction {
    return {
      type: 'ASSESMENTS_LOADER_MODAL',
      payload: {
        loaderModal: status,
      },
    };
  },
};

export default assesmentsAction;
