import React, { FC } from 'react';
import { Tag } from 'antd';
//types
import { OptionType } from '../../molecules/inputsBlocks/select/SelectOption';
import { DefaultValueType } from 'rc-select/lib/interface/generator';
// styles
import './style.scss';

interface SelectTypeProps {
  options: Array<OptionType>;
  label: React.ReactNode;
  value: DefaultValueType;
  disabled: boolean;
  onClose: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  closable: boolean;
}

export const SelectTag: FC<SelectTypeProps> = ({ options, ...props }: SelectTypeProps) => {
  const { value, onClose } = props;
  const option = options.find((option: any) => option.value === value);
  const lightCloseIcon =
    "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10.486' height='10.492' viewBox='0 0 10.486 10.492'%3E%3Cpath id='Shape' d='M9.206,10.271,5.247,6.31,1.28,10.271A.75.75,0,1,1,.22,9.21L4.187,5.249.221,1.28A.75.75,0,0,1,1.283.22L5.249,4.189,9.206.238A.75.75,0,0,1,10.265,1.3L6.309,5.25l3.958,3.962a.75.75,0,1,1-1.061,1.06Z' fill='%231891e5'/%3E%3C/svg%3E";
  const darkCloseIcon =
    "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10.486' height='10.492' viewBox='0 0 10.486 10.492'%3E%3Cpath id='Shape' d='M9.206,10.271,5.247,6.31,1.28,10.271A.75.75,0,1,1,.22,9.21L4.187,5.249.221,1.28A.75.75,0,0,1,1.283.22L5.249,4.189,9.206.238A.75.75,0,0,1,10.265,1.3L6.309,5.25l3.958,3.962a.75.75,0,1,1-1.061,1.06Z' fill='%233D5581'/%3E%3C/svg%3E";
  const onMouseDown = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <Tag onMouseDown={onMouseDown}>
      <div className="select-tag">
        <div className="select-tag__label">{option?.label}</div>
        <img className="lightClose" src={lightCloseIcon} alt="close" onClick={onClose} />
        <img className="darkClose" src={darkCloseIcon} alt="close" onClick={onClose} />
      </div>
    </Tag>
  );
};
