import React, { useEffect, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Modal, Spin } from 'antd';
import ReactPlayer from 'react-player';
import { useWindowResize } from 'beautiful-react-hooks';
// types
import { stateType } from '../../../../types/types';
// images
import imgPlay from '../../../../assets/image/slider/playBtn.svg';
import iconNextArr from 'assets/image/slider/nextArr.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/image/account/close4.svg';
import noteUnActive from '../../../../assets/image/info/noteDisabled.svg';
// styles
import './style.scss';
// actions
import upcomingAction from '../../../../redux/actions/upcoming_events';
import noteActive from '../../../../assets/image/info/noteEnabled.svg';
import Slider from 'react-slick';

const PrevArrow = ({ className, style, onClick, currentSlide, slideCount }: any) => {
  if (currentSlide === 0) return null;
  return <div className={className} style={{ ...style, display: 'block' }} onClick={onClick} />;
};

const NextArrow = ({ className, style, onClick, currentSlide, slideCount, widthDiv, widthBlock }: any) => {
  const allSlide = (widthBlock * slideCount - widthDiv) / widthBlock;
  if (widthDiv / slideCount > widthBlock || currentSlide - 1 === Math.trunc(allSlide)) return null;
  return <div className={className} style={{ ...style, display: 'block' }} onClick={onClick} />;
};

const PastEvents: React.FC = () => {
  const loaderEvents: boolean = useSelector((state: stateType) => state.upcoming_events.loaderEvents, shallowEqual);
  const loaderPasts: boolean = useSelector((state: stateType) => state.upcoming_events.loaderPosts, shallowEqual);
  const params: any = useSelector((state: stateType) => state.upcoming_events.params, shallowEqual);
  const events: any = useSelector((state: stateType) => state.upcoming_events.posts, shallowEqual);
  const count: any = useSelector((state: stateType) => state.upcoming_events.count, shallowEqual);
  const [width, setWidth] = useState(window.innerWidth);
  const [wasNotEmptyFiler, setWasNotEmptyFiler] = useState(false);
  const allParams: any = useSelector((state: stateType) => state.upcoming_events.allParams, shallowEqual);
  const [page, setPage] = useState(2);
  const [slickPage, setSlickPage] = useState(0);
  const componentRef = useRef<any>();
  const componentRefBlock = useRef<any>();
  const [widthDiv, setWidthDiv] = useState<any>();
  const [widthBlock, setWidthBlock] = useState<any>();
  useEffect(() => {
    setWidthDiv(componentRef.current ? componentRef.current.offsetWidth : 0);
  }, [componentRef.current]);

  useEffect(() => {
    setWidthBlock(componentRefBlock.current ? componentRefBlock.current.offsetWidth : 0);
  }, [componentRefBlock.current]);

  const sliderRef = useRef<any>();
  const firstRender = useRef<boolean>(true);

  const dispatch = useDispatch();
  const isIncrement = (count - 4) / 4 > page - 1;

  useWindowResize(() => {
    setWidth(window.innerWidth);
  });

  useEffect(() => {
    if (page > 2) {
      dispatch(upcomingAction.getPastPosts());
    }
  }, [page]);

  useEffect(() => {
    !firstRender.current && slickPage && sliderRef.current?.slickGoTo(slickPage);
    firstRender.current = false;
  }, [slickPage]);

  useEffect(() => {
    const isEmptyFilters = !allParams.length && !allParams.sort && !allParams.topic && !allParams.type;
    if (
      allParams.length ||
      allParams.sort ||
      allParams.topic ||
      allParams.type ||
      (isEmptyFilters && wasNotEmptyFiler)
    ) {
      setWasNotEmptyFiler(true);
      sliderRef.current?.slickGoTo(0);
      firstRender.current = false;
    }
  }, [allParams]);

  useEffect(() => {
    if (params.page === 1) {
      setPage(2);
    }
  }, [params.page]);

  const settings: any = {
    className: 'slider',
    dots: false,
    draggable: false,
    infinite: false,
    slidesToShow: events?.length < 3 ? 3 : width > 1500 ? 3 : width < 900 ? 1 : 2,
    slidesToScroll: 1,
    rows: 2,
    variableWidth: width > 480,
    prevArrow: <PrevArrow className="arrow next-arrow" />,
    nextArrow: <NextArrow className="arrow next-arrow" widthDiv={widthDiv} widthBlock={widthBlock} />,
    beforeChange: (i: number) => {
      if (isIncrement) {
        if (width > 900 || (i > 0 && i % 4 === 0)) {
          setSlickPage(i);
          setPage(page + 1);
        }
        if (width > 1500 || (i > 0 && i % 6 === 0)) {
          setSlickPage(i);
          setPage(page + 1);
        }
      }
    },
  };

  return (
    <PastEventContainer loader={loaderPasts && loaderEvents} page={page} events={events}>
      <Spin spinning={loaderPasts}>
        <div className="past-events" ref={componentRef}>
          <Slider {...settings} ref={sliderRef}>
            {events?.map((item: any) => (
              <PastEventItem key={item.id} item={item} componentRefBlock={componentRefBlock} />
            ))}
            {/*{events?.map((item: any, index: number) => {*/}
            {/*  if (index < 1) {*/}
            {/*    return <PastEventItem key={item.id} item={item} componentRefBlock={componentRefBlock} />;*/}
            {/*  }*/}
            {/*})}*/}
          </Slider>
        </div>
      </Spin>
    </PastEventContainer>
  );
};

export default PastEvents;

const PastEventItem = ({ item, componentRefBlock }: any) => {
  const { title, picture, playUrl, bookmark, id } = item;
  const [visible, setVisible] = useState(false);
  const [isBookMarked, setIsBookMarked] = useState(false);
  const dispatch = useDispatch();
  const onPlayVideo = () => {
    playUrl && window.open(playUrl, '_blank');
  };

  const clickBookMark = (id?: number) => {
    setIsBookMarked(true);
    dispatch(upcomingAction.changeBookMark({ id, events: 'past' }));
  };

  useEffect(() => {
    setIsBookMarked(false);
  }, [item]);

  return (
    <div className="slider-item" ref={componentRefBlock}>
      <img
        className="slider-item__note"
        src={bookmark ? noteActive : noteUnActive}
        alt="make a note"
        onClick={() => (isBookMarked ? {} : clickBookMark(id))}
      />
      <div className="slider-item__bg">
        <img src={picture.url} alt="" />
      </div>
      <div className="slider-item__body">
        <div className="slider-item__button" onClick={onPlayVideo}>
          {playUrl && <img src={imgPlay} alt="imgPlay" />}
        </div>
        <div className="slider-item__title" style={{ cursor: 'pointer' }} onClick={onPlayVideo}>
          {title}
        </div>
        <div className="slider-item__link">
          <div className="slider-item__text" onClick={onPlayVideo}>
            What you’ll get out of it
          </div>
          <img src={iconNextArr} alt="show more arrow" onClick={onPlayVideo} />
        </div>
      </div>
      {visible ? (
        <Modal
          visible={true}
          onCancel={() => setVisible(false)}
          centered
          footer={null}
          closeIcon={<CloseIcon />}
          className="past-events__video"
        >
          <ReactPlayer url={playUrl} autoPlay controls playing={visible} />
        </Modal>
      ) : null}
    </div>
  );
};

const PastEventContainer = ({ children, loader, page, events }: any) => {
  return (
    <div className="past-events-container">
      {(!loader || page > 2) && (
        <>
          {events.length > 0 && <h3 className="text-page-header--size-15">Past Events</h3>}
          {children}
        </>
      )}
    </div>
  );
};
