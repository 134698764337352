/*
 *
 * Clients reducer
 *
 */
const initialState = {
  items: [],
  item: {},
  count: 0,
  missingClients: [],
  missingClientCount: 0,
  loader: false,
  loaderModal: false,
  loaderReminders: false,
  order: {},
  optionsIsReceived: '',
  rowIndex: undefined,
  params: {
    page: 1,
    per_page: 10,
    name: '',
  },
};

export type clientsInitStateType = typeof initialState;

interface clientsActionTypes {
  type: string;
  payload: {
    items: [];
    item: any;
    count: number;
    missingClients: [];
    missingClientCount: number;
    loader: boolean;
    loaderModal: boolean;
    loaderReminders: boolean;
    order: any;
    optionsIsReceived: string;
    rowIndex?: number;
    params: {
      page: number;
      per_page: number;
      name: string;
    };
  };
}

const clients = (state = initialState, action: clientsActionTypes): clientsInitStateType => {
  switch (action.type) {
    case 'CLIENTS':
      return Object.assign({}, state, {
        items: action.payload.items,
        count: action.payload.count,
      });
    case 'MISSING_ICD_CLIENTS':
      return Object.assign({}, state, {
        missingClients: action.payload.missingClients,
        missingClientCount: action.payload.missingClientCount,
      });
    case 'CLIENT':
      return Object.assign({}, state, {
        item: action.payload.item,
      });
    case 'CLIENTS_LOADER':
      return Object.assign({}, state, {
        loader: action.payload.loader,
      });
    case 'CLIENTS_LOADER_MODAL':
      return Object.assign({}, state, {
        loaderModal: action.payload.loaderModal,
      });
    case 'CLIENTS_LOADER_REMINDERS':
      return Object.assign({}, state, {
        loaderReminders: action.payload.loaderReminders,
      });
    case 'CLIENTS_PARAMS':
      return Object.assign({}, state, {
        params: action.payload.params,
      });
    case 'CLIENTS_ROW_INDEX':
      return Object.assign({}, state, {
        rowIndex: action.payload.rowIndex,
      });
    case 'CLIENTS_ORDER':
      return Object.assign({}, state, {
        order: action.payload.order,
      });
    case 'SET_CLIENTS_FILTER_OPTIONS':
      return Object.assign({}, state, {
        optionsIsReceived: action.payload.optionsIsReceived,
      });

    default:
      return state;
  }
};

export default clients;
