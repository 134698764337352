import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Spin } from 'antd';
import cn from 'classnames';
// components
import { AddList } from './add';
// actions
import progressAction from '../../../redux/actions/progress';
// types
import { stateType } from 'types/types';
import { LongTermItemType } from 'types/actions/progress';
// icons
import goalsIcon from '../../../assets/image/progress/goals.svg';
import pointIcon from '../../../assets/image/progress/point.png';
import closeIcon from 'assets/image/account/close4.svg';
import backIcon from 'assets/image/questions/back.svg';
import editIcon from 'assets/image/common/edit.svg';
// styles
import './style.scss';
// configs
import { useIsMounted } from '../../../configs/hooks/useIsMounted';
import eventAnalytics from '../../../redux/actions/analytics';

const ProgressTerm: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { add } = useParams<{ add?: string }>();

  useEffect(() => {
    dispatch(progressAction.get_term());
  }, []);

  const loader: boolean = useSelector((state: stateType) => state.progress.loaderModal, shallowEqual);
  const term: LongTermItemType[] = useSelector((state: stateType) => state.progress.term, shallowEqual);

  const isMounted = useIsMounted();

  const onClickPlusGoal = () => {
    history.push('/progress/term/add');
    dispatch(eventAnalytics.analytics('add-goal-second'));
  };

  const renderComponent = () => {
    if (loader) {
      return;
    }
    if (add) {
      return (
        <div className="progress-term-box__body">
          <img src={goalsIcon} alt="img Point" className="progress-term-box__img" />
          <div className="progress-term-box__title">Long-term goals</div>
          <div className="progress-term-box__info">
            Add your long term goals, and continually refer back to them to see what small steps you are taking to reach
            them.
          </div>
          <AddList list={term} loader={loader} />
        </div>
      );
    } else {
      return (
        <>
          <div className="progress-term-box__body">
            <img src={term.length > 0 ? goalsIcon : pointIcon} alt="img Point" className="progress-term-box__img" />
            <div className="progress-term-box__title">
              {term.length === 0 ? 'You don’t have any goals yet' : 'Long-term goals'}
            </div>
            <div className="progress-term-box__info">
              Add your long term goals, and continually refer back to them to see what small steps you are taking to
              reach them.
            </div>
            {term.length > 0 && <TermList list={term} />}
          </div>
          {term.length === 0 && (
            <div className="progress-term-box__btn-add" onClick={onClickPlusGoal}>
              +
            </div>
          )}
        </>
      );
    }
  };

  return (
    <Spin spinning={loader}>
      <div className="progress-term-box">
        <div className="progress-term-box__header">
          <button onClick={() => history.goBack()}>
            <img src={backIcon} alt="back icon" />
          </button>
          <div className="progress-term-box__top-title">Long-term goals</div>
          <button onClick={() => history.push('/progress')}>
            <img src={closeIcon} alt="close" />
          </button>
        </div>
        {!loader && isMounted() && renderComponent()}
      </div>
    </Spin>
  );
};

export default ProgressTerm;

interface TermListType {
  list: LongTermItemType[];
}

const TermList: React.FC<TermListType> = ({ list }: TermListType) => {
  const dispatch = useDispatch();

  const onClickPlusGoal = () => {
    history.push('/progress/term/add');
    dispatch(eventAnalytics.analytics('add-goal-second'));
  };
  const history = useHistory();
  return (
    <div className="term-ul">
      <div className="term-ul__title">My long-term goals</div>
      <ol>
        {list?.map((item: LongTermItemType) => (
          <li className={cn('term-ul__item', { done: item.completed })} key={item.id}>
            {`${item.goal}`}
          </li>
        ))}
      </ol>

      <div className="term-ul__footer">
        <div className="term-ul__btn-edit" onClick={onClickPlusGoal}>
          <img src={editIcon} alt="edit list" />
        </div>
      </div>
    </div>
  );
};
