import React, { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Checkbox, Radio, Select } from 'antd';
// types
import { stateType } from 'types/types';
// helps
import { week_days } from '../../../../configs/tables/admin/clients';
import { sortActiveGroup } from '../../../../tools/consts';
// styles
import './style.scss';
// icons
import Icon, { CaretDownOutlined } from '@ant-design/icons';
import { ReactComponent as IconPointer } from '../../../../assets/image/forms/pointer.svg';
// components
import FormSelect from '../../inputsBlocks/select';
import { AnimatePresence } from 'framer-motion';

interface FilterWeekDaysFieldType {
  change: ([]) => void;
  defaultValue?: [];
}
export const FilterWeekDaysField: React.FC<FilterWeekDaysFieldType> = ({
  change,
  defaultValue,
}: FilterWeekDaysFieldType) => {
  const onChange = (values: any) => {
    change(values);
  };

  return (
    <Checkbox.Group
      className="check-box-list"
      options={week_days.map((item, index) => {
        return { label: item, value: index + 1 };
      })}
      value={defaultValue}
      onChange={onChange}
    />
  );
};

export const FilterGroupTopicField: React.FC<any> = ({ change, defaultValue }: any) => {
  const [limited, setLimited] = useState(true);
  const onChange = (values: any) => {
    change(values);
  };

  const options = useSelector((state: stateType) => state.options.topics);
  const limitedNum = 6;
  const limitedOptions = options?.length > limitedNum && limited ? options.slice(0, limitedNum) : options;

  return (
    <AnimatePresence initial={false}>
      <Checkbox.Group
        className="check-box-list"
        options={
          options &&
          limitedOptions.map((item: any) => {
            return { label: item.label, value: item.value };
          })
        }
        value={defaultValue}
        onChange={onChange}
        key={'1'}
      />
      {options?.length > limitedNum && (
        <div className="check-box-list__btn">
          <button type="button" onClick={() => setLimited(!limited)}>
            {limited ? 'Show all topics' : 'Show less topics'}
          </button>
        </div>
      )}
    </AnimatePresence>
  );
};

export const FilterStatusesField: React.FC<any> = ({ change, defaultValue }: any) => {
  const onChange = (values: any) => {
    change(values);
  };

  const options = useSelector((state: stateType) => state.options.customerStatuses);

  return (
    <Checkbox.Group
      className="check-box-list"
      options={
        options &&
        options.map((item: any) => {
          return { label: item.label, value: item.value };
        })
      }
      value={defaultValue}
      onChange={onChange}
    />
  );
};

export const FilterGroupStatusesField: React.FC<any> = ({ change, defaultValue }: any) => {
  const onChange = (values: any) => {
    change(values);
  };

  const options = useSelector((state: stateType) => state.options.groupStatuses);

  return (
    <Checkbox.Group
      className="check-box-list"
      options={
        options &&
        options.map((item: any) => {
          return { label: item.label, value: item.value };
        })
      }
      value={defaultValue}
      onChange={onChange}
    />
  );
};

export const FilterTherapistStatusesField: React.FC<any> = ({ change, defaultValue }: any) => {
  const onChange = (values: any) => {
    change(values);
  };

  const options = useSelector((state: stateType) => state.options.therapistStatuses);

  return (
    <Checkbox.Group
      className="check-box-list"
      options={
        options &&
        options
          .filter((el: any) => el.value === 1 || el.value === 4)
          .map((item: any) => {
            return { label: item.label, value: item.value };
          })
      }
      value={defaultValue}
      onChange={onChange}
    />
  );
};

export const FilterNumberOfMembersField: React.FC<any> = ({ change, defaultValue }: any) => {
  const onChange = (values: any) => {
    change(values);
  };

  const options = useSelector((state: stateType) => state.options.numberOfMembers);

  return (
    <Checkbox.Group
      className="check-box-list"
      options={
        options &&
        options.map((item: any) => {
          return { label: item.label, value: item.value };
        })
      }
      value={defaultValue}
      onChange={onChange}
    />
  );
};

export const FilterTherapistsField: React.FC<any> = ({ change, defaultValue }: any) => {
  const onChange = (values: any) => {
    change(values);
  };

  const options: any = useSelector((state: stateType) => state.groups.therapists, shallowEqual);

  return (
    <Select
      className="form-select"
      dropdownClassName="form-dropdown"
      placeholder="Select Therapist "
      style={{ width: '100%' }}
      onChange={(value: any) => {
        onChange(value);
      }}
      options={options}
      allowClear
      showSearch
      suffixIcon={<CaretDownOutlined />}
      defaultValue={defaultValue}
      filterOption={(input, option: any): any => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
    />
  );
};

export const FilterTherapistLicenses: React.FC<any> = ({ change, defaultValue }: any) => {
  const [limited, setLimited] = useState(true);
  const onChange = (values: any) => {
    change(values);
  };

  const options = useSelector((state: stateType) => state.options.licenses);
  const limitedNum = 6;
  const limitedOptions = options?.length > limitedNum && limited ? options.slice(0, limitedNum) : options;

  return (
    <div>
      <Checkbox.Group
        className="check-box-list"
        options={
          options &&
          limitedOptions.map((item: any) => {
            return { label: item.label, value: item.value };
          })
        }
        value={defaultValue}
        onChange={onChange}
      />
      {options?.length > limitedNum && (
        <div className="check-box-list__btn">
          <button type="button" onClick={() => setLimited(!limited)}>
            {limited ? 'Show all licensures' : 'Show less licensures'}
          </button>
        </div>
      )}
    </div>
  );
};

export const FilterGroupActiveSort: React.FC<any> = ({ change, defaultValue }: any) => {
  const onChange = (values: any) => {
    change([values.target.value]);
  };

  return (
    <Radio.Group className="radio-list" value={defaultValue?.join('')} onChange={onChange}>
      {sortActiveGroup.map((item: any, index) => {
        return (
          <Radio value={item.value} key={index}>
            {item.label}
          </Radio>
        );
      })}
    </Radio.Group>
  );
};

export const FilterTherapistsState: React.FC<any> = ({ defaultValue, control }: any) => {
  return (
    <FormSelect
      name="state"
      label=""
      errors={undefined}
      control={control}
      defaultValue={defaultValue}
      placeholder={
        <>
          <Icon component={IconPointer} style={{ fontSize: '15px', marginBottom: '2px', marginRight: '5px' }} />
          {'Select State'}
        </>
      }
      filterOption={(input: string, option: any) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
    />
  );
};
