import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Modal } from 'antd';
import cn from 'classnames';
// components
import { SubjectBody, SubjectInput, SubjectButton } from '../../../molecules/inputsBlocks/account';
// actions
import accountAction from '../../../../redux/actions/account';
// images
import ImgSuccess from '../../../../assets/image/questions/after.png';
// style
import './style.scss';
import appAction from 'redux/actions/app';

interface FormData {
  subject: string;
  message: string;
}

interface SuccessType {
  action: () => void;
  success?: boolean;
}

const ContactsSupportForm: React.FC = () => {
  const [success, setSuccess] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<FormData>();

  const dispatch = useDispatch();

  const onSubmit = (data: FormData) => {
    dispatch(accountAction.sendSupport(data, () => setSuccess(true)));
  };

  const action = () => {
    setSuccess(false);
    dispatch(appAction.account('details'));
    reset();
  };

  return (
    <div className="contacts-support">
      <>
        <div className="contacts-support__title">
          {`Reach out to our support team and we'll get back to you as soon as possible via email`}
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="contacts-support-form">
          <SubjectInput
            errors={errors.subject ? errors.subject.message : undefined}
            register={register}
            control={control}
          />
          <SubjectBody
            errors={errors.message ? errors.message.message : undefined}
            register={register}
            control={control}
          />
          <SubjectButton name="Send" clearName="Clear" reset={reset} />
        </form>
      </>
      <ModalSuccess action={action} success={success} />
    </div>
  );
};

export default ContactsSupportForm;

const ModalSuccess: React.FC<SuccessType> = ({ action, success }: SuccessType) => {
  const showMinimal = useSelector((state: any) => state.main_menu.showMinimal, shallowEqual);

  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(appAction.account('details'));
    };
  }, []);

  return (
    <Modal
      visible={success}
      onOk={action}
      closable={false}
      onCancel={action}
      centered
      footer={null}
      className={cn('modal-success', {
        'modal-success__minimal': showMinimal,
      })}
    >
      <BoxSuccess action={action} />
    </Modal>
  );
};

const BoxSuccess = ({ action }: SuccessType) => {
  return (
    <div className="container-success">
      <img src={ImgSuccess} alt="Img Success" />
      <h2 className="container-success__title">Thanks</h2>
      <p className="container-success__text">
        Thanks for submitting your inquiry. You can expect to receive a reply from one of our support team members in
        your email inbox shortly.
      </p>
      <button type="button" className="btn-form" onClick={action}>
        OK
      </button>
    </div>
  );
};
