/*
 *
 * Choice group reducer
 *
 */
const initialState = {
  item: [
    {
      group: {},
      therapist: {},
    },
  ],
  totalCount: 0,
  loader: true,
  loaderModal: false,
  params: {
    page: 1,
  },
};

export type choiceGroupInitStateType = typeof initialState;

interface choiceGroupActionTypes {
  type: string;
  payload: {
    item: [
      {
        group: any;
        therapist: any;
      },
    ];
    totalCount: number;
    loader: boolean;
    loaderModal: boolean;
    params: {
      page: number;
    };
  };
}

const choice_group = (state = initialState, action: choiceGroupActionTypes): choiceGroupInitStateType => {
  switch (action.type) {
    case 'CHOICE_GROUP':
      return Object.assign({}, state, {
        item: action.payload.item,
        totalCount: action.payload.totalCount,
      });
    case 'CHOICE_GROUP_LOADER':
      return Object.assign({}, state, {
        loader: action.payload.loader,
      });
    case 'CHOICE_GROUP_LOADER_MODAL':
      return Object.assign({}, state, {
        loaderModal: action.payload.loaderModal,
      });
    case 'CHOICE_GROUP_PARAMS':
      return Object.assign({}, state, {
        params: action.payload.params,
      });

    default:
      return state;
  }
};

export default choice_group;
