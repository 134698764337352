import React from 'react';
import { Spin } from 'antd';
// icons
import logoIcon from 'assets/image/headers/png/old-logo.png';

const LoadingPage: React.FC = () => {
  return (
    <div className="loading-page">
      <div className="loading-page__content">
        <Spin spinning={true}>
          <img src={logoIcon} alt="Logotype" />
        </Spin>
      </div>
    </div>
  );
};

export default LoadingPage;
