import React, { useEffect, useState } from 'react';
import { Spin, Tabs } from 'antd';
import _ from 'lodash';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Moment from 'react-moment';
// configs
import { APP } from '../../../../configs/app';
// components
import { AccountDetailsItem, AccountEditBlock, AdminTherapistAccountDetails, ButtonTherapistEdit } from '../customer';
import GroupsList from '../../lists/groups';
import SessionList from '../../lists/history/therapist';
import ShowText from '../../../molecules/showText';
import UploadHistoryStatements from '../../modals/historyStatements';
import EditTherapistPhoto from 'components/organisms/modals/therapistEditPhoto';
import EditTherapistAvatarModal from '../../modals/edit/admin/therapist/therapistAvatar';
import EnabledNotifications from '../../../molecules/enabledNotification';
import ShareAccount from '../../shareAccount';
// actions
import therapistAction from '../../../../redux/actions/therapist';
// types
import { stateType } from '../../../../types/types';
import { CopyLinkRole } from '../../../../types/actions/common-types';
// styles
import './style.scss';

const AccountTherapist: React.FC = () => {
  const item: any = useSelector((state: stateType) => state.account.item, shallowEqual);

  return (
    <div className="account-therapist">
      {!_.isEmpty(item) && (
        <>
          <div className="account-therapist__avatar">
            <div className="account-therapist__avatar-wrapper">
              <EditTherapistPhoto />
              <img
                src={
                  item.avatar
                    ? item.avatar.url
                    : 'https://cdn4.iconfinder.com/data/icons/alternative-medicine-flat/64/male-therapist-doctor-dentist-avatar-512.png'
                }
                alt="avatar"
                className="account-therapist__img"
              />
            </div>
          </div>
          <div className="account-therapist__title-name">
            {item.title?.name ? `${item.title?.name}.` : ''} {item.firstname} {item.lastname}
          </div>
          <div className="account-therapist__bio">
            <div className="account-therapist__label">My Bio</div>
            <ShowText text={item.biography} strLength={160} />
          </div>
          <div className="account-therapist__list-info">
            <div className="account-therapist__item">
              <div className="account-therapist__label">Years in Practice</div>
              {!!item?.practiceYears && <div className="account-therapist__value">{item.practiceYears}</div>}
            </div>
            <div className="account-therapist__item">
              <div className="account-therapist__label">State</div>
              <div className="account-therapist__value">
                {item?.state?.length
                  ? item.state.map((state: any, i: number) => {
                      if (item.state.length > 1) {
                        if (i < item.state.length - 1) {
                          return `${state.name}, `;
                        }
                      }
                      return state.name;
                    })
                  : ''}
              </div>
            </div>
            <div className="account-therapist__item">
              <div className="account-therapist__label">Licensure</div>
              <div className="account-therapist__value">{item.licensure}</div>
            </div>
            <div className="account-therapist__item">
              <div className="account-therapist__label">Specialties</div>
              <div className="account-therapist__value">
                {item.specialtiesString.map((item: any) => {
                  return `${item + ' '}`;
                })}
              </div>
            </div>
            <div className="account-therapist__item">
              <div className="account-therapist__label">Possible group topics</div>
              <div className="account-therapist__value">{item.groupTopicsString}</div>
            </div>
            <div className="account-therapist__item">
              <div className="account-therapist__label">Date Joined: </div>
              <div className="account-therapist__value">{<Moment format="MMMM D, YYYY">{item.joinedDate}</Moment>}</div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AccountTherapist;

export const AccountTherapistItem: React.FC<any> = ({ item, loader }: any) => {
  return (
    <div className="account-therapist">
      {!_.isEmpty(item) && (
        <>
          <div className="account-therapist__avatar">
            <div className="account-therapist__avatar-wrapper">
              <EditTherapistAvatarModal btnClass="account-therapist__avatar-edit-icon" />
              <img
                src={
                  item.avatar
                    ? item.avatar.url
                    : 'https://cdn4.iconfinder.com/data/icons/alternative-medicine-flat/64/male-therapist-doctor-dentist-avatar-512.png'
                }
                alt="avatar"
                className="account-therapist__img"
              />
            </div>
          </div>
          <div className="account-therapist__title-name admin">
            <span>
              {item?.title?.name ? `${item.title.name}.` : ''} {item.firstname} {item.lastname}
            </span>
            <ShareAccount role={CopyLinkRole.Therapist} id={item.id} />
          </div>
          <div className="account-therapist__bio">
            <div className="account-therapist__edit">
              <div className="account-therapist__label">My Bio</div>
              <ButtonTherapistEdit />
            </div>
            <ShowText text={item.biography} strLength={160} />
          </div>
          <div className="account-therapist__list-info">
            <div className="account-therapist__item">
              <div className="account-therapist__label">Years in Practice</div>
              {!!item?.practiceYears && <div className="account-therapist__value">{item.practiceYears}</div>}
            </div>
            <div className="account-therapist__item">
              <div className="account-therapist__label">State</div>
              <div className="account-therapist__value">
                {item?.state?.length
                  ? item.state.map((state: any, i: number) => {
                      if (item.state.length > 1) {
                        if (i < item.state.length - 1) {
                          return `${state.name}, `;
                        }
                      }
                      return state.name;
                    })
                  : ''}
              </div>
            </div>
            <div className="account-therapist__item">
              <div className="account-therapist__label">Licensure</div>
              <div className="account-therapist__value">{item.licensure}</div>
            </div>
            <div className="account-therapist__item">
              <div className="account-therapist__label">Specialties</div>
              <div className="account-therapist__value">
                {item.specialtiesString.map((item: any) => {
                  return `${item + ' '}`;
                })}
              </div>
            </div>
            <div className="account-therapist__item">
              <div className="account-therapist__label">Possible group topics</div>
              <div className="account-therapist__value">{item.groupTopicsString}</div>
            </div>
            <div className="account-therapist__item">
              <div className="account-therapist__label">Date Joined: </div>
              <div className="account-therapist__value">{<Moment format="MMMM D, YYYY">{item.joinedDate}</Moment>}</div>
            </div>
            {APP.MODE.IS_STAGE && (
              <div className="account-therapist__item">
                <Spin spinning={loader}>
                  <EnabledNotifications classes="account-therapist" item={item} isTherapist />
                </Spin>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export const AccountTherapistTabs: React.FC = () => {
  const { TabPane } = Tabs;

  const item: any = useSelector((state: stateType) => state.account.item, shallowEqual);

  const callback = (key: any) => {
    console.log(key);
  };

  return (
    <Tabs defaultActiveKey="1" onChange={callback} className="account-tabs">
      <TabPane tab="Account Details" key="details" className="account-tabs__item">
        <AccountEditBlock title={'Profile'} />
        <AccountDetailsItem item={item} edit={true} />
      </TabPane>
    </Tabs>
  );
};

export const TherapistInfoTabs: React.FC<any> = ({ paramsId }: any) => {
  const { TabPane } = Tabs;
  const dispatch = useDispatch();

  const groups: any = useSelector((state: stateType) => state.therapists.groups, shallowEqual);
  const sessions: any = useSelector((state: stateType) => state.therapists.sessions, shallowEqual);
  const params: any = useSelector((state: stateType) => state.therapists.sessionsParams, shallowEqual);

  const [page, setPage] = useState(1);

  const onShowMore = () => {
    setPage(page + 1);
  };

  useEffect(() => {
    if (page > 1) {
      dispatch(therapistAction.sessionsHistoryParams({ ...params, page }));
      dispatch(therapistAction.getSessionsHistory(paramsId));
    }

    return () => {
      dispatch(therapistAction.sessionsHistoryParams({ page: 1, per_page: 5 }));
    };
  }, [page]);

  return (
    <Tabs defaultActiveKey="1" className="account-tabs">
      <TabPane tab="Account Details" key="details" className="account-tabs__item">
        <AccountEditBlock title={'Profile'} />
        <AdminTherapistAccountDetails />
      </TabPane>
      <TabPane tab="Groups running" key="running" className="account-tabs__item">
        <div className="account-tabs__item-info">
          <AccountEditBlock title={'Groups running '} />
          <div className="count_group ">{groups.length} Active Groups </div>
        </div>
        <GroupsList groups={groups} />
      </TabPane>
      <TabPane tab="Session History" key="history" className="account-tabs__item">
        <AccountEditBlock title={'Session History '}>
          <UploadHistoryStatements />
        </AccountEditBlock>
        <PaddingBox>
          <SessionList list={sessions} onShowMore={onShowMore} params={params} />
        </PaddingBox>
      </TabPane>
    </Tabs>
  );
};

const PaddingBox = ({ children }: any) => {
  return <div className="account-therapist-box">{children}</div>;
};
