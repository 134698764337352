import React, { FC, useEffect } from 'react';
import { Radio, RadioChangeEvent } from 'antd';
import cn from 'classnames';
// types
import { IQuestion } from 'types/app-types';

interface IBooleanChoice {
  question: IQuestion;
  onChangeRadio: (value: number) => void;
  value: number;
}

const BooleanChoice: FC<IBooleanChoice> = ({ question, onChangeRadio, value }: IBooleanChoice) => {
  useEffect(() => {
    onChangeRadio(question.defaultValue?.answerId || question?.answers?.[0].id);
  }, []);

  const onChange = (e: RadioChangeEvent) => {
    onChangeRadio(e.target.value);
  };

  return (
    <Radio.Group onChange={onChange} value={value}>
      {question?.answers?.map(({ id, answer }) => (
        <div
          key={id}
          className={cn('questions__answer radio', { selected: id === value })}
          onClick={() => onChangeRadio(id)}
        >
          <Radio value={id}>
            <div className="questions__answer-text">{answer}</div>
          </Radio>
        </div>
      ))}
    </Radio.Group>
  );
};

export default BooleanChoice;
