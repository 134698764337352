import React from 'react';
import { Link } from 'react-router-dom';

const Component404: React.FC = () => {
  return (
    <div className="notfound-container">
      <Link to="/">HOME</Link>
      <img src="https://nerdweb.com.br/uploads/1578511646-cropit-.jpg" alt="notfound" />
    </div>
  );
};

export default Component404;
