import React, { FC, ReactElement } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Controller } from 'react-hook-form';
import { Select } from 'antd';
// components
import InputField from '../../inputField';
// types
import { stateType } from '../../../../types/types';

interface IFormSelect {
  errors: string | undefined;
  control: any;
  name: string;
  optionsName?: string;
  label: string;
  placeholder: string | ReactElement<any, any>;
  filterOption?: any;
  rules?: { [key: string]: string | boolean };
  defaultValue?: any;
  mode?: 'multiple' | 'tags';
  maxTagCount?: number | 'responsive';
}

const FormSelect: FC<IFormSelect> = (props: IFormSelect) => {
  const { errors, control, name, label, placeholder, filterOption, optionsName, rules, defaultValue } = props;
  const { [optionsName || name]: options }: any = useSelector((state: stateType) => state.options, shallowEqual);

  return (
    <InputField name={name} label={label} errors={errors} icon={false}>
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field: { onChange, onBlur, value } }) => (
          <Select
            className={errors ? 'form-select form-select__error' : 'form-select'}
            dropdownClassName="form-dropdown"
            placeholder={placeholder}
            style={{ width: '100%' }}
            onChange={onChange}
            onBlur={onBlur}
            showSearch
            searchValue={''}
            options={options}
            allowClear
            defaultValue={defaultValue}
            filterOption={filterOption}
            onDropdownVisibleChange={(open) => !open && onBlur()}
            getPopupContainer={(trigger) => trigger.parentNode}
            value={value}
          />
        )}
      />
    </InputField>
  );
};

export default FormSelect;

export const FormSearchSelect: FC<IFormSelect> = (props: IFormSelect) => {
  const {
    errors,
    control,
    name,
    label,
    placeholder,
    filterOption,
    optionsName,
    rules,
    defaultValue,
    mode,
    maxTagCount,
  } = props;
  const { [optionsName || name]: options }: any = useSelector((state: stateType) => state.options, shallowEqual);

  let newOptions = options;
  if (name === 'icd10') newOptions = options.map((o: any) => ({ label: o, value: o }));

  return (
    <InputField name={name} label={label} errors={errors} icon={false}>
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field: { onChange, onBlur, value } }) => (
          <Select
            className={
              errors ? 'form-select form-select__search form-select__error' : 'form-select form-select__search'
            }
            dropdownClassName="form-dropdown"
            placeholder={placeholder}
            optionFilterProp="children"
            style={{ width: '100%' }}
            onChange={(value: any) => {
              onChange(value);
            }}
            onBlur={() => {
              !value && onChange(null);
              onBlur();
            }}
            onFocus={() => {
              !mode && onChange('');
            }}
            showSearch
            mode={mode}
            maxTagCount={maxTagCount}
            options={newOptions.filter((el: any) => el.label !== 'Outside the US')}
            allowClear
            defaultValue={defaultValue}
            filterOption={filterOption}
            onDropdownVisibleChange={(open) => !open && onBlur()}
            getPopupContainer={(trigger) => trigger.parentNode}
            value={value}
          >
            {newOptions.map((item: any) => (
              <Select.Option title={item.label} key={item.label} id={item.value} value={item.value}>
                {item.label}
              </Select.Option>
            ))}
          </Select>
        )}
      />
    </InputField>
  );
};
