import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Modal, Spin } from 'antd';
import Icon from '@ant-design/icons';
// actions
import groupsAction from '../../../../../redux/actions/groups';
// types
import { stateType } from '../../../../../types/types';
// components
import SmallGroupCard from '../../../cards/groupCard/SmallGroupCard';
// configs
import { week_days } from '../../../../../configs/tables/admin/clients';
// icons
import { ReactComponent as CloseIcon } from '../../../../../assets/image/account/close4.svg';
import { ReactComponent as IconCalendar } from '../../../../../assets/image/dropdown/calendar.svg';
import { ReactComponent as ImgAfter } from '../../../../../assets/image/questions/after.svg';
// styles
import '../style.scss';

const CanselGroup: React.FC<any> = () => {
  const [visible, setVisible] = useState(false);
  const [success, setSuccess] = useState(false);

  const loader = useSelector((state: stateType) => state.groups.loaderModal, shallowEqual);
  const item = useSelector((state: stateType) => state.groups.item, shallowEqual);

  const open = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onSuccess = () => {
    setSuccess(true);
  };

  return (
    <>
      <button className="btn-form btn-form__cancel" onClick={open}>
        Canсel Group
      </button>
      {visible && (
        <Modal
          visible={visible}
          onOk={handleOk}
          closable={true}
          onCancel={handleCancel}
          centered
          maskTransitionName="none"
          footer={null}
          className="cansel-group-modal"
          closeIcon={<CloseIcon />}
        >
          <Spin spinning={loader}>
            {success ? (
              <ModalSuccess handleCancel={handleCancel} item={item} />
            ) : (
              <ModalContainer onSuccess={onSuccess} item={item} groupID={item.group.id} />
            )}
          </Spin>
        </Modal>
      )}
    </>
  );
};

export default CanselGroup;

const ModalContainer = ({ onSuccess, item, groupID }: any) => {
  const dispatch = useDispatch();

  const action = () => {
    dispatch(groupsAction.cancel(groupID, onSuccess));
  };

  return (
    <div className="cansel-session-container">
      <div className="cansel-session-container__title">Are you sure you want to cancel the following Group?</div>
      <div className="cansel-session-container__main">
        <div className="cansel-session-container__label">
          <Icon component={IconCalendar} />
          {`Every ${week_days[item.group.date.dayOfWeek - 1]}`}
        </div>
        <div className="cansel-session-container__group-info">
          <SmallGroupCard item={item} />
        </div>
      </div>
      <div className="cansel-session-container__block">
        <button className="btn-form" onClick={action}>
          Cancel Group
        </button>
      </div>
    </div>
  );
};

const ModalSuccess = ({ handleCancel, item }: any) => {
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(groupsAction.id(item.group.id));
    };
  }, []);
  return (
    <div className="cansel-session-container">
      <div className="cansel-session-container__title cansel-session-container__title_success">
        <ImgAfter className="cansel-session-container__finish-icon" />
        You’ve successfully canceled the following Group!
      </div>
      <div className="cansel-session-container__main">
        <div className="cansel-session-container__group-info">
          <SmallGroupCard item={item} />
        </div>
      </div>
      <div className="cansel-session-container__block">
        <button className="btn-form" onClick={handleCancel}>
          Done
        </button>
      </div>
    </div>
  );
};
