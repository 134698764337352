import React, { FC, useEffect } from 'react';
// components
import SimpleHeader from '../../organisms/headers/simple/simple-header';
import PreviewContainer from '../../containers/previewContainer';
// action
import groupsAction from '../../../redux/actions/groups';
import clientsAction from '../../../redux/actions/clients';
// styles
import './style.scss';
import PreviewAfterContainer from 'components/containers/previewAfterContainer';

interface AuthTemplateType {
  children: React.ReactNode;
  headerComponent?: React.ReactNode;
  text_before_form?: React.ReactNode;
  text_footer?: string;
  mask?: React.ReactNode;
  box_down?: boolean;
  after_assessment?: boolean;
}
const AuthTemplate: FC<AuthTemplateType> = ({
  children,
  headerComponent,
  text_before_form,
  text_footer,
  mask,
  box_down,
  after_assessment = false,
}: AuthTemplateType) => {
  useEffect(() => {
    groupsAction.clear();
    clientsAction.clear();
  }, []);

  const redirect_url = localStorage.getItem('role') ? `/login-${localStorage.getItem('role')}` : '/login-customer';

  return (
    <div className="auth-template">
      <SimpleHeader redirect_url={redirect_url} />
      <div className="box">
        {after_assessment ? (
          <PreviewAfterContainer
            text_before_form={text_before_form}
            headerComponent={headerComponent}
            box_down={box_down}
          >
            {children}
          </PreviewAfterContainer>
        ) : (
          <PreviewContainer text_before_form={text_before_form} headerComponent={headerComponent} box_down={box_down}>
            {children}
          </PreviewContainer>
        )}
        <div className="auth-template__footer">{text_footer}</div>
        {mask && mask}
      </div>
    </div>
  );
};

export default AuthTemplate;
