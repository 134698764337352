export const APP = {
  API: {
    URL: String(process.env.REACT_APP_API_URL),
    URL_SIGN_UP: String(process.env.REACT_APP_SIGNUP_URL),
  },
  STRIPE: {
    TOKEN: String(process.env.REACT_APP_STRIPE_SECRET_KEY),
    TEST_TOKEN: String(process.env.REACT_APP_STRIPE_SECRET_TEST_KEY),
  },
  MODE: {
    IS_STAGE: String(process.env.REACT_APP_ENV) === 'development',
  },
};
