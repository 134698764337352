import React, { FC } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Spin } from 'antd';
// components
import AuthTemplate from '../../../components/templates/authTemplate';
import { ConsultationPreviewText } from 'components/atoms/texts/authTemplate/preview-text';
import ConsultationForm from '../../../components/organisms/forms/consultation';
// types
import { stateType } from '../../../types/types';

const PageRegistered: FC = () => {
  const textFooter = '©2022 Grouport Inc. All rights reserved.';

  const loader: boolean = useSelector((state: stateType) => state.consultation_form.loader, shallowEqual);
  return (
    <AuthTemplate text_footer={textFooter} headerComponent={<ConsultationPreviewText />}>
      <Spin tip="Loading..." spinning={loader}>
        <ConsultationForm />
      </Spin>
    </AuthTemplate>
  );
};

export default PageRegistered;
