/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/display-name */
import React from 'react';
import * as ColumnItems from '../../../../molecules/columnItems/admin/clients';

export const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (value, row, index) => <ColumnItems.ItemName info={value} row={row} index={index} link="clients" />,
    width: '230px',
  },
  {
    title: 'Topic',
    dataIndex: 'topic',
    key: 'topic',
    render: (value, row, index) => {
      return <ColumnItems.ItemTopic info={value} row={row} index={index} />;
    },
    width: '120px',
  },

  {
    title: 'Day',
    dataIndex: 'day',
    key: 'day',
    render: (value, row, index) => <ColumnItems.ItemDay info={value} row={row} index={index} />,
    width: '130px',
    maxWidth: '130px',
  },

  {
    title: 'Time',
    dataIndex: 'time',
    key: 'time',
    render: (value, row, index) => <ColumnItems.ItemTime info={value} row={row} index={index} />,
    width: '190px',
  },

  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (value, row, index) => <ColumnItems.ItemStatus info={value} row={row} index={index} />,
    width: '115px',
  },
];

columns.displayName = 'MyComponent';
