/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { call, put, select } from 'redux-saga/effects';
import showError from './showError';
// api
import { api } from '../../api/sessions';
// actions
import sessionsAction from '../actions/sessions';
// helps
import { sessionMap } from '../../api/mapping/response/sessions';

export function* sagaGetSessions(action) {
  yield put(sessionsAction.loader(true));
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);

  try {
    const next_response = yield call(api.get, role.toLowerCase(), action.data, 'next');
    const future_response = yield call(api.get, role.toLowerCase(), action.data, 'future');

    yield put(sessionsAction.loader(false));

    let result = {
      next: next_response.data.data.items,
      future: future_response.data.data.items,
    };

    yield sessionsAction.create(result);
  } catch (e) {
    yield put(sessionsAction.loader(false));
    showError();
  }
}

export function* sagaGetNextSessions(action) {
  const getRole = (state) => state.user.profile.role;
  const getFuture = (state) => state.sessions.items.future;
  const role = yield select(getRole);
  const future = yield select(getFuture);

  try {
    const next_response = yield call(api.get, role.toLowerCase(), action.data, 'next');

    yield put(sessionsAction.loader(false));

    let result = {
      next: next_response.data.data.items,
      future,
    };

    yield sessionsAction.create(result);
  } catch (e) {
    yield put(sessionsAction.loader(false));
    showError();
  }
}

export function* sagaGetSessionsEvents() {
  yield put(sessionsAction.loaderEvents(true));
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);

  try {
    const response = yield call(api.getEvents, role.toLowerCase());
    yield put(sessionsAction.loaderEvents(false));
    yield sessionsAction.setEvents(response.data.data.items);
  } catch (e) {
    yield put(sessionsAction.loaderEvents(false));
    /* notification.error({
      message: e.title,
      description: e.message,
      onClick: () => {
        console.log('Notification Clicked!');
      },
    }); */
    // if (e.response.status === 401) {
    //       yield put(appAction.auth('login'));
    //     } else {
    //       showError();
    //     } TODO: in future need show error
  }
}

export function* sagaGetCommEventSessions() {
  yield put(sessionsAction.loader(true));
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);
  try {
    // const next_response = yield call(api.getCommEventSessions, role.toLowerCase(), 'next');
    // const future_response = yield call(api.getCommEventSessions, role.toLowerCase(), 'future');
    // yield sessionsAction.setNextEventSessions(next_response.data.data.items);
    // yield sessionsAction.setFutureEventSessions(future_response.data.data.items);
    yield put(sessionsAction.loader(false));
  } catch (e) {
    yield put(sessionsAction.loader(false));
    showError();
  }
}

export function* sagaGetSelfSessions() {
  yield put(sessionsAction.loader(true));
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);
  try {
    const next_response = yield call(api.getSelf, role.toLowerCase(), 'next');
    const future_response = yield call(api.getSelf, role.toLowerCase(), 'future');
    const co_hosted_response = role == 'THERAPIST' ? yield call(api.getSelf, role.toLowerCase(), 'cohosted') : null;

    yield put(sessionsAction.loader(false));

    let result = {
      next: sessionMap.buildSessionsNext(next_response.data.data.items),
      future: future_response.data.data.items,
      coHosted: co_hosted_response ? co_hosted_response.data.data.items : [],
    };

    yield sessionsAction.create(result);
  } catch (e) {
    yield put(sessionsAction.loader(false));
    showError();
  }
}

export function* sagaGetSelfNextSessions() {
  const getRole = (state) => state.user.profile.role;
  const getFuture = (state) => state.sessions.items.future;
  const getCoHosted = (state) => state.sessions.items.coHosted;
  const role = yield select(getRole);
  const future = yield select(getFuture);
  const coHosted = yield select(getCoHosted);

  try {
    const next_response = yield call(api.getSelf, role.toLowerCase(), 'next');

    yield put(sessionsAction.loader(false));

    let result = {
      next: sessionMap.buildSessionsNext(next_response.data.data.items),
      future,
      coHosted,
    };

    yield sessionsAction.create(result);
  } catch (e) {
    showError();
  }
}

export function* sagaRescheduleSession(action) {
  yield put(sessionsAction.loaderModal(true));
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);

  try {
    yield call(api.reschedule, role.toLowerCase(), action.data, action.id);

    yield put(sessionsAction.loaderModal(false));

    if (action.callback) {
      action.callback();
    }
  } catch (e) {
    yield put(sessionsAction.loaderModal(false));
    showError();
  }
}

export function* sagaCanselSession(action) {
  yield put(sessionsAction.loaderModal(true));
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);

  try {
    yield call(api.cancel, role.toLowerCase(), action.id);

    yield put(sessionsAction.loaderModal(false));

    if (action.callback) {
      action.callback();
    }
  } catch (e) {
    yield put(sessionsAction.loaderModal(false));
    showError();
  }
}

export function* sagaJoinToSession(action) {
  yield put(sessionsAction.loader(true));
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);

  try {
    yield call(api.join, role.toLowerCase(), action.id);

    yield put(sessionsAction.loader(false));

    yield sagaGetSelfSessions();

    if (action.callback) {
      action.callback();
    }
  } catch (e) {
    yield put(sessionsAction.loader(false));
    showError();
  }
}

export function* sagaStartSession(action) {
  yield put(sessionsAction.loader(true));
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);

  try {
    yield call(api.start, role.toLowerCase(), action.id);

    yield put(sessionsAction.loader(false));

    action.paramsId ? yield sagaGetSessions({ data: action.paramsId }) : yield sagaGetSelfSessions();

    if (action.callback) {
      action.callback();
    }
  } catch (e) {
    yield put(sessionsAction.loader(false));
    showError();
  }
}
