/*
 *
 * notificationClient reducer
 *
 */

const initialState = {
  item: {} as any,
  isDiscount: false,
};
export type notificationClientInitStateType = typeof initialState;

interface notificationClientActionTypes {
  type: string;
  payload: {
    item: any;
    isDiscount: boolean;
  };
}

const notificationClient = (
  state = initialState,
  action: notificationClientActionTypes,
): notificationClientInitStateType => {
  switch (action.type) {
    case 'NOTIFICATION_CLIENT':
      return Object.assign({}, state, {
        item: action.payload.item,
      });
    case 'NOTIFICATION_CLIENT_DISCOUNT':
      return Object.assign({}, state, {
        isDiscount: action.payload.isDiscount,
      });
    default:
      return state;
  }
};

export default notificationClient;
