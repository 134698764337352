/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import _ from 'lodash';
import { Moment } from 'moment';
import moment from 'moment-timezone';

export const week_days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

export const timeConvertor = (time: number, type = 'full'): string => {
  const seconds = time * 60;
  const momentDate = moment.utc(seconds * 1000);
  const date = moment().utc().hour(momentDate.hour()).minute(momentDate.minute()).local(false);

  if (type === 'small') {
    return `${moment(date).tz('America/New_York').format('h:mm')}`;
  } else if (type === 'small-format') {
    return `${moment(date).tz('America/New_York').format('h:mma')}`;
  } else if (type === 'any-hours') {
    return `${moment(date).tz('America/New_York').format('hA')}`;
  } else if (type === 'type-time') {
    return `${moment(date).tz('UTC').format('h:mm A')}`;
  } else if (type === 'utc') {
    return `${moment(date).tz('UTC').format('H:mm')}`;
  }

  return `${moment(date).tz('UTC').format('h:mm A')}`;
};

export const colorGenerator = () => {
  const colors = ['#6C63FF', '#1891E5', '#FC5A5A', '#82C43C'];
  return colors[_.random(0, 3)];
};

interface dayToDateType {
  numDay: number;
  time: number;
}

export const dayToDate = ({ numDay, time }: dayToDateType): string => {
  const thisData: Moment = moment();
  const dataSession: Moment = moment(week_days[numDay - 1], 'dddd');

  if (dataSession.isBefore(thisData, 'day')) {
    const num = Math.ceil(Math.abs(moment.duration(thisData.diff(dataSession.add(1, 'week'))).asDays()));
    return checkDay(num);
  } else if (dataSession.isAfter(thisData, 'day')) {
    const num = Math.ceil(Math.abs(moment.duration(thisData.diff(dataSession)).asDays()));
    return checkDay(num);
  } else {
    if (moment(timeConvertor(time), 'h:mm A').isAfter(thisData, 'minute')) {
      return `Begins today`;
    } else {
      return `Begins in 7 days`;
    }
  }

  return '';
};

const checkDay = (num: number) => {
  if (num === 1) {
    return `Begins in ${num} day`;
  } else {
    return `Begins in ${num} days`;
  }
};
