/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { call, put, select } from 'redux-saga/effects';
import showError from '../showError';
// api
import { api } from '../../../api/stripe';
// actions
import stripeActions from '../../actions/stripe';
import userActions from '../../actions/user';
import accountAction from '../../actions/account';
import restartAction from '../../actions/restart_subscriptions';
// helpers
import { mapping } from '../../../api/mapping/response/user';
import { accountMapping } from '../../../api/mapping/response/account';

export function* sagaGetStripeList() {
  yield put(stripeActions.loader(true));
  yield put(stripeActions.set([]));
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);
  try {
    const response = yield call(api.get, role.toLowerCase());

    yield put(stripeActions.set(response.data.data.items));
    yield put(stripeActions.loader(false));
  } catch (e) {
    yield put(stripeActions.loader(false));
    showError();
  }
}

export function* sagaCheckPromo(action) {
  yield put(stripeActions.loader(true));
  try {
    const response = yield call(api.checkPromo, action.couponName);
    if (response.data.data.item.valid) {
      yield put(stripeActions.promoInfo(response.data.data.item));
      action.callback && action.callback();
    } else {
      yield put(stripeActions.invalidPromo(response.data.data.item.name));
    }
    yield put(stripeActions.loader(false));
  } catch (e) {
    yield put(stripeActions.loader(false));
    showError();
  }
}

export function* sagaChooseStripe(action) {
  // yield put(stripeActions.loader(true));
  const getRole = (state) => state.user.profile.role;
  const getIsRestart = (state) => state.user.profile.restartSubscriptionFlow;
  const role = yield select(getRole);
  const isRestart = yield select(getIsRestart);

  try {
    const response = yield call(api.choose, role.toLowerCase(), action);
    // yield userActions.state(mapping.stateFlowBuild(10));
    yield put(stripeActions.secred(response.data.data.item));
    if (isRestart) {
      yield put(restartAction.state(5));
    }
    // yield put(stripeActions.loader(false));
  } catch (e) {
    yield put(stripeActions.loader(false));
    showError();
  }
}

export function* sagaConfirmStripe(action) {
  const getRole = (state) => state.user.profile.role;
  const getIsRestart = (state) => state.user.profile.restartSubscriptionFlow;
  const role = yield select(getRole);
  const isRestart = yield select(getIsRestart);
  const dataGclid = JSON.parse(sessionStorage.getItem('gclid'));
  const data = action.id;
  if (dataGclid?.value) {
    data.gclid = dataGclid?.value;
  }

  try {
    const response = yield call(api.confirm, role.toLowerCase(), data);

    yield userActions.create(response.data.data.item);

    yield userActions.state(
      mapping.stateFlowBuild(isRestart ? 14 : response.data.data.item.stateFlow, response.data.data.item),
    );

    yield put(restartAction.state(6));

    yield put(stripeActions.loader(false));
  } catch (e) {
    yield put(stripeActions.loader(false));
    showError();
  }
}

export function* sagaUpdateStripe(action) {
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);

  try {
    const response = yield call(api.update, role.toLowerCase(), action.id);

    yield put(accountAction.set(accountMapping.build(response.data.data.item, role)));
    yield put(stripeActions.loader(false));

    action.callback && action.callback();
    // notification.success({
    //   message: `Payment Info Changed Successfully`,
    //   description: response.data.message,
    //   onClick: () => {
    //     console.log('Notification Clicked!');
    //   },
    // });
  } catch (e) {
    yield put(stripeActions.loader(false));
    showError();
  }
}

export function* sagaCheckedStripe() {
  yield put(stripeActions.loader(true));
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);
  try {
    const response = yield call(api.checked, role.toLowerCase());
    yield put(stripeActions.secred(response.data.data.item));
    yield put(stripeActions.loader(false));
  } catch (e) {
    yield put(stripeActions.loader(false));

    if (e.response.status === 400) {
      yield userActions.state(mapping.stateFlowBuild(e.response.data.new_state_flow));
      return;
    }
    showError();
  }
}

export function* sagaCanselStripe() {
  yield put(stripeActions.loader(true));
  const getIsRestart = (state) => state.user.profile.restartSubscriptionFlow;
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);

  try {
    const response = yield call(api.cansel, role.toLowerCase());
    yield userActions.state(mapping.stateFlowBuild(response.data.data.item.stateFlow));
    yield put(stripeActions.loader(false));
  } catch (e) {
    yield put(stripeActions.loader(false));
    showError();
  }
}

export function* sagaChangeStripe(action) {
  yield put(stripeActions.loader(true));

  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);

  try {
    yield call(api.change, role.toLowerCase(), action.id);

    yield put(stripeActions.loader(false));

    if (action.callback) {
      action.callback();
    }
  } catch (e) {
    yield put(stripeActions.loader(false));
    showError();
  }
}
