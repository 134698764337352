import React from 'react';
import { Controller } from 'react-hook-form';
import InputMask from 'react-input-mask';
// components
import InputField from '../../inputField';
// types
import { InputsType } from '../../../../types/form-data';

const PhonesInput: React.FC<InputsType> = ({ errors, control, defaultValue }: InputsType) => {
  return (
    <InputField name="phone" label="Phone Number" errors={errors}>
      <Controller
        control={control}
        name="phone"
        render={({ field: { onChange, onBlur } }) => (
          <InputMask
            mask="+1 999 999 9999"
            placeholder="+1 (###) - ### - ####"
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={defaultValue ? defaultValue : ''}
          />
        )}
      />
    </InputField>
  );
};

export default PhonesInput;
