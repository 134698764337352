import React from 'react';

export const consts = {
  hostName: window.location.origin,
};

export const MAX_WIDTH = 1440;

export const colors = [
  { id: 1, color: '#e6f4ff' },
  { id: 2, color: '#f6efff' },
  { id: 3, color: '#fdf0e9' },
  { id: 4, color: '#e3f5ea' },
  { id: 5, color: '#eff5e3' },
];

export const frequentlyRegister = [
  {
    title: 'What happens next?',
    text:
      'You’ll be asked a few questions, which will give your therapist a better sense of what you’re dealing with prior to joining your group. You’ll gain immediate access to your portal, and be ready to start your first session on the date listed.',
  },
  {
    title: 'When will I begin my group? ',
    text:
      'All groups are ongoing and meet weekly at the same time each week. So when the day you registered for comes up next, that will be the time when you have your first session. The date and time of your first session will appear on the home page in your portal.',
  },
  {
    title: 'How am I billed? ',
    text:
      'We offer a subscription service that’s billed on either a monthly, quarterly, or biannual basis. No matter what payment schedule you select, you are entitled to one 60-minute group session per week and all the community benefits we offer outside of session. Subscriptions auto renew, until you cancel your membership which you can do at any time.',
  },
  {
    title: 'Can I cancel my subscription?',
    text:
      'Yes, you can cancel your subscription at any time. It’ll just finish out your current billing cycle and won’t bill you thereafter. So if you elect for a monthly, quarterly, or biannual payment schedule than it’ll just finish out the current billing cycle you’re on and won’t charge you thereafter.',
  },
  {
    title: 'What if I don’t like my group? ',
    text:
      'You can switch your group at any time. We have tons of therapist-led groups to choose from, so if the current one is not the right fit, we encourage you to try another option until we find the group that’s best for you.',
  },
  {
    title: 'Are Grouport therapists licensed?',
    text:
      'Absolutely! All our therapists are licensed, accredited, and vetted mental health professionals with years of experience and our network spans therapists who specialize across the full range of mental health challenges.',
  },
];

export const frequentlyChange = [
  {
    title: 'What’s the difference in the payment options?',
    text: (
      <>
        - You can pay on a monthly, 3 month, or 6 month basis <br />
        - The subscription will auto-renew according to the payment schedule you elect
        <br />- The benefit of paying on a 3 or 6 month basis is you receive additional discounts
      </>
    ),
  },
  {
    title: 'When does my billing change take effect?  ',
    text: (
      <>
        The change will take effect following the end of your current billing cycle
        <br />
        - e.g. if you’re currently paying monthly, and switch to pay quarterly the change will take effect following the
        end of the monthly billing cycle <br />- e.g. if you’re currently paying quarterly, and switch to pay monthly
        the change will take effect following the end of the quarterly billing cycle
      </>
    ),
  },
  {
    title: 'Can I change how I’m billed? ',
    text:
      ' Yes, you can do so at any time. The change will just take effect following the \n' +
      'end of your current billing cycle. ',
  },
  {
    title: ' If I cancel before my billing change is set to take effect, what happens? ',
    text:
      ' If you cancel before the end of your current billing cycle, prior to when the \n' +
      'billing change is set to take effect, than your plan will be cancelled and the \n' +
      'billing change won’t take effect. ',
  },
  {
    title: 'What if I don’t like my group? ',
    text:
      'You can switch your group at any time. We have tons of therapist-led groups \n' +
      'to choose from, so if the current one is not the right fit, we encourage you to \n' +
      'try another option until we find the group that’s best for you.\n',
  },
];

export const benefits = [
  // `Meet weekly in your online group therapy session (60\u00A0min)`,
  // 'Join regular community events for added skills ',
  // 'Chat with others like you outside of sessions ',
  // 'Set therapy goals and track your progress ',

  'Meet weekly in your online group therapy session (4 sessions per month @ 60min each)',
  'Led by expert therapists with years of experience',
  'Groups matched to your specific needs',
  'Be part of a community which will support you every step of the way',
];

export const sortActiveGroup = [
  { label: 'Ascending', value: 'asc' },
  { label: 'Descending', value: 'desc' },
];

export const sortCommunityEvents = {
  length: [
    { label: '30 min', value: 1 },
    { label: '45 min', value: 2 },
    { label: '60 min', value: 3 },
  ],
  topic: [
    { label: 'Anxiety', value: 1 },
    { label: 'Depression', value: 2 },
    { label: 'DBT', value: 3 },
    { label: 'Bipolar Disorder', value: 4 },
    { label: 'Borderline Personality ', value: 5 },
  ],
  type: [
    { label: 'Session', value: 1 },
    { label: 'Webinar', value: 2 },
  ],
  sort: [
    { label: 'New', value: 1 },
    { label: 'Bookmarked', value: 2 },
  ],
};

export const TOTAL_GROUPS_NUMBER = 3;
