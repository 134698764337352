import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Spin } from 'antd';
import Moment from 'react-moment';
import moment from 'moment';
// componets
import FormRescheduleSession from '../../../forms/reschedule/session';
// types
import { stateType } from '../../../../../types/types';
// styles
import './style.scss';
//icons
import { ReactComponent as CloseIcon } from '../../../../../assets/image/account/close4.svg';
import BlueIconTime from 'assets/image/group/reschedule-group-blue-time.svg';
import GreenIconTime from 'assets/image/group/reschedule-group-green-time.svg';
import Line from 'assets/image/group/line.png';
// actions
import groupsAction from 'redux/actions/groups';
import sessionsAction from '../../../../../redux/actions/sessions';

const RescheduleSession: React.FC<any> = ({ session }: any) => {
  const [visible, setVisible] = useState(false);
  const [success, setSuccess] = useState(false);
  const loader = useSelector((state: stateType) => state.sessions.loaderModal);
  const item = useSelector((state: stateType) => state.groups.item);
  const [newDate, setNewDate] = useState();

  const open = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setSuccess(false);
    setVisible(false);
  };

  const handleCancel = () => {
    setSuccess(false);
    setVisible(false);
  };

  const onSuccess = () => {
    setSuccess(true);
  };

  return (
    <>
      <button className="btn-item" onClick={open}>
        Reschedule Session
      </button>
      {visible && (
        <Modal
          visible={visible}
          onOk={handleOk}
          closable={true}
          onCancel={handleCancel}
          centered
          maskTransitionName="none"
          footer={null}
          className="reschedule-session-modal"
          closeIcon={<CloseIcon />}
        >
          {!success && (
            <Spin spinning={loader}>
              <ModalContainer
                onSuccess={onSuccess}
                session={session}
                groupID={item.group.id}
                setVisible={setVisible}
                setNewDate={setNewDate}
              />
            </Spin>
          )}
          {success && <ModalSuccess handleCancel={handleCancel} session={session} newDate={newDate} item={item} />}
        </Modal>
      )}
    </>
  );
};

export default RescheduleSession;

const ModalContainer = ({ setVisible, session, groupID, onSuccess, setNewDate }: any) => {
  return (
    <div className="reschedule-session-container">
      <div className="reschedule-session-container__title">Select a time to reschedule the following group session</div>
      <div className="reschedule-session-container__block">
        <FormRescheduleSession
          setVisible={setVisible}
          session={session}
          groupID={groupID}
          onSuccess={onSuccess}
          setNewDate={setNewDate}
        />
      </div>
    </div>
  );
};

const ModalSuccess = ({ handleCancel, session, newDate, item }: any) => {
  const groupDate = newDate.date;
  const groupEnd = newDate.end;
  const groupStart = newDate.start;

  const startHours = moment(groupStart).format('h:mmA');
  const endHours = moment(groupEnd).format('h:mmA z');

  const oldTimeStart = moment(session.start).format('h:mmA');
  const oldTimeEnd = moment(session.end).format('h:mmA z');

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(groupsAction.get(item.group.id));
      dispatch(sessionsAction.get(item.group.id));
    };
  }, []);

  return (
    <div className="rechedule-session-container reschedule-success-container">
      <div className="reschedule-session-container__title">
        You’ve successfully rescheduled the following <br />
        session:
      </div>
      <div className="form-reschedule-session__main reschedule-success-container__main">
        <div className="reschedule-success-container__main-wrapper">
          <div className="reschedule-success-container__icons">
            <img src={GreenIconTime} alt="" />
            <img src={Line} className="reschedule-session-container__line-icon" alt="" />
            <img src={BlueIconTime} alt="" />
          </div>
          <div className="reschedule-success-container__time">
            <div className="form-reschedule-session__old">
              <div className="different-container__titles">
                <div className="different-container__title">Old Time:</div>
                <div className="green-time-container">
                  <Moment format="dddd MMMM Do, YYYY">{session.start}</Moment>
                  &nbsp;
                  {oldTimeStart}-{oldTimeEnd}
                </div>
                <div className="different-container__label"></div>
              </div>
            </div>
            <div className="form-reschedule-session__new">
              <div className="different-container__titles">
                <div className="different-container__title">New Time:</div>
                <div className="blue-time-container">
                  <Moment format="dddd MMMM Do, YYYY">{groupDate}</Moment>
                  &nbsp;
                  {startHours}-{endHours}
                </div>
                <div className="different-container__label"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-reschedule-session__footer">
        <button className="btn-form" onClick={handleCancel}>
          Done
        </button>
      </div>
    </div>
  );
};
