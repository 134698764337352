/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { put, call, all, select } from 'redux-saga/effects';
// actions
import clientAction from '../actions/clients';
import groupsAction from '../actions/groups';
import therapistAction from '../actions/therapist';
import therapistsAction from '../actions/therapists';
// sagas
import {
  getTopics,
  getCustomerStatuses,
  getSpecificGroup,
  getGroupStatuses,
  getNumbersOfMembers,
  getLicenses,
  getState,
  getTherapistStatuses,
} from './options';
// helps
import showError from './showError';
import { getTherapistsGroup } from './admin/groups';
import { FilterOptionsRole } from '../../types/actions/common-types';

export function* sagaClientFiltersForm() {
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);

  yield put(clientAction.loaderModal(true));
  try {
    if (role === 'ADMIN') {
      yield all([call(getSpecificGroup), call(getTopics), call(getCustomerStatuses), call(getTherapistsGroup)]);
      yield put(clientAction.setOptionsIsReceived(FilterOptionsRole.Admin));
    } else {
      yield all([call(getSpecificGroup), call(getTopics), call(getCustomerStatuses)]);
      yield put(clientAction.setOptionsIsReceived(FilterOptionsRole.Therapist));
    }
  } catch (e) {
    showError();
  } finally {
    yield put(clientAction.loaderModal(false));
  }
}

export function* sagaGroupFiltersForm() {
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);

  yield put(groupsAction.loaderModal(true));
  try {
    if (role === 'ADMIN') {
      yield all([call(getTopics), call(getGroupStatuses), call(getNumbersOfMembers)]);
      yield put(groupsAction.setOptionsIsReceived(FilterOptionsRole.Admin));
    } else {
      yield all([call(getTopics), call(getGroupStatuses)]);
      yield put(groupsAction.setOptionsIsReceived(FilterOptionsRole.Therapist));
    }
  } catch (e) {
    showError();
  } finally {
    yield put(groupsAction.loaderModal(false));
  }
}

export function* sagaTherapistFiltersForm() {
  yield put(therapistAction.loaderModal(true));
  try {
    yield all([call(getTopics), call(getLicenses), call(getState), call(getTherapistStatuses)]);
    yield put(therapistsAction.setOptionsIsReceived(FilterOptionsRole.Admin));
  } catch (e) {
    showError();
  } finally {
    yield put(therapistAction.loaderModal(false));
  }
}
