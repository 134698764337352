import React, { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Spin } from 'antd';

// components
import AuthTemplate from '../../components/templates/authTemplate';
import RegistrationForm from '../../components/organisms/forms/registration';
// components texts
import {
  RegistrationAfterPreviewText,
  RegistrationPreviewText,
} from '../../components/atoms/texts/authTemplate/preview-text';
import { stateType } from 'types/types';

const RegistrationPage: React.FC = () => {
  const redirect_url = localStorage.getItem('role') ? `/login-${localStorage.getItem('role')}` : '/login-customer';

  const [textFooter] = useState('');
  const [textBeforeForm] = useState(
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <span>
        {`Already have an account?`}{' '}
        <Link to={redirect_url} className="text-link">
          Sign In
        </Link>
      </span>
      <span>{'©2022 Grouport Inc. All rights reserved.'}</span>
    </div>,
  );

  const status: string = useSelector((state: stateType) => state.assessment_questions.status, shallowEqual);

  const loader: boolean = useSelector((state: any) => state.auth.loader, shallowEqual);

  return (
    <div className="registration-page  custom-style">
      <AuthTemplate
        text_footer={textFooter}
        headerComponent={status == 'after' ? <RegistrationAfterPreviewText /> : <RegistrationPreviewText />}
        text_before_form={textBeforeForm}
        after_assessment={status == 'after' ? true : false}
      >
        <Spin tip="Loading..." spinning={loader}>
          <RegistrationForm />
        </Spin>
      </AuthTemplate>
    </div>
  );
};

export default RegistrationPage;
