/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { put, call, select } from 'redux-saga/effects';
// api
import { api } from '../../api/assesments';
// actions
import assesmentsAction from '../actions/assesments';
// help
import { notification } from 'antd';
import showError from './showError';
import appAction from '../actions/app';

export function* sagaGetAssesments(action) {
  yield put(assesmentsAction.loader(true));
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);

  try {
    const response = yield call(api.get, role.toLowerCase(), action.id);

    yield put(assesmentsAction.loader(false));

    yield put(assesmentsAction.set(response.data.data.items));
  } catch (e) {
    yield put(assesmentsAction.loader(false));
    console.log(e);
    showError();
  }
}

export function* sagaGetAssesment(action) {
  yield put(assesmentsAction.loaderModal(true));
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);

  try {
    const response = yield call(api.getByID, role.toLowerCase(), action.id);

    yield put(assesmentsAction.loaderModal(false));

    yield put(assesmentsAction.setByID(response.data.data.item));
  } catch (e) {
    yield put(assesmentsAction.loaderModal(false));
    console.log(e);
    showError();
  }
}
