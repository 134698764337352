import React from 'react';
import { Spin } from 'antd';
import { shallowEqual, useSelector } from 'react-redux';
// components
import EventDetails, { EventDetailsInfoTabs } from '../../organisms/eventDetails';
import BoxAccontContainer from '../boxAccountContainer';
import EventDetailsButtons from '../../molecules/events/detailsButtons';
// types
import { stateType } from '../../../types/types';
// styles
import './style.scss';

const EventDetailsContainer: React.FC<any> = () => {
  const loader: boolean = useSelector((state: stateType) => state.events.loader, shallowEqual);

  return (
    <Spin spinning={loader}>
      <div className="event-details-container">
        <div className="event-details-container__column">
          <EventDetails />
        </div>
        <div className="event-details-container__column">
          <BoxAccontContainer>
            <EventDetailsInfoTabs />
          </BoxAccontContainer>
          <EventDetailsButtons />
        </div>
      </div>
    </Spin>
  );
};

export default EventDetailsContainer;
