import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import queryString from 'query-string';
// actions
import { authActions } from '../../../../redux/actions/auth';
// components
import { NewInputPassword, NewInputPasswordRepeatPassword, SubmitButton } from '../../../molecules/inputsBlocks/login';
// style
import '../style.scss';

interface FormData {
  password: string;
  password_repeat: string;
}

const NewPasswordForm: React.FC = () => {
  const {
    watch,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  const history = useHistory();
  const dispatch = useDispatch();

  const redirect_url = '/login-customer';

  const newPasswordCallback = (password: string) => {
    const email = sessionStorage.getItem('email');
    if (email && password) {
      dispatch(authActions.login({ email, password }, history));
      sessionStorage.removeItem('email');
    } else {
      history.push(redirect_url);
    }
  };

  const onSubmit = (data: FormData) => {
    const hash = String(queryString.parse(history.location.search).token);
    const request = { ...data, hash: hash };
    dispatch(authActions.newPassword(request, () => newPasswordCallback(data.password)));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="form-edit">
      <NewInputPassword errors={errors.password ? errors.password.message : undefined} register={register} />
      <NewInputPasswordRepeatPassword
        errors={errors.password_repeat ? errors.password_repeat.message : undefined}
        register={register}
        watch={watch}
      />
      <SubmitButton name="Enter" />
    </form>
  );
};

export default NewPasswordForm;
