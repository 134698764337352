import React, { useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Spin, Tabs } from 'antd';
const { TabPane } = Tabs;
// components
import LongTermGoals from '../../molecules/lists/longTermGoals';
import SelfAwarenessLog from '../../molecules/lists/selfAwarenessLog';
// actions
import progressAction from '../../../redux/actions/progress';
// types
import { stateType } from 'types/types';
import { LogItemType, LongTermItemType } from 'types/actions/progress';
// styles
import './style.scss';

const ProgressTabs: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(progressAction.get_term());
    dispatch(progressAction.get_logs());
  }, []);

  const loader: boolean = useSelector((state: stateType) => state.progress.loaderModal, shallowEqual);
  const term: LongTermItemType[] = useSelector((state: stateType) => state.progress.term, shallowEqual);
  const logs: LogItemType[] = useSelector((state: stateType) => state.progress.logs, shallowEqual);

  return (
    <Spin spinning={loader}>
      {!loader && (
        <Tabs defaultActiveKey="goals" className="progress-tabs">
          <TabPane tab="Long-term goals" key="goals">
            <LongTermGoals term={term} loader={loader} />
          </TabPane>
          <TabPane tab="Self awareness log" key="log">
            <SelfAwarenessLog logs={logs} loader={loader} />
          </TabPane>
        </Tabs>
      )}
    </Spin>
  );
};

export default ProgressTabs;
