import {
  CreateIntakeConsultationAction,
  GetConsultationDatesAction,
  GetConsultationTimeAction,
  IntakeConsultationAction,
  LoaderIntakeAction,
  LoaderModalIntakeAction,
  SetIntakeDatesAction,
  SetIntakeTimesAction,
} from '../../types/actions/intake_consultation';
import { ActionCallback } from '../../types/actions/common-types';

const intakeConsultationAction: IntakeConsultationAction = {
  getTime(data?: any, callback?: ActionCallback, errorsCallback?: (e: any) => void): GetConsultationTimeAction {
    return {
      type: 'GET_CONSULTATION_TIME',
      data,
      callback,
      errorsCallback,
    };
  },

  getDates(data?: any, callback?: ActionCallback, errorsCallback?: (e: any) => void): GetConsultationDatesAction {
    return {
      type: 'GET_CONSULTATION_DATES',
      data,
      callback,
      errorsCallback,
    };
  },

  create(data?: any, callback?: ActionCallback): CreateIntakeConsultationAction {
    return {
      type: 'CREATE_INTAKE_CONSULTATION',
      data,
      callback,
    };
  },

  setTimes(times?: any): SetIntakeTimesAction {
    return {
      type: 'SET_CONSULTATION_TIME',
      payload: {
        times,
      },
    };
  },

  setDates(dates?: any): SetIntakeDatesAction {
    return {
      type: 'SET_CONSULTATION_DATES',
      payload: {
        dates,
      },
    };
  },

  loader(status: boolean): LoaderIntakeAction {
    return {
      type: 'INTAKE_CONSULTATION_LOADER',
      payload: {
        loader: status,
      },
    };
  },

  loaderModal(status: boolean): LoaderModalIntakeAction {
    return {
      type: 'INTAKE_CONSULTATION_LOADER_MODAL',
      payload: {
        loaderModal: status,
      },
    };
  },
};

export default intakeConsultationAction;
