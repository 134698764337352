import { ActionCallback, errorsCallback } from '../../types/actions/common-types';
import {
  consultationActionType,
  ConsultationCountriesAction,
  ConsultationDataAction,
  ConsultationGendersAction,
  ConsultationLoaderAction,
  ConsultationReasonsAction,
  ConsultationStateAction,
  ConsultationStatusesAction,
  ConsultationTopicsAction,
  GetConsultationOptionsAction,
  Mapping,
  SubmitConsultationAction,
} from '../../types/actions/consultation';

const consultationAction: consultationActionType = {
  submit(data: any, errorsCallback: errorsCallback, callback?: ActionCallback): SubmitConsultationAction {
    return {
      type: 'CONSULTATION_FORM_SUBMIT',
      data,
      errorsCallback,
      callback,
    };
  },
  get_options(): GetConsultationOptionsAction {
    return {
      type: 'GET_CONSULTATION_FORM_OPTIONS',
    };
  },
  reasons: (reasons: Mapping[]): ConsultationReasonsAction => {
    return {
      type: 'CONSULTATION_FORM_REASONS',
      payload: {
        reasons,
      },
    };
  },
  topics: (topics: Mapping[]): ConsultationTopicsAction => {
    return {
      type: 'CONSULTATION_FORM_TOPICS',
      payload: {
        topics,
      },
    };
  },
  statuses: (statuses: Mapping[]): ConsultationStatusesAction => {
    return {
      type: 'CONSULTATION_FORM_STATUSES',
      payload: {
        statuses,
      },
    };
  },
  genders: (genders: Mapping[]): ConsultationGendersAction => {
    return {
      type: 'CONSULTATION_FORM_GENDERS',
      payload: {
        genders,
      },
    };
  },
  state: (state: any): ConsultationStateAction => {
    return {
      type: 'CONSULTATION_FORM_STATE',
      payload: {
        state,
      },
    };
  },

  countries: (countries: Mapping[]): ConsultationCountriesAction => {
    return {
      type: 'CONSULTATION_FORM_COUNTRIES',
      payload: {
        countries,
      },
    };
  },

  loader(loader: boolean): ConsultationLoaderAction {
    return {
      type: 'CONSULTATION_FORM_LOADER',
      payload: {
        loader,
      },
    };
  },

  setData(consultationData: any): ConsultationDataAction {
    return {
      type: 'CONSULTATION_FORM_DATA',
      payload: {
        consultationData,
      },
    };
  },
};

export default consultationAction;
