/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { call, put } from 'redux-saga/effects';
// actions
import intakeConsultationAction from '../actions/intake_consultation';
import userActions from '../actions/user';
// configs
import mappingConsultation from '../../api/mapping/response/consultation';
import showError from './showError';
import { mapping } from '../../api/mapping/response/user';
// api
import { apiAcuity } from '../../api/intakeConsultation';

export function* sagaGetConsultationTime(action) {
  yield put(intakeConsultationAction.loader(true));
  try {
    const response = yield call(apiAcuity.getTimes, action.data);

    yield put(intakeConsultationAction.setTimes(mappingConsultation.buildIntakeTime(response.data.data.item)));

    if (action.callback) {
      action.callback();
    }
  } catch (e) {
    if (action.errorsCallback) {
      action.errorsCallback({ message: e.response.data.title });
    }
  } finally {
    yield put(intakeConsultationAction.loader(false));
  }
}

export function* sagaGetConsultationDates(action) {
  yield put(intakeConsultationAction.loaderModal(true));
  try {
    const response = yield call(apiAcuity.getDates, action.data);

    yield put(intakeConsultationAction.setDates(response.data.data.item));

    if (action.callback) {
      action.callback();
    }
  } catch (e) {
    showError();
  } finally {
    yield put(intakeConsultationAction.loaderModal(false));
  }
}

export function* sagaPostAppointments(action) {
  yield put(intakeConsultationAction.loader(true));
  try {
    const response = yield call(apiAcuity.created, action.data);

    yield userActions.state(mapping.stateFlowBuild(response.data.data.item.stateFlow, response.data.data.item));

    if (action.callback) {
      action.callback();
    }
  } catch (e) {
    showError();
  } finally {
    yield put(intakeConsultationAction.loader(false));
  }
}
