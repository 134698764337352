import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
// components
import MainTemplate from '../../../components/templates/mainTemplate';
import ClientsTable from '../../../components/organisms/tables/admin/clients';
import TableContainer from '../../../components/containers/tableContainer';
import { HeaderPageContainer } from '../../../components/containers/pageContainer';
import NavigationContainer from '../../../components/containers/navigationContainer';
// actions
import appAction from '../../../redux/actions/app';
import clientsAction from '../../../redux/actions/clients';
// types
import { FilterOptionsRole } from '../../../types/actions/common-types';

const ClientsPage: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(appAction.page('All Clients'));
    dispatch(clientsAction.get());

    return () => {
      dispatch(clientsAction.setOptionsIsReceived(FilterOptionsRole.Empty));
    };
  }, []);

  return (
    <MainTemplate>
      <HeaderPageContainer>
        <h2 className="text-page-header">All Clients</h2>
      </HeaderPageContainer>
      <NavigationContainer name="admin" table="clients" />
      <TableContainer>
        <ClientsTable />
      </TableContainer>
    </MainTemplate>
  );
};

export default ClientsPage;
