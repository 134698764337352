import React, { useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
// components
import SimpleHeader from '../../components/organisms/headers/simple/simple-header';
import ConfirmChoiceGroups from '../../components/organisms/changeGroup/confirmChoice';
// types
import { stateType } from '../../types/types';

const ConfirmChoice: React.FC = () => {
  const groups: any = useSelector((state: stateType) => state.choice_group.item, shallowEqual);
  const history = useHistory();

  useEffect(() => {
    _.isEmpty(groups.group) && history.push('/');
  }, []);

  const done = (): void => {
    history.push('/');
  };

  return (
    <div className="choice-group-page">
      <div className="change-group-template">
        <SimpleHeader />
        <div className="change-group-template__body">
          <div className="change-group-template__confirm">
            {!_.isEmpty(groups.group) && <ConfirmChoiceGroups groups={groups} done={done} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmChoice;
