import React, { FC } from 'react';
import { Tabs } from 'antd';
import Moment from 'react-moment';
import { shallowEqual, useSelector } from 'react-redux';
// components
import BeginsIn from '../../molecules/beginsIn';
import EditEvent from '../modals/editEvent';
import EventTopics from '../../molecules/events/topics';
import { EventEditMembers, EventMembers } from '../cards/members';
// styles
import './style.scss';
// types
import { stateType } from '../../../types/types';
import { AccountEditBlock } from '../account/customer';

const EventDetails: FC = () => {
  const event: any = useSelector((state: stateType) => state.events.event, shallowEqual);
  return (
    <div className="event-details">
      <div className="event-details__image">
        {event?.picture?.url ? (
          <img src={event.picture.url} alt="event image" />
        ) : (
          <div className="event-details__false-image" />
        )}
        <div className="event-details__start-time">
          <Moment format="MMM Do, YYYY">{event?.start}</Moment> <Moment format="h:mma">{event?.start}</Moment> ET
        </div>
      </div>
      <div className="event-details__title-header">
        <div
          className={`event-details__session-type event-details__session-type_${event?.communityEventType?.name?.toLowerCase()}`}
        >
          <span>{event?.communityEventType?.name}</span>
        </div>
        <div className="event-details__label">{event?.maxAttendees} Attendees Max</div>
      </div>
      <div
        className="event-details__main"
        style={{
          wordWrap: 'break-word',
          whiteSpace: 'pre-wrap',
        }}
      >
        <h3 className="event-details__title">{event?.title}</h3>
        <h4 className="event-details__subtitle">{event?.subtitle}</h4>
        {/*<h4 className="event-details__therapist">Led by Dr. Matthew Mandelbaum</h4>*/}
        <div className="event-details__begins">
          <BeginsIn start={event?.start} />
        </div>
        <div className="event-details__info-card">
          <div className="event-details__info-card__item">
            <div className="event-details__item-wrap">
              <p className="event-details__info-card__label">Title</p>
              <EditEvent id={event?.id} isDetails />
            </div>
            <p className="event-details__info-card__value">{event?.title}</p>
          </div>
          <div className="event-details__info-card__item">
            <p className="event-details__info-card__label">Subtitle</p>
            <p className="event-details__info-card__value">{event?.subtitle}</p>
          </div>
          <div className="event-details__info-card__item">
            <p className="event-details__info-card__label">{`Topic${event?.groupTopics?.length > 1 ? '(s)' : ''}`}</p>
            <p className="event-details__info-card__value">
              <EventTopics topics={event?.groupTopics} />
            </p>
          </div>
          <div className="event-details__info-card__item">
            <p className="event-details__info-card__label">Date</p>
            <p className="event-details__info-card__value">
              <Moment format="dddd MM/D/YY">{event?.start}</Moment>
            </p>
          </div>
          <div className="event-details__info-card__item">
            <p className="event-details__info-card__label">Start time</p>
            <p className="event-details__info-card__value">
              <Moment format="h:mma">{event?.start}</Moment>
            </p>
          </div>
          <div className="event-details__info-card__item">
            <p className="event-details__info-card__label">Time Length</p>
            <p className="event-details__info-card__value">{event?.timeLength?.name}</p>
          </div>
          <div className="event-details__info-card__item">
            <p className="event-details__info-card__label">Therapist</p>
            <p className="event-details__info-card__value">
              {' '}
              {event?.leader?.firstname ? `${event?.leader?.firstname} ` : ''}
              {event?.leader?.lastname ? event?.leader?.lastname : ''}
            </p>
          </div>
          <div className="event-details__info-card__item">
            <p className="event-details__info-card__label">Type of event</p>
            <p className="event-details__info-card__value">{event?.communityEventType?.name}</p>
          </div>
          <div className="event-details__info-card__item">
            <p className="event-details__info-card__label"># of max attendees:</p>
            <p className="event-details__info-card__value">{event?.maxAttendees} Attendees</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventDetails;

export const EventDetailsInfoTabs: React.FC<any> = () => {
  const event: any = useSelector((state: stateType) => state.events.event, shallowEqual);
  const { TabPane } = Tabs;

  const callback = (key: any) => {
    console.log(key);
  };

  return (
    <Tabs defaultActiveKey="1" onChange={callback} className="account-tabs">
      <TabPane tab="Registered Attendees" key="details" className="account-tabs__item">
        <div className="account-tabs__item-info">
          <AccountEditBlock
            title={`Registered Attendees ${event?.members?.length ? `(${event?.members?.length})` : ''}`}
          />
          <EventEditMembers members={event?.members} isEvent />
        </div>
        <EventMembers members={event?.members} />
      </TabPane>
    </Tabs>
  );
};
