import React from 'react';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';

// styles
import './style.scss';
import eventAnalytics from '../../../../redux/actions/analytics';
import { useDispatch } from 'react-redux';

const LongTermGoals: React.FC<any> = ({ term, loader }: any) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const onClickGoals = () => {
    history.push('/progress/term');
    dispatch(eventAnalytics.analytics('add-goal-first'));
  };
  return (
    <div className="term-container">
      <div className="term-container__header">
        Write down your long term goals, and continually refer back to them to see your progress and understand the
        short term objectives you can tackle to reach them over time.
      </div>
      {!loader && term.length > 0 && <TermList term={term} />}
      {!loader && (
        <span onClick={onClickGoals} className="term-container__link">
          {term.length === 0 ? 'Add goals' : 'See your goals'}
        </span>
      )}
    </div>
  );
};

export default LongTermGoals;

const TermList = ({ term }: any) => {
  return (
    <div className="term-container__items">
      <div className="term-container__title">My long-term goals</div>
      <ul className="term-container__list">
        {term?.slice(0, 2).map((item: any, index: number) => (
          <li key={index} className={cn('term-container__item', { done: item.completed })}>
            {index + 1}. <span>{item.goal}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};
