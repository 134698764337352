import React, { useRef } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Table } from 'antd';
// actions
import clientsAction from '../../../../../redux/actions/clients';
// configs
import { columns } from './column';
import useDidMountEffect from '../../../../../configs/hooks/useDidMountEffect';
// style
import './../../style.scss';
import eventAnalytics from '../../../../../redux/actions/analytics';

const ClientsTable: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const scrollToRef = useRef<any>();

  const loader = useSelector((state: any) => state.clients.loader, shallowEqual);
  const items = useSelector((state: any) => state.clients.items, shallowEqual);
  const params = useSelector((state: any) => state.clients.params, shallowEqual);
  const count = useSelector((state: any) => state.clients.count, shallowEqual);
  const rowI = useSelector((state: any) => state.clients.rowIndex, shallowEqual);

  useDidMountEffect(() => {
    if (items.length && scrollToRef.current) {
      scrollToRef.current.scrollIntoView();
      clientsAction.rowIndex(undefined);
    }
  }, [loader]);

  return (
    <Table
      rowKey={'id'}
      className="clients-table"
      columns={columns}
      dataSource={loader ? undefined : items}
      loading={loader}
      scroll={{ y: 570 }}
      locale={{ emptyText: <div> </div> }}
      pagination={{
        style: { visibility: `${count <= 10 ? 'hidden' : 'visible'}` },
        size: 'small',
        showQuickJumper: true,
        defaultPageSize: 10,
        pageSize: params.per_page,
        current: params.page,
        total: count,
        pageSizeOptions: ['10', '20', '25', '30', '50', '100'],
        position: ['bottomCenter'],
        onChange: async (page, pageSize) => {
          try {
            await clientsAction.params({ ...params, page: page, per_page: pageSize });
            dispatch(clientsAction.get());
          } catch (e) {
            console.log(e);
          }
        },
      }}
      onRow={(record, rowIndex) => {
        return {
          ref: rowIndex === rowI ? scrollToRef : undefined,
          onClick: () => {
            dispatch(eventAnalytics.analytics('view-client', { userId: record?.id }));
            history.push(`/clients/${record.id}`);
            clientsAction.rowIndex(rowIndex);
          },
        };
      }}
    />
  );
};

export default ClientsTable;
