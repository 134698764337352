import React, { FC } from 'react';
import cn from 'classnames';
import MoreDropdown from '../../../../molecules/popovers/MoreDropdown';
import { momentOrdinal } from '../../../../../tools/helpFunctions';
import skillsIcon from '../../../../../assets/image/dropdown/skills.svg';
import manIcon from '../../../../../assets/image/dropdown/man.svg';
import moment from 'moment-timezone';
import calendarIcon from '../../../../../assets/image/dropdown/calendar.svg';
import timeIcon from '../../../../../assets/image/dropdown/time.svg';
import Moment from 'react-moment';
import { MembersMiniList } from '../../../cards/members';
import BeginsIn from '../../../../molecules/beginsIn';
import { SessionButton } from './index';

export const NextCommunityEvent: FC<any> = ({ item, type, hideMembers, isBg, isPaused }) => {
  const { start, end, groupSessionHistoryMarkers } = item;

  const actions = [
    {
      text: item?.title,
      icon: skillsIcon,
    },
    {
      text: item?.leader?.firstname
        ? item?.leader?.firstname
        : '' + ' ' + item?.leader?.lastname
        ? item?.leader?.lastname
        : '',
      icon: manIcon,
    },
    {
      text: moment(start).tz('America/New_York').format('dddd, MMMM Do'),
      icon: calendarIcon,
    },
    {
      text: `${moment(start).tz('America/New_York').format('h:mm A')} - ${moment(end)
        .tz('America/New_York')
        .format('h:mm A')} ET`,
      icon: timeIcon,
    },
  ];

  return (
    <div className={cn('session-item', { 'session-item_bg': isBg })}>
      <div
        className={cn('session-item__status-history', {
          rescheduled:
            groupSessionHistoryMarkers?.rescheduleSessionHistory && !groupSessionHistoryMarkers?.cancelSessionHistory,
          cancel: groupSessionHistoryMarkers?.cancelSessionHistory,
        })}
      >
        {groupSessionHistoryMarkers?.rescheduleSessionHistory &&
          !groupSessionHistoryMarkers?.cancelSessionHistory &&
          'Rescheduled'}
        {groupSessionHistoryMarkers?.cancelSessionHistory && 'Cancelled'}
      </div>
      <div className="session-item__wrap">
        <div className="session-item__avatar">
          {item.picture?.url ? (
            <img src={item.picture?.url} alt="session avatar" className="session-item__avatar-img" />
          ) : (
            <div className="session-item__home-avatar-txt">{item?.leader?.therapist1}</div>
          )}
          {/*TODO add conditional and special property*/}
          <div
            className={cn('session-item__event-type', { webinar: true, session: false })}
            style={{
              backgroundColor: item?.communityEventType?.name === 'Webinar' ? '#82c43c' : '#e2351e',
              borderBottomLeftRadius: 10,
              borderBottomRightRadius: 10,
            }}
          >{`Community ${item?.communityEventType?.name}`}</div>
        </div>
        <div className="session-item__body">
          <div style={{ position: 'absolute', top: 20, right: 20 }}>
            <MoreDropdown actions={actions} />
          </div>
          <div className="session-item__header">
            <div className="session-item__title">{item?.title}</div>
          </div>

          <div className="session-item__time-block">
            <div className="session-item__item">
              <div className="session-item__label">Date:</div>
              <div className="session-item__value">
                <Moment format="dddd, MMMM">{item.start}</Moment> <Moment format="D">{item.start}</Moment>
                <sup>{momentOrdinal(item.start)}</sup>
              </div>
            </div>

            <div className="session-item__item">
              <div className="session-item__label">Time:</div>
              <div className="session-item__value">
                <Moment format="h:mm A">{item.start}</Moment>
                {' - '}
                <Moment format="h:mm A">{item.end}</Moment> ET
              </div>
            </div>
          </div>

          {!hideMembers && item.members && (
            <div className="session-item__members">
              <MembersMiniList members={item.members} />
            </div>
          )}
          {!groupSessionHistoryMarkers?.cancelSessionHistory && (
            <div className="session-item__footer footer-top">
              <div>
                <BeginsIn start={item.start} status={item.status} />
              </div>
              {type && !isPaused && (
                <SessionButton item={item} name={type === 'join' ? 'Join this session' : 'Start session'} type={type} />
              )}
            </div>
          )}
        </div>
      </div>
      {!groupSessionHistoryMarkers?.cancelSessionHistory && (
        <div className="session-item__footer footer-bottom">
          <div>
            <BeginsIn start={item.start} status={item.status} />
          </div>
          {type && !isPaused && (
            <SessionButton item={item} name={type === 'join' ? 'Join this session' : 'Start session'} type={type} />
          )}
        </div>
      )}
    </div>
  );
};
