import React from 'react';
import { useHistory } from 'react-router-dom';
// components
import { UserAvatar } from '../../../../atoms/forTable';
// styles
import '../../style.scss';
// config
import { timeConvertor, week_days } from '../../../../../configs/tables/admin/clients';

export const ItemName: React.FC = (props: any) => {
  const { row, link } = props;
  const history = useHistory();

  return (
    <div
      className="table-item-box table-item-box_name"
      onClick={() => {
        history.push(`/${link}/${row.id}`);
      }}
    >
      <UserAvatar
        first_name={row.firstname}
        last_name={row.lastname}
        color={row.color}
        url={row?.avatar ? row.avatar.url : false}
      />
      <span className="table-item-box_name__value table-item-box__value-row-limit">{row.name}</span>
    </div>
  );
};

export const ItemState: React.FC = (props: any) => {
  const { row } = props;

  return (
    <div className="table-item-box">
      <span className="table-item-box__value">
        {row.state.length > 1
          ? row.state.map((item: any, i: number) => {
              if (i < row.state.length - 1) {
                return (
                  <span title={item.name} key={item.id}>
                    {`${item.code}`},&nbsp;
                  </span>
                );
              } else {
                return (
                  <span title={item.name} key={item.id}>
                    {item.code}
                  </span>
                );
              }
            })
          : row.state[0]?.name}
      </span>
    </div>
  );
};

export const ItemActiveGroups: React.FC = (props: any) => {
  const { row } = props;

  return (
    <div className="table-item-box table-item-box_info-cart">
      <div className="table-item-box__value">
        {row.activeGroupsAmount}
        {row.activeGroups.length ? (
          <ul className="table-item-box_info-cart_list table-item-box_info-cart_list_bg">
            {row.activeGroups.map((item: any, i: number) => (
              <li className="table-item-box_info-cart_list-item" key={i}>
                {`${item.groupTopic.name} ${week_days[item.dayOfWeek - 1]} ${timeConvertor(item.start)}-${timeConvertor(
                  item.end,
                  'type-time',
                )} ET`}
              </li>
            ))}
          </ul>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export const ItemPossibleGroups: React.FC = (props: any) => {
  const { row } = props;
  return (
    <div className="table-item-box table-item-box_info-cart">
      <div className="table-item-box__value">
        {row.posibleGroupsAmount}
        {row.posibleGroups.length ? (
          <ul className="table-item-box_info-cart_list">
            {row.posibleGroups.map((item: any, i: number) => (
              <li className="table-item-box_info-cart_list-item" key={i}>
                {item.name}
              </li>
            ))}
          </ul>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export const ItemLicensure: React.FC = (props: any) => {
  const { row } = props;
  return (
    <div className="table-item-box">
      <span className="table-item-box__value table-item-box_licensure">{row.licensure}</span>
    </div>
  );
};
