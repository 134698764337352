import React, { useCallback, useEffect, useMemo } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import useUserIsHost from '../../../hooks/useUserIsHost/useUserIsHost';
import useFilesContext from '../../../hooks/useFilesContext/useFilesContext';
import * as filestack from 'filestack-js';
import { useParams } from 'react-router-dom';
import CloseIcon from '../../../icons/CloseIcon';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { useAppState } from '../../../state';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fileWindowContainer: {
      background: '#FFFFFF',
      zIndex: 9,
      display: 'flex',
      flexDirection: 'column',
      borderLeft: '1px solid #E4E7E9',
      borderRadius: 16,
      padding: 10,
      [theme.breakpoints.down('sm')]: {
        position: 'fixed',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: 100,
        padding: '5px',
      },
    },
    hide: {
      display: 'none',
    },
    header: {
      padding: '8px',
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '24px',
    },
    closeButton: {
      float: 'right',
      width: 30,
      height: 30,
      backgroundColor: '#828282',
      borderRadius: '50%',
      padding: '7px',
    },
    uploadFileButton: {
      padding: '8px',
      borderRadius: '4px',
      backgroundColor: '#E19D89',
      color: '#ffffff',
      width: '50%',
      margin: '10px 0 10px 50%',
    },
    fileItem: {
      padding: '8px 14px',
      borderRadius: 12,
      margin: '2px',
      '& a': {
        color: '#333333',
      },
      '&:hover': {
        backgroundColor: '#F2F2F2',
      },
    },
  }),
);

export default function FileWindow() {
  const cssClasses = useStyles();
  const isHost = useUserIsHost();
  const { isFileWindowOpen, setIsFileWindowOpen, upload, loadFileList, fileList } = useFilesContext();
  const filestackApiKey = process.env.REACT_APP_FILESTACK_API_KEY ? process.env.REACT_APP_FILESTACK_API_KEY : '';
  const filestackClient = filestack.init(filestackApiKey);

  const fileEndpoint = process.env.REACT_APP_API_URL + 'twilio/file_from_filestack';
  const fileDownloadURL = process.env.REACT_APP_FILE_DOWNLOAD_URL;
  const JWTtoken = localStorage.getItem('token');
  const { URLRoomName } = useParams<{ URLRoomName: string }>();
  const { meetingType } = useAppState();

  const { localTracks } = useVideoContext();

  const fileUploadFinished = useCallback((fileData) => {
    console.log(fileData);
    fetch(fileEndpoint, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${JWTtoken}`,
      },
      body: JSON.stringify({
        file_name: fileData.filename,
        file_url: fileData.url,
        room_name: URLRoomName,
        meeting_type: meetingType,
      }),
    })
      .then((response) => response.json())
      .then((responseData) => {
        console.log(responseData);
        loadFileList();
        localTracks
          .filter((track) => track.kind == 'data')
          .forEach((track) => {
            //@ts-ignore
            track.send(JSON.stringify({ command: 'file-uploaded' }));
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const filestackPickerOptions = {
    uploadConfig: {
      tags: {
        foo: 'bar',
      },
    },
    onFileUploadFinished: fileUploadFinished,
    fromSources: ['local_file_system', 'dropbox', 'onedrive', 'googledrive'],
  };

  const picker = filestackClient.picker(filestackPickerOptions);

  const openPicker = useCallback(() => {
    picker.open();
  }, [filestackClient, picker]);

  const handleDrop = useCallback((e) => {
    if (e.dataTransfer.items) {
      for (let i = 0; i < e.dataTransfer.items.length; i++) {
        const file = e.dataTransfer.items[i].getAsFile();
        if (file) {
          upload(file);
        } else {
          console.log('no file', e.dataTransfer.items[i]);
          e.dataTransfer.items[i].getAsString((text: string) => {
            console.log(text);
          });
        }
      }
    }
    if (e.dataTransfer.files) {
      for (let i = 0; i < e.dataTransfer.files.length; i++) {
        console.log('file', e.dataTransfer.files[i]);
      }
    }
    e.preventDefault();
    e.stopPropagation();
    return false;
  }, []);

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    return false;
  }, []);

  const closeFileWindow = useCallback(() => {
    setIsFileWindowOpen(false);
  }, []);

  useEffect(() => {
    loadFileList();
  }, []);

  const download = (filename: string) => {
    const url = fileDownloadURL ? fileDownloadURL : '';
    fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${JWTtoken}`,
      },
      body: JSON.stringify({
        filename: filename,
      }),
    })
      .then((response) => {
        if (response.body) {
          const reader = response.body.getReader();
          return new ReadableStream({
            start(controller) {
              return pump();
              function pump(): any {
                return reader.read().then(({ done, value }) => {
                  // When no more data needs to be consumed, close the stream
                  if (done) {
                    controller.close();
                    return;
                  }
                  // Enqueue the next data chunk into our target stream
                  controller.enqueue(value);
                  return pump();
                });
              }
            },
          });
        }
      })
      .then((stream) => {
        return new Response(stream);
      })
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        return URL.createObjectURL(blob);
      })
      .then((url) => {
        console.log(url);
        const link = document.createElement('a');
        link.href = url;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <aside className={clsx(cssClasses.fileWindowContainer, { [cssClasses.hide]: !isFileWindowOpen })}>
      <div className={cssClasses.header}>
        Files
        <button className={cssClasses.closeButton} onClick={closeFileWindow}>
          <CloseIcon />
        </button>
      </div>
      {isHost ? (
        <button onClick={openPicker} className={cssClasses.uploadFileButton}>
          Upload Files
        </button>
      ) : (
        ''
      )}
      <div>
        {fileList &&
          fileList.map((item) => {
            return (
              <div key={item.id} className={cssClasses.fileItem}>
                <a
                  download={fileDownloadURL + item.url}
                  rel="noreferrer"
                  target="_blank"
                  href={fileDownloadURL + item.url}
                  onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    download(item.url);
                    return false;
                  }}
                >
                  {item.name}
                </a>
              </div>
            );
          })}
      </div>
    </aside>
  );
}
