import React, { FC, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
// types
// actions
import changeGroupActions from 'redux/actions/cancel_group';
import cancelGroupActions from 'redux/actions/cancel_group';
// components
import CloseChangeBtn from 'components/molecules/groupQuestions/CloseQuestion';
// icon
import icon from 'assets/image/group/back.svg';
import eventAnalytics from '../../../../redux/actions/analytics';

const CancelBefore: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(changeGroupActions.progress(0));
  }, []);

  const nextAnswer = useCallback(() => dispatch(changeGroupActions.next()), []);

  const onClick = () => {
    dispatch(eventAnalytics.analytics('cancel-subscription', { confirm: true }));
    dispatch(cancelGroupActions.cancelRenewal('cancel-subscription', nextAnswer));
  };

  const onCancel = () => {
    dispatch(eventAnalytics.analytics('cancel-subscription', { confirm: false }));
    history.push('/');
  };

  return (
    <div className="change-group-container">
      <div>
        <CloseChangeBtn />
      </div>
      <div className="change-group-container__back-wrap">
        <img src={icon} alt="logo" />
      </div>
      <div className="change-group-container__main-title">Before you go</div>
      <div className="change-group-container__desc-text">
        We’re sorry to see you go, are you sure you want to cancel your subscription?
      </div>
      <button className="btn-form" onClick={onCancel}>
        No, I’ll continue therapy
      </button>
      <div className="change-group-container__cancel" onClick={onClick}>
        Yes, cancel my subscription
      </div>
    </div>
  );
};

export default CancelBefore;
