import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
// actions
import { authActions } from '../../../../redux/actions/auth';
import { mapping } from '../../../../api/mapping/request/auth';
// components
import {
  RegistrationField,
  RegistrationInputPassword,
  RegistrationInputRepeatPassword,
  RegistrationOptions,
  RegistrationSubmitButton,
} from '../../../molecules/inputsBlocks/registration';
// helpers
import { yupResolver } from '@hookform/resolvers/yup';
import { registrationSchema } from '../../../../tools/validations';
// style
import '../style.scss';
import { setFormErrors } from '../../../../tools/helpFunctions';
import { IErrors } from '../../../../types/actions/common-types';
import { stateType } from 'types/types';

interface FormData {
  firstname: string;
  lastname: string;
  email: string;
  password: string;
  password_repeat: string;
  checker: boolean;
  assessment: boolean;
}

const FormRegistration: React.FC = () => {
  const [checker, setChecker] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    control,
    trigger,
    setError,
  } = useForm<FormData>({ mode: 'onBlur', resolver: yupResolver(registrationSchema) });

  const dispatch = useDispatch();
  const history = useHistory();
  const status: string = useSelector((state: stateType) => state.assessment_questions.status, shallowEqual);

  const onSubmit = (data: FormData) => {
    const request: FormData = {
      ...data,
      firstname: data.firstname.trim(),
      lastname: data.lastname.trim(),
      email: data.email.trim(),
      assessment: status == 'after',
    };

    const setErrors = (errors: Array<IErrors>) => setFormErrors(setError, errors);
    console.log('submit dispath');
    dispatch(
      authActions.registration(mapping.registrationBuild(request), setErrors, () =>
        setTimeout(() => {
          history.push('/consultation');
        }, 100),
      ),
    );
  };

  const actionChecked = (value: boolean) => {
    setChecker(value);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="form-edit">
      <RegistrationField name="firstname" label="First Name" errors={errors.firstname?.message} register={register} />
      <RegistrationField name="lastname" label="Last Name" errors={errors.lastname?.message} register={register} />
      <RegistrationField name="email" label="Email" errors={errors.email?.message} register={register} />
      <RegistrationInputPassword
        errors={errors.password?.message}
        repeatError={errors?.password_repeat?.message}
        trigger={trigger}
        register={register}
        watch={watch}
      />
      <RegistrationInputRepeatPassword errors={errors.password_repeat?.message} register={register} watch={watch} />
      <RegistrationOptions
        actionChecked={actionChecked}
        watch={watch}
        errors={errors}
        register={register}
        control={control}
      />
      <RegistrationSubmitButton />
    </form>
  );
};

export default FormRegistration;
