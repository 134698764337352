/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { AxiosResponse } from 'axios';
// config
import http from '../instance';

export const api = {
  get: async (role: string, params: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.get(`${role}/therapists${params ? `?${params}` : ''}`);
  },

  getByID: async (id: string, role: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.get(`${role}/therapist/${id}/account`);
  },

  add: async (data: any, role: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.post(`${role}/therapist`, data);
  },

  getInvoice: async (data: any): Promise<AxiosResponse> => {
    const api = await http();
    const newData = { start: data.data.start, end: data.data.end, format: data.type };
    return api.post(`/file/export/${data.id.id}`, newData);
  },

  edit: async (id: any, data: any, role: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.patch(`${role}/therapist/${id}/account`, data);
  },

  editNames: async (id: any, data: any, role: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.patch(`${role}/therapist/${id}/profile`, data);
  },

  list: async (role: string, id: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.get(`${role}/customer/${id}/change-groups?all`);
  },

  set_zoom: async (role: string, id: any, data: any): Promise<AxiosResponse> => {
    const api = await http();
    return api.patch(`${role}/therapist/${id}/zoom-data`, data);
  },

  get_zoom_clients: async (role: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.get(`${role}/zoom/clients?scope=free`);
  },

  resetPassword: async (id: any, data: any, role: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.patch(`${role}/therapist/${id}/create-set-password-notification`, data);
  },

  groups: async (role: string, id: any): Promise<AxiosResponse> => {
    const api = await http();
    return api.get(`${role}/therapist/${id}/groups?scope=running`);
  },

  sessionsHistory: async (role: string, id: any, params?: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.get(`${role}/therapist/${id}/group-session-histories${params ? `?${params}` : ''}`);
  },

  archive: async (id: any, role: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.delete(`${role}/therapist/${id}/archive`);
  },

  delete: async (id: any, role: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.delete(`${role}/therapist/${id}/delete`);
  },

  enableSms: async (id: string, enable: boolean): Promise<AxiosResponse> => {
    const api = await http();
    return api.patch(`admin/therapist/${id}/enable-sms?enable_sms=${enable}`);
  },

  enableEmails: async (id: string, enable: boolean): Promise<AxiosResponse> => {
    const api = await http();
    return api.patch(`admin/therapist/${id}/enable-emails?enable_emails=${enable}`);
  },
};
