import React from 'react';

export type exampleValue = {
  message: string;
  status: 'success' | 'error';
};

export enum QuestionType {
  MultiQuestion = 'multi',
  BooleanQuestion = 'boolean',
  SingleQuestion = 'single',
  ArbitraryQuestion = 'arbitrary',
  MultiWithArbitrary = 'multi_with_arbitrary',
}

export interface IAnswer {
  readonly id: number;
  answer: string;
}

export interface IQuestion {
  additions: { accountLabel: string; placeholder: string; subQuestion?: string; disclaimerText?: string };
  answerType: QuestionType;
  answers: Array<IAnswer>;
  defaultValue: null | { answerId?: number; answerIds?: number; answerText?: string };
  readonly id: number;
  number: number;
  question: string;
  questionType: QuestionType;
  serialNumber: number;
  maxQuestionNumber: number;
}

export interface ISessionItem {
  id: number;
  end: string;
  joinUrl?: null | string;
  startUrl?: null | string;
  medicalGroup: {
    id: number;
    groupTopic: {
      id: number;
      name: string;
    };
    avatar: {
      url: string;
    } | null;
    therapist: {
      firstname: string;
      lastname: string;
      title: {
        id: any;
        name: string;
      } | null;
      licenses:
        | [
            {
              id: any;
              name: string;
            },
          ]
        | null;
    };
  };
  start: string;
  status: { value: number; name: string };
  members?: Array<any>;
  groupSessionHistoryMarkers?: {
    cancelSessionHistory: boolean;
    rescheduleSessionHistory: boolean;
  };
  twilioRoomName?: string;
  coHost?: {
    id: number;
    firstname: string;
    lastname: string;
  };
}

export interface IEvent {
  id: number;
  end: string;
  joinUrl?: null | string;
  startUrl?: null | string;
  picture: {
    url: string | null;
  } | null;
  start: string;
  title: string;
}

export interface IActionListItem {
  text: string | React.ReactNode;
  icon: string;
  link?: string;
  onClick?: (...args: Array<any>) => void;
}

export interface IMember {
  firstname: string;
  clientId?: number;
  id?: number;
  lastname: string;
  name?: string;
  color?: string;
}

export interface IStripeItem {
  amount: number;
  productName: string;
  productPeriodName: string;
  saveAmount: number | null;
  saveAmountPercent: string | null;
  stripePriceId: string;
  weeklyAmount: number;
}
