/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

export const mapping = {
  userBuild(data, role) {
    return {
      username: `${data.firstname} ${data.lastname}`,
      firstname: data.firstname,
      lastname: data.lastname,
      title: data?.title ? data?.title : null,
      email: data.email,
      progressScoreType: data.progressScoreType ? data.progressScoreType : false,
      restartSubscriptionFlow: data?.restartSubscriptionFlow ? data.restartSubscriptionFlow : false,
      role: data.roles ? mapping.initRole(data.roles) : role,
      create: data.createdAt,
      status: data.status,
      subscriptionStatus: data?.subscriptionData?.status,
      avatar: data.avatar ? data.avatar : false,
      notification: data.notification,
      stateFlow: data.stateFlow,
      preferableGroupInfo: data?.preferableGroupInfo ? data.preferableGroupInfo : null,
      testUser: data?.testUser ? data.testUser : false,
      resetPasswordToken: data?.resetPasswordToken ? data.resetPasswordToken : null,
    };
  },

  initRole: (array) => {
    if (array[0] === 'ROLE_CUSTOMER') {
      return 'CUSTOMER';
    }

    if (array[0] === 'ROLE_USER') {
      return 'CUSTOMER';
    }

    if (array[0] === 'ROLE_ADMIN' || array[0] === 'ROLE_SUPER_ADMIN') {
      return 'ADMIN';
    }

    if (array[0] === 'ROLE_THERAPIST') {
      return 'THERAPIST';
    }
    return '';
  },

  initState: (state) => {
    switch (state) {
      case 1:
        return 'registered';
      case 2:
        return 'waiting';
      case 3:
        return 'group_search';
      case 4:
        return 'questioning';
      case 5:
        return 'group_joined';
      case 6:
        return 'group_change';
      case 7:
        return 'recommendation';
      case 8:
        return 'progress';
      case 9:
        return 'start_subscription';
      case 10:
        return 'subscription_in_progress';
      case 11:
        return 'approve_consultations';
      case 12:
        return 'redirect_to_reset_password';
      case 13:
        return 'restart_pre_cancel';
      case 14:
        return 'restart_cancel';

      default:
        return '';
    }
  },

  initTherapistState: (state) => {
    switch (state) {
      case 1:
        return 'waiting';
      case 2:
        return 'registered';
      case 3:
        return 'recommendation';

      default:
        return '';
    }
  },

  stateFlowBuild: (state, item) => {
    let role;

    if (item?.roles) {
      role = mapping.initRole(item.roles);
    }

    if (role === 'CUSTOMER') {
      if (item.status) {
        switch (item.status.name) {
          case 'active':
          case 'lead':
          case 'import':
          case 'awaiting consultation':
            return mapping.initState(state);

          case 'paused':
            switch (state) {
              case 1:
                return 'group_joined';

              default:
                return '';
            }

          case 'cancelled':
            switch (item.stateFlow) {
              case 1:
                return 'group_joined';
              case 3:
              case 9:
              case 10:
              case 14:
                return mapping.initState(state);

              default:
                return '';
            }
          default:
            return '';
        }
      }
      return mapping.initState(state);
    } else if (role === 'THERAPIST') {
      return mapping.initTherapistState(state);
    }

    return mapping.initState(state);
  },
};
