import React, { createContext } from 'react';
import cn from 'classnames';
// types
import { SelectGroupsListType } from './index';
// components
import { GroupItem } from './GroupItem';
import GroupSliderWrap from 'components/containers/GroupSliderWrap';

const SelectGroupsContext = createContext<boolean | undefined>(false);

const SelectGroupsList: React.FC<SelectGroupsListType> = ({
  groups,
  number,
  onGoBack,
  redirect,
}: SelectGroupsListType) => {
  return (
    <SelectGroupsContext.Provider value={redirect}>
      <div
        className={cn('select-groups select-groups__wrapper', {
          'select-groups__three select-groups__wrapper_prev': number === 'three',
        })}
      >
        {number === 'three' && <button type="button" className="select-groups__btn" onClick={onGoBack} />}

        <GroupSliderWrap slidesCount={groups?.length} number={number}>
          {groups &&
            groups.length > 0 &&
            groups?.map((item: any) => <GroupItem key={item.group.id} item={item} bordered number={number} />)}
        </GroupSliderWrap>
        {(!groups || groups.length === 0) && <div className="select-groups-item__not-found">Groups weren`t found</div>}
      </div>
    </SelectGroupsContext.Provider>
  );
};

export { SelectGroupsContext };

export default SelectGroupsList;
