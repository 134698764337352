import React from 'react';
// styles
import './style.scss';

interface HeaderPageContainerType {
  children: React.ReactNode;
}

export const HeaderPageContainer: React.FC<HeaderPageContainerType> = ({ children }: HeaderPageContainerType) => {
  return <div className="header-page-container">{children}</div>;
};

export const BodyPageContainer: React.FC<HeaderPageContainerType> = ({ children }: HeaderPageContainerType) => {
  return <div className="body-page-container">{children}</div>;
};
