/*
 *
 * Superbill reducer
 *
 */
const initialState = {
  item: {},
  info: {},
  date: {},
  loader: true,
  loaderModal: false,
};

export type superbillInitialStateType = typeof initialState;

interface superbillActionTypes {
  type: string;
  payload: {
    item: any;
    info: any;
    date: any;
    loader: boolean;
    loaderModal: boolean;
  };
}

const superbill = (state = initialState, action: superbillActionTypes): superbillInitialStateType => {
  switch (action.type) {
    case 'ACCOUNT':
      return Object.assign({}, state, {
        item: action.payload.item,
      });
    case 'SET_SUPERBILL_PAYMENT_INFO':
      return Object.assign({}, state, {
        info: action.payload.info,
      });
    case 'SELECT_SUPERBILL_DATE':
      return Object.assign({}, state, {
        date: action.payload.date,
      });
    case 'SUPERBILL_LOADING':
      return Object.assign({}, state, {
        loader: action.payload.loader,
      });
    case 'SUPERBILL_LOADING_MODAL':
      return Object.assign({}, state, {
        loaderModal: action.payload.loaderModal,
      });

    default:
      return state;
  }
};

export default superbill;
