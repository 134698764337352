import React from 'react';
// configs
import { week_days } from '../../../../configs/tables/admin/clients';
// icons
import Icon from '@ant-design/icons';
import ImgAfter from '../../../../assets/image/questions/after.png';
import { ReactComponent as IconCalendar } from '../../../../assets/image/questions/calendar.svg';
// components
import SmallGroupCard from '../../cards/groupCard/SmallGroupCard';

interface ConfirmChoiceType {
  groups: any;
  done: () => void;
}

const ConfirmChoiceGroups: React.FC<ConfirmChoiceType> = ({ groups, done }: ConfirmChoiceType) => {
  return (
    <div className="confirm-finish">
      <div className="confirm-finish__title">
        <img src={ImgAfter} alt="Finish icon" />
        You’ve successfully switched to the following Group!
      </div>
      <div className="confirm-finish__body">
        <div className="confirm-finish__body-title">
          <Icon component={IconCalendar} style={{ marginRight: '6px', fontSize: '20px' }} />
          Every {week_days[groups.group.date.dayOfWeek - 1]}
        </div>
        <div className="confirm-finish__body-box">
          <SmallGroupCard item={groups} isBegins />
        </div>
      </div>
      <div className="confirm-finish__button">
        <button className="btn-form" onClick={done}>
          Done
        </button>
      </div>
    </div>
  );
};

export default ConfirmChoiceGroups;
