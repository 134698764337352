import React, { FC, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'antd';
// actions
import notificationClientAction from '../../../../redux/actions/notificationClient';
// icons
import ImgAfter from '../../../../assets/image/questions/after.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/image/account/close4.svg';
// style
import './style.scss';

const NotificationsDiscountModal: FC = () => {
  const [visible, setVisible] = useState(true);

  const dispatch = useDispatch();

  const handleOk = useCallback(() => {
    setVisible(false);
    dispatch(notificationClientAction.notificationDiscount(false));
  }, []);
  return (
    <>
      {visible && (
        <Modal
          closeIcon={<CloseIcon />}
          visible={visible}
          onOk={handleOk}
          closable={true}
          onCancel={handleOk}
          centered
          maskTransitionName="none"
          footer={null}
          className="notification-discount-modal"
        >
          <ModalContainer handleOk={handleOk} />
        </Modal>
      )}
    </>
  );
};

export default NotificationsDiscountModal;

const ModalContainer = ({ handleOk }: any) => {
  return (
    <div className="notification-discount-container">
      <div className="notification-discount-container__title">
        <img className="approve-client-container__finish-ico" src={ImgAfter} alt="Finish icon" />
        <div>Discount Applied!</div>
      </div>
      <div className="notification-discount-container__text-info">
        We have applied a $50 discount to your upcoming billing cycle. Thank you for continuing with Grouport!
      </div>
      <div className="notification-discount-container__block">
        <button className="btn-form" onClick={handleOk}>
          Done
        </button>
      </div>
    </div>
  );
};
