import React from 'react';
import _ from 'lodash';
import Icon from '@ant-design/icons';
import cn from 'classnames';
// configs
import { timeConvertor, week_days } from '../../../../configs/tables/admin/clients';
// icons
import { ReactComponent as IconEdit } from '../../../../assets/image/account/edit-blue.svg';
import { ReactComponent as IconCamera } from '../../../../assets/image/account/camera.svg';
import { ReactComponent as IconUser } from '../../../../assets/image/group/user.svg';
import { ReactComponent as IconTime } from '../../../../assets/image/group/time.svg';
// style
import './style.scss';

//components
import ShowText from '../../../molecules/showText';
import { MembersMiniList } from '../members';
import EditGroupAvatarModal from '../../modals/edit/admin/group/groupAvatar';
import EditGroupDetailsModal from '../../modals/edit/admin/group/groupDetails';
import EditGroupDescriptionModal from '../../modals/edit/admin/group/groupDescription';

const CardGroup: React.FC<any> = ({ group, isEdit, members }: any) => {
  return (
    <div className="card-group">
      {!_.isEmpty(group) && (
        <>
          <div className="card-group__body">
            <div className="card-group__avatar">
              {group.url ? <img src={group.url} alt="" /> : isEdit ? <div /> : <div>{group.name[0].toUpperCase()}</div>}
              {isEdit && <EditGroupAvatarModal btnClass="card-group__avatar_edit" icon={<IconCamera />} />}
            </div>
            <div className="card-group__info">
              <div className={cn('card-group__title', { 'card-group__edit': isEdit })}>
                <span>{`${group.name} Group`} </span>
                {isEdit && (
                  <EditGroupDetailsModal
                    btnClass={'group-details-card__edit-btn'}
                    icon={<Icon component={IconEdit} />}
                    name={'Edit'}
                  />
                )}
              </div>
              <div>
                <div className="card-group__date">
                  <div className="card-group__icon">
                    <Icon component={IconTime} />
                  </div>
                  <div className="card-group__text">
                    {week_days[group.date.dayOfWeek - 1]}, {timeConvertor(group.date.start)} -{' '}
                    {timeConvertor(group.date.end, 'type-time')} ET
                  </div>
                </div>
                <div className="card-group__name">
                  <div className="card-group__icon">
                    <Icon component={IconUser} />
                  </div>
                  <div className="card-group__text">
                    Therapist: &nbsp;
                    <span className="card-group__name-text">
                      {group?.therapist_title?.name ? `${group?.therapist_title?.name}. ` : ''}
                      {group.therapist_name}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-group__about">
            {(group.about || isEdit) && (
              <>
                <div className={cn('card-group__label', { 'card-group__edit': isEdit })}>
                  <span>
                    About{' '}
                    {members && isEdit && (
                      <span className="card-group__members">
                        <MembersMiniList members={members} />
                      </span>
                    )}
                  </span>
                  {isEdit && (
                    <EditGroupDescriptionModal
                      btnClass={'group-details-card__edit-btn'}
                      icon={<Icon component={IconEdit} />}
                      name={'Edit'}
                    />
                  )}
                </div>
                <p className="card-group__about-text">
                  <ShowText text={group.about} strLength={240} />
                </p>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default CardGroup;
