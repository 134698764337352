/*
 *
 * Questions reducer
 *
 */
const initialState = {
  status: 'before',
  item: {},
  loader: false,
  loaderModal: false,
  progress: 0,
};

export type questionsInitStateType = typeof initialState;

interface questionsActionTypes {
  type: string;
  payload: {
    status: string;
    item: any;
    loader: boolean;
    loaderModal: boolean;
    progress: number;
  };
}

const questions = (state = initialState, action: questionsActionTypes): questionsInitStateType => {
  switch (action.type) {
    case 'QUESTION_STATUS':
      return Object.assign({}, state, {
        status: action.payload.status,
      });
    case 'QUESTION_ITEM':
      return Object.assign({}, state, {
        item: action.payload.item,
      });
    case 'QUESTION_LOADER':
      return Object.assign({}, state, {
        loader: action.payload.loader,
      });

    case 'QUESTION_LOADER_MODAL':
      return Object.assign({}, state, {
        loaderModal: action.payload.loaderModal,
      });

    case 'QUESTION_PROGRESS':
      return Object.assign({}, state, {
        progress: action.payload.progress,
      });

    default:
      return state;
  }
};

export default questions;
