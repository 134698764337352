import React, { useCallback, useEffect, useState } from 'react';
import { Button, createStyles, makeStyles, Theme, useMediaQuery } from '@material-ui/core';
import useParticipants from '../../../hooks/useParticipants/useParticipants';
import ParticipantMenuItem from './ParticipantMenuItem';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import useChatContext from '../../../hooks/useChatContext/useChatContext';
import { useAppState } from '../../../state';
import useParticipantMenuContext from '../../../hooks/useParticipantMenuContext/useParticipantMenuContext';
import clsx from 'clsx';
import CloseIcon from '../../../icons/CloseIcon';
import { shallowEqual, useSelector } from 'react-redux';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    participantMenuContainer: {
      background: '#FFFFFF',
      zIndex: 9,
      display: 'flex',
      flexDirection: 'column',
      borderLeft: '1px solid #E4E7E9',
      borderRadius: 16,
      [theme.breakpoints.down('sm')]: {
        position: 'fixed',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: 100,
        padding: '5px',
      },
    },
    hide: {
      display: 'none',
    },
    header: {
      padding: '22px 26px',
    },
    headerText: {
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '24px',
    },
    closeButton: {
      float: 'right',
      width: 30,
      height: 30,
      backgroundColor: '#828282',
      borderRadius: '50%',
      padding: '7px',
    },
  }),
);

export default function ParticipantMenu() {
  const [participantMenuOpen, setParticipantMenuOpen] = useState(false);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const menuDisplay = participantMenuOpen ? 'block' : 'none';
  const participants = useParticipants();
  const cssClasses = useStyles();
  const { room, localTracks } = useVideoContext();
  const localParticipant = room!.localParticipant;
  const { connectPrivate, privateConversations } = useChatContext();
  const { meetingType } = useAppState();
  const { isParticipantMenuOpen, setIsParticipantMenuOpen } = useParticipantMenuContext();
  const [participantsConnecting, setParticipantsConnecting] = useState<string[]>([]);
  const role = useSelector((state: any) => state.user.profile.role, shallowEqual);

  const close = useCallback(() => {
    setIsParticipantMenuOpen(false);
  }, []);

  useEffect(() => {
    if (role === 'THERAPIST' || role === 'ADMIN') {
      console.log('check for new participants ', participants.length, participantsConnecting.length);
      participants.map((participant) => {
        const conversation = privateConversations.find((item) => {
          return item.identity == participant.identity;
        });
        if (!conversation && participantsConnecting.indexOf(participant.sid) < 0 && meetingType != 'webinar') {
          setParticipantsConnecting((old: string[]) => [...old, participant.sid]);
          console.log('adding private conversation ', participant.identity, participant.sid);
          const conversationFriendlyName = localParticipant.identity + ' - ' + participant.identity;
          const conversationUniqueName =
            conversationFriendlyName +
            ' - ' +
            Math.round(Math.random() * 100000)
              .toString()
              .padStart(6, '0');
          const endpoint = process.env.REACT_APP_API_URL + 'twilio/chat_token';
          const JWTtoken = localStorage.getItem('token');
          fetch(endpoint, {
            method: 'POST',
            headers: {
              'content-type': 'application/json',
              Authorization: `Bearer ${JWTtoken}`,
            },
            body: JSON.stringify({
              user_identity: localParticipant.identity,
              conversation_friendly_name: conversationFriendlyName,
              conversation_unique_name: conversationUniqueName,
            }),
          })
            .then((response) => response.json())
            .then((data) => {
              connectPrivate(data.token, participant.identity, conversationUniqueName);
              localTracks
                .filter((track) => track.kind == 'data')
                .forEach((track) => {
                  //@ts-ignore
                  track.send(
                    JSON.stringify({
                      command: 'connect-to-private-chat',
                      recipient: participant.sid,
                      identity: localParticipant.identity,
                      conversationUniqueName: conversationUniqueName,
                      conversationFriendlyName: conversationFriendlyName,
                    }),
                  );
                });
            });
        }
      });
    }
  }, [participants, participantsConnecting]);

  return (
    <aside className={clsx(cssClasses.participantMenuContainer, { [cssClasses.hide]: !isParticipantMenuOpen })}>
      <div className={cssClasses.header}>
        <span className={cssClasses.headerText}>Participants ({participants.length + 1})</span>
        <button className={cssClasses.closeButton} onClick={close}>
          <CloseIcon />
        </button>
      </div>
      <div>
        <ParticipantMenuItem key={localParticipant.sid} participant={localParticipant} isLocal={true} />
        {participants.map((participant) => {
          return <ParticipantMenuItem key={participant.sid} participant={participant} />;
        })}
      </div>
    </aside>
  );
}
