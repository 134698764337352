import appAction from './app';
import jwt_decode from 'jwt-decode';
import { apiAuth } from 'api/auth';
// actions
import userActions from './user';
import { LocalStorageResponse } from 'types/request-types';
import { ActionCallback, CopyLinkRole, errorsCallback } from 'types/actions/common-types';
import {
  AuthActions,
  AuthBackAction,
  ForgotPasswordAction,
  ForgotPasswordData,
  GetAuthLinkAction,
  LoginAction,
  LoginByKeyAction,
  loginByKeyDataType,
  loginDataType,
  LogoutAction,
  NewPasswordAction,
  NewPasswordData,
  NewPasswordTherapistAction,
  RegistrationAction,
  registrationDataType,
  ResetAction,
} from 'types/actions/auth-actions-types';

export const authActions: AuthActions = {
  login(data: loginDataType, callback?: ActionCallback, errorsCallback?: errorsCallback): LoginAction {
    return {
      type: 'AUTH_LOGIN',
      data,
      callback,
      errorsCallback,
    };
  },

  loginByKey(id: string | number, data: loginByKeyDataType, callback?: ActionCallback): LoginByKeyAction {
    return {
      type: 'LOGIN_BY_KEY',
      id,
      data,
      callback,
    };
  },

  logout(data: loginDataType, callback?: ActionCallback): LogoutAction {
    return {
      type: 'AUTH_LOGOUT',
      data,
      callback,
    };
  },

  reset(): ResetAction {
    return {
      type: 'RESET_APP',
    };
  },

  registration(
    data: registrationDataType,
    errorsCallback: errorsCallback,
    callback?: ActionCallback,
  ): RegistrationAction {
    return {
      type: 'AUTH_REGISTRATION',
      data,
      errorsCallback,
      callback,
    };
  },

  forgotPassword(
    data: ForgotPasswordData,
    callback?: ActionCallback,
    errorsCallback?: errorsCallback,
  ): ForgotPasswordAction {
    return {
      type: 'AUTH_FORGOT_PASSWORD',
      data,
      callback,
      errorsCallback,
    };
  },

  newPassword(data: NewPasswordData, callback?: ActionCallback): NewPasswordAction {
    return {
      type: 'AUTH_NEW_PASSWORD',
      data,
      callback,
    };
  },

  newPasswordTherapist(data: NewPasswordData, callback?: ActionCallback): NewPasswordTherapistAction {
    return {
      type: 'AUTH_NEW_PASSWORD_THERAPIST',
      data,
      callback,
    };
  },

  loader(status: boolean) {
    return {
      type: 'AUTH_LOADER',
      payload: {
        loader: status,
      },
    };
  },

  back(typeGoBack: any, callback?: ActionCallback): AuthBackAction {
    return {
      type: 'AUTH_BACK',
      typeGoBack,
      callback,
    };
  },

  async decodeToken(token: LocalStorageResponse): Promise<any> {
    const time: number = +new Date();
    try {
      let decoded: any = {};
      decoded = jwt_decode(token || '');
      if (decoded.exp * 1000 > time) {
        return decoded;
      }
      await authActions.refresh();
      return authActions.decodeToken(localStorage.getItem('token'));
    } catch (err) {
      return false;
    }
  },

  success(data: any) {
    localStorage.setItem('token', data.token);
    localStorage.setItem('refresh_token', data.refreshToken);
  },

  async authChecker(dispatch: (authType: string) => void): Promise<void> {
    const token = localStorage.getItem('token');

    if (!token) {
      return dispatch(appAction.auth('login'));
    }

    const result = await authActions.decodeToken(token);

    if (!result) {
      localStorage.removeItem('token');
      localStorage.removeItem('refresh_token');

      return dispatch(appAction.auth('login'));
    }

    if (result) {
      userActions.update(result, dispatch);
    }
  },

  async refresh() {
    try {
      const response = await apiAuth.refresh();
      authActions.success(response.data.data);
      return response;
    } catch (e) {
      localStorage.removeItem('token');
      localStorage.removeItem('refresh_token');
    }
  },

  getAuthLink(data: CopyLinkRole, id: any, callback?: ActionCallback): GetAuthLinkAction {
    return {
      type: 'GET_AUTH_LINK',
      data,
      id,
      callback,
    };
  },
};
