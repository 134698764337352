import React, { useState } from 'react';
import _ from 'lodash';
import { AnimatePresence, motion } from 'framer-motion';
import cn from 'classnames';

// components
import CardGroup from '../../cards/groupCard/CardGroup';
import BorderCard from '../../cards/groupCard/cardGroupElements/BorderCard';
import CardTherapist from '../../cards/therapist';
import GroupMeaning from '../../cards/groupCard/GroupMeaning';
import ModalConfirmChoice from '../../modals/changeGroup/confirms/choice';
// helpers
import { showTherapist } from '../../../../tools/animations';

export const GroupItem: React.FC<any> = ({ item, number }: any) => {
  const [show, setShow] = useState(false);

  return (
    <div
      className={cn('select-groups-item', {
        'select-groups-item_more': show,
        'select-groups-item__three': number && number === 'three',
      })}
    >
      <CardGroup group={item.group} />
      <AnimatePresence initial={false}>
        {show && (
          <motion.div variants={showTherapist} initial="initial" animate="visible" exit="exit">
            <BorderCard small>
              <CardTherapist therapist={item.therapist} />
            </BorderCard>
            <GroupMeaning group={item.group} />
          </motion.div>
        )}
      </AnimatePresence>

      {!_.isEmpty(item.group) && (
        <div className="select-groups-item__buttons">
          <ModalConfirmChoice id={item.group.id} item={item} />
          <span
            className="select-groups-item__more"
            onClick={() => {
              setShow(!show);
            }}
          >
            {show ? 'Show less ' : 'Show more'}
          </span>
        </div>
      )}
    </div>
  );
};
