/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import moment from 'moment-timezone';

export const mapping = {
  addEventBuild(data) {
    let dateTime = moment(data.time).tz('America/New_York');
    const check = moment(data.date, 'YYYY/MM/DD');
    const month = check.format('M');
    const day = check.format('D');
    const year = check.format('YYYY');
    dateTime.set('year', year);
    dateTime.set('month', month - 1);
    dateTime.set('date', day);
    const arrayTopic = data.topic.map((e) => e);
    return {
      start: dateTime.tz('America/New_York'),
      maxAttendees: data.maxParticipants,
      groupTopicIds: arrayTopic,
      subtitle: data.subtitle,
      therapistId: data.therapistId,
      // time: data.time,
      timeLength: data.timeLength,
      title: data.title,
      typeId: data.type,
      pictureUuid: data.eventPhoto,
    };
  },
};
