/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { put, call, select } from 'redux-saga/effects';
import queryString from 'query-string';

// api
import { api } from '../../api/npsScore';
// actions
import npsScoreAction from '../actions/nps_score';
import showError from './showError';
import appAction from '../actions/app';

export function* sagaGetNpsScore(action) {
  yield put(npsScoreAction.loader(true));
  const getParams = (state) => state.nps_score.params;
  const getNpsScore = (state) => state.nps_score.items;
  const params = yield select(getParams);
  const npsScore = yield select(getNpsScore);

  try {
    const response = yield call(api.getNpsScore, action.id, queryString.stringify(params));

    yield put(npsScoreAction.loader(false));

    if (params.page > 1) {
      yield npsScoreAction.create([...npsScore, ...response.data.data.items], response.data.data.totalCount);
    } else {
      yield npsScoreAction.create(response.data.data.items, response.data.data.totalCount);
    }
  } catch (e) {
    console.log(e);
    yield put(npsScoreAction.loader(false));
    showError();
  }
}
