import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import eventAnalytics from 'redux/actions/analytics';
// types
import { stateType } from '../../../../types/types';

export const MenuFooterTextBox: FC = () => {
  const role = useSelector((state: stateType) => state.user.profile.role);
  const dispatch = useDispatch();
  const onCLickLink = () => {
    dispatch(eventAnalytics.analytics('emergency-resources'));
  };

  return (
    <div className="main-menu__footer-body">
      <div className="main-menu__footer-text">
        {role === 'CUSTOMER'
          ? 'If you are in a life-threatening situation, do not use this site.'
          : `If ${
              role === 'THERAPIST' ? 'a patient of yours' : 'someone'
            } is in an immediate crisis, here are some emergency resources you can share with them.`}
      </div>
      <div className="main-menu__footer-text">
        Call{' '}
        <a className="main-menu__contact" href="tel:+18002738255">
          1-800-273-8255
        </a>
      </div>
      <div className="main-menu__footer-text">
        or use{' '}
        <a
          className="main-menu__link"
          target="_blank"
          href="https://grouporttherapy.com/gethelpnow"
          rel="noreferrer"
          onClick={onCLickLink}
        >
          these resources
        </a>{' '}
        to get immediate help.
      </div>
    </div>
  );
};
