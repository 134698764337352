import { useContext } from 'react';
import { RoomInfoContext } from '../../components/video/RoomInfoProvider/RoomInfoProvider';

export default function useRoomInfoContext() {
  const context = useContext(RoomInfoContext);
  if (!context) {
    throw new Error('useRoomInfoContext must be used within a RoomInfoProvider');
  }
  return context;
}
