/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/display-name */
import React from 'react';
import * as ColumnItems from '../../../../molecules/columnItems/admin/events';

export const columns = [
  {
    title: 'Events name',
    dataIndex: 'name',
    key: 'name',
    render: (value, row, index) => <ColumnItems.ItemName info={value} row={row} index={index} link="event_details" />,
    width: '240px',
  },
  {
    title: 'Time',
    dataIndex: 'time',
    key: 'time',
    render: (value, row, index) => <ColumnItems.ItemTime info={value} row={row} />,
    width: '125px',
  },
  {
    title: 'Therapist',
    dataIndex: 'therapist',
    key: 'therapist',
    render: (value, row, index) => {
      return <ColumnItems.ItemTherapist info={value} row={row} index={index} />;
    },
    width: '155px',
  },
  {
    title: 'Event type',
    dataIndex: 'type',
    key: 'type',
    render: (value, row, index) => <ColumnItems.ItemType info={value} row={row} index={index} />,
    width: '75px',
  },

  {
    title: 'Max # of participant',
    dataIndex: 'maxParticipant',
    key: 'maxParticipant',
    render: (value, row, index) => <ColumnItems.ItemParticipants info={value} row={row} index={index} />,
    width: '125px',
  },
  //
  // {
  //   title: '',
  //   dataIndex: 'edit',
  //   key: 'edit',
  //   render: (value, row, index) => <ColumnItems.ItemEdit info={value} row={row} index={index} />,
  //   width: '55px',
  // },
];

columns.displayName = 'MyComponent';
