import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
// components
import ArbitraryAnswer from 'components/molecules/groupQuestions/Answers/ArbitraryAnswer';
import BooleanChoice from 'components/molecules/groupQuestions/Answers/BooleanChoice';
import MultiQuestion from 'components/molecules/groupQuestions/Answers/MultiQuestion';
import Select from 'components/atoms/form/Select';
import QuestionCard from 'components/molecules/groupQuestions/QuestionCard';
// actions
import questionsActions from 'redux/actions/questions';
import userActions from 'redux/actions/user';
import mappingQuestions from 'api/mapping/response/questions';
// types
import { IQuestion, QuestionType } from 'types/app-types';
import { FlowType } from 'types/actions/common-types';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';

const flowType = FlowType.StartGroup;

interface IStepProps {
  question: IQuestion;
}

const Step: FC<IStepProps> = ({ question }: IStepProps) => {
  const [checkedValues, setCheckedValues] = useState<any>([]);
  const [answerText, setAnswerText] = useState<string | undefined>('');
  const dispatch = useDispatch();

  useEffect(() => {
    initDefaultValue();
  }, []);

  const initDefaultValue = () => {
    if (question.defaultValue) {
      switch (question.questionType) {
        case QuestionType.MultiQuestion:
          return setCheckedValues(question.defaultValue.answerIds);

        case QuestionType.BooleanQuestion:
          return setCheckedValues([question.defaultValue.answerId]);

        case QuestionType.SingleQuestion:
          return setCheckedValues([question.defaultValue.answerId]);

        case QuestionType.ArbitraryQuestion:
          return setAnswerText(question.defaultValue.answerText);

        default:
          return;
      }
    }
  };

  const onChangeCheck = (checkedValues: CheckboxValueType[]) => {
    setCheckedValues(checkedValues);
  };

  const onChangeRadio = (value: number) => {
    setCheckedValues([value]);
  };

  const onSelectChange = (value: number) => {
    setCheckedValues([value]);
  };

  const answerController = () => {
    switch (question.questionType) {
      case 'multi':
        return <MultiQuestion question={question} onChangeCheck={onChangeCheck} checkedValues={checkedValues} />;

      case 'boolean':
        return <BooleanChoice question={question} onChangeRadio={onChangeRadio} value={checkedValues[0]} />;

      case 'single':
        return (
          <Select
            options={mappingQuestions.buildOptions(question.answers)}
            onChange={onSelectChange}
            defaultValue={question.defaultValue?.answerId}
            placeholder={question.additions.placeholder}
          />
        );

      case 'arbitrary':
        return <ArbitraryAnswer answerText={answerText} setAnswerText={(text) => setAnswerText(text)} />;

      default:
        return null;
    }
  };

  const onNext = () => {
    const data = {
      questionId: question.id,
      answersIds: checkedValues,
      answerText: answerText,
    };

    dispatch(
      questionsActions.answer(flowType, mappingQuestions.buildAnswer(data, question.answerType), () => {
        if (question.number === 10) {
          userActions.state('group_joined');
          questionsActions.status('after');
          return;
        }
        return dispatch(questionsActions.next(flowType));
      }),
    );
  };

  const onBack = () => {
    dispatch(questionsActions.after(flowType));
  };

  if (_.isEmpty(question)) {
    return null;
  }

  return (
    <QuestionCard
      onBack={onBack}
      question={question}
      renderController={answerController}
      onNext={onNext}
      nextDisabled={!answerText && !checkedValues.length}
      apply={question.number === 1}
      showBack={question.number !== 1}
      totalStepCount={question.maxQuestionNumber}
    />
  );
};

export default Step;
