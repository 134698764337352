import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import LoadingIcon from '../../../icons/LoadingIcon';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: '12px 0',
  },
  center: {
    width: 44,
    margin: '0 auto',
  },
  '@keyframes spinnerAnimation': {
    '0%': { transform: 'rotate(0)' },
    '12.5%': { transform: 'rotate(45deg)' },
    '25%': { transform: 'rotate(90deg)' },
    '37.5%': { transform: 'rotate(135deg)' },
    '50%': { transform: 'rotate(180deg)' },
    '62.5%': { transform: 'rotate(225deg)' },
    '75%': { transform: 'rotate(270deg)' },
    '87.5%': { transform: 'rotate(315deg)' },
    '100%': { transform: 'rotate(360deg)' },
  },
  spinner: {
    width: 35,
    height: 36,
    animation: '$spinnerAnimation 1s infinite',
    animationTimingFunction: 'steps(1, jump-end)',
  },
}));

export default function Loader() {
  const cssClasses = useStyles();

  return (
    <div className={cssClasses.container}>
      <div className={cssClasses.center}>
        <div className={cssClasses.spinner}>
          <LoadingIcon />
        </div>
      </div>
    </div>
  );
}
