import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
// types
import { stateType } from '../../../../types/types';
// styles
import './style.scss';
import EditAccountModal from 'components/organisms/modals/editAccount';
import Icon from '@ant-design/icons';
import { ReactComponent as IconEditBlue } from '../../../../assets/image/account/edit-blue.svg';
import EditClientModal from 'components/organisms/modals/edit/admin/client/client';
import EditClientICDModal from 'components/organisms/modals/edit/admin/client/clientICD';
import { useHistory } from 'react-router-dom';

const ConsultationForm: React.FC = () => {
  const item: any = useSelector((state: stateType) => state.clients.item, shallowEqual);
  const role: any = useSelector((state: stateType) => state.user.profile.role, shallowEqual);
  const history = useHistory();

  const initValue = (element: any) => {
    switch (element.question.answerType) {
      case 'multi':
        return _.filter(element.question.answers, function (p) {
          return _.includes(element.value.answerIds, p.id);
        }).map((item, index) => {
          if (index === 0) {
            return `${item.answer}`;
          }
          return `, ${item.answer} `;
        });

      case 'single':
        return _.filter(element.question.answers, { id: element.value.answerId })[0].answer;

      case 'arbitrary':
        return element.value.answerText;

      default:
        return '';
    }
  };

  const goToSuperbills = () => {
    history.push(`/client-superbills/${item.id}`);
  };

  return (
    <div className="consultation-form">
      <div className="consultation-form__title">Consultation Form</div>
      <div className="consultation-form__row">
        <div className="consultation-form__item">
          <div className="consultation-form__label">Gender</div>
          <div className="consultation-form__value consultation-form__value_bg">{item.gender?.name}</div>
        </div>
        <div className="consultation-form__item">
          <div className="consultation-form__label">Age</div>
          <div className="consultation-form__value consultation-form__value_bg">
            {item.birthday ? moment().diff(item.birthday, 'years') : ''}
          </div>
        </div>
        <div className="consultation-form__item">
          <div className="consultation-form__label">State</div>
          <div className="consultation-form__value consultation-form__value_bg">{item.stateCode}</div>
        </div>
      </div>

      <div className="consultation-form__block">
        {item.questionnaireAnswers.map((element: any) => {
          if (element.value) {
            return (
              <div
                className={`consultation-form__item consultation-form__item${element.question.id}`}
                key={element.question.id}
              >
                <div className="consultation-form__label">{element.question.additions.accountLabel}</div>
                <div className="consultation-form__value">{initValue(element)}</div>
              </div>
            );
          }
          return null;
        })}
      </div>

      <div className="consultation-form__item">
        <div className="consultation-form__label">Reasons for seeking therapy</div>
        <div className="consultation-form__value">
          {item?.reasons.map((elem: any, i: number) => {
            if (i === 0) {
              return `${elem.name}`;
            }
            return `, ${elem.name} `;
          })}
        </div>
      </div>
      <div className="consultation-form__item">
        <div className="consultation-form__label">Preferred Group</div>
        <div className="consultation-form__value">{item?.preferredGroup?.name} Group</div>
      </div>
      <div className="consultation-form__item">
        <div className="consultation-form__label">Email</div>
        <div className="consultation-form__value">{item.email}</div>
      </div>
      <div className="consultation-form__item">
        <div className="consultation-form__label">Phone number</div>
        <div className="consultation-form__value">{item.phone}</div>
      </div>

      <div className="consultation-form__item">
        <div className="consultation-form__label">ICD-10 code</div>
        <div className="consultation-form__input-value">
          {item.icd10}
          {role === 'THERAPIST' ? (
            <EditAccountModal type={'icd10'}>
              <button>
                <Icon component={IconEditBlue} className="account-details__edit-icon" />
                <span className="account-details__edit">Edit</span>
              </button>
            </EditAccountModal>
          ) : (
            <EditClientICDModal>
              <button>
                <Icon component={IconEditBlue} className="account-details__edit-icon" />
                <span className="account-details__edit">Edit</span>
              </button>
            </EditClientICDModal>
          )}
        </div>
      </div>

      {role === 'ADMIN' && (
        <div className="consultation-form__button">
          <button className="view-superbill" onClick={goToSuperbills}>
            View superbills
          </button>
        </div>
      )}
    </div>
  );
};

export default ConsultationForm;
