/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { AxiosResponse } from 'axios';
// config
import http from '../instance';

export const apiProgress = {
  get: async (role: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.get(`${role}/progress-scores`);
  },

  start: async (role: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.patch(`${role}/progress-flow/start`);
  },

  reset: async (role: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.patch(`${role}/progress-flow/reset`);
  },

  hold_over: async (role: string, taskType: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.patch(`${role}/${taskType}/hold-over`);
  },

  get_term: async (role: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.get(`${role}/long-term-goals`);
  },

  post_term: async (role: string, data: []): Promise<AxiosResponse> => {
    const api = await http();
    return api.post(`${role}/long-term-goals`, data);
  },

  get_log: async (role: string, data: any): Promise<AxiosResponse> => {
    const api = await http();
    return api.get(`${role}/self-awareness-log/${data.id}`);
  },

  get_logs: async (role: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.get(`${role}/self-awareness-logs`);
  },

  post_logs: async (role: string, data: any): Promise<AxiosResponse> => {
    const api = await http();
    return api.post(`${role}/self-awareness-log`, data);
  },

  edit_logs: async (role: string, data: any): Promise<AxiosResponse> => {
    const api = await http();
    return api.put(`${role}/self-awareness-log/${data.id}`, data);
  },

  delete_logs: async (role: string, data: any): Promise<AxiosResponse> => {
    const api = await http();
    return api.delete(`${role}/self-awareness-log/${data.id}`);
  },
};
