// types
import { ActionCallback } from '../../types/actions/common-types';
import {
  EditProgressLogs,
  GetProgressAction,
  GetProgressLogAction,
  GetProgressTermAction,
  HoldOverProgressAction,
  LogItemType,
  PostProgressLogs,
  PostTermListAction,
  progressActionType,
  ProgressCoreItemType,
  ProgressLoaderAction,
  ProgressLoaderModalAction,
  requestLogType,
  requestTermType,
  ResetProgressAction,
  SetProgressListAction,
  SetProgressLogAction,
  SetProgressLogsAction,
  SetProgressTerm,
  StartProgressAction,
} from '../../types/actions/progress';

const progressAction: progressActionType = {
  get(callback?: ActionCallback): GetProgressAction {
    return {
      type: 'GET_PROGRESS_LIST',
      callback,
    };
  },
  set(list: [ProgressCoreItemType]): SetProgressListAction {
    return {
      type: 'PROGRESS_LIST',
      payload: {
        list: list,
      },
    };
  },
  start(callback?: ActionCallback): StartProgressAction {
    return {
      type: 'PROGRESS_START',
      callback,
    };
  },
  reset(callback?: ActionCallback): ResetProgressAction {
    return {
      type: 'PROGRESS_RESET',
      callback,
    };
  },
  hold_over(taskType: string, callback?: ActionCallback): HoldOverProgressAction {
    return {
      type: 'PROGRESS_HOLD_OVER',
      taskType,
      callback,
    };
  },
  loader(status: boolean): ProgressLoaderAction {
    return {
      type: 'PROGRESS_LOADER',
      payload: {
        loader: status,
      },
    };
  },
  loaderModal(status: boolean): ProgressLoaderModalAction {
    return {
      type: 'PROGRESS_LOADER_MODAL',
      payload: {
        loaderModal: status,
      },
    };
  },
  get_term(callback?: ActionCallback): GetProgressTermAction {
    return {
      type: 'GET_TERM_LIST',
      callback,
    };
  },
  post_term(data: requestTermType, callback?: ActionCallback): PostTermListAction {
    return {
      type: 'POST_TERM_LIST',
      data,
      callback,
    };
  },
  set_term(list: requestTermType): SetProgressTerm {
    return {
      type: 'PROGRESS_TERM_LIST',
      payload: {
        term: list,
      },
    };
  },
  get_log(data: { id: number }, callback?: ActionCallback): GetProgressLogAction {
    return {
      type: 'GET_LOG_ITEM',
      data,
      callback,
    };
  },
  get_logs(callback?: ActionCallback) {
    return {
      type: 'GET_LOGS_LIST',
      callback,
    };
  },
  set_logs(list: [LogItemType]): SetProgressLogsAction {
    return {
      type: 'PROGRESS_LOGS',
      payload: {
        logs: list,
      },
    };
  },
  set_log(item: LogItemType): SetProgressLogAction {
    return {
      type: 'PROGRESS_LOG_ITEM',
      payload: {
        log: item,
      },
    };
  },
  post_logs(data: requestLogType, callback?: ActionCallback): PostProgressLogs {
    return {
      type: 'POST_LOG_ITEM',
      data,
      callback,
    };
  },
  edit_logs(data: requestLogType, callback?: ActionCallback): EditProgressLogs {
    return {
      type: 'PUT_LOG_ITEM',
      data,
      callback,
    };
  },
  delete_logs(data: { id: number }, callback?: ActionCallback) {
    return {
      type: 'DELETE_LOG_ITEM',
      data,
      callback,
    };
  },
};

export default progressAction;
