import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
// components
import MainTemplate from '../../../components/templates/mainTemplate';
import { HeaderPageContainer, BodyPageContainer } from '../../../components/containers/pageContainer';
import EventDetailsContainer from '../../../components/containers/eventDetailsContainer';
// actions
import eventsAction from '../../../redux/actions/admin_events';

const EventDetailsPage: React.FC = ({ computedMatch }: any) => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(eventsAction.getByID(computedMatch.params.id));

    return () => {
      dispatch(eventsAction.setEvent({}));
    };
  }, []);

  return (
    <MainTemplate>
      <HeaderPageContainer>
        <h2 className="text-page-header title-back" onClick={() => history.push('/community_events')}>
          Community Event Details
        </h2>
      </HeaderPageContainer>
      <BodyPageContainer>
        <EventDetailsContainer />
      </BodyPageContainer>
    </MainTemplate>
  );
};

export default EventDetailsPage;
