import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useWindowResize } from 'beautiful-react-hooks';
// components
import DefaultAside from '../DefaultAside';
import MobileAside from '../MobileAside';
// actions
import mainMenuAction from 'redux/actions/main_menu';
// types
import { stateType } from 'types/types';
// styles
import './style.scss';

const MainMenu: React.FC = () => {
  const dispatch = useDispatch();

  const { page } = useSelector((state: stateType) => state.app, shallowEqual);
  const { menu } = useSelector((state: stateType) => state.main_menu);

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    dispatch(mainMenuAction.changeStatus(page, menu));
  }, [page]);

  useWindowResize(() => {
    setWidth(window.innerWidth);

    if (window.innerWidth < 768) {
      dispatch(mainMenuAction.showDrawer(false));
    }
  });

  return width > 768 ? <DefaultAside /> : <MobileAside />;
};

export default MainMenu;
