import React, { FC, ReactNode } from 'react';
// components
import ArcProgress from 'react-arc-progress';
//types
import { IQuestion } from 'types/app-types';
// icons
import iconNextArr from 'assets/image/slider/nextArr.svg';
// styles
import './style.scss';

interface IAssessmentQuestionProps {
  showBack?: boolean;
  onBack: () => void;
  question: IQuestion;
  renderController: () => ReactNode;
  onNext: () => void;
  nextDisabled: boolean;
  closeBtn?: ReactNode;
  children?: ReactNode;
  apply?: boolean;
  totalStepCount?: number;
  subQuestion?: ReactNode | boolean;
  answerText?: string;
  firstStep?: boolean;
}

const AssessmentQuestionCard: FC<IAssessmentQuestionProps> = (props: IAssessmentQuestionProps) => {
  const {
    showBack = true,
    onBack,
    question,
    renderController,
    onNext,
    nextDisabled,
    closeBtn,
    children,
    apply,
    totalStepCount = 1,
    subQuestion,
    answerText,
    firstStep,
  } = props;

  return (
    <div className="questions">
      <div className="questions__header">
        <div className="questions__header-top">
          <div className="questions__anxiety-progress">
            {/* <StepProgress step={question.serialNumber} stepNumber={question.number} totalStepCount={totalStepCount} /> */}
            <ArcProgress
              progress={question.number / totalStepCount}
              text={`${question.number}`}
              animation={false}
              arcStart={180}
              arcEnd={360}
              thickness={17}
              fillColor="#42557e"
              textStyle={{ size: '25px', font: 'Roboto' }}
            />
          </div>
          <div className="questions__close-btn">{closeBtn}</div>
        </div>
        <div className="questions__question-body">
          {children || (
            <>
              {subQuestion && <div className="questions__top-question">{subQuestion}</div>}
              <div className="questions__question bold">{question.question}</div>
              {apply && <div className="questions__apply">(Check all that apply)</div>}
            </>
          )}
        </div>
      </div>
      <div className="questions__body">{renderController()}</div>
      <div className="questions__footer">
        {showBack && (
          <button className="btn-form btn-left" onClick={onBack}>
            Go Back
          </button>
        )}
        <button className="btn-form btn-next" onClick={onNext} disabled={(!answerText || firstStep) && nextDisabled}>
          Next <img src={iconNextArr} alt="show more arrow" className="icon-arrow" />
        </button>
      </div>
    </div>
  );
};

export default AssessmentQuestionCard;
