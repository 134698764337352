import { AxiosResponse } from 'axios';
import http from '../instance';

export const apiAnalytics = {
  analytics: async (role: string, params: any): Promise<AxiosResponse> => {
    const eventName = params.payload.route;
    const data = params?.payload?.data || {};
    const api = await http();
    return api.post(`/analytics/${role}/${eventName}`, data);
  },

  resetPassword: async (params: any): Promise<AxiosResponse> => {
    const api = await http();
    const data = params.params || {};
    return api.post(`/analytics/reset-password-tracker/customer`, data);
  },
};
