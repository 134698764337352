import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

// components
import MainTemplate from '../../../components/templates/mainTemplate';
import EventNavigationContainer from '../../../components/containers/navigationContainer/event';
import { HeaderPageContainer } from '../../../components/containers/pageContainer';
// actions
import appAction from '../../../redux/actions/app';
import upcomingAction from '../../../redux/actions/upcoming_events';
import { stateType } from '../../../types/types';
import UpcomingEvents from '../../../components/organisms/sliders/upcomingEvents';
import PastEvents from '../../../components/organisms/lists/pastEvents';
import eventAnalytics from '../../../redux/actions/analytics';

const EventsPage: React.FC = () => {
  const dispatch = useDispatch();
  const ordersFilter = useSelector((state: stateType) => state.upcoming_events.ordersFilter, shallowEqual);
  const searchType: string = useSelector((state: stateType) => state.upcoming_events.searchType, shallowEqual);
  // const posts: any = useSelector((state: stateType) => state.upcoming_events.posts, shallowEqual);
  // const events: any = useSelector((state: stateType) => state.upcoming_events.events, shallowEqual);
  const showPast = searchType !== 'upcoming';
  const showEvents = searchType !== 'past';

  useEffect(() => {
    dispatch(eventAnalytics.analytics('view-community-events'));
    dispatch(appAction.page('Community Events'));
    dispatch(upcomingAction.getLengthsFilters());
    dispatch(upcomingAction.getTypesFilters());
    dispatch(upcomingAction.getTopicsFilters());
    dispatch(upcomingAction.getOrdersFilters());
    dispatch(upcomingAction.getEventsAndPosts());
    return () => {
      dispatch(upcomingAction.setEvents([]));
      dispatch(upcomingAction.setPost([]));
    };
  }, []);

  useEffect(() => {
    if (ordersFilter) {
      dispatch(upcomingAction.getEventsAndPosts(ordersFilter));
      return () => {
        dispatch(upcomingAction.setEvents([]));
        dispatch(upcomingAction.setPost([]));
      };
    }
  }, [ordersFilter]);

  return (
    <MainTemplate>
      <HeaderPageContainer>
        <h2 className="text-page-header">Community Events</h2>
        <div className={'view-text-under-header'}>
          <h2 className="text-page-header text-under-header">
            Register for free group sessions or webinars to explore other topics of interest, get additional skills, and
            be supported by others who get it. You can also listen to past webinars at any time. You can join all
            registered events from your home page.
          </h2>
        </div>
      </HeaderPageContainer>
      <EventNavigationContainer />
      <>
        {showEvents && <UpcomingEvents />}
        {showPast && <PastEvents />}
      </>
    </MainTemplate>
  );
};

export default EventsPage;
