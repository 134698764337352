import React from 'react';
import AudioLevelIndicator from '../../AudioLevelIndicator/AudioLevelIndicator';
import { LocalAudioTrack } from 'twilio-video';
import { FormControl, MenuItem, Typography, Select, Grid, Theme } from '@material-ui/core';
import { SELECTED_AUDIO_INPUT_KEY } from '../../../../constants';
import useDevices from '../../../../hooks/useDevices/useDevices';
import useMediaStreamTrack from '../../../../hooks/useMediaStreamTrack/useMediaStreamTrack';
import useVideoContext from '../../../../hooks/useVideoContext/useVideoContext';
import { makeStyles } from '@material-ui/core/styles';
import useLocalAudioToggle from '../../../../hooks/useLocalAudioToggle/useLocalAudioToggle';

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#344054',
  },
  select: {
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '28px',
    padding: '10px 14px',
    border: '1px solid #D0D5DD',
    borderRadius: 8,
  },
  testContainer: {
    paddingTop: 16,
  },
  testButton: {
    fontWeight: 400,
    fontSize: '0.9rem',
    lineHeight: '28px',
    padding: 8,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#D0D5DD',
    borderRadius: 8,
  },
}));

export default function AudioInputList() {
  const { audioInputDevices } = useDevices();
  const { localTracks } = useVideoContext();

  const localAudioTrack = localTracks.find((track) => track.kind === 'audio') as LocalAudioTrack;
  const mediaStreamTrack = useMediaStreamTrack(localAudioTrack);
  const localAudioInputDeviceId = mediaStreamTrack?.getSettings().deviceId;
  const cssClasses = useStyles();
  const [testButtonText, setTestButtonText] = React.useState('Test Microphone');
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();

  function replaceTrack(newDeviceId: string) {
    window.localStorage.setItem(SELECTED_AUDIO_INPUT_KEY, newDeviceId);
    localAudioTrack?.restart({ deviceId: { exact: newDeviceId } });
  }

  const testMicrophone = () => {
    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      const mediaRecorder = new MediaRecorder(stream);
      const audioChunks: BlobPart[] | undefined = [];
      const wasAudioEnabled = isAudioEnabled;

      mediaRecorder.addEventListener('dataavailable', (event) => {
        audioChunks.push(event.data);
      });

      mediaRecorder.addEventListener('stop', () => {
        const audioBlob = new Blob(audioChunks);
        const audioUrl = URL.createObjectURL(audioBlob);
        const audio = new Audio(audioUrl);
        audio.play();
      });

      if (wasAudioEnabled) {
        toggleAudioEnabled();
      }

      mediaRecorder.start();
      setTestButtonText('Recording...');

      setTimeout(() => {
        mediaRecorder.stop();
        setTestButtonText('Playing...');
        setTimeout(() => {
          setTestButtonText('Test Microphone');
          if (wasAudioEnabled) {
            toggleAudioEnabled();
          }
        }, 4000);
      }, 4000);
    });
  };

  return (
    <div>
      <label className={cssClasses.label}>Audio Device</label>
      <Grid container alignItems="center" justifyContent="space-between">
        <div className="inputSelect">
          {audioInputDevices.length > 1 ? (
            <FormControl fullWidth>
              <Select
                onChange={(e) => replaceTrack(e.target.value as string)}
                value={localAudioInputDeviceId || ''}
                variant="outlined"
              >
                {audioInputDevices.map((device) => (
                  <MenuItem value={device.deviceId} key={device.deviceId}>
                    {device.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <Typography>{localAudioTrack?.mediaStreamTrack.label || 'No Local Audio'}</Typography>
          )}
        </div>
        <div style={{ paddingTop: 16 }}>
          <AudioLevelIndicator audioTrack={localAudioTrack} color="black" size="large" />
        </div>
      </Grid>
      <div className={cssClasses.testContainer}>
        <button className={cssClasses.testButton} onClick={testMicrophone}>
          {testButtonText}
        </button>
      </div>
    </div>
  );
}
