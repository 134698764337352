import React from 'react';
// styles
import './style.scss';

interface BoxContainerType {
  children: React.ReactNode;
}

const BoxContainer: React.FC<BoxContainerType> = ({ children }: BoxContainerType) => {
  return <div className="box-container">{children}</div>;
};

export default BoxContainer;
