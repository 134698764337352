import { combineReducers } from 'redux';
import app from './app';
import auth from './auth';
import main_menu from './main_menu';
import user from './user';
import clients from './clients';
import therapists from './therapists';
import groups from './groups';
import consultation_form from './consultation_form';
import choice_group from './choice_group';
import questions from './questions';
import account from './account';
import members from './members';
import sessions from './sessions';
import history from './history';
import nps_score from './nps_score';
import notificationClient from './notificationClient';
import assesments from './assesments';
import options from './options';
import progress from './progress';
import upcoming_events from './upcoming_events';
import stripe from './stripe';
import intakeConsultation from './intake_consultation';
import assessment_questions from './assessment_questions';
import superbill from './superbill';
import events from './events';
import restartSubscriptions from './restart_subscriptions';

export default combineReducers({
  app,
  auth,
  main_menu,
  user,
  clients,
  therapists,
  groups,
  consultation_form,
  choice_group,
  questions,
  assessment_questions,
  account,
  members,
  sessions,
  history,
  nps_score,
  notificationClient,
  assesments,
  options,
  progress,
  upcoming_events,
  events,
  stripe,
  intakeConsultation,
  superbill,
  restartSubscriptions,
});
