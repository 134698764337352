import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Moment from 'react-moment';
import { Collapse, Spin } from 'antd';
import cn from 'classnames';
import { useWindowResize } from 'beautiful-react-hooks';
// components
import HistoryMemberItem from '../items';
// types
import { stateType } from '../../../../../types/types';
// style
import './style.scss';
//actions
import historyAction from '../../../../../redux/actions/history';
// helpers
import useDidMountEffect from '../../../../../configs/hooks/useDidMountEffect';
import { scrollToBottom } from '../../../../../tools/helpFunctions';

const HistoryList: React.FC<any> = ({ paramsId }: any) => {
  const [page, setPage] = useState(1);
  const history = useSelector((state: stateType) => state.history.items);
  const totalCount = useSelector((state: stateType) => state.history.totalCount);
  const params = useSelector((state: stateType) => state.history.params);
  const loader = useSelector((state: stateType) => state.history.loader);
  const [itemKey, setItemKey] = useState<string | string[]>('');

  const refScrollTo = useRef<HTMLDivElement>(null);

  const dispatch = useDispatch();

  const onShowMore = (): void => {
    setPage(page + 1);
  };

  useEffect(() => {
    if (page > 1) {
      historyAction.params({ ...params, page });
      dispatch(historyAction.get(paramsId));
    }
  }, [page]);

  useDidMountEffect(() => {
    scrollToBottom(refScrollTo);
  }, [history]);

  return (
    <div className={cn('history-container', { content: history?.length })}>
      <Spin spinning={loader}>
        <h3 className="history-container__title">Group History</h3>
        {history?.length === 0 && <span className="history-container__text">This Group has not yet started</span>}
        <Collapse className="history-container__collapse" accordion onChange={(key) => setItemKey(key)}>
          {history &&
            history?.length > 0 &&
            history.map((item: any) => {
              return (
                <Collapse.Panel
                  showArrow={false}
                  header={
                    <HeaderPanel
                      itemId={item.id}
                      itemKey={itemKey}
                      end={item.end}
                      membersCount={item.membersCount}
                      status={item.status}
                    />
                  }
                  key={item.id}
                  className="history-container__panel"
                >
                  <HistoryMemberItem item={item} />
                </Collapse.Panel>
              );
            })}
          <div ref={refScrollTo} />
        </Collapse>
        {totalCount > 5 && (
          <div className="history-container__button-container">
            {page * 5 < totalCount && (
              <button className="history-container__button" type="button" onClick={onShowMore}>
                Show more
              </button>
            )}
          </div>
        )}
      </Spin>
    </div>
  );
};

export default HistoryList;

const HeaderPanel: React.FC<any> = ({ itemId, itemKey, end, membersCount, status }: any) => {
  const [width, setWidth] = useState(window.innerWidth);

  useWindowResize(() => {
    setWidth(window.innerWidth);
  });

  const open = +itemKey === itemId;

  return (
    <div className="history-container__header">
      <Moment format="dddd, MMMM Do, yyyy" className="history-container__header_date">
        {end}
      </Moment>
      <p className={cn('history-container__header_members', { open })}>
        <span className="history-container__cancelled-status">
          {!open && width > 768 && status.value === 4 ? status.name : ''}
        </span>
        {open && width > 768 ? `${membersCount} members` : ''}
      </p>
    </div>
  );
};
