import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Modal, Spin } from 'antd';
// componets
import FormZoomIntegration from '../../forms/zoomClients';
// actions
import optionsAction from '../../../../redux/actions/options';
// types
import { stateType } from '../../../../types/types';
// styles
import './style.scss';
// icons
import { ReactComponent as CloseIcon } from 'assets/image/account/close4.svg';

const EditZoom: React.FC<any> = ({ classes }: any) => {
  const [visible, setVisible] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    visible && dispatch(optionsAction.get_zoom_clients());
  }, [visible]);

  const loader = useSelector((state: stateType) => state.therapists.loaderModal, shallowEqual);

  const open = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <span onClick={open} className={classes}>
        Edit
      </span>
      {visible && (
        <Modal
          visible={visible}
          onOk={handleOk}
          closable={true}
          onCancel={handleCancel}
          centered
          maskTransitionName="none"
          footer={null}
          className="edit-zoom-modal"
          closeIcon={<CloseIcon />}
        >
          <Spin spinning={loader}>
            <ModalContainer setVisible={setVisible} />
          </Spin>
        </Modal>
      )}
    </>
  );
};

export default EditZoom;

const ModalContainer = ({ setVisible }: any) => {
  return (
    <div className="edit-zoom-container">
      <div className="edit-zoom-container__title">Zoom integration</div>
      <FormZoomIntegration setVisible={setVisible} />
    </div>
  );
};
