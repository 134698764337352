/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/display-name */
import React from 'react';
import * as ColumnItems from '../../../../molecules/columnItems/admin/clients';

export const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (value, row, index) => <ColumnItems.ItemName info={value} row={row} index={index} link="all_clients" />,
    width: '230px',
    minWidth: '230px',
  },
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    render: (value, row, index) => <ColumnItems.ItemId info={value} row={row} />,
    width: '50px',
    minWidth: '50px',
  },
  {
    title: 'Topic',
    dataIndex: 'topic',
    key: 'topic',
    render: (value, row, index) => {
      return <ColumnItems.ItemTopic info={value} row={row} index={index} />;
    },
    width: '200px',
  },

  {
    title: 'Time',
    dataIndex: 'time',
    key: 'time',
    render: (value, row, index) => <ColumnItems.ItemTime info={value} row={row} index={index} />,
    width: '240px',
  },

  {
    title: 'Therapist',
    dataIndex: 'therapist',
    key: 'therapist',
    render: (value, row, index) => <ColumnItems.ItemTherapist info={value} row={row} index={index} />,
    width: '140px',
  },

  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (value, row, index) => <ColumnItems.ItemStatus info={value} row={row} index={index} />,
    width: '120px',
  },
];

columns.displayName = 'MyComponent';
