import store from '../store';
import { mapping } from '../../api/mapping/response/tables';
// actions
import {
  ApproveClientSubscriptionAction,
  ArchiveClientSubscriptionAction,
  CancelClientGroupAction,
  CancelClientSubscriptionAction,
  ChangeClientGroupAction,
  ClientAction,
  ClientLoaderAction,
  ClientModalLoaderAction,
  ClientRemindersLoaderAction,
  EditClientAction,
  EnableClientEmailsAction,
  EnableClientSmsAction,
  GetClientAction,
  NoSowClientSubscriptionAction,
  PauseClientAction,
  RestartClientSubscriptionAction,
  UnarchiveClientAction,
  SetICD10CodeAction,
  UnpauseClientAction,
} from '../../types/actions/client';
import { ActionCallback } from '../../types/actions/common-types';

const clientAction: ClientAction = {
  get(id?: number, callback?: ActionCallback): GetClientAction {
    return {
      type: 'GET_CLIENT',
      id,
      callback,
    };
  },
  restartSubscription(id?: number, callback?: ActionCallback): RestartClientSubscriptionAction {
    return {
      type: 'PUT_RESTART_SUBSCRIPTION',
      id,
      callback,
    };
  },
  cancelSubscription(id?: number, callback?: ActionCallback): CancelClientSubscriptionAction {
    return {
      type: 'PUT_CANCEL_SUBSCRIPTION',
      id,
      callback,
    };
  },
  enableSms(id: number, enable: boolean, callback?: ActionCallback): EnableClientSmsAction {
    return {
      type: 'CLIENT_ENABLED_SMS',
      id,
      enable,
      callback,
    };
  },
  enableEmails(id: number, enable: boolean, callback?: ActionCallback): EnableClientEmailsAction {
    return {
      type: 'CLIENT_ENABLED_EMAILS',
      id,
      enable,
      callback,
    };
  },
  setICD10(id: number, icd10: string, callback?: ActionCallback): SetICD10CodeAction {
    return {
      type: 'CLIENT_SET_ICD10_CODE',
      id,
      icd10,
      callback,
    };
  },
  archive(id?: number, callback?: ActionCallback): ArchiveClientSubscriptionAction {
    return {
      type: 'CLIENT_ARCHIVE',
      id,
      callback,
    };
  },
  approve(id?: number, callback?: ActionCallback): ApproveClientSubscriptionAction {
    return {
      type: 'CLIENT_APPROVE',
      id,
      callback,
    };
  },
  approveNoShow(id?: number, callback?: ActionCallback): NoSowClientSubscriptionAction {
    return {
      type: 'CLIENT_NO_SHOW',
      id,
      callback,
    };
  },
  pause(id?: number, data?: any, callback?: ActionCallback): PauseClientAction {
    return {
      type: 'CLIENT_PAUSE',
      id,
      data,
      callback,
    };
  },
  unpause(id?: number, callback?: ActionCallback): UnpauseClientAction {
    return {
      type: 'CLIENT_UNPAUSE',
      id,
      callback,
    };
  },
  unarchive(id?: number, callback?: ActionCallback): UnarchiveClientAction {
    return {
      type: 'CLIENT_UNARCHIVE',
      id,
      callback,
    };
  },
  edit(id?: number, data?: any, callback?: ActionCallback): EditClientAction {
    return {
      type: 'CLIENT_EDIT',
      id,
      data,
      callback,
    };
  },
  loader(status: boolean): ClientLoaderAction {
    return {
      type: 'CLIENTS_LOADER',
      payload: {
        loader: status,
      },
    };
  },
  loaderModal(status: boolean): ClientModalLoaderAction {
    return {
      type: 'CLIENTS_LOADER_MODAL',
      payload: {
        loaderModal: status,
      },
    };
  },
  loaderReminders(status: boolean): ClientRemindersLoaderAction {
    return {
      type: 'CLIENTS_LOADER_REMINDERS',
      payload: {
        loaderReminders: status,
      },
    };
  },
  create: (data: any, role: string): void => {
    store.dispatch({
      type: 'CLIENT',
      payload: {
        item: mapping.clientBuild(data, role),
      },
    });
  },
  clear(): void {
    store.dispatch({
      type: 'CLIENT',
      payload: {
        item: {},
      },
    });
  },
  change(data?: any, callback?: ActionCallback): ChangeClientGroupAction {
    return {
      type: 'CHANGE_GROUP_CLIENT',
      data,
      callback,
    };
  },
  cancel(data?: any, callback?: ActionCallback): CancelClientGroupAction {
    return {
      type: 'CANCEL_GROUP_CLIENT',
      data,
      callback,
    };
  },
};

export default clientAction;
