import React, { FC } from 'react';
// types
import { IActionListItem } from 'types/app-types';
// components
import MoreDropdown from 'components/molecules/popovers/MoreDropdown';
// styles
import './style.scss';
import { useHistory } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import clientsAction from 'redux/actions/clients';
import { MembersMiniList } from 'components/organisms/cards/members';

const MissingICDCard: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  // const items = useSelector((state: any) => state.clients.items, shallowEqual);
  // const params = useSelector((state: any) => state.clients.params, shallowEqual);
  // const count = useSelector((state: any) => state.clients.count, shallowEqual);
  React.useEffect(() => {
    dispatch(clientsAction.getMissingICDClients());
  }, [dispatch]);

  const { missingClients } = useSelector((state: any) => state.clients, shallowEqual);

  return (
    <>
      {missingClients?.length > 0 && (
        <div className="card">
          <div className="card__header">
            <div className="card__title">Clients Missing ICD-10 Code</div>
          </div>
          <div className="session-list__items" style={{ maxHeight: 535 }}>
            <div className="session-list__members">
              <MembersMiniList
                members={missingClients.map((item: any) => {
                  return {
                    id: item.id,
                    firstname: item.firstname,
                    lastname: item.lastname,
                    color: item.color,
                    clientId: item.id,
                  };
                })}
                title="Clients missing ICD-10 Codes"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MissingICDCard;
