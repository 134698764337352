import React, { FC } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import Moment from 'react-moment';
// components
import ApproveClientButton from '../../organisms/modals/approve';
import AdminChangeGroup from '../../organisms/modals/adminChangeGroup';
import NoShowClientButton from '../../organisms/modals/approve/noShow';
// types
import { stateType } from '../../../types/types';
//style
import './style.scss';
// configs
import { createLicenseList } from '../../../tools/helpFunctions';
import { timeConvertor, week_days } from '../../../configs/tables/admin/clients';
import moment from 'moment-timezone';

const ApproveClient: FC = () => {
  const item: any = useSelector((state: stateType) => state.clients.item, shallowEqual);
  return (
    <div className="approve">
      {item?.adminApprovalLogs.length
        ? item.adminApprovalLogs.reverse().map((elem: any, i: number) => (
            <React.Fragment key={i}>
              <div className="approve__text">
                <span>Admin Log:</span> <Moment format="h:mm A MMM D YYYY">{elem?.actionAt}</Moment>, Admin{' '}
                {`${elem?.actionBy.firstname} ${elem?.actionBy.lastname}`}, {elem?.actionName}{' '}
                {elem?.therapist && elem?.groupTopic && elem?.actionName !== 'Client No-Showed' ? (
                  <p>
                    Group info:
                    {` ${elem.groupTopic} Group, ${week_days[elem.dayOfWeek - 1]}s 
                  ${timeConvertor(elem.startGroup, 'small-format')} - ${timeConvertor(
                      elem.endGroup,
                      'small-format',
                    )}, ${elem?.therapist?.title ? elem?.therapist?.title + '. ' : ''}${elem?.therapist?.firstname} ${
                      elem?.therapist?.lastname
                    } ${createLicenseList(elem?.therapist?.licenses, true)}`}
                  </p>
                ) : (
                  ''
                )}
              </div>
              {i === item.adminApprovalLogs.length - 1 && elem?.preferableGroupInfo && (
                <PreferableGroupInfo info={elem.preferableGroupInfo} />
              )}
            </React.Fragment>
          ))
        : null}
      {item?.preferableGroupInfo && <PreferableGroupInfo info={item} />}
      {item.features.canApprove && (
        <div className="approve__buttons">
          <ApproveClientButton
            disabled={
              item.preferableGroupInfo ||
              !!item?.adminApprovalLogs?.[item.adminApprovalLogs.length - 1]?.preferableGroupInfo
            }
          />
          <AdminChangeGroup id={item.id} status={item.status.value} />
          <NoShowClientButton />
        </div>
      )}
    </div>
  );
};

export default ApproveClient;

const PreferableGroupInfo: FC<any> = ({ info }: any) => {
  const timeFrom = moment(info?.groupTimeFrom + '+00:00').format('h:mmA');
  const timeTo = moment(info?.groupTimeTo + '+00:00').format('h:mmA dddd');

  return (
    <div className="approve__text">
      <span>Sign-Up Group Selection:</span> {`${info?.name}, therapist: ${info?.therapist}`}, {' ' + timeFrom}
      {' - '}
      {' ' + timeTo}s
    </div>
  );
};
