import React, { FC } from 'react';
import { Select } from 'antd';

interface ISelect {
  placeholder: string;
  onChange: (...args: Array<any>) => void;
  options: Array<any>;
  defaultValue: any;
}

const CommonSelect: FC<ISelect> = ({ placeholder, onChange, options, defaultValue }: ISelect) => {
  return (
    <Select
      className="form-select"
      dropdownClassName="form-dropdown"
      placeholder={placeholder}
      style={{ width: '100%' }}
      onChange={onChange}
      options={options}
      allowClear
      defaultValue={defaultValue}
      getPopupContainer={(trigger) => trigger.parentNode}
    />
  );
};

export default CommonSelect;
