/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { call, put, select } from 'redux-saga/effects';
import showError from './showError';
// api
import { apiAccount } from '../../api/account';

// actions
import accountAction from '../actions/account';
import stripeAction from '../actions/stripe';
// help
import { accountMapping } from '../../api/mapping/response/account';
import { accountErrors } from './error-messages';

export function* sagaGetAccount(action) {
  yield put(accountAction.loader(true));
  yield put(accountAction.set({}));

  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);

  try {
    const response = yield call(apiAccount.get, action.role);

    yield put(accountAction.set(accountMapping.build(response.data.data.item, role)));
    yield put(accountAction.loader(false));
  } catch (e) {
    yield put(accountAction.loader(false));
    showError();
  }
}

export function* sagaGetAccountInfo() {
  yield put(accountAction.loader(true));
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);

  try {
    const response = yield call(apiAccount.subscription, role.toLowerCase());
    yield put(accountAction.setInfo(accountMapping.customerInfoBuild(response.data.data.item)));
    yield put(accountAction.loader(false));
  } catch (e) {
    showError();
  }
}

export function* sagaSendToSupport(action) {
  yield put(accountAction.loader(true));
  try {
    yield call(apiAccount.sendSupport, action.data);
    yield put(accountAction.loader(false));

    if (action.callback) {
      action.callback();
    }
  } catch (e) {
    yield put(accountAction.loader(false));
    showError();
  }
}

export function* sagaCheckEditToken() {
  yield put(accountAction.loaderModal(true));

  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);
  try {
    const response = yield call(
      apiAccount.editCheckToken,
      localStorage.getItem('edit_token') ? localStorage.getItem('edit_token') : 'token',
      role.toLowerCase(),
    );

    yield put(accountAction.loaderModal(false));
    yield put(accountAction.varibleToken(response.data.data.expired));
  } catch (e) {
    yield put(accountAction.loaderModal(false));
    yield put(accountAction.varibleToken(true));
  }
}

export function* sagaGetEditToken(action) {
  yield put(accountAction.loaderModal(true));

  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);
  try {
    const response = yield call(apiAccount.getEditToken, action.data, role.toLowerCase());

    yield put(accountAction.loaderModal(false));
    yield put(accountAction.varibleToken(false));
    localStorage.setItem('edit_token', response.data.data.item.token);
  } catch (e) {
    yield put(accountAction.loaderModal(false));
    yield put(accountAction.varibleToken(true));

    if (e.response.status === 403) {
      action.errorsCallback({ input: 'password', message: accountErrors.INCORRECT_PASSWORD });
    } else {
      showError();
    }
  }
}

export function* sagaEditAccount(action) {
  yield put(accountAction.loaderModal(true));

  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);
  try {
    const response = yield call(apiAccount.edit, action.data, role.toLowerCase());

    yield put(accountAction.loaderModal(false));

    yield put(accountAction.set(accountMapping.build(response.data.data.item, role)));

    localStorage.setItem('token', response.data.data.item.userTokensData.token);
    localStorage.setItem('refresh_token', response.data.data.item.userTokensData.refreshToken);

    if (action.callback) {
      action.callback();
    }
  } catch (e) {
    yield put(accountAction.loaderModal(false));
    if (e.response.status === 403) {
      yield put(accountAction.varibleToken(true));
    }

    if (action.data.type === 'payment-method') {
      yield put(stripeAction.loader(false));
      action.callback && action.callback();
    }
    showError();
  }
}

export function* sagaSetAvatar(action) {
  yield put(accountAction.loaderModal(true));

  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);

  try {
    const response = yield call(apiAccount.setAvatar, { avatarUuid: action.file.uuid }, role.toLowerCase());
    yield put(accountAction.set(accountMapping.build(response.data.data.item, role)));

    yield put(accountAction.loaderModal(false));

    if (action.callback) {
      action.callback();
    }
  } catch (e) {
    showError();
  } finally {
    yield put(accountAction.loaderModal(false));
  }
}

export function* sagaGetNotifications() {
  yield put(accountAction.loader(true));

  try {
    const response = yield call(apiAccount.getNotifications);
    yield put(accountAction.getNotifications(response.data.data));
    yield put(accountAction.loader(false));
  } catch (e) {
    yield put(accountAction.loader(false));
    showError();
  } finally {
    yield put(accountAction.loaderModal(false));
  }
}

export function* sagaReadNewNotification(action) {
  try {
    yield call(apiAccount.readNewNotifications, action.data);
  } catch (e) {
    showError();
  }
}

export function* sagaCheckNotification() {
  try {
    const response = yield call(apiAccount.checkNotifications);
    yield put(accountAction.checkNotifications(response.data.data));
  } catch (e) {
    showError();
  }
}
