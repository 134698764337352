import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import MainTemplate from '../../components/templates/mainTemplate';
import { BodyPageContainer, HeaderPageContainer } from '../../components/containers/pageContainer';
import VideoApp from '../../components/video/videoApp';
import AppStateProvider, { useAppState } from '../../state';

const VideoPage: React.FC = ({ computedMatch }: any) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { setMeetingType } = useAppState();

  useEffect(() => {
    setMeetingType('session');
  }, []);

  return <VideoApp />;
};

export default VideoPage;
