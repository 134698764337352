import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Spin } from 'antd';
// components
import AccountAdmin from '../../../organisms/account/admin';
import BoxAccontContainer from '../../boxAccountContainer';
// types
import { stateType } from '../../../../types/types';

const AccountAdminContainer: React.FC = () => {
  const loader = useSelector((state: stateType) => state.account.loader, shallowEqual);
  return (
    <BoxAccontContainer>
      <Spin spinning={loader}>
        <AccountAdmin />
      </Spin>
    </BoxAccontContainer>
  );
};

export default AccountAdminContainer;
