import { useContext } from 'react';
import { FilesContext } from '../../components/video/FilesProvider/FilesProvider';

export default function useFilesContext() {
  const context = useContext(FilesContext);
  if (!context) {
    throw new Error('useFilesContext must be used within a FilesProvider');
  }
  return context;
}
