import { ActionCallback, FlowType, LoaderAction, LoaderModalAction } from '../../types/actions/common-types';
import store from '../store';
// actions
// import mainMenuAction from './main_menu'

type FlowTypeAction<Type = string> = {
  type: Type;
  flowType: FlowType;
  number: number;
  callback?: ActionCallback;
};

type NextQuestionAction = FlowTypeAction<'GET_NEXT_QUESTION'>;
type AfterQuestionAction = FlowTypeAction<'GET_AFTER_QUESTION'>;
type QuestionAnswerAction = {
  type: 'PUSH_ANSWER_QUESTION';
  flowType: FlowType;
  callback?: ActionCallback;
  data: any;
};
type QuestionsLoaderAction = LoaderAction<'QUESTION_LOADER'>;
type QuestionsLoaderModalAction = LoaderModalAction<'QUESTION_LOADER_MODAL'>;

const questionsActions: any = {
  next(flowType: FlowType, number?: any, callback?: ActionCallback): NextQuestionAction {
    return {
      type: 'GET_NEXT_QUESTION',
      flowType,
      number,
      callback,
    };
  },

  after(flowType: FlowType, number?: any, callback?: ActionCallback): AfterQuestionAction {
    return {
      type: 'GET_AFTER_QUESTION',
      flowType,
      number,
      callback,
    };
  },

  answer(flowType: FlowType, data: any, callback: ActionCallback): QuestionAnswerAction {
    return {
      type: 'PUSH_ANSWER_QUESTION',
      flowType,
      data,
      callback,
    };
  },

  status(status: any): void {
    store.dispatch({
      type: 'QUESTION_STATUS',
      payload: {
        status: status,
      },
    });
  },

  create(data: any): void {
    store.dispatch({
      type: 'QUESTION_ITEM',
      payload: {
        item: data,
      },
    });
  },

  clear(): void {
    store.dispatch({
      type: 'QUESTION_ITEM',
      payload: {
        item: {},
      },
    });
  },

  loader(status: boolean): QuestionsLoaderAction {
    return {
      type: 'QUESTION_LOADER',
      payload: {
        loader: status,
      },
    };
  },

  loaderModal(status: boolean): QuestionsLoaderModalAction {
    return {
      type: 'QUESTION_LOADER_MODAL',
      payload: {
        loaderModal: status,
      },
    };
  },
};

export default questionsActions;
