/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import moment from 'moment';
import { fixDayOfWeek } from '../../../../tools/helpFunctions';

export const mapping = {
  rescheduleSessionBuild(data) {
    const isMidnight = moment(data.end).format('HH:mm') === '00:00';

    const start = moment(data.date).add(moment(data.start).format('HH:mm'));
    const end = moment(data.date).add(moment(data.end).format('HH:mm'));

    return {
      start: new Date(start),
      end: isMidnight ? new Date(end.add(1, 'day')) : new Date(end),
    };
  },

  rescheduleGroupBuild(data) {
    return {
      dayOfWeek: fixDayOfWeek(data.dayOfWeek, data.start, 'to_utc'),
      start: mapping.covertToInt(data.start.clone().subtract(1, 'days').utc('UTC').format('HH:mm')),
      end: mapping.covertToInt(data.end.clone().subtract(1, 'days').utc('UTC').format('HH:mm')),
    };
  },

  covertToInt(string) {
    let h = 0;
    let m = 0;

    if (Number(string.split(':')[0]) > 0) {
      h = Number(string.split(':')[0]) * 60;
    }

    if (Number(string.split(':')[1]) > 0) {
      m = Number(string.split(':')[1]);
    }
    let result = h + m;
    return result;
  },
};
