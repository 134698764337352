import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Input, Rate, Select, Spin } from 'antd';
// types
import { stateType } from '../../../types/types';
// configs
import mappingQuestions from '../../../api/mapping/response/questions';
// components
import { GroupAnswer, SingleLargeAnswer } from './colections';
import SelectGroupsList from '../../organisms/changeGroup/groups/SelectGroupsList';
// actions
import choiceGroupAction from '../../../redux/actions/choice_group';

interface AnswerControllerType {
  question: any;
  rating: number;
  answerText: string;
  actions: {
    setCheckedValues: any;
    setAnswerText: any;
    onChangeRate: any;
    goNext: any;
    onChangeSingleAnswer: any;
  };
}

export const AnswerController: React.FC<AnswerControllerType> = ({
  question,
  rating,
  answerText,
  actions,
}: AnswerControllerType) => {
  const { setCheckedValues, setAnswerText, onChangeRate, goNext, onChangeSingleAnswer } = actions;

  const answerController = () => {
    switch (question.questionType) {
      case 'multi':
        return <AnswerMulti goNext={goNext} onChange={setCheckedValues} />;

      case 'single':
        return <AnswerSingleLarge onChange={onChangeSingleAnswer} />;

      case 'single_large':
        return <AnswerSingleLarge onChange={onChangeSingleAnswer} />;

      case 'evaluation':
        return (
          <AnswerEvaluation
            rating={rating}
            answerText={answerText}
            setAnswerText={setAnswerText}
            onChangeRate={onChangeRate}
            goNext={goNext}
          />
        );

      case 'group':
        return <AnswerGroup goNext={goNext} />;

      default:
        return <></>;
    }
  };

  return answerController();
};

// answer`s
export const AnswerMulti: React.FC<any> = ({ goNext, onChange }: any) => {
  const question: any = useSelector((state: stateType) => state.questions.item, shallowEqual);
  const [width, setWidth] = useState(window.innerWidth);

  const getCount = () => {
    if (!width || width > 768) {
      return 'responsive';
    } else if (width < 768 && width > 480) {
      return 2;
    } else {
      return 1;
    }
  };

  return (
    <div className="change-group-container__answers">
      <div className="change-group-container__question">{question.question}</div>
      {question.number !== 6 && (
        <Select
          className="form-select"
          dropdownClassName="form-dropdown form-dropdown_questions"
          placeholder={'Select'}
          style={{ width: '100%' }}
          onChange={(value: any) => {
            onChange([value]);
          }}
          options={mappingQuestions.buildOptions(question.answers)}
          allowClear
          defaultValue={question.defaultValue ? question.defaultValue.answerIds[0] : undefined}
        />
      )}

      {question.number === 6 && (
        <Select
          mode="multiple"
          className={'form-select'}
          dropdownClassName="form-dropdown"
          placeholder={'Select'}
          style={{ width: '100%' }}
          onChange={(value: any) => {
            onChange(value);
          }}
          options={mappingQuestions.buildOptions(question.answers)}
          allowClear
          defaultValue={question.defaultValue ? question.defaultValue.answerIds[0] : undefined}
          maxTagCount={getCount()}
        />
      )}
      {question.number !== 6 && (
        <button onClick={goNext} className="btn-form">
          Next
        </button>
      )}
    </div>
  );
};

export const AnswerSingleLarge: React.FC<any> = ({ onChange }: any) => {
  const question: any = useSelector((state: stateType) => state.questions.item, shallowEqual);

  return (
    <div className="change-group-container__answers">
      <div className="change-group-container__question">{question.question}</div>
      <div className="change-group-container__sub-question">{question.additions?.subQuestion}</div>

      <SingleLargeAnswer answers={question.answers} setCheckedValues={onChange} />
    </div>
  );
};

export const AnswerEvaluation: React.FC<any> = ({ rating, answerText, setAnswerText, onChangeRate, goNext }: any) => {
  const desc = ['terrible', 'bad', 'normal', 'good', 'wonderful'];
  const question: any = useSelector((state: stateType) => state.questions.item, shallowEqual);

  return (
    <>
      <div className="change-group-container__question">{question.question}</div>
      <div className="change-group-container__sub-question">{question.additions.subQuestion}</div>

      <Rate tooltips={desc} onChange={onChangeRate} value={rating} style={{ fontSize: '31px', color: '#ff974a' }} />
      <div className="textarea-cotnainer">
        <span className="change-group-container__label">Please leave your confidential response here </span>
        <Input.TextArea
          value={answerText}
          rows={4}
          onChange={(e) => setAnswerText(e.target.value)}
          className="qustion-container__textarea"
        />
      </div>
      <button onClick={goNext} className="btn-form" disabled={!rating}>
        Next
      </button>
    </>
  );
};

export const AnswerGroup: React.FC<any> = ({ goNext }: any) => {
  const question: any = useSelector((state: stateType) => state.questions.item, shallowEqual);
  const groups: any = useSelector((state: stateType) => state.choice_group.item, shallowEqual);
  const loader: boolean = useSelector((state: stateType) => state.choice_group.loader, shallowEqual);
  const totalCount: number = useSelector((state: stateType) => state.choice_group.totalCount, shallowEqual);

  const [page, setPage] = useState(1);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      choiceGroupAction.params({ page: 1 });
    };
  }, []);

  useEffect(() => {
    if (page > 1) {
      choiceGroupAction.params({ page: page });
      dispatch(choiceGroupAction.get());
    }
  }, [page]);

  const onChange = async () => {
    if (totalCount > 4) {
      setPage(page + 1);
    }
  };

  return (
    <>
      <div className="change-group-container__question">{question.question}</div>
      <div className="change-group-container__sub-question">{question.additions.subQuestion}</div>
      <Spin spinning={loader}>{!loader && <SelectGroupsList groups={groups.length ? groups : [groups]} />}</Spin>

      <GroupAnswer question={question} goNext={goNext} onChange={onChange} />
    </>
  );
};
