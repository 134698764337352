import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  makeStyles,
  Theme,
} from '@material-ui/core';
import LeaveCallButton from '../../Buttons/LeaveCallButton/LeaveCallButton';
import EndCallButton from '../../Buttons/EndCallButton/EndCallButton';

interface EndCallDialogProps {
  open: boolean;
  onClose(): void;
}

const useStyle = makeStyles((theme: Theme) => ({
  cancelButton: {
    border: '1px solid rgba(208, 213, 221, 1)',
    backgroundColor: '#ffffff',
    color: '#000000',
    display: 'block',
    width: '30%',
    '&:hover': {
      backgroundColor: '#e1e1e1',
    },
  },
}));

export default function EndCallDialog({ open, onClose }: EndCallDialogProps) {
  const cssClasses = useStyle();

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="xs">
      <DialogTitle>Leave session</DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText>Are you sure you want to leave the session?</DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="contained" className={cssClasses.cancelButton} autoFocus>
          Cancel
        </Button>
        <LeaveCallButton />
        <EndCallButton />
      </DialogActions>
    </Dialog>
  );
}
