/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { AxiosResponse } from 'axios';
// config
import http from '../instance';

export const apiNotification = {
  getNotification: async (): Promise<AxiosResponse> => {
    const api = await http();
    return api.get(`customer/get-notification`);
  },

  readNotification: async (id: any, answerParams: boolean): Promise<AxiosResponse> => {
    const api = await http();
    return api.post(`customer/notification/${id}/${!answerParams ? 'read?answer=false' : 'read?answer=true'}`);
  },
};
