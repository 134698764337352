import React from 'react';
import { Spin, Tabs } from 'antd';
import { useSelector, shallowEqual } from 'react-redux';
import _ from 'lodash';
// components
import ClientCardMinimal from '../../../organisms/info/client/card/mini/index';
import ConsultationInfo from '../../../organisms/forms/consultationInfo';
import AssasmentList from '../../../molecules/lists/assasments/therapist';
// types
import { stateType } from '../../../../types/types';
// style
import './style.scss';

const TherapistClientInfoContainer: React.FC = () => {
  const { TabPane } = Tabs;
  const loader: any = useSelector((state: stateType) => state.clients.loader);
  const item: any = useSelector((state: stateType) => state.clients.item, shallowEqual);
  return (
    <Spin spinning={loader} style={{ minHeight: '480px' }}>
      {!loader && !_.isEmpty(item) && (
        <div className="page-client-container">
          <div className="page-client-container__column">
            <div className="info-client-container">
              <ClientCardMinimal />
              <ConsultationInfo />
            </div>
          </div>
          <div className="page-client-container__column">
            <Tabs defaultActiveKey="1" className="info-tabs">
              <TabPane tab="Assesments" key="assessments" className="info-tabs__item">
                <AssasmentList />
              </TabPane>
            </Tabs>
          </div>
        </div>
      )}
    </Spin>
  );
};

export default TherapistClientInfoContainer;
