/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

// helpers
import { fixDayOfWeek, ParseArray } from '../../../../tools/helpFunctions';

const colors = ['#6C63FF', '#1891E5', '#FC5A5A', '#82C43C'];

export const mapping = {
  clientsBuild(array, role) {
    switch (role) {
      case 'ADMIN':
        return array.map((item) => {
          return {
            id: item.id,
            name: item.firstname + ' ' + item.lastname,
            firstname: item.firstname,
            lastname: item.lastname,
            topic: item.medicalGroup ? item.medicalGroup.groupTopic.name : '',
            date: item.medicalGroup
              ? {
                  day: fixDayOfWeek(item.medicalGroup.dayOfWeek, item.medicalGroup.start, 'to_ny'),
                  start: item.medicalGroup.start,
                  end: item.medicalGroup.end,
                }
              : false,
            therapist: item.medicalGroup ? item.medicalGroup?.therapist.lastname : '',
            status: item.status ? item.status : null,
            color: colors[item.id % 4],
            markers: item?.customerMarkers,
          };
        });

      case 'THERAPIST':
        return array.map((item) => {
          return {
            id: item.id,
            name: item.firstname + ' ' + item.lastname,
            firstname: item.firstname,
            lastname: item.lastname,
            topic: item.medicalGroup ? item.medicalGroup.groupTopic.name : '',
            day: fixDayOfWeek(item.medicalGroup.dayOfWeek, item.medicalGroup.start, 'to_ny'),
            date: item.medicalGroup ? { start: item.medicalGroup.start, end: item.medicalGroup.end } : false,
            status: item.status ? item.status : null,
            color: colors[item.id % 4],
            markers: item?.customerMarkers,
          };
        });

      default:
        return array;
    }
  },

  clientBuild(item, role) {
    switch (role) {
      case 'ADMIN':
        return {
          ...item,
          medicalGroup: item?.medicalGroup
            ? {
                ...item.medicalGroup,
                dayOfWeek: fixDayOfWeek(item.medicalGroup.dayOfWeek, item.medicalGroup.start, 'to_ny'),
              }
            : null,
        };

      case 'THERAPIST':
        return {
          ...item,
          medicalGroup: item?.medicalGroup
            ? {
                ...item.medicalGroup,
                dayOfWeek: fixDayOfWeek(item.medicalGroup.dayOfWeek, item.medicalGroup.start, 'to_ny'),
              }
            : null,
        };

      default:
        return item;
    }
  },

  therapistsBuild(array) {
    return array.map((item) => {
      return {
        id: item.id,
        avatar: item.avatar ? item.avatar : false,
        name: item.firstname + ' ' + item.lastname,
        firstname: item.firstname,
        lastname: item.lastname,
        status: item.status ? item.status.name : '',
        members: item.members,
        state: item.state,
        activeGroupsAmount: item.activeGroups ? item.activeGroups.length : 0,
        activeGroups: item.activeGroups,
        posibleGroupsAmount: item.groupTopics ? item.groupTopics.length : 0,
        posibleGroups: item.groupTopics,
        licensure: ParseArray(item.licenses),
        membersCount: item.membersCount,
        color: colors[item.id % 4],
      };
    });
  },

  groupsBuild(array, role) {
    switch (role) {
      case 'ADMIN':
        return array.map((item) => {
          return {
            id: item.id,
            avatar: item.avatar ? item.avatar : false,
            topic: item.groupTopic.name ? item.groupTopic.name : '',
            name: item.groupTopic ? item.groupTopic.name : '',
            date: item ? { day: item.dayOfWeek, start: item.start, end: item.end } : false,
            therapist: item.therapist.name + ' ' + item.therapist.lastname,
            status: item.status ? item.status : null,
            members: item.members,
            membersCount: item.membersCount,
            color: colors[item.id % 4],
          };
        });

      case 'THERAPIST':
        return array.map((item) => {
          return {
            id: item.id,
            avatar: item.avatar ? item.avatar : false,
            name: item.groupTopic ? item.groupTopic.name : '',
            date: item
              ? { day: fixDayOfWeek(item.dayOfWeek, item.start, 'to_ny'), start: item.start, end: item.end }
              : false,
            status: item.status ? item.status : null,
            members: item.members,
            day: item.dayOfWeek ? fixDayOfWeek(item.dayOfWeek, item.start, 'to_ny') : '',
            membersCount: item.membersCount,
            color: colors[item.id % 4],
          };
        });

      default:
        return array;
    }
  },

  membersResultBuild(data) {
    return data.map((item) => {
      return {
        id: item.id,
        firstname: item.firstname,
        lastname: item.lastname,
        color: colors[item.id % 4],
      };
    });
  },

  groupBuild(data, role) {
    switch (role) {
      case 'ADMIN':
        return {
          group: {
            id: data.id,
            name: data.groupTopic.name,
            topic_id: data.groupTopic.id,
            date: {
              dayOfWeek: data.dayOfWeek,
              end: data.end,
              start: data.start,
            },
            therapist_name: data.therapist.firstname + ' ' + data.therapist.lastname,
            therapist_id: data.therapist.id,
            therapist_title: data.therapist.title,
            url: data.avatar ? data.avatar.url : '',
            about: data.description,
            status: data.status,
            tags: data.tags,
            avatar: data.avatar ? data.avatar : false,
            features: data.features ? data.features : false,
          },
          therapist: {
            id: data.therapist.id,
            avatar: data.therapist.avatar ? data.therapist.avatar.url : '',
            name: data.therapist.firstname + ' ' + data.therapist.lastname,
            therapist_title: data.therapist.title,
            licensure: data.therapist.licenses ? ParseArray(data.therapist.licenses) : '',
            practiceYears: data.therapist.practiceYears,
            specialties:
              data.therapist.specialties.length > 0
                ? data.therapist.specialties.map((item, index) => {
                    if (index > 0) {
                      return `, ${item.name}`;
                    }

                    return `${item.name}`;
                  })
                : null,
            description: data.therapist.biography,
          },
          members: data.members.map((item) => {
            return {
              id: item.id,
              firstname: item.firstname,
              lastname: item.lastname,
              color: colors[item.id % 4],
            };
          }),
        };

      case 'THERAPIST':
        return {
          group: {
            id: data.id,
            name: data.groupTopic.name,
            date: {
              dayOfWeek: fixDayOfWeek(data.dayOfWeek, data.start, 'to_ny'),
              end: data.end,
              start: data.start,
            },
            therapist_name: data.therapist.firstname + ' ' + data.therapist.lastname,
            url: data.avatar ? data.avatar.url : '',
            about: data.description,
            status: data.status,
            tags: data.tags,
            avatar: data.avatar ? data.avatar : false,
          },
          therapist: {
            avatar: data.therapist.avatar ? data.therapist.avatar.url : '',
            name: data.therapist.firstname + ' ' + data.therapist.lastname,
            firstname: data.therapist.firstname,
            lastname: data.therapist.lastname,
            licensure: data?.therapist?.licenses?.length ? ParseArray(data.therapist.licenses) : '',
            practiceYears: data.therapist.practiceYears,
            title: data?.therapist?.title,
            specialties: data.therapist.specialties
              ? data.therapist.specialties.length > 0
                ? data.therapist.specialties.map((item, index) => {
                    if (index > 0) {
                      return `, ${item.name}`;
                    }

                    return `${item.name}`;
                  })
                : null
              : null,
            description: data.therapist.biography,
          },
          members: data.members.map((item, index) => {
            return {
              id: index,
              firstname: item.firstname,
              lastname: item.lastname,
              clientId: item.id,
              color: colors[item.id % 4],
            };
          }),
        };

      default:
        return data;
    }
  },
};
