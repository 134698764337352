import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Table } from 'antd';
// configs
import { columns } from './column';
// types
import { stateType } from '../../../../../types/types';
// style
import './../../style.scss';
import moment from 'moment-timezone';
import eventsAction from '../../../../../redux/actions/admin_events';
import upcomingAction from '../../../../../redux/actions/upcoming_events';

// region mockEventsItems
export const mockEventsItems = [
  {
    id: 1,
    date: moment(),
    maxParticipants: 49,
    topic: [1, 3, 2],
    subtitle: 'Knowing when to take a break',
    therapistId: 3,
    time: moment(),
    timeLength: 1,
    title: 'Social Media in the 21st Century',
    type: 'Webinar',
    picture: {
      url:
        'https://backend-grouport.challengesoft.tech/uploads/files/media/cache/community_event_picture_upcoming/pictures/98fcd289-6ff9-482a-88ec-49733a8a60de.png',
    },
  },
  {
    id: 2,
    date: moment(),
    maxParticipants: 30,
    topic: [1, 3, 2],
    subtitle: 'Knowing when to take a break',
    therapistId: 3,
    time: moment(),
    timeLength: 1,
    title: 'Dealing with PTSD',
    type: 'Session',
    picture: {
      url:
        'https://backend-grouport.challengesoft.tech/uploads/files/media/cache/community_event_picture_upcoming/pictures/98fcd289-6ff9-482a-88ec-49733a8a60de.png',
    },
  },
  {
    id: 3,
    date: moment(),
    maxParticipants: 50,
    topic: [1, 3, 2],
    subtitle: 'Knowing when to take a break',
    therapistId: 3,
    time: moment(),
    timeLength: 1,
    title: 'Social Media in the 21st Century',
    type: 'Webinar',
    picture: {
      url:
        'https://backend-grouport.challengesoft.tech/uploads/files/media/cache/community_event_picture_upcoming/pictures/98fcd289-6ff9-482a-88ec-49733a8a60de.png',
    },
  },
  {
    id: 4,
    date: moment(),
    maxParticipants: 55,
    topic: [1, 3, 2],
    subtitle: 'Knowing when to take a break',
    therapistId: 3,
    time: moment(),
    timeLength: 1,
    title: 'Social Media in the 21st Century',
    type: 'Webinar',
    picture: {
      url:
        'https://backend-grouport.challengesoft.tech/uploads/files/media/cache/community_event_picture_upcoming/pictures/98fcd289-6ff9-482a-88ec-49733a8a60de.png',
    },
  },
  {
    id: 5,
    date: moment(),
    maxParticipants: 12,
    topic: [1, 3, 2],
    subtitle: 'Knowing when to take a break',
    therapistId: 3,
    time: moment(),
    timeLength: 1,
    title: 'Social Media in the 21st Century',
    type: 'Session',
    picture: {
      url:
        'https://backend-grouport.challengesoft.tech/uploads/files/media/cache/community_event_picture_upcoming/pictures/98fcd289-6ff9-482a-88ec-49733a8a60de.png',
    },
  },
  {
    id: 6,
    date: moment(),
    maxParticipants: 76,
    topic: [1, 3, 2],
    subtitle: 'Knowing when to take a break',
    therapistId: 3,
    time: moment(),
    timeLength: 1,
    title: 'Social Media in the 21st Century',
    type: 'Webinar',
    picture: {
      url:
        'https://backend-grouport.challengesoft.tech/uploads/files/media/cache/community_event_picture_upcoming/pictures/98fcd289-6ff9-482a-88ec-49733a8a60de.png',
    },
  },
  {
    id: 7,
    date: moment(),
    maxParticipants: 35,
    topic: [1, 3, 2],
    subtitle: 'Knowing when to take a break',
    therapistId: 3,
    time: moment(),
    timeLength: 1,
    title: 'Social Media in the 21st Century',
    type: 'Webinar',
    picture: {
      url:
        'https://backend-grouport.challengesoft.tech/uploads/files/media/cache/community_event_picture_upcoming/pictures/98fcd289-6ff9-482a-88ec-49733a8a60de.png',
    },
  },
  {
    id: 8,
    date: moment(),
    maxParticipants: 24,
    topic: [1, 3, 2],
    subtitle: 'Knowing when to take a break',
    therapistId: 3,
    time: moment(),
    timeLength: 1,
    title: 'Dealing with PTSD',
    type: 'Session',
    picture: {
      url:
        'https://backend-grouport.challengesoft.tech/uploads/files/media/cache/community_event_picture_upcoming/pictures/98fcd289-6ff9-482a-88ec-49733a8a60de.png',
    },
  },
  {
    id: 9,
    date: moment(),
    maxParticipants: 99,
    topic: [1, 3, 2],
    subtitle: 'Knowing when to take a break',
    therapistId: 3,
    time: moment(),
    timeLength: 1,
    title: 'Social Media in the 21st Century',
    type: 'Webinar',
    picture: {
      url:
        'https://backend-grouport.challengesoft.tech/uploads/files/media/cache/community_event_picture_upcoming/pictures/98fcd289-6ff9-482a-88ec-49733a8a60de.png',
    },
  },
  {
    id: 10,
    date: moment(),
    maxParticipants: 11,
    topic: [1, 3, 2],
    subtitle: 'Knowing when to take a break',
    therapistId: 3,
    time: moment(),
    timeLength: 1,
    title: 'Social Media in the 21st Century',
    type: 'Webinar',
    picture: {
      url:
        'https://backend-grouport.challengesoft.tech/uploads/files/media/cache/community_event_picture_upcoming/pictures/98fcd289-6ff9-482a-88ec-49733a8a60de.png',
    },
  },
  {
    id: 11,
    date: moment(),
    maxParticipants: 45,
    topic: [1, 3, 2],
    subtitle: 'Knowing when to take a break',
    therapistId: 3,
    time: moment(),
    timeLength: 1,
    title: 'Social Media in the 21st Century',
    type: 'Session',
    picture: {
      url:
        'https://backend-grouport.challengesoft.tech/uploads/files/media/cache/community_event_picture_upcoming/pictures/98fcd289-6ff9-482a-88ec-49733a8a60de.png',
    },
  },
  {
    id: 12,
    date: moment(),
    maxParticipants: 14,
    topic: [1, 3, 2],
    subtitle: 'Knowing when to take a break',
    therapistId: 3,
    time: moment(),
    timeLength: 1,
    title: 'Dealing with PTSD',
    type: 'Webinar',
    picture: {
      url:
        'https://backend-grouport.challengesoft.tech/uploads/files/media/cache/community_event_picture_upcoming/pictures/98fcd289-6ff9-482a-88ec-49733a8a60de.png',
    },
  },
];
// endregion

const EventsTable: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const allParams: any = useSelector((state: stateType) => state.upcoming_events.allParams, shallowEqual);
  const { loader, events, count, params } = useSelector((state: stateType) => state.events, shallowEqual);
  return (
    <Table
      rowKey={'id'}
      className="clients-table"
      columns={columns}
      dataSource={loader ? undefined : events}
      loading={loader}
      scroll={{ y: 570 }}
      locale={{ emptyText: <div> </div> }}
      pagination={{
        style: { visibility: `${count <= 10 ? 'hidden' : 'visible'}` },
        size: 'small',
        showQuickJumper: true,
        defaultPageSize: 10,
        pageSize: allParams?.per_page || params.per_page,
        current: allParams?.page || params.page,
        total: count,
        pageSizeOptions: ['10', '20', '25', '30', '50', '100'],
        position: ['bottomCenter'],
        onChange: async (page, pageSize) => {
          try {
            dispatch(eventsAction.params({ ...allParams, page, per_page: pageSize }));
            dispatch(upcomingAction.allParams({ ...allParams, page, per_page: pageSize }));
            dispatch(upcomingAction.getEventsAndPosts());
          } catch (e) {
            console.log(e);
          }
        },
      }}
      onRow={(record, rowIndex) => {
        return {
          onClick: () => {
            history.push(`/event_details/${record.id}`);
          },
        };
      }}
    />
  );
};

export default EventsTable;
