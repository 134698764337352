/*
 *
 * Account reducer
 *
 */
const initialState = {
  item: {},
  info: {},
  loader: true,
  loaderModal: false,
  varibleToken: false,
  notification: {},
  checkNotification: {},
};

export type accountInitialStateType = typeof initialState;

interface accountActionTypes {
  type: string;
  payload: {
    item: any;
    info: any;
    loader: boolean;
    loaderModal: boolean;
    varibleToken: boolean;
    notification: any;
    checkNotification: any;
  };
}

const account = (state = initialState, action: accountActionTypes): accountInitialStateType => {
  switch (action.type) {
    case 'ACCOUNT':
      return Object.assign({}, state, {
        item: action.payload.item,
      });
    case 'ACCOUNT_INFO':
      return Object.assign({}, state, {
        info: action.payload.info,
      });
    case 'ACCOUNT_LOADING':
      return Object.assign({}, state, {
        loader: action.payload.loader,
      });
    case 'ACCOUNT_LOADING_MODAL':
      return Object.assign({}, state, {
        loaderModal: action.payload.loaderModal,
      });
    case 'ACCOUNT_VARIBLE_TOKEN':
      return Object.assign({}, state, {
        varibleToken: action.payload.varibleToken,
      });
    case 'GET_ACCOUNT_NOTIFICATIONS':
      return Object.assign({}, state, {
        notification: action.payload.notification,
      });
    case 'CHECK_NOTIFICATIONS':
      return Object.assign({}, state, {
        checkNotification: action.payload.checkNotification,
      });

    default:
      return state;
  }
};

export default account;
