import React, { useCallback, useEffect, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Spin from 'antd/lib/spin';
import { useHistory } from 'react-router-dom';
// components
import SimpleHeader from '../../components/organisms/headers/simple/simple-header';
import SelectGroups from '../../components/organisms/changeGroup/groups';
import CloseChangeBtn from '../../components/molecules/groupQuestions/CloseQuestion';
import LoadingModal from '../../components/molecules/modals';
// actions
import choiceGroupAction from '../../redux/actions/choice_group';
import changeGroupActions from '../../redux/actions/change_group';
// types
import { stateType } from 'types/types';
import { SearchGroupQuery } from '../../types/actions/common-types';
// configs
import { useIsMounted } from '../../configs/hooks/useIsMounted';
// consts
import { TOTAL_GROUPS_NUMBER } from '../../tools/consts';

const ChangeGroupPage: React.FC = () => {
  const ref = useRef<HTMLDivElement>(null);

  const [page, setPage] = useState<number>(1);

  const dispatch = useDispatch();
  const history = useHistory();

  const { item, loader, loaderModal }: any = useSelector((state: stateType) => state.choice_group, shallowEqual);

  useEffect(() => {
    dispatch(choiceGroupAction.get(SearchGroupQuery.Change));

    return () => {
      setPage(1);
      choiceGroupAction.params({ page: 1 });
    };
  }, []);

  const onSeeOtherGroup = (): void => {
    if (page * TOTAL_GROUPS_NUMBER < item.length) {
      setPage(page + 1);
    } else {
      setPage(1);
    }
  };

  const onGoBack = useCallback((): void => {
    dispatch(changeGroupActions.goBack(() => history.push('profile/change-group')));
  }, []);

  const isMounted = useIsMounted();

  useEffect(() => {
    ref?.current?.scrollIntoView();
  }, [page]);

  return (
    <div className="choice-group-page custom-style-group">
      <div className="change-group-template" ref={ref}>
        <SimpleHeader />
        <Spin spinning={!Array.isArray(item)}>
          <div className="change-group-template__body">
            <div className="change-group-template__header">
              <CloseChangeBtn circle />
            </div>
            {!loaderModal && isMounted() && (
              <Spin spinning={loader}>
                <div className="change-group-template__groups">
                  {Array.isArray(item) && (
                    <SelectGroups
                      reschedule
                      onSeeOtherGroup={onSeeOtherGroup}
                      onGoBack={onGoBack}
                      groups={item.slice((page - 1) * TOTAL_GROUPS_NUMBER, page * TOTAL_GROUPS_NUMBER)}
                      redirect
                    />
                  )}
                </div>
              </Spin>
            )}
            {loaderModal && (
              <LoadingModal
                visible={loaderModal}
                title="You’re all set!"
                text="Hang on while we find the group that’s best for you."
              />
            )}
          </div>
        </Spin>
      </div>
    </div>
  );
};

export default ChangeGroupPage;
