import { ActionCallback } from '../../types/actions/common-types';
import StripeAction, {
  CanselStripeAction,
  ChangeStripeAction,
  CheckedStripeAction,
  CheckPromoAction,
  ChooseStripeAction,
  ConfirmStripeAction,
  GetStripeAction,
  InvalidPromoInfoAction,
  LoaderAction,
  LoaderModalAction,
  PageStripeAction,
  PromoInfoAction,
  SecredStripeAction,
  SetItemStripeAction,
  SetStripeAction,
} from '../../types/actions/stripe-actions';

const stripeAction: StripeAction = {
  get(callback?: () => ActionCallback): GetStripeAction {
    return {
      type: 'GET_STRIPE_LIST',
      callback,
    };
  },
  choose(id: any, couponName: any, callback?: () => ActionCallback): ChooseStripeAction {
    return {
      type: 'STRIPE_CHOOSE',
      id,
      couponName,
      callback,
    };
  },

  checkPromo(couponName: any, callback?: () => ActionCallback): CheckPromoAction {
    return {
      type: 'CHECK_PROMO',
      couponName,
      callback,
    };
  },
  confirm(id: number, callback?: () => ActionCallback): ConfirmStripeAction {
    return {
      type: 'STRIPE_CONFIRM',
      id,
      callback,
    };
  },
  checked(callback?: () => ActionCallback): CheckedStripeAction {
    return {
      type: 'STRIPE_CHECK_STATUS',
      callback,
    };
  },
  cansel(callback?: () => ActionCallback): CanselStripeAction {
    return {
      type: 'STRIPE_CANSEL',
      callback,
    };
  },
  set(items: []): SetStripeAction {
    return {
      type: 'STRIPE_LIST',
      payload: {
        items: items,
      },
    };
  },
  setItem(item: any): SetItemStripeAction {
    return {
      type: 'STRIPE_ITEM',
      payload: {
        item,
      },
    };
  },
  loader(status: boolean): LoaderAction {
    return {
      type: 'STRIPE_LOADER',
      payload: {
        loader: status,
      },
    };
  },
  loaderModal(status: boolean): LoaderModalAction {
    return {
      type: 'STRIPE_LOADER_MODAL',
      payload: {
        loaderModal: status,
      },
    };
  },
  secred(secred: string): SecredStripeAction {
    return {
      type: 'STRIPE_SECRED',
      payload: {
        secred: secred,
      },
    };
  },
  // choosePrice(id: any): ChoosePriceAction {
  //   return {
  //     type: 'CHOOSE_PRICE',
  //     payload: {
  //       id,
  //     },
  //   };
  // },
  promoInfo(payload: any): PromoInfoAction {
    return {
      type: 'PROMO_INFO',
      payload,
    };
  },
  invalidPromo(payload: any): InvalidPromoInfoAction {
    return {
      type: 'INVALID_PROMO',
      payload,
    };
  },
  page(page: string): PageStripeAction {
    return {
      type: 'STRIPE_PAGE',
      payload: {
        page: page,
      },
    };
  },
  change(id: number, callback?: ActionCallback): ChangeStripeAction {
    return {
      type: 'STRIPE_CHANGE',
      id,
      callback,
    };
  },
};

export default stripeAction;
