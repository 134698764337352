/*
 *
 * Clients reducer
 *
 */
const initialState = {
  items: [],
  searchResult: [],
  topics: [],
  therapists: [],
  item: {} as any,
  count: 0,
  loader: false,
  loaderModal: false,
  order: {},
  optionsIsReceived: '',
  rowIndex: undefined,
  params: {
    page: 1,
    per_page: 10,
    name: '',
  },
};

export type groupsInitStateType = typeof initialState;

interface groupsActionTypes {
  type: string;
  payload: {
    items: [];
    searchResult: [];
    topics: [];
    therapists: [];
    item: any;
    count: number;
    loader: boolean;
    loaderModal: boolean;
    order: any;
    optionsIsReceived: string;
    rowIndex?: number;
    params: {
      page: number;
      per_page: number;
      name: string;
    };
  };
}

const groups = (state = initialState, action: groupsActionTypes): groupsInitStateType => {
  switch (action.type) {
    case 'GROUPS':
      return Object.assign({}, state, {
        items: action.payload.items,
        count: action.payload.count,
      });
    case 'GROUP_SEARCH_RESULT':
      return Object.assign({}, state, {
        searchResult: action.payload.searchResult,
      });
    case 'GROUP':
      return Object.assign({}, state, {
        item: action.payload.item,
      });
    case 'GROUPS_LOADER':
      return Object.assign({}, state, {
        loader: action.payload.loader,
      });
    case 'GROUPS_LOADER_MODAL':
      return Object.assign({}, state, {
        loaderModal: action.payload.loaderModal,
      });

    case 'GROUP_FORM_TOPICS':
      return Object.assign({}, state, {
        topics: action.payload.topics,
      });

    case 'GROUP_FORM_THERAPISTS':
      return Object.assign({}, state, {
        therapists: action.payload.therapists,
      });

    case 'GROUPS_PARAMS':
      return Object.assign({}, state, {
        params: action.payload.params,
      });

    case 'GROUPS_ROW_INDEX':
      return Object.assign({}, state, {
        rowIndex: action.payload.rowIndex,
      });

    case 'GROUPS_ORDER':
      return Object.assign({}, state, {
        order: action.payload.order,
      });

    case 'SET_GROUPS_FILTER_OPTIONS':
      return Object.assign({}, state, {
        optionsIsReceived: action.payload.optionsIsReceived,
      });

    default:
      return state;
  }
};

export default groups;
