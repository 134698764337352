/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { AxiosResponse } from 'axios';
// config
import http from '../instance';

export const api = {
  get: async (role: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.get(`${role}/stripe-prices`);
  },

  checkPromo: async (data: any): Promise<AxiosResponse> => {
    const api = await http();
    return api.post(`customer/check-coupon`, data);
  },

  choose: async (role: string, data: any): Promise<AxiosResponse> => {
    const api = await http();
    return api.post(`${role}/create-subscription`, { couponName: data.couponName, priceId: data.id });
  },

  confirm: async (role: string, data: any): Promise<AxiosResponse> => {
    const api = await http();
    return api.patch(`${role}/confirm-subscription`, data);
  },

  update: async (role: string, data: any): Promise<AxiosResponse> => {
    const api = await http();
    return api.patch(`${role}/payment-method`, data);
  },

  checked: async (role: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.patch(`${role}/refresh-subscription`);
  },

  cansel: async (role: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.patch(`${role}/cancel-subscription`);
  },

  change: async (role: string, data: any): Promise<AxiosResponse> => {
    const api = await http();
    return api.patch(`${role}/change-subscription`, data);
  },
};
