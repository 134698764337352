import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
// components
import EditZoom from '../../organisms/modals/editZoom';
// types
import { stateType } from '../../../types/types';
// styles
import './style.scss';

const ZoomIntegrationContainer: React.FC = () => {
  const item: any = useSelector((state: stateType) => state.account.item, shallowEqual);
  const loader = useSelector((state: stateType) => state.therapists.loader, shallowEqual);

  return (
    <div className="zoom-integration-container">
      {!loader && (
        <>
          <div className="zoom-integration-container__title">Zoom Integration</div>
          <div className="zoom-integration-container__body">
            <span className="zoom-integration-container__mail">
              {item?.zoomClient?.email ? `${item?.zoomClient?.email} (Zoom)` : ''}
            </span>
            <EditZoom classes={'zoom-integration-container__edit'} />
          </div>
        </>
      )}
    </div>
  );
};

export default ZoomIntegrationContainer;
