import React from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
// actions
// helps
import { mapping } from '../../../../../api/mapping/request/addGroup';
// components
import {
  AddGroupEndTime,
  AddGroupSelectDays,
  AddGroupSelectTherapists,
  AddGroupSelectTopics,
  AddGroupStartTime,
  SubmitButton,
} from '../../../../molecules/inputsBlocks/addGroup';
// style
import '../../style.scss';
import './style.scss';
import { useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { addGroupSchema } from '../../../../../tools/validations';
import moment from 'moment';
import groupsAction from '../../../../../redux/actions/groups';

interface FormData {
  groupTopicId: any;
  therapistId: any;
  dayOfWeek: any;
  start: any;
  end: any;
}

const FormAddGroup: React.FC<any> = ({ setVisible }: any) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(addGroupSchema),
  });

  const dispatch = useDispatch();
  const history = useHistory();

  const addGroupCallback = (id: any) => {
    setVisible(false);
    history.push(`/all_groups/${id}`);
  };

  const onSubmit = (data: FormData) => {
    dispatch(
      groupsAction.created(
        mapping.addGroupBuild({ ...data, start: moment(data.start), end: moment(data.end) }),
        addGroupCallback,
      ),
    );
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="form-add-group validation-form">
        <div className="form-add-group__selections">
          <AddGroupSelectTopics
            errors={errors.groupTopicId ? errors.groupTopicId.message : undefined}
            register={register}
            control={control}
          />

          <AddGroupSelectDays
            errors={errors.dayOfWeek ? errors.dayOfWeek.message : undefined}
            register={register}
            control={control}
          />

          <div className="form-add-group__row">
            <div className="form-add-group__column">
              <AddGroupStartTime
                errors={errors.start ? errors.start.message : undefined}
                register={register}
                control={control}
              />
            </div>
            <div className="form-add-group__column">
              <AddGroupEndTime
                errors={errors.end ? errors.end.message : undefined}
                register={register}
                control={control}
              />
            </div>
          </div>

          <AddGroupSelectTherapists
            errors={errors.therapistId ? errors.therapistId.message : undefined}
            register={register}
            control={control}
          />
        </div>
        <div className="form-add-group__submit-btn">
          <SubmitButton name="Add Group" />
        </div>
      </form>
    </>
  );
};

export default FormAddGroup;
