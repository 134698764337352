import React, { FC, useEffect, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { useWindowResize } from 'beautiful-react-hooks';
// components
import SimpleTemplate from '../../../components/templates/simpleTemplate';
import CardGroup from '../../../components/organisms/cards/groupCard/CardGroup';
import CardTherapist from '../../../components/organisms/cards/therapist';
import ButtonWrapper from '../../../components/organisms/cards/groupCard/cardGroupElements/ButtonWrapper';
import BorderCard from '../../../components/organisms/cards/groupCard/cardGroupElements/BorderCard';
import PaddingCard from '../../../components/organisms/cards/groupCard/cardGroupElements/PaddingCard';
import NotFoundGroup from '../../../components/organisms/cards/groupCard/cardGroupElements/NotFoundGroup';
import GroupMeaning from '../../../components/organisms/cards/groupCard/GroupMeaning';
import ThreeGroupSearch from '../../../components/containers/groupSearch';
// types
import { stateType } from '../../../types/types';
// consts
import { TOTAL_GROUPS_NUMBER } from '../../../tools/consts';
// actions
import { authActions } from 'redux/actions/auth';
import eventAnalytics from '../../../redux/actions/analytics';

const BUTTON_BREAKPOINT = 768;

const PageGroupSearch: FC = () => {
  const [page, setPage] = useState<number>(0);
  const ref = useRef<any>();

  const loader: boolean = useSelector((state: stateType) => state.choice_group.loader, shallowEqual);
  const item: any = useSelector((state: stateType) => state.choice_group.item, shallowEqual);
  const subscriptionStatus: any = useSelector(
    (state: stateType) => state.user.profile?.subscriptionStatus,
    shallowEqual,
  );

  const notCancelled = subscriptionStatus && subscriptionStatus?.value !== 4 && subscriptionStatus?.value !== 6;

  const dispatch: any = useDispatch();

  const onIncrement = (): void => {
    if (page === 0) {
      const newMatchGroupIds = item.slice(1, TOTAL_GROUPS_NUMBER + 1).map((e: any) => e.group.id);
      dispatch(
        eventAnalytics.analytics('sign-up-three', {
          newMatchGroupIds,
          matchGroupIds: [item[0].group.id],
        }),
      );
    } else {
      const newMatchGroupIds = item
        .slice(page * TOTAL_GROUPS_NUMBER + 1, (page + 1) * TOTAL_GROUPS_NUMBER + 1)
        .map((e: any) => e.group.id);
      const matchGroupIds = item
        .slice((page - 1) * TOTAL_GROUPS_NUMBER + 1, page * TOTAL_GROUPS_NUMBER + 1)
        .map((e: any) => e.group.id);
      dispatch(
        eventAnalytics.analytics('sign-up-three', {
          newMatchGroupIds,
          matchGroupIds,
        }),
      );
    }
    if (page * TOTAL_GROUPS_NUMBER + 1 < item.length) {
      setPage(page + 1);
    } else {
      setPage(0);
    }
  };

  const onGoBack = (): void => {
    if (page) {
      setPage(page - 1);
    } else {
      dispatch(authActions.back('go-registered'));
    }
  };

  const [width, setWidth] = useState<number>(window.innerWidth);

  useWindowResize(() => {
    setWidth(window.innerWidth);
  });

  useEffect(() => {
    ref.current.scrollIntoView();
  }, [page]);

  return (
    <div ref={ref}>
      <SimpleTemplate
        title={`Here's Your Group Match`}
        page={page}
        beforeText={
          !loader && item.length ? (
            <span>
              <Link to={'#'} onClick={onIncrement}>
                See other groups
              </Link>
            </span>
          ) : null
        }
        button={
          !loader && item.length ? (
            <ButtonWrapper group={item[0].group}>
              <button className="btn-form">Join this group</button>
            </ButtonWrapper>
          ) : null
        }
        id={page}
        prevButton={
          !loader && (notCancelled || page) ? (
            <>
              {width > BUTTON_BREAKPOINT ? (
                <button
                  type="button"
                  className={cn('select-groups__btn', {
                    'select-groups__btn_first': page === 0,
                    'select-groups__btn_last': page > 0,
                  })}
                  onClick={onGoBack}
                />
              ) : (
                <button
                  type="button"
                  onClick={onGoBack}
                  className="select-groups__small-btn select-groups__small-btn_first"
                >
                  {page ? 'Previous Groups' : 'Consultation'}
                </button>
              )}
            </>
          ) : null
        }
      >
        <Spin tip="Loading..." spinning={loader}>
          {!loader && !item?.length && <NotFoundGroup />}
          {!loader && item.length ? (
            page === 0 ? (
              <>
                <PaddingCard>
                  <CardGroup group={item[0].group} />
                </PaddingCard>
                <BorderCard>
                  <CardTherapist therapist={item[0].therapist} />
                </BorderCard>
                <GroupMeaning group={item[0].group} />
              </>
            ) : (
              <ThreeGroupSearch
                group={item.slice((page - 1) * TOTAL_GROUPS_NUMBER + 1, page * TOTAL_GROUPS_NUMBER + 1)}
              />
            )
          ) : (
            <div style={{ width: '300px', height: '100px' }} /> // to increase the size of the spin container
          )}
        </Spin>
      </SimpleTemplate>
    </div>
  );
};

export default PageGroupSearch;
