import React from 'react';
// components
import Members from '../../members';
// style
import '../style.scss';
import { TherapistGroupDetailsCard } from '../../../lists/sessions/items';
import cn from 'classnames';

const GroupDetailsCard: React.FC<any> = ({ group, members }: any) => {
  return (
    <div className="group-details-card">
      {group?.status?.value && (
        <div
          className={cn('table-item-box__status', {
            'table-item-box__status-4': group.status.value === 1,
            'table-item-box__status-3': group.status.value === 2,
            'table-item-box__status-1': group.status.value === 3,
            'table-item-box__status-2': group.status.value === 4,
            'group-details-card-custom__status': true,
          })}
        >
          {group?.status.name} group
        </div>
      )}
      {group && <TherapistGroupDetailsCard item={group} members={members} />}
      <Members members={members} group={group} />
    </div>
  );
};

export default GroupDetailsCard;
