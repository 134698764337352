import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import cn from 'classnames';
// strips
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
// actions
import stripeAction from '../../../../redux/actions/stripe';
// components
import SslFooter from 'components/organisms/stripe/form/SslFooter';
// helps
import { APP } from '../../../../configs/app';
import { animScale } from 'tools/animations';
import { nameSchema } from 'tools/validations';
// styles
import './style.scss';
import eventAnalytics from '../../../../redux/actions/analytics';
import { stateType } from '../../../../types/types';
import stripeCullMethods from '../../../../api/stripe/methods';

const SubscriptionForm: React.FC<any> = ({ secred, loader, isTestUser }: any) => {
  const stripePromise = loadStripe(isTestUser ? APP.STRIPE.TEST_TOKEN : APP.STRIPE.TOKEN);

  return (
    <motion.div
      className="subscription-container subscription-container__wrapper"
      variants={animScale}
      initial="initial"
      animate="visible"
      exit="exit"
    >
      <Elements stripe={stripePromise} options={{ locale: 'en' }}>
        <CheckoutForm secred={secred} loader={loader} />
      </Elements>
      <SslFooter />
    </motion.div>
  );
};

export default SubscriptionForm;

const CheckoutForm = ({ secred, loader }: any) => {
  const stripe = useStripe();
  const dataStripe: any = useSelector((state: stateType) => state.stripe);
  const sub: any = useSelector((state: stateType) => state.stripe.item);
  const elements = useElements();
  const [cardName, setCardName] = useState({ text: '', error: '', touched: false });
  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState<any>(false);
  const [cardComplete, setCardComplete] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);

  const dispatch = useDispatch();

  const createSubscription = async (event: any) => {
    setLoading(true);
    dispatch(stripeAction.choose(sub.stripePriceId, dataStripe.data.name));
    event.preventDefault();
    // handleSubmit();
  };

  useEffect(() => {
    if (secred && stripe && elements) {
      handleSubmit();
    }
  }, [secred, stripe, elements]);

  const handleSubmit = async () => {
    if (!isDisabled) {
      setIsDisabled(true);

      if (!stripe || !elements) {
        setIsDisabled(false);
        return;
      }
      if (!cardName.text) {
        setCardName((state) => ({ ...state, error: 'Card name is required field' }));
        setIsDisabled(false);
      }
      if (error) {
        setIsDisabled(false);
        return;
      }
      if (cardComplete) {
        setProcessing(true);
      }

      const clientSecret = secred.clientSecret;
      const subscriptionId = secred.subscriptionId;

      dispatch(stripeAction.loader(true));

      // Create payment method and confirm payment intent.
      stripeCullMethods.confirmCardPayment(
        stripe,
        clientSecret,
        elements,
        subscriptionId,
        setProcessing,
        setError,
        dispatch,
        cardName.text,
      );
      dispatch(eventAnalytics.analytics('sign-up-six'));
    }
    setIsDisabled(false);
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setCardName((state) => ({ ...state, text: value, touched: true }));
    if (cardName.touched) {
      validate();
    }
  };

  const validate = async () => {
    const isValid = await nameSchema.isValid(cardName.text);
    if (!isValid) {
      setCardName((state) => ({
        ...state,
        error: cardName.text ? 'Input a correct card name' : 'Card name is required field',
      }));
    } else {
      setCardName((state) => ({
        ...state,
        error: '',
      }));
    }
  };

  const onBlurInput = () => {
    validate();
  };

  const reset = () => {
    setError(null);
    setProcessing(false);
    setPaymentMethod(null);
    setCardName({ text: '', error: '', touched: false });
  };

  return paymentMethod ? (
    <div className="Result">
      <div className="ResultTitle" role="alert">
        Payment successful
      </div>
      <div className="ResultMessage">
        Thanks for trying Stripe Elements. No money was charged, but we generated a PaymentMethod:{' '}
      </div>
      <ResetButton onClick={reset} />
    </div>
  ) : (
    <form className="card-form" onSubmit={createSubscription}>
      <div className="card-form__title">Payment details</div>
      <div>
        <input
          className={cn('card-form__input', { error: cardName.error })}
          name="name"
          placeholder="Name on card "
          value={cardName.text}
          onChange={onInputChange}
          onBlur={onBlurInput}
          autoComplete="on"
        />
        <div className="card-form__error">{cardName.error}</div>
      </div>
      <CardField
        onChange={(e: any) => {
          setError(e.error);
          setCardComplete(e.complete);
        }}
      />
      <div className="card-form__error">{error?.message}</div>
      {!loader && (
        <>
          <SubmitButton
            processing={processing}
            error={error}
            disabled={!stripe || error || cardName.error || !cardName.text || !cardComplete || loading}
          >
            Complete Purchase
          </SubmitButton>
        </>
      )}
    </form>
  );
};

const CARD_OPTIONS = {
  hidePostalCode: true,
  style: {
    base: {
      iconColor: '#44444f',
      color: '#44444f',
      fontWeight: 500,
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '14px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '#44444f',
      },
      '::placeholder': {
        color: '#44444F80',
      },
    },
    invalid: {
      iconColor: '#e6e7e9',
      color: '#44444f',
    },
  },
};

const CardField = ({ onChange }: any) => (
  <div className="card-element">
    <CardElement options={CARD_OPTIONS} onChange={onChange} />
  </div>
);

const SubmitButton = ({ processing, children, disabled }: any) => (
  <button className={`btn-form`} type="submit" disabled={processing || disabled}>
    {processing ? 'Processing...' : children}
  </button>
);

const ResetButton = ({ onClick }: any) => (
  <button type="button" className="btn-form" onClick={onClick}>
    ResetButton
  </button>
);
