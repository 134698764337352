import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Modal, Spin } from 'antd';
import Moment from 'react-moment';
// actions
import groupsAction from '../../../../../redux/actions/groups';
// types
import { stateType } from '../../../../../types/types';
// styles
import './style.scss';
// components
import SmallGroupCard from '../../../cards/groupCard/SmallGroupCard';
// icons
import { ReactComponent as CloseIcon } from '../../../../../assets/image/account/close4.svg';
import ImgAfter from '../../../../../assets/image/questions/after.svg';
import { ReactComponent as IconCalendar } from '../../../../../assets/image/dropdown/calendar.svg';

const ArchiveGroup: React.FC<any> = () => {
  const [visible, setVisible] = useState(false);
  const [success, setSuccess] = useState(false);

  const loader = useSelector((state: stateType) => state.groups.loaderModal, shallowEqual);
  const item = useSelector((state: stateType) => state.groups.item, shallowEqual);

  const open = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onSuccess = () => {
    setSuccess(true);
  };

  return (
    <>
      <button className="btn-form btn-form__light" onClick={open}>
        Archive Group
      </button>
      {visible && (
        <Modal
          visible={visible}
          onOk={handleOk}
          closable={true}
          onCancel={handleCancel}
          centered
          maskTransitionName="none"
          footer={null}
          className="archive-group-modal"
          closeIcon={<CloseIcon />}
        >
          {!success && (
            <Spin spinning={loader}>
              <ModalContainer onSuccess={onSuccess} setVisible={setVisible} item={item} groupID={item.group.id} />
            </Spin>
          )}
          {success && <ModalSuccess handleCancel={handleCancel} item={item} />}
        </Modal>
      )}
    </>
  );
};

export default ArchiveGroup;

const ModalContainer = ({ onSuccess, item, groupID }: any) => {
  const dispatch = useDispatch();

  const action = () => {
    dispatch(groupsAction.archive(groupID, onSuccess));
  };

  return (
    <div className="archive-session-container">
      <div className="archive-session-container__title">Are you sure you want to archive the following Group?</div>
      <div className="archive-session-container__main">
        <div className="archive-session-container__group-info">
          <SmallGroupCard item={item} />
        </div>
      </div>
      <div className="archive-session-container__block">
        <button className="btn-form" onClick={action}>
          Confirm
        </button>
      </div>
    </div>
  );
};

const ModalSuccess = ({ handleCancel, item }: any) => {
  const history = useHistory();

  useEffect(() => {
    history.push('/all_groups');
  }, []);
  return (
    <div className="archive-session-container">
      <div className="archive-session-container__title archive-session-container__title_success">
        <img className="archive-session-container__finish-icon" src={ImgAfter} alt="Finish icon" />
        <div>You’ve successfully archived the following Group</div>
      </div>
      <div className="archive-session-container__main">
        <div className="archive-session-container__label">
          <IconCalendar style={{ width: '20px', height: '20px' }} />
          <div className="archive-session-container__date">
            Every  <Moment format={'dddd'}>{item.start}</Moment>
          </div>
        </div>
        <div className="archive-session-container__group-info">
          <SmallGroupCard item={item} />
        </div>
      </div>
      <div className="archive-session-container__block">
        <button className="btn-form" onClick={handleCancel}>
          Done
        </button>
      </div>
    </div>
  );
};
