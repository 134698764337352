import React, { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Modal, Spin } from 'antd';
import cn from 'classnames';
// types
import { stateType } from '../../../../../types/types';
// icons
import { ReactComponent as CloseIcon } from '../../../../../assets/image/account/close4.svg';
// components
import { CancelButton } from '../../../../atoms/form/CancelButton';
// style
import './styles.scss';
// actions
import clientAction from '../../../../../redux/actions/client';
import eventAnalytics from '../../../../../redux/actions/analytics';

const UndoCancellation: React.FC = () => {
  const [visible, setVisible] = useState(false);

  const loader = useSelector((state: stateType) => state.clients.loaderModal);
  const showMinimal: boolean = useSelector((state: stateType) => state.main_menu.showMinimal, shallowEqual);

  const open = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <button className={'btn-form'} onClick={open}>
        Undo cancellation
      </button>
      {visible && (
        <Modal
          visible={visible}
          onOk={handleOk}
          closable={true}
          onCancel={handleCancel}
          centered
          maskTransitionName="none"
          footer={null}
          className={cn('restart-client-modal restart-client-modal_customer', {
            minimal: showMinimal,
          })}
          closeIcon={<CloseIcon />}
        >
          <Spin spinning={loader}>
            <ModalContainer handleCancel={handleCancel} />
          </Spin>
        </Modal>
      )}
    </>
  );
};

export default UndoCancellation;

const ModalContainer = ({ handleCancel }: any) => {
  const dispatch = useDispatch();

  const restartSubscription = () => {
    dispatch(clientAction.restartSubscription());
    dispatch(eventAnalytics.analytics('update-payment-start'));
  };

  return (
    <div className="restart-client-form">
      <div className="restart-client-form__container">
        <div className="success-change-group-container__title-img">
          <p className="restart-client-form__customer-title">Restart subscription</p>
        </div>
        <div className="restart-client-form__main restart-client-form__select-group">
          <p className="restart-client-form__text">Do you want to restart your subscription?</p>
        </div>
        <div className="restart-client-form__confirm-button">
          <button className="btn-form" onClick={restartSubscription}>
            Yes
          </button>
          <CancelButton name="No" cancel={handleCancel} />
        </div>
      </div>
    </div>
  );
};
