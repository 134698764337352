import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { Spin } from 'antd';
import { shallowEqual, useSelector } from 'react-redux';
// components
import StepProgress from 'components/atoms/progress';
import { GroupAnswer } from 'components/molecules/questions/colections';
import SelectGroupsList from 'components/organisms/changeGroup/groups/SelectGroupsList';
// types
import { stateType } from 'types/types';
import { IQuestion } from 'types/app-types';
//icons
import Icon from '@ant-design/icons';
import { ReactComponent as IconBack } from 'assets/image/questions/back.svg';
// actions
import choiceGroupAction from '../../../redux/actions/choice_group';
// consts
import { TOTAL_GROUPS_NUMBER } from '../../../tools/consts';

interface IQuestionProps {
  onBack: () => void;
  question: IQuestion;
  closeBtn?: ReactNode;
  children?: ReactNode;
  totalStepCount?: number;
  goNext: () => void;
}

const GroupQuestion: FC<IQuestionProps> = (props: IQuestionProps) => {
  const { onBack, question, closeBtn, totalStepCount = 1, goNext } = props;

  const ref = useRef<HTMLDivElement>(null);

  const groups: any = useSelector((state: stateType) => state.choice_group.item, shallowEqual);
  const loader: boolean = useSelector((state: stateType) => state.choice_group.loader, shallowEqual);
  const totalCount: number = useSelector((state: stateType) => state.choice_group.totalCount, shallowEqual);

  const [page, setPage] = useState(1);

  useEffect(() => {
    return () => {
      setPage(1);
      choiceGroupAction.params({ page: 1 });
    };
  }, []);

  const onSeeOtherGroup = (): void => {
    if (page * TOTAL_GROUPS_NUMBER < groups.length) {
      setPage(page + 1);
    } else {
      setPage(1);
    }
  };

  const onGoBack = () => {
    if (totalCount > TOTAL_GROUPS_NUMBER && page > 1) {
      setPage(page - 1);
    }
  };

  useEffect(() => {
    ref?.current?.scrollIntoView();
  }, [page]);

  return (
    <>
      <div className="questions" ref={ref}>
        <div className="questions__header">
          <div className="questions__header-top">
            <div className="questions__back" onClick={onBack}>
              <Icon component={IconBack} />
            </div>
            <div className="questions__progress">
              <StepProgress step={question.serialNumber} stepNumber={question.number} totalStepCount={totalStepCount} />
            </div>
            <div className="questions__close-btn">{closeBtn}</div>
          </div>
        </div>
        <div className="questions__question-body">
          <div className="questions__group-question">
            <div className="questions__question">{question.question}</div>
            <div className="questions__sub-question">{question.additions.subQuestion}</div>
          </div>
        </div>
        <div className="questions__body questions__body_group">
          <Spin spinning={loader}>
            {Array.isArray(groups) && (
              <SelectGroupsList
                groups={groups.slice((page - 1) * TOTAL_GROUPS_NUMBER, page * TOTAL_GROUPS_NUMBER)}
                onGoBack={onGoBack}
                redirect
              />
            )}
          </Spin>
        </div>
        <div className="questions__footer pad-sm" style={{ display: 'flex', justifyContent: 'center' }}>
          <GroupAnswer question={question} goNext={goNext} onChange={onSeeOtherGroup} />
        </div>
      </div>
    </>
  );
};

export default GroupQuestion;
