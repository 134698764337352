import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import useHeight from '../../../hooks/useHeight/useHeight';
import useParticipants from '../../../hooks/useParticipants/useParticipants';
import { shallowEqual, useSelector } from 'react-redux';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import useLocalAudioToggle from '../../../hooks/useLocalAudioToggle/useLocalAudioToggle';
import useLocalVideoToggle from '../../../hooks/useLocalVideoToggle/useLocalVideoToggle';
import { useAppState } from '../../../state';
import Loader from '../Loader/Loader';
import { displayName } from '../Helpers/helpers';
import useRoomInfoContext from '../../../hooks/useRoomInfoContext/useRoomInfoContext';
import { _extLog } from '../../../tools/ExternalLogs';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    waitingRoom: {
      display: 'block',
      position: 'absolute',
      width: 'calc(100% - 0px)',
      height: 'calc(100% - 0px)',
      backgroundColor: `${theme.palette.background.default}`,
      zIndex: 20,
    },
    waitingRoomMessage: {
      textAlign: 'center',
      padding: '60px 20px',
      width: 375,
      backgroundColor: '#ffffff',
      borderRadius: 20,
      margin: '250px auto 0',
    },
    mainText: {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 500,
      color: 'rgba(68, 64, 60, 1)',
    },
    secondaryText: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '20px',
      color: '#79716B',
      marginTop: 10,
    },
    therapistInfo: {
      margin: '10px 0',
    },
    hostContainer: {
      padding: '20px 0 33px',
      textAlign: 'center',
    },
    hostContainerInner: {
      display: 'inline-block',
    },
    initials: {
      float: 'left',
      width: 32,
      height: 32,
      borderRadius: '50%',
      backgroundColor: '#98A2B3',
      color: '#ffffff',
      paddingTop: 8,
      marginRight: 10,
    },
    hostName: {
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '18px',
    },
    hostGroup: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '10px',
      lineHeight: '18px',
    },
  }),
);

export default function WaitingRoom() {
  const { showWaitingRoom, hideWaitingRoom } = useAppState();
  const cssClasses = useStyles();
  const participants = useParticipants();
  const role = useSelector((state: any) => state.user.profile.role, shallowEqual);
  const { room, localTracks } = useVideoContext();
  const localParticipant = room!.localParticipant;
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
  const { hostFirstName, hostLastName, hostTitle, hostInitials, hostLicenses } = useRoomInfoContext();
  const [videoInitiallyEnabled, setVideoInitiallyEnabled] = useState(false);
  const [audioInitiallyEnabled, setAudioInitiallyEnabled] = useState(false);

  useEffect(() => {
    setVideoInitiallyEnabled(isVideoEnabled);
    setAudioInitiallyEnabled(isAudioEnabled);
    if (role == 'CUSTOMER') {
      if (isAudioEnabled) {
        toggleAudioEnabled();
      }
      if (isVideoEnabled) {
        toggleVideoEnabled();
      }
    }
    _extLog(`User role: ${role}`);
  }, []);

  useEffect(() => {
    if (!showWaitingRoom && role == 'CUSTOMER') {
      if (audioInitiallyEnabled) {
        toggleAudioEnabled();
      }
      if (videoInitiallyEnabled) {
        toggleVideoEnabled();
      }
      console.log('sending show-me');
      _extLog(`Sending show-me`);
      localTracks
        .filter((track) => track.kind == 'data')
        .forEach((track) => {
          //@ts-ignore
          track.send(JSON.stringify({ command: 'show-me', identity: localParticipant.identity }));
        });
      setTimeout(() => {
        _extLog(`Sending show-me after 4 seconds`);
        console.log('sending show-me after 4 seconds');
        localTracks
          .filter((track) => track.kind == 'data')
          .forEach((track) => {
            //@ts-ignore
            track.send(JSON.stringify({ command: 'show-me', identity: localParticipant.identity }));
          });
      }, 4000);
    }
  }, [showWaitingRoom, audioInitiallyEnabled, videoInitiallyEnabled]);

  useEffect(() => {
    console.log('waiting room effect');
    _extLog(`Waiting room effect`);
    let pingInterval: any = 0;
    if (showWaitingRoom) {
      if (role == 'ADMIN' || role == 'THERAPIST') {
        hideWaitingRoom();
        _extLog(`Hide waiting room`);
      } else if (participants.length > 0) {
        _extLog(`Sending ping-for-host`);
        localTracks
          .filter((track) => track.kind == 'data')
          .forEach((track) => {
            //@ts-ignore
            track.send(JSON.stringify({ command: 'ping-for-host' }));
          });
        pingInterval = setInterval(() => {
          console.log('ping-for-host');
          _extLog(`Sending ping-for-host`);
          localTracks
            .filter((track) => track.kind == 'data')
            .forEach((track) => {
              //@ts-ignore
              track.send(JSON.stringify({ command: 'ping-for-host' }));
            });
        }, 4000);
      }
    }
    if (role == 'ADMIN' || role == 'THERAPIST') {
      setTimeout(() => {
        _extLog(`Sending host-present`);
        localTracks
          .filter((track) => track.kind == 'data')
          .forEach((track) => {
            //@ts-ignore
            track.send(JSON.stringify({ command: 'host-present', identity: localParticipant.identity }));
            console.log('sending host-present');
          });
      }, 4000);
    }
    setTimeout(() => {
      _extLog(`Sending send-name`);
      localTracks
        .filter((track) => track.kind == 'data')
        .forEach((track) => {
          //@ts-ignore
          track.send(JSON.stringify({ command: 'send-name' }));
        });
    }, 4000);
    return () => {
      if (pingInterval) {
        clearInterval(pingInterval);
      }
    };
  }, [participants, showWaitingRoom]);

  return (
    <>
      {showWaitingRoom ? (
        <div className={cssClasses.waitingRoom}>
          <div className={cssClasses.waitingRoomMessage}>
            <Loader />
            <div className={cssClasses.mainText}>Please wait for</div>
            <div className={cssClasses.hostContainer}>
              <div className={cssClasses.hostContainerInner}>
                <div className={cssClasses.initials}>{hostInitials}</div>
                <span className={cssClasses.hostName}>
                  {hostTitle} {hostFirstName} {hostLastName} {hostLicenses.join(', ')}
                </span>
                <br />
                <span className={cssClasses.hostGroup}>{room && displayName(room?.name)}</span>
              </div>
            </div>
            <div className={cssClasses.mainText}>to start the session</div>
            <div className={cssClasses.secondaryText}>Hang on for your therapist to start the session</div>
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
}
