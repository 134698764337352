import React from 'react';
import { useSelector } from 'react-redux';
import { Spin, Tabs } from 'antd';
// components
import GroupDetailsCard from '../../../organisms/cards/groupDetails/therapist';
import { SessionGroupList } from '../../../organisms/lists/sessions/therapist/group';
import HistoryList from '../../../organisms/lists/history/admin';
// types
import { stateType } from '../../../../types/types';
// style
import '../style.scss';
// pictures
import waitMembersPicture from '../../../../assets/image/group/wait-members.png';

const GroupDetailsContainer: React.FC<any> = ({ paramsId }: any) => {
  const group: any = useSelector((state: stateType) => state.groups.item.group);
  const therapist: any = useSelector((state: stateType) => state.groups.item.therapist);
  const members: any = useSelector((state: stateType) => state.groups.item.members);
  const sessions: any = useSelector((state: stateType) => state.sessions.items);
  const groupLoader: any = useSelector((state: stateType) => state.groups.loader);
  const sessionsLoader: any = useSelector((state: stateType) => state.sessions.loader);

  return (
    <Spin spinning={groupLoader || sessionsLoader} style={{ minHeight: '480px' }}>
      {group && group.status.value != 1 ? (
        <div className="group-details-container">
          {!groupLoader && (
            <div className="group-details-container__column">
              <GroupDetailsCard group={group} therapist={therapist} members={members} />
            </div>
          )}

          {!sessionsLoader && (
            <div className="group-details-container__column">
              <SessionTabBox>
                <GroupDetailsTabs sessions={sessions} paramsId={paramsId} />
              </SessionTabBox>
            </div>
          )}
        </div>
      ) : (
        <div className="wait-members">
          {group && (
            <div className="wait-members__page">
              <h2 className="wait-members__greeting">Hi!</h2>
              <p className="wait-members__therapist-name">
                {therapist?.title ? `${therapist?.title}. ${therapist?.lastname}` : therapist?.firstname},
              </p>
              <div className="wait-members__image-container">
                <img src={waitMembersPicture} alt="" className="wait-members__image" />
              </div>
              <p className="wait-members__group-details-description">
                Your group is currently being marketed and awaiting group members. We’ll <br />
                notify you when the first member joins.
              </p>
            </div>
          )}
        </div>
      )}
    </Spin>
  );
};

export default GroupDetailsContainer;

const GroupDetailsTabs = ({ sessions, paramsId }: any) => {
  const { TabPane } = Tabs;

  const callback = (key: any) => {
    console.log(key);
  };

  return (
    <Tabs defaultActiveKey="1" onChange={callback} className="account-tabs">
      <TabPane tab="Upcoming Sessions" key="sessions" className="account-tabs__item">
        <SessionGroupList sessions={sessions} />
      </TabPane>
      <TabPane tab="Group History" key="history" className="account-tabs__item">
        <HistoryList paramsId={paramsId} />
      </TabPane>
    </Tabs>
  );
};

const SessionTabBox = ({ children }: any) => {
  return <div className="session-tab-box">{children}</div>;
};
