import React, { FC, useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Spin } from 'antd';
import { useHistory } from 'react-router-dom';
// style
import './style.scss';
// types
import { stateType } from '../../../../types/types';
// icons
import Icon from '@ant-design/icons';
import ImgAfter from '../../../../assets/image/questions/after.png';
import { ReactComponent as IconCalendar } from '../../../../assets/image/questions/calendar.svg';
// configs
import { week_days } from '../../../../configs/tables/admin/clients';
// components
import { RestartSuccessGroupCard } from '../../cards/groupCard/SmallGroupCard';
// actions
import userActions from '../../../../redux/actions/user';

const SuccessComponent: FC = () => {
  const info: any = useSelector((state: stateType) => state.account.info, shallowEqual);
  const loader: any = useSelector((state: stateType) => state.restartSubscriptions.loader, shallowEqual);

  const history = useHistory();

  const onClose = () => {
    history.push('/');
  };

  useEffect(() => {
    return () => {
      userActions.state('group_joined');
      history.push('/');
    };
  }, []);

  return (
    <Spin spinning={loader}>
      <div className="restart-success">
        <div className="restart-success__header">
          <img src={ImgAfter} alt="canceled subscription icon" />
          <h2 className="restart-success__title">You’ve successfully joined the following Group!</h2>
        </div>
        <div className="restart-success__group-container">
          <div className="restart-success__body-title">
            <Icon component={IconCalendar} style={{ marginRight: '6px', fontSize: '20px' }} />
            Every {week_days[info?.medicalGroup?.dayOfWeek - 1]}
          </div>
          <RestartSuccessGroupCard item={info?.medicalGroup} />
        </div>
        <div className="restart-success__button-container">
          <button className="btn-form" type="button" onClick={onClose}>
            Done
          </button>
        </div>
      </div>
    </Spin>
  );
};

export default SuccessComponent;
