/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { AxiosResponse } from 'axios';
// config
import http from '../instance';

export const apiConsultation = {
  submit: async (data: any): Promise<AxiosResponse> => {
    const api = await http();
    return api.patch(`customer/search-group-data`, data);
  },
};
