import React, { useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Modal, Spin } from 'antd';
// componets
import FormEdit from '../../../forms/edit/admin/therapist';
// types
import { stateType } from '../../../../../types/types';
// styles
import './style.scss';
// icons
import { ReactComponent as CloseIcon } from 'assets/image/account/close4.svg';

const EditTherapitsModal: React.FC<any> = ({ btnClass, icon, name }: any) => {
  const [visible, setVisible] = useState(false);

  const loader = useSelector((state: stateType) => state.therapists.loaderModal, shallowEqual);

  const open = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <button className={btnClass} onClick={open}>
        {icon}
        <span>{name}</span>
      </button>
      {visible && (
        <Modal
          visible={visible}
          onOk={handleOk}
          closable={true}
          onCancel={handleCancel}
          centered
          maskTransitionName="none"
          footer={null}
          className="edit-modal edit-therapist-modal"
          closeIcon={<CloseIcon />}
        >
          <Spin spinning={loader}>
            <ModalContainer handleCancel={handleCancel} />
          </Spin>
        </Modal>
      )}
    </>
  );
};

export default EditTherapitsModal;

const ModalContainer = ({ handleCancel }: any) => {
  return (
    <div className="edit-container edit-container__therapist">
      <div className="edit-container__title">Edit Therapist Info</div>
      <FormEdit handleCancel={handleCancel} />
    </div>
  );
};
