import React from 'react';
// components
import { SimpleLogo } from '../../../atoms/logos';
import { AuthLogo } from '../../../atoms/logos/authLogo';
// style
import './simple-header.scss';

interface SimpleHeaderInterface {
  redirect_url?: string;
}

const SimpleHeader: React.FC<SimpleHeaderInterface> = ({ redirect_url }) => {
  return (
    <div className="simple-header">
      {redirect_url ? <AuthLogo redirect_url={redirect_url} /> : <SimpleLogo click={true} />}
    </div>
  );
};

export default SimpleHeader;
