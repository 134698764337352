import React, { useEffect } from 'react';
import Snackbar from '../Snackbar/Snackbar';
import useRoomState from '../../../hooks/useRoomState/useRoomState';
import { _extLog } from '../../../tools/ExternalLogs';

export default function ReconnectingNotification() {
  const roomState = useRoomState();

  useEffect(() => {
    _extLog(`ReconnectingNotification: ${roomState}`);
  }, [roomState]);

  return (
    <Snackbar
      variant="error"
      headline="Connection Lost:"
      message="Reconnecting to room..."
      open={roomState === 'reconnecting'}
    />
  );
}
