/*
 *
 * Admin Events
 *
 */

const initialState = {
  events: [],
  event: {},
  count: 0,
  params: {
    page: 1,
    per_page: 10,
  },
  loader: false,
  loaderModal: false,
};

export type eventsInitStateType = typeof initialState;

interface eventsType {
  type: string;
  payload: {
    pastEventsPreview: any;
    upcomingEventsPreview: any;
    events: Array<any>;
    event: Record<string, unknown>;
    count: number;
    id: any;
    isReschedule: boolean;
    params: {
      page: number;
      per_page: number;
    };
    loader: boolean;
    loaderModal: boolean;
  };
}

const events = (state = initialState, action: eventsType): eventsInitStateType => {
  switch (action.type) {
    case 'SET_EVENTS':
      return Object.assign({}, state, {
        events: action.payload.events,
        count: action.payload.count,
      });
    case 'SET_PAST_EVENTS_PREVIEW':
      return Object.assign({}, state, {
        pastEventsPreview: action.payload.pastEventsPreview,
      });
    case 'SET_UPCOMING_EVENTS_PREVIEW':
      return Object.assign({}, state, {
        upcomingEventsPreview: action.payload.upcomingEventsPreview,
      });
    case 'SET_RESCHEDULE':
      return Object.assign({}, state, {
        isReschedule: action.payload.isReschedule,
      });
    case 'SET_EVENT':
      return Object.assign({}, state, {
        event: action.payload.event,
      });

    case 'EVENT_PARAMS':
      return Object.assign({}, state, {
        params: action.payload.params,
      });

    case 'COMMUNITY_EVENTS_PARAMS':
      return Object.assign({}, state, {
        params: action.payload.params,
      });

    case 'COMMUNITY_EVENTS_DELETE':
      return Object.assign({}, state, {
        id: action.payload.id,
      });

    case 'COMMUNITY_EVENTS_LOADER':
      return Object.assign({}, state, {
        loader: action.payload.loader,
      });

    case 'COMMUNITY_EVENTS_LOADER_MODAL':
      return Object.assign({}, state, {
        loaderModal: action.payload.loaderModal,
      });

    default:
      return state;
  }
};

export default events;
