import React, { FC, useEffect } from 'react';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
// components
import SubmitButton from '../../../../atoms/form/Button';
import { CancelButton } from '../../../../atoms/form/CancelButton';
// types
import { stateType } from '../../../../../types/types';
import { EditModalInterface } from '../../../../../types/edit';
// actions
import sessionsAction from '../../../../../redux/actions/sessions';
import groupsAction from '../../../../../redux/actions/groups';
// style
import '../style.scss';

const EditGroupModalContainer: FC<EditModalInterface> = ({
  handleCancel,
  titleName,
  buttonName,
  children,
  setValue,
  handleSubmit,
  isCancel,
  isPhoto,
}: EditModalInterface) => {
  const dispatch = useDispatch();

  const { group }: any = useSelector((state: stateType) => state.groups.item);
  const file: any = useSelector((state: stateType) => state.app.file);

  const submitCallback = () => {
    handleCancel();
    dispatch(sessionsAction.get(group.id));
  };

  useEffect(() => {
    setValue('description', group.about);
    setValue('tags', group.tags);
    setValue('avatarUuid', group.avatar.uuid);
    setValue('therapistId', group.therapist_id);
    setValue('groupTopicId', group.topic_id);
  }, [group]);

  useEffect(() => {
    file && setValue('avatarUuid', file.uuid);
  }, [file]);

  const onSubmit = (data: FormData) => {
    dispatch(groupsAction.edit(group.id, data, submitCallback));
  };

  return (
    <div className="edit-group-container">
      <div className="edit-group-container__title edit-group-container__header">{titleName}</div>
      <form onSubmit={handleSubmit(onSubmit)} className="form-edit validation-form">
        <div
          className={cn('edit-group-container__main', {
            photo: isPhoto,
          })}
        >
          {children}
        </div>
        <div className="edit-group-container__buttons">
          <SubmitButton name={buttonName} className="edit-group-container__submit-btn" />
          {isCancel && <CancelButton name="Cancel" cancel={handleCancel} />}
        </div>
      </form>
    </div>
  );
};

export default EditGroupModalContainer;
