import React, { FC, useEffect, useState } from 'react';
import cn from 'classnames';
import { Checkbox } from 'antd';
// components
import MultiQuestion from 'components/molecules/groupQuestions/Answers/MultiQuestion';
// types
import { IQuestion } from 'types/app-types';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { AnimatePresence, motion } from 'framer-motion';
import { animTextarea } from 'tools/animations';

interface IMultiArbitrary {
  question: IQuestion;
  onChange: (checkedValues: CheckboxValueType[]) => void;
  checkedValues: Array<number>;
  answerText: string;
  setAnswerText: (text: string) => void;
  placeholder?: string;
  small?: boolean;
  isOther?: boolean;
}

const MultiArbitrary: FC<IMultiArbitrary> = (props: IMultiArbitrary) => {
  const { question, checkedValues, onChange, answerText, setAnswerText, placeholder, small, isOther } = props;

  const [otherSelected, setOtherSelected] = useState(!!answerText);

  useEffect(() => {
    setOtherSelected(!!answerText);
  }, [answerText]);

  useEffect(() => {
    if (!otherSelected) {
      setAnswerText('');
    } else {
      setAnswerText(answerText);
    }
  }, [otherSelected]);

  const onCheck = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    setOtherSelected(!otherSelected);
  };

  return (
    <>
      <MultiQuestion question={question} checkedValues={checkedValues} onChangeCheck={onChange} small={small} />
      {isOther && (
        <>
          <div
            className={cn('questions__answer', 'questions__check-answer', 'other', {
              selected: otherSelected,
              small,
            })}
            onClick={onCheck}
          >
            <Checkbox checked={otherSelected}>
              <div className="questions__answer-text">Other reason</div>
            </Checkbox>
          </div>
          <AnimatePresence>
            {otherSelected && (
              <motion.textarea
                variants={animTextarea}
                initial="initial"
                animate="visible"
                exit="exit"
                className="questions__textarea"
                value={answerText}
                onChange={(e) => setAnswerText(e.target.value)}
                placeholder={placeholder || 'Type here..'}
                disabled={!otherSelected}
              />
            )}
          </AnimatePresence>
        </>
      )}
    </>
  );
};

export default MultiArbitrary;
