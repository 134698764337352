import { GetAssessmentProgressAction } from 'types/actions/progress';
import { ActionCallback, FlowType, LoaderAction, LoaderModalAction } from '../../types/actions/common-types';
import store from '../store';
// actions
// import mainMenuAction from './main_menu'

type FlowTypeAction<Type = string> = {
  type: Type;
  flowType: FlowType;
  number: number;
  callback?: ActionCallback;
};

type NextQuestionAction = FlowTypeAction<'GET_ASSESSMENT_NEXT_QUESTION'>;
type AfterQuestionAction = FlowTypeAction<'GET_ASSESSMENT_AFTER_QUESTION'>;
type QuestionAnswerAction = {
  type: 'PUSH_ASSESSMENT_ANSWER_QUESTION';
  flowType: FlowType;
  callback?: ActionCallback;
  data: any;
};
type QuestionsLoaderAction = LoaderAction<'ASSESSMENT_QUESTION_LOADER'>;
type QuestionsLoaderModalAction = LoaderModalAction<'ASSESSMENT_QUESTION_LOADER_MODAL'>;

const assessmentQuestionsActions: any = {
  next(flowType: FlowType, number?: any, callback?: ActionCallback): NextQuestionAction {
    return {
      type: 'GET_ASSESSMENT_NEXT_QUESTION',
      flowType,
      number,
      callback,
    };
  },

  after(flowType: FlowType, number?: any, callback?: ActionCallback): AfterQuestionAction {
    return {
      type: 'GET_ASSESSMENT_AFTER_QUESTION',
      flowType,
      number,
      callback,
    };
  },

  answer(flowType: FlowType, data: any, callback: ActionCallback): QuestionAnswerAction {
    return {
      type: 'PUSH_ASSESSMENT_ANSWER_QUESTION',
      flowType,
      data,
      callback,
    };
  },

  setQuestionNumber(value: number): any {
    store.dispatch({
      type: 'ASSESSMENT_QUESTION_NUMBER',
      payload: {
        question_number: value,
      },
    });
  },

  prevQuestionNumber(value: number): any {
    store.dispatch({
      type: 'ASSESSMENT_PREV_QUESTION_NUMBER',
    });
  },

  setScore(question_number: number, score: number): any {
    store.dispatch({
      type: 'ASSESSMENT_SCORE',
      payload: {
        score,
        question_number,
      },
    });
  },

  setArrayScore(score: Array<number>): any {
    store.dispatch({
      type: 'ASSESSMENT_ARRAY_SCORE',
      payload: {
        score,
      },
    });
  },

  clearScore(): any {
    store.dispatch({
      type: 'CLEAR_ASSESSMENT_SCORE',
    });
  },

  status(status: any): void {
    store.dispatch({
      type: 'ASSESSMENT_QUESTION_STATUS',
      payload: {
        status: status,
      },
    });
  },

  create(data: any): void {
    store.dispatch({
      type: 'ASSESSMENT_QUESTION_ITEM',
      payload: {
        item: data,
      },
    });
  },

  clear(): void {
    store.dispatch({
      type: 'ASSESSMENT_QUESTION_ITEM',
      payload: {
        item: {},
      },
    });
  },

  //Send score to server
  saveScore(): void {
    store.dispatch({
      type: 'SAVE_ANXIETY_ASSESSMENT_SCORE',
    });
  },

  loader(status: boolean): QuestionsLoaderAction {
    return {
      type: 'ASSESSMENT_QUESTION_LOADER',
      payload: {
        loader: status,
      },
    };
  },

  loaderModal(status: boolean): QuestionsLoaderModalAction {
    return {
      type: 'ASSESSMENT_QUESTION_LOADER_MODAL',
      payload: {
        loaderModal: status,
      },
    };
  },
};

export default assessmentQuestionsActions;
