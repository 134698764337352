/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { fixDayOfWeek } from '../../../../tools/helpFunctions';

export const mapping = {
  addGroupBuild(data) {
    return {
      groupTopicId: data.groupTopicId,
      therapistId: data.therapistId,
      dayOfWeek: fixDayOfWeek(data.dayOfWeek, data.start, 'to_utc'),
      start: mapping.covertToInt(data.start.subtract(1, 'days').utc('UTC').format('HH:mm')),
      end: mapping.covertToInt(data.end.subtract(1, 'days').utc('UTC').format('HH:mm')),
    };
  },

  covertToInt(string) {
    let h = 0;
    let m = 0;

    if (Number(string.split(':')[0]) > 0) {
      h = Number(string.split(':')[0]) * 60;
    }

    if (Number(string.split(':')[1]) > 0) {
      m = Number(string.split(':')[1]);
    }
    let result = h + m;
    return result;
  },
};
