import React, { useState } from 'react';
import { Spin } from 'antd';
import { shallowEqual, useSelector } from 'react-redux';
import { useWindowResize } from 'beautiful-react-hooks';
// components
import HomeCard from 'components/molecules/cards';
import {
  IntakeConsultationCard,
  SessionCard,
  TherapistHomeSessionItemCoHosted,
  TherapistHomeSessionItemFuture,
} from '../../organisms/lists/sessions/items';
import HelpFullSlider from 'components/organisms/sliders/helpFullTips';
// types
import { stateType } from 'types/types';
// icons
import skillsIcon from '../../../assets/image/dropdown/skills.svg';
import manIcon from '../../../assets/image/dropdown/man.svg';
import calendarIcon from '../../../assets/image/dropdown/calendar.svg';
import timeIcon from '../../../assets/image/dropdown/time.svg';
// style
import './style.scss';
import MissingICDCard from 'components/molecules/missingICDCard';
import { NextCommunityEvent } from '../../organisms/lists/sessions/items/NextCommunityEvent';
import { NextCommunitySession } from '../../organisms/lists/sessions/items/NextCommunitySession';
import { NextAndFutureSessions } from '../../organisms/lists/sessions/client/home/NextAndFutureSessions';

// TODO add actions for dropdowns (this is example mock)
const listItems = [
  {
    text: 'DBT Skills Group',
    icon: skillsIcon,
    link: '#',
  },
  {
    text: 'Dr. Mottoman Sunnigo',
    icon: manIcon,
    link: '#',
  },
  {
    text: 'Tuesday, December 29th',
    icon: calendarIcon,
    onClick: () => console.log('click'),
  },
  {
    text: '12:30 - 1:30 PM EST',
    icon: timeIcon,
    onClick: () => console.log('click'),
  },
];

const HomePageContainer: React.FC = () => {
  const sessions: any = useSelector((state: stateType) => state.sessions.items, shallowEqual);
  const nextEvents: any = useSelector((state: stateType) => state.sessions.nextEvents, shallowEqual);
  const futureEvents: any = useSelector((state: stateType) => state.sessions.futureEvents, shallowEqual);
  const events: any = useSelector((state: stateType) => state.sessions.events, shallowEqual);
  const loader: any = useSelector((state: stateType) => state.sessions.loader, shallowEqual);
  const role: any = useSelector((state: stateType) => state.user.profile.role, shallowEqual);
  const tips: any = useSelector((state: stateType) => state.app.helpSlieder, shallowEqual);
  const profile: any = useSelector((state: stateType) => state.user.profile, shallowEqual);
  const [width, setWidth] = useState<number>(window.innerWidth);

  useWindowResize(() => {
    setWidth(window.innerWidth);
  });

  const controller = () => {
    if (role === 'CUSTOMER') {
      const isPending = profile?.status?.value === 5 || profile?.status?.value === 6;
      const isCancel = profile?.subscriptionStatus?.value === 4 || profile?.subscriptionStatus?.value === 6;
      const isPaused = profile?.subscriptionStatus?.value === 3;

      return (
        <>
          <div className="home-page-container__column">
            <HomeCard
              title={isPending ? ' Intake Consultation' : 'Next Group Session'}
              noSession={sessions?.next?.length === 0 && !isPending}
              scrollHeight={384}
              isCancel={isCancel}
            >
              {isPending ? (
                <IntakeConsultationCard />
              ) : (
                sessions.next.length > 0 &&
                sessions.next?.map((item: any) => {
                  if (item.medicalGroup) {
                    return <SessionCard key={item.id} item={item} type="join" hideMembers isPaused={isPaused} />;
                  } else {
                    return (
                      <NextAndFutureSessions
                        item={item}
                        type={'join'}
                        isPaused={isPaused}
                        key={item.id}
                        e={item}
                        commSession
                      />
                    );
                  }
                })
              )}
            </HomeCard>
            {width > 1350 && (
              <HomeCard title="Helpful Tips">
                <HelpFullSlider list={tips} />
              </HomeCard>
            )}
          </div>
          <div className="home-page-container__column">
            <HomeCard
              title="Future Group Sessions"
              noSession={sessions?.future?.length === 0 && !isPending}
              isCancel={isCancel}
            >
              {isPending ? (
                <IntakeConsultationCard />
              ) : (
                sessions.future.length > 0 &&
                sessions.future?.map((item: any) => {
                  if (item.medicalGroup) {
                    return <SessionCard key={item.id} item={item} isPaused={isPaused} />;
                  } else {
                    return <NextAndFutureSessions key={item.id} e={item} commSession={true} />;
                  }
                })
              )}
            </HomeCard>

            {width < 1350 && (
              <HomeCard title="Helpful Tips">
                <HelpFullSlider list={tips} />
              </HomeCard>
            )}
          </div>
        </>
      );
    } else if (role === 'THERAPIST') {
      return (
        <>
          <div className="home-page-container__column">
            <div>
              <HomeCard title="Next Group Session" noSession={sessions?.next?.length === 0} scrollHeight={465}>
                {sessions.next?.map((item: any) => {
                  if (!item.medicalGroup) {
                    return (
                      <NextCommunityEvent
                        key={item.id}
                        item={item}
                        type="start"
                        isBg
                        therapistName={`${profile.title ? `${profile.title.name}.` : ''} ${profile.firstname} ${
                          profile.lastname
                        }`}
                      />
                    );
                  } else {
                    return (
                      <SessionCard
                        key={item.id}
                        item={item}
                        type="start"
                        isBg
                        therapistName={`${profile.title ? `${profile.title.name}.` : ''} ${profile.firstname} ${
                          profile.lastname
                        }`}
                      />
                    );
                  }
                })}
              </HomeCard>
              <MissingICDCard />
            </div>
          </div>
          <div className="home-page-container__column">
            <HomeCard title="Future Group Sessions" noSession={sessions?.future?.length === 0} scrollHeight={710}>
              <div className="home-page-container__future">
                {sessions.future?.map((item: any) => {
                  if (item.medicalGroup) {
                    return (
                      <TherapistHomeSessionItemFuture
                        key={item.id}
                        item={item}
                        therapistName={`${profile.firstname} ${profile.lastname}`}
                      />
                    );
                  } else {
                    return (
                      <NextCommunitySession
                        key={item.id}
                        item={item}
                        therapistName={`${profile.firstname} ${profile.lastname}`}
                      />
                    );
                  }
                })}
              </div>
            </HomeCard>
            <HomeCard title="Co-hosted Group Sessions" noSession={sessions?.coHosted?.length === 0} scrollHeight={710}>
              <div className="home-page-container__future">
                {sessions.coHosted?.map((item: any) => {
                  if (item.medicalGroup) {
                    return (
                      <TherapistHomeSessionItemCoHosted
                        key={item.id}
                        item={item}
                        therapistName={`${profile.firstname} ${profile.lastname}`}
                      />
                    );
                  }
                })}
              </div>
            </HomeCard>
          </div>
        </>
      );
    }
  };

  return (
    <Spin spinning={loader} style={{ height: 'calc(100vh - 70px)' }}>
      <div className="home-page-container">{!loader && controller()}</div>
    </Spin>
  );
};

export default HomePageContainer;
