/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { call, put, select } from 'redux-saga/effects';
import queryString from 'query-string';
// api
import { api } from '../../../api/clients';
// actions
import clientsAction from '../../actions/clients';
import clientAction from '../../actions/client';
import userActions from '../../actions/user';
import accountAction from '../../actions/account';
// helpers
import { mapping } from '../../../api/mapping/response/user';
import showError from '../showError';
import { getSortValue } from 'tools/helpFunctions';

export function* sagaGetClients() {
  yield put(clientsAction.loader(true));
  const getParams = (state) => state.clients.params;
  const getRole = (state) => state.user.profile.role;
  const getOrder = (state) => state.clients.order;
  const params = yield select(getParams);
  const role = yield select(getRole);
  const order = yield select(getOrder);

  let stringOrder = getSortValue(order);

  try {
    const response = yield call(
      api.get,
      role.toLowerCase(),
      `${queryString.stringify(params, { arrayFormat: 'comma' })}${stringOrder ? stringOrder : ''}`,
    );

    yield put(clientsAction.loader(false));

    yield clientsAction.create(response.data.data, role);
  } catch (e) {
    const error = e.response.data;
    if (error.error_type === 'pagination_error' && /The currentPage must be inferior to/.test(error.message)) {
      const currentPage = Number(error.message.match(/\d+/g).pop());

      yield clientsAction.params({ ...params, page: currentPage });
      yield put(clientsAction.get());
    } else {
      yield put(clientsAction.loader(false));

      showError();
    }
  }
}

export function* sagaGetMissingICDClients() {
  // yield put(clientsAction.loader(true));
  const getParams = (state) => state.clients.params;
  const getRole = (state) => state.user.profile.role;
  const getOrder = (state) => state.clients.order;
  const params = yield select(getParams);
  const role = yield select(getRole);
  const order = yield select(getOrder);

  let stringOrder = getSortValue(order);

  try {
    const response = yield call(api.getMissingICDClients, role.toLowerCase());

    // yield put(clientsAction.loader(false));

    yield clientsAction.createMissingICD(response.data.data, role);
  } catch (e) {
    const error = e.response.data;
    if (error.error_type === 'pagination_error' && /The currentPage must be inferior to/.test(error.message)) {
      const currentPage = Number(error.message.match(/\d+/g).pop());

      yield clientsAction.params({ ...params, page: currentPage });
      yield put(clientsAction.getMissingICDClients());
    } else {
      yield put(clientsAction.loader(false));

      showError();
    }
  }
}

export function* sagaGetClient(action) {
  yield put(clientAction.loader(true));
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);

  try {
    const response = yield call(api.getByID, action.id, role.toLowerCase());

    yield put(clientAction.loader(false));

    yield clientAction.create(response.data.data.item, role);
  } catch (e) {
    yield put(clientAction.loader(false));
    showError();
  }
}

export function* sagaEnabledSmsClient(action) {
  yield put(clientAction.loaderReminders(true));
  const getClient = (state) => state.clients.item;
  const client = yield select(getClient);

  try {
    const { data } = yield call(api.enableSms, action.id, action.enable);

    yield put(clientAction.loaderReminders(false));

    yield clientAction.create({ ...client, enableSms: data.data.item.enableSms }, '');
  } catch (e) {
    yield put(clientAction.loaderReminders(false));
    showError();
  }
}

export function* sagaEnabledEmailClient(action) {
  yield put(clientAction.loaderReminders(true));
  const getClient = (state) => state.clients.item;
  const client = yield select(getClient);

  try {
    const { data } = yield call(api.enableEmails, action.id, action.enable);

    yield put(clientAction.loaderReminders(false));

    yield clientAction.create({ ...client, enableEmails: data.data.item.enableEmails }, '');
  } catch (e) {
    yield put(clientAction.loaderReminders(false));
    showError();
  }
}

export function* sagaSetICD10Code(action) {
  yield put(clientAction.loader(true));
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);
  const getClient = (state) => state.clients.item;
  const client = yield select(getClient);

  try {
    const response = yield call(api.setICD10, action.id, role.toLowerCase(), { code: action.icd10 });

    yield put(clientAction.loader(false));

    yield clientAction.create({ ...client, icd10: action.icd10 });
  } catch (e) {
    yield put(clientAction.loader(false));
    showError();
  }
}

export function* sagaRestartSubscription(action) {
  yield put(accountAction.loader(true));
  yield put(clientAction.loaderModal(true));
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);

  try {
    const response = action.id
      ? yield call(api.restartSubscriptionByID, role.toLowerCase(), action.id)
      : yield call(api.restartSubscription, role.toLowerCase());

    yield put(accountAction.loader(false));
    yield put(clientAction.loaderModal(false));

    if (action.callback) {
      action.callback();
    }

    if (action.id) {
      return;
    }

    yield actionUpdateSelf(response, role);
  } catch (e) {
    yield put(accountAction.loader(false));
    yield put(clientAction.loaderModal(false));
    showError();
  }
}

export function actionUpdateSelf(response, role) {
  userActions.create(response.data.data.item, role);
  userActions.state(mapping.stateFlowBuild(response.data.data.item.stateFlow, response.data.data.item));
}

function actionUpdate(response, role) {
  clientAction.create(response.data.data.item, role);
}

export function* sagaCancelSubscription(action) {
  yield put(clientAction.loaderModal(true));
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);

  try {
    yield call(api.cancelSubscriptionByID, role.toLowerCase(), action.id);

    yield put(clientAction.loaderModal(false));

    if (action.callback) {
      action.callback();
    }
  } catch (e) {
    yield put(clientAction.loaderModal(false));
    showError();
  }
}

export function* sagaApproveClient(action) {
  yield put(clientAction.loaderModal(true));
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);

  try {
    yield call(api.approveByID, role.toLowerCase(), action.id);

    yield put(clientAction.loaderModal(false));

    if (action.callback) {
      action.callback();
    }
  } catch (e) {
    yield put(clientAction.loaderModal(false));
    showError();
  }
}

export function* sagaApproveNoShowClient(action) {
  yield put(clientAction.loaderModal(true));
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);

  try {
    yield call(api.noShow, role.toLowerCase(), action.id);

    yield put(clientAction.loaderModal(false));

    if (action.callback) {
      action.callback();
    }
  } catch (e) {
    yield put(clientAction.loaderModal(false));
    showError();
  }
}

export function* sagaArchiveClient(action) {
  yield put(clientAction.loaderModal(true));
  const getRole = (state) => state.user.profile.role;
  // const getProfile = (state) => state.user.profile;
  const role = yield select(getRole);
  // const profile = yield select(getProfile);

  try {
    yield call(api.archiveByID, role.toLowerCase(), action.id);

    yield put(clientAction.loaderModal(false));

    if (action.callback) {
      action.callback();
    }
  } catch (e) {
    yield put(clientAction.loaderModal(false));
    showError();
  }
}

export function* sagaPauseClient(action) {
  yield put(clientAction.loaderModal(true));
  const getRole = (state) => state.user.profile.role;
  // const getProfile = (state) => state.user.profile;
  const role = yield select(getRole);
  // const profile = yield select(getProfile);

  try {
    yield call(api.pauseByID, role.toLowerCase(), action.id, action.data);

    yield put(clientAction.loaderModal(false));

    if (action.callback) {
      action.callback();
    }
  } catch (e) {
    yield put(clientAction.loaderModal(false));
    showError();
  }
}

export function* sagaUnpauseClient(action) {
  yield put(clientAction.loaderModal(true));
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);

  try {
    yield call(api.unpauseByID, role.toLowerCase(), action.id);

    yield put(clientAction.loaderModal(false));

    if (action.callback) {
      action.callback();
    }
  } catch (e) {
    yield put(clientAction.loaderModal(false));
    showError();
  }
}

export function* sagaUnarchiveClient(action) {
  yield put(clientAction.loaderModal(true));
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);

  try {
    yield call(api.unarchiveByID, role.toLowerCase(), action.id);

    yield put(clientAction.loaderModal(false));

    if (action.callback) {
      action.callback();
    }
  } catch (e) {
    yield put(clientAction.loaderModal(false));
    showError();
  }
}

export function* sagaEditClient(action) {
  yield put(clientAction.loaderModal(true));
  const getRole = (state) => state.user.profile.role;
  // const getProfile = (state) => state.user.profile;
  const role = yield select(getRole);
  // const profile = yield select(getProfile);

  try {
    const response = yield call(api.edit, role.toLowerCase(), action.id, action.data);

    yield put(clientAction.loaderModal(false));

    yield clientAction.create(response.data.data.item, role);
  } catch (e) {
    yield put(clientAction.loaderModal(false));
    showError();
  }
}
