/*
 *
 * Assesments reducer
 *
 */
const initialState = {
  item: {},
  items: [],
  loader: false,
  loaderModal: false,
};

export type assesmentsInitStateType = typeof initialState;

interface assesmentsActionTypes {
  type: string;
  payload: {
    item: any;
    items: Array<any>;
    loader: boolean;
    loaderModal: boolean;
  };
}

const assesments = (state = initialState, action: assesmentsActionTypes): assesmentsInitStateType => {
  switch (action.type) {
    case 'ASSESMENT':
      return Object.assign({}, state, {
        item: action.payload.item,
      });
    case 'ASSESMENTS':
      return Object.assign({}, state, {
        items: action.payload.items,
      });
    case 'ASSESMENTS_LOADER':
      return Object.assign({}, state, {
        loader: action.payload.loader,
      });
    case 'ASSESMENTS_LOADER_MODAL':
      return Object.assign({}, state, {
        loaderModal: action.payload.loaderModal,
      });

    default:
      return state;
  }
};

export default assesments;
