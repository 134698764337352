import React, { useEffect, useState } from 'react';
import { Redirect, Route, RouteProps, useHistory } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import _ from 'lodash';
// types
import { stateType } from '../../types/types';
// components
import LoadingPage from './loading';
import HandlerAnotherRole from './HandlerAnotherRole';
import ResetPasswordImport from '../../pages/auth/resetPasswordImport';
import PageWaiting from '../../pages/customer/consultation/waiting';

interface LayoutDefault {
  component: React.ComponentType<RouteProps>;
  path?: string;
  exact?: boolean;
  for?: Array<string>;
}

const LayoutPage: React.FC<LayoutDefault> = ({ component: Component, ...args }: LayoutDefault) => {
  const [state, setState] = useState('');
  const auth = useSelector((state: stateType) => state.app.auth, shallowEqual);
  const role = useSelector((state: stateType) => state.user.profile.role, shallowEqual);
  const stateFlow = useSelector((state: stateType) => state.user.state, shallowEqual);

  const redirect_url =
    args.for?.length === 1
      ? `/login-${args.for[0].toLowerCase()}`
      : localStorage.getItem('role')
      ? `/login-${localStorage.getItem('role')}`
      : '/login-customer';

  const history = useHistory();

  useEffect(() => {
    setState(stateFlow);
  }, [stateFlow]);

  return (
    <Route
      render={() => {
        if (auth) {
          if (auth === 'auth') {
            if (role === 'THERAPIST' || role === 'ADMIN') {
              if (_.find(args.for, (element) => element === role)) {
                return (
                  <Route
                    path={args.path}
                    render={() => {
                      return <Component {...args} />;
                    }}
                  />
                );
              }
              return <HandlerAnotherRole role={args.for?.length === 1 ? args.for[0].toLowerCase() : ''} />;
            }

            if (role === 'CUSTOMER') {
              if (state === 'start_subscription' || state === 'subscription_in_progress') {
                return <Redirect to={'/stripe'} />;
              }

              if (state === '') {
                return <PageWaiting />;
              }

              if (state === 'group_change') {
                return <Redirect to={'/profile/choice-group'} />;
              }

              if (state === 'progress') {
                return <Redirect to={'/progress-questions'} />;
              }

              if (state === 'anxiety-assessment' || state === 'registered') {
                return <Redirect to={'/anxiety-assessment'} />;
              }

              if (state === 'group_search' || state === 'waiting') {
                return <Redirect to={'/consultation'} />;
              }

              if (state === 'approve_consultations') {
                return <Redirect to={'/approve-consultations'} />;
              }

              if (state === 'redirect_to_reset_password') {
                return <ResetPasswordImport />;
              }

              if (state === 'restart_pre_cancel' || state === 'restart_cancel') {
                return <Redirect to={'/profile/restart-subscriptions'} />;
              }

              if (state === 'group_joined' || state === 'questioning' || state === 'recommendation') {
                if (_.find(args.for, (element) => element === role)) {
                  return (
                    <Route
                      path={args.path}
                      render={() => {
                        return <Component {...args} />;
                      }}
                    />
                  );
                }
                return <HandlerAnotherRole role={args.for?.length === 1 ? args.for[0].toLowerCase() : ''} />;
              }
            }
          }

          if (auth === 'login' || auth === 'logout' || auth === '') {
            auth === 'login' &&
              localStorage.setItem(
                'redirect_url',
                JSON.stringify({
                  role: role || args.for?.length === 1 ? args.for?.[0].toLowerCase() : '',
                  path: `${history.location.pathname}${history.location.search}` || '',
                }),
              );

            return <Redirect to={redirect_url} />;
          }

          if (auth === 'registration') {
            return <Redirect to="/registration" />;
          }

          if (auth === 'loading') {
            return <LoadingPage />;
          }
        }
      }}
    />
  );
};

export default LayoutPage;
