import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Spin } from 'antd';
import cn from 'classnames';
// components
import { AnswerMulti } from '../../molecules/questions/cancel';
import CancelBefore from 'components/molecules/groupQuestions/BeforeAfter/CancelBefore';
import CancelAfter from 'components/molecules/groupQuestions/BeforeAfter/CancelAfter';
import CloseChangeBtn from 'components/molecules/groupQuestions/CloseQuestion';
import RateQuestion from 'components/molecules/groupQuestions/RateQuestion/RateQuestion';
import Question from 'components/molecules/groupQuestions/QuestionCard';
import ArbitraryAnswer from 'components/molecules/groupQuestions/Answers/ArbitraryAnswer';
import SimpleQuestion from 'components/molecules/groupQuestions/QuestionCard/SimpleQuestion';
import MultiArbitrary from 'components/molecules/groupQuestions/Answers/MultiArbitrary';
import GroupQuestion from 'components/molecules/groupQuestions/GroupQuestion';
// actions
import changeGroupActions from '../../../redux/actions/cancel_group';
import choiceGroupAction from '../../../redux/actions/choice_group';
// helpers
import mappingQuestions from '../../../api/mapping/response/questions';
// types
import { stateType } from 'types/types';
import { GroupActionStatus, SearchGroupQuery } from 'types/actions/common-types';
// style
import './style.scss';
// icons
import icon from 'assets/image/group/back.svg';
import eventAnalytics from '../../../redux/actions/analytics';

const CancelGroupOrganism: React.FC = () => {
  const question: any = useSelector((state: stateType) => state.questions.item, shallowEqual);
  const status: string = useSelector((state: stateType) => state.questions.status, shallowEqual);
  const loader: boolean = useSelector((state: stateType) => state.questions.loader, shallowEqual);

  useEffect(() => {
    changeGroupActions.status(GroupActionStatus.Before);
  }, []);

  const questionController = () => {
    switch (status) {
      case 'before':
        return <CancelBefore />;
      case 'step':
        return <Step question={question} />;
      case 'after':
        return <CancelAfter />;

      default:
        return null;
    }
  };

  return (
    <div className={cn('change-group-wrap', { 'change-group_pad': status === 'before' })}>
      <div className={cn('change-group', { 'full-width': question.questionType === 'group' })}>
        <Spin spinning={loader}>{!loader && questionController()}</Spin>
      </div>
    </div>
  );
};

export default CancelGroupOrganism;

const Step = ({ question }: any) => {
  const [checkedValues, setCheckedValues] = useState<any>(question?.defaultValue?.answerIds || []);
  const [answerText, setAnswerText] = useState(question?.defaultValue?.answerText || '');
  const [rating, setRating] = useState(0);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    initDefaultValue();
  }, []);

  useEffect(() => {
    question.questionType === 'group' && dispatch(choiceGroupAction.get(SearchGroupQuery.Cancel));
  }, [question.id]);

  const initDefaultValue = () => {
    if (question.number) {
      dispatch(changeGroupActions.progress(question.number * 15));
    }

    if (question.defaultValue) {
      switch (question.questionType) {
        case 'multi':
          return setCheckedValues(question.defaultValue.answerIds);

        case 'evaluation':
          setRating(question.defaultValue.rating);
          return setAnswerText(question.defaultValue.answerText);

        default:
          return;
      }
    }
  };

  const onChangeRate = (value: any) => {
    setRating(value);
  };

  const onChangeSingleAnswer = (answerId: any) => {
    const data = {
      questionId: question.id,
      answersIds: [answerId],
      answerText: answerText,
    };
    const answersArray = question?.answers.map((e: any) => e);
    dispatch(
      changeGroupActions.answer(mappingQuestions.buildAnswer(data, question.answerType), () => {
        if (question.number === 5 && answerId === 44) {
          history.push('/');
        }
        if (question.number === 7) {
          dispatch(changeGroupActions.progress(0));
          changeGroupActions.status(GroupActionStatus.After);
          return;
        }
        return dispatch(changeGroupActions.next());
      }),
    );
    switch (question?.number) {
      case 4:
        return dispatch(
          eventAnalytics.analytics('cancel-subscription-different', { cancel: answersArray[1]?.id === answerId }),
        );
      case 5:
        return dispatch(
          eventAnalytics.analytics('cancel-subscription-discount', { cancel: answersArray[1]?.id === answerId }),
        );
      case 7:
        return dispatch(eventAnalytics.analytics('cancel-subscription-final'));

      default:
        return console.log('err');
    }
  };

  const goNext = () => {
    const data = {
      questionId: question.id,
      rating: rating,
      answersIds: checkedValues,
      answerText: answerText,
    };
    //here
    dispatch(
      changeGroupActions.answer(mappingQuestions.buildAnswer(data, question.answerType), () => {
        if (question.number === 7) {
          dispatch(changeGroupActions.progress(0));
          changeGroupActions.status(GroupActionStatus.After);
          return;
        }
        return dispatch(changeGroupActions.next());
      }),
    );
    switch (question?.number) {
      case 1:
        return dispatch(eventAnalytics.analytics('cancel-subscription-nps', { rating }));
      case 2:
        return dispatch(eventAnalytics.analytics('cancel-subscription-nps-group', { rating }));
      case 3:
        return dispatch(eventAnalytics.analytics('cancel-subscription-groups', { answerIds: [question.id] }));
      case 6:
        return dispatch(
          eventAnalytics.analytics('cancel-subscription-reasons', { answerIds: checkedValues, answerText: answerText }),
        );
      default:
        return console.log('err');
    }
  };

  const goBack = () => {
    if (question.serialNumber > 1) {
      dispatch(changeGroupActions.after());
    } else {
      changeGroupActions.status(GroupActionStatus.Before);
    }
  };

  const answerController = () => {
    switch (question.questionType) {
      case 'multi':
        return <AnswerMulti goNext={goNext} onChange={setCheckedValues} />;

      // TODO this is a multiple question
      case 'single':
        return (
          <MultiArbitrary
            question={question}
            onChange={setCheckedValues}
            checkedValues={checkedValues}
            answerText={answerText}
            setAnswerText={setAnswerText}
            small
          />
        );

      case 'evaluation':
        return (
          <ArbitraryAnswer
            answerText={answerText}
            setAnswerText={(text) => setAnswerText(text)}
            placeholder={question?.additions?.placeholder}
          />
        );

      default:
        return null;
    }
  };

  const disabledButton = () => {
    return (question.number <= 2 && !rating) || (question.number === 6 && (!checkedValues.length || !checkedValues[0]));
  };

  const renderComponents = () => {
    switch (question.questionType) {
      case 'single_large':
        return (
          <SimpleQuestion
            question={question}
            onBack={goBack}
            closeBtn={<CloseChangeBtn />}
            onChoose={onChangeSingleAnswer}
            totalStepCount={question.maxQuestionNumber}
          >
            {question.number === question.maxQuestionNumber && (
              <>
                <div className="change-group-container__back-wrap reverse">
                  <img src={icon} alt="logo" />
                </div>
                <div className="change-group-container__main-title">{question.question}</div>
                <div className="change-group-container__desc-text no-mar">{question.additions.subQuestion}</div>
              </>
            )}
          </SimpleQuestion>
        );

      case 'group':
        return (
          <GroupQuestion
            question={question}
            onBack={goBack}
            closeBtn={<CloseChangeBtn />}
            totalStepCount={question.maxQuestionNumber}
            goNext={goNext}
          />
        );

      case 'multi_with_arbitrary':
        return (
          <Question
            question={question}
            nextDisabled={disabledButton()}
            onNext={goNext}
            renderController={answerController}
            onBack={goBack}
            closeBtn={<CloseChangeBtn />}
            apply={question.questionType === 'multi' || question.questionType === 'multi_with_arbitrary'}
            totalStepCount={question.maxQuestionNumber}
            answerText={answerText}
          >
            <div className="questions__question bold">{question.question}</div>
            <MultiArbitrary
              question={question}
              onChange={setCheckedValues}
              checkedValues={checkedValues}
              answerText={answerText}
              setAnswerText={setAnswerText}
              placeholder="Type here.."
              isOther
            />
          </Question>
        );

      default:
        return (
          <Question
            question={question}
            nextDisabled={disabledButton()}
            onNext={goNext}
            renderController={answerController}
            onBack={goBack}
            closeBtn={<CloseChangeBtn />}
            apply={question.questionType === 'multi' || question.questionType === 'multi_with_arbitrary'}
            totalStepCount={question.maxQuestionNumber}
          >
            {question.number < 3 && <RateQuestion question={question} onChangeRate={onChangeRate} rating={rating} />}
          </Question>
        );
    }
  };
  return renderComponents();
};
