import React, { useCallback } from 'react';
import BackgroundSelectionHeader from './BackgroundSelectionHeader/BackgroundSelectionHeader';
import BackgroundThumbnail from './BackgroundThumbnail/BackgroundThumbnail';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { backgroundConfig } from '../VideoProvider/useBackgroundSettings/useBackgroundSettings';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import VideoTrack from '../VideoTrack/VideoTrack';
import { LocalVideoTrack } from 'twilio-video';
import CloseIcon from '../../../icons/CloseIcon';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '1200px',
    minHeight: '400px',
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100vw - 32px)',
    },
    '& .inputSelect': {
      width: 'calc(100% - 35px)',
    },
  },
  leftColumn: {
    float: 'left',
    width: 220,
    height: '100%',
    minHeight: 380,
  },
  leftMenuLink: {
    display: 'block',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    padding: '8px 12px',
    width: 175,
    borderRadius: 6,
    color: '#667085',
    '&:hover': {
      color: '#2B3C5C',
      backgroundColor: '#E6F2F7',
    },
  },
  rightColumn: {
    float: 'left',
    width: 'calc(100% - 220px)',
  },
  drawer: {
    display: 'flex',
    width: theme.rightDrawerWidth,
    height: `calc(100% - ${theme.footerHeight}px)`,
  },
  thumbnailContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '5px',
    overflowY: 'auto',
  },
  paper: {
    borderRadius: 20,
    maxWidth: 1200,
    [theme.breakpoints.down('xs')]: {
      margin: '16px',
    },
  },
  preview: {
    width: '400px',
    maxHeight: '270px',
    borderRadius: 12,
    margin: '0.5em auto',
    '& video': {
      maxHeight: '200px',
    },
  },
  button: {
    float: 'right',
    width: '30px',
    height: '30px',
    backgroundColor: '#828282',
    border: '0',
    borderRadius: '50%',
    padding: '6px',
  },
  supportInfo: {
    fontSize: '12px',
    textAlign: 'center',
    paddingTop: '12px',
    paddingBottom: '12px',
    color: '#15141A',
  },
}));

function BackgroundSelectionDialog({
  open,
  onClose,
  openDeviceDialog,
}: {
  open: boolean;
  onClose: () => void;
  openDeviceDialog: () => void;
}) {
  const classes = useStyles();
  const { isBackgroundSelectionOpen, setIsBackgroundSelectionOpen, localTracks } = useVideoContext();

  const imageNames = backgroundConfig.imageNames;
  const images = backgroundConfig.images;
  const localVideoTrack = localTracks.find((track) => track.kind === 'video') as LocalVideoTrack | undefined;

  const audioVideoClick = useCallback((e) => {
    openDeviceDialog();
    return false;
  }, []);

  const virtualBackgroundClick = useCallback((e) => {
    return false;
  }, []);

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.paper }}>
      <DialogTitle>
        Settings
        <button className={classes.button} onClick={onClose}>
          <CloseIcon />
        </button>
      </DialogTitle>
      <DialogContent className={classes.container}>
        <div className={classes.leftColumn}>
          <a href="#" className={classes.leftMenuLink} onClick={audioVideoClick}>
            Audio & Video Settings
          </a>
          <a href="#" className={classes.leftMenuLink} onClick={virtualBackgroundClick}>
            Virtual Background
          </a>
        </div>
        <div className={classes.rightColumn}>
          {localVideoTrack && (
            <div className={classes.preview}>
              <VideoTrack isLocal track={localVideoTrack} />
            </div>
          )}
          <BackgroundSelectionHeader onClose={() => setIsBackgroundSelectionOpen(false)} />
          <div className={classes.thumbnailContainer}>
            <BackgroundThumbnail thumbnail={'none'} name={'None'} />
            <BackgroundThumbnail thumbnail={'blur'} name={'Blur'} />
            <BackgroundThumbnail thumbnail={'custom'} name={'custom'} />
            {images.map((image, index) => (
              <BackgroundThumbnail
                thumbnail={'image'}
                name={imageNames[index]}
                index={index}
                imagePath={image}
                key={image}
              />
            ))}
          </div>
          <div>
            <p className={classes.supportInfo}>
              This feature is only available on Google Chrome and other Chromium based browsers (e.g. Microsoft Edge)
            </p>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default BackgroundSelectionDialog;
