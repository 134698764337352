import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';
import { AnimatePresence } from 'framer-motion';
import cn from 'classnames';
import { useHistory, useParams } from 'react-router-dom';
// actions
import stripeAction from '../../../redux/actions/stripe';
import accountAction from 'redux/actions/account';
import appAction from 'redux/actions/app';
// components
import Details from 'components/organisms/stripe/form/Details';
import SummaryChange from 'components/organisms/stripe/form/Summary/SummaryChange';
import SubscriptionList from 'components/organisms/stripe/list';
// types
import { stateType } from 'types/types';
// styles
import './style.scss';

const StripeBillingContainer: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { action } = useParams<{ action: string }>();
  const [tab, setTab] = useState<1 | 2>(1);
  const loader = useSelector((state: stateType) => state.stripe.loader, shallowEqual);
  const items = useSelector((state: stateType) => state.stripe.items, shallowEqual);
  const { medicalGroup }: any = useSelector((state: stateType) => state.account.info, shallowEqual);
  const role = useSelector((state: stateType) => state.user.profile.role, shallowEqual);

  const [title, setTitle] = useState('Change billing schedule');

  useEffect(() => {
    if (action) {
      history.push('/change-billing');
    }

    dispatch(accountAction.get(role.toLowerCase()));
    items.length === 0 && dispatch(stripeAction.get());
    (!medicalGroup || !tab) && dispatch(accountAction.getInfo(role.toLowerCase()));

    return () => {
      dispatch(accountAction.set({}));
      setTab(1);
      dispatch(stripeAction.setItem({}));
    };
  }, []);

  const onGoBack = () => {
    setTitle('Change billing schedule');
    history.push('/change-billing');
    setTab(1);
  };

  const onClose = () => {
    history.push('/profile');
    dispatch(appAction.account('subscription'));
  };

  return (
    <div className="stripe-container change custom-style">
      <div className="stripe-container__body box">
        <div className="stripe-container__top">
          <div className="stripe-container__back">
            {action && <button className="stripe-container__cancel" onClick={onGoBack} />}
          </div>
          <div className="stripe-container__title">{title}</div>
          <div className="stripe-container__close" onClick={onClose}>
            <button />
          </div>
        </div>
        <div className="stripe-container__wrap">
          <div className="stripe-container__back">
            {action && <button className="stripe-container__cancel" onClick={onGoBack} />}
          </div>
          <div className="stripe-container__card">
            <div className={cn('stripe-container__row', { second: tab === 2 })}>
              <div className="stripe-container__col">
                {tab && <Details tab={tab} setTab={setTab} medicalGroup={medicalGroup} />}
              </div>
              <div className={cn('stripe-container__col', { second: tab === 2 })}>
                <Spin spinning={loader || items.length === 0}>
                  <AnimatePresence initial={false} exitBeforeEnter>
                    {items.length !== 0 ? (
                      action ? (
                        <SummaryChange key="summ" />
                      ) : (
                        <SubscriptionList
                          key="list"
                          items={items}
                          loader={loader}
                          setTab={setTab}
                          setTitle={setTitle}
                        />
                      )
                    ) : null}
                  </AnimatePresence>
                </Spin>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StripeBillingContainer;
