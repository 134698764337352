/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { put, call, select } from 'redux-saga/effects';
import showError from '../showError';
// api
import { apiQuestions } from '../../../api/question';
import { api } from '../../../api/clients';
// actions
import questionsAction from '../../actions/questions';
import userActions from '../../actions/user';
import clientAction from '../../actions/client';
import changeGroupActions from '../../actions/change_group';
import choiceGroupAction from '../../actions/choice_group';
// helpers
import mappingQuestions from '../../../api/mapping/response/questions';
import { mapping } from '../../../api/mapping/response/user';
// types
import { GroupActionStatus } from '../../../types/actions/common-types';
// saga`s

export function* sagaChangeNextQuestion(action) {
  yield put(questionsAction.loader(true));
  yield questionsAction.create({});
  try {
    const response = yield call(apiQuestions.next, action ? action.number : null, 'change-group');

    yield questionsAction.status('step');
    yield questionsAction.create(mappingQuestions.buildQuestion(response.data.data.item, 'next'));
    yield put(questionsAction.loader(false));
  } catch (e) {
    yield put(questionsAction.loader(false));
    showError();
  }
}

export function* sagaChangeAnswer(action) {
  yield put(questionsAction.loader(true));
  try {
    if (action.questionNumber === 5) {
      changeGroupActions.status(GroupActionStatus.After);
    }

    yield call(apiQuestions.answer, action.data, 'change-group');

    yield put(questionsAction.loader(false));

    action.callback && action.callback();

    if (action.questionNumber === 5) {
      userActions.state('group_change');
    }
  } catch (e) {
    console.log(e);
    yield put(questionsAction.loader(false));
    showError();
  }
}

export function* sagaChangeAfterQuestion(action) {
  yield put(questionsAction.loader(true));
  yield questionsAction.create({});
  try {
    const response = yield call(apiQuestions.after, action ? action.number : null, 'change-group');
    yield questionsAction.status('step');
    yield questionsAction.create(mappingQuestions.buildQuestion(response.data.data.item, 'after'));
    yield put(questionsAction.loader(false));
  } catch (e) {
    console.log(e);
    yield put(questionsAction.loader(false));
    showError();
  }
}

export function* sagaGoBackQuestion(action) {
  yield put(choiceGroupAction.loader(true));
  yield questionsAction.create({});
  try {
    const response = yield call(apiQuestions.goBack, 'change-group');
    yield questionsAction.status('go_back');
    yield questionsAction.create(mappingQuestions.buildQuestion(response.data.data.item, 'after'));
    userActions.state('group_joined');

    if (action.callback) {
      action.callback();
    }

    yield put(choiceGroupAction.loader(false));
  } catch (e) {
    console.log(e);
    yield put(choiceGroupAction.loader(false));
    showError();
  }
}

export function* sagaCenselChangeGroup() {
  yield put(choiceGroupAction.loader(true));
  try {
    const response = yield call(apiQuestions.cansel);

    userActions.state(mapping.stateFlowBuild(response.data.data.item.stateFlow, response.data.data.item));
    yield put(choiceGroupAction.loader(true));
    changeGroupActions.status('');
  } catch (e) {
    console.log(e);
    yield put(choiceGroupAction.loader(true));
    showError();
  }
}

export function* sagaChangeGroup(action) {
  yield put(clientAction.loaderModal(true));
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);
  try {
    yield call(api.change, role.toLowerCase(), action.data.customerId, action.data.groupId);
    yield put(clientAction.loaderModal(false));
  } catch (e) {
    yield put(clientAction.loaderModal(false));
    showError();
  }
}
