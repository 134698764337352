import React, { FC, useState } from 'react';
import cn from 'classnames';
import { AnimatePresence } from 'framer-motion';
import { shallowEqual, useSelector } from 'react-redux';
// components
import EventSort from '../../../molecules/sortBlock/eventSort';
import SearchEvents from '../../../organisms/searchs/events';
// icons
import { FilterOutlined } from '@ant-design/icons';
// style
import '../style.scss';
// config
import SearchEventsSelects from '../../../organisms/searchs/events/SearchSelects';
// types
import { roleType } from '../../../../types/commonPropTypes';
import { stateType } from '../../../../types/types';

interface FilterButtonInterface {
  show: boolean;
  setShow: any;
}

const EventNavigationContainer: FC = () => {
  const [show, setShow] = useState(false);

  const { role }: roleType = useSelector((state: stateType) => state.user.profile, shallowEqual);

  return (
    <div className="navigation-table-wrap">
      <div className="navigation-table-container">
        <div className="navigation-table-container__column">
          <SearchEvents role={role} />
        </div>
        <div className="navigation-table-container__column">
          <EventSort role={role} show={show} setShow={setShow} />
        </div>
        {/*<div className="navigation-table-container__column">*/}
        {/*  <FilterButton show={show} setShow={setShow} />*/}
        {/*</div>*/}
      </div>
      <AnimatePresence initial={false}>{show && <SearchEventsSelects role={role} />}</AnimatePresence>
    </div>
  );
};

export default EventNavigationContainer;

const FilterButton: FC<FilterButtonInterface> = ({ show, setShow }: FilterButtonInterface) => {
  return (
    <div className={cn('btn-filter btn-filter__events', { 'btn-filter__active': show })} onClick={() => setShow(!show)}>
      <FilterOutlined style={{ fontSize: '20px', color: show ? '#1891e5' : '#92929d', margin: '0 15px' }} />
      <span>Filter</span>
    </div>
  );
};
