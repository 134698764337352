import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { stateType } from '../../../types/types';

//actions
import npsScoreAction from '../../../redux/actions/nps_score';

import Moment from 'react-moment';
import cn from 'classnames';

import { Collapse, Spin } from 'antd';

// nouislider
import Nouislider from 'nouislider-react';
import 'nouislider/distribute/nouislider.css';

// style
import './style.scss';
// helpers
import useDidMountEffect from '../../../configs/hooks/useDidMountEffect';
import { scrollToBottom } from '../../../tools/helpFunctions';

const NpsScoreContainer: React.FC<any> = ({ paramsId }: any) => {
  const [page, setPage] = useState(1);

  const npsScore = useSelector((state: stateType) => state.nps_score);
  const totalCount = useSelector((state: stateType) => state.nps_score.totalCount);
  const params = useSelector((state: stateType) => state.nps_score.params);
  const loader = useSelector((state: stateType) => state.nps_score.loader);

  const refScrollTo = useRef<HTMLDivElement>(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (page > 1) {
      npsScoreAction.params({ ...params, page });
      dispatch(npsScoreAction.getNpsScore(paramsId));
    }

    return () => {
      npsScoreAction.params({ page: 1, per_page: 5 });
    };
  }, [page]);

  const onShowMore = (): void => {
    setPage(page + 1);
  };

  useDidMountEffect(() => {
    scrollToBottom(refScrollTo);
  }, [npsScore.items]);

  return (
    <div className={cn('nps-score-container', { content: npsScore?.items.length })}>
      <Spin spinning={loader}>
        <h3 className="nps-score-container__title">NPS Score </h3>
        {npsScore?.items.length === 0 && (
          <span className="nps-score-container__text">This user has not yet given an NPS Score </span>
        )}
        <Collapse className="nps-score-container__collapse" accordion>
          {npsScore &&
            npsScore?.items.length > 0 &&
            npsScore.items.map((item: any) => {
              return (
                <Collapse.Panel
                  showArrow={false}
                  header={<ScorePanel itemId={item.id} date={item.createdAt} />}
                  key={item.id}
                  className="nps-score-container__panel"
                >
                  <RecommendInfo score={item.rating} reason={item.comment} />
                </Collapse.Panel>
              );
            })}
        </Collapse>
        {totalCount > 5 && (
          <div className="nps-score-container__button-container">
            {page * 5 < totalCount && (
              <button className="nps-score-container__button" type="button" onClick={onShowMore}>
                Show more
              </button>
            )}
          </div>
        )}
        <div ref={refScrollTo} />
      </Spin>
    </div>
  );
};

export default NpsScoreContainer;

const ScorePanel: React.FC<any> = ({ itemId, itemKey, date }: any) => {
  const open = +itemKey === itemId;
  return (
    <div className="history-container__header">
      <Moment format="dddd, MMMM Do, yyyy" className="history-container__header_date">
        {date}
      </Moment>
      <p className={cn('history-container__header_members', { open })} />
    </div>
  );
};

const RecommendInfo: React.FC<any> = ({ score: step, reason }: any) => {
  return (
    <div className="smile-range">
      <h2 className="history-container__title">How likely are you to recommend Grouport to a friend or colleague? </h2>

      <div className="smile-range__items">
        {Array(10)
          .fill(0)
          .map((_, i) => (
            <span key={i}>{i + 1}</span>
          ))}
      </div>
      <div className={'score-smile__box i' + step.toString()}>
        <Nouislider
          range={{ min: 1, max: 10 }}
          start={step}
          step={1}
          connect={[true, false]}
          clickablePips={false}
          disabled={true}
        />
      </div>
      <div className={'primary-reason'}>
        <h2 className="history-container__title">What is the primary reason for your score? </h2>
        <div className={'primary-reason__text'}>
          <p>{reason}</p>
        </div>
      </div>
    </div>
  );
};
