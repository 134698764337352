import React, { useCallback } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Spin } from 'antd';
// components
import AuthTemplate from '../../components/templates/authTemplate';
import ForgotForm from '../../components/organisms/forms/forgotPassword';
// components texts
import { ForgotPasswordPreviewText } from '../../components/atoms/texts/authTemplate/preview-text';
import ForgotSuccessModal from '../../components/organisms/modals/forgotPassword/forgotSuccess';

const ForgotPasswordPage: React.FC = () => {
  const [visible, setVisible] = React.useState(false);

  const textFooter = '©2022 Grouport Inc. All rights reserved.';

  const loader: boolean = useSelector((state: any) => state.auth.loader, shallowEqual);

  const openSuccess = useCallback(() => {
    setVisible(true);
  }, []);

  const handleCancel = useCallback(() => {
    setVisible(false);
  }, []);

  return (
    <>
      <ForgotSuccessModal visible={visible} handleCancel={handleCancel} />
      <div className="login-page custom-style">
        <AuthTemplate text_footer={textFooter} headerComponent={<ForgotPasswordPreviewText />} box_down={true}>
          <Spin tip="Loading..." spinning={loader} data-testid="forgot-password-spin">
            <ForgotForm openSuccess={openSuccess} />
          </Spin>
        </AuthTemplate>
      </div>
    </>
  );
};

export default ForgotPasswordPage;
