import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Select, Spin } from 'antd';
import { Controller, useForm } from 'react-hook-form';
import Moment from 'react-moment';
// types
import { stateType } from '../../../../../types/types';
// actions
import clientAction from 'redux/actions/client';
import sessionsAction from 'redux/actions/sessions';
import groupsAction from 'redux/actions/groups';
import clientsAction from 'redux/actions/clients';
// icons
import Icon from '@ant-design/icons';
import { ReactComponent as IconCalendar } from 'assets/image/dropdown/calendar.svg';
import { ReactComponent as CloseIcon } from '../../../../../assets/image/account/close4.svg';
import IconDone from 'assets/image/questions/after.png';
// components
import { ClientBox } from '../../adminChangeGroup';
import InputField from 'components/molecules/inputField';
import BeginsIn from 'components/molecules/beginsIn';
// styles
import './styles.scss';
import { timeConvertor, week_days } from 'configs/tables/admin/clients';

const RestartSubscription: React.FC<any> = ({ id }: any) => {
  const [visible, setVisible] = useState(false);
  const [success, setSuccess] = useState(false);

  const loader = useSelector((state: stateType) => state.clients.loaderModal);
  const item = useSelector((state: stateType) => state.clients.item);
  const group = useSelector((state: stateType) => state.groups.item);
  const session = useSelector((state: stateType) => state.sessions.items.next);

  const open = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onSuccess = () => {
    setSuccess(true);
  };

  return (
    <>
      <button className={'btn-form showRestartBtn'} onClick={open}>
        Restart client Subscription
      </button>
      {visible && (
        <Modal
          visible={visible}
          onOk={handleOk}
          closable={true}
          onCancel={handleCancel}
          centered
          maskTransitionName="none"
          footer={null}
          className="restart-client-modal"
          closeIcon={<CloseIcon />}
        >
          {!success ? (
            <Spin spinning={loader}>
              <ModalContainer item={item} onSuccess={onSuccess} />
            </Spin>
          ) : (
            <Spin spinning={loader}>
              <SuccessModalContainer item={item} id={id} handleCancel={handleCancel} group={group} session={session} />
            </Spin>
          )}
        </Modal>
      )}
    </>
  );
};

export default RestartSubscription;

const ModalContainer = ({ item, onSuccess }: any) => {
  const [groupID, setGroupID] = useState(null);

  const dispatch = useDispatch();

  const { reset, control } = useForm();

  const options: any = useSelector((state: stateType) => state.options.specificGroup);

  useEffect(() => {
    dispatch(clientsAction.getFilterOptions());
  }, []);

  const onChange = (value: any) => {
    if (value) {
      return setGroupID(value);
    }
    return setGroupID(null);
  };

  const restartSubscriptionCallback = (id: any, item: any) => {
    if (id != item?.medicalGroup?.id) {
      item.medicalGroup || item.preferableGroupInfo
        ? dispatch(
            clientAction.change({
              customerId: item.id,
              groupId: id,
            }),
          )
        : dispatch(
            groupsAction.addClient({
              customerId: item.id,
              groupId: id,
            }),
          );
    }
  };

  const restartSubscription = () => {
    const id = groupID ? groupID : item.medicalGroup.id;

    onChange(id);
    onSuccess();

    dispatch(groupsAction.id(id));
    dispatch(sessionsAction.get(id));

    dispatch(clientAction.restartSubscription(item.id, () => restartSubscriptionCallback(id, item)));
  };

  const onClearSelect = () => {
    reset();
  };

  return (
    <div className="restart-client-form">
      <div className="restart-client-form__container">
        <div className="restart-client-form__client-box">
          <ClientBox item={item} />
        </div>
        <div className="restart-client-form__select-group">
          <p className="restart-client-form__title">Which group do you want to add {item.firstname} to?</p>
          <InputField name={'specificGroup'} errors={undefined} icon={false}>
            <Controller
              control={control}
              name={'specificGroup'}
              render={({ field: { onBlur, value } }) => (
                <Select
                  className={'form-select'}
                  dropdownClassName="form-dropdown"
                  placeholder={'Select Group'}
                  style={{ width: '100%' }}
                  onChange={(value: any) => {
                    onChange(value);
                  }}
                  onBlur={onBlur}
                  options={options}
                  allowClear
                  onClear={onClearSelect}
                  defaultValue={item?.medicalGroup?.id ? item.medicalGroup.id : undefined}
                  onDropdownVisibleChange={(open) => !open && onBlur()}
                  getPopupContainer={(trigger) => trigger.parentNode}
                />
              )}
            />
          </InputField>
        </div>
        <div className="restart-client-form__confirm-button">
          <button className="btn-form" onClick={restartSubscription}>
            Confirm Group
          </button>
        </div>
      </div>
    </div>
  );
};

const SuccessModalContainer = ({ item, handleCancel, session, group }: any) => {
  const dispatch = useDispatch();

  const groupDate = group.group?.date.dayOfWeek;
  const groupEnd = group.group?.date.end;
  const groupStart = group.group?.date.start;
  const nextSession = session[0]?.start;

  useEffect(() => {
    return () => {
      dispatch(clientAction.get(item.id));
    };
  }, []);

  return (
    <div className="success-change-group-container">
      <div className="success-change-group-container__title-img">
        <img src={IconDone} className="success-change-group-container__img" alt="" />
        <p className="success-change-group-container__title">
          You’ve successfully added {item.firstname} to the following Group!
        </p>
      </div>
      <div className="success-change-group-container__group-card-container success-restart-client-container__group-card">
        <div className="different-container__label success-change-group-container__label">
          <Icon component={IconCalendar} />
          Every {`${week_days[groupDate - 1]}`}
        </div>
        <div className="small-group-card">
          <div className="small-group-card__avatar">
            {group.group?.avatar ? <img src={group.group?.avatar.url} alt="avatar" /> : <div />}
          </div>
          <div className="small-group-card__content">
            <div className="small-group-card__title">{`${group.group?.name} Group`}</div>
            <div className="small-group-card__info">
              <div className="small-group-card__when">
                <span>When:</span> {`${week_days[groupDate - 1]}s`} at{` `}
                {timeConvertor(groupStart)}
                {' - '}
                {timeConvertor(groupEnd, 'type-time')} ET
              </div>
              {nextSession && (
                <div className="small-group-card__when small-group-card__next-session">
                  <span>Next Session:</span> {` `}
                  <Moment format="MMMM Do, yyyy">{nextSession}</Moment>
                </div>
              )}
              <div className="small-group-card__when small-group-card__therapist-item">
                <span>Therapist:</span>&nbsp;
                <span className="small-group-card__therapist-name">{`${
                  group.group?.therapist_title?.name ? `${group.group?.therapist_title?.name}.` : ''
                }${group.group?.therapist_name}`}</span>
              </div>
              {nextSession && (
                <div className="small-group-card__begins-in">
                  <BeginsIn start={nextSession} status={item.status} isAddBackground />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="success-change-group-container__button">
        <button className="btn-form" onClick={handleCancel}>
          Done
        </button>
      </div>
    </div>
  );
};
