import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Spin } from 'antd';
// actions
import { authActions } from '../../../redux/actions/auth';
import restartAction from '../../../redux/actions/restart_subscriptions';
// types
import { stateType } from '../../../types/types';
// components
import { SupportStateType, WaitingFlowPreviewText } from '../../atoms/texts/consultation/form-text';

export const WaitingFlow: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const loader: boolean = useSelector((state: stateType) => state.auth.loader);
  const { restartSubscriptionFlow: isRestart } = useSelector((state: stateType) => state.user.profile);
  const supportStates: Array<SupportStateType> = useSelector((state: stateType) => state.app.supportStates);

  const redirect_url = localStorage.getItem('role') ? `/login-${localStorage.getItem('role')}` : '/login-customer';

  const onClickDone = useCallback(() => {
    const data = {
      refreshToken: localStorage.getItem('refresh_token'),
    };
    dispatch(
      authActions.logout(data, () => {
        localStorage.removeItem('token');
        localStorage.removeItem('refresh_token');
        history.push(redirect_url);
      }),
    );
  }, []);

  const action = () => {
    if (isRestart) {
      dispatch(restartAction.goBack());
    } else {
      dispatch(authActions.back('go-registered'));
    }
  };

  return (
    <div className="preview-text preview-text__wait-container">
      <Spin spinning={loader}>
        <WaitingFlowPreviewText
          supportStates={supportStates}
          onClickDone={onClickDone}
          action={action}
          loader={loader}
        />
      </Spin>
    </div>
  );
};
