import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Modal, Spin } from 'antd';
import cn from 'classnames';
// componets
import UpdateForm from '../../stripe/form/update';
import { GetTokenContainer } from '../editAccount';
// types
import { stateType } from 'types/types';
// styles
import './style.scss';
// icons
import { ReactComponent as CloseIcon } from 'assets/image/account/close4.svg';

interface IEditCardModal {
  visible: boolean;
  setVisible: (type: boolean) => void;
}

const EditCardModal: React.FC<IEditCardModal> = ({ visible, setVisible }: IEditCardModal) => {
  const loader = useSelector((state: stateType) => state.stripe.loader, shallowEqual);
  const loaderModal = useSelector((state: stateType) => state.account.loaderModal, shallowEqual);
  const varibleToken = useSelector((state: stateType) => state.account.varibleToken, shallowEqual);
  const showMinimal = useSelector((state: any) => state.main_menu.showMinimal, shallowEqual);

  return (
    <>
      <Modal
        visible={visible}
        closable={true}
        onCancel={() => setVisible(false)}
        centered
        footer={null}
        closeIcon={<CloseIcon />}
        className={cn('edit-card-modal', {
          'edit-card-modal__minimal': showMinimal,
        })}
      >
        <Spin spinning={loader || loaderModal}>
          <GetTokenContainer setVisible={setVisible} show={!loaderModal && varibleToken} />
          {!loaderModal && !varibleToken && <ModalContainer setVisible={setVisible} />}
        </Spin>
      </Modal>
    </>
  );
};

export default EditCardModal;

const ModalContainer = ({ setVisible }: any) => {
  return (
    <div className="edit-card-container">
      <div className="edit-card-container__title">Update payment details</div>
      <div className="edit-card-container__desc">To change your card on file, please do so below.</div>
      <UpdateForm setVisible={setVisible} />
    </div>
  );
};
