/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { AxiosResponse } from 'axios';
// config
import http from '../instance';

export const api = {
  get: async (role: string, id: any, params: any): Promise<AxiosResponse> => {
    const api = await http();
    return api.get(`${role}/group/${id}/history${params ? `?${params}` : ''}`);
  },

  getHistory: async (id: string, role: string, params: any): Promise<AxiosResponse> => {
    const api = await http();
    return api.get(`${role}/customer/${id}/group-histories${params ? `?${params}` : ''}`);
  },

  getSessions: async (id: string, role: string, params: any): Promise<AxiosResponse> => {
    const api = await http();
    return api.get(`${role}/customer/group-history/${id}/sessions${params ? `?${params}` : ''}`);
  },
};
