const eventAnalytics: any = {
  analytics(route: string, data: any): any {
    return {
      type: 'ANALYTICS',
      payload: {
        route,
        data,
      },
    };
  },
  resetPassword() {
    return {
      type: 'RESET_PASSWORD',
    };
  },
};

export default eventAnalytics;
