export type ActionCallback = () => void;
export type ActionIdCallback<T> = (id: T) => void;

export type ObjectType = Record<string, any>;

// Enums

export enum GroupActionStatus {
  Before = 'before',
  After = 'after',
  Empty = '',
}

export enum FlowType {
  StartGroup = 'start-group',
  GrouportRecommendation = 'grouport-recommendation',
  TherapistGrouportRecommendation = 'therapist-grouport-recommendation',
}

export enum SearchGroupQuery {
  Change = 'change-group',
  Cancel = 'cancel-subscription',
  Restart = 'restart-subscription',
}

export enum FilterOptionsRole {
  Admin = 'admin',
  Therapist = 'therapist',
  Empty = '',
}

export enum CopyLinkRole {
  Customer = 'customer',
  Therapist = 'therapist',
}

// Generics

export type GetByIdAction = {
  type: string;
  id?: number | string;
  callback?: ActionCallback;
};

export type SessionStartAction = {
  type: string;
  id?: number | string;
  paramsId?: string;
  callback?: ActionCallback;
};

export type GetDataAction<DataType = any, ActionType = string> = {
  type: ActionType;
  data?: DataType;
  callback?: ActionCallback;
};

export type LoaderAction<Type = string> = {
  type: Type;
  payload: {
    loader: boolean;
  };
};

export type LoaderModalAction<Type = string> = {
  type: Type;
  payload: {
    loaderModal: boolean;
  };
};

export type LoaderEventsAction<Type = string> = {
  type: Type;
  payload: {
    loaderEvents: boolean;
  };
};

export type CallbackAction<Type> = {
  type: Type;
  callback?: ActionCallback;
};

export type PayloadAction<ActionType = string, PayloadType = any> = {
  type: ActionType;
  payload: PayloadType;
};

export type DataIdCallbackAction<Type = string, DataType = any, IdType = any> = {
  type: Type;
  id: IdType;
  data?: DataType;
  callback?: ActionCallback;
};

export type IdCallbackAction<Type = string, IdType = any> = {
  type: Type;
  id: IdType;
  callback?: ActionCallback;
};

export type DataCallbackIdAction<Type = string, DataType = any, IdType = any> = {
  type: Type;
  data?: DataType;
  callback?: ActionIdCallback<IdType>;
};

export type NumberId = {
  id: number;
};

export interface IErrors {
  message: string;
  propertyPath: string;
}

export type errorsCallback = (errors: Array<IErrors>) => void;
