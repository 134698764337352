/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { AxiosResponse } from 'axios';
// config
import http from '../instance';
// types
import { loginByKeyDataType } from '../../types/actions/auth-actions-types';

interface LoginDataType {
  name: string;
  password: string;
}

export const apiAuth = {
  login: async (data: LoginDataType, role: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.post(`${role}/login`, data);
  },

  checkEmail: async (data: any) => {
    const api = await http();
    return api.post(`/customer/is-exists`, {
      email: data?.email,
    });
  },

  openLoginLead: async (data: any) => {
    const api = await http();
    return api.post(`customer/open-login-lead`, {
      email: data?.email,
      reset: true,
      password: data.password,
      firstName: data.firstname,
      lastName: data.lastname,
    });
  },

  registration: async (data: LoginDataType): Promise<AxiosResponse> => {
    const api = await http();
    return api.post(`customer/registration`, data);
  },

  forgotPassword: async (data: LoginDataType): Promise<AxiosResponse> => {
    const api = await http();
    return api.post(`forgot-password`, data);
  },

  newPassword: async (data: LoginDataType): Promise<AxiosResponse> => {
    const api = await http();
    return api.post(`forgot-password/set`, data);
  },

  newPasswordTherapist: async (data: LoginDataType): Promise<AxiosResponse> => {
    const api = await http();
    return api.patch(`therapist/password-set`, data);
  },

  logout: async (data: any): Promise<AxiosResponse> => {
    const api = await http();
    return api.post(`logout`, data);
  },

  refresh: async (): Promise<AxiosResponse> => {
    const api = await http();
    return api.post(`refresh-token`, { refreshToken: localStorage.getItem('refresh_token') });
  },

  back: async (type: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.patch(`customer/flow/${type}`);
  },

  getKey: async (id: any): Promise<AxiosResponse> => {
    const api = await http();
    return api.get(`admin/${id}/ghost-login-start`);
  },

  loginByKey: async (id: string | number, data: loginByKeyDataType): Promise<AxiosResponse> => {
    const api = await http();
    return api.post(`${id}/ghost-login`, data);
  },
};
