import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
// components
import MainTemplate from '../../../components/templates/mainTemplate';
import EventNavigationContainer from '../../../components/containers/navigationContainer/event';
import { HeaderPageContainer } from '../../../components/containers/pageContainer';
import AddEvent from '../../../components/organisms/modals/addEvent';
import TableContainer from '../../../components/containers/tableContainer';
import EventsTable from '../../../components/organisms/tables/admin/events';
// actions
import appAction from '../../../redux/actions/app';
import upcomingAction from '../../../redux/actions/upcoming_events';

const AdminEventsPage: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(appAction.page('Community Events'));
    dispatch(upcomingAction.getLengthsFilters());
    dispatch(upcomingAction.getTypesFilters());
    dispatch(upcomingAction.getTopicsFilters());
    dispatch(upcomingAction.getOrdersFilters());
    dispatch(upcomingAction.getEventsAndPosts());
  }, []);
  return (
    <MainTemplate>
      <HeaderPageContainer>
        <h2 className="text-page-header">Community Events</h2>
        <AddEvent />
      </HeaderPageContainer>
      <EventNavigationContainer />
      <TableContainer>
        <EventsTable />
      </TableContainer>
    </MainTemplate>
  );
};

export default AdminEventsPage;
