import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import ChatIcon from '../../../../icons/ChatIcon';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import useChatContext from '../../../../hooks/useChatContext/useChatContext';
import useVideoContext from '../../../../hooks/useVideoContext/useVideoContext';
import useFilesContext from '../../../../hooks/useFilesContext/useFilesContext';
import useParticipantMenuContext from '../../../../hooks/useParticipantMenuContext/useParticipantMenuContext';

export const ANIMATION_DURATION = 700;

const useStyles = makeStyles({
  iconContainer: {
    position: 'relative',
    display: 'flex',
  },
  circle: {
    width: '20px',
    height: '20px',
    backgroundColor: '#E19D89',
    borderRadius: '50%',
    position: 'absolute',
    top: '-8px',
    left: '8px',
    opacity: 0,
    transition: `opacity ${ANIMATION_DURATION * 0.5}ms ease-in`,
  },
  hasUnreadMessages: {
    opacity: 1,
  },
  ring: {
    border: '3px solid #E19D89',
    borderRadius: '30px',
    height: '14px',
    width: '14px',
    position: 'absolute',
    left: '11px',
    top: '-5px',
    opacity: 0,
  },
  animateRing: {
    animation: `$expand ${ANIMATION_DURATION}ms ease-out`,
    animationIterationCount: 1,
  },
  '@keyframes expand': {
    '0%': {
      transform: 'scale(0.1, 0.1)',
      opacity: 0,
    },
    '50%': {
      opacity: 1,
    },
    '100%': {
      transform: 'scale(1.4, 1.4)',
      opacity: 0,
    },
  },
});

export default function ToggleChatButton(props: { className?: string }) {
  const classes = useStyles();
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const {
    isChatWindowOpen,
    setIsChatWindowOpen,
    conversation,
    hasUnreadMessages,
    tabHasUnreadMessages,
    setActiveChatTab,
  } = useChatContext();
  const { setIsBackgroundSelectionOpen } = useVideoContext();
  const { setIsFileWindowOpen } = useFilesContext();
  const { setIsParticipantMenuOpen } = useParticipantMenuContext();

  const toggleChatWindow = () => {
    if (hasUnreadMessages && tabHasUnreadMessages.length) {
      setActiveChatTab(tabHasUnreadMessages[0]);
      setIsChatWindowOpen(true);
    } else {
      setIsChatWindowOpen(!isChatWindowOpen);
    }
    setIsBackgroundSelectionOpen(false);
    setIsFileWindowOpen(false);
    setIsParticipantMenuOpen(false);
  };

  useEffect(() => {
    if (shouldAnimate) {
      setTimeout(() => setShouldAnimate(false), ANIMATION_DURATION);
    }
  }, [shouldAnimate]);

  useEffect(() => {
    if (conversation && !isChatWindowOpen) {
      const handleNewMessage = () => setShouldAnimate(true);
      conversation.on('messageAdded', handleNewMessage);
      return () => {
        conversation.off('messageAdded', handleNewMessage);
      };
    }
  }, [conversation, isChatWindowOpen]);

  return (
    <Button data-cy-chat-button onClick={toggleChatWindow} disabled={!conversation} className={props.className}>
      <ChatIcon />
      <div className={clsx(classes.ring, { [classes.animateRing]: shouldAnimate })} />
      <div className={clsx(classes.circle, { [classes.hasUnreadMessages]: hasUnreadMessages })} />
    </Button>
  );
}
