import React, { Dispatch, FC } from 'react';
import { useHistory, useParams, RouteComponentProps } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
// components
import LoadingPage from '../../components/app/loading';
// types
import { stateType } from '../../types/types';
// actions
import notificationClientAction from '../../redux/actions/notificationClient';
import userActions from '../../redux/actions/user';

interface UpdateTimeInterface {
  action: string;
  history: RouteComponentProps['history'];
  id: string;
  dispatch: Dispatch<any>;
  profile: any;
  type: 'group' | 'sessions';
}

const EmailsLinkHandler: FC = () => {
  const profile: any = useSelector((state: stateType) => state.user.profile, shallowEqual);

  const params = useParams<{ action: any; type: any; id?: any }>();
  const history = useHistory();
  const dispatch = useDispatch();

  const { action, type, id } = params;

  const emailsHandler = (type: any) => {
    switch (type) {
      case 'update-group-time':
        return (
          <UpdateTime action={action} history={history} id={id} dispatch={dispatch} profile={profile} type="group" />
        );
      case 'update-session-time':
        return (
          <UpdateTime action={action} history={history} id={id} dispatch={dispatch} profile={profile} type="sessions" />
        );
      default:
        history.push('/');
        return <></>;
    }
  };

  return emailsHandler(type);
};

export default EmailsLinkHandler;

const UpdateTime: FC<UpdateTimeInterface> = ({ action, history, id, dispatch, profile, type }: UpdateTimeInterface) => {
  if (type === 'group') {
    switch (action) {
      case '0':
        dispatch(notificationClientAction.readNotification(id));
        dispatch(userActions.rewrite({ ...profile, notification: false }));
        history.push('/profile/change-group');

        break;
      case '1':
        dispatch(notificationClientAction.readNotification(id));
        dispatch(userActions.rewrite({ ...profile, notification: false }));
        history.push('/');

        break;
      default:
        history.push('/');
    }
  } else if (type === 'sessions') {
    switch (action) {
      case '0':
        dispatch(notificationClientAction.readNotification(id, false));
        dispatch(userActions.rewrite({ ...profile, notification: false }));
        history.push('/');

        break;
      case '1':
        dispatch(notificationClientAction.readNotification(id));
        dispatch(userActions.rewrite({ ...profile, notification: false }));
        history.push('/');

        break;
      default:
        history.push('/');
    }
  }

  return <LoadingPage />;
};
