import * as yup from 'yup';
import moment from 'moment';

const CHOOSE_OPTION = 'Choose at least one option';

const getDate = (yearSubtract: number) => moment().subtract(yearSubtract, 'years').format('YYYY-MM-DD');

export const consultationSchema = yup.object().shape(
  {
    reasons: yup.array().of(yup.number()).min(1, CHOOSE_OPTION).required(CHOOSE_OPTION),
    topics: yup.number().required(CHOOSE_OPTION),
    statuses: yup.number().when(['reasons', 'topics'], {
      is: (reasons: Array<number> | undefined, topics: number) =>
        reasons?.some((reason) => reason === 11) || topics === 7,
      then: yup.number().required(CHOOSE_OPTION),
    }),
    date: yup
      .date()
      .max(new Date(getDate(18)), 'We can only serve people who are 18+')
      .min(new Date(getDate(100)), 'Input correct date')
      .typeError('Invalid birth format (MM/DD/YYYY)')
      .required('Enter your date of birth'),
    genders: yup.number().required(CHOOSE_OPTION),
    countries: yup.number().when('state', {
      is: (exist: number) => !!exist,
      then: yup.number(),
      otherwise: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required(CHOOSE_OPTION),
    }),
    state: yup.number().when('countries', {
      is: (exist: number) => !!exist,
      then: yup.number(),
      otherwise: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required(CHOOSE_OPTION),
    }),
    phone: yup
      .string()
      .matches(/[0-9]{3}\s?[0-9]{3}\s?[0-9]{4}/, 'Please add a valid phone number')
      .required('Enter your phone'),
  },
  [
    ['countries', 'state'],
    ['reasons', 'topics'],
  ],
);

export const restartSubscriptionSchema = yup.object().shape({
  reasons: yup.array().of(yup.number()).min(1, CHOOSE_OPTION).required(CHOOSE_OPTION),
  topics: yup.number().required(CHOOSE_OPTION),
  date: yup
    .date()
    .max(new Date(getDate(18)), 'We can only serve people who are 18+')
    .min(new Date(getDate(100)), 'Input correct date')
    .typeError('Invalid birth format (MM/DD/YYYY)')
    .required('Enter your date of birth'),
  genders: yup.number().required(CHOOSE_OPTION),
  state: yup.number().required(CHOOSE_OPTION),
});

export const registrationSchema = yup.object().shape({
  firstname: yup.string().min(1, 'First name must have at least 1 characters').required('Enter your first name'),
  lastname: yup.string().min(1, 'Last name must have at least 1 characters').required('Enter your lastname name'),
  email: yup
    .string()
    .matches(/^\s*[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\s*$/i, 'Enter a valid e-mail address')
    .required('Enter your e-mail'),
  password: yup.string().min(6, 'Password must have at least 6 characters').required('Enter your password'),
  password_repeat: yup
    .string()
    .oneOf([yup.ref('password'), null], 'The passwords do not match')
    .required('Repeat your password'),
  checker: yup.boolean().oneOf([true], 'Required').required('Required'),
});

export const rescheduleSessionSchema = yup.object().shape(
  {
    date: yup.date().required('choose option'),
    end: yup
      .date()
      .when(['start', 'date'], (start?: any, date?: any, schema?: any) => {
        return start && moment(start).isValid()
          ? schema
              .test({
                name: 'min',
                exclusive: false,
                params: {},
                message: "End time can't be before Start time",
                test: function (value: any) {
                  const isMidnight = moment(value).format('HH:mm') === '00:00';

                  return isMidnight
                    ? !moment(value).add(1, 'day').isBefore(moment(start))
                    : !moment(value).isBefore(moment(start));
                },
              })
              .test({
                name: 'same',
                exclusive: false,
                params: {},
                message: 'End time must be grater than start time',
                test: function (value: any) {
                  return !moment(value).isSame(moment(start));
                },
              })
              .test({
                name: 'before',
                exclusive: false,
                params: {},
                message: 'The time has passed',
                test: (value: any) => {
                  const isToday = moment(date).isSame(moment(), 'day');
                  const isMidnight = moment(value).format('HH:mm') === '00:00';

                  return isToday
                    ? isMidnight
                      ? moment().isBefore(moment(value).add(1, 'day'), 'minutes')
                      : moment().isBefore(moment(value), 'minutes')
                    : !isToday;
                },
              })
              .required('choose option')
          : schema.required('choose option');
      })
      .nullable()
      .typeError('Invalid Date'),
    start: yup
      .date()
      .when(['end', 'date'], (end?: any, date?: any, schema?: any) => {
        return end && moment(end).isValid()
          ? schema
              .test({
                name: 'max',
                exclusive: false,
                params: {},
                message: "Start time can't be after End time",
                test: function (value: any) {
                  const isMidnight = moment(end).format('HH:mm') === '00:00';

                  return isMidnight
                    ? !moment(value).isAfter(moment(end).add(1, 'day'))
                    : !moment(value).isAfter(moment(end));
                },
              })
              .test({
                name: 'same',
                exclusive: false,
                params: {},
                message: 'End time must be grater than start time',
                test: function (value: any) {
                  return !moment(value).isSame(moment(end));
                },
              })
              .test({
                name: 'before',
                exclusive: false,
                params: {},
                message: 'The time has passed',
                test: (value: any) => {
                  const isToday = moment(date).isSame(moment(), 'day');

                  return isToday ? moment().isBefore(moment(value), 'minutes') : !isToday;
                },
              })
              .required('choose option')
          : schema.required('choose option');
      })
      .nullable()
      .typeError('Invalid Date'),
  },
  [['start', 'end']],
);

export const rescheduleGroupSchema = yup.object().shape(
  {
    dayOfWeek: yup.number().required('choose option'),
    end: yup
      .date()
      .when(['start'], (start?: any, schema?: any) => {
        return start && moment(start).isValid()
          ? schema
              .test({
                name: 'min',
                exclusive: false,
                params: {},
                message: "End time can't be before Start time",
                test: function (value: any) {
                  const isMidnight = moment(value).format('HH') === '00';

                  return isMidnight
                    ? !moment(value).add(1, 'day').isBefore(moment(start))
                    : !moment(value).isBefore(moment(start));
                },
              })
              .test({
                name: 'same',
                exclusive: false,
                params: {},
                message: 'End time must be grater than start time',
                test: function (value: any) {
                  return !moment(value).isSame(moment(start));
                },
              })
              .required('choose option')
          : schema.required('choose option');
      })
      .nullable()
      .typeError('Invalid Date'),
    start: yup
      .date()
      .when(['end'], (end?: any, schema?: any) => {
        return end && moment(end).isValid()
          ? schema
              .test({
                name: 'max',
                exclusive: false,
                params: {},
                message: "Start time can't be after End time",
                test: function (value: any) {
                  const isMidnight = moment(end).format('HH') === '00';

                  return isMidnight
                    ? !moment(value).isAfter(moment(end).add(1, 'day'))
                    : !moment(value).isAfter(moment(end));
                },
              })
              .test({
                name: 'same',
                exclusive: false,
                params: {},
                message: 'End time must be grater than start time',
                test: function (value: any) {
                  return !moment(value).isSame(moment(end));
                },
              })
              .required('choose option')
          : schema.required('choose option');
      })
      .nullable()
      .typeError('Invalid Date'),
  },
  [['start', 'end']],
);

export const addGroupSchema = yup.object().shape(
  {
    groupTopicId: yup.number().required('choose option'),
    therapistId: yup.number().required('choose option'),
    dayOfWeek: yup.number().required('choose option'),
    end: yup
      .date()
      .when(['start'], (start?: any, schema?: any) => {
        return start && moment(start).isValid()
          ? schema
              .test({
                name: 'min',
                exclusive: false,
                params: {},
                message: "End time can't be before Start time",
                test: function (value: any) {
                  const isMidnight = moment(value).format('HH') === '00';

                  return isMidnight
                    ? !moment(value).add(1, 'day').isBefore(moment(start))
                    : !moment(value).isBefore(moment(start));
                },
              })
              .test({
                name: 'same',
                exclusive: false,
                params: {},
                message: 'End time must be grater than start time',
                test: function (value: any) {
                  return !moment(value).isSame(moment(start));
                },
              })
              .required('choose option')
          : schema.required('choose option');
      })
      .nullable()
      .typeError('Invalid Date'),
    start: yup
      .date()
      .when(['end'], (end?: any, schema?: any) => {
        return end && moment(end).isValid()
          ? schema
              .test({
                name: 'max',
                exclusive: false,
                params: {},
                message: "Start time can't be after End time",
                test: function (value: any) {
                  const isMidnight = moment(end).format('HH') === '00';

                  return isMidnight
                    ? !moment(value).isAfter(moment(end).add(1, 'day'))
                    : !moment(value).isAfter(moment(end));
                },
              })
              .test({
                name: 'same',
                exclusive: false,
                params: {},
                message: 'End time must be grater than start time',
                test: function (value: any) {
                  return !moment(value).isSame(moment(end));
                },
              })
              .required('choose option')
          : schema.required('choose option');
      })
      .nullable()
      .typeError('Invalid Date'),
  },
  [['start', 'end']],
);

export const nameSchema = yup.string().matches(/^([a-zA-Z'`-]+\s?){1,5}$/, 'Input a correct card name');
