import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ProgressQuestions from '../../../components/organisms/modals/progress';
// components

import ProgressTemplate from '../../../components/templates/progressTemplate';

// actions
import appAction from '../../../redux/actions/app';

const ProgressQuestionsPage: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(appAction.page('Progress'));
  }, []);
  return (
    <ProgressTemplate>
      <ProgressQuestions />
    </ProgressTemplate>
  );
};

export default ProgressQuestionsPage;
