import React, { useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Modal, Spin } from 'antd';
import { useForm } from 'react-hook-form';
// componets
import EditTherapistNamesContainer from 'components/molecules/modals/edit/editTherapistNames';
import { LastName, NPINumber, RepeatLastName } from 'components/molecules/inputsBlocks/edit';
// types
import { stateType } from '../../../../../../types/types';
// icons
import { ReactComponent as CloseIcon } from 'assets/image/account/close4.svg';
// styles
import '../style.scss';
// types
import { FormDataEditTherapist } from '../../../../../../types/edit';
import EditTherapistContainer from 'components/molecules/modals/edit/editTherapist';

const EditTherapistNPINumber: React.FC<any> = ({ btnClass, icon, name }: any) => {
  const [visible, setVisible] = useState(false);

  const loader = useSelector((state: stateType) => state.therapists.loaderModal, shallowEqual);

  const open = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <button className={btnClass} onClick={open}>
        {icon}
        <span>{name}</span>
      </button>
      {visible && (
        <Modal
          visible={visible}
          onOk={handleOk}
          closable={true}
          onCancel={handleCancel}
          centered
          maskTransitionName="none"
          footer={null}
          className="edit-group-modal"
          closeIcon={<CloseIcon />}
        >
          <Spin spinning={loader}>
            <ModalContainer handleCancel={handleCancel} />
          </Spin>
        </Modal>
      )}
    </>
  );
};

export default EditTherapistNPINumber;

const ModalContainer = ({ handleCancel }: any) => {
  const {
    handleSubmit,
    setValue,
    formState: { errors },
    register,
    watch,
  } = useForm<FormDataEditTherapist>();

  return (
    <EditTherapistContainer
      titleName="Change Therapist NPI Number"
      undertitle="To change therapist NPI Number please enter the appropriate info below"
      buttonName="Save"
      handleCancel={handleCancel}
      setValue={setValue}
      handleSubmit={handleSubmit}
      isCancel
    >
      <NPINumber errors={errors.npiNumber?.message} register={register} />
    </EditTherapistContainer>
  );
};
