import React, { FC, ReactNode } from 'react';
// components
import StepProgress from 'components/atoms/progress';
//types
import { IQuestion } from 'types/app-types';
// icons
import Icon from '@ant-design/icons';
import { ReactComponent as IconBack } from 'assets/image/questions/back.svg';
// styles
import './style.scss';

interface IQuestionProps {
  showBack?: boolean;
  onBack: () => void;
  question: IQuestion;
  renderController: () => ReactNode;
  onNext: () => void;
  nextDisabled: boolean;
  closeBtn?: ReactNode;
  children?: ReactNode;
  apply?: boolean;
  totalStepCount?: number;
  subQuestion?: ReactNode | boolean;
  answerText?: string;
  firstStep?: boolean;
}

const QuestionCard: FC<IQuestionProps> = (props: IQuestionProps) => {
  const {
    showBack = true,
    onBack,
    question,
    renderController,
    onNext,
    nextDisabled,
    closeBtn,
    children,
    apply,
    totalStepCount = 1,
    subQuestion,
    answerText,
    firstStep,
  } = props;

  console.log(question);

  return (
    <div className="questions">
      <div className="questions__header">
        <div className="questions__header-top">
          {showBack && (
            <div className="questions__back" onClick={onBack}>
              <Icon component={IconBack} />
            </div>
          )}
          <div className="questions__progress">
            <StepProgress step={question.serialNumber} stepNumber={question.number} totalStepCount={totalStepCount} />
          </div>
          <div className="questions__close-btn">{closeBtn}</div>
        </div>
        <div className="questions__question-body">
          {children || (
            <>
              {subQuestion && <div className="questions__top-question">{subQuestion}</div>}
              <div className="questions__question bold">{question.question}</div>
              {apply && <div className="questions__apply">(Check all that apply)</div>}
            </>
          )}
        </div>
      </div>
      {question.number === 6 && question.answerType === 'multi_with_arbitrary' ? null : (
        <div className="questions__body">{renderController()}</div>
      )}
      <div className="questions__footer">
        <button className="btn-form" onClick={onNext} disabled={(!answerText || firstStep) && nextDisabled}>
          Next
        </button>
      </div>
    </div>
  );
};

export default QuestionCard;
