/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { AxiosResponse } from 'axios';
// config
import http from '../instance';

export const apiAdminEvents = {
  get: async (params: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.get(`/${params ? `?${params}` : ''}`);
  },
  getByID: async (id: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.get(`/admin/community-event/${id}}/details`);
  },
  add: async (data: any): Promise<AxiosResponse> => {
    const api = await http();
    return api.post(`/admin/community-event`, data);
  },
  edit: async (id: any, data: any): Promise<AxiosResponse> => {
    const api = await http();
    return api.patch(`/admin/community-event/${id}/details`, data);
  },
  deleteTopic: async (id: any, paramsId: any): Promise<AxiosResponse> => {
    const api = await http();
    return api.delete(`/${id}/${paramsId}`);
  },
  search: async (value: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.get(`admin/community-event-search-clients?name=${value}&page=1&per_page=50`);
  },
  addClient: async (eventId: string, customerId: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.patch(`admin/community-event/${eventId}/add/client/${customerId}`);
  },
  removeClient: async (eventId: string, customerId: string): Promise<AxiosResponse> => {
    const api = await http();
    return api.patch(`admin/community-event/${eventId}/remove/client/${customerId}`);
  },
  removeEventById: async (id: any): Promise<AxiosResponse> => {
    const api = await http();
    return api.patch(`admin/community-event/${id}/remove`);
  },
};
