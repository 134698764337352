import React, { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
// components
import LoadingPage from '../../components/app/loading';
// actions
import { authActions } from '../../redux/actions/auth';

const LoginByKey: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const redirectToHome = () => history.push('/');

  useEffect(() => {
    const role = String(queryString.parse(history.location.search).role);
    const id = String(queryString.parse(history.location.search).id);
    const key = String(queryString.parse(history.location.search).key);

    localStorage.setItem('role', role);

    dispatch(authActions.loginByKey(id, { key }, redirectToHome));
  }, []);

  return <LoadingPage />;
};

export default LoginByKey;
