import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Modal, Spin } from 'antd';
import Moment from 'react-moment';
import _ from 'lodash';
import { useWindowResize } from 'beautiful-react-hooks';

// componets
// actions
import assesmentsAction from '../../../../redux/actions/assesments';
// types
import { stateType } from '../../../../types/types';
// styles
import './style.scss';
import { ReactComponent as CloseIcon } from '../../../../assets/image/account/close4.svg';

const ShowAssesment: React.FC<any> = ({ id }: any) => {
  const [visible, setVisible] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    visible && dispatch(assesmentsAction.getByID(id));
  }, [visible]);

  const clinet = useSelector((state: stateType) => state.clients.item, shallowEqual);
  const item = useSelector((state: stateType) => state.assesments.item, shallowEqual);
  const loader = useSelector((state: stateType) => state.assesments.loaderModal, shallowEqual);

  const open = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <div className="assesment-item__link" onClick={open}>
        See More
      </div>
      {visible && (
        <Modal
          visible={visible}
          onOk={handleOk}
          closable={true}
          onCancel={handleCancel}
          centered
          maskTransitionName="none"
          footer={null}
          className="assesment-modal"
          closeIcon={<CloseIcon />}
        >
          <Spin spinning={loader}>
            {!loader && !_.isEmpty(item) && <ModalContainer setVisible={setVisible} item={item} client={clinet} />}
          </Spin>
        </Modal>
      )}
    </>
  );
};

export default ShowAssesment;

const ModalContainer = ({ item, client }: any) => {
  const { createdAt, customerProgressAnswers, customerProgressScoreItems, ranking, scores, totalScore } = item;
  const [width, setWidth] = useState(window.innerWidth);

  useWindowResize(() => {
    setWidth(window.innerWidth);
  });

  return (
    <div className="assesment-container">
      <div className="assesment-container__header">
        <div className="assesment-container__avatar">
          {client?.avatar?.url ? (
            <img src="#" alt="avatar" className="assesment-container__avatar-img" />
          ) : (
            <div className="assesment-container__avatar-fake">
              {client.firstname[0].toUpperCase()}
              {client.lastname[0].toUpperCase()}
            </div>
          )}
        </div>
        <div>
          <div className="assesment-container__name">
            {client.firstname} {client.lastname}
          </div>
          <div className="assesment-container__date">Score as of {<Moment format="d/MM/YYYY">{createdAt}</Moment>}</div>
        </div>
      </div>
      <button className="btn-form">PHQ - ADS</button>
      {width > 768 && (
        <div className="assesment-container__item assesment-container__item_titles">
          <div className="assesment-container__li assesment-container__li_title">
            Over the last 2 weeks, how often have you noticed the following?
          </div>
          <div className="assesment-container__li">Not at all</div>
          <div className="assesment-container__li">Several days</div>
          <div className="assesment-container__li">More than half the days</div>
          <div className="assesment-container__li">Nearly every day</div>
        </div>
      )}
      <div className="assesment-container__body">
        {customerProgressScoreItems &&
          customerProgressScoreItems.map((item: any, index: number) => {
            return (
              <div className="assesment-container__item" key={item.id}>
                <div className="assesment-container__item_title">
                  <div className="assesment-container__li assesment-container__li_number">
                    <div>{index + 1}</div>
                    <div>{item.question.question}</div>
                  </div>
                </div>
                {customerProgressAnswers.map((el: any) => {
                  return (
                    <div key={el.id} className="assesment-container__score-li">
                      <span
                        className={
                          el.number === item.answer.number
                            ? 'assesment-container__li assesment-container__li-active'
                            : 'assesment-container__li'
                        }
                      >
                        {el.score}
                      </span>
                    </div>
                  );
                })}
              </div>
            );
          })}
      </div>
      <div className="assesment-container__footer">
        <div className="assesment-container__result">
          <div className="assesment-container__result-header">
            <div className="assesment-container__text assesment-container__result-header_title">Total Score </div>
            <div className="assesment-container__result-item">
              <div className="assesment-container__text assesment-container__result-item_score">{totalScore}</div>
              <div />
              <div className="assesment-container__text assesment-container__result-item_center">=</div>
            </div>
            <div className="assesment-container__result-item">
              <div />
              <div />
              <div className="assesment-container__text assesment-container__result-item_score">
                {scores[1] ? scores[1] : <>&mdash;</>}
              </div>
            </div>
            <div className="assesment-container__result-item">
              <div />
              <div className="assesment-container__text assesment-container__result-item_symbol">+</div>
              <div className="assesment-container__text assesment-container__result-item_score">
                {scores[2] ? scores[2] : <>&mdash;</>}
              </div>
            </div>
            <div className="assesment-container__result-item">
              <div />
              <div className="assesment-container__text assesment-container__result-item_symbol">+</div>
              <div className="assesment-container__text assesment-container__result-item_score">
                {scores[3] ? scores[3] : <>&mdash;</>}
              </div>
            </div>
          </div>
          <div className="assesment-container__result-rank">
            <div className="assesment-container__text assesment-container__result-header_title"> Score Ranking: </div>
            <div className={`assesment-container__text ${ranking.toLowerCase()}`}>{ranking}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
