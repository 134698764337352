import React from 'react';
// components
import SimpleHeader from 'components/organisms/headers/simple/simple-header';
import StripeBillingContainer from 'components/containers/stripeContainer/StripeBillingContainer';

const ChangeBillingPage: React.FC = () => {
  return (
    <>
      <SimpleHeader />
      <StripeBillingContainer />
    </>
  );
};

export default ChangeBillingPage;
