import React, { FC } from 'react';
import { notification } from 'antd';

export const ERROR_MESSAGE = 'Oooops, something went wrong';

interface ErrorProps {
  contact?: boolean;
}

const Error: FC<ErrorProps> = ({ contact }: ErrorProps) => (
  <>
    {ERROR_MESSAGE + (contact ? ', ' : '.')}
    {contact && <a href="/contact">contact support</a>}
  </>
);

const showError = (contact?: boolean, description?: string) => {
  notification.error({
    message: <Error contact={contact} />,
    description,
  });
};

export default showError;
