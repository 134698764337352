import React, { FC, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';
import _ from 'lodash';
// style
import './style.scss';
// types
import { stateType } from '../../../../types/types';
import { stateFlowType } from '../../../../redux/reducers/user';
// components
import SubmitButton from '../../../atoms/form/Button';
import { CancelButton } from '../../../atoms/form/CancelButton';
import { TimeGroupCard } from '../../cards/groupCard/SmallGroupCard';
// actions
import restartAction from '../../../../redux/actions/restart_subscriptions';

interface IMainContainer {
  title: string;
  item: any;
  submitName: string;
  cancelName: string;
  onCurrent: () => void;
  onNewGroup: () => void;
}

const RestartSubscriptionsQuestions: FC = () => {
  const info: any = useSelector((state: stateType) => state.account.info, shallowEqual);
  const loader: boolean = useSelector((state: stateType) => state.account.loader, shallowEqual);
  const loaderRestart: boolean = useSelector((state: stateType) => state.restartSubscriptions.loader, shallowEqual);
  const stateFlow: stateFlowType = useSelector((state: stateType) => state.user.state, shallowEqual);

  const dispatch = useDispatch();

  const isPreCancel = stateFlow === 'restart_pre_cancel';

  const choiceGroupCallback = () => {
    dispatch(restartAction.state(isPreCancel ? 6 : 4));
    dispatch(restartAction.choice(true));
  };

  const onCurrent = () => {
    dispatch(
      restartAction.join(info?.medicalGroup?.id || info?.medicalGroupHistory?.medicalGroup?.id, choiceGroupCallback),
    );
  };

  const onNewGroup = () => {
    dispatch(restartAction.state(2));
    dispatch(restartAction.choice(false));
  };

  useEffect(() => {
    if (!_.isEmpty(info) && !info?.medicalGroup && !info?.medicalGroupHistory?.medicalGroup) {
      dispatch(restartAction.state(2));
    }
  }, [info]);

  return (
    <>
      {!_.isEmpty(info) ? (
        <div className="restart-subscriptions-questions">
          <div className="restart-subscriptions-questions__container">
            <h1 className="restart-subscriptions-questions__title">Welcome Back!</h1>
            <Spin spinning={loader || loaderRestart}>
              <MainContainer
                title={
                  isPreCancel
                    ? 'Would You like to remain in your current group? '
                    : `Would You like to rejoin the group you were in previously?`
                }
                submitName={
                  isPreCancel ? 'Yes, I’d like to remain in the same group' : 'Yes, I’d like to rejoin the same group'
                }
                cancelName="No, I’d like to join a new group"
                item={info}
                onCurrent={onCurrent}
                onNewGroup={onNewGroup}
              />
            </Spin>
          </div>
          <p className="restart-subscriptions-questions__footer">©2022 Grouport Inc. All rights reserved.</p>
        </div>
      ) : (
        <Spin spinning />
      )}
    </>
  );
};

export default RestartSubscriptionsQuestions;

const MainContainer: FC<IMainContainer> = ({ title, submitName, cancelName, item, onCurrent, onNewGroup }) => {
  return (
    <div className="restart-subscriptions-questions__main">
      <h3 className="restart-subscriptions-questions__main-title">{title}</h3>
      <p className="restart-subscriptions-questions__main-subtitle">
        Pick up where you left off on try another group. Select your choice below
      </p>
      <div className="restart-subscriptions-questions__group-container">
        <TimeGroupCard medicalGroup={item?.medicalGroup || item?.medicalGroupHistory?.medicalGroup} />
      </div>
      <div className="restart-subscriptions-questions__button-container">
        <SubmitButton name={submitName} action={onCurrent} className="edit-group-container__submit-btn" />
        <CancelButton name={cancelName} cancel={onNewGroup} />
      </div>
    </div>
  );
};
