import { HeaderPageContainer } from 'components/containers/pageContainer';
import MainTemplate from 'components/templates/mainTemplate';
import React from 'react';
import NotificationList from './list';

const NotificationPage: React.FC = () => {
  return (
    <MainTemplate>
      <HeaderPageContainer>
        <h2 className="text-page-header">Notification List</h2>
      </HeaderPageContainer>
      <NotificationList />
    </MainTemplate>
  );
};

export default NotificationPage;
