import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
// components
import { SubmitButton } from '../../../../molecules/inputsBlocks/addGroup';
import { AddEventInput, AddTime, CheckboxSelect, EventsSelect } from '../../../../molecules/inputsBlocks/addEvent';
import CustomDatepicker from '../../../../molecules/customDatepicker';
import Uploader from '../../../files';
// style
import '../../style.scss';
import './style.scss';
// configs
import { sortCommunityEvents } from '../../../../../tools/consts';
import { arrayOptions } from '../../../../../tools/helpFunctions';
import { mapping } from '../../../../../api/mapping/request/addEvent';
// types
import { stateType } from '../../../../../types/types';
// actions
import eventsAction from '../../../../../redux/actions/admin_events';

interface FormData {
  title: string;
  subtitle: string;
  topic: any;
  date: any;
  time: any;
  timeLength: number;
  therapistId: number;
  type: number;
  maxParticipants: number;
  eventPhoto: any;
}

const FormAddEvent: React.FC<any> = ({ setVisible }: any) => {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<FormData>();

  const optionsTherapists: any = useSelector((state: stateType) => state.groups.therapists, shallowEqual);
  const optionsTopics: any = useSelector((state: stateType) => state.groups.topics, shallowEqual);
  const file: any = useSelector((state: stateType) => state.app.file);

  const dispatch = useDispatch();
  const history = useHistory();

  const addEventCallback = (id: any) => {
    setVisible(false);
    history.push(`/event_details/${id}`);
  };

  const onSubmit = (data: FormData) => {
    dispatch(eventsAction.add(mapping.addEventBuild(data), addEventCallback));
  };

  useEffect(() => {
    file && setValue('eventPhoto', file.uuid);
  }, [file]);
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="form-add-event validation-form">
        <div className="form-add-event__selections">
          <AddEventInput
            errors={errors.title ? errors.title.message : undefined}
            register={register}
            name="title"
            label="Title"
            placeholder="Type in Event Title"
          />
          <AddEventInput
            errors={errors.subtitle ? errors.subtitle.message : undefined}
            register={register}
            name="subtitle"
            label="Subtitle"
            placeholder="Type in Event Subtitle"
          />
          <CheckboxSelect
            errors={errors.topic ? errors.topic.message : undefined}
            control={control}
            register={register}
            options={optionsTopics}
            name="topic"
            label="Topic"
            placeholder="Select topic tags"
          />
          <CustomDatepicker
            errors={errors.date ? errors.date.message : undefined}
            control={control}
            name="date"
            label="Date"
            placeholder="Select date"
            format="MMMM Do, YYYY"
            disabled={false}
          />
          <div className="form-add-event__row">
            <div className="form-add-event__column">
              <AddTime
                errors={errors.time ? errors.time.message : undefined}
                control={control}
                name="time"
                label="Start time"
                placeholder="e.g. 6 PM, 12:30 PM"
              />
            </div>
            <div className="form-add-event__column">
              <EventsSelect
                errors={errors.timeLength ? errors.timeLength.message : undefined}
                control={control}
                options={sortCommunityEvents.length}
                name="timeLength"
                label="Time Length"
                placeholder="Select time length of event"
              />
            </div>
          </div>
          <EventsSelect
            errors={errors.therapistId ? errors.therapistId.message : undefined}
            control={control}
            options={optionsTherapists}
            name="therapistId"
            label="Therapist"
            placeholder="Select the therapist"
          />
          <div className="form-add-event__row">
            <div className="form-add-event__column">
              <EventsSelect
                errors={errors.type ? errors.type.message : undefined}
                control={control}
                options={sortCommunityEvents.type}
                name="type"
                label="Type"
                placeholder="Select time length of event"
              />
            </div>
            <div className="form-add-event__column">
              <EventsSelect
                errors={errors.maxParticipants ? errors.maxParticipants.message : undefined}
                control={control}
                options={arrayOptions(50)}
                name="maxParticipants"
                label="Max # of participants"
                placeholder="Select the max # of members"
              />
            </div>
          </div>
          <p className="form-add-event__photo-label">Upload Event Cover Photo</p>
          <Uploader type={'community_event_picture'} className="form-add-event__photo-uploader" />
        </div>
        {file?.uuid && (
          <div className="form-add-event__submit-btn">
            <SubmitButton name="Add Event" />
          </div>
        )}
      </form>
    </>
  );
};

export default FormAddEvent;
