import React, { createContext, Dispatch, SetStateAction, useContext, useReducer, useState } from 'react';
import { RecordingRules, RoomType } from '../types';
import { TwilioError } from 'twilio-video';
import { settingsReducer, initialSettings, Settings, SettingsAction } from './settings/settingsReducer';
import useActiveSinkId from './useActiveSinkId/useActiveSinkId';
import useFirebaseAuth from './useFirebaseAuth/useFirebaseAuth';
import usePasscodeAuth from './usePasscodeAuth/usePasscodeAuth';
import { User } from 'firebase';

export interface StateContextType {
  error: TwilioError | Error | null;
  setError(error: TwilioError | Error | null): void;
  getToken(
    name: string,
    room: string,
    passcode?: string,
  ): Promise<{ room_type: RoomType; token: string; is_host?: string }>;
  user?: User | null | { displayName: undefined; photoURL: undefined; passcode?: string };
  signIn?(passcode?: string): Promise<void>;
  signOut?(): Promise<void>;
  isAuthReady?: boolean;
  isFetching: boolean;
  activeSinkId: string;
  setActiveSinkId(sinkId: string): void;
  settings: Settings;
  dispatchSetting: React.Dispatch<SettingsAction>;
  roomType?: RoomType;
  showWaitingRoom?: boolean;
  hideWaitingRoom(): void;
  userLayout?: string;
  toggleUserLayout(): void;
  meetingType: string;
  setMeetingType: Dispatch<SetStateAction<string>>;
  userIsHost: boolean;
  setUserIsHost: Dispatch<SetStateAction<boolean>>;
  isAudioRemotelyLocked: boolean;
  setIsAudioRemotelyLocked: Dispatch<SetStateAction<boolean>>;
  isVideoRemotelyLocked: boolean;
  setIsVideoRemotelyLocked: Dispatch<SetStateAction<boolean>>;
  participantsToShow: string[];
  setParticipantsToShow: Dispatch<SetStateAction<string[]>>;
  hostIdentity: string;
  setHostIdentity: Dispatch<SetStateAction<string>>;
  updateRecordingRules(room_sid: string, rules: RecordingRules): Promise<Record<string, unknown>>;
}

export const StateContext = createContext<StateContextType>(null!);

/*
  The 'react-hooks/rules-of-hooks' linting rules prevent React Hooks from being called
  inside of if() statements. This is because hooks must always be called in the same order
  every time a component is rendered. The 'react-hooks/rules-of-hooks' rule is disabled below
  because the "if (process.env.REACT_APP_SET_AUTH === 'firebase')" statements are evaluated
  at build time (not runtime). If the statement evaluates to false, then the code is not
  included in the bundle that is produced (due to tree-shaking). Thus, in this instance, it
  is ok to call hooks inside if() statements.
*/
export default function AppStateProvider(props: React.PropsWithChildren<unknown>) {
  const [error, setError] = useState<TwilioError | null>(null);
  const [isFetching, setIsFetching] = useState(false);
  const [activeSinkId, setActiveSinkId] = useActiveSinkId();
  const [settings, dispatchSetting] = useReducer(settingsReducer, initialSettings);
  const [roomType, setRoomType] = useState<RoomType>();
  const [showWaitingRoom, setShowWaitingRoom] = useState(true);
  const [userLayout, setUserLayout] = useState('grid');
  const [meetingType, setMeetingType] = useState('session');
  const [userIsHost, setUserIsHost] = useState(false);
  const [hostIdentity, setHostIdentity] = useState('');
  const [isAudioRemotelyLocked, setIsAudioRemotelyLocked] = useState(false);
  const [isVideoRemotelyLocked, setIsVideoRemotelyLocked] = useState(false);
  const [participantsToShow, setParticipantsToShow] = useState<string[]>([]);

  let contextValue = {
    error,
    setError,
    isFetching,
    activeSinkId,
    setActiveSinkId,
    settings,
    dispatchSetting,
    roomType,
    showWaitingRoom,
    userLayout,
    meetingType,
    userIsHost,
    isAudioRemotelyLocked,
    isVideoRemotelyLocked,
    participantsToShow,
    hostIdentity,
  } as StateContextType;

  if (process.env.REACT_APP_SET_AUTH === 'firebase') {
    contextValue = {
      ...contextValue,
      ...useFirebaseAuth(),
    };
  } else if (process.env.REACT_APP_SET_AUTH === 'passcode') {
    contextValue = {
      ...contextValue,
      ...usePasscodeAuth(),
    };
  } else {
    contextValue = {
      ...contextValue,
      getToken: async (user_identity, room_name, room_type) => {
        console.log(user_identity, room_name, room_type);
        const endpoint =
          typeof room_type != 'undefined' && room_type == 'webinar'
            ? process.env.REACT_APP_TOKEN_ENDPOINT + '_webinar' || '/token_webinar'
            : process.env.REACT_APP_TOKEN_ENDPOINT || '/token';
        const token = localStorage.getItem('token');

        return fetch(endpoint, {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            user_identity,
            room_name,
            create_conversation: process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== 'true',
          }),
        }).then((res) => res.json());
      },
      updateRecordingRules: async (room_sid, rules) => {
        const endpoint = process.env.REACT_APP_API_URL + 'twilio/recording_rules';
        const token = localStorage.getItem('token');
        console.log(endpoint);
        return fetch(endpoint, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ room_sid, rules }),
          method: 'POST',
        })
          .then(async (res) => {
            const jsonResponse = await res.json();

            if (!res.ok) {
              const recordingError = new Error(
                jsonResponse.error?.message || 'There was an error updating recording rules',
              );
              recordingError.code = jsonResponse.error?.code;
              return Promise.reject(recordingError);
            }

            return jsonResponse;
          })
          .catch((err) => setError(err));
      },
    };
  }

  const getToken: StateContextType['getToken'] = (name, room, room_type) => {
    setIsFetching(true);
    return contextValue
      .getToken(name, room, room_type)
      .then((res) => {
        setRoomType(res.room_type);
        setIsFetching(false);
        return res;
      })
      .catch((err) => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  const updateRecordingRules: StateContextType['updateRecordingRules'] = (room_sid, rules) => {
    setIsFetching(true);
    return contextValue
      .updateRecordingRules(room_sid, rules)
      .then((res) => {
        setIsFetching(false);
        return res;
      })
      .catch((err) => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  const hideWaitingRoom = () => {
    setShowWaitingRoom(false);
  };

  const toggleUserLayout = () => {
    userLayout == 'grid' ? setUserLayout('speaker') : setUserLayout('grid');
  };

  return (
    <StateContext.Provider
      value={{
        ...contextValue,
        getToken,
        updateRecordingRules,
        hideWaitingRoom,
        toggleUserLayout,
        meetingType,
        setMeetingType,
        userIsHost,
        setUserIsHost,
        isAudioRemotelyLocked,
        setIsAudioRemotelyLocked,
        isVideoRemotelyLocked,
        setIsVideoRemotelyLocked,
        participantsToShow,
        setParticipantsToShow,
        hostIdentity,
        setHostIdentity,
      }}
    >
      {props.children}
    </StateContext.Provider>
  );
}

export function useAppState() {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error('useAppState must be used within the AppStateProvider');
  }
  return context;
}
