import React, { FC } from 'react';
import cn from 'classnames';
import { momentOrdinal } from '../../../../../tools/helpFunctions';
import Moment from 'react-moment';
import BeginsIn from '../../../../molecules/beginsIn';
import { MembersMiniList } from '../../../cards/members';
import { colorGenerator } from 'configs/tables/admin/clients';
import skillsIcon from '../../../../../assets/image/dropdown/skills.svg';
import manIcon from '../../../../../assets/image/dropdown/man.svg';
import moment from 'moment-timezone';
import calendarIcon from '../../../../../assets/image/dropdown/calendar.svg';
import timeIcon from '../../../../../assets/image/dropdown/time.svg';
import MoreDropdown from '../../../../molecules/popovers/MoreDropdown';

export const NextCommunitySession: FC<any> = ({ item }) => {
  const { start, end } = item;
  const actions = [
    {
      text: item?.title,
      icon: skillsIcon,
    },
    {
      text: item?.leader?.firstname
        ? item?.leader?.firstname
        : '' + ' ' + item?.leader?.lastname
        ? item?.leader?.lastname
        : '',
      icon: manIcon,
    },
    {
      text: moment(start).tz('America/New_York').format('dddd, MMMM Do'),
      icon: calendarIcon,
    },
    {
      text: `${moment(start).tz('America/New_York').format('h:mm A')} - ${moment(end)
        .tz('America/New_York')
        .format('h:mm A')} ET`,
      icon: timeIcon,
    },
  ];

  return (
    <div className="session-item session-item_bg">
      <div
        className={cn('session-item__status-history', {
          rescheduled:
            item?.groupSessionHistoryMarkers?.rescheduleSessionHistory &&
            !item?.groupSessionHistoryMarkers?.cancelSessionHistory,
          cancel: item?.groupSessionHistoryMarkers?.cancelSessionHistory,
        })}
      >
        {item?.groupSessionHistoryMarkers?.rescheduleSessionHistory &&
          !item?.groupSessionHistoryMarkers?.cancelSessionHistory &&
          'Rescheduled'}
        {item?.groupSessionHistoryMarkers?.cancelSessionHistory && 'Cancelled'}
      </div>
      <div className="session-item__avatar">
        {item.picture?.url ? (
          <img src={item.picture?.url} alt="" className="session-item__avatar-img" />
        ) : (
          <div className="session-item__home-avatar-txt">{item?.leader?.therapist1}</div>
        )}
        <div
          className={cn('session-item__event-type', { webinar: true, session: false })}
          style={{
            backgroundColor: item?.communityEventType?.name === 'Webinar' ? '#82c43c' : '#e2351e',
            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10,
          }}
        >{`Community ${item?.communityEventType?.name}`}</div>
      </div>
      <div className="session-item__body">
        <div style={{ position: 'absolute', top: 20, right: 20 }}>
          <MoreDropdown actions={actions} />
        </div>
        <div className="session-item__header">
          <div className="session-item__title">{item?.title}</div>
        </div>

        <div className="session-item__item">
          <div className="session-item__label">Date:</div>
          <div className="session-item__value">
            <Moment format="dddd, MMMM D">{item.start}</Moment>
            <sup>{momentOrdinal(item.start)}</sup>
          </div>
        </div>

        <div className="session-item__item">
          <div className="session-item__label">Time:</div>
          <div className="session-item__value">
            <Moment format="h:mm A">{item.start}</Moment>
            {' - '}
            <Moment format="h:mm A">{item.end}</Moment> ET
          </div>
        </div>
        {item?.members && (
          <div className="session-item__members session-item__members_future">
            <MembersMiniList
              members={item.members.map((item: any) => {
                return {
                  id: item.id,
                  firstname: item.firstname,
                  lastname: item.lastname,
                  color: colorGenerator(),
                  clientId: item.id,
                };
              })}
            />
          </div>
        )}
        <div className="session-item__item session-item__item_last">
          <BeginsIn start={item.start} status={item.status} />
        </div>
      </div>
    </div>
  );
};
