import React, { FC, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Input, Spin } from 'antd';
import Moment from 'react-moment';
import _ from 'lodash';
import cn from 'classnames';
// actions
import progressAction from '../../../redux/actions/progress';
// types
import { stateType } from 'types/types';
import { LogItemType, requestLogType } from 'types/actions/progress';
// icons
import imgPage from '../../../assets/image/progress/page.png';
import headImg from '../../../assets/image/progress/head.svg';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import backIcon from 'assets/image/questions/back.svg';
import closeIcon from 'assets/image/account/close4.svg';
// helps
import { colors } from 'tools/consts';
import { useIsMounted } from '../../../configs/hooks/useIsMounted';
// styles
import './style.scss';
import eventAnalytics from '../../../redux/actions/analytics';

const ProgressLogs: React.FC = () => {
  const [mounted, setMounted] = useState<boolean>(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const { type, id } = useParams<{ type?: string; id?: string }>();

  useEffect(() => {
    !id && !type && dispatch(progressAction.get_logs());
  }, [id, type]);

  const loader: boolean = useSelector((state: stateType) => state.progress.loaderModal, shallowEqual);
  const logs: LogItemType[] = useSelector((state: stateType) => state.progress.logs, shallowEqual);

  const renderBody = () => {
    if (type) {
      return <Notes id={Number(id)} />;
    } else if (logs.length === 0) {
      return <NoNotes mounted={mounted} setMounted={setMounted} />;
    } else {
      return <AnswerLog logs={logs} mounted={mounted} setMounted={setMounted} />;
    }
  };

  const onClickPlusNotes = () => {
    history.push('/progress/logs/add');
    dispatch(eventAnalytics.analytics('new-log'));
  };

  const isMounted = useIsMounted();

  return (
    <Spin spinning={loader}>
      <div className="progress-logs-box">
        <div className="progress-logs-box__header">
          <button onClick={() => history.push('/progress')}>
            <img src={backIcon} alt="back icon" />
          </button>
          <div className="progress-logs-box__top-title">Self Awareness Log</div>
          <button onClick={() => history.push('/progress')}>
            <img src={closeIcon} alt="close" />
          </button>
        </div>
        <div className="progress-logs-box__body">
          {!loader && isMounted() && renderBody()}
          {!type && !loader && isMounted() && mounted && (
            <div className="progress-logs-box__btn-add" onClick={onClickPlusNotes}>
              <PlusOutlined style={{ color: '#fff' }} />
            </div>
          )}
        </div>
      </div>
    </Spin>
  );
};

export default ProgressLogs;

interface INoNotes {
  mounted: boolean;
  setMounted: (type: boolean) => void;
}

const NoNotes: FC<INoNotes> = ({ setMounted, mounted }) => {
  // useEffect to fix flicker before loading
  useEffect(() => {
    setMounted(true);

    return () => {
      setMounted(false);
    };
  }, []);

  return (
    <>
      {mounted && (
        <>
          <img src={imgPage} alt="imgPage" className="progress-logs-box__img" />
          <div className="progress-logs-box__title">You don’t have any notes yet</div>
          <div className="progress-logs-box__info">
            Keep track of your thoughts and emotions on a consistent basis to help you build self awareness, acceptance,
            and mindfulness. Building these skills are all important parts of making progress.
          </div>
        </>
      )}
    </>
  );
};

interface IAnswerLog {
  logs: LogItemType[];
  mounted: boolean;
  setMounted: (type: boolean) => void;
}

const AnswerLog: FC<IAnswerLog> = ({ logs, mounted, setMounted }: IAnswerLog) => {
  // useEffect to fix flicker before loading
  useEffect(() => {
    setMounted(true);

    return () => {
      setMounted(false);
    };
  }, []);

  return (
    <>
      {mounted && (
        <>
          <img src={headImg} alt="head image" className="progress-logs-box__img" />
          <div className="progress-logs-box__title">Self Awareness Log</div>
          <div className="progress-logs-box__info">
            Keep track of your thoughts and emotions on a consistent basis to help you build self awareness, acceptance,
            and mindfulness. Building these skills are all important parts of making progress.
          </div>
          <LogList list={logs} />
        </>
      )}
    </>
  );
};

interface INotes {
  id?: number;
}

const Notes: FC<INotes> = ({ id }: INotes) => {
  const dispatch = useDispatch();
  const [color, setColor] = useState<number>(1);

  const log: LogItemType = useSelector((state: stateType) => state.progress.log, shallowEqual);

  useEffect(() => {
    id && (_.isEmpty(log) || log.id !== id) && dispatch(progressAction.get_log({ id }));

    return () => {
      !_.isEmpty(log) && dispatch(progressAction.set_log({}));
    };
  }, []);

  useEffect(() => {
    !_.isEmpty(log) && id && setColor(log?.colorNumber);
  }, [id]);

  return (
    <div className="progress-logs-box__log-body">
      <div className="progress-logs-box__answers-title">Self Awareness Log</div>
      <div className="progress-logs-box__answers-sub-title">
        You can add entries to your self awareness log by adding a note below. Making a consistent practice of adding
        notes, and checking in with yourself is a great way to stay on track.
      </div>
      <ChoiceColor color={color} setColor={setColor} />
      <Note item={log} color={color} actionType={id ? 'edit' : 'create'} />
    </div>
  );
};

interface ChoiceColorType {
  color: number;
  setColor: (colorId: number) => void;
}

const ChoiceColor: React.FC<ChoiceColorType> = ({ color, setColor }: ChoiceColorType) => {
  return (
    <div className="choice-colors">
      {colors.map((item) => (
        <div
          key={item.id}
          className={cn('choice-colors__wrap ', { 'choice-colors__wrap-active': color === item.id })}
          onClick={() => setColor(item.id)}
        >
          <div className="choice-colors__item" style={{ background: item.color }} />
        </div>
      ))}
    </div>
  );
};

interface NoteType {
  item: LogItemType;
  color: number | null;
  actionType: string;
}

const Note: React.FC<NoteType> = ({ item, color, actionType }: NoteType) => {
  const [value, setValue] = useState<string>('');
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    actionType === 'edit' && setValue(item.note || '');
  }, [item]);

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value || '');
  };

  const logsCallback = () => {
    history.push('/progress/logs');
  };

  const done = () => {
    const request: requestLogType = {
      id: item.id,
      colorNumber: color,
      note: value,
    };
    dispatch(eventAnalytics.analytics('save-log'));
    actionType === 'edit' && dispatch(progressAction.edit_logs(request, logsCallback));
    actionType === 'create' && dispatch(progressAction.post_logs(request, logsCallback));
  };

  const remove = () => {
    dispatch(progressAction.delete_logs({ id: item.id }, logsCallback));
  };

  const find: any = _.find(colors, { id: color });

  return (
    <div className="note-box" style={{ backgroundColor: `${find?.color}B2` }}>
      <div className="note-box__header">
        <span onClick={logsCallback} className="note-box__link back">
          Notes
        </span>
        <button onClick={done} disabled={!value.trim()} className="note-box__link">
          Done
        </button>
      </div>
      <Input.TextArea
        bordered={false}
        className="note-box__textarea"
        rows={7}
        autoSize={{ minRows: 7, maxRows: 7 }}
        onChange={onChange}
        value={value}
      />
      {actionType === 'edit' && (
        <div className="note-box__footer">
          <div className="note-box__date">
            <Moment format="MMMM DD, YYYY">{item.createdAt}</Moment>
          </div>
          <button className="btn-remove" onClick={remove} />
        </div>
      )}
    </div>
  );
};

interface LogListType {
  list: LogItemType[];
}

interface IColor {
  id: number;
  color: string;
}

const LogList: React.FC<LogListType> = ({ list }: LogListType) => {
  const history = useHistory();

  return (
    <div className="logs-list">
      {list?.map((item: LogItemType) => {
        const find: IColor | undefined = _.find(colors, { id: item.colorNumber });
        return (
          <div key={item.id} className="logs-list__wrap">
            <div className="logs-list__item" style={{ backgroundColor: find?.color || '#e6f4ff' }}>
              <p className="logs-list__note">{item.note}</p>
              <div className="logs-list__item-footer">
                <div className="logs-list__date">
                  <Moment format="MMMM DD, YYYY">{item.createdAt}</Moment>
                </div>
                <div className="logs-list__btn-edit" onClick={() => history.push(`/progress/logs/edit/${item.id}`)}>
                  <EditOutlined style={{ color: '#fff' }} />
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
