import React from 'react';

const NotFoundGroup: React.FC = () => {
  return (
    <div className="not-found-group">
      <p>Group is not a found</p>
    </div>
  );
};

export default NotFoundGroup;
