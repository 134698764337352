/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { put, call, select } from 'redux-saga/effects';
// api
import { apiProgress } from '../../api/progress';
// actions
import userActions from '../actions/user';
import progressAction from '../actions/progress';
// helps
import { mapping } from '../../api/mapping/response/user';
import showError from './showError';

export function* sagaProgressStart() {
  yield put(progressAction.loaderModal(true));
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);

  try {
    const response = yield call(apiProgress.start, role.toLowerCase());
    yield userActions.create(response.data.data.item);
    yield userActions.state(mapping.stateFlowBuild(response.data.data.item.stateFlow, response.data.data.item));
    yield put(progressAction.loaderModal(false));
  } catch (e) {
    yield put(progressAction.loaderModal(false));
    showError();
  }
}

export function* sagaProgressReset() {
  yield put(progressAction.loaderModal(true));
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);

  try {
    const response = yield call(apiProgress.reset, role.toLowerCase());
    yield userActions.create(response.data.data.item);
    yield userActions.state(mapping.stateFlowBuild(response.data.data.item.stateFlow, response.data.data.item));
    yield put(progressAction.loaderModal(false));
  } catch (e) {
    yield put(progressAction.loaderModal(false));
    showError();
  }
}

export function* sagaProgressHoldOver(action) {
  yield put(progressAction.loaderModal(true));
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);

  try {
    yield call(apiProgress.hold_over, role.toLowerCase(), action.taskType);

    if (action.callback) {
      action.callback();
    }

    yield put(progressAction.loaderModal(false));
  } catch (e) {
    yield put(progressAction.loaderModal(false));
    showError();
  }
}

export function* sagaGetListProgress() {
  yield put(progressAction.loader(true));
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);

  try {
    const response = yield call(apiProgress.get, role.toLowerCase());

    yield put(progressAction.set(response.data.data.items));
    yield put(progressAction.loader(false));
  } catch (e) {
    yield put(progressAction.loader(false));
    showError();
  }
}

export function* sagaGetTermList() {
  yield put(progressAction.loaderModal(true));
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);

  try {
    const response = yield call(apiProgress.get_term, role.toLowerCase());
    yield put(progressAction.set_term(response.data.data.items));
    yield put(progressAction.loaderModal(false));
  } catch (e) {
    yield put(progressAction.loaderModal(false));
    showError();
  }
}

export function* sagaPostTermList(action) {
  yield put(progressAction.loaderModal(true));
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);

  try {
    const response = yield call(apiProgress.post_term, role.toLowerCase(), action.data);
    yield put(progressAction.set_term(response.data.data.items));
    yield put(progressAction.loaderModal(false));
    action.callback && action.callback();
  } catch (e) {
    yield put(progressAction.loaderModal(false));
    showError();
  }
}

export function* sagaGetLogsList() {
  yield put(progressAction.loaderModal(true));
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);

  try {
    const response = yield call(apiProgress.get_logs, role.toLowerCase());
    yield put(progressAction.set_logs(response.data.data.items));
    yield put(progressAction.loaderModal(false));
  } catch (e) {
    yield put(progressAction.loaderModal(false));
    showError();
  }
}

export function* sagaGetLogItem(action) {
  yield put(progressAction.loaderModal(true));
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);

  try {
    const response = yield call(apiProgress.get_log, role.toLowerCase(), action.data);
    yield put(progressAction.set_log(response.data.data.item));
    yield put(progressAction.loaderModal(false));
  } catch (e) {
    yield put(progressAction.loaderModal(false));
    showError();
  }
}

export function* sagaCreateLogItem(action) {
  yield put(progressAction.loaderModal(true));
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);

  try {
    yield call(apiProgress.post_logs, role.toLowerCase(), action.data);
    yield put(progressAction.loaderModal(false));
    action.callback && action.callback();
  } catch (e) {
    yield put(progressAction.loaderModal(false));
    showError();
  }
}

export function* sagaEditLogItem(action) {
  yield put(progressAction.loaderModal(true));
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);

  try {
    yield call(apiProgress.edit_logs, role.toLowerCase(), action.data);
    yield put(progressAction.loaderModal(false));
    action.callback && action.callback();
  } catch (e) {
    yield put(progressAction.loaderModal(false));
    showError();
  }
}

export function* sagaDeleteLogItem(action) {
  yield put(progressAction.loaderModal(true));
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);

  try {
    yield call(apiProgress.delete_logs, role.toLowerCase(), action.data);
    action.callback && action.callback();
    yield put(progressAction.loaderModal(false));
  } catch (e) {
    yield put(progressAction.loaderModal(false));
    showError();
  }
}
