/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { all, call, put } from 'redux-saga/effects';
// api
import { apiConsultation } from '../../../api/consultation';
// actions
import consultationAction from '../../actions/consultation';
// sagas
import { getCountry, getGenders, getReasons, getState, getStatuses, getTopics, getTopicsExtended } from '../options';
// helps
import showError from '../showError';
import { getConsultationErrors } from '../../../tools/helpFunctions';
import userActions from '../../actions/user';
import { mapping } from '../../../api/mapping/response/user';

export function* sagaConsultationFormSubmit(action) {
  yield put(consultationAction.loader(true));
  try {
    const response = yield call(apiConsultation.submit, action.data);
    if (response.data.data.item?.seriesFlow) {
      yield put(consultationAction.loader(false));
      action.callback && action.callback();
      window.location.href = `${process.env.REACT_APP_SERIES_DOMEN}?id=${response.data.data.item?.id}&token=${response.data.data.item?.seriesUserToken}`;
    } else {
      yield userActions.state(mapping.stateFlowBuild(response.data.data.item.stateFlow, response.data.data.item));
      yield put(consultationAction.loader(false));
    }
  } catch (e) {
    yield put(consultationAction.loader(false));
    showError();
    if (e.response.status === 400 && e.response.data.violations) {
      action.errorsCallback(getConsultationErrors(e.response.data.violations));
    }
  }
}

export function* sagaConsultationForm() {
  yield put(consultationAction.loader(true));
  try {
    yield all([
      call(getReasons),
      call(getTopics),
      call(getTopicsExtended),
      call(getStatuses),
      call(getGenders),
      call(getState),
      call(getCountry),
    ]);
  } catch (e) {
    showError();
  } finally {
    yield put(consultationAction.loader(false));
  }
}
