import { Spin } from 'antd';
import React, { FC, useEffect } from 'react';
import Moment from 'react-moment';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
// actions
import progressAction from '../../../redux/actions/progress';
// types
import { stateType } from 'types/types';
// styles
import './style.scss';

// icons

interface ITracker {
  moreText?: string;
  onMore: () => void;
  description?: string;
}

const Tracker: FC<ITracker> = ({ moreText, onMore, description }: ITracker) => {
  const dispatch = useDispatch();

  const loader: boolean = useSelector((state: stateType) => state.progress.loader, shallowEqual);
  const list: any = useSelector((state: stateType) => state.progress.list, shallowEqual);

  useEffect(() => {
    list.length === 0 && dispatch(progressAction.get());

    return () => {
      dispatch(progressAction.set([]));
    };
  }, []);

  return (
    <Spin spinning={loader}>
      {!loader && (
        <div className="tracker">
          <div className="tracker__header">
            <div className="tracker__title">Symptom tracker</div>
            <div className="tracker__results">
              {list.length > 0 && (
                <>
                  Results on <Moment format="MM/DD/YY">{new Date()}</Moment>
                </>
              )}
              {list.length === 0 && (
                <div className="tracker__text">
                  {`We'll ask you questions from time to time that will help you keep track of your progress on your mental
                    health journey. You will be notified about your first check-in soon, and you'll be able to view your
                    results here.`}
                </div>
              )}
            </div>
          </div>
          <div className="tracker__body">
            {list.length > 0 && (
              <div className="tracker__boxs">
                <div className="tracker__col">
                  <div className="tracker__box">
                    <SingleTrackInfo />
                  </div>
                </div>
                <div className="tracker__col">
                  <div className="tracker__box">
                    <ListTrackInfo />
                  </div>
                </div>
              </div>
            )}
            <div className="tracker__more">
              <button onClick={onMore}>{moreText || 'See more'}</button>
            </div>
            {description && <p className="tracker__description">{description}</p>}
          </div>
        </div>
      )}
    </Spin>
  );
};

export default Tracker;

const SingleTrackInfo = () => {
  const { totalScore, maxScore, ranking }: any = useSelector(
    (state: stateType) => state.progress.list[0],
    shallowEqual,
  );

  const textController = () => {
    switch (ranking) {
      case 'High':
        return 'You are experiencing a high degree of symptoms. With consistent therapy and continued practice, over time our groups can help you work on these areas, and see a reduction in symptoms.';
      case 'Moderate':
        return 'You are experiencing a multitude of symptoms. Over time, therapy can help you work on these areas and see improvement.';

      case 'Mild':
        return 'You are experiencing mild symptoms. Therapy can help you manage and reduce your symptoms further.';
      case 'Minimal':
        return 'You are experiencing minimal symptoms. Still, our groups can help you reduce stress, maintain your wellbeing, and shift habits that are keeping you stuck.';
      default:
        return '';
    }
  };
  return (
    <div className="single-track-info">
      <div className="single-track-info__title">
        Your current score is {totalScore}/{maxScore}
      </div>
      <div className={`single-track-info__rank ${ranking.toLowerCase()}`}>{ranking}</div>
      <div className="single-track-info__text">{textController()}</div>
    </div>
  );
};

const ListTrackInfo = () => {
  const list: any = useSelector((state: stateType) => state.progress.list, shallowEqual);

  return (
    <div className="list-track-info">
      <div className="list-track-info__title">Recent results</div>
      <div className="list-track-info__items">
        {list?.map(({ id, createdAt, ranking, totalScore, maxScore, comparativePercentage }: any) => (
          <div className="list-track-info__item" key={id}>
            <div className="list-track-info__item-date"> {<Moment format="MMM, DD">{createdAt}</Moment>}</div>
            <div className={`list-track-info__item-rank ${ranking.toLowerCase()}`}> {ranking}</div>
            <div className="list-track-info__result">
              {totalScore}/{maxScore}
            </div>
            <div className="list-track-info__progress">
              {comparativePercentage || comparativePercentage === 0 ? `${comparativePercentage}%` : <>&mdash;</>}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
