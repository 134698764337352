import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Spin } from 'antd';
// types
import { stateType } from '../../../../../types/types';
// actions
import clientAction from 'redux/actions/client';
// icons
import { ReactComponent as CloseIcon } from '../../../../../assets/image/account/close4.svg';
import WarningIcon from '../../../../../assets/image/client/warning-icon.svg';
import doneIcon from '../../../../../assets/image/questions/after.svg';
// styles
import '../style.scss';

const CancelClient: React.FC<any> = ({ id }: any) => {
  const [visible, setVisible] = useState(false);
  const [success, setSuccess] = useState(false);

  const loader = useSelector((state: stateType) => state.clients.loaderModal);
  const item = useSelector((state: stateType) => state.clients.item);

  const open = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onSuccess = () => {
    setSuccess(true);
  };

  return (
    <>
      <button className="btn-form" onClick={open}>
        Cancel Client Subscription
      </button>
      {visible && (
        <Modal
          visible={visible}
          onOk={handleOk}
          closable={true}
          onCancel={handleCancel}
          centered
          maskTransitionName="none"
          footer={null}
          className="cancel-client-modal"
          closeIcon={<CloseIcon />}
        >
          {!success ? (
            <Spin spinning={loader}>
              <ModalContainer item={item} id={id} onSuccess={onSuccess} />
            </Spin>
          ) : (
            <Spin spinning={loader}>
              <SuccessModalContainer item={item} id={id} handleCancel={handleCancel} />
            </Spin>
          )}
        </Modal>
      )}
    </>
  );
};

export default CancelClient;

const ModalContainer = ({ item, id, onSuccess }: any) => {
  const dispatch = useDispatch();

  const cancelSubscription = () => {
    dispatch(clientAction.cancelSubscription(id, onSuccess));
  };

  return (
    <>
      <div className="cancel-session-warning-container">
        <div className="cancel-session-warning-container__main">
          <div className="cancel-session-warning-container__icon">
            <img src={WarningIcon} alt="" />
          </div>
          <div className="cancel-session-warning-container__title">
            <div className="cancel-session-warning-container__question">
              Are you sure you want to cancel {item.firstname} {item.lastname} Subscription?
            </div>
            <p className="cancel-session-warning-container__subtitle">
              By cancelling, this will stop {item.firstname} from being billed after his current <br />
              billing cycle and remove him from sessions following the <br />
              remainder of sessions on {item.firstname}’s current billing cycle.
            </p>
          </div>
        </div>
        <div style={{ width: '100%' }}>
          <button className="btn-form" type="submit" onClick={cancelSubscription}>
            Cancel Subscription
          </button>
        </div>
      </div>
    </>
  );
};

const SuccessModalContainer = ({ item, id, handleCancel }: any) => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(clientAction.get(id));
    };
  }, []);

  return (
    <>
      <div className="cancel-session-warning-container">
        <div className="cancel-session-warning-container__main">
          <div className="cancel-session-warning-container__icon">
            <img src={doneIcon} alt="" />
          </div>
          <div className="cancel-session-warning-container__cancellation">Cancellation</div>
          <div className="cancel-session-warning-container__title cancel-session-warning-container__cancellation-title">
            You’ve succesfully canceled {item.firstname} {item.lastname}’s <br />
            Subscription
          </div>
        </div>
        <div style={{ width: '100%' }}>
          <button className="btn-form" onClick={handleCancel}>
            Done
          </button>
        </div>
      </div>
    </>
  );
};
