import { useCallback, useEffect, useState } from 'react';
import { useAppState } from '../../state';
import useChatContext from '../useChatContext/useChatContext';
import useFilesContext from '../useFilesContext/useFilesContext';
import useParticipantMenuContext from '../useParticipantMenuContext/useParticipantMenuContext';

export default function useGridDimensions() {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const { userLayout } = useAppState();
  const { isChatWindowOpen } = useChatContext();
  const { isFileWindowOpen } = useFilesContext();
  const { isParticipantMenuOpen } = useParticipantMenuContext();

  const updateDimensions = useCallback(() => {
    if (userLayout == 'grid') {
      const container = document.getElementById('participantGridContainer');
      if (container != null) {
        setWidth(container.clientWidth);
        setHeight(container.clientHeight);
      } else {
        setWidth(0);
        setHeight(0);
      }
    } else {
      setWidth(0);
      setHeight(0);
    }
  }, [userLayout, isChatWindowOpen, isFileWindowOpen, isParticipantMenuOpen]);

  useEffect(() => {
    updateDimensions();
    const onResize = () => {
      updateDimensions();
      setTimeout(updateDimensions, 100);
    };
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [userLayout, updateDimensions]);

  return [width, height];
}
