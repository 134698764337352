import React, { FC } from 'react';
import { Modal } from 'antd';
// icons
import spinerIcon from 'assets/image/progress/spiner.svg';
// styles
import './style.scss';

interface ILoadingModal {
  visible: boolean;
  title: string;
  text: string;
}

const LoadingModal: FC<ILoadingModal> = ({ visible, title, text }: ILoadingModal) => {
  return (
    <Modal
      visible={visible}
      closable={false}
      centered
      maskTransitionName="none"
      footer={null}
      className="loading-modal"
    >
      <div className="loading-modal__body">
        <div className="loading-modal__title">{title}</div>
        <div className="loading-modal__loader">
          <img src={spinerIcon} alt="spiner" />
        </div>
        <div className="loading-modal__text">{text}</div>
      </div>
    </Modal>
  );
};

export default LoadingModal;
