import React, { FC } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import Moment from 'react-moment';
// types
import { stateType } from 'types/types';
// icons
import mockImg from 'assets/image/common/mockPhoto.png';
// styles
import './style.scss';
// configs
import { createLicenseList } from '../../../../../tools/helpFunctions';
import { timeConvertor, week_days } from 'configs/tables/admin/clients';

interface GroupInterface {
  medicalGroup: any;
  showDet?: boolean;
  profile?: any;
}

const Group: FC<GroupInterface> = ({ medicalGroup, showDet }: GroupInterface) => {
  const sub: any = useSelector((state: stateType) => state.stripe.item, shallowEqual);
  const profile: any = useSelector((state: stateType) => state.user.profile);

  return (
    <div className="group">
      {showDet && sub?.saveAmountPercent && <span className="group__precent">Save {sub.saveAmountPercent} %</span>}
      <div className="group__avatar">
        <img src={medicalGroup?.avatar?.url || mockImg} alt="group avatar" />
      </div>
      <div className="group__info">
        <p className="group__title">Your Group Details </p>
        <DetailsTherapist medicalGroup={medicalGroup} profile={profile} />
        {medicalGroup ? (
          <p className="group__time">
            Meets every {week_days[medicalGroup?.dayOfWeek - 1]},{' '}
            <span>
              {timeConvertor(medicalGroup?.start, 'small-format')} - {timeConvertor(medicalGroup?.end, 'small-format')}{' '}
              ET
            </span>
          </p>
        ) : (
          <p className="group__time">
            Meets every <Moment format="dddd">{profile?.preferableGroupInfo?.groupTimeFrom}</Moment>,{' '}
            <span>
              <Moment format="h:mma">{profile?.preferableGroupInfo?.groupTimeFrom}</Moment>
              {' - '}
              <Moment format="h:mma">{profile?.preferableGroupInfo?.groupTimeTo}</Moment> ET
            </span>
          </p>
        )}
      </div>
    </div>
  );
};

export default React.memo(Group);

const DetailsTherapist: FC<GroupInterface> = ({ medicalGroup, profile }: GroupInterface) => {
  return (
    <p className="group__therapist">
      {medicalGroup?.therapist?.title ? `${medicalGroup?.therapist?.title}. ` : ''}
      {medicalGroup?.therapist?.firstname || profile?.preferableGroupInfo?.therapist}{' '}
      {medicalGroup?.therapist?.lastname}{' '}
      {medicalGroup?.therapist?.licenses.length && createLicenseList(medicalGroup?.therapist?.licenses, true)}{' '}
      <span style={{ whiteSpace: 'nowrap' }}>
        {medicalGroup?.groupTopic?.name || profile?.preferableGroupInfo?.name} Group
      </span>
    </p>
  );
};
