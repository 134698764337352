/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { fixDayOfWeek, ParseArray } from '../../../../tools/helpFunctions';
export const choiceGroupMapping = {
  build(array) {
    if (array) {
      let data = array[0];
      return {
        group: {
          id: data.id,
          name: data.groupTopic.name,
          date: {
            dayOfWeek: fixDayOfWeek(data.dayOfWeek, data.start, 'to_ny'),
            end: data.end,
            start: data.start,
          },
          therapist_name: data.therapist.firstname + ' ' + data.therapist.lastname,
          url: data.avatar ? data.avatar.url : '',
          tags: data.tags,
          about: data.description,
        },
        therapist: {
          avatar: data.therapist.avatar ? data.therapist.avatar.url : '',
          name: data.therapist.name,
          licensure: data?.therapist?.licenses ? ParseArray(data?.therapist?.licenses) : '',
          practiceYears: data.therapist.practiceYears,
          specialties:
            data.therapist.specialties.length > 0
              ? data.therapist.specialties.map((item, index) => {
                  if (index > 0) {
                    return `, ${item.name}`;
                  }

                  return `${item.name}`;
                })
              : null,
          description: data.therapist.biography,
        },
      };
    }
    return {};
  },

  buildArray(array) {
    if (array) {
      return array.map((data) => {
        return {
          group: {
            id: data.id,
            name: data.groupTopic.name,
            date: {
              dayOfWeek: fixDayOfWeek(data.dayOfWeek, data.start, 'to_ny'),
              end: data.end,
              start: data.start,
            },
            therapist_name: data.therapist.firstname + ' ' + data.therapist.lastname,
            url: data.avatar ? data.avatar.url : '',
            tags: data.tags,
            about: data.description,
          },
          therapist: {
            avatar: data.therapist.avatar ? data.therapist.avatar.url : '',
            name: data.therapist.firstname + ' ' + data.therapist.lastname,
            licensure: data?.therapist?.licenses ? ParseArray(data?.therapist?.licenses) : '',
            practiceYears: data.therapist.practiceYears,
            specialties:
              data.therapist.specialties.length > 0
                ? data.therapist.specialties.map((item, index) => {
                    if (index > 0) {
                      return `, ${item.name}`;
                    }

                    return `${item.name}`;
                  })
                : null,
            description: data.therapist.biography,
          },
        };
      });
    }
  },

  buildSmCardItem(object) {
    if (object) {
      return {
        group: {
          id: object.id,
          name: object.groupTopic.name,
          date: {
            dayOfWeek: fixDayOfWeek(object.dayOfWeek, object.start, 'to_ny'),
            end: object.end,
            start: object.start,
          },
          therapist_name: object.therapist.firstname + ' ' + object.therapist.lastname,
          url: object.avatar ? object.avatar.url : '',
          tags: '',
          about: '',
        },
        therapist: {},
      };
    }
  },
};
