import React, { useEffect } from 'react';
import { Redirect, Route, RouteProps, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// components
import LoadingPage from './loading';
import { authActions } from '../../redux/actions/auth';
import queryString from 'query-string';
import userActions from '../../redux/actions/user';

interface LayoutDefault {
  component: React.ComponentType<RouteProps>;
  path?: string;
  exact?: boolean;
  role?: string;
}

const LayoutAlternativeAuth2: React.FC<LayoutDefault> = ({ component: Component, ...args }: LayoutDefault) => {
  const auth = useSelector((state: any) => state.app.auth);

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    localStorage.setItem('role', 'customer');

    authActions.success({
      token: String(queryString.parse(history.location.search).token),
      refreshToken: String(queryString.parse(history.location.search).refresh_token),
    });

    userActions.update(undefined, dispatch, () => history.push('/'));
  }, []);
  return (
    <Route
      render={() => {
        if (auth === 'loading') {
          return <LoadingPage />;
        } else {
          return (
            <Route
              path={args.path}
              render={() => {
                return <Redirect to={'/'} />;
              }}
            />
          );
        }
      }}
    />
  );
};

export default LayoutAlternativeAuth2;
