import React, { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Modal, Spin } from 'antd';
import cn from 'classnames';
// componets
import {
  EditTypeFormChangePassword,
  EditTypeFormEmail,
  EditTypeFormName,
  EditTypeFormPassword,
  EditTypeFormPhone,
  EditTypeICD10,
} from '../../forms/edit/editTypeForms';
// actions
import accountAction from '../../../../redux/actions/account';
// types
import { stateType } from '../../../../types/types';
// styles
import './style.scss';
// icons
import { ReactComponent as CloseIcon } from '../../../../assets/image/account/close4.svg';

import { useForm } from 'react-hook-form';
import { FormDataPassword } from 'types/form-data';
import eventAnalytics from '../../../../redux/actions/analytics';

const EditAccountModal: React.FC<any> = ({ children, icon, type, role }: any) => {
  const [visible, setVisible] = useState(false);

  const dispatch = useDispatch();

  const loader = useSelector((state: stateType) => state.account.loaderModal, shallowEqual);
  const varibleToken = useSelector((state: stateType) => state.account.varibleToken, shallowEqual);
  const showMinimal = useSelector((state: any) => state.main_menu.showMinimal, shallowEqual);

  const open = () => {
    setVisible(true);
    const route =
      type === 'name' ? 'change-name-start' : type === 'phone' ? 'change-number-start' : 'change-email-start';
    dispatch(accountAction.editCheckToken());
    dispatch(eventAnalytics.analytics(route));
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <div onClick={open}>
        {icon}
        {children}
      </div>
      {visible && (
        <Modal
          visible={visible}
          onOk={handleOk}
          closable={true}
          onCancel={handleCancel}
          centered
          maskTransitionName="none"
          footer={null}
          closeIcon={<CloseIcon />}
          className={cn('edit-account-modal', {
            'edit-account-modal__minimal': showMinimal,
          })}
        >
          <Spin spinning={loader}>
            <GetTokenContainer setVisible={setVisible} show={!loader && varibleToken} />
            {!loader && !varibleToken && <EditTypeForm type={type} setVisible={setVisible} role={role} />}
          </Spin>
        </Modal>
      )}
    </>
  );
};

export default EditAccountModal;

const EditTypeForm = ({ type, setVisible, role }: any) => {
  const TypeController = () => {
    switch (type) {
      case 'name':
        return <FormEditNameContainer setVisible={setVisible} />;
      // case 'forum-name':
      //   return <FormEditForumNameContainer setVisible={setVisible} />;
      case 'phone':
        return <FormEditPhoneContainer setVisible={setVisible} role={role} />;
      case 'email':
        return <FormEditEmailContainer setVisible={setVisible} />;
      case 'password':
        return <FormEditPasswordContainer setVisible={setVisible} />;
      case 'icd10':
        return <FormEditICD10Container setVisible={setVisible} />;
      default:
        return '';
    }
  };
  return <>{TypeController()}</>;
};

export const GetTokenContainer: React.FC<any> = ({ setVisible, show }: any) => {
  const formProps = useForm<FormDataPassword>();

  if (!show && typeof show === 'boolean') {
    return <></>;
  }

  return (
    <div className="edit-account-container">
      <div className="edit-account-container__title">Password required</div>
      <div className="edit-account-container__desc">
        To protect your personal information, please verify your password.
      </div>
      <EditTypeFormPassword setVisible={setVisible} formProps={formProps} />
    </div>
  );
};

// const FormEditForumNameContainer = ({ setVisible }: any) => {
//   return (
//     <div className="edit-account-container">
//       <div className="edit-account-container__title">Change Forum Name</div>
//       <div className="edit-account-container__desc">
//         Please include your forum name. This is entirely confidential and will only be shared with your therapist.
//       </div>
//       <EditTypeFormForumName setVisible={setVisible} />
//     </div>
//   );
// };

const FormEditNameContainer = ({ setVisible }: any) => {
  return (
    <div className="edit-account-container">
      <div className="edit-account-container__title">Change name</div>
      <div className="edit-account-container__desc">
        Please include your first name and last name. This is entirely confidential and will only be shared with your
        therapist.
      </div>
      <EditTypeFormName setVisible={setVisible} />
    </div>
  );
};

export const FormEditICD10Container = ({ setVisible }: any) => {
  return (
    <div className="edit-account-container">
      <div className="edit-account-container__title">Client ICD-10 Code</div>
      <div className="edit-account-container__desc">
        To change the client’s ICD-10 code, please select a code from the dropdown. You can filter for a code by typing
        in the dropdown, and please select it before clicking save. To see the diagnosis associated with each code,
        <br />
        check the{' '}
        <a href="https://dev.grouporttherapy.com/ICD-10%20Diagnosis%20Codes.pdf" target="_blank" rel="noreferrer">
          ICD-10 Diagnosis Codes
        </a>
        .
      </div>
      <EditTypeICD10 setVisible={setVisible} />
    </div>
  );
};

const FormEditPhoneContainer = ({ setVisible, role }: any) => {
  return (
    <div className="edit-account-container">
      <div className="edit-account-container__title">Change phone number</div>
      <div className="edit-account-container__desc">
        To change your phone number, please enter a new phone number below.
        {(!role || role !== 'therapist') && 'This information is important for session reminders.'}
      </div>
      <EditTypeFormPhone setVisible={setVisible} />
    </div>
  );
};

const FormEditEmailContainer = ({ setVisible }: any) => {
  return (
    <div className="edit-account-container">
      <div className="edit-account-container__title">Change email</div>
      <div className="edit-account-container__desc">
        To change the email associated with your account, please enter a new email below. All important information
        regarding your account will be sent to this email.
      </div>
      <EditTypeFormEmail setVisible={setVisible} />
    </div>
  );
};

const FormEditPasswordContainer = ({ setVisible }: any) => {
  return (
    <div className="edit-account-container">
      <div className="edit-account-container__title">Change password</div>
      <div className="edit-account-container__desc">
        To change your password for your account, please enter a new one below. This will control access to your
        account.
      </div>
      <EditTypeFormChangePassword setVisible={setVisible} />
    </div>
  );
};
