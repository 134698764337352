import React, { createContext, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppState } from '../../../state';

type FilesContextType = {
  isFileWindowOpen: boolean;
  setIsFileWindowOpen: (isChatWindowOpen: boolean) => void;
  fileList: any[];
  upload: (file: File) => void;
  loadFileList: () => void;
  hasNewFiles: boolean;
};

export const FilesContext = createContext<FilesContextType>(null!);

export const FilesProvider: React.FC = ({ children }) => {
  const [isFileWindowOpen, setIsFileWindowOpen] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [hasNewFiles, setHasNewFiles] = useState(false);
  const { URLRoomName } = useParams<{ URLRoomName: string }>();
  const { meetingType } = useAppState();

  const loadFileList = useCallback(() => {
    const endpoint = process.env.REACT_APP_API_URL + 'twilio/file_list';
    const JWTtoken = localStorage.getItem('token');
    fetch(endpoint, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${JWTtoken}`,
      },
      body: JSON.stringify({
        room_name: URLRoomName,
        meeting_type: meetingType,
      }),
    })
      .then((response) => response.json())
      .then((responseData) => {
        setFileList(responseData.files);
        if (responseData.files.length > 0 && !isFileWindowOpen) {
          setHasNewFiles(true);
        }
      });
  }, [fileList, URLRoomName, meetingType, isFileWindowOpen]);

  useEffect(() => {
    if (isFileWindowOpen) {
      setHasNewFiles(false);
    }
  }, [isFileWindowOpen]);

  const upload = useCallback(
    (file: File) => {
      console.log('upload file', file);
      const endpoint = process.env.REACT_APP_API_URL + 'twilio/file_upload';
      const JWTtoken = localStorage.getItem('token');
      file.arrayBuffer().then((fileContents) => {
        fetch(endpoint, {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${JWTtoken}`,
          },
          body: JSON.stringify({
            room_name: URLRoomName,
            file_name: file.name,
            file_type: file.type,
            file_size: file.size,
            file_contents: btoa(
              new Uint8Array(fileContents).reduce((data, byte) => data + String.fromCharCode(byte), ''),
            ),
          }),
        })
          .then((response) => response.json())
          .then((responseData) => {
            console.log(responseData);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    [fileList],
  );

  return (
    <FilesContext.Provider
      value={{
        isFileWindowOpen,
        setIsFileWindowOpen,
        fileList,
        upload,
        loadFileList,
        hasNewFiles,
      }}
    >
      {children}
    </FilesContext.Provider>
  );
};
