import { takeEvery, takeLeading } from 'redux-saga/effects';
// sagas
import {
  sagaConnectToForum,
  sagaGetAppStates,
  sagaGetHelpFullTips,
  sagaSetImage,
  sagaSetImageCreateEvent,
} from './app';
import {
  sagaAuthBack,
  sagaForgotPassword,
  sagaGetAuthKey,
  sagaLogin,
  sagaLoginByKey,
  sagalogout,
  sagaNewPassword,
  sagaNewPasswordTherapist,
  sagaRegistration,
} from './auth';
import {
  sagaApproveClient,
  sagaApproveNoShowClient,
  sagaArchiveClient,
  sagaCancelSubscription,
  sagaEditClient,
  sagaEnabledEmailClient,
  sagaEnabledSmsClient,
  sagaGetClient,
  sagaGetClients,
  sagaGetMissingICDClients,
  sagaPauseClient,
  sagaRestartSubscription,
  sagaSetICD10Code,
  sagaUnarchiveClient,
  sagaUnpauseClient,
} from './admin/clients';
import {
  getTherapistsGroup,
  getTopicsGroup,
  sagaAddClientForGroup,
  sagaArchiveGroup,
  sagaCanselGroup,
  sagaEditGroup,
  sagaGetGroup,
  sagaGetGroups,
  sagaGroupCreated,
  sagaRemoveClientForGroup,
  sagaRescheduleGroup,
  sagaRestartGroup,
  sagaSearchClients,
} from './admin/groups';
import {
  sagaAddTherapist,
  sagaArchiveTherapist,
  sagaCreateTherapistForm,
  sagaDeleteTherapist,
  sagaEditTherapist,
  sagaEditTherapistNames,
  sagaEnabledEmailTherapist,
  sagaEnabledSmsTherapist,
  sagaGetListTherapists,
  sagaGetTherapist,
  sagaGetTherapists,
  sagaGetTherapistTitle,
  sagaResetTherapist,
  sagaSetZoomTherapist,
  sagaTherapistGroups,
  sagaTherapistInvoice,
  sagaTherapistSessionsHistory,
} from './admin/therapists';
import { sagaConsultationForm, sagaConsultationFormSubmit } from './client/consultation';
import { sagaGetChoiceGroup, sagaJoinChoiceGroup } from './client/choice_group';
import {
  sagaAssessmentAfterQuestion,
  sagaAssessmentAnswer,
  sagaAssessmentNextQuestion,
  sagaAssessmentSaveScore,
} from './client/assessment_questions';

import { sagaAfterQuestion, sagaAnswer, sagaNextQuestion } from './client/questions';
import {
  sagaCenselChangeGroup,
  sagaChangeAfterQuestion,
  sagaChangeAnswer,
  sagaChangeGroup,
  sagaChangeNextQuestion,
  sagaGoBackQuestion,
} from './client/change_group';

import {
  sagaCancelAfterQuestion,
  sagaCancelAnswer,
  sagaCancelGroup,
  sagaCancelNextQuestion,
  sagaCancelSubscriptionRenewal,
} from './client/cancel_group';
import {
  sagaCheckEditToken,
  sagaEditAccount,
  sagaGetAccount,
  sagaGetAccountInfo,
  sagaGetEditToken,
  sagaSendToSupport,
  sagaSetAvatar,
  sagaGetNotifications,
  sagaReadNewNotification,
  sagaCheckNotification,
} from './account';
import {
  sagaCanselSession,
  sagaGetCommEventSessions,
  sagaGetNextSessions,
  sagaGetSelfNextSessions,
  sagaGetSelfSessions,
  sagaGetSessions,
  sagaGetSessionsEvents,
  sagaJoinToSession,
  sagaRescheduleSession,
  sagaStartSession,
} from './sessions';
import { sagaGetClietnGroupHistory, sagaGetClietnSessionsHistory, sagaGetHistory } from './history';
import {
  getCountry,
  getCustomerStatuses,
  getGenders,
  getLicenses,
  getReasons,
  getSpecialties,
  getSpecificGroup,
  getState,
  getStatuses,
  getTopics,
  getTopicsExtended,
  getZoomClients,
} from './options';
import {
  sagaCreateLogItem,
  sagaDeleteLogItem,
  sagaEditLogItem,
  sagaGetListProgress,
  sagaGetLogItem,
  sagaGetLogsList,
  sagaGetTermList,
  sagaPostTermList,
  sagaProgressHoldOver,
  sagaProgressReset,
  sagaProgressStart,
} from './progress';

import {
  sagaBookMarkEvents,
  sagaGetEventsAndPosts,
  sagaGetLengthsFilters,
  sagaGetOrdersFilters,
  sagaGetPastEventsAndPostsPreview,
  sagaGetPastPosts,
  sagaGetTopicsFilters,
  sagaGetTypesFilters,
  sagaGetUpcomingEventsAndPostsPreview,
  sagaPosts,
  sagaRegister,
  sagaUpcomingEvents,
} from './client/coming_events';

import { sagaGetAssesment, sagaGetAssesments } from './assesments';

import {
  sagaCanselStripe,
  sagaChangeStripe,
  sagaCheckedStripe,
  sagaCheckPromo,
  sagaChooseStripe,
  sagaConfirmStripe,
  sagaGetStripeList,
  sagaUpdateStripe,
} from './client/stripe';

import { sagaClientFiltersForm, sagaGroupFiltersForm, sagaTherapistFiltersForm } from './forms';
import { sagaGetNpsScore } from './nps_score';
import { sagaGetNotification, sagaReadNotification } from './notificationClient';
import { sagaGetConsultationDates, sagaGetConsultationTime, sagaPostAppointments } from './intake_consultation';
import {
  sagaAddClientForEvent,
  sagaAddEvent,
  sagaDeleteEventTopic,
  sagaEditEvent,
  sagaGetAdminEvent,
  sagaGetAdminEvents,
  sagaRemoveClientForEvent,
  sagaRemoveEvent,
  sagaSearchEventClients,
} from './admin/events';
import { sagaAdminClientSuperbillPaymentInfo, sagaSuperbillPaymentInfo } from './superbills';
import {
  sagaCancelRestartSubscription,
  sagaJoinRestartGroup,
  sagaUpdateRegistrationData,
  sagaWaitingGoBack,
} from './client/restart_subscriptions';
import { sagaResetPasswordAnalytics, sagaSendAnalytics } from './analytics';

export function* sagaWatcher(): Generator {
  yield takeEvery('GET_APP_HELP_SLIDER', sagaGetHelpFullTips);
  yield takeEvery('APP_UPLOAD_FILE', sagaSetImage);
  yield takeEvery('APP_UPLOAD_FILE_ADMIN', sagaSetImageCreateEvent);
  yield takeEvery('APP_CONNECT_TO_FORUM', sagaConnectToForum);
  yield takeEvery('GET_SUPPORT_STATES', sagaGetAppStates);

  yield takeEvery('AUTH_LOGIN', sagaLogin);
  yield takeEvery('AUTH_LOGOUT', sagalogout);
  yield takeEvery('AUTH_REGISTRATION', sagaRegistration);
  yield takeEvery('AUTH_FORGOT_PASSWORD', sagaForgotPassword);
  yield takeEvery('AUTH_NEW_PASSWORD', sagaNewPassword);
  yield takeEvery('AUTH_NEW_PASSWORD_THERAPIST', sagaNewPasswordTherapist);
  yield takeEvery('AUTH_BACK', sagaAuthBack);
  yield takeEvery('GET_AUTH_LINK', sagaGetAuthKey);
  yield takeEvery('LOGIN_BY_KEY', sagaLoginByKey);

  yield takeEvery('GET_THERAPISTS', sagaGetTherapists);
  yield takeLeading('GET_THERAPISTS_LIST', sagaGetListTherapists);

  yield takeEvery('GET_THERAPIST', sagaGetTherapist);
  yield takeEvery('ADD_THERAPIST', sagaAddTherapist);
  yield takeEvery('ARCHIVE_THERAPIST', sagaArchiveTherapist);
  yield takeEvery('DELETE_THERAPIST', sagaDeleteTherapist);
  yield takeEvery('EDIT_THERAPIST', sagaEditTherapist);
  yield takeEvery('EDIT_THERAPIST_NAMES', sagaEditTherapistNames);
  yield takeEvery('SET_ZOOM_THERAPIST', sagaSetZoomTherapist);
  yield takeEvery('THERAPIST_ENABLED_EMAILS', sagaEnabledEmailTherapist);
  yield takeEvery('THERAPIST_ENABLED_SMS', sagaEnabledSmsTherapist);
  yield takeEvery('GET_THERAPIST_INVOICE', sagaTherapistInvoice);

  yield takeEvery('GET_THERAPIST_FORM_OPTIONS', sagaCreateTherapistForm);
  yield takeEvery('GET_THERAPIST_TITLE', sagaGetTherapistTitle);
  yield takeEvery('THERAPIST_RESET', sagaResetTherapist);
  yield takeEvery('GET_THERAPIST_GROUPS', sagaTherapistGroups);
  yield takeEvery('GET_THERAPIST_SESSIONS_HISTORY', sagaTherapistSessionsHistory);

  yield takeEvery('GET_GROUPS', sagaGetGroups);
  yield takeEvery('GET_GROUP', sagaGetGroup);
  yield takeEvery('GET_GROUPS_TOPICS', getTopicsGroup);
  yield takeEvery('GET_GROUPS_THERAPISTS', getTherapistsGroup);
  yield takeEvery('GROUP_CREATED', sagaGroupCreated);
  yield takeEvery('GROUP_RESCHEDULE', sagaRescheduleGroup);
  yield takeEvery('GROUP_ARCHIVE', sagaArchiveGroup);
  yield takeEvery('GROUP_CANCEL', sagaCanselGroup);
  yield takeEvery('GROUP_RESTART', sagaRestartGroup);
  yield takeEvery('GROUP_CLIENTS_SEARCH', sagaSearchClients);
  yield takeEvery('GROUP_ADD_CLIENT', sagaAddClientForGroup);
  yield takeEvery('GROUP_REMOVE_CLIENT', sagaRemoveClientForGroup);
  yield takeEvery('GROUP_EDIT', sagaEditGroup);

  yield takeEvery('GET_CLIENTS', sagaGetClients);
  yield takeEvery('GET_CLIENT', sagaGetClient);
  yield takeEvery('GET_MISSING_ICD_CLIENTS', sagaGetMissingICDClients);
  yield takeEvery('CLIENT_ARCHIVE', sagaArchiveClient);
  yield takeEvery('CLIENT_APPROVE', sagaApproveClient);
  yield takeEvery('CLIENT_NO_SHOW', sagaApproveNoShowClient);
  yield takeEvery('CLIENT_PAUSE', sagaPauseClient);
  yield takeEvery('CLIENT_UNPAUSE', sagaUnpauseClient);
  yield takeEvery('CLIENT_UNARCHIVE', sagaUnarchiveClient);
  yield takeEvery('CLIENT_EDIT', sagaEditClient);
  yield takeEvery('PUT_RESTART_SUBSCRIPTION', sagaRestartSubscription);
  yield takeEvery('PUT_CANCEL_SUBSCRIPTION', sagaCancelSubscription);
  yield takeEvery('CLIENT_ENABLED_EMAILS', sagaEnabledEmailClient);
  yield takeEvery('CLIENT_SET_ICD10_CODE', sagaSetICD10Code);
  yield takeEvery('CLIENT_ENABLED_SMS', sagaEnabledSmsClient);

  yield takeEvery('CANCEL_RESTART_SUBSCRIPTIONS', sagaCancelRestartSubscription);
  yield takeEvery('UPDATE_REGISTRATION_DATA', sagaUpdateRegistrationData);
  yield takeEvery('GO_BACK_WAITING', sagaWaitingGoBack);
  yield takeEvery('JOIN_RESTART_GROUP', sagaJoinRestartGroup);

  yield takeEvery('GET_CONSULTATION_FORM_OPTIONS', sagaConsultationForm);
  yield takeEvery('CONSULTATION_FORM_SUBMIT', sagaConsultationFormSubmit);

  yield takeEvery('GET_CHOICE_GROUP', sagaGetChoiceGroup);
  yield takeEvery('JOIN_CHOICE_GROUP', sagaJoinChoiceGroup);

  yield takeEvery('PUSH_ANSWER_QUESTION', sagaAnswer);
  yield takeEvery('GET_NEXT_QUESTION', sagaNextQuestion);
  yield takeEvery('GET_AFTER_QUESTION', sagaAfterQuestion);
  yield takeEvery('PUSH_ASSESSMENT_ANSWER_QUESTION', sagaAssessmentAnswer);
  yield takeEvery('GET_ASSESSMENT_NEXT_QUESTION', sagaAssessmentNextQuestion);
  yield takeEvery('GET_ASSESSMENT_AFTER_QUESTION', sagaAssessmentAfterQuestion);
  yield takeEvery('SAVE_ANXIETY_ASSESSMENT_SCORE', sagaAssessmentSaveScore);

  yield takeEvery('PUSH_CHANGE_ANSWER_QUESTION', sagaChangeAnswer);
  yield takeEvery('GET_CHANGE_NEXT_QUESTION', sagaChangeNextQuestion);
  yield takeEvery('GET_CHANGE_AFTER_QUESTION', sagaChangeAfterQuestion);
  yield takeEvery('CHANGE_GROUP_GO_BACK', sagaGoBackQuestion);
  yield takeEvery('CHANGE_GROUP_CLIENT', sagaChangeGroup);

  yield takeEvery('PUSH_CANCEL_ANSWER_QUESTION', sagaCancelAnswer);
  yield takeEvery('GET_CANCEL_NEXT_QUESTION', sagaCancelNextQuestion);
  yield takeEvery('GET_CANCEL_AFTER_QUESTION', sagaCancelAfterQuestion);
  yield takeEvery('CANCEL_GROUP_CLIENT', sagaCancelGroup);
  yield takeEvery('CANCEL_SUBSCRIPTION_RENEWAL', sagaCancelSubscriptionRenewal);

  yield takeEvery('CANCEL_CHANGE_GROUP', sagaCenselChangeGroup);

  yield takeEvery('GET_ACCOUNT', sagaGetAccount);
  yield takeEvery('GET_ACCOUNT_INFO', sagaGetAccountInfo);
  yield takeEvery('SEND_SUPPORT_MESSAGE', sagaSendToSupport);
  yield takeEvery('EDIT_CHECK_TOKEN', sagaCheckEditToken);
  yield takeEvery('GET_EDIT_TOKEN', sagaGetEditToken);
  yield takeEvery('EDIT_ACCOUNT', sagaEditAccount);
  yield takeEvery('SET_ACCOUNT_AVATAR', sagaSetAvatar);
  yield takeLeading('GET_ACCOUNT_NOTIFICATIONS', sagaGetNotifications);
  yield takeLeading('READ_ACCOUNT_NOTIFICATIONS', sagaReadNewNotification);
  yield takeLeading('CHECK_NOTIFICATIONS', sagaCheckNotification);

  yield takeEvery('ANALYTICS', sagaSendAnalytics);
  yield takeEvery('RESET_PASSWORD', sagaResetPasswordAnalytics);

  yield takeEvery('GET_SUPERBILL_PAYMENT_INFO', sagaSuperbillPaymentInfo);
  yield takeEvery('GET_ADMIN_CLIENT_SUPERBILL_PAYMENT_INFO', sagaAdminClientSuperbillPaymentInfo);

  yield takeEvery('GET_SESSIONS', sagaGetSessions);
  yield takeEvery('GET_COMM_EVENT_SESSIONS', sagaGetCommEventSessions);
  yield takeEvery('GET_NEXT_SESSIONS', sagaGetNextSessions);
  yield takeEvery('GET_SESSIONS_EVENTS', sagaGetSessionsEvents);
  yield takeEvery('GET_SELF_SESSIONS', sagaGetSelfSessions);
  yield takeEvery('GET_SELF_NEXT_SESSIONS', sagaGetSelfNextSessions);
  yield takeEvery('SESSION_RESCHEDULE', sagaRescheduleSession);
  yield takeEvery('SESSION_CANCEL', sagaCanselSession);
  yield takeEvery('SESSION_JOIN', sagaJoinToSession);
  yield takeEvery('SESSION_START', sagaStartSession);

  yield takeEvery('GET_HISTORY', sagaGetHistory);
  yield takeEvery('GET_HISTORY_GROUPS', sagaGetClietnGroupHistory);
  yield takeEvery('GET_HISTORY_SESSIONS', sagaGetClietnSessionsHistory);

  yield takeEvery('GET_NPS_SCORE', sagaGetNpsScore);

  yield takeEvery('GET_NOTIFICATION', sagaGetNotification);
  yield takeEvery('POST_NOTIFICATION', sagaReadNotification);

  yield takeEvery('GET_REASONS', getReasons);
  yield takeEvery('GET_TOPICS', getTopics);
  yield takeEvery('GET_TOPICS_EXTENDED', getTopicsExtended);
  yield takeEvery('GET_STATUSES', getStatuses);
  yield takeEvery('GET_GENDERS', getGenders);
  yield takeEvery('GET_STATE', getState);
  yield takeEvery('GET_COUNTRY', getCountry);
  yield takeEvery('GET_SPECIALTIES', getSpecialties);
  yield takeEvery('GET_ZOOM_CLIENTS', getZoomClients);
  yield takeEvery('GET_LICENSES', getLicenses);
  yield takeEvery('GET_SPECIFIC_GROUPS', getSpecificGroup);
  yield takeEvery('GET_CUSTOMER_STATUSES', getCustomerStatuses);

  yield takeEvery('PROGRESS_START', sagaProgressStart);
  yield takeEvery('PROGRESS_RESET', sagaProgressReset);
  yield takeEvery('PROGRESS_HOLD_OVER', sagaProgressHoldOver);
  yield takeEvery('GET_PROGRESS_LIST', sagaGetListProgress);
  yield takeEvery('GET_TERM_LIST', sagaGetTermList);
  yield takeEvery('POST_TERM_LIST', sagaPostTermList);

  yield takeEvery('GET_LOGS_LIST', sagaGetLogsList);
  yield takeEvery('GET_LOG_ITEM', sagaGetLogItem);
  yield takeEvery('POST_LOG_ITEM', sagaCreateLogItem);
  yield takeEvery('PUT_LOG_ITEM', sagaEditLogItem);
  yield takeEvery('DELETE_LOG_ITEM', sagaDeleteLogItem);

  yield takeEvery('GET_COMMUNITY_EVENTS', sagaGetAdminEvents);
  yield takeEvery('COMMUNITY_EVENTS_DELETE', sagaRemoveEvent);
  yield takeEvery('GET_COMMUNITY_EVENT', sagaGetAdminEvent);
  yield takeEvery('ADD_COMMUNITY_EVENT', sagaAddEvent);
  yield takeEvery('EDIT_COMMUNITY_EVENT', sagaEditEvent);
  yield takeEvery('DELETE_COMMUNITY_EVENT_TOPIC', sagaDeleteEventTopic);
  yield takeEvery('EVENT_CLIENTS_SEARCH', sagaSearchEventClients);
  yield takeEvery('EVENT_ADD_CLIENT', sagaAddClientForEvent);
  yield takeEvery('EVENT_REMOVE_CLIENT', sagaRemoveClientForEvent);

  yield takeEvery('GET_UPCOMING_EVENTS', sagaUpcomingEvents);
  yield takeEvery('GET_EVENTS_AND_POSTS', sagaGetEventsAndPosts);
  yield takeEvery('GET_PAST_EVENTS_AND_POSTS_PREVIEW', sagaGetPastEventsAndPostsPreview);
  yield takeEvery('GET_UPCOMING_EVENTS_AND_POSTS_PREVIEW', sagaGetUpcomingEventsAndPostsPreview);
  yield takeEvery('GET_PAST_POSTS', sagaGetPastPosts);
  yield takeEvery('GET_LENGTHS_FILTERS', sagaGetLengthsFilters);
  yield takeEvery('GET_TYPES_FILTERS', sagaGetTypesFilters);
  yield takeEvery('GET_TOPICS_FILTERS', sagaGetTopicsFilters);
  yield takeEvery('GET_ORDERS_FILTERS', sagaGetOrdersFilters);
  yield takeEvery('BOOKMARK_EVENTS', sagaBookMarkEvents);
  yield takeEvery('GET_UPCOMING_POSTS', sagaPosts);
  yield takeEvery('UPCOMING_REGISTER', sagaRegister);

  yield takeEvery('GET_ASSESMENTS', sagaGetAssesments);
  yield takeEvery('GET_ASSESMENT', sagaGetAssesment);

  yield takeEvery('GET_STRIPE_LIST', sagaGetStripeList);
  yield takeEvery('CHECK_PROMO', sagaCheckPromo);
  yield takeEvery('STRIPE_CHOOSE', sagaChooseStripe);
  yield takeEvery('STRIPE_CONFIRM', sagaConfirmStripe);
  yield takeEvery('STRIPE_UPDATE', sagaUpdateStripe);
  yield takeEvery('STRIPE_CHECK_STATUS', sagaCheckedStripe);
  yield takeEvery('STRIPE_CANSEL', sagaCanselStripe);
  yield takeEvery('STRIPE_CHANGE', sagaChangeStripe);

  yield takeEvery('GET_CLIENTS_FILTER_OPTIONS', sagaClientFiltersForm);
  yield takeEvery('GET_GROUPS_FILTER_OPTIONS', sagaGroupFiltersForm);
  yield takeEvery('GET_THERAPIST_FILTER_OPTIONS', sagaTherapistFiltersForm);

  yield takeEvery('GET_CONSULTATION_TIME', sagaGetConsultationTime);
  yield takeEvery('GET_CONSULTATION_DATES', sagaGetConsultationDates);
  yield takeEvery('CREATE_INTAKE_CONSULTATION', sagaPostAppointments);
}
