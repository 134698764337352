import React from 'react';
import { useForm } from 'react-hook-form';
// components
import { SubmitButton } from '../../../../molecules/inputsBlocks/reschedule/session';
import { AddGroupEndTime, AddGroupSelectDays, AddGroupStartTime } from '../../../../molecules/inputsBlocks/addGroup';
// style
import '../../style.scss';
import './style.scss';
import { yupResolver } from '@hookform/resolvers/yup';
import { rescheduleGroupSchema } from '../../../../../tools/validations';
import moment from 'moment';

interface FormData {
  dayOfWeek: any;
  start: any;
  end: any;
}

const FormRescheduleGroup: React.FC<any> = ({ onSuccess, setNewDate }: any) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(rescheduleGroupSchema),
  });

  const onSubmit = (data: FormData) => {
    setNewDate({ ...data, start: moment(data.start), end: moment(data.end) });
    onSuccess();
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="form-reschedule-group validation-form">
        <div className="form-reschedule-group__fields">
          <AddGroupSelectDays
            errors={errors.dayOfWeek ? errors.dayOfWeek.message : undefined}
            register={register}
            control={control}
          />

          <div className="form-reschedule-group__row">
            <div className="form-reschedule-group__column">
              <AddGroupStartTime
                errors={errors.start ? errors.start.message : undefined}
                register={register}
                control={control}
              />
            </div>
            <div className="form-reschedule-group__column">
              <AddGroupEndTime
                errors={errors.end ? errors.end.message : undefined}
                register={register}
                control={control}
              />
            </div>
          </div>
        </div>

        <div className="form-reschedule-group__buttons">
          <SubmitButton name="Submit" />
        </div>
      </form>
    </>
  );
};

export default FormRescheduleGroup;
