import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import Participant from '../Participant/Participant';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import useMainParticipant from '../../../hooks/useMainParticipant/useMainParticipant';
import useParticipants from '../../../hooks/useParticipants/useParticipants';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import useSelectedParticipant from '../VideoProvider/useSelectedParticipant/useSelectedParticipant';
import useScreenShareParticipant from '../../../hooks/useScreenShareParticipant/useScreenShareParticipant';
import { useAppState } from '../../../state';
import useGridDimensions from '../../../hooks/UseGridDimensions/UseGridDimensions';
import { RemoteParticipant } from 'twilio-video';
import HiddenParticipant from '../HiddenParticipant/HiddenParticipant';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      overflowY: 'auto',
      background: 'rgb(79, 83, 85)',
      gridArea: '1 / 2 / 1 / 3',
      zIndex: 5,
      [theme.breakpoints.down('sm')]: {
        gridArea: '2 / 1 / 3 / 3',
        overflowY: 'initial',
        overflowX: 'auto',
        display: 'flex',
      },
    },
    transparentBackground: {
      background: 'transparent',
    },
    scrollContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    innerScrollContainer: {
      width: `calc(${theme.sidebarWidth}px - 3em)`,
      padding: '1.5em 0',
      [theme.breakpoints.down('sm')]: {
        width: 'auto',
        padding: `${theme.sidebarMobilePadding}px`,
        display: 'flex',
      },
    },
    gridLayoutContainer: {
      gridArea: '1 / 1 / 2 / 3',
    },
    gridParticipantContainer: {
      width: '50%',
      float: 'left',
      padding: '2px',
      boxSizing: 'border-box',
    },
  }),
);

export default function ParticipantList() {
  const classes = useStyles();
  const { room } = useVideoContext();
  const localParticipant = room!.localParticipant;
  const allParticipants = useParticipants();
  const [initParticipants, setInitParticipants] = useState<string[]>([]);
  const [participants, setParticipants] = useState<RemoteParticipant[]>([]);
  const [selectedParticipant, setSelectedParticipant] = useSelectedParticipant();
  const screenShareParticipant = useScreenShareParticipant();
  const mainParticipant = useMainParticipant();
  const isRemoteParticipantScreenSharing = screenShareParticipant && screenShareParticipant !== localParticipant;
  const { userLayout, participantsToShow, hostIdentity } = useAppState();

  const [width, height] = useGridDimensions();
  const [gridElementWidth, setGridElementWidth] = useState('0px');
  const [gridElementHeight, setGridElementHeight] = useState(0);
  const [elementsInGridRow, setElementsInGridRow] = useState(1);
  const [gridContainerPadding, setGridContainerPadding] = useState(0);
  const [gridRowLeftPadding, setGridRowLeftPadding] = useState(0);
  const [lastGridRowLeftPadding, setLastGridRowLeftPadding] = useState(0);

  const bottomMargin = 8;

  useEffect(() => {
    console.log('Update participants', participantsToShow);
    const tmp = allParticipants.filter((participant) => {
      return initParticipants.includes(participant.identity) || participantsToShow.includes(participant.identity);
    });
    setParticipants(tmp);
  }, [allParticipants, initParticipants, participantsToShow.length]);

  useEffect(() => {
    const tmp = initParticipants;
    allParticipants.forEach((participant) => {
      if (
        !tmp.includes(participant.identity) &&
        (participant.audioTracks.size > 0 || participant.videoTracks.size > 0)
      ) {
        tmp.push(participant.identity);
      }
      setInitParticipants(tmp);
    });
  }, [allParticipants, initParticipants]);

  useEffect(() => {
    console.log('UPDATE GRID', allParticipants.length);
    const participantCount = allParticipants.length + 1;
    const containerRatio = width > 0 ? height / width : 0;
    let inRow = 1;
    let currentWidth = 0;
    let maxElementWidth = 0;
    let maxElementHeight = 0;
    const aspectRatio = 16 / 9;
    for (let i = 1; i <= participantCount; i++) {
      let maxWidth = 0;
      const rowCount = Math.ceil(participantCount / i);
      let tmpWidth = Math.floor(width / i);
      let tmpHeight = tmpWidth / aspectRatio + bottomMargin;
      if ((tmpHeight + bottomMargin) * rowCount > height) {
        tmpHeight = Math.floor((height - bottomMargin) / rowCount);
        tmpWidth = Math.floor(tmpHeight * aspectRatio);
        maxWidth = tmpWidth;
        maxElementHeight = tmpHeight;
      }
      if (tmpWidth > currentWidth) {
        inRow = i;
        currentWidth = tmpWidth;
        maxElementWidth = maxWidth;
        maxElementHeight = Math.floor(tmpWidth / aspectRatio);
      }
    }

    const gridParticipantWidth = maxElementWidth ? maxElementWidth + 'px' : Math.floor(100 / inRow) + '%';

    const rowPadding = maxElementWidth
      ? Math.floor((width - (maxElementWidth + 4) * inRow) / 2)
      : Math.floor((width % inRow) / 2);

    const reminder = participantCount % inRow;
    let lastRowPadding = rowPadding;
    if (reminder) {
      lastRowPadding = maxElementWidth
        ? Math.floor((width - (maxElementWidth + 4) * reminder) / 2)
        : reminder > 1
        ? Math.floor((width % reminder) / 2)
        : Math.floor(((width / inRow) * (inRow - reminder)) / 2);
    }

    setGridElementWidth(gridParticipantWidth);
    setGridElementHeight(maxElementHeight);
    setElementsInGridRow(inRow);
    setGridRowLeftPadding(rowPadding >= 0 ? rowPadding : 0);
    setLastGridRowLeftPadding(lastRowPadding);
  }, [width, height, participants, allParticipants]);

  useEffect(() => {
    const rowCount = Math.ceil((participants.length + 1) / elementsInGridRow);
    let padding = (height - (gridElementHeight + bottomMargin) * rowCount) / 2;
    padding = padding >= 0 ? padding : 0;
    setGridContainerPadding(padding);
  }, [gridElementWidth, gridElementHeight, elementsInGridRow, participants]);

  if (userLayout == 'speaker' && participants.length === 0) return null; // Don't render this component if there are no remote participants.

  return (
    <>
      {userLayout == 'speaker' ? (
        <aside
          className={clsx(classes.container, {
            [classes.transparentBackground]: !isRemoteParticipantScreenSharing,
          })}
        >
          <div className={classes.scrollContainer}>
            <div className={classes.innerScrollContainer}>
              <Participant participant={localParticipant} isLocalParticipant={true} />
              {allParticipants.map((participant) => {
                const isSelected = participant === selectedParticipant;
                const hideParticipant =
                  participant === mainParticipant && participant !== screenShareParticipant && !isSelected;
                return (
                  <Participant
                    key={participant.sid}
                    participant={participant}
                    isSelected={participant === selectedParticipant}
                    onClick={() => setSelectedParticipant(participant)}
                    hideParticipant={hideParticipant}
                  />
                );
              })}
            </div>
          </div>
        </aside>
      ) : (
        <div
          className={classes.gridLayoutContainer}
          id={'participantGridContainer'}
          style={{ paddingTop: gridContainerPadding }}
        >
          <div
            className={classes.gridParticipantContainer}
            style={{ width: gridElementWidth, marginLeft: gridRowLeftPadding }}
          >
            <Participant participant={localParticipant} isLocalParticipant={true} />
          </div>
          {allParticipants
            .sort((a, b) => {
              if (a.identity == hostIdentity) {
                return -1;
              }
              if (b.identity == hostIdentity) {
                return 1;
              }
              return a.identity.localeCompare(b.identity);
            })
            .map((participant, index) => {
              const isSelected = false;
              const hideParticipant = false;
              const firstInRow = (index + 1) % elementsInGridRow == 0;
              const lastRow = participants.length - index < elementsInGridRow;
              const leftMargin = firstInRow ? (lastRow ? lastGridRowLeftPadding : gridRowLeftPadding) : 0;
              const enableScreenShare = participant.sid !== localParticipant.sid;
              return initParticipants.includes(participant.identity) ||
                participantsToShow.includes(participant.identity) ? (
                <div
                  key={participant.sid}
                  className={classes.gridParticipantContainer}
                  style={{ width: gridElementWidth, marginLeft: leftMargin }}
                >
                  <Participant
                    participant={participant}
                    isSelected={participant === selectedParticipant}
                    onClick={() => setSelectedParticipant(participant)}
                    hideParticipant={hideParticipant}
                    enableScreenShare={enableScreenShare}
                  />
                </div>
              ) : (
                <React.Fragment key={participant.sid}>
                  <HiddenParticipant key={participant.sid} participant={participant} />
                </React.Fragment>
              );
            })}
        </div>
      )}
    </>
  );
}
